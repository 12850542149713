import { Button, Grid, Image } from 'antd-mobile'
import { campaignStatus } from 'consts/storage'
import { useLayoutContext } from 'context/LayoutContext'
import { useProduct } from 'context/product-context'
import React from 'react'
import { compareCampaignTime, ShowPriceWithCurrency } from 'utils/helper'
import QuantityPicker from '../QuantityPicker/QuantityPicker'

const NoVariations = ({ meta }) => {
  const { localeCurrency } = useLayoutContext()
  const {
    getProductVariationPrice,
    getMetaField,
    handleAddProductMeta,
    productData,
    campaign,
    setSelectedProductImg,
    activeWholesalePrice,
    totalQty,
  } = useProduct()

  let campaignInfo = compareCampaignTime(campaign?.campaign?.finished_at, campaign?.campaign?.started_at);
  let wholsaleFirstRange;
  if (productData?.wholesales?.length > 0) {
    wholsaleFirstRange = productData?.wholesales[0];
  }

  return (
    <div className="table-responsive">
      <div className="qty-picker-list space-y-2">
        <Grid columns={12} style={{ marginTop: 10 }}>
          <Grid.Item span={4}>
            <div>{meta && meta.name}</div>
          </Grid.Item>
          <Grid.Item span={3}>
            <div>Price</div>
          </Grid.Item>
          <Grid.Item span={5}>
            <div>Quantity</div>
          </Grid.Item>
        </Grid>
        <div className="max-h-64 space-y-2 overflow-y-auto scrollbar:!w-1.5 scrollbar:!h-1.5 scrollbar:bg-transparent scrollbar-track:!bg-slate-100 scrollbar-thumb:!rounded scrollbar-thumb:!bg-slate-300 scrollbar-track:!rounded dark:scrollbar-track:!bg-slate-500/[0.16] dark:scrollbar-thumb:!bg-slate-500/50 supports-scrollbars:pr-2">
          {
            meta.map((item) => {
              let itemPrice = getProductVariationPrice(item.id, 'wholesale')
              let cartItem = getMetaField(item)
              let qtyValue = null;
              if (cartItem) {
                qtyValue = cartItem.qty
              }

              let inStock = itemPrice?.stock?.available ?? itemPrice?.stock;

              let offerPrice = wholsaleFirstRange?.discount || itemPrice?.price?.offer || itemPrice?.discount_price;
              let actualPrice = wholsaleFirstRange?.original || itemPrice?.price?.actual || itemPrice?.price;

              if (activeWholesalePrice?.qty !== undefined && activeWholesalePrice?.qty <= totalQty && !campaign) {
                offerPrice = activeWholesalePrice.discount;
                actualPrice = activeWholesalePrice.original;
              }

              return (
                <Grid columns={12} key={`${meta?.name}-${item?.name}-${item.id}`} style={{ alignItems: 'center' }}>
                  <Grid.Item span={4} style={{ marginTop: 8 }}>
                    <div>
                      {
                        item.image !== null
                          ?
                          (
                            <Button
                              // disabled={isDisabled}
                              // className={isSelected
                              //   ? "product_variation_muti_select__image_button_hover"
                              //   : "product_variation_muti_select__image_button"}
                              onClick={() => setSelectedProductImg(item.image)}
                              style={{ margin: 0, padding: 2, marginBottom: 5 }}
                            >
                              <Image
                                src={item.thumb}
                                width={40}
                                height={40}
                                fit="fill"
                                style={{ borderRadius: 8 }}
                              />
                            </Button>
                          )

                          :
                          <span className="font-medium">{item.title ?? item?.name}</span>
                      }
                    </div>
                  </Grid.Item>
                  <Grid.Item span={3} style={{ marginTop: 8 }}>
                  <div>
                    {
                      offerPrice ?
                      <>
                        <div><strong className="font-medium">{ShowPriceWithCurrency({ price: offerPrice, currency: localeCurrency })}</strong></div>
                        <div style={{marginTop: '5px'}}><del className="text-gray-400 font-normal text-xs">{actualPrice}</del></div>
                      </>
                      : <strong className="font-medium">{ShowPriceWithCurrency({ price: actualPrice, currency: localeCurrency })}</strong>
                    }
                    </div>
                    <div>{itemPrice?.stock?.available ?? itemPrice?.stock} in stock</div>
                  </Grid.Item>
                  <Grid.Item span={5} style={{ marginTop: 8 }}>
                    <div>
                      {
                        itemPrice && (itemPrice?.stock?.available || itemPrice?.stock) > 0 ?
                          <div className="flex flex-col justify-center space-y-1">
                            <div>
                              {
                                campaign && campaignInfo?.campaignStatus === campaignStatus.expired ?
                                  <span>Campaign Expired</span>
                                  :
                                  <QuantityPicker
                                    value={qtyValue > inStock ? inStock : qtyValue}
                                    stock={inStock}
                                    onQtyChange={(value, type) => handleAddProductMeta(value > inStock ? inStock : value, item, type)}
                                  />
                              }
                            </div>
                          </div>
                          :
                          <div><span style={{ fontSize: '14px' }}>out of stock</span></div>
                      }
                    </div>
                  </Grid.Item>
                </Grid>
              )
            })
          }
        </div>
      </div>
      {
        meta?.values?.length > 2 &&
        <div className="text-center">Scroll more</div>
      }
    </div>
  )
}

export default NoVariations
