import React, {  useState } from 'react'
import '../../Myorders.scss'
import { Grid, Image, Loading, Space, Toast } from "antd-mobile";
import { useHistory } from "react-router-dom";
import { CheckOutlined, CopyOutlined } from "@ant-design/icons";
import { ShowPriceWithCurrency } from "utils/helper";
import { useLayoutContext } from 'context/LayoutContext';

const OrderParcelProductDetails = (props) => {
  const { localeCurrency } = useLayoutContext();
  const history = useHistory()

  const { elements, isParcelProductLoading, parcelProductDetails } = props

  const [isCopied, setIsCopied] = useState(false);
  const [selectedTracking, setSelectedTracking] = useState(null);

  if (isParcelProductLoading) {
    return (
      <div className="loading_middle">
        <Loading color='primary' />
      </div>
    )
  }

  const products = parcelProductDetails?.products

  const handleCopy = (value) => {
    Toast.show({
      content: 'Copied!'
    })
    setIsCopied(true)
    navigator.clipboard.writeText(value)
    setSelectedTracking(value)
  }

  return (
    <>
      {
        parcelProductDetails && products?.map((productItem, index) => {
          return (
            <div
              key={`itemOrderDetails${index}`}
              className="product_details_order">
              <Grid columns={10} gap={8} className="order-product-details">
                <Grid.Item span={2} onClick={() => history.push(`/account/parcel/${elements.id}`)}>
                  <Image src={productItem?.item?.image} fit='fill' className="product_image" />
                </Grid.Item>
                <Grid.Item span={8}>
                  <Grid columns={1} gap={0} onClick={() => history.push(`/account/parcel/${elements.id}`)}>
                    <Grid.Item span={1} className="product_title">
                      <div className="product_title__title">
                        <span>{productItem?.item?.title}</span>
                      </div>
                    </Grid.Item>
                  </Grid>
                  <Grid columns={2} gap={4} className="product_meta">
                    <Grid.Item span={2}>
                      <div className="product_price">
                        <span>Price: {ShowPriceWithCurrency({ price: productItem?.item?.price, currency: localeCurrency })}</span>
                      </div>
                    </Grid.Item>
                    <Grid.Item span={2}>
                      <Space block justify="between">
                        <span style={{ color: "black" }}>Courier: {elements?.courier?.name}</span>
                        <span style={{ color: "black" }}>Due: {elements?.due}</span>
                      </Space>
                    </Grid.Item>
                    <Grid.Item span={2}>
                      {
                        productItem?.item?.tracking_code &&
                        <div className="product_parcel">
                          <div>Product Tracking:
                            {
                              isCopied && selectedTracking === productItem?.item?.tracking_code ?
                                <Space>
                                  <span >{productItem?.item?.tracking_code} </span>
                                  <span style={{ marginLeft: '2%' }}><CheckOutlined /></span>
                                </Space>
                                :
                                <Space>
                                  <span className="product_parcel__title" onClick={() => history.push(`/tracking?code=${productItem?.item?.tracking_code}`)}>
                                    {productItem?.item?.tracking_code} </span>
                                  <span style={{ marginLeft: '2%' }} onClick={() => handleCopy(productItem?.item?.tracking_code)}><CopyOutlined /></span>
                                </Space>

                            }
                          </div>
                        </div>
                      }
                    </Grid.Item>
                  </Grid>
                </Grid.Item>
              </Grid>
              {/*<Divider/>*/}
            </div>
          )
        })
      }
    </>
  )
}

export default OrderParcelProductDetails
