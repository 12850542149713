import React, { useCallback, useEffect, useState } from 'react'
import { Divider, Grid, Swiper } from "antd-mobile";
import './SellerProduct.scss'
import { SellerProductList, SliderSkeleton } from "components";
import { useProduct } from "context/product-context";
import { getKeywordsSearch } from "services/product-client";
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { navigateToNewSearch } from 'app/actions/products';

const SellerProducts = () => {
  const history = useHistory();
  const dispatch = useDispatch()
  const { isFetched, data:gsProductData } = useSelector(state => state.productsDetails);
  const [isSellerLoading, setIsSellerLoading] = useState(false);
  const [sellerProduct, setSellerProduct] = useState([]);
  const { state: productData } = useProduct()


  const fetchSellerProducts = useCallback(
    (seller_id) => {
      setIsSellerLoading(true)
      let filters = {
        seller_id,
        shop_id: 4,
        page: 1
      }
      if (seller_id) {
        getKeywordsSearch(filters)
          .then(res => {
            setIsSellerLoading(res.data.data)
            setSellerProduct(res?.data)
          })
          .catch(err => {
            setIsSellerLoading(false)
          })
      }
    },
    [],
  )


  useEffect(() => {
    if (isFetched && gsProductData?.seller?.id) {
      fetchSellerProducts(gsProductData.seller.id)
    }
  }, [isFetched, fetchSellerProducts, gsProductData]);

  return (
    <>
      {
        sellerProduct?.items?.length > 0 &&
        <Grid columns={11} gap={0} className="seller_product_grid">
          <Grid.Item span={5}>
            <h3>Recommend By Seller</h3>
          </Grid.Item>
          <Grid.Item span={6} onClick={() => {
            dispatch(navigateToNewSearch())
            history.push({
              pathname: `/seller-details`,
              search: `?seller_id=${productData?.seller?.id}&shop_id=${productData?.source?.id}`,
            })
          }}>
            <Divider contentPosition='right'>
              <span className="seller_product_grid__view-all">VIEW ALL</span>
            </Divider>
          </Grid.Item>
        </Grid>
      }

      {
        sellerProduct?.items?.length > 0 &&
        <div onClick={() => {
          dispatch(navigateToNewSearch())
          history.push({
            pathname: `/seller-details`,
            search: `?seller_id=${productData?.seller?.id}&shop_id=${productData?.source?.id}`,
          })
        }}>
          <Swiper
            allowTouchMove={true}
            indicator={() => null}
            slideSize={42}
            className={sellerProduct?.items?.length <= 3 ? "swiper_value" : "swiper_value_item"}
          >
            {
              sellerProduct?.items?.map((product, index) => {
                return (
                  <Swiper.Item key={`shipper_item${index}`}>
                    {
                      !isSellerLoading ?
                        <SellerProductList product={product} />
                        :
                        <SliderSkeleton />
                    }
                  </Swiper.Item>
                )
              })
            }
          </Swiper>
        </div>
      }
    </>
  )
}

export default SellerProducts
