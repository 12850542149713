const routePaths = {
  pages: {
    tncs: '/pages/terms-conditions',
    moveOn: `/pages/about-moveon-ship-for-me`,
    aboutUs: '/pages/about-us',
    privacyPolicy: '/pages/privacy-policy',
    refundPolicy: '/pages/refund-policy',
    tracking: '/tracking'
  }

}

export default routePaths;
