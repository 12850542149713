import React from "react"
import "../../Myorders.scss"
import {Grid, Image} from "antd-mobile";
import {StatusTag} from "components";
import { ShowPriceWithCurrency } from "utils/helper";
import {useHistory} from "react-router-dom";
import { useLayoutContext } from "context/LayoutContext";

const DeliverableProductDetails = (props) => {
    const { localeCurrency } = useLayoutContext();
    const history = useHistory()
    const {products: item, totalChecked, handleAllChecked, elements} = props

    return (
        <div>
            <div
                // onClick={() => history.push(`/my-orders/${elements?.id}/product/${item.id}`)}
                className="product_details_order">
                <Grid columns={12} gap={8} className="order-product-details">
                    {/*Remove single parcel request it might use in future do not remove it */}
                    {/*<input type="checkbox"
                                       className="text-primary focus:ring-primary"
                                       id={`pt-product-${item?.id}`}
                                       key={index}
                                       onChange={handleCheckedSingle}
                                       checked={isChecked(item)}
                                       value={item.id}
                                />*/}
                    <Grid.Item span={1} className="product_checkbox">
                        <input type="checkbox"
                               id={`selectAll`}
                               className="text-primary focus:ring-primary"
                               checked={totalChecked() === elements?.products?.length}
                               onChange={handleAllChecked}
                        />
                    </Grid.Item>
                    <Grid.Item span={2}>
                        <Image src={item?.image} fit="fill" className="product_image"/>
                    </Grid.Item>
                    <Grid.Item span={9} onClick={() => history.push(`/account/orders/${elements.id}`)}>
                        <Grid columns={1} gap={0}>
                            <Grid.Item span={1} className="product_title">
                                <div className="product_title__title">
                                    <span>{item?.title}</span>
                                </div>
                            </Grid.Item>
                        </Grid>
                        <Grid columns={2} gap={0} className="product_meta">
                            <Grid.Item span={2}>
                                <div className="product_price">
                                    <span>Price: {ShowPriceWithCurrency({price: item?.price, currency: localeCurrency})}</span>
                                </div>
                            </Grid.Item>
                            <Grid.Item span={2}>
                                <div className="product_status">
                                    <span>Status: <StatusTag slug={item?.status} text={item?.status}/></span>
                                </div>
                            </Grid.Item>
                            <Grid.Item span={2}>
                                <div className="product_status">
                                    <span>Pay Type: {elements?.pay_type?.toUpperCase()}</span>
                                </div>
                            </Grid.Item>
                        </Grid>
                    </Grid.Item>
                </Grid>
            </div>
        </div>
    )
}

export default DeliverableProductDetails
