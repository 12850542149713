import React, {useEffect, useState} from 'react'
import {Grid, InfiniteScroll, Loading } from "antd-mobile";
import {GlobalHeaderBack, KeywordSearchProductDetails, KeywordSearchSkeleton} from "components";
import {ProductDetailsBlock} from "demos";
import {useDispatch, useSelector} from "react-redux";
import useFilters from "hooks/useFilters";
import {getProductsSearch} from "app/actions/products";
import {PageHead} from "../../components";
import './SimilarProducts.scss'

const SimilarProducts = () => {

    const dispatch = useDispatch();
    const {items, loading, isFetched: isItemsFetched} = useSelector((state) => state.products);

    const {
        filters,
        isFetched,
        initializeAvailableFilter
    } = useFilters();

    useEffect(() => {
        if (!isFetched && items.filters?.configurator) {
            initializeAvailableFilter(items.filters?.configurator);
        }
    }, [isFetched, initializeAvailableFilter, items.filters?.configurator]);

    useEffect(() => {
        if (filters && !isItemsFetched) {
            dispatch(getProductsSearch(filters));
        }
    }, [dispatch, filters, isItemsFetched]);

    const [hasMore, setHasMore] = useState(false)

    const InfiniteScrollContent = ({hasMore}) => {
        return (
            <>
                {
                    hasMore ? (
                            <span>End of products</span>
                        )
                        : (
                            <div className="loading_middle">
                                <Loading color='primary'/>
                            </div>
                        )
                }
            </>
        )
    }

    let totalPage = parseInt(items?.paginate?.last_page - 1)

    useEffect(() => {
        if (items?.paginate?.current_page < totalPage) {
            setHasMore(true)
        }
    }, [items?.paginate?.current_page, totalPage]);

    const loadMoreProducts = () => {
        if (items?.paginate) {
            dispatch(getProductsSearch({...filters, ...{page: items?.paginate.current_page + 1}}, true));
            setHasMore(false)
        }
    }

    // if (loading) return (
    //     <KeywordSearchSkeleton/>
    // )

    return (
        <>
            <PageHead title={'Similar Product'}/>

            <GlobalHeaderBack title="Similar Products" height="60px" color="white"/>
            <ProductDetailsBlock padding="3%" marginBottom="12%" background="none">
                {
                    !loading && items.items?.length >= 0 ?
                        <Grid columns={2} gap={8}>
                            {
                                items?.items?.map((product, index) => {
                                    return (
                                        <Grid.Item
                                            key={`product_search${index}`}
                                            className="similar_results_grid"
                                        >
                                            <KeywordSearchProductDetails product={product}/>
                                        </Grid.Item>
                                    )
                                })

                            }
                        </Grid>
                        :
                        <KeywordSearchSkeleton/>
                }

                {
                    items && items?.paginate?.last_page > 1 && items?.paginate?.current_page !== items?.paginate?.last_page &&
                    <InfiniteScroll hasMore={hasMore} loadMore={loadMoreProducts}>
                        < InfiniteScrollContent hasMore={hasMore}/>
                    </InfiniteScroll>
                }
            </ProductDetailsBlock>
        </>
    )
}

export default SimilarProducts
