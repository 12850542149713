import React from 'react'
import styles from './index.less'

export const ProductDetailsBlock = (props) => {
    return (
        <div className={styles.demoBlock}>
            <div
                className={styles.content}
                style={{
                    padding: props.padding,
                    background: props.background,
                    border: props.border,
                    borderRadius: props.borderRadius,
                    marginTop: props.marginTop,
                    marginBottom: props.marginBottom,
                    minHeight: props.minHeight
                }}
            >
                {props.children}
            </div>
        </div>
    )
}

ProductDetailsBlock.defaultProps = {
    padding: '2px 2px',
    background: '#ffffff',
}
