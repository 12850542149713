import { Button, Grid, Image, Space, Stepper } from 'antd-mobile';
import { useLayoutContext } from 'context/LayoutContext';
import { useProduct } from 'context/product-context';
import React, { useState, useEffect } from 'react';
import { changeImageHostName, ShowPriceWithCurrency } from 'utils/helper';

const RetailsVariations = () => {
  const { localeCurrency } = useLayoutContext();
  const {
    state: productData,
    selectedProductSku,
    setTotalQuantity,
    totalQuantity,
    handleVariationSelect,
    selectedProps,
    setStockOutProps,
    stockOutProps,
    selectedVariations,
    selectedDomesticDetails
  } = useProduct();

  const [selectedPropsId, setSelectedPropsId] = useState(null);
  const [stockOutItems, setStockOutItems] = useState(null);

  useEffect(() => {
    let skus = productData && productData?.skus;
    let items = [];
    if (skus) {
      for (let element of skus) {
        if (element.stock > 0) {
          items.push(element.props.split(","));
        }
      }
    }
    setStockOutItems(items);

    return () => {
      setSelectedPropsId(null);
      setStockOutProps([]);
      setStockOutItems([]);
    }
  }, [productData, setStockOutItems, setStockOutProps]);


  const handleSelectedProps = (value, idx) => {
    setSelectedPropsId(idx);
    handleVariationSelect(value, idx);
    let filteredStockOut = stockOutItems.filter((item) =>
      // item.includes(value.id)
      item[idx] === value.id
    );
    setStockOutProps(filteredStockOut.flat());
  };

  let totalPrice = (totalQuantity * selectedProductSku?.price)?.toFixed(2);

  if (selectedProductSku?.discount_price) {
    totalPrice = (totalQuantity * selectedProductSku?.discount_price)?.toFixed(
      2,
    );
  }

  return (
    <>
      {productData?.attributes?.map((productVariations, index) => {
        return (
          <Grid
            columns={2}
            gap={2}
            className="product_variation_select"
            key={index + "productVariationTitle"}
          >
            <Grid.Item
              span={2}
              className="product_variation_select__text"
            >
              <Grid columns={1} gap={2}>
                <Grid.Item>
                  <div className="product_variation_select__text__gird">
                    <span>{`${productVariations?.name}:`}</span>
                    <span className="product_variation_select__text__label">
                      {selectedProps?.[index]?.label ??
                        selectedProps?.[index]?.title}
                    </span>
                  </div>
                </Grid.Item>
              </Grid>
            </Grid.Item>
            <Grid.Item
              span={2}
              className="product_variation_select__button"
            >
              <Space wrap justify="start" align="center">
                {productVariations?.values?.map(
                  (productVariationValue, VIndex) => {
                    let isSelected = false;
                    if (selectedVariations) {
                      if (
                        [selectedVariations[index]]?.includes(
                          productVariationValue?.id,
                        )
                      ) {
                        isSelected = true;
                      }
                    }
                    let selectedOptimizeProductThumb = changeImageHostName(
                      productVariationValue?.thumb,
                    );

                    let isDisabled = false;
                    if (index !== selectedPropsId && stockOutProps?.length > 0) {
                      isDisabled = !stockOutProps?.includes(productVariationValue?.id);
                    }

                    if (isSelected && isDisabled) {
                      isSelected = false;
                    }

                    return (
                      <div
                        className="product_variation_muti_select"
                        key={VIndex + "productVariationValue"}
                        style={{ position: 'relative' }}
                      >
                        {
                          isSelected && totalQuantity > 0 &&
                          <span
                            className="sku-qty-value absolute -top-1 -left-1 w-6 h-6 rounded-full bg-primary text-white text-xs flex items-center justify-center"
                            style={{ position: 'absolute', top: '0', left: '0', width: '24px', height: '24px', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'red', color: 'white', fontSize: '9px', zIndex: '9' }}
                          >{totalQuantity}</span>
                        }
                        {productVariationValue?.image
                          ? (
                            <Button
                              disabled={isDisabled}
                              className={isSelected
                                ? "product_variation_muti_select__image_button_hover"
                                : "product_variation_muti_select__image_button"}
                              onClick={() =>
                                handleSelectedProps(
                                  productVariationValue,
                                  index,
                                )}
                            >
                              <Image
                                src={selectedOptimizeProductThumb}
                                width={40}
                                height={40}
                                fit="fill"
                                style={{ borderRadius: 8 }}
                              />
                            </Button>
                          )
                          : (
                            <Button
                              disabled={isDisabled}
                              onClick={() =>
                                handleSelectedProps(
                                  productVariationValue,
                                  index,
                                )}
                              className={isSelected
                                ? "product_variation_muti_select__title_button_hover"
                                : "product_variation_muti_select__title_button"}
                            >
                              <div
                                style={{
                                  maxWidth: "26em",
                                  overflowWrap: "break-word",
                                }}
                              >
                                {productVariationValue?.title}
                              </div>
                            </Button>
                          )}
                      </div>
                    );
                  },
                )}
              </Space>
              {/*<Button color='primary' size='small'>Shopping Assistance <RightOutlined/></Button>*/}
            </Grid.Item>
          </Grid>
        );
      })}

      <Grid columns={22} gap={18} className="product_variation_number">
        <Grid.Item span={12}>
          <span className="product_variation_number__title title-text">
            Quantity
          </span>
        </Grid.Item>
        <Grid.Item span={3}>
          <Stepper
            value={totalQuantity > selectedProductSku?.stock
              ? selectedProductSku?.stock
              : totalQuantity}
            min={selectedProductSku?.min_qty
              ? selectedProductSku.min_qty
              : 1}
            max={selectedProductSku?.limit_qty
              ? selectedProductSku.limit_qty
              : selectedProductSku?.stock}
            onChange={(value) => {
              setTotalQuantity(value);
            }}
            disabled={selectedProductSku?.stock === 0}
            className="button_stepper"
          />
        </Grid.Item>
      </Grid>
      <div className="product_variation_total">
        <div>
          {selectedProductSku?.stock > 0
            ? (
              <>
                {productData?.shop_id === 4
                  ? (
                    <span>
                      Total :{" "}
                      {ShowPriceWithCurrency({
                        price: Number(totalPrice) +
                          Number(
                            (selectedDomesticDetails?.per_qty || 1) *
                            parseFloat(productData?.fx),
                          ),
                        currency: localeCurrency,
                      })}
                    </span>
                  )
                  : (
                    <span>
                      Total :{" "}
                      {ShowPriceWithCurrency({
                        price: totalPrice,
                        currency: localeCurrency,
                      })}
                    </span>
                  )}
              </>
            )
            : <span>Stock Out</span>}
        </div>
        <div>
          {selectedProductSku?.stock &&
            (
              <span className="available">
                {totalQuantity > selectedProductSku?.stock
                  ? 0
                  : selectedProductSku?.stock - totalQuantity} is available
              </span>
            )}
        </div>
      </div>
    </>
  )
}

export default RetailsVariations;
