import React, {useState} from "react"
import './Register.scss'
import {Button, Form, Input, Selector, Space, Tag, Toast} from "antd-mobile";
import {useAuth} from "context/auth-context";
import useErrors from "hooks/useErrors";
import {postRegister} from "services/auth-client";
import {LOCAL_STORAGE_KEY} from "constants/constants";
import {GlobalHeaderBack, PageHead} from "components";
import {ProductDetailsBlock} from "demos";
import { useLayoutContext } from "context/LayoutContext";
import siteConfig from "constants/site-configs";

const Register = () => {
    const { localeCountry } = useLayoutContext();
    const { newUser, getProfileData, getUsername } = useAuth();
    const [form] = Form.useForm();
    const { getErrors } = useErrors();

    const [isRegisterLoading, setIsRegisterLoading] = useState(false);

    const onFinish = (value) => {
        setIsRegisterLoading(true)
        let payload = {
          otp: newUser?.otp,
          username: getUsername(newUser?.username, localeCountry),
          first_name: value.first_name,
          last_name: value.last_name,
          gender: value.gender ? value.gender[0] : null,
          password: value.password,
          password_confirmation: value.password_confirmation,
        };
        if (payload.username?.includes('@')) {
          payload['mobile'] = getUsername(value.mobile, localeCountry);
        } else {
          payload['email'] = value.email;
        }

        setIsRegisterLoading(false)
        postRegister(payload).then(res => {
            setIsRegisterLoading(false)
            if (res.data?.intent === "app_new_password"){
                window.localStorage.setItem(LOCAL_STORAGE_KEY, res.data.token)

                if (res.data?.token){
                    getProfileData(res.data.token)
                    setTimeout(() => {
                        window.location = "/dashboard"
                    }, 500);
                }
            }
        }).catch(({response}) => {
            setIsRegisterLoading(false)
            let errs = getErrors(response.data.errors);
            form.setFields(errs);

            if (response.data?.message) {
              if (errs?.length > 0) {
                for (const err of errs) {
                  if (err.errors?.length > 0) {
                    Toast.show({
                      icon: 'fail',
                      content: err.errors[0],
                  })
                  }
                }
              } else {
                Toast.show({
                    icon: 'fail',
                    content: response.data.message,
                })
              }

            } else {
                Toast.show({
                    icon: 'fail',
                    content: 'failed!',
                })
            }
        })
    }

    return (
        <div>
            <PageHead title={`Register`} />

            <GlobalHeaderBack title="" height="60px" color="black" backgroundImage="none" backgroundColor="white" />

            <ProductDetailsBlock>
                <div className="register_form">
                        <Space direction='vertical'>
                            <h2 className="register_title">Complete Your Registration</h2>
                            <span className="register_title__sub">Please fill up the following information</span>
                        </Space>
                    <div className="register_fillable_form">
                        <Form
                            form={form}
                            onFinish={onFinish}
                            footer={
                                <Button block type='submit' color='primary' loading={isRegisterLoading} disabled={isRegisterLoading}>
                                    Submit
                                </Button>
                            }
                        >
                            <div className="form_name">
                                <Form.Item
                                    name='first_name'
                                    label='First Name'
                                    rules={[{required: true, message: 'First name cannot be empty'}]}
                                >
                                    <Input placeholder='Please enter your first name'/>
                                </Form.Item>
                                <Form.Item
                                    name='last_name'
                                    label='Last Name'
                                    rules={[{required: true, message: 'Last name cannot be empty'}]}
                                >
                                    <Input placeholder='Please enter your last name'/>
                                </Form.Item>
                            </div>
                            {
                              newUser?.username?.includes('@') ?
                                <Form.Item
                                  name='mobile'
                                  label={<Space justify="start" align="center"><div>Phone</div>
                                    <Tag style={{ marginBottom: '3px', color: 'var(--adm-color-warning)', background: '#fff', border: '0', fontWeight: 'bold' }}
                                    >number without country code {localeCountry?.calling ? localeCountry.calling === '+880' ? `(${localeCountry.calling.slice(1, 3)})` :  `(${localeCountry.calling.slice(1)})` : null}</Tag></Space>}
                                  rules={[{ required: true, message: 'Phone number is required' }]}
                                >
                                  <Input placeholder={localeCountry?.calling  ? siteConfig.phonePlaceholder : 'Phone number'} />
                                </Form.Item>
                              : <Form.Item
                                    name='email'
                                    label='Email'
                                    rules={[{required: true, message: 'Email is required'}]}
                                >
                                    <Input placeholder='Email address'/>
                                </Form.Item>
                            }
                            <Form.Item
                                name='username'
                                label={`Username (${newUser?.username?.includes('@') ? 'Email' : 'Phone'})`}
                                disabled={newUser?.username}
                            >
                                <Input defaultValue={newUser?.username} placeholder='Please enter your username'/>
                            </Form.Item>
                            <Form.Item
                                name='password'
                                label='Password'
                                type="password"
                                rules={[{required: true, message: 'Password cannot be empty'}]}
                            >
                                <Input placeholder='Please enter your password' type="password"/>
                            </Form.Item>
                            <Form.Item
                                name='password_confirmation'
                                label='Confirm Password'
                                rules={[{required: true, message: 'Password cannot be empty'}]}
                            >
                                <Input placeholder='Please match your password' type="password"/>
                            </Form.Item>
                            <Form.Item name='gender' label = 'Gender' >
                                <Selector
                                    columns = {2}
                                    options = { [
                                        {label:'Male', value : 'male'},
                                        {label:'Female',value: 'female'},
                                    ] }
                                />
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </ProductDetailsBlock>
        </div>
    );
}
export default Register
