import { Ellipsis, Space } from 'antd-mobile';
import { useLayoutContext } from 'context/LayoutContext';
import { useProduct } from 'context/product-context';
import React from 'react';
import { ShowPriceWithCurrency } from 'utils/helper';

const ApproxWeightCost = () => {
  const { localeCurrency } = useLayoutContext();
  const { approxWeight, approxShippingCost } = useProduct();
  return (
    <Space direction="vertical">
      {
        approxWeight > 0 &&
        <Space>
          <strong>Approximate Weight: </strong>
          <span>{approxWeight} KG</span>
        </Space>
      }
      {
        approxShippingCost > 0 &&
        <Space>
          <strong>Approximate Shipping Cost: </strong>
          <span>{ShowPriceWithCurrency({ price: approxShippingCost, currency: localeCurrency })}</span>
        </Space>
      }
      {
        <Ellipsis
          style={{ marginTop: "1%", fontSize: "0.9em" }}
          direction="end"
          rows={5}
          content={'This weight is approximate, final shipping charge will be confirmed after final measurement of product weight.'}
          expandText="Read More"
          collapseText="Read Less"
        />
      }
    </Space>
  )
}

export default ApproxWeightCost;
