import { combineReducers } from 'redux';
import products from './products';
import userActivity from "./userActivity";
import payments from "./payments";
import productsDetails from "./productsDetails";
import orders from "./orders";
import gateways from "./gateways";
import internalProducts from "./internalProducts";
import campaign from "./campaign";
import category from "./category";
import address from "./address";
import system from "./system";
import issue from "./issue";
import builder from "./builder";
import productsDetailsData from "./productsDetailsData";
import cart from './cart';

const rootReducer = combineReducers({
    products: products,
    userActivity: userActivity,
    payments: payments,
    productsDetails: productsDetails,
    orders: orders,
    gateways: gateways,
    internalProducts: internalProducts,
    campaign: campaign,
    category: category,
    address: address,
    system: system,
    issue: issue,
    builder: builder,
    productsDetailsData: productsDetailsData,
    cart: cart
});

export default rootReducer;
