import React from 'react';
import '../dashboard.scss'
import {Grid, Space} from "antd-mobile";
import {
    SafetyCertificateOutlined,
} from "@ant-design/icons";
import {BillOutline, ContentOutline, InformationCircleOutline} from "antd-mobile-icons";
import {useHistory} from "react-router-dom";
import routePaths from 'consts/route-paths';
import { useLayoutContext } from 'context/LayoutContext';

const OurServices = () => {
  const { shippingMethodsMap } = useLayoutContext();
  let history = useHistory();

    return (
        <div className="our_services">
            <span className="my-order__orders">Our Services</span>
            <Grid columns={4} gap={1} className="services_menu">
                <Grid.Item onClick={() => history.push(routePaths.pages.privacyPolicy)}>
                    <Space direction='vertical'>
                        <span className="services_menu__icon"><SafetyCertificateOutlined /></span>
                        <span className="services_menu__text">Privacy Policy</span>
                    </Space>
                </Grid.Item>
                <Grid.Item onClick={() => history.push(routePaths.pages.refundPolicy)}>
                    <Space direction='vertical'>
                        <span className="services_menu__icon"><BillOutline /></span>
                        <span className="services_menu__text">Refund Policy</span>
                    </Space>
                </Grid.Item>
                <Grid.Item onClick={() => history.push(routePaths.pages.aboutUs)}>
                    <Space direction='vertical'>
                        <span className="services_menu__icon"><InformationCircleOutline /></span>
                        <span className="services_menu__text">About Us</span>
                    </Space>
                </Grid.Item>
                <Grid.Item onClick={() => history.push(routePaths.pages.moveOn)}>
                    <Space direction='vertical'>
                        <span className="services_menu__icon"><ContentOutline /></span>
                        <span className="services_menu__text">{shippingMethodsMap?.MoveOn?.label || 'MoveOn'} Ship For Me</span>
                    </Space>
                </Grid.Item>
            </Grid>
        </div>
    );
}

export default OurServices;
