import React from 'react';
import {Grid, Swiper} from "antd-mobile";
import Skeleton from 'react-loading-skeleton';

const OrderSliderSkeleton = () => {

    return (
        <Swiper
            allowTouchMove={true}
            indicator={() => null}
            slideSize={42}
        >
            <Swiper.Item>
                <Grid columns={1} gap={4} style={{marginRight: '8%'}}>
                    <Grid.Item>
                        <Skeleton height={125}/>
                        <Skeleton count={1}/>
                        <Skeleton count={1}/>
                        <Skeleton count={1}/>
                    </Grid.Item>
                </Grid>
            </Swiper.Item>
            <Swiper.Item>
                <Grid columns={1} gap={4} style={{marginRight: '8%'}}>
                    <Grid.Item>
                      <Skeleton height={125}/>
                        <Skeleton count={1}/>
                        <Skeleton count={1}/>
                        <Skeleton count={1}/>
                    </Grid.Item>
                </Grid>
            </Swiper.Item>
            <Swiper.Item>
                <Grid columns={1} gap={4} style={{marginRight: '8%'}}>
                    <Grid.Item>
                      <Skeleton height={125}/>
                        <Skeleton count={1}/>
                        <Skeleton count={1}/>
                        <Skeleton count={1}/>
                    </Grid.Item>
                </Grid>
            </Swiper.Item>
            <Swiper.Item>
                <Grid columns={1} gap={4} style={{marginRight: '8%'}}>
                    <Grid.Item>
                      <Skeleton height={125}/>
                        <Skeleton count={1}/>
                        <Skeleton count={1}/>
                        <Skeleton count={1}/>
                    </Grid.Item>
                </Grid>
            </Swiper.Item>
            <Swiper.Item>
                <Grid columns={1} gap={4} style={{marginRight: '8%'}}>
                    <Grid.Item>
                      <Skeleton height={125}/>
                        <Skeleton count={1}/>
                        <Skeleton count={1}/>
                        <Skeleton count={1}/>
                    </Grid.Item>
                </Grid>
            </Swiper.Item>
        </Swiper>
    );
}

export default OrderSliderSkeleton;
