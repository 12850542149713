import { useLayoutContext } from 'context/LayoutContext';
import React from 'react';
import { ShowPriceWithCurrency } from 'utils/helper';
import './ProductPrice.scss'

const ProductPrice = (props) => {
  const { localeCurrency } = useLayoutContext();
  const { discountPrice, originalPrice, fontSize } = props;

  return (
    <>
      {
        !discountPrice &&
        <span className={`product_price__original ${fontSize}`}>{ShowPriceWithCurrency({ price: originalPrice, currency: localeCurrency })}</span>
      }
      {
        parseFloat(discountPrice) > 0 &&
        <>
          <span
            className={`product_price__discount ${fontSize}`}>{ShowPriceWithCurrency({ price: discountPrice, currency: localeCurrency })}</span>
          <del className={`product_price__del ${fontSize}__del`}>{originalPrice}</del>
        </>
      }
    </>
  )
}

export default ProductPrice;
