import React, { useEffect, useState, useCallback } from "react";
import { HomePageBlock } from "demos";
import { Grid, Space, Swiper } from "antd-mobile";
import { FlashDealProductDetails } from "components";
import "pages/HomePageCraft/homepage.scss"
import OrderSliderSkeleton from "components/OrderSliderSkeleton";
import { useHistory } from "react-router-dom";
import { compareCampaignTime } from "utils/helper";
import Countdown from "react-countdown";
import { getCampaignDetails } from "services/campaign-client";

const PromoCampaignCarousel = (props) => {
  const { campaignQuery } = props;
  let history = useHistory();
  const [productsData, setProductsData] = useState(null);
  const [isFirstCall, setIsFirstCall] = useState(true);
  const [productsLoading, setProductsLoading] = useState(false);
  const [fetchedCampaign, setFetchedCampaign] = useState(null);

  const getProductsFromApi = useCallback((params = {}) => {
    setProductsLoading(true);
    if (params?.campaign && !params?.keyword) {
      getCampaignDetails(params.campaign)
        .then(res => {
          const { data, campaign} = res.data;
          let resData = {items: data, campaign}
          setFetchedCampaign(resData)
          setIsFirstCall(false);
        })
        .catch(({ response }) => {
          if (response?.data) {
            // toastErrors(response.data)
          }
        })
        .finally(() => {
          setProductsLoading(false);
        })
    }
  }, [])

  useEffect(() => {
    if (campaignQuery?.link && isFirstCall) {
      getProductsFromApi({ campaign: campaignQuery.link })
      setIsFirstCall(false);
    }
  }, [campaignQuery, isFirstCall, getProductsFromApi])

  useEffect(() => {
    if (fetchedCampaign?.items) {
      setProductsData(fetchedCampaign?.items)
    }
  }, [fetchedCampaign])

  let scheduledTime = null

  if (compareCampaignTime(fetchedCampaign?.campaign?.finished_at, fetchedCampaign?.campaign?.started_at) === "Scheduled") {
    scheduledTime = fetchedCampaign?.campaign?.started_at
  } else if (compareCampaignTime(fetchedCampaign?.campaign?.finished_at, fetchedCampaign?.campaign?.started_at) === "Running") {
    scheduledTime = fetchedCampaign?.campaign?.finished_at
  }


  return (
    <>
      <HomePageBlock
        style={{
          background: "linear-gradient(90deg, rgba(248,113,113,1) 0%, rgba(245,158,11,1) 35%, rgba(248,113,113,1) 100%)",
          // borderRadius: '4px',
          margin: '4% 0',
          paddingTop: "2%",
          paddingBottom: "4%"
        }}
      >
        <Grid columns={12} gap={8} className="flash_deal_grid">
          <Grid.Item span={9}>
            <div style={{ display: 'flex', gap: '0 6px' }}>
              <h3 style={{ lineHeight: '1.25rem' }}>{fetchedCampaign?.campaign?.title ?? "Flash Deals"}</h3>
              {
                (scheduledTime || fetchedCampaign?.campaign?.finished_at) &&
                <div style={{ display: 'flex', width: '50%' }}>
                  <Countdown
                    date={(scheduledTime ? scheduledTime : fetchedCampaign?.campaign?.finished_at) * 1000}
                    renderer={props => {
                      return (
                        <Grid columns={8} gap={4} style={{ alignItems: "center" }}>
                          <Grid.Item span={2}>
                            <Space direction="horizontal" align="center" className="count_down_format_homepage">
                              <span className="count_down_format_homepage__title">{props.formatted.days} </span>
                            </Space>
                          </Grid.Item>
                          <Grid.Item span={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div><strong className="dot">:</strong></div>
                          </Grid.Item>
                          <Grid.Item span={2}>
                            <Space direction="horizontal" align="center" className="count_down_format_homepage">
                              <span className="count_down_format_homepage__title">{props.formatted.hours}</span>
                            </Space>
                          </Grid.Item>
                          <Grid.Item span={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div><strong className="dot">:</strong></div>
                          </Grid.Item>
                          <Grid.Item span={2}>
                            <Space direction="vertical" align="center" className="count_down_format_homepage">
                              <span className="count_down_format_homepage__title">{props.formatted.minutes}</span>
                            </Space>
                          </Grid.Item>
                        </Grid>
                      )
                    }}
                  />
                </div>
              }
            </div>
          </Grid.Item>
          <Grid.Item span={3} style={{ textAlign: "right", color: '#fff !important' }}>
            {
              <span className="flash-deal-view-all"
                onClick={() => history.push(`/campaigns/${fetchedCampaign?.campaign?.slug}`)}>View All</span>
            }
          </Grid.Item>
        </Grid>
        {
          !productsLoading ?
            <Swiper
              className="flash_deal_swiper"
              allowTouchMove={true}
              indicator={() => null}
              slideSize={42}
            >
              {
                productsData && productsData?.map((product, index) => {
                  return (
                    <Swiper.Item key={`flashDeal${index}`}>
                      <FlashDealProductDetails product={product} />
                    </Swiper.Item>
                  )
                })
              }
            </Swiper>
            :
            <OrderSliderSkeleton />
        }
      </HomePageBlock>
    </>
  );
}

export default PromoCampaignCarousel;
