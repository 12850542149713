import React, { useState } from 'react'
import { Divider, Form, Image, Input, Selector } from "antd-mobile";
import './CustomFormElement.scss'
import qs from "qs";

const CustomFormElement = (props) => {

  const { type, label, name, handleChange, fieldValue, options, separator } = props;

  const params = qs.parse((window.location.search).substring(1));

  const [showMore, setShowMore] = useState(false);
  const [selectedValue, setSelectedValue] = React.useState(null);

  const handleShowMore = (itemValue) => {
    if (selectedValue) {
      setSelectedValue((state) => {
        state = itemValue;
        return state;
      })
      if (selectedValue === itemValue) {
        setShowMore(!showMore)
      } else if (selectedValue !== itemValue) {
        setShowMore(true)
      }
    } else {
      setSelectedValue((state) => {
        state = itemValue;
        return state;
      })
      setShowMore(true)
    }
  }

  let fieldInput = <Input type="text" defaultValue={fieldValue}
    onChange={(e) => handleChange({ [name]: e.target.value })} />

  if (type === 'checkbox') {
    const getLastCharacterOfParams = params?.features?.slice(-1);
    const splitDefaultValue = params?.features?.split(getLastCharacterOfParams)

    let makeOption = options?.map(item => {
      return {
        label: item.image
          ?
          <div className="filter_image">
            <div className="filter_image__thumb">
              <Image src={item.image} fit='fill' className="filter_image__thumb__image" />
            </div>
          </div>
          :
          item.label,
        value: item.value
      }
    })
    fieldInput = <>
      <Selector
        options={makeOption}
        multiple={true}
        defaultValue={splitDefaultValue}
        onChange={(arr, context) => handleChange({ [name]: arr.join(separator) })}
      />
      <Divider />
    </>
  } else if (type === "attribute") {
    fieldInput = null
  } else if (type === "tree") {
    const numberOfItems = showMore ? options?.length : 4;

    let makeOption = options?.slice(0, numberOfItems)?.map(item => {
      return {
        label: item.image
          ?
          <div className="filter_image">
            <div className="filter_image__thumb">
              <Image src={item.image} fit='fill' className="filter_image__thumb__image" />
            </div>
          </div>
          :
          item.label,
        value: item.value
      }
    })

    fieldInput = <>
      <Selector
        options={makeOption}
        defaultValue={params?.cid}
        onChange={(arr, context) => handleChange({ [name]: arr + "" })}
      />
      {
        options?.length > 4 &&
        <div className="view_more_customer_order">
          <div onClick={() => setShowMore(!showMore)}> {
            showMore
              ?
              <Divider contentPosition='right'>
                <div className="customize_order">
                  <Image src="/assets/icons/filter_down.svg" width={18} height={18} fit='fill' />
                  <span className="customize_order__title">View Less</span>
                </div>
              </Divider>
              :
              <Divider contentPosition='right'>
                <div className="customize_order">
                  <Image src="/assets/icons/filter_up.svg" width={18} height={18} fit='fill' />
                  <span className="customize_order__title">View More</span>
                </div>
              </Divider>
          }
          </div>
        </div>
      }
      <Divider />
    </>
  } else if (type === "range") {
    const splitDefaultValue = params?.pr?.split("-")

    fieldInput = <>
      <div className="price_range">
        <Input type="number" placeholder='Min Price' defaultValue={params?.pr ? splitDefaultValue[0] : ""}
          onChange={(val) => handleChange({ [name]: { val, tag: "min" } })} />
        <Input type="number" placeholder='Max Price' defaultValue={params?.pr ? splitDefaultValue[1] : ""}
          onChange={(val) => handleChange({ [name]: { val, tag: "max" } })} />
      </div>
      <Divider />
    </>
  }

  return (
    <div className="form_element">
      <Form.Item label={type === "attribute" ? null : label}>
        {fieldInput}
      </Form.Item>
      {
        type === "attribute" && <>
          {
            options?.map((item, index) => {
              const numberOfItems = selectedValue === item?.value && showMore ? item?.length ?? item?.values?.length : 4;

              const splitDefaultValue = params?.attr?.split(";")

              let makeOption = item?.values?.slice(0, numberOfItems)?.map(item => {
                return {
                  label: item.image
                    ?
                    <div className="filter_image">
                      <div className="filter_image__thumb">
                        <Image src={item.image} fit='fill'
                          className="filter_image__thumb__image" />
                      </div>
                    </div>
                    :
                    item.label,
                  value: item.value
                }
              })

              return (
                <div key={`itemIndex${index}`}>
                  <Form.Item label={item?.label}>
                    <Selector
                      options={makeOption}
                      multiple={true}
                      defaultValue={splitDefaultValue}
                      onChange={(arr, context) => handleChange({ [name]: arr.join(separator) })}
                    />
                  </Form.Item>
                  {
                    item?.values?.length > 4 && <div className="view_more_customer_order">
                      <div onClick={() => handleShowMore(item?.value)}> {
                        showMore && selectedValue === item?.value
                          ?
                          <Divider contentPosition='right'>
                            <div className="customize_order">
                              <Image src="/assets/icons/filter_down.svg" width={18} height={18}
                                fit='fill' />
                              <span className="customize_order__title">View Less</span>
                            </div>
                          </Divider>
                          :
                          <Divider contentPosition='right'>
                            <div className="customize_order">
                              <Image src="/assets/icons/filter_up.svg" width={18} height={18}
                                fit='fill' />
                              <span className="customize_order__title">View More</span>
                            </div>
                          </Divider>
                      }
                      </div>
                    </div>
                  }
                  <Divider />
                </div>
              )
            })
          }
        </>
      }
    </div>
  )
}

export default CustomFormElement
