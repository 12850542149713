import React, { useEffect } from "react"
import { Button, DotLoading, ErrorBlock, Result, Space } from "antd-mobile";
import { GlobalHeaderBack } from "components";
import { useHistory } from "react-router-dom";
import { ACTIVE_TAB } from "consts/storage";
import qs from "qs";
import { processPaymentByToken } from "services/payments";

const PaymentSuccessful = () => {
  const history = useHistory()
  const [isLoading, setIsLoading] = React.useState(true);

  const params = qs.parse((window.location.search).substring(1))

  useEffect(() => {
    document.body.style.background = "#ffffff"
  }, [])

  const stripeprocessPayment = React.useCallback((token) => {
    processPaymentByToken(token)
      .then((res) => {
        history.push(`/payment/feedback?status=success`)
      })
      .catch(({ response }) => {
        console.error({ response })
        history.push(`/payment/feedback?status=failed`)
      })
      .finally(() => setIsLoading(false))
  }, [history])

  React.useEffect(() => {
    if (params?.payment_id && params?.gateway === 'stripe') {
      stripeprocessPayment(params.payment_id)
    }
  }, [params, stripeprocessPayment])

  let paymentTitle = ""
  if (params.status === "success") {
    paymentTitle = <span style={{ fontWeight: "bold" }}>Payment Successful</span>
  } else if (params.status === "failed") {
    paymentTitle = <span style={{ fontWeight: 'bold' }}>Payment Failed</span>
  }


  const handleTabSelect = (value) => {
    window.localStorage.setItem(ACTIVE_TAB, JSON.stringify(value))
    history.push("/account/orders")
  }

  const renderMsgByStatus = (params, isLoading) => {
    let isStripeLoading = params?.gateway === 'stripe' && params?.payment_id && isLoading;
    if (isStripeLoading) {
      return (
        <Result
          status='waiting'
          title={<>Payment proccessing <DotLoading color='var(--adm-color-primary)' /></>}
          description="Please don't close this app until the process is done."
          style={{
            "--image-height": "150px",
            paddingBottom: "33%"
          }}
        />
      )
    }
    else if (params?.status === "success") {
      return (
        <ErrorBlock
          fullPage
          image="/assets/social/payment_successful.svg"
          style={{
            "--image-height": "150px",
            paddingBottom: "33%"
          }}
          description="Payment has been successfully done"
          title={paymentTitle}
        >
          <Space>
            <Button color="primary" onClick={() => history.push("/")}>Continue Shopping</Button>
            <Button color="success" onClick={() => handleTabSelect("all")}>My Orders</Button>
          </Space>
        </ErrorBlock>
      )
    } else if (params?.status === 'failed') {
      return (
        <ErrorBlock
          fullPage
          image="/assets/social/payment_failed.svg"
          style={{
            "--image-height": "150px",
            paddingBottom: "33%",
            paddingLeft: '10px',
            paddingRight: '10px',
          }}
          description="Sorry! Payment was unsuccessful. Please retry or get in touch with our support staff."
          title={paymentTitle}
        >
          <Space>
            <Button color="primary" onClick={() => history.push("/")}>Continue Shopping</Button>
            <Button color="success" onClick={() => handleTabSelect("all")}>My Orders</Button>
          </Space>
        </ErrorBlock>
      )
    }
  }

  return (
    <>
      <GlobalHeaderBack title={paymentTitle} height="60px" color="white" />
      {
        renderMsgByStatus(params, isLoading)
      }
    </>
  )
}

export default PaymentSuccessful
