import React, {useEffect, useState} from "react";
import {Button, Checkbox, Form, Input, Selector, Toast} from "antd-mobile";
import '../Wallet.scss'
import { storeWithdrawalsMethod} from "services/wallet";
import useErrors from "hooks/useErrors";
import {CheckSquareFilled, CheckSquareOutlined} from "@ant-design/icons";

const WithdrawMethod = (props) => {

    const { withdrawal, fetchWithdrawalsMethods, setIsViewRefundMethod, findActiveAccount } = props

    const [form] = Form.useForm();
    const [isWithdrawingLoad, setIsWithdrawingLoad] = useState(false);
    const [withdrawalValue, setWithdrawalValue] = useState([findActiveAccount?.type]);
    const {getErrors} = useErrors();


    const onFinish = (value) => {

        let data = null

        if (withdrawalValue[0] === "bank"){
            data = {
                password: value.password,
                type: withdrawalValue[0],
                account: value.account,
                payee_name: value.payee_name,
                bank_name: value.bank_name,
                bank_branch: value.bank_branch,
                routing_no: value.routing_no,
                swift_code: value.swift_code,
                is_default: value.is_default ? value.is_default : true
            }
        }else if (withdrawalValue[0] === "bkash" || withdrawalValue[0] === "nagad" || withdrawalValue[0] === "rocket"){
            data = {
                password: value.password,
                type: withdrawalValue[0],
                account: value.account,
                is_default: value.is_default ? value.is_default : true
            }
        }


        setIsWithdrawingLoad(true)
        storeWithdrawalsMethod(data)
            .then(res => {
                fetchWithdrawalsMethods()
                setIsWithdrawingLoad(false)
                setIsViewRefundMethod(false)
                Toast.show({
                    content: res?.data?.message,
                    position: 'bottom'
                })
            })
            .catch(err => {
                setIsWithdrawingLoad(false)
                if (err?.response?.data?.errors){
                    form.setFields(getErrors(err.response.data.errors));
                }else if (err?.response?.data?.message) {
                    Toast.show({
                        icon: 'fail',
                        content: err?.response?.data?.message,
                    })
                }else {
                    Toast.show({
                        icon: 'fail',
                        content: 'failed!',
                    })
                }
            })
    }

    const findValueActiveAccount = withdrawal && Object.values(withdrawal).find((el) => el.type === withdrawalValue[0])

    useEffect(() => {

        let fields = null
        if (findValueActiveAccount?.type === "bank"){
             fields = {
                account: findValueActiveAccount.account,
                bank_branch: findValueActiveAccount.bank_branch ? findValueActiveAccount.bank_branch : '',
                bank_name: findValueActiveAccount.bank_name ? findValueActiveAccount.bank_name : '',
                payee_name: findValueActiveAccount.payee_name ? findValueActiveAccount.payee_name : '',
                routing_no: findValueActiveAccount.routing_no ? findValueActiveAccount.routing_no : '',
                swift_code: findValueActiveAccount.swift_code ? findValueActiveAccount.swift_code : '',
            }   
        }else {
            fields = {
                account: findValueActiveAccount?.account,
            }
        }
        
        form.setFieldsValue(fields);
    }, [findValueActiveAccount?.account, findValueActiveAccount?.bank_branch, findValueActiveAccount?.bank_name, findValueActiveAccount?.payee_name, findValueActiveAccount?.routing_no, findValueActiveAccount?.swift_code, findValueActiveAccount?.type, form]);
    

    const makeWithdrawMethodOption = withdrawal && Object.keys(withdrawal).map((el, index) => {
        const optionValue  = withdrawal[el]
        return {
            label : el,
            value: optionValue?.type !== null ? optionValue?.type : el
        }
    })

    return (
        <div className="windrow_form">
            <Form
                onFinish={onFinish}
                form={form}
                footer={
                    <Button block type='submit' color='primary' loading={isWithdrawingLoad}>
                        Save
                    </Button>
                }
            >
                <Form.Item name='account_type' label='Account Type'>
                    {
                        withdrawal &&
                            <Selector
                                columns={2}
                                options={makeWithdrawMethodOption}
                                onChange={(arr) => setWithdrawalValue(arr)}
                                defaultValue={[findActiveAccount?.type]}
                            />
                    }
                </Form.Item>

                {
                    (withdrawalValue[0] === "nagad" || withdrawalValue[0] === "bkash" || withdrawalValue[0] === "rocket") &&
                        <>
                            <Form.Item
                                name='account'
                                label='Account Number'
                            >
                                <Input placeholder='01XXXXXXXXXXXX' autoComplete="chrome-off" />
                            </Form.Item>
                            <Form.Item
                                name='password'
                                label='Password'
                            >
                                <Input placeholder='Enter your password' type="password" />
                            </Form.Item>
                            <Form.Item
                                name='is_default'
                            >
                                <Checkbox
                                    value='1'
                                    checked={true}
                                    icon={checked =>
                                        checked ? (
                                            <CheckSquareFilled style={{color: 'var(--adm-color-primary)'}}/>
                                        ) : (
                                            <CheckSquareOutlined style={{color: 'var(--adm-color-weak)'}}/>
                                        )
                                    }
                                > Set as default method </ Checkbox>
                            </Form.Item>
                        </>
                }
                {
                    withdrawalValue[0] === "bank" &&
                    <>
                        <div className="format_form">
                            <Form.Item
                                name='account'
                                label='Account Number'
                            >
                                <Input placeholder='0125586544474' autoComplete="chrome-off" />
                            </Form.Item>
                            <Form.Item
                                name='payee_name'
                                label='Payee Name'
                            >
                                <Input placeholder='Enter your payee name' autoComplete="chrome-off" />
                            </Form.Item>
                        </div>
                        <div className="format_form">
                            <Form.Item
                                name='bank_name'
                                label='Bank Name'
                            >
                                <Input placeholder='Enter your bank name' autoComplete="chrome-off" />
                            </Form.Item>
                            <Form.Item
                                name='bank_branch'
                                label='Bank Branch'
                            >
                                <Input placeholder='Enter your bank branch' autoComplete="chrome-off" />
                            </Form.Item>
                        </div>
                        <div className="format_form">
                            <Form.Item
                                name='routing_no'
                                label='Routing Number'
                            >
                                <Input placeholder='Enter your routing number' autoComplete="chrome-off" />
                            </Form.Item>
                            <Form.Item
                                name='swift_code'
                                label='Swift Code'
                            >
                                <Input placeholder='Enter your swift code' autoComplete="chrome-off" />
                            </Form.Item>
                        </div>
                        <Form.Item
                            name='password'
                            label='Password'
                        >
                            <Input placeholder='Enter your password' type="password" />
                        </Form.Item>
                        <Form.Item
                            name='is_default'
                        >
                            <Checkbox
                                value='1'
                                defaultChecked={true}
                                icon={checked =>
                                    checked ? (
                                        <CheckSquareFilled style={{color: 'var(--adm-color-primary)'}}/>
                                    ) : (
                                        <CheckSquareOutlined style={{color: 'var(--adm-color-weak)'}}/>
                                    )
                                }
                            > Set as default method </ Checkbox>
                        </Form.Item>
                    </>
                }
            </Form>
        </div>
    );
}

export default WithdrawMethod
