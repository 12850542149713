import React, {useCallback, useEffect, useState} from 'react'
import {Grid, Image, Toast} from "antd-mobile";
import './Notification.scss'
import {GlobalHeaderBack, LoadingState, LoadMore, PageHead} from "../../components";
import {getNotification, storeNotification} from "services/my-request-client";
import {BellOutline} from "antd-mobile-icons";
import {formatDate} from "utils/helper";
import {ProductDetailsBlock} from "demos";

const Notification = () => {

    const [isNotificationLoading, setIsNotificationLoading] = useState({
        isPageLoading: false,
        isButtonLoading: false,
    })


    const [notificationData, setNotificationData] = useState({
        data: [],
        meta: null
    });

    const [isFirstCall, setIsFirstCall] = useState(true);


    const fetchNotification = useCallback(
        (page) => {
            setIsNotificationLoading({
                isPageLoading: notificationData.data.length <= 0,
                isButtonLoading: true
            })
            getNotification(page)
                .then(res => {
                    setIsNotificationLoading(false)
                    let newData = [...notificationData.data, ...res.data.data]
                    setNotificationData({
                        data: newData,
                        meta: res.data.meta,
                    })
                    setIsNotificationLoading({
                        isPageLoading: false,
                        isButtonLoading: false,
                    })
                })
                .catch(err => {
                    setIsNotificationLoading({
                        isPageLoading: false,
                        isButtonLoading: false,
                    })
                })
        },
        [setNotificationData, setIsNotificationLoading, notificationData],
    )

    useEffect(() => {
        if (isFirstCall) {
            fetchNotification()
            setIsFirstCall(false)
        }
    }, [fetchNotification, isFirstCall]);

    const isNotificationButtonLoading = isNotificationLoading.isButtonLoading
    const isNotificationPageLoading = isNotificationLoading.isPageLoading

    const loadNotification = () => {
        if (notificationData.meta) {
            fetchNotification((notificationData.meta.current_page + 1))
        }
    }

    if (isNotificationPageLoading) return <LoadingState/>

    const handleNotification = (action, item) => {

        let data = {
            action: action,
            ids: [item.id]
        }

        storeNotification(data)
            .then(res => {
                if (action === "read") {
                    if (item.type === "OrderPlaced"){
                        if (item?.data?.ids?.length > 0 ){
                            setTimeout(() => {
                                window.location = `/account/orders/${item.data.ids[0]}`
                            }, 500);
                        }
                    }else if (item.type === "ProductRejected" || item.type === "ProductApproved" || item.type === "ProductRequested"){
                        setTimeout(() => {
                            window.location = '/my-request'
                        }, 500);
                    }
                    let now = new Date()
                    let newData = notificationData.data.map(items => {
                        if (items.id === item.id) {
                            items.read_at = now.toISOString()
                            return items
                        }
                        return items
                    })
                    setNotificationData({
                        ...notificationData,
                        data: newData
                    })
                } else if (action === "delete") {
                    let newData = notificationData.data.filter(items => items.id !== item.id)
                    setNotificationData({
                        ...notificationData,
                        data: newData
                    })
                }
                Toast.show({
                    icon: 'success',
                    content: res?.data?.message,
                })
            })
            .catch((err) => {
                if (err?.response?.data?.message) {
                    Toast.show({
                        icon: 'fail',
                        content: err?.response?.data?.message,
                    })
                } else {
                    Toast.show({
                        icon: 'fail',
                        content: 'failed!',
                    })
                }
            })
    }

    return (
        <div style={{marginBottom: '15%'}}>
            <PageHead title={'Notification'}/>

            <GlobalHeaderBack title="Notification" height="60px" color="white"/>
            {
                notificationData && notificationData.data.map((item, index) => {
                    return (
                        <div key={`notification${index}`} onClick={() => handleNotification("read", item)} className={item.read_at ? "read_title" : "non_read_title"}>
                            <ProductDetailsBlock padding="3%" marginBottom="1%" marginTop="1%">
                                <Grid columns={15} gap={8} className="notification">
                                    <Grid.Item span={1}>
                                        <span className="notification__icons"><BellOutline/></span>
                                    </Grid.Item>
                                    <Grid.Item span={10} className="notification__title">
                                        <span>{item.message}</span>
                                    </Grid.Item>
                                    <Grid.Item span={4} className="notification__date">
                                        <span>{formatDate(item.notified_at)}</span>
                                    </Grid.Item>
                                    <Grid.Item span={1}>

                                    </Grid.Item>
                                    <Grid.Item span={14} className="notification__grid">
                                        <Grid columns={8} gap={8} className="notification__grid__item">
                                            {
                                                item.data.image &&
                                                <Grid.Item span={2}>
                                                    <Image
                                                        src={item.data.image}
                                                        width={64}
                                                        height={64}
                                                        fit='cover'
                                                        style={{borderRadius: 32}}
                                                    />
                                                </Grid.Item>
                                            }
                                            <Grid.Item span={item.data.image ? 6 : 8}>
                                                <span>{item.message}</span>
                                            </Grid.Item>
                                        </Grid>
                                    </Grid.Item>
                                </Grid>
                            </ProductDetailsBlock>
                        </div>
                    )
                })
            }

            <div className="loadmore_button">
                {
                    notificationData && notificationData?.meta?.last_page > 1 && notificationData?.meta?.current_page !== notificationData?.meta?.last_page &&
                    <LoadMore
                        handleClick={loadNotification}
                        isLoading={isNotificationButtonLoading}
                        loadingText="Notification"
                    />
                }
            </div>
        </div>
    )
}

export default Notification
