import { call, put, takeEvery } from 'redux-saga/effects'
import * as types from 'app/actionTypes/products';
import {getKeywordsSearch} from "services/product-client";

const getApi = async (filters = {}) => {
    return getKeywordsSearch(filters)
    .then(res => res.data)
    .catch((error) => { throw error })
}

function* fetchKeyWordSearch(action) {
    try {
        const data = yield call(() => getApi(action.payload));
        yield put({ ...action, type: types.GET_SEARCH_SUCCESS, items: data });
    } catch (e) {
        yield put({ type: types.GET_SEARCH_FAILED, message: e.message });
    }
}

function* productsSaga() {
    yield takeEvery(types.GET_SEARCH_REQUESTED, fetchKeyWordSearch);
}

export default productsSaga;
