import React, {useState} from "react"
import "../../Myorders.scss"
import {Button, Divider, Grid, Space} from "antd-mobile";
import {StatusTag} from "components";
import ImageRenderer from "utils/ImageRenderer";
import {useHistory} from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import {changeImageOptimizeSize, ShowPriceWithCurrency} from "utils/helper";
import LargeImageViewer from "components/LargeImageViewer";
import {useMyOrders} from "context/my-order-context";
import {PRODUCT_DETAILS_TAB} from "constants/constants";
import { useLayoutContext } from "context/LayoutContext";

const MyOrderProductDetails = (props) => {
    const { localeCurrency, preloaderImage } = useLayoutContext();
    const history = useHistory()

    const {elements} = props

    const {
        myOrdersLoadings,
    } = useMyOrders()

    const [isImageView, setIsImageView] = useState(false);
    const isMyOrderPageLoading = myOrdersLoadings.isPageLoading

    const packageData = elements.products

    return (
        <>
            {
                packageData && packageData?.map((itemData, index) => {
                    const item = itemData
                    return (
                        <div
                            key={`itemOrderDetails${index}`}
                            onClick={() => history.push(`/account/orders/${elements.id}`)}
                            className="product_details_order"
                        >
                            <Grid columns={10} gap={8} className="order-product-details">
                                <Grid.Item span={2} onClick={() => setIsImageView(true)}>
                                    {
                                        !isMyOrderPageLoading ?
                                            <ImageRenderer
                                                key={item?.id}
                                                url={item?.image}
                                                thumb={item?.image}
                                                width={60}
                                                height={60}
                                                alt={`ProductImage${item?.id}`}
                                                borderRadius="8px"
                                                imageBackground={preloaderImage ?? "/assets/product/preloader-bg_62X62.png"}
                                            />
                                            :
                                            <Skeleton height={60} width={60}/>
                                    }
                                    <LargeImageViewer src={changeImageOptimizeSize(item?.image)} visible={isImageView}
                                                      setVisible={setIsImageView}/>
                                </Grid.Item>
                                <Grid.Item span={8}>
                                    <Grid columns={1} gap={0}>
                                        {
                                            !isMyOrderPageLoading ?
                                                <Grid.Item span={1} className="product_title">
                                                    <div className="product_title__title">
                                                        <span>{item?.title}</span>
                                                    </div>
                                                </Grid.Item>
                                                :
                                                <Skeleton count={1}/>
                                        }
                                    </Grid>
                                    <Grid columns={2} gap={0} className="product_meta">
                                        <Grid.Item span={2}>
                                            {
                                                !isMyOrderPageLoading ?
                                                    <div className="product_price">
                                                        <span>Price: {ShowPriceWithCurrency({price: item?.price, currency: localeCurrency})}</span>
                                                    </div>
                                                    :
                                                    <Skeleton count={1}/>
                                            }
                                        </Grid.Item>
                                        <Grid.Item span={2}>
                                            {
                                                !isMyOrderPageLoading ?
                                                    <Space block justify="between" align="center" className="product_status">
                                                        <div>Status: <StatusTag slug={item?.status} text={item?.status}/></div>
                                                        {
                                                            item?.status === "not_purchased" &&
                                                                <Button className="order_details_status" size="mini" color="primary" onClick={() => window.localStorage.setItem(PRODUCT_DETAILS_TAB, "action")}>Action</Button>
                                                        }
                                                    </Space>
                                                    :
                                                    <Skeleton count={1}/>
                                            }
                                        </Grid.Item>
                                        <Grid.Item span={2}>
                                            {
                                                elements?.pay_type === "cod" &&
                                                    <div style={{marginTop: '1%', color: "black"}}>Pay Type: {elements?.pay_type?.toUpperCase()}</div>
                                            }
                                        </Grid.Item>
                                    </Grid>
                                </Grid.Item>
                            </Grid>
                            <Divider/>
                        </div>
                    )
                })
            }
            {
                !isMyOrderPageLoading ?
                    <Space className="total_amount">
                        {
                            elements?.paid &&
                            <div>Paid: {elements?.paid},</div>
                        }
                        {
                            (elements?.due || elements?.due === 0) &&
                            <>
                                {
                                    parseFloat(elements.total) >= parseFloat(elements.paid) ?
                                        <div>Due: {elements?.due},</div>
                                        :
                                        <div>Overpaid: {-1 * elements?.due},</div>
                                }
                            </>
                        }
                        {
                            elements?.total &&
                            <div>Total: <span className="total_amount__total">{elements?.total}</span></div>
                        }
                    </Space>
                    :
                    <Skeleton count={1}/>
            }
        </>
    )
}

export default MyOrderProductDetails
