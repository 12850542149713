import React from "react";
import {
    GlobalHeaderBack,
    KeywordSearchSkeleton,
    PageHead
} from "components/index";
import {DemoBlock, ProductDetailsBlock} from "demos";
import {Card, Grid, Skeleton} from "antd-mobile";
import "./CampaignSkeleton.scss"

const CampaignDetailsSkeleton = () => {

    return (
        <>
            <PageHead title={`Campaign`}/>

            <GlobalHeaderBack title="Campaigns" height="60px" color="white"/>

            <ProductDetailsBlock background="linear-gradient(360deg, rgba(230,160,127,1) 0%, rgba(242,102,36,1) 65%)" padding="0 0 10% 0" marginBottom="10%">
                <div style={{margin: '0 3%'}}>
                    <Skeleton animated className="skeleton_image"/>
                </div>
                <div style={{margin: '2% 3%'}}>
                    <Skeleton animated className="skeleton_image"/>
                </div>
                <Grid columns={4} gap={8} className="category_campaign">
                    <Grid.Item>
                        <Skeleton animated className="skeleton_image_icon"/>
                    </Grid.Item>
                    <Grid.Item>
                        <Skeleton animated className="skeleton_image_icon"/>
                    </Grid.Item>
                    <Grid.Item className="category_campaign__item">
                        <Skeleton animated className="skeleton_image_icon"/>
                    </Grid.Item>
                    <Grid.Item className="category_campaign__item">
                        <Skeleton animated className="skeleton_image_icon"/>
                    </Grid.Item>
                </Grid>
                <DemoBlock margin="2% 3%" borderRadius="8px">
                    <Card
                        title={
                            <Skeleton.Paragraph lineCount={1} animated/>
                        }
                        style={{borderRadius: "16px"}}
                    >
                        <Grid columns={3} gap={12}>
                            <Grid.Item>
                                <Skeleton animated className="skeleton_product_image"/>
                            </Grid.Item>
                            <Grid.Item>
                                <Skeleton animated className="skeleton_product_image"/>
                            </Grid.Item>
                            <Grid.Item>
                                <Skeleton animated className="skeleton_product_image"/>
                            </Grid.Item>
                        </Grid>
                    </Card>
                </DemoBlock>
                <DemoBlock margin="2% 3%" borderRadius="8px">
                    <Card
                        title={
                            <Skeleton.Paragraph lineCount={1} animated/>
                        }
                        style={{borderRadius: "16px"}}
                    >
                        <Grid columns={3} gap={12}>
                            <Grid.Item>
                                <Skeleton animated className="skeleton_product_image"/>
                            </Grid.Item>
                            <Grid.Item>
                                <Skeleton animated className="skeleton_product_image"/>
                            </Grid.Item>
                            <Grid.Item>
                                <Skeleton animated className="skeleton_product_image"/>
                            </Grid.Item>
                        </Grid>
                    </Card>
                </DemoBlock>
                <DemoBlock margin="2% 3%" borderRadius="8px">
                    <Card
                        title={
                            <Skeleton.Paragraph lineCount={1} animated/>
                        }
                        style={{borderRadius: "16px"}}
                    >
                        <Grid columns={3} gap={12}>
                            <Grid.Item>
                                <Skeleton animated className="skeleton_product_image"/>
                            </Grid.Item>
                            <Grid.Item>
                                <Skeleton animated className="skeleton_product_image"/>
                            </Grid.Item>
                            <Grid.Item>
                                <Skeleton animated className="skeleton_product_image"/>
                            </Grid.Item>
                        </Grid>
                    </Card>
                </DemoBlock>

                <DemoBlock margin="5% 3% 3% 3%" background="#7D001E">
                    <Skeleton.Paragraph lineCount={2} animated/>
                </DemoBlock>

                <div style={{padding: '0 3%'}}>
                  <KeywordSearchSkeleton/>
                </div>
            </ProductDetailsBlock>

        </>
    );
}

export default CampaignDetailsSkeleton;
