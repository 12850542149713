import React from 'react'
import { useLocation } from 'react-router-dom';
import styles from './index.less'

export const HomePageBlock = (props) => {
  const routeLocation = useLocation();

  let style = {
    padding: props.padding,
    border: props.border,
    borderRadius: props.borderRadius,
    marginTop: props.marginTop,
    marginBottom: props.marginBottom,
  };
  if (props?.style) {
    style = {
      ...style,
      ...props.style,
    }
  }
  else if (props?.backgroundImage) {
    style = {
      ...style,
      backgroundImage: `url(${process.env.PUBLIC_URL + props.backgroundImage})`,
      backgroundRepeat: props.backgroundRepeat,
      backgroundSize: props.backgroundSize,
      backgroundColor: props.backgroundColor || '#ffffff',
      backgroundBlendMode: props.backgroundBlendMode,
    }
  } else if (routeLocation?.pathname !== '/') {
    style['background'] = props.background;
  }
  return (
    <div className={styles.demoBlock}>
      <div
        className={styles.content}
        style={style}
      >
        {props.children}
      </div>
    </div>
  )
}

HomePageBlock.defaultProps = {
  padding: '2px 12px',
  background: '#ffffff',
}
