import React, {useEffect} from "react"
import {GlobalHeaderBack, PageHead} from "components";
import {authActing} from "services/auth-client";
import {ErrorBlock, Toast} from "antd-mobile";
import {useHistory, useParams} from "react-router-dom";
import {useAuth} from "context/auth-context";

const LoginWithToken = () => {
  const history = useHistory();
  const { getProfileData } = useAuth();

  let {token} = useParams();

  useEffect(() => {
    if (token) {
      authActing(token)
        .then(res => {
          if (res.data?.token) {
            getProfileData(res.data?.token);
            history.push('/account/orders')
          }
        })
        .catch(err => {
          if (err?.response?.data?.message) {
            Toast.show({
              icon: "fail",
              content: err?.response?.data?.message,
            })
          } else {
            Toast.show({
              icon: "fail",
              content: "failed!",
            })
          }
        })
    }
  }, [getProfileData, token, history])


    return (
        <>
            <PageHead title={`Login With Token`}/>

            <GlobalHeaderBack title="" height="60px" color="white" />
            <ErrorBlock fullPage status='busy' title="Wait for login" description="Please wait some sec for login and you will go to order page"/>

        </>
    );
}
export default LoginWithToken
