import React, {useEffect, useState} from "react"
import './ProfileDetails.scss'
import {Button, List, NavBar, Popup, Toast} from "antd-mobile";
import {
    BankcardOutline, ClockCircleOutline,
    EnvironmentOutline,
    ExclamationShieldOutline,
    FaceRecognitionOutline,
    LockOutline,
    MailOutline, PayCircleOutline,
    PhoneFill, TeamOutline,
    UserCircleOutline
} from "antd-mobile-icons";
import {useHistory} from "react-router-dom";
import ProfileName from "./ProfileName";
import PhoneNumber from "./PhoneNumber";
import EmailAddress from "./EmailAddress";
import Gender from "./Gender";
import BirthDay from "./BirthDay";
import RefundNumber from "./RefundNumber";
import ChangePassword from "./ChangePassword";
import {ArrowLeftOutlined} from "@ant-design/icons";
import SettingUpdate from "./SettingUpdate";
import axios from "axios";
import {getAllSetting} from "services/auth-client";
import {getCountries, getCurrencies, getLanguages} from "services/settings-client";
import {config , LOCAL_STORAGE_KEY, LOCAL_STORAGE_USER} from "constants/constants";
import {LoadingState, PageHead} from "components";
import {ProductDetailsBlock} from "demos";
import {capitalizeFirstLetter} from "utils/helper";
import instance from "services/api-client";


const ProfileDetails = () => {

    const history = useHistory()

    const [isViewName, setIsViewName] = useState(false);
    const [isViewPhoneNumber, setIsViewPhoneNumber] = useState(false);
    const [isViewEmailAddress, setIsViewEmailAddress] = useState(false);
    const [isViewGender, setIsViewGender] = useState(false);
    const [isViewBirthDay, setIsViewBirthDay] = useState(false);
    const [isViewRefundMethod, setIsViewRefundMethod] = useState(false);
    const [changePassword, setChangePassword] = useState(false);

    //Setting Data
    const [settingData, setSettingData] = useState(null);
    const [isSettingLoading, setIsSettingLoading] = useState(false);

    //Data
    const [languageData, setLanguageData] = useState(null);
    const [countryData, setCountryData] = useState(null);
    const [currencyData, setCurrencyData] = useState(null);

    //PopUp View
    const [isLanguageView, setIsLanguageView] = useState(false);
    const [countryView, setCountryView] = useState(false);
    const [isCurrencyView, setIsCurrencyView] = useState(false);
    const [userData, setUserData] = useState(null);
    const [isFirstCall, setIsFirstCall] = useState(true);
    const [isLogInLoading, setIsLogInLoading] = useState(false);

  // const profileInfo = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER))

    const logout = () => {
      setIsLogInLoading(true)
      instance.get('/auth/logout')
        .then(() => {
          setIsLogInLoading(false)
          window.localStorage.removeItem(LOCAL_STORAGE_KEY)
          window.localStorage.removeItem(LOCAL_STORAGE_USER)
          window.location.href = '/'
        })
        .catch(err => {
          if (err?.response?.data?.message) {
            Toast.show({
              icon: 'fail',
              content: err?.response?.data?.message,
            })
          } else {
            Toast.show({
              icon: 'fail',
              content: 'Quantity update failed',
            })
          }
          setIsLogInLoading(false)
        })
    }

    const fetchSetting = React.useCallback(
        () => {
            setIsSettingLoading(true)
            getAllSetting()
                .then(res => {
                    setIsSettingLoading(false)
                    setSettingData(res.data.settings)
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        window.location.href = "/auth/login"
                    }
                    setIsSettingLoading(false)
                })
        },
        [],
    )

    const fetchLanguage = React.useCallback(
        () => {
            getLanguages()
                .then(res => {
                    setLanguageData(res.data.languages)
                })
                .catch(err => {

                })
        },
        [],
    )

    const fetchCountry = React.useCallback(
        () => {
            getCountries()
                .then(res => {
                    setCountryData(res.data)
                })
                .catch(err => {

                })
        },
        [],
    )

    const fetchCurrencies = React.useCallback(
        () => {
            getCurrencies()
                .then(res => {
                    setCurrencyData(res.data.currencies)
                })
                .catch(err => {

                })
        },
        [],
    )

    const fetchUserData = React.useCallback(
        () => {
            const date = new Date();
            date.setDate(date.getDate() + 365);
            axios.get(`${config.url.CONSUMER_API}/account/me`, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem(LOCAL_STORAGE_KEY),
                },
            })
                .then(acc => {
                    const profileData = acc?.data?.profile
                    setUserData(profileData)
                    window.localStorage.setItem(LOCAL_STORAGE_USER, JSON.stringify(profileData))
                })
                .catch(error => {
                })
        },
        [],
    )

    useEffect(() => {
        if (isFirstCall){
            fetchUserData()
            setIsFirstCall(false)
        }
    }, [fetchUserData, isFirstCall, setIsFirstCall])


    useEffect(() => {
        fetchSetting()
        fetchLanguage()
        fetchCountry()
        fetchCurrencies()
    }, [fetchLanguage, fetchSetting, fetchCountry, fetchCurrencies]);

    const findLanguage = languageData && languageData?.find(el => el?.code === settingData?.language)
    const findCountry = countryData && countryData?.countries?.find(el => el?.flag === settingData?.country)
    const findCurrency = currencyData && currencyData?.find(el => el?.currency === settingData?.code)

    const right = (
        <span className="profile_image">
            <UserCircleOutline/>
        </span>
    )

    const back = () => history.goBack()

    let name = null

    if (userData?.first_name) {
        name = userData?.first_name + " " + userData?.last_name
    }

    const backArrow = (
        <span className="global_right_icon"><ArrowLeftOutlined/></span>
    )

    if (isSettingLoading) return (
        <LoadingState/>
    )

    return (
        <>
            <PageHead title={`Profile`}/>

            <div className="profile_details">
                <NavBar right={right} onBack={back} backArrow={backArrow}>
                    My Profile
                </NavBar>
            </div>

            <ProductDetailsBlock marginTop="10px">
                <div className="profile_list_item">
                    <List>
                        <List.Item extra={name}>
                            <span><UserCircleOutline/> Name</span>
                        </List.Item>
                        <List.Item extra={userData?.mobile} onClick={() => setIsViewPhoneNumber(true)}>
                            <span>< PhoneFill/> Mobile Number</span>
                        </List.Item>
                        <List.Item extra={userData?.email} onClick={() => setIsViewEmailAddress(true)}>
                            <span>< MailOutline/> Email Address</span>
                        </List.Item>
                        <List.Item onClick={() => setChangePassword(true)}>
                            <span>< LockOutline/> Change Password</span>
                        </ List.Item>
                    </List>
                </div>
            </ProductDetailsBlock>

            <ProductDetailsBlock marginTop="10px">
                <div className="profile_list_item">
                    <List>
                        <List.Item extra={userData?.gender && capitalizeFirstLetter(userData?.gender)}
                                   onClick={() => setIsViewGender(true)}>
                            <span><TeamOutline/> Gender</span>
                        </List.Item>
                        <List.Item extra={userData?.dob} onClick={() => setIsViewBirthDay(true)}>
                            <span>< FaceRecognitionOutline/> Birthday </span>
                        </List.Item>
                    </List>
                </div>
            </ProductDetailsBlock>

            <ProductDetailsBlock marginTop="10px">
                <div className="profile_list_item">
                    <List>
                        <List.Item onClick={() => history.push('/account/wallet')}>
                            <span><ExclamationShieldOutline/> Refund Method</span>
                        </List.Item>
                        <List.Item onClick={() => history.push('/profile/shipping-address')}>
                            <span>< EnvironmentOutline/> Shipping Address </span>
                        </List.Item>
                    </List>
                </div>
            </ProductDetailsBlock>

            <ProductDetailsBlock marginTop="10px" marginBottom="2%">
                <div className="profile_list_item">
                    <List>
                        <List.Item extra={findLanguage?.title} onClick={() => setIsLanguageView(true)}>
                            <span><BankcardOutline/> Language </span>
                        </List.Item>
                        <List.Item extra={findCountry?.name} onClick={() => setCountryView(true)}>
                            <span><EnvironmentOutline/> Country </span>
                        </List.Item>
                        <List.Item extra={findCurrency?.code} onClick={() => setIsCurrencyView(true)}>
                            <span><PayCircleOutline/> Currency </span>
                        </List.Item>
                        <List.Item extra={settingData?.timezone}>
                            <span><ClockCircleOutline/> Timezone </span>
                        </List.Item>
                    </List>
                </div>
            </ProductDetailsBlock>
            <div style={{margin: "0 3% 20% 3%"}}>
                <Button block color="primary" onClick={() => logout()} loading={isLogInLoading}>Logout</Button>
            </div>

            <Popup
                visible={isViewName}
                onMaskClick={() => {
                    setIsViewName(false)
                }}
                bodyStyle={{
                    borderTopLeftRadius: '8px',
                    borderTopRightRadius: '8px',
                    minHeight: '25vh'
                }}
            >
                <ProfileName setIsViewName={setIsViewName}/>
            </Popup>

            <Popup
                visible={isViewPhoneNumber}
                onMaskClick={() => {
                    setIsViewPhoneNumber(false)
                }}
                bodyStyle={{
                    borderTopLeftRadius: '8px',
                    borderTopRightRadius: '8px',
                    minHeight: '25vh'
                }}
            >
                <PhoneNumber setIsViewPhoneNumber={setIsViewPhoneNumber} userData={userData}/>
            </Popup>

            <Popup
                visible={isViewEmailAddress}
                onMaskClick={() => {
                    setIsViewEmailAddress(false)
                }}
                bodyStyle={{
                    borderTopLeftRadius: '8px',
                    borderTopRightRadius: '8px',
                    minHeight: '25vh'
                }}
            >
                <EmailAddress setIsViewEmailAddress={setIsViewEmailAddress} userData={userData}/>
            </Popup>

            <Popup
                visible={isViewGender}
                onMaskClick={() => {
                    setIsViewGender(false)
                }}
                bodyStyle={{
                    borderTopLeftRadius: '8px',
                    borderTopRightRadius: '8px',
                    minHeight: '25vh'
                }}
            >
                <Gender setIsViewGender={setIsViewGender} userData={userData}/>
            </Popup>

            <Popup
                visible={isViewBirthDay}
                onMaskClick={() => {
                    setIsViewBirthDay(false)
                }}
                bodyStyle={{
                    borderTopLeftRadius: '8px',
                    borderTopRightRadius: '8px',
                    minHeight: '25vh'
                }}
            >
                <BirthDay setIsViewBirthDay={setIsViewBirthDay} userData={userData}/>
            </Popup>

            <Popup
                visible={isViewRefundMethod}
                onMaskClick={() => {
                    setIsViewRefundMethod(false)
                }}
                bodyStyle={{
                    borderTopLeftRadius: '8px',
                    borderTopRightRadius: '8px',
                    minHeight: '25vh'
                }}
            >
                <RefundNumber setIsViewRefundMethod={setIsViewRefundMethod}/>
            </Popup>

            <Popup
                visible={changePassword}
                onMaskClick={() => {
                    setChangePassword(false)
                }}
                bodyStyle={{
                    borderTopLeftRadius: '8px',
                    borderTopRightRadius: '8px',
                    minHeight: '35vh'
                }}
            >
                <ChangePassword setChangePassword={setChangePassword}/>
            </Popup>

            <Popup
                visible={isLanguageView}
                onMaskClick={() => {
                    setIsLanguageView(false)
                }}
                bodyStyle={{
                    borderTopLeftRadius: '8px',
                    borderTopRightRadius: '8px',
                    minHeight: '25vh'
                }}
            >
                <SettingUpdate
                    setIsLanguageViewFouce={setIsLanguageView}
                    languageData={languageData}
                    fetchSetting={fetchSetting}
                    isLanguageViewFouce={isLanguageView}
                />
            </Popup>

            <Popup
                visible={countryView}
                onMaskClick={() => {
                    setCountryView(false)
                }}
                bodyStyle={{
                    borderTopLeftRadius: '8px',
                    borderTopRightRadius: '8px',
                    minHeight: '25vh'
                }}
            >
                <SettingUpdate
                    setCountryView={setCountryView}
                    countryData={countryData}
                    fetchSetting={fetchSetting}
                    countryView={countryView}
                />
            </Popup>

            <Popup
                visible={isCurrencyView}
                onMaskClick={() => {
                    setIsCurrencyView(false)
                }}
                bodyStyle={{
                    borderTopLeftRadius: '8px',
                    borderTopRightRadius: '8px',
                    minHeight: '25vh'
                }}
            >
                <SettingUpdate
                    setIsCurrencyView={setIsCurrencyView}
                    currencyData={currencyData}
                    fetchSetting={fetchSetting}
                    isCurrencyView={isCurrencyView}
                />
            </Popup>
        </>
    );
}
export default ProfileDetails
