import { call, put, takeEvery } from 'redux-saga/effects'
import * as types from 'app/actionTypes/internalProducts';
import {getInternalProductDetails} from "services/product-client";

const getApi = async ({id, filters = {}}) => {
    return getInternalProductDetails(id, filters)
    .then(res => res.data)
    .catch((error) => { throw error })
}

function* fetchInternalProducts(action) {
    try {
        const data = yield call(() => getApi(action.payload));
        yield put({ type: types.GET_INTERNAL_SEARCH_SUCCESS, data: data });
    } catch (e) {
        yield put({ type: types.GET_INTERNAL_SEARCH_FAILED, message: e.message });
    }
}

function* internalProductsSaga() {
    yield takeEvery(types.GET_INTERNAL_SEARCH_REQUESTED, fetchInternalProducts);
}

export default internalProductsSaga;
