import React, { useEffect, useState } from "react"
import { GlobalHeaderBack, PageHead, StatusTag, ProductFullDetailsSkeleton } from "components";
import { DemoBlock } from "demos";
import { getProductDetails } from "services/my-order-client";
import { useHistory, useParams } from "react-router-dom";
import { Divider, Grid, Toast } from "antd-mobile";
import {
  calculateShippingCharges,
  changeImageOptimizeSize,
  getShippingRate,
  ShowPriceWithCurrency
} from "utils/helper";
import ImageRenderer from "utils/ImageRenderer";
import LargeImageViewer from "components/LargeImageViewer";
import { CheckOutlined, CopyOutlined } from "@ant-design/icons";
import ProductIssue from "pages/MyOrders/components/ProductIssue";
import '../../MyOrders/Myorders.scss'
import { useLayoutContext } from "context/LayoutContext";

const IssueDetails = () => {
  const { localeCurrency, defaultShippingUnit } = useLayoutContext();
  let { id } = useParams();
  const history = useHistory();
  const [isLogDetailsLoading, setIsLogDetailsLoading] = useState(false);
  const [logDetails, setLogDetails] = useState(null);
  const [isImageView, setIsImageView] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [selectedTracking, setSelectedTracking] = useState(null);

  const fetchProductDetails = React.useCallback(
    () => {
      setIsLogDetailsLoading(true)
      getProductDetails(id)
        .then(res => {
          setLogDetails(res.data.data)
          setIsLogDetailsLoading(false)
        })
        .catch(err => {
          setIsLogDetailsLoading(false)
        })
    },
    [id],
  )


  useEffect(() => {
    fetchProductDetails()
  }, [fetchProductDetails]);

  if (isLogDetailsLoading) {
    return (
      <ProductFullDetailsSkeleton />
    )
  }

  const handleCopy = (value) => {
    Toast.show({
      content: "Copied!"
    })
    setIsCopied(true)
    navigator.clipboard.writeText(value)
    setSelectedTracking(value)
  }

  let item = logDetails;
  let itemShippingRate = parseFloat(item?.extras?.shipping?.fee)
  if (item?.freight === "mvn_ship_for_me") {
    let isWholesaleProduct = item.order_type === 'wholesale'
    let approxQty = item?.quantity;
    let approxWeight = (item?.extras?.weight?.value || 0) * approxQty;
    itemShippingRate = getShippingRate({ selectedFrCat: item?.shipping_category, approxWeight, isWholesaleProduct });
    itemShippingRate = parseFloat(itemShippingRate)
  }

  return (
    <>
      <PageHead title={`Product Details`} />

      <GlobalHeaderBack title="Issue Details" height="60px" color="white" />

      {
        logDetails &&
        <DemoBlock padding="2px 0" margin="2% 3%" borderRadius="8px">
          <div className="product_all_details" style={{ backgroundColor: 'white' }}>
            <Grid columns={10} gap={8} className="product_details_with_order" style={{ marginBottom: '6%' }}>
              <Grid.Item span={2} onClick={() => setIsImageView(true)}>
                <ImageRenderer
                  key={item.id}
                  url={item?.image}
                  thumb={item?.image}
                  width={60}
                  height={60}
                  alt={`ProductItemImage${item?.id}`}
                  borderRadius="8px"
                />
              </Grid.Item>
              <Grid.Item span={8}>
                <Grid columns={1} gap={0}>
                  <Grid.Item span={1} className="product_title">
                    <div className="product_title__title">
                      <span>{item.title}</span>
                    </div>
                  </Grid.Item>
                </Grid>
                <Grid columns={1} gap={2} className="product_meta">
                  <Grid.Item>
                    <div className="product_meta_grid">
                      <div>
                        Price: <span
                          className="product_qty_price">{ShowPriceWithCurrency({ price: item?.price, currency: localeCurrency })}</span>
                      </div>
                      <div>
                        Quantity: <span
                          className="product_qty_price">{item.quantity}</span>
                      </div>
                    </div>
                  </Grid.Item>
                  <Grid.Item>
                    {
                      item?.order?.pay_type === "cod" &&
                      <div>Pay Type: {item?.order?.pay_type?.toUpperCase()}</div>
                    }
                  </Grid.Item>
                  <Grid.Item>
                    <div className="product_status_full">
                      <div>Status: <StatusTag slug={item.status}
                        text={item.status} /></div>
                    </div>
                  </Grid.Item>
                  <Grid.Item>
                    {
                      item?.shipping_category &&
                      <span className="shipping_charges_order">
                        {item?.shipping_category?.name}: {calculateShippingCharges({ shipping: item?.shipping_category, rate: itemShippingRate, currency: localeCurrency, defaultShippingUnit, shopId: item?.shop_id })}
                      </span>
                    }
                  </Grid.Item>
                  {
                    item?.tracking_code &&
                    <Grid.Item className="product_tracking">
                      <span style={{ cursor: "pointer" }}
                        onClick={() => history.push(`/tracking?code=${item?.tracking_code}`)}>Tracking: {item?.tracking_code}</span>
                      {
                        isCopied && selectedTracking === item?.tracking_code ?
                          <span
                            style={{ marginLeft: "2%" }}><CheckOutlined /></span>
                          :
                          <span style={{ marginLeft: "2%" }}
                            onClick={() => handleCopy(item.tracking_code)}><CopyOutlined /></span>
                      }
                    </Grid.Item>
                  }
                </Grid>
              </Grid.Item>
            </Grid>
            <Divider />
            <ProductIssue productId={item.id} item={item} fromIssueDetails={true} />
            <LargeImageViewer src={changeImageOptimizeSize(item?.image)}
              visible={isImageView} setVisible={setIsImageView} />
          </div>

        </DemoBlock>
      }
    </>
  )
}

export default IssueDetails
