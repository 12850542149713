import * as Types from 'app/actionTypes/productDetailsData';
import produce from "immer";

const initialState = {
  productReviews: null,
  isProductReviewsLoading: true,
  productDescription: null,
  isProductDescriptionLoading: true,
}

export default function productsDetailsData(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.GET_PRODUCT_REVIEW_REQUESTED:
        draft.isProductReviewsLoading = true
        break
      case Types.GET_PRODUCT_REVIEW_SUCCESS:
        draft.productReviews = action.data
        draft.isProductReviewsLoading = false
        break
      case Types.GET_PRODUCT_REVIEW_FAILED:
        draft.isProductReviewsLoading = false
        break
      case Types.GET_PRODUCT_DESCRIPTION_REQUESTED:
        draft.isProductDescriptionLoading = true
        break
      case Types.GET_PRODUCT_DESCRIPTION_SUCCESS:
        draft.productDescription = action.data
        draft.isProductDescriptionLoading = false
        break
      case Types.GET_PRODUCT_DESCRIPTION_FAILED:
        draft.isProductDescriptionLoading = false
        break
      default:
        return state
    }
  })
}
