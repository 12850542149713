import React, {useEffect} from 'react';
import '../HomePage/homepage.scss'
import { PageHead } from "../../components";
import SearchBarHome from "pages/HomePage/components/SearchBarHome";
import HomePageBuilder from './HomePageBuilder';
import { useLayoutContext } from 'context/LayoutContext';
import SpecialCampaignBlock from 'components/SpecialCampaignBlock/SpecialCampaignBlock';
import SliderWidget from './components/SliderWidget';
import CountriesSection from './CountriesSection/CountriesSection';
import ReactGA from "react-ga4";

const HomePageCraft = () => {
  const { specialCampaignSection, heroSlides, countriesSection } = useLayoutContext();
  let showSpecialCamp = specialCampaignSection?.special_offer?.label?.toLowerCase() === 'on';

  useEffect(()=>{
    ReactGA.event({
      category: "page view",
      action: "home_page_view",
    });
  },[])

  return (
    <>
      <PageHead />
      {/* <NoticeBar style={{"--font-size": '13px', "--height": "44px"}} content={NOTICES} color='alert' closeable /> */}
      <SearchBarHome />
      <SliderWidget slides={heroSlides} />
      {
        countriesSection?.visible?.toLowerCase() === 'on' &&
        <CountriesSection countriesSection={countriesSection} />
      }
      {
        showSpecialCamp &&
        <SpecialCampaignBlock
          campaignQuery={specialCampaignSection?.special_offer}
          specialCamp={specialCampaignSection}
        />
      }
      <HomePageBuilder />
    </>
  );
}

export default HomePageCraft;
