import React, { useEffect, useState } from "react"
import "../../Myorders.scss"
import { Button, Collapse, Divider, Grid, Space, } from "antd-mobile";
import { RightOutlined } from "@ant-design/icons";
import DeliverableProductDetails from "../DeliverableProductDetails/DeliverableProductDetails";
import { useMyOrders } from "context/my-order-context";
import { SkeletonOrder } from "components";
import { formatDate } from "utils/helper";
import Skeleton from "react-loading-skeleton";
import { useHistory } from "react-router-dom";

const initCheck = (order) => {
  let checked = [];
  order?.products?.forEach(product => {
    checked.push({
      id: product.id,
      isChecked: false,
      orderID: order?.id,
      product: product,
    });
  })
  return checked;
}

const ToDeliverable = (props) => {

  const history = useHistory();

  const { elements, index, isToDeliverablePageLoading } = props

  const { selectedProducts, setSelectedProducts } = useMyOrders()

  const [selectedProductsCount, setSelectedProductsCount] = useState(0);

  const [checkbox, setCheckbox] = useState([]);

  useEffect(() => {
    setCheckbox(initCheck(elements));
  }, [elements])

  const removeFromSelected = ({ orderID, id: productID }) => {
    let orders = selectedProducts
    let isOrder = orders && orders?.find(item => item.id === orderID);
    if (isOrder) {
      let productIdx = isOrder?.products?.findIndex(item => item?.id === productID)
      if (productIdx >= 0) {
        let { products } = isOrder;
        products?.splice(productIdx, 1)
        Object.assign(orders, isOrder);
        setSelectedProductsCount(selectedProductsCount - 1)
      }
      if (isOrder?.products?.length < 1) {
        let orderIdx = orders?.findIndex(item => item.id === orderID);
        orders.splice(orderIdx, 1);
      }
    }
    setSelectedProducts(orders)
  };

  const addToSelected = ({ orderID, id: productID, product }) => {
    let orders = selectedProducts;
    let isOrder = orders.find(item => item.id === orderID);
    if (isOrder) {
      let isProduct = isOrder?.products?.find(item => item?.id === productID)
      if (!isProduct) {
        let { products } = isOrder;
        products.push(product);
        Object.assign(orders, isOrder);
        setSelectedProductsCount(selectedProductsCount + 1)
      }
    } else {
      orders.push({
        id: orderID,
        products: [product],
      })
    }
    setSelectedProducts(orders);
  };

  const totalChecked = () => {
    let cnt = 0;
    checkbox && checkbox?.forEach(item => {
      if (item.isChecked) {
        cnt++;
      }
    })
    return cnt;
  }

  const handleAllChecked = (event) => {
    checkbox && checkbox?.forEach(item => {
      item.isChecked = event.target.checked;
      if (item.isChecked) {
        addToSelected(item);
      } else {
        removeFromSelected(item);
      }
    });
    setCheckbox([...checkbox]);
  };

  const isChecked = (product) => {
    let isChecked = false;
    checkbox && checkbox?.forEach(item => {
      if (item.orderID === elements?.id && item?.id === product?.id) {
        isChecked = item.isChecked;
      }
    });
    return isChecked;
  };

  const handleCheckedSingle = (event) => {
    checkbox && checkbox?.forEach(item => {
      if (item.id === parseInt(event.target.value)) {
        item.isChecked = event.target.checked;
      }
      if (item.isChecked) {
        addToSelected(item);
      } else {
        removeFromSelected(item);
      }
    });
    setCheckbox([...checkbox]);
  }

  const orderTitle = <Grid columns={20} gap={4} className="order_deliverable_title">
    <Grid.Item span={2} className="order_checkbox">
      <input type="checkbox"
        id={`selectAll`}
        className="text-primary focus:ring-primary"
        checked={totalChecked() === elements?.products?.length}
        onChange={handleAllChecked}
        disabled
        key={`orderCheckBox${index}`}
      />
    </Grid.Item>
    <Grid.Item span={12}>
      <Space direction="vertical" style={{ "--gap": "2" }}>
        <span className="all-orders__order-text">Order #{elements?.id} <RightOutlined /></span>
        <div>
          {elements.products?.[0]?.order_type ? <span style={{ color: "var(--adm-color-primary)" }}>{elements.products?.[0]?.order_type} </span> : null}<span className="all-orders__order-date">{formatDate(elements?.created_at)}</span>
        </div>
      </Space>
    </Grid.Item>
    <Grid.Item span={6} className="payment-status">
      {
        (parseFloat(elements?.due) > 0 && elements?.pay_type !== "cod") &&
        <Grid.Item span={2} style={{ textAlign: 'right' }}>
          <Button size="mini" color="danger" onClick={() => history.push(`/payment/${elements.id}?PT=online-full&GWI=10`)}> Pay Now</Button>
        </Grid.Item>
      }
      {/*<Space direction="vertical">*/}
      {/*    <StatusTag text={elements?.status} slug={elements?.status}/>*/}
      {/*</Space>*/}
    </Grid.Item>
  </Grid>

  let filteredProducts = elements?.products?.filter(item => item.status === 'received_in_country');

  return (
    <div className="all-orders">
      {
        !isToDeliverablePageLoading ?
          <Collapse defaultActiveKey={["0", "1", "2", "3", "4", "5"]}>
            <Collapse.Panel key={index} title={orderTitle} onClick={(e) => e.stopPropagation()}>
              {
                elements?.products?.length > 0 && filteredProducts?.map((products, index) => {
                  return (
                    <div key={`deliverP${index}`}>
                      <DeliverableProductDetails
                        products={products}
                        isChecked={isChecked}
                        handleCheckedSingle={handleCheckedSingle}
                        elements={elements}
                        indexPackage={index}
                        totalChecked={totalChecked}
                        handleAllChecked={handleAllChecked}
                      />
                    </div>
                  )
                })
              }
              <Divider />
              {
                elements ?
                  <Space className="total_amount">
                    {
                      elements?.paid &&
                      <div>Paid: {elements?.paid},</div>
                    }
                    {
                      elements?.due &&
                      <div>Due: {elements?.due},</div>
                    }
                    {
                      elements?.total &&
                      <div>Total: <span
                        className="total_amount__total">{elements?.total}</span>
                      </div>
                    }
                  </Space>
                  :
                  <Skeleton count={1} />
              }
            </Collapse.Panel>
          </Collapse>
          :
          <SkeletonOrder />
      }
    </div>
  )
}

export default ToDeliverable
