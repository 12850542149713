import React from 'react';
import { Grid, Rate, Space } from "antd-mobile";
import './SearchProductDetails.scss'
import { useHistory } from "react-router-dom";
import ImageRenderer from "utils/ImageRenderer";
import { calculateDiscount, changeImageOptimizeSize, RenderNemonicBadge, findRequestAbleStore } from "utils/helper";
import ProductPrice from 'components/ProductPrice/ProductPrice';
import { useLayoutContext } from 'context/LayoutContext';

const SearchProductDetails = (props) => {
  const { preloaderImage } = useLayoutContext();
  const history = useHistory()

  const { product } = props

  let rating = 0

  if (product?.ratings_average) {
    rating = product?.ratings_average
  } else if (product?.rating) {
    rating = product?.rating
  }

  let ratings_count = 0

  if (product?.ratings_count) {
    ratings_count = product?.ratings_count
  } else if (product?.rating_count) {
    ratings_count = product?.rating_count
  }

  let productPrice = product?.price?.original?.min
  let productDiscountPrice = product?.price?.discount?.min

  if (product?.price?.min) {
    productPrice = product?.price?.min
  }

  if (product?.discount_price?.min) {
    productDiscountPrice = product?.discount_price?.min
  }

  let pathName = "/products/details"

  if (findRequestAbleStore(parseInt(product?.shop_id))) {
    pathName = "/manual-requests"
  }

  let searchParams = `?url=${encodeURIComponent(product?.link)}`

  if (product?.type === 'campaign' || product?.pivot?.campaign_id) {
    searchParams = `?ipid=${product.id}&campaign=${product?.pivot?.campaign_id}&campaign_product=${product?.pivot?.id}`
  } else if (product?.product_code) {
    let productSlug = product?.slug || product?.title;
    pathName = `/products/${productSlug}/${product.product_code}`
    searchParams = null;
  } else if (product?.type) {
    searchParams = `?id=${product.id}`
  }

  let showNemonicBadge = false;
  // if ((product?.pivot?.nemonic_badge || product?.pivot?.campaign_id) || (product?.link?.includes('taobao') && (parseFloat(product?.discount_price?.min) > 0))) {
  if (product?.pivot?.nemonic_badge) {
    showNemonicBadge = true;
  }


  return (
    <div onClick={() => history.push({
      pathname: pathName,
      search: searchParams,
      state: {
        title: product.title,
        price: productDiscountPrice ? productDiscountPrice : productPrice,
        image: product?.image
      }
    })}>
      <Space direction='vertical' className="product_search_just_for_you product-search-grid-item" style={{ '--gap': '4px' }}>
        <div className="product_search_image">
          <div className="product_search_image__thumb">
            <ImageRenderer
              key={product?.id}
              url={changeImageOptimizeSize(product?.image)}
              thumb={changeImageOptimizeSize(product?.image)}
              width={240}
              height={240}
              alt={`ProductImage${product?.id}`}
              imageBackground={preloaderImage ?? "/assets/product/preloader-bg_184X184.png"}
            />
            {/*if any thing change needed then this image will be used don't remove it */}
            {/*<Image src={product?.image} fit='fill' className="product_search_image__thumb__image"/>*/}
            {
              productDiscountPrice ?
                <div className="product_search_image__thumb__status-top">
                  <span>{calculateDiscount(productPrice, productDiscountPrice)}%</span>
                </div>
                :
                <div className="product_search_image__thumb__status-top">
                  <span>New</span>
                </div>
            }
            {/*<div className="product_search_image__thumb__wish">*/}
            {/*    <button><HeartFilled/></button>*/}
            {/*</div>*/}
            {
              showNemonicBadge &&
              <RenderNemonicBadge
                showNemonicBadge={showNemonicBadge}
                img={product?.pivot?.nemonic_badge}
              />
            }
          </div>
        </div>
        <div className="product_search_title extra">
          <span>{product?.title}</span>
        </div>
        <div className="product_search_price">
          <Grid columns={1} gap={4}>
            <Grid.Item>
              <ProductPrice
                originalPrice={productPrice}
                discountPrice={productDiscountPrice}
              />
            </Grid.Item>
            {
              product?.sold &&
              <Grid.Item>
                <span className="product_keyword_search_price__sold">Sold: {product?.sold}</span>
              </Grid.Item>
            }
          </Grid>
        </div>
        {
          rating !== 0 &&
          <div className="product_search_rating">
            <span><Rate readonly value={parseFloat(rating)} /> ({ratings_count})</span>
          </div>
        }
      </Space>
    </div>
  );
}

export default SearchProductDetails;
