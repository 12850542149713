import {processPayment} from "services/payments";
import qs from "qs";

const usePayments = (props) => {
  const { handleBkashResponse } = props
  const params = qs.parse((window.location.search).substring(1))
  /**
   * Handle bkash checkout
   * Load jQuery script first and then load bkash script and active script
   *
   * @param {object} res response from api
   * @returns {object}
   */
  const handleBkashCheckout = (res) => {
    if (typeof jQuery === 'undefined') {
      let jQueryScript = document.createElement('script');
      jQueryScript.src = res.data.jQuery;
      document.head.appendChild(jQueryScript);

      jQueryScript.onload = function () {
        window.$('body').append(
          window.$('<button></button>')
            .attr('id', 'bKash_button')
            .attr('disabled', 'disabled')
            .css({
              display: 'none',
            }),
        );
        
        window.$.getScript(res.data.script)
          .done(function () {
            /*global bKash*/
            /*eslint no-undef: "error"*/
            bKash.init({
              paymentMode: 'checkout',
              paymentRequest: {
                amount: res.data.token.amount,
                intent: res.data.token.intent,
              },
              createRequest: function () {
                bKash.create().onSuccess(res.data.token);
              },

              executeRequestOnAuthorization: function () {
                bKash.execute().onError();
                let data = {
                  gateway_id: params.GWI ? params.GWI : res?.gateway_id,
                  invoice_id: res?.invoice_id,
                  invoice_type: res?.invoice_type,
                  paymentID: res.data.token.paymentID,
                }
                bkashCheckoutExecuteHandle(data);
              },

              onClose: function () {
                window.location.reload();
              },
            });

            window.$('#bKash_button').click();
          });
      };
    } else {
      window.$('#bKash_button').click();
    }
  }

  const bkashCheckoutExecuteHandle = (data) => {
    processPayment(data)
      .then(res => {
        handleBkashResponse({ status: 'success', data: res.data })
      })
      .catch(err => {
        handleBkashResponse({ status: 'error', data: err.response.data })
      })
  }

  const handleExtarnalPayment = (url) => {
    window.location.href = url;
  }

  return {
    handleBkashCheckout,
    handleExtarnalPayment,
  }
}

export default usePayments
