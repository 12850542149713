import { useLayoutContext } from "context/LayoutContext";
import { useEffect, useState } from "react";
import { getStatesByCountryCode } from "services/settings-client";

const usePhone = () => {
  const { localeCountry } = useLayoutContext();
  const [selectedCountry, setSelectedCountry] = useState(localeCountry)
  const [states, setStates] = useState([]);
  const [countryPhoneStates, setCountryPhoneStates] = useState([]);
  const [selectedState, setSelectedState] = useState(null)

  useEffect(() => {
    if (selectedCountry?.code) {
      getStatesByCountryCode(selectedCountry?.code)
        .then(res => {
          let resStates = res?.data;
          let mappedPhoneStates = selectedCountry && resStates && Object?.keys(resStates)?.map((state) => {
            return {
              label: resStates[state],
              value: resStates[state],
            }
          })
          let mappedCountryPhoneStates = selectedCountry && resStates && Object?.keys(resStates)?.map((state) => {
            return {
              label: resStates[state],
              value: resStates[state],
            }
          })
          setCountryPhoneStates(mappedCountryPhoneStates)
          setStates(mappedPhoneStates);
        })
        .catch(({ response }) => {
          if (response?.data) {
            // toastErrors(response.data)
          }
        })
    }
  }, [selectedCountry])

  return {
    selectedCountry, setSelectedCountry,
    states, setStates,
    selectedState, setSelectedState,
    countryPhoneStates, setCountryPhoneStates
  }
}

export default usePhone;
