import React from 'react';
import {useCart} from "context/cart-context";
import {Dialog, Divider, Grid, Space} from "antd-mobile";
import ImageRenderer from "utils/ImageRenderer";
import {useHistory} from "react-router-dom";
import {EmptyPage, GlobalHeaderBack, LoadingState, LoadMore, StatusTag} from "components";
import {ProductDetailsBlock} from "demos";
import './WishList.scss'
import {DeleteOutline} from "antd-mobile-icons";
import {sleep} from "utils/sleep";
import {PageHead} from "../../components";
import {changeImageOptimizeSize, ShowPriceWithCurrency} from "utils/helper";
import { useLayoutContext } from 'context/LayoutContext';

const WishList = () => {

    const history = useHistory()
    const { localeCurrency, preloaderImage } = useLayoutContext();
    const {wishList, isWishListLoading, loadMoreWishListItem, handleDeleteWishList} = useCart()

    const isWishListButtonLoading = isWishListLoading.isButtonLoading
    const isWishListPageLoading = isWishListLoading.isPageLoading
    const wishListMeta = wishList.meta

    if (isWishListPageLoading) {
        return (
            <LoadingState/>
        )
    }

    if (wishList.data.length <= 0) {
        return (
            <>
                <GlobalHeaderBack title="Wish List" height="60px" color="white"/>
                <EmptyPage title="Wish List Empty" status="Default"
                           description="Your wish list is empty, Please add some products in wish lists"
                           buttonTitle="Back Home"/>
            </>
        )
    }

    return (
        <>
            <PageHead title={`Wish List`}/>

            <GlobalHeaderBack title="Wish List" height="60px" color="white"/>
            <ProductDetailsBlock marginTop="2%" padding="12px" marginBottom="15%">
                {
                    wishList?.data?.map((item, index) => {
                        return (
                            <div key={`wish_list${index}`} className="wish_list_divider">
                                <Grid columns={15} gap={10} className="wish_list" key={`sellerWiseProduct${index}`}>
                                    <Grid.Item span={3} className="wish_list_image" onClick={() => history.push(`/products/details?url=${encodeURIComponent(item?.link)}`, {
                                      title: item.title,
                                      price: item?.price?.min,
                                      image: item?.image
                                    })}>
                                        <ImageRenderer
                                            key={item?.id}
                                            url={changeImageOptimizeSize(item?.image)}
                                            thumb={changeImageOptimizeSize(item?.image)}
                                            width={60}
                                            height={60}
                                            alt={`CartImage${item?.id}`}
                                            borderRadius="8px"
                                            imageBackground={preloaderImage ?? "/assets/product/preloader-bg_62X62.png"}
                                        />
                                    </Grid.Item>
                                    <Grid.Item span={12} className="wish_list_product_details">
                                        <Grid columns={1} gap={2} style={{'--gap-vertical': '6px'}}>
                                            <Grid.Item className="wish_list_product_details__title">
                                                <div
                                                    onClick={() => history.push(`/products/details?url=${encodeURIComponent(item?.link)}`, {
                                                        title: item.title,
                                                        price: item?.price?.min,
                                                        image: item?.image
                                                    })}>
                                                    <span>{item?.title}</span>
                                                </div>
                                            </Grid.Item>
                                            {
                                                item?.attrs &&
                                                <Grid.Item>
                                                    <Space direction='horizontal' wrap>
                                                        {
                                                            item?.attrs?.map((el, index) => {
                                                                return (
                                                                    <span key={`productAttrMeta${index}`}
                                                                          className="product_attr">{el?.value}, </span>
                                                                )
                                                            })
                                                        }
                                                    </Space>
                                                </Grid.Item>
                                            }
                                            <Grid.Item style={{marginTop: '2%'}}>
                                                <Space>
                                                    <span
                                                        className="wish_list_product_details__price">Price: {ShowPriceWithCurrency({price: item?.price, currency: localeCurrency})}</span>
                                                </Space>
                                            </Grid.Item>
                                            <Grid.Item style={{marginTop: '2%'}}>
                                                <Space block justify="between">
                                                    <span>Status: <StatusTag text={item.status}
                                                                             slug={item.status}/></span>
                                                    <span className="wish_list_product_details__icons"
                                                          onClick={() =>
                                                              Dialog.confirm({
                                                                  content: 'Are you sure you want to delete this item from wish list',
                                                                  closeOnMaskClick: true,
                                                                  confirmText: "Confirm",
                                                                  cancelText: "Cancel",
                                                                  onConfirm: async () => {
                                                                      await sleep(500)
                                                                      handleDeleteWishList(item?.id)
                                                                  },
                                                              })
                                                          }
                                                    ><DeleteOutline/></span>
                                                </Space>
                                            </Grid.Item>
                                        </Grid>
                                    </Grid.Item>
                                </Grid>
                                <Divider/>
                            </div>
                        )
                    })
                }

                {
                    wishListMeta && wishListMeta.last_page > 1 && wishListMeta.current_page !== wishListMeta.last_page &&
                    <LoadMore
                        handleClick={loadMoreWishListItem}
                        isLoading={isWishListButtonLoading}
                    />
                }

                {/*{*/}
                {/*    wishListMeta && wishListMeta.last_page > 1 && wishListMeta.current_page !== wishListMeta.last_page &&*/}
                {/*    <InfiniteScroll hasMore={hasMore} loadMore={loadMoreWishList}>*/}
                {/*        < InfiniteScrollContent hasMore={hasMore}/>*/}
                {/*    </InfiniteScroll>*/}
                {/*}*/}
            </ProductDetailsBlock>
        </>
    );
}

export default WishList
