import React, {useState} from 'react'
import {Button, TabBar} from "antd-mobile";
import './CheckoutTabBar.scss'
import {useCart} from "context/cart-context";
import {useHistory} from "react-router-dom";
import ReactGA from "react-ga4";

const CheckoutTabBar = (props) => {

    let history = useHistory();

    const { isAddToCardLoading } = props

    const { handleCheckoutItems, state } = useCart()

    const isSelectedItem = state.data.filter(el => el.isSelected)

    let cartTotal = isSelectedItem.reduce((acc, value) => acc + (parseFloat(value.price)), 0)
    cartTotal = parseFloat(cartTotal + state.totalCharges).toFixed(2);

    let cartTotalFullTotal = state.data?.reduce((acc, value) => acc + (parseFloat(value.price)), 0)
    cartTotalFullTotal = parseFloat(cartTotalFullTotal + state.totalCharges).toFixed(2);

    const [activeKey, setActiveKey] = useState(null)

    const newData = state.data.filter(el => el.isSelected)

    const handleSubmit = (e) => {
        e.preventDefault()
        handleCheckoutItems()
        let internalProductFilter = state.data.filter(el => el.isSelected && el.type === "internal")
        if (internalProductFilter.length > 0) {
          history.push({
            pathname: '/checkout',
            state: {
              data: internalProductFilter
            }
          })
        } else {
          history.push({
            pathname: '/checkout',
            state: {
              data: state.data.filter(el => el.isSelected)
            }
          })
          ReactGA.event({
            category: 'order',
            action: 'begin_checkout',
            value: cartTotal,
          });
        }
    }

    const checkoutButton = <div>
        <Button
            block
            size='middle'
            onClick={handleSubmit}
            loading={isAddToCardLoading}
            loadingText = 'Add to cart'
            className="checkout_cart"
            disabled={newData?.length <= 0}
        > Checkout </Button>
    </div>

    const totalCheckout = <div>
        <Button
            block
            size='middle'
        > {parseFloat(cartTotal) !== 0 ? cartTotal : cartTotalFullTotal} </Button>
    </div>

    const tabs = [
        {
            key: 'addToCart',
            icon: totalCheckout,
            className: "tab_bar_button_check"
        },
        {
            key: 'checkout',
            icon: checkoutButton,
            className: "tab_bar_button"
        },
    ]

    if (activeKey === 'cart'){
        history.push('/cart')
    }

    if (activeKey === 'favorite'){
        history.push('/wish-list');
    }

    return (
        <div className="tab-bar-checkout">
            <TabBar activeKey={activeKey} onChange={setActiveKey}>
                {tabs.map(item => (
                    <TabBar.Item key={item.key} icon={item.icon} title={item.title} badge={item.badge} className={item.className}/>
                ))}
            </TabBar>
        </div>
    )
}

export default CheckoutTabBar
