import React, { useEffect, useState } from "react"
import { useProduct } from "context/product-context";
import { Grid, Space } from "antd-mobile";
import "./ProductTitleDescription.scss"
import { HeartFilled, SyncOutlined } from "@ant-design/icons"
import { useHistory, useLocation } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { useCart } from "context/cart-context";
import { ShowPriceWithCurrency } from "utils/helper";
import { loggedInUser } from "services/auth-client";
import { useSelector } from "react-redux";
import { getUrlProductDetails } from "services/product-client";
import ProductPrice from "components/ProductPrice/ProductPrice";
import { useLayoutContext } from "context/LayoutContext";
import WholesalesPriceRanges from "../ProductDetailComponents/components/WholesalePriceRanges/WholesalePriceRanges";
import siteConfig from "constants/site-configs";

const ProductTitleDescription = () => {
  const history = useHistory();
  const { localeCurrency } = useLayoutContext();
  const { isFetched } = useSelector(state => state.productsDetails)
  const { addToWishList, isWishListExits, isWishListFetching, fetchIsWishListExits } = useCart()

  const { state } = useLocation();

  const { state: productData, isProductLoading, isWholesaleProduct, isCampaignItem } = useProduct()
  const isLoggedIn = loggedInUser();
  const [refreshLoading, setRefreshLoading] = useState(false);

  useEffect(() => {
    if (isFetched && productData?.id && isLoggedIn) {
      fetchIsWishListExits(productData?.id)
    }
  }, [isFetched, fetchIsWishListExits, productData?.id, isLoggedIn]);

  const handleRefresh = () => {
    if (productData?.id) {
      setRefreshLoading(true)
      getUrlProductDetails({ url: productData.link, refresh: 'yes' })
        .then(async (product) => {
          try {
            const res = await getUrlProductDetails({ id: productData?.id, refresh: 'yes' }, 'id');
            return res.data;
          } catch ({ response }) { }
        })
        .then(product => {
          if (product?.id) {
            window.location.href = history.location.pathname + history.location.search;
          }
        })
        .catch(({ response }) => { })
        .finally(() => {
          setRefreshLoading(false)
        })
    }
  }

  return (
    <div className="product_des">
      <Grid columns={15} gap={8} className="product_des_grid">
        <Grid.Item span={13}>
          {
            isWholesaleProduct && productData?.wholesales?.length ?
              <WholesalesPriceRanges
                wholesales={productData?.wholesales}
                fromTitle={true}
              />
              : productData?.price?.min ?
                <Space justify="between" block align="center">
                  <ProductPrice
                    originalPrice={(parseFloat(productData.price.min))}
                    discountPrice={(parseFloat(productData.discount_price.min))}
                    fontSize="text-lg"
                  />
                </Space>
                :
                state ?
                  <span
                    className="product_front_price__amount">{ShowPriceWithCurrency({ price: state?.price, currency: localeCurrency })}</span>
                  :
                  <Skeleton count={1} />
          }
        </Grid.Item>
        <Grid.Item span={2} style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}>
          {
            history.location.search?.includes('aliexpress') &&
            <button
              onClick={() => handleRefresh()}
              disabled={refreshLoading}
              title="Product Refresh"
              style={{
                backgroundColor: `${refreshLoading ? '#EF4444' : 'green'}`,
                borderRadius: '50%',
                color: '#fff',
                width: '27px',
                height: '27px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: 'none',
                marginRight: '5px'
              }}
            >
              <SyncOutlined spin={refreshLoading || false} />
            </button>
          }
          {
            !isProductLoading && !isWishListFetching ?
              <>
                {
                  isWishListExits ?
                    <>
                      {
                        (Object?.keys(isWishListExits)?.length > 0 || isWishListExits?.length > 0) ?
                          <div className="product_linking__exist">
                            <button onClick={() => history.push('/wish-list')}>
                              <HeartFilled /></button>
                          </div>
                          :
                          <div className="product_linking__wish_list">
                            <button onClick={() => {
                              addToWishList(productData?.id)
                            }}><HeartFilled /></button>
                          </div>
                      }
                    </>
                    :
                    <div className="product_linking__wish_list">
                      <button onClick={() => {
                        addToWishList(productData?.id)
                      }}><HeartFilled /></button>
                    </div>
                }
              </>
              :
              <Skeleton height={24} width={28} />
          }
        </Grid.Item>
        <Grid.Item span={15}>
          <Space direction="vertical" block>
            {
              productData?.title
                ?
                <span className="product_des__title">{productData.title}</span>
                :
                <>
                  {
                    state ?
                      <span className="product_des__title">{state?.title}</span>
                      :
                      <Skeleton count={3} />
                  }
                </>

            }
            {
              isProductLoading ?
                <Skeleton count={1} />
                :
                productData?.sold > 0 ?
                  <div>
                    <span className="sold">Sold: </span> <span
                      className="sold_amount">{productData.sold}</span>
                  </div>
                  : null
            }
          </Space>
        </Grid.Item>
      </Grid>
      {
        !isWholesaleProduct && !isCampaignItem && siteConfig.brandName === 'Ali2BD' &&
        <Grid columns={15} gap={8} className="product_linking">
          <Grid.Item span={8}>
            {
              !isProductLoading ?
                <>
                  {
                    productData?.source?.name &&
                    <span
                      className="title-text product_linking__text"> Product is from {productData.source.name}</span>
                  }
                </>
                :
                <Skeleton count={1} />
            }
          </Grid.Item>
          <Grid.Item span={7} className="product_linking__title_link">
            {
              !isProductLoading && productData?.link && !isCampaignItem && !isWholesaleProduct ?
                <span className="title-text product_linking__title_link__link">
                  {
                    productData?.link && productData?.type !== "campaign" &&
                    <a href={productData.link} target="_blank" rel="noopener noreferrer">Original Link</a>
                  }
                </span>
                :
                <Skeleton count={1} />
            }
          </Grid.Item>
        </Grid>
      }


    </div>
  )
}

export default ProductTitleDescription
