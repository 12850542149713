import React, {useState} from "react"
import './ForgetPassword.scss'
import {Button, Form, Input, Toast} from "antd-mobile"
import {ProductDetailsBlock} from "demos"
import {forgetPasswordPost} from "services/auth-client"
import {useHistory} from "react-router-dom"
import {useAuth} from "context/auth-context"
import {GlobalHeaderBack, PageHead} from "components"
import useErrors from "hooks/useErrors";
import { useLayoutContext } from "context/LayoutContext"

const ForgetPassword = () => {
    let history = useHistory()
    const { localeCountry } = useLayoutContext();
    const [isSignInLoading, setIsSignInLoading] = useState(false)
    const { getErrors } = useErrors();
    const [form] = Form.useForm();
    const {setNextIntent, setAuthMeta, getUsername} = useAuth();

    const onFinish = (value) => {

        let username = value.username
        username = getUsername(username, localeCountry)

        const loginData = {
            username: username,
            password: value.password
        }

        setIsSignInLoading(true)
        forgetPasswordPost(loginData)
            .then(res => {
                setIsSignInLoading(false)
                const intent = res?.data?.intent
                if (res?.data?.status === "success"){
                    setNextIntent(intent)
                    setAuthMeta({
                        ...res?.data,
                        username,
                    })

                    Toast.show({
                        icon : 'success' ,
                        content : res?.data?.message,
                    })
                    history.push("/auth/otp-login")
                }
            }).catch(err => {
                setIsSignInLoading(false)
            if (err?.response?.data?.message) {
                Toast.show({
                    icon: 'fail',
                    content: err?.response?.data?.message,
                })
            } else if (err.response.data.errors){
                form.setFields(getErrors(err.response.data.errors));
            }else {
                Toast.show({
                    icon: 'fail',
                    content: 'failed!',
                })
            }
        })
    }

    return (
        <>
            <PageHead title={`Forget Password`} />

            <GlobalHeaderBack title="" height="60px" color="black" backgroundImage="none" backgroundColor="white" />

            <ProductDetailsBlock>
                <div className="forget_password">
                    <span className="forget_password__title">Find your password</span>
                    <span className="forget_password__title_sub">Enter email/phone address to reset password</span>
                </div>
                <div className="login_register_take">
                    <div className="login_form">
                        <Form
                            onFinish={onFinish}
                            footer={
                                <Button block type='submit' color='primary' loading={isSignInLoading} disabled={isSignInLoading} size="large">
                                    CONTINUE
                                </Button>
                            }
                        >
                            <Form.Item
                                name='username'
                                label='Email / Phone'
                            >
                                <Input placeholder=' Please enter email / phone number'/>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </ProductDetailsBlock>
        </>
    );
}
export default ForgetPassword
