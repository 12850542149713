import React from 'react';
import {CapsuleTabs, Divider, Grid, Space} from "antd-mobile";
import Skeleton from 'react-loading-skeleton';
import {DemoBlock} from "../../demos";
import GlobalHeaderBack from "../GlobalHeaderBack";

const ProductFullDetailsSkeleton = () => {

    return (
        <div>
            <GlobalHeaderBack title="My Product" height="60px" color="white"/>
            <DemoBlock padding="16px 12px" margin="2% 3%" borderRadius="8px">
                <Grid columns={15} gap={20} className="product_order_details">
                    <Grid.Item span={9}>
                        <Skeleton count={1}/>
                        <Skeleton count={1}/>
                    </Grid.Item>
                    <Grid.Item span={6} className="pay-invoice">
                        <Skeleton height={30} width={80}/>
                    </Grid.Item>
                </Grid>
            </DemoBlock>

            <DemoBlock padding="8px 4px" margin="2% 3%" borderRadius="8px">
                <div>
                    <Grid columns={1} gap={8} className="package_details">
                        <Grid.Item>
                            <Space direction="vertical">
                                <div>
                                    <Skeleton height={50}/>
                                </div>
                            </Space>
                        </Grid.Item>
                    </Grid>
                    <div className="product_all_details">
                        <Grid columns={10} gap={8} className="product_details_with_order">
                            <Grid.Item span={2}>
                                <Skeleton height={60} width={60}/>
                            </Grid.Item>
                            <Grid.Item span={8}>
                                <Skeleton count={1}/>
                                <Skeleton count={1}/>
                                <Skeleton count={1}/>
                                <Skeleton count={1}/>
                                <Skeleton count={1}/>
                            </Grid.Item>
                        </Grid>
                        <CapsuleTabs defaultActiveKey="more_details" className="collapse_log_panel">
                            <CapsuleTabs.Tab title={<Skeleton count={1}/>} key='details'>
                                <Grid columns={1} gap={8}>
                                    <Grid.Item>
                                        <Skeleton count={1}/>
                                    </Grid.Item>
                                </Grid>
                            </CapsuleTabs.Tab>
                            <CapsuleTabs.Tab title={<Skeleton count={1}/>} key='timeline'>
                                <Skeleton count={1}/>
                            </CapsuleTabs.Tab>
                        </CapsuleTabs>
                        <Divider/>
                    </div>
                </div>
            </DemoBlock>

            <DemoBlock padding="16px 12px" margin="2% 3%" borderRadius="8px">
                <div className="order_summery">
                    <Skeleton count={1}/>
                    <Skeleton count={1}/>
                    <Skeleton count={1}/>
                    <Skeleton count={1}/>
                    <Skeleton count={1}/>
                    <Skeleton count={1}/>
                </div>
                <Divider/>
                <Skeleton count={1}/>
                <Skeleton count={1}/>
            </DemoBlock>

            <DemoBlock padding="16px 12px" margin="2% 3%" borderRadius="8px">
                <div className="transactions">
                    <Skeleton count={1}/>
                    <Skeleton count={1}/>
                    <Grid columns="9" gap="20" className="trans_grid">
                        <Grid.Item span={1}>
                            <Skeleton count={1}/>
                        </Grid.Item>
                        <Grid.Item span={8}>
                            <Skeleton count={1}/>
                        </Grid.Item>
                    </Grid>
                    <Grid columns="9" gap="20" className="trans_grid">
                        <Grid.Item span={1}>
                            <Skeleton count={1}/>
                        </Grid.Item>
                        <Grid.Item span={8}>
                            <Skeleton count={1}/>
                        </Grid.Item>
                    </Grid>
                    <Grid columns="9" gap="20" className="trans_grid">
                        <Grid.Item span={1}>
                            <Skeleton count={1}/>
                        </Grid.Item>
                        <Grid.Item span={8}>
                            <Skeleton count={1}/>
                        </Grid.Item>
                    </Grid>
                </div>
            </DemoBlock>
        </div>
    );
}

export default ProductFullDetailsSkeleton;
