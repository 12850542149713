import React from "react";
import {Image} from "antd-mobile";
import {ICON_AIRPLANE_UP, ICON_CONFIRMED} from "consts/status-consts";

const StatusLogIcons = props => {

    const { icons } = props

    if (icons === ICON_CONFIRMED){
        return (
            <Image src="/assets/icons/pending.svg" width={18} height={18} fit='fill' />
        )
    }else if (icons === ICON_AIRPLANE_UP){
        return (
            <Image src="/assets/icons/approved.svg" width={18} height={18} fit='fill' />
        )
    }else if (icons === "purchased"){
        return (
            <Image src="/assets/icons/airplane_down.svg" width={18} height={18} fit='fill' />
        )
    }else if (icons === "seller_shipped"){
        return (
            <Image src="/assets/icons/truck_load.svg" width={30} height={30} fit='fill' />
        )
    }else if (icons === "received_in_origin"){
        return (
            <Image src="/assets/icons/delivery.svg" width={30} height={30} fit='fill' />
        )
    }else {
        return (
            <Image src="/assets/icons/pending.svg" width={18} height={18} fit='fill' />
        )
    }
}

export default StatusLogIcons
