import { Collapse, SearchBar, Space, Toast } from 'antd-mobile';
import { GlobalHeaderBack, PageHead } from 'components';
import YoutubeEmbed from 'components/YoutubeEmbed/YoutubeEmbed';
import { useLayoutContext } from 'context/LayoutContext';
import { ProductDetailsBlock } from 'demos';
import React from 'react';
import { useHistory } from 'react-router-dom';

export default function IndiaStoresPage() {
  const {
    configsData
  } = useLayoutContext();
  const history = useHistory();

  let pageConfigs;
  let pageFaqs;
  let pageVideos;

  if (configsData?.length) {
    for (let config of configsData) {
      if (config.key === 'india_page_configs') {
        let valObj = config?.value?.reduce((obj, cur) => {
          return {
            ...obj,
            [cur.key]: cur
          }
        }, {})
        pageConfigs = valObj;
      } else if (config.key === 'india_faqs') {
        pageFaqs = config.value
      } else if (config.key === 'india_yt_videos') {
        pageVideos = config.value;
      }
    }
  }

  return (
    <>
      <GlobalHeaderBack title={pageConfigs?.title?.label || "Start shopping from India"} height="50px" color="white" />
      <ProductDetailsBlock padding="3% 5%">
        <div className="page-wrapper space-y-8 pt-20 pb-40">
          <PageHead
            title={pageConfigs?.title?.label || "Start shopping from India"}
          />
          {
            pageConfigs?.title?.label ?
              <section className="container text-center space-y-2" style={{ textAlign: 'center', fontFamily: "Noto Sans Bengali, sans-serif"  }}>
                <h2 style={{ marginBottom: 0}}>
                  {pageConfigs?.title?.label ?? "Title"}
                </h2>
                <p className="text-base text-gray-500" style={{ marginTop: 5, fontSize: 14 }}>{pageConfigs?.tagline?.label ?? "Subtitle"}</p>
              </section>
              : <section style={{ textAlign: 'center'}}>
                <h2 className="text-3xl font-bold">You will find it, we will buy it.</h2>
                <p className="text-base text-gray-500" style={{ marginTop: 5, fontSize: 14 }}>Did you find your products? Enter your desired keyword and hit enter!</p>
              </section>
          }

          <section className="search-field container flex justify-center items-center">
            <div className="xl:w-1/2">
              <button
                className="home_search_box"
                style={{
                  borderRadius: '10px'
                }}
              >
                <SearchBar
                  onSearch={val => {
                    // eslint-disable-next-line no-useless-escape
                    let regexUrlCheck = /(http|https):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%\-\/]))?/;
                    //Check if it is link or keyword
                    if (regexUrlCheck.test(val)) {
                      history.push({
                        pathname: `/my-request/request`,
                        search: `?url=${encodeURIComponent(val)}`
                      })
                    } else {
                      Toast.show({
                        icon: 'fail',
                        content: 'Invalid product link!. Please paste your desired product link.'
                      })
                    }
                  }}
                  placeholder='Paste link & tap enter/go to make request'
                />
              </button>
            </div>
            {
                pageConfigs?.stores_image &&
                <div style={{ marginTop: '6%' }}>
                  <img src={pageConfigs?.stores_image?.image} width="100%" alt="Dubai's stores" />
                </div>
              }
          </section>
          <section
            style={{
              width: '100%',
              paddingBottom: '25%',
              backgroundImage: 'url(/assets/wholesale.svg)',
              backgroundSize: "cover",
              backgroundPosition: '36% 50%',
              height: '120px',
              backgroundRepeat: 'no-repeat',
              margin: '10px 0 30px'
            }}
          >
          </section>
          {
            Array.isArray(pageVideos) && pageVideos?.length > 0 &&
            <section
              className="wholesale-how-to container"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Space direction="vertical" justify="center" style={{ textAlign: 'center', fontSize: 12, color: "var(--adm-color-text-secondary)" }}>
                {
                  pageVideos?.map(video => (
                    <div key={video.key} style={{
                      padding: '8px',
                      border: '2px solid #ddd',
                      borderRadius: '12px',
                      boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px'
                    }}>
                      <YoutubeEmbed videoId={video.key} />
                      <h3 className="text-center font-medium mt-2">{video.label}</h3>
                    </div>
                  ))
                }
              </Space>
            </section>
          }
          <section className="wholesale-faq container" style={{ marginTop: 30, marginBottom: 60 }}>
            <header className="text-center font-semibold text-xl mt-4 mb-12"
              style={{
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: 14,
              }}
            >
              <h3>FAQs - Frequently asked questions</h3>
            </header>
            <div className="w-full grid grid-cols-2 gap-x-4" style={{ fontFamily: "Noto Sans Bengali, sans-serif", lineHeight: '1.25rem' }}>
              <Collapse accordion defaultActiveKey={['1']} className="space-y-2">
                {pageFaqs?.map((item, idx) => (
                  <Collapse.Panel
                    key={`${idx}`}
                    title={item?.title ?? item.key}
                  >
                    {item?.content ?? item?.label}
                  </Collapse.Panel>
                ))}
              </Collapse>
            </div>
          </section>
        </div>
      </ProductDetailsBlock>
    </>
  )
}
