import React from 'react';
import {Divider, Grid} from "antd-mobile";
import Skeleton from 'react-loading-skeleton';
import {GlobalHeaderBack} from "components/index";
import {ProductDetailsBlock} from "demos";

const StoreSkeleton = () => {

    return (
        <>
            <GlobalHeaderBack title="Store List" height="60px" color="white" />
            <ProductDetailsBlock padding="3%" marginBottom="2%" marginTop="2%">
                <div className="services-shop">
                    <Grid columns={20} gap={0}>
                        <Grid.Item span={6}>
                            <Skeleton height={60} width={60}/>
                        </Grid.Item>
                        <Grid.Item span={14}>
                            <Skeleton count={1}/>
                            <Skeleton count={1}/>
                            <Skeleton count={1}/>
                            <Skeleton count={1}/>
                        </Grid.Item>
                    </Grid>
                    <Divider/>
                </div>
            </ProductDetailsBlock>
            <ProductDetailsBlock padding="3%" marginBottom="2%" marginTop="2%">
                <div className="services-shop">
                    <Grid columns={20} gap={0}>
                        <Grid.Item span={6}>
                            <Skeleton height={60} width={60}/>
                        </Grid.Item>
                        <Grid.Item span={14}>
                            <Skeleton count={1}/>
                            <Skeleton count={1}/>
                            <Skeleton count={1}/>
                            <Skeleton count={1}/>
                        </Grid.Item>
                    </Grid>
                    <Divider/>
                </div>
            </ProductDetailsBlock>
            <ProductDetailsBlock padding="3%" marginBottom="2%" marginTop="2%">
                <div className="services-shop">
                    <Grid columns={20} gap={0}>
                        <Grid.Item span={6}>
                            <Skeleton height={60} width={60}/>
                        </Grid.Item>
                        <Grid.Item span={14}>
                            <Skeleton count={1}/>
                            <Skeleton count={1}/>
                            <Skeleton count={1}/>
                            <Skeleton count={1}/>
                        </Grid.Item>
                    </Grid>
                    <Divider/>
                </div>
            </ProductDetailsBlock>
            <ProductDetailsBlock padding="3%" marginBottom="2%" marginTop="2%">
                <div className="services-shop">
                    <Grid columns={20} gap={0}>
                        <Grid.Item span={6}>
                            <Skeleton height={60} width={60}/>
                        </Grid.Item>
                        <Grid.Item span={14}>
                            <Skeleton count={1}/>
                            <Skeleton count={1}/>
                            <Skeleton count={1}/>
                            <Skeleton count={1}/>
                        </Grid.Item>
                    </Grid>
                    <Divider/>
                </div>
            </ProductDetailsBlock>
            <ProductDetailsBlock padding="3%" marginBottom="2%" marginTop="2%">
                <div className="services-shop">
                    <Grid columns={20} gap={0}>
                        <Grid.Item span={6}>
                            <Skeleton height={60} width={60}/>
                        </Grid.Item>
                        <Grid.Item span={14}>
                            <Skeleton count={1}/>
                            <Skeleton count={1}/>
                            <Skeleton count={1}/>
                            <Skeleton count={1}/>
                        </Grid.Item>
                    </Grid>
                    <Divider/>
                </div>
            </ProductDetailsBlock>
            <ProductDetailsBlock padding="3%" marginBottom="2%" marginTop="2%">
                <div className="services-shop">
                    <Grid columns={20} gap={0}>
                        <Grid.Item span={6}>
                            <Skeleton height={60} width={60}/>
                        </Grid.Item>
                        <Grid.Item span={14}>
                            <Skeleton count={1}/>
                            <Skeleton count={1}/>
                            <Skeleton count={1}/>
                            <Skeleton count={1}/>
                        </Grid.Item>
                    </Grid>
                    <Divider/>
                </div>
            </ProductDetailsBlock>
            <ProductDetailsBlock padding="3%" marginBottom="2%" marginTop="2%">
                <div className="services-shop">
                    <Grid columns={20} gap={0}>
                        <Grid.Item span={6}>
                            <Skeleton height={60} width={60}/>
                        </Grid.Item>
                        <Grid.Item span={14}>
                            <Skeleton count={1}/>
                            <Skeleton count={1}/>
                            <Skeleton count={1}/>
                            <Skeleton count={1}/>
                        </Grid.Item>
                    </Grid>
                    <Divider/>
                </div>
            </ProductDetailsBlock>
        </>
    );
}

export default StoreSkeleton;
