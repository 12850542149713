import React, {useEffect} from "react";
import {Button, ErrorBlock} from "antd-mobile";
import './NotFound.scss'
import {GlobalHeaderBack} from "../index";
import {Link} from "react-router-dom";

const NotFound = (props) => {

    const { title, description, image, status } = props

    useEffect(() => {
        document.body.style.background = '#ffffff'
    }, [])

    return (
        <>
            <GlobalHeaderBack title="Not Found" height="60px" color="white"/>
            <div className="error_block">
                <ErrorBlock fullPage title={title} description={description} image={image} status={status} />

                <div className="error_button">
                    <Link to="/" className="link_underline">
                        <Button block color="primary"> Back To Home </Button>
                    </Link>
                </div>
            </div>
        </>
    )
}
export default NotFound
