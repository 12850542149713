import React, { useState } from "react"
import { Button, Dialog, Popup, Space, TabBar } from "antd-mobile";
import { ShopOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import './AddToCartTabBar.scss'
import { useCart } from "context/cart-context";
import Login from "pages/Auth/Login";
import { RecommendedProduct } from "components";
import { useProduct } from "context/product-context";
import { compareCampaignTime, findRequestAbleStore } from "utils/helper";
import { useHistory } from "react-router-dom";
import QueryString from "qs";
import ReactGA from "react-ga4";

const AddToCartTabBar = (props) => {
  const history = useHistory();
  const {
    state: productData,
    selectedProductSku,
    totalQuantity,
    handleAddToCart,
    isAddToCardLoading,
    isBuyNowLoading,
    viewLoginPopUp,
    setViewLoginPopUp,
    afterCartDialogView,
    setAfterCartDialogView,
    handleRequestNow,
    myRequestLoading
  } = useProduct()

  const params = QueryString.parse(history.location.search);

  let campaignStart = true

  if (productData?.campaigns && productData?.campaigns.length > 0) {
    campaignStart = (compareCampaignTime(productData?.campaigns[0]?.finished_at, productData?.campaigns[0]?.started_at) === "Running")
  }

  let isDisable = true

  if (totalQuantity < selectedProductSku?.stock) {
    if (campaignStart) {
      isDisable = false
    }
  } else {
    isDisable = false
  }

  const {
    position,
  } = props

  const { state: cartMetaData } = useCart()

  const [activeKey, setActiveKey] = useState(null)

  const addToButton =
    <Button
      block
      size='large'
      onClick={() => handleAddToCart()}
      loading={isAddToCardLoading}
      className="add_to_cart"
      disabled={isDisable}
    > Add To Cart </Button>

  const requestNowButton =
    <Button
      block
      size='large'
      onClick={() => handleRequestNow()}
      loading={myRequestLoading}
      className="add_to_cart"
    > Request Now </Button>

  const buyNow =
    <Button
      block
      size='large'
      onClick={() => handleAddToCart("buyNow")}
      loading={isBuyNowLoading}
      className="buy_now"
      disabled={isDisable}
    > Buy Now</Button>

  const tabs = [
    {
      key: 'cart',
      title: 'Cart',
      icon: <ShoppingCartOutlined />,
      badge: cartMetaData?.length > 0 ? cartMetaData?.length : 0,
      className: "tab_bar_cart"
    },
    {
      key: 'store',
      title: '' ? '11.11' : 'Store',
      icon: '' ? <img src="/eleven-eleven/nemonic_11_11.png" width={20} alt="11.11" /> : <ShopOutlined />,
      className: "tab_bar_favorite store",
    },
    {
      key: 'buyNow',
      icon: buyNow,
      className: "tab_bar_button"
    },
    {
      key: 'addToCart',
      icon: addToButton,
      className: "tab_bar_button"
    },
  ]

  const requestTabs = [
    {
      key: 'cart',
      title: 'Cart',
      icon: <ShoppingCartOutlined />,
      badge: cartMetaData?.total > 0 ? cartMetaData?.total : 0,
      className: "tab_bar_cart"
    },
    {
      key: 'store',
      title: '' ? '11.11' : 'Store',
      icon: '' ? <img src="/eleven-eleven/nemonic_11_11.png" width={20} alt="11.11" /> : <ShopOutlined />,
      className: "tab_bar_favorite store",
    },
    {
      key: 'addToCart',
      icon: requestNowButton,
      className: "tab_bar_button"
    },
  ]

  if (activeKey === 'cart') {
    history.push('/cart')

  }

  if (activeKey === 'store') {
    if (params?.campaign && params?.campaign_product) {
      history.push({
        pathname: '/campaigns'
      })
    } else {
      history.push({
        pathname: `/seller-details`,
        search: `?seller_id=${productData?.seller?.id}&shop_id=${productData?.source?.id}`
      })
    }
  }
  const handleCartButtonClick = () => {
    ReactGA.event({
      category: 'Button',
      action: 'clicked_shopping_cart',
    });
    history.push('/cart');
  };
  const handleContinueShoppingClick = () => {
    ReactGA.event({
      category: 'Button',
      action: 'clicked_continue_shopping',
    });
    setAfterCartDialogView(false);
  };


  const modalContent = <>
    <Space block justify="center" align="center" direction="vertical">
      <div className="cart_text">A new item has been added to your Shopping Cart.</div>
      <div className="cart_title">You now have {cartMetaData?.length} items in your Shopping Cart.</div>
      <Space wrap>
        <Button size="small" color="primary" className="cart_button" onClick={handleCartButtonClick}>Shopping Cart</Button>
        <Button size="small" color="primary" onClick={handleContinueShoppingClick}>Continue Shopping</Button>
      </Space>
    </Space>
    <RecommendedProduct similarFromCart={"fromCart"} />
  </>

  return (
    <div className="tab-bar-cart" style={{ position }}>
      {
        findRequestAbleStore(productData?.source?.id) ?
          <TabBar activeKey={activeKey} onChange={setActiveKey}>
            {requestTabs.map(item => (
              <TabBar.Item
                key={item.key}
                icon={item.icon}
                title={item.title}
                badge={item.badge}
                className={item.className}
              />
            ))}
          </TabBar>
          :
          <TabBar activeKey={activeKey} onChange={setActiveKey}>
            {tabs.map(item => (
              <TabBar.Item
                key={item.key}
                icon={item.icon}
                title={item.title}
                badge={item.badge}
                className={item.className}
              />
            ))}
          </TabBar>
      }

      <Popup
        visible={viewLoginPopUp}
        onMaskClick={() => {
          setViewLoginPopUp(false)
        }}
        position='right'
        bodyStyle={{
          height: '180vh',
          width: '100vw'
        }}
        destroyOnClose={true}
      >
        <Login viewLoginPopUp={viewLoginPopUp} setViewLoginPopUp={setViewLoginPopUp} />
      </Popup>

      {
        afterCartDialogView &&
        <Dialog
          visible={afterCartDialogView}
          content={modalContent}
          closeOnAction
          onClose={() => {
            setAfterCartDialogView(false)
          }}
          closeOnMaskClick
        />
      }
    </div>
  )
}

export default AddToCartTabBar
