import dayjs from "dayjs";
import { STORE_STORAGE } from "constants/constants";
import siteConfig from "constants/site-configs";

const calculateDiscount = (main_price, offer_price) => {
  return Math.floor((100 * (main_price - offer_price) / main_price));
}

const capitalizeFirstLetter = (string) => {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
}

const formatDate = (dateData) => {
  let monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
  // let monthNames = ["January", "February", "March", "April", "May", "June",
  //   "July", "August", "September", "October", "November", "December"
  // ]

  let today = new window.Date(dateData)
  let date = today.getDate().toString()
  if (date.length === 1) {
    date = "0" + date
  }
  return date + ' ' + monthNames[today.getMonth()] + ', ' + today.getFullYear()
}

const formatTime = (time) => {
  return new window.Date(time).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
}

const formatDateTime = (dateTime) => {
  return formatDate(dateTime) + ' - ' + formatTime(dateTime)
}

const updateImageRenderSize = (url) => {
  let urlLastValue = url.substring(url.lastIndexOf('_'));
  return url.replace(urlLastValue, ``)
}

const updateSize = (url, size) => {
  // console.log(url.replace(/\d+x\d+/, `${size}x${size}`))
  return url.replace(/_\d+x\d+/, `_${size}x${size}`)
}

function support_format_webp() {
  let elem = document.createElement('canvas');

  if (!!(elem.getContext && elem.getContext('2d'))) {
    return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
  } else {
    return false;
  }
}

const changeImageHostName = (url, vendor, variation) => {

  let regexCheck = /.jpg_310x310.jpg/g;

  let addAdditionImage = "_800x800Q80.jpg_.webp"
  let addAdditionImageJpg = "_800x800Q80.jpg"
  let videoThumbImage = ".jpg_800x800Q80.jpg_.webp"

  let addAdditionImageVariation = "_260x260Q50.jpg_.webp"
  let addAdditionImageJpgVariation = "_260x260Q50.jpg"

  if (url?.includes('ali2bd')) {
    return url;
  }

  if (vendor === 4 || vendor === 10) {
    if (variation === "variation") {
      if (support_format_webp) {
        return url + addAdditionImageVariation
      } else {
        return url + addAdditionImageJpgVariation;
      }
    }
    if (regexCheck.test(url)) {
      return url?.replace(regexCheck, videoThumbImage)
    }
    if (support_format_webp) {
      return url + addAdditionImage
    } else {
      return url + addAdditionImageJpg;
    }
  }

  return url
}

const changeImageOptimizeSize = (url, quality = 70) => {

  let addAdditionImage = `_260x260Q${quality}.jpg_.webp`
  let addAdditionImageJpg = `_260x260Q${quality}.jpg`

  if (support_format_webp) {
    return url + addAdditionImage
  } else {
    return url + addAdditionImageJpg;
  }
}

const getLocalData = (localKey) => {
  return JSON.parse(localStorage.getItem(localKey))
}


const compareDateTimeFormat = (jsonData) => {

  let rightNowTime = new Date()
  let formatDayJS = new Date(dayjs(rightNowTime).format("M/D/YYYY"))

  let homepageDateTime = jsonData?.timestamp
  let homepageFormatDayJS = new Date(dayjs(homepageDateTime).format("M/D/YYYY"))

  const diffTime = Math.abs(formatDayJS - homepageFormatDayJS);

  return Math.ceil(diffTime / (1000 * 60 * 60))
}

const productPrice = (price) => {
  if (price !== null) {
    return <span>{Number(price)?.toFixed(2)}</span>
  }
}

const totalPriceOrder = (order) => {

  if (order) {
    return order?.packages?.map(el => {
      return el.items.reduce((acc, value) => {
        if (value.price === 0) {
          value = 0
        } else {
          value = value.price * value.quantity
        }
        return acc + (value)
      }, 0)
    })
  }
  return null
}

const compareCampaignTime = (campaignFinishedTime, campaignStartedTime) => {

  let isCampaignRunning = "Running"

  if (campaignFinishedTime || campaignStartedTime) {
    if (Date.now() > (campaignFinishedTime * 1000)) {
      isCampaignRunning = "Expired"
    } else if (Date.now() < (campaignStartedTime * 1000)) {
      isCampaignRunning = "Scheduled"
    }
  }

  return isCampaignRunning
}

const findRequestAbleStore = (storeID) => {
  let store = getLocalData(STORE_STORAGE)
  let useAbleShopID = storeID ? parseInt(storeID) : 1
  const findStore = store?.value?.find(el => el.id === useAbleShopID)

  let isRequestAble = false
  if (findStore) {
    if (findStore?.options?.manual_request && findStore?.options?.manual_request_amount > 20) {
      isRequestAble = true
    }
  }
  return isRequestAble
}

const calculateShippingCharges = ({ shipping, rate, currency, defaultShippingUnit, shopId }) => {
  let shippingPrice = '';
  let rateString = '';

  rateString = `${currency?.code} ${parseFloat(rate)?.toFixed(2)} /-`;
  if (shipping?.unit === "piece") {
    shippingPrice = `${rateString} 1P`
  }
  else if (shipping?.unit === 'kg' && defaultShippingUnit?.includes(shopId?.toString())) {

    rate = ((parseFloat(rate) / 1000) * 100)
    rateString = `${currency?.code} ${rate?.toFixed(2)} /-`;
    shippingPrice = `${rateString} 100gm`
  } else {
    shippingPrice = `${rateString} ${shipping?.unit}`
  }

  return shippingPrice
}

export const formattedWithFloatAmount = (price) => {
  return Math.round(price * 100) / 100
}

export const calculateShippingChargesWithTag = ({ shipping, rate, currency, defaultShippingUnit, shopId }) => {
  let shippingFee = 0

  if (parseFloat(rate) === 0) {
    shippingFee = "Free Shipping"
  } else if (parseFloat(rate) >= 0) {
    shippingFee = calculateShippingCharges({ shipping, rate, currency, defaultShippingUnit, shopId })
  } else {
    shippingFee = "MoveOn-Ship For Me";
  }

  return shippingFee
}

export const ShowPriceWithCurrency = ({ price, currency }, isSign = false) => {
  if (isSign) {
    if (currency?.prefix) {
      return (
        <>
          {currency?.sign} {getFloatingPrice(price)}
        </>
      )
    } else {
      return (
        <>
          {getFloatingPrice(price)} {currency?.code}
        </>
      )
    }
  }

  return (
    <>
      {currency?.code} {getFloatingPrice(price)}
    </>
  )
}

export const getFloatingPrice = (price) => {

  if (typeof price === 'string') {
    return Number(price)?.toFixed(2)
  }

  return price?.toFixed(2);

}

export const getShippingRate = ({ selectedFrCat, approxWeight, isWholesaleProduct }) => {
  let rate;
  let slots = selectedFrCat?.slots;
  let filteredSlots = [];
  if (isWholesaleProduct) {
    if (slots?.length && approxWeight > 0) {
      filteredSlots = slots?.filter(slot => approxWeight >= parseFloat(slot?.from));
    }
    if (filteredSlots?.length) {
      rate = filteredSlots?.[filteredSlots?.length - 1]?.rate
    } else if (filteredSlots?.length <= 0 && slots?.length) {
      rate = Math.min(parseFloat(selectedFrCat?.price), slots[0].rate)
    } else {
      rate = selectedFrCat?.price;
    }
  } else {
    rate = parseFloat(slots?.[0]?.rate ?? selectedFrCat?.price);
  }
  return rate;
}

export const getShippingUnitFee = ({ productData, selectedShippingDetails, categoryShippingCharge, approxWeight, shippingRate, shippingMethodsData, localeCurrency, defaultShippingUnit, isCampaignItem }) => {
  let shippingFee;

  if (productData?.type === "campaign" || productData?.shipping || isCampaignItem) {

    let ratePerUnit = getShippingRate({ selectedFrCat: productData?.shipping, approxWeight })
    shippingFee = calculateShippingChargesWithTag({ shipping: productData?.shipping, rate: ratePerUnit, currency: localeCurrency, defaultShippingUnit, shopId: productData?.shop_id });
  } else if (selectedShippingDetails?.identifier === "mvn_ship_for_me") {
    if (categoryShippingCharge) {
      shippingFee = calculateShippingChargesWithTag({ shipping: categoryShippingCharge, rate: shippingRate, currency: localeCurrency, defaultShippingUnit, shopId: productData?.shop_id });
    } else {
      shippingFee = "Category not selected"
    }
  } else if (selectedShippingDetails?.fee <= 0) {
    shippingFee = "Free Shipping";
  } else if (selectedShippingDetails?.fee > 0) {
    let shippingUnitRate;
    if (productData?.shop_id === 1) {
      shippingUnitRate = ((selectedShippingDetails?.fee) * productData?.fx)?.toFixed(2);
    } else {
      shippingUnitRate = selectedShippingDetails?.fee;
    }
    shippingFee = ShowPriceWithCurrency({ price: shippingUnitRate, currency: localeCurrency })
  }
  else if (shippingMethodsData?.length && shippingMethodsData[0]?.fee <= 0) {
    shippingFee = "Free Shipping";
  } else {
    let shippingUnitRate;
    if (productData?.shop_id === 1) {
      shippingUnitRate = shippingMethodsData?.length &&
        ((shippingMethodsData[0]?.fee) * productData?.fx)?.toFixed(2);
    }
    else {
      shippingUnitRate = shippingMethodsData && (shippingMethodsData[0]?.fee);
    }
    shippingFee = ShowPriceWithCurrency({ price: shippingUnitRate, currency: localeCurrency })
  }
  return shippingFee;
}

export const RenderNemonicBadge = ({img, alt, width}) => {
  return (
    <div
      className="nemonic_badge"
      style={{
        position: 'absolute',
        top: '0',
        right: '0',
      }}
    >
      <img
        src={img || "/eleven-eleven/nemonic_11_11.png"}
        alt={alt || "11.11 offer"}
        width={width || 48}
      />
    </div>
  )
}

export const RenderPromoBanner = ({promoBanner, Link, borderRadius}) => {

  return (
      <div
        className="promo-banner bg-primary p-1 rounded inline-block"
        style={{backgroundColor: 'var(--adm-color-primary)', paddingTop: '3px', borderRadius}}
      >
        <Link to={`${promoBanner?.link}`}>
            <img src={promoBanner?.image} alt={promoBanner?.label || ''} width="100%" />
        </Link>
      </div>
  )
}

export const getShippingLabel = ({shippingIdentifier, title, shippingMethodsMap}) => {
  if (shippingIdentifier === 'mvn_ship_for_me') {
    return shippingMethodsMap?.[shippingIdentifier]?.label;
  } else if (title) {
    return title;
  }
  return shippingIdentifier?.replaceAll('_', ' ');
}

/**
 *
 * @param {value: amount, number}
 * @param {locales: en-BD | en-AE etc}
 * @param {style: number | currency}
 * @param {currency: BDT | AED }
 * @param { currencyDisplay: code | narrowSymbol | name | symbol }
 */
export const getLocaleValue = ({
  value,
  locales=siteConfig?.locales || 'en-BD',
  style='currency',
  currency=siteConfig?.currency ||'BDT',
  currencyDisplay
}) => {
let options = {style, currency};

if (style === 'currency') {
  value = getFloatingPrice(value);
}

if (currencyDisplay) {
  options['currencyDisplay'] = currencyDisplay;
}

return new Intl.NumberFormat(locales, options).format(value)
}

export {
  calculateDiscount,
  capitalizeFirstLetter,
  formatTime,
  formatDateTime,
  formatDate,
  updateImageRenderSize,
  updateSize,
  changeImageHostName,
  changeImageOptimizeSize,
  support_format_webp,
  getLocalData,
  compareDateTimeFormat,
  productPrice,
  totalPriceOrder,
  compareCampaignTime,
  findRequestAbleStore,
  calculateShippingCharges
}
