import React, { useState } from "react";
import "./ProductVariationsSelected.scss";
import {
  Divider,
  DotLoading,
  Ellipsis,
  Grid,
  ImageViewer,
  List,
  Popup,
  Space,
  Steps,
} from "antd-mobile";
import { useProduct } from "context/product-context";
import { ProductDetailsBlock } from "demos";
import AddToCartTabBar from "layout/AddToCartTabBar";
import ShippingDetails from "../ShippingDetails";
import ImageRenderer from "utils/ImageRenderer";
import { CarOutlined, RocketOutlined } from "@ant-design/icons";
import {
  changeImageHostName,
  getFloatingPrice,
  getShippingLabel,
  getShippingUnitFee,
  ShowPriceWithCurrency,
} from "utils/helper";
import { CloseCircleOutline, FileWrongOutline } from "antd-mobile-icons";
import {
  defaultKeys,
  DOMESTIC_NOTICE,
  SHIPPING_DETAILS,
  SHIPPING_DETAILS_CATEGORY,
} from "consts/storage";
import { useLayoutContext } from "context/LayoutContext";
import WholesaleSkuSelections from "../ProductDetailComponents/components/WholesaleSkuSelections/WholesaleSkuSelections";
import RetailsVariations from "../ProductDetailComponents/components/RetailsVariations/RetailsVariations";
import WholesalesPriceRanges from "../ProductDetailComponents/components/WholesalePriceRanges/WholesalePriceRanges";
import ApproxWeightCost from "../ProductDetailComponents/components/ApproxWeightCost/ApproxWeightCost";

const { Step } = Steps;

const ProductVariationsSelected = ({ setViewVariations }) => {
  const { localeCurrency,
    defaultShippingUnit,
    preloaderImage,
    visibleConfigs,
    notifyMessages,
    shippingMethodsMap,
    localeCountry
  } = useLayoutContext();
  const [viewSelectedImage, setViewSelectedImage] = useState(false);

  const {
    state: productData,
    selectedProductSku,
    selectedProductImg,
    selectedShippingDetails,
    shippingMethodsData,
    setViewShippingSelect,
    viewShippingSelect,
    categoryShippingCharge,
    selectedDomesticDetails,
    productStore,
    isShippingMethodsLoading,
    isProductLoading,
    isWholesaleProduct,
    totalQty, totalPrice,
    approxWeight,
    shippingRate,
    isCampaignItem
  } = useProduct();

  const mvnServiceDisclaimer = productData?.extras?.mvnServiceDisclaimer || notifyMessages?.moveon_service_discalimer?.label || SHIPPING_DETAILS;

  let shippingFee = getShippingUnitFee({
    productData,
    selectedShippingDetails,
    categoryShippingCharge,
    approxWeight, shippingRate,
    shippingMethodsData,
    localeCurrency,
    defaultShippingUnit
  })

  let titleOfSpecification = (
    <div className="title-text">
      To {localeCountry?.name} via{" "}
      <span className="shipping_details_spe">
        {getShippingLabel({ shippingIdentifier: selectedShippingDetails?.identifier, title: selectedShippingDetails?.title, shippingMethodsMap }) || (selectedShippingDetails?.title ? selectedShippingDetails?.title : shippingMethodsData && shippingMethodsData[0]?.title)}
      </span>
    </div>
  );
  if (isCampaignItem) {
    titleOfSpecification = <div className="title-text">To {localeCountry?.name} via <span className="shipping_details_spe">{getShippingLabel({ shippingIdentifier: "mvn_ship_for_me", shippingMethodsMap }) || `${shippingMethodsMap?.MoveOn?.label || 'MoveOn'}-Ship For Me`}</span></div>
  }
  const estimated = (
    <span>
      Estimated delivery time {selectedShippingDetails?.time
        ? selectedShippingDetails?.time
        : shippingMethodsData && shippingMethodsData[0]?.time} days
    </span>
  );
  let desTitle = `From ${productStore?.country_name || "China"}`;
  let shippingUnitRate = (
    <span className="shipping_details_spe__rate">Shipping: {shippingFee}</span>
  );

  const titleShipping = (
    <Steps direction="vertical" current={1} className="shipping_step">
      <Step
        title={titleOfSpecification}
        description={shippingUnitRate}
        icon={<CarOutlined />}
      />
      <Step
        title={desTitle}
        description={estimated}
        icon={<RocketOutlined />}
      />
    </Steps>
  );

  let selectedOptimizeProductImg = changeImageHostName(
    selectedProductImg,
    productData?.source?.id,
    "variation",
  );


  let selectedPrice = selectedProductSku?.price;

  if (selectedProductSku?.discount_price) {
    selectedPrice = selectedProductSku?.discount_price;
  }

  const getShippingList = () => {
    if (isCampaignItem || shippingMethodsData?.length > 0) {
      return (
        <ProductDetailsBlock padding="0" marginTop="1.5%">
          <List.Item
            title={titleShipping}
            onClick={() => setViewShippingSelect(true)}
            className="shipping_list"
          // disabled={isCampaignItem}
          >
          </List.Item>
        </ProductDetailsBlock>
      );
    } else if (isProductLoading || isShippingMethodsLoading) {
      return (
        <ProductDetailsBlock>
          <List.Item>
            <span>Shipping methods loading</span> <DotLoading />
          </List.Item>
        </ProductDetailsBlock>
      );
    } else {
      return (
        <ProductDetailsBlock padding="2% 0" marginTop="3%">
          <List.Item
            prefix={<FileWrongOutline />}
            className="shipping_method_no"
          >
            No shipping methods found
          </List.Item>
        </ProductDetailsBlock>
      );
    }
  };

  return (
    <>
      <div className="product_variation">
        <span
          style={{
            float: "right",
            bottom: "12px",
            position: "relative",
            marginTop: "4px",
          }}
        >
          <span
            style={{ fontSize: "18px" }}
            onClick={() => setViewVariations(false)}
          >
            <CloseCircleOutline />
          </span>
        </span>
        <Grid columns={18} gap={0}>
          <Grid.Item span={6}>
            <div className="product_selected_variation_image">
              <div
                className="product_selected_variation_image__thumb"
                onClick={() => setViewSelectedImage(true)}
              >
                <ImageRenderer
                  key={productData?.id}
                  url={selectedProductImg
                    ? selectedOptimizeProductImg
                    : changeImageHostName(
                      productData?.image,
                      productData?.source?.id,
                      "variation",
                    )}
                  thumb={selectedProductImg
                    ? selectedOptimizeProductImg
                    : changeImageHostName(
                      productData?.image,
                      productData?.source?.id,
                      "variation",
                    )}
                  width={240}
                  height={240}
                  alt={`ProductVariationImage${productData?.id}`}
                  maxWidth="none"
                  borderRadius="8px"
                  imageBackground={preloaderImage ?? "/assets/product/preloader-bg_118X118.png"}
                />
              </div>
            </div>
          </Grid.Item>
          <Grid.Item span={12} style={{ marginRight: "3%" }}>
            <Space direction="vertical" className="product_space">
              <Ellipsis
                direction="end"
                rows={2}
                content={productData?.title}
                className="product_variation__title"
              />
              {
                isWholesaleProduct && productData?.wholesales?.length ?
                  <WholesalesPriceRanges wholesales={productData?.wholesales} />
                  : <div className="product_variation__price">
                    Price:{" "}
                    <span className="product_variation__amount">
                      {selectedPrice > 0
                        ? ShowPriceWithCurrency({
                          price: selectedPrice,
                          currency: localeCurrency,
                        })
                        : "Stock Out"}
                    </span>
                  </div>
              }
            </Space>
          </Grid.Item>
        </Grid>
        <Divider />
        {isWholesaleProduct ?
          <>
            <WholesaleSkuSelections ssrProduct={productData} />
            <Divider />
            <Space align="center" justify="between" style={{ width: '92%' }}>
              <div>
                <strong>Total Price: {ShowPriceWithCurrency({ price: getFloatingPrice(totalPrice), currency: localeCurrency })}</strong>
              </div>
              <div>
                <strong>Total Quantity: {totalQty}</strong>
              </div>
            </Space>
          </>
          : <RetailsVariations />
        }
        <div className="product_variation_divider">
          <Divider />
        </div>
        {
          productData?.source?.identifier !== 'aliexpress' && approxWeight > 0 &&
          <List.Item>
            <ApproxWeightCost />
          </List.Item>
        }
        <div className="product_variation_divider">
          <Divider />
        </div>
        {
          productData?.shop_id !== 1 && isCampaignItem && visibleConfigs?.[defaultKeys.wholesaleLabelVisible] && productData?.fx &&
          (
            <div>
              <span className="product_dom">
                China Domestic Delivery Charge: {ShowPriceWithCurrency({ price: (parseFloat(selectedDomesticDetails?.per_qty || 0) * parseFloat(productData?.fx || 0)), currency: localeCurrency })}
              </span>

              <Ellipsis
                style={{ marginTop: "1%", fontSize: "0.9em" }}
                direction="end"
                rows={5}
                content={DOMESTIC_NOTICE}
                expandText="Read More"
                collapseText="Read Less"
              />
            </div>
          )}
        {productData?.source?.identifier !== 'aliexpress'
          && shippingFee !== 'Free Shipping'
          && mvnServiceDisclaimer?.length > 3 ?
          (
            <div className="shipping_notice">
              <Ellipsis
                direction="end"
                rows={3}
                content={mvnServiceDisclaimer}
                expandText="Read More"
                collapseText="Read Less"
                className="shipping_notice__notice_des"
              />
            </div>
          ) : null
        }
        {productData?.source?.identifier !== 'aliexpress'
          && shippingFee !== 'Free Shipping' &&
          (
            <div className="shipping_notice shipping_notice_cate">
              <Ellipsis
                direction="end"
                rows={3}
                content={SHIPPING_DETAILS_CATEGORY}
                expandText="Read More"
                collapseText="Read Less"
                className="shipping_notice__notice_des"
              />
            </div>
          )}
        {getShippingList()}
        {/* {
          <ProductDetailsBlock padding="0 1.5%" marginTop="1.5%">
            <ProductShipping
              selectedDomesticDetails={selectedDomesticDetails}
            />
          </ProductDetailsBlock>
        } */}
      </div>
      <AddToCartTabBar />

      <ImageViewer
        image={selectedProductImg
          ? selectedOptimizeProductImg
          : productData?.image}
        visible={viewSelectedImage}
        onClose={() => {
          setViewSelectedImage(false);
        }}
      />

      <Popup
        visible={viewShippingSelect}
        onMaskClick={() => {
          setViewShippingSelect(false);
        }}
        bodyStyle={{
          height: "60vh",
          borderTopLeftRadius: "15px",
          borderTopRightRadius: "15px",
        }}
      >
        <ShippingDetails setViewShippingSelect={setViewShippingSelect} />
      </Popup>
    </>
  );
};

export default ProductVariationsSelected;
