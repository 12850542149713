import client from './without-auth-api-client'
import Qs from 'qs';
import axios from "axios";
let invAPI = process.env.NEXT_PUBLIC_INV_API ?? 'https://inventory.ali2bd.com/api/v1';

const getUrlProductDetails = (filter = null, findByType='url') => {

    return client.get(`/products/find-by-${findByType}`, {
        params: filter,
        paramsSerializer(params) {
            return Qs.stringify(params, { arrayFormat: 'brackets' });
        },
    });
};

const getInternalProductDetails = (id, filter = {}) => {
  if (typeof parseInt(id) === "number" && !isNaN(parseInt(id))){
      return client.get(`/products/internal/by-id/${id}`, {
        params: filter,
        paramsSerializer(params) {
          return Qs.stringify(params, { arrayFormat: 'brackets' });
        },
      });
    } else {
      return client.get(`/products/internal/by-slug/${id}`, {
        params: filter,
        paramsSerializer(params) {
          return Qs.stringify(params, { arrayFormat: 'brackets' });
        },
      });
    }
};

const getShippingCharges = (data = null) => {
    return client.get('/products/shipping', {
        params: data,
        paramsSerializer(params) {
            return Qs.stringify(params, { arrayFormat: 'brackets' });
        },
    });
};

const getKeywordsSearch = (filter = {}) => {
    return client.get('/products/search', {
        params: filter,
        paramsSerializer(params) {
            return Qs.stringify(params, { arrayFormat: 'brackets' });
        },
    });
};

const getInternalProducts = (filter = {}) => {
    return client.get('/products/internal', {
        params: filter,
        paramsSerializer(params) {
            return Qs.stringify(params, { arrayFormat: 'brackets' });
        },
    });
};

export const getProductDescription = (filter = null) => {

    return client.get('/products/description', {
        params: filter,
        paramsSerializer(params) {
            return Qs.stringify(params, { arrayFormat: 'brackets' });
        },
    });
};

const getProductDescriptionURL = (url) => {
    return client.get(`products/description?id=${url}`);
};

const getProductReviews = (REVIEWS_PAGE_NO = null, PRODUCT_ID) => {
    let data = {
        page: REVIEWS_PAGE_NO ? REVIEWS_PAGE_NO : 1,
    }

    return client.get(`/products/reviews?id=${PRODUCT_ID}`, {
        params: data,
        paramsSerializer(params) {
            return Qs.stringify(params, { arrayFormat: 'brackets' });
        },
    });
};


const getAllCategory = (filter = { type: 'moveon', country: 'CN' }) => {
    return client.get('/products/shipping-categories', {
        params: filter,
        paramsSerializer(params) {
            return Qs.stringify(params, { arrayFormat: 'brackets' });
        },
    });
};

export const getPagesDetails = (slug) => {
  return client.get(`/pages/${slug}`);
};

export const getConfigInfo = () => {
  return client.get(`/system/configs`);
};

export const getTagCategory = (id) => {
  return client.get(`/campaigns/tags/${id}`);
};

export const getDomesticFees = (params) => {
  return axios.get(`${invAPI}/domestic-fee`, {
    params: { ...params },
    paramsSerializer: function (params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' })
    },
  })
}

export const getProductReview = (filter = null) => {

    return client.get('/products/reviews', {
        params: filter,
        paramsSerializer(params) {
            return Qs.stringify(params, { arrayFormat: 'brackets' });
        },
    });
};


/**
 *
 * @param {
 * shipping_category_id,
 * country_id,
 * foreign_type=moveon'
 * } params
 * @returns
 */

 export const requestShippingSlots = (params = {}) => {
  return client.get(`/products/shipping-tag`, {
    params: { ...params },
    paramsSerializer: function (params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' })
    },
  })
}

export {
    getUrlProductDetails,
    getShippingCharges,
    getKeywordsSearch,
    getProductDescriptionURL,
    getProductReviews,
    getInternalProducts,
    getInternalProductDetails,
    getAllCategory
}
