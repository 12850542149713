import React, {useState} from "react"
import './ProfileDetails.scss'
import {Button, Form, Picker, Toast} from "antd-mobile";
import {CloseCircleOutline} from "antd-mobile-icons";
import {storeAllSetting} from "../../../../services/auth-client";

const SettingUpdate = (props) => {

    const {
        setIsLanguageViewFouce,
        languageData,
        fetchSetting,
        isLanguageViewFouce,

        setCountryView,
        countryData,
        countryView,

        setIsCurrencyView,
        currencyData,
        isCurrencyView,

    } = props
    const [isSettingUpdating, setIsSettingUpdating] = useState(false);

    const [isLanguageView, setIsLanguageView] = useState(false);
    const [languageValue, setLanguageValue] = useState(null)

    const [countryValue, setCountryValue] = useState(null);
    const [isCountryView, setIsCountryView] = useState(false);

    const [isCurrencyValue, setIsCurrencyValue] = useState(null);
    const [currencyPicker, setCurrencyPicker] = useState(false);

    const onFinish = (value) => {

        let data = null

        if (isLanguageViewFouce){
            data = {
                setting: "language",
                value: value.language[0]
            }
        }else if (countryView){
            data = {
                setting: "country",
                value: value.country[0]
            }
        } else if (isCurrencyView){
            data = {
                setting: "currency",
                value: value.currency[0]
            }
        }

        setIsSettingUpdating(true)
        storeAllSetting(data)
            .then(res => {
                fetchSetting()
                setIsSettingUpdating(false)
                if (isLanguageViewFouce){
                    setIsLanguageViewFouce(false)
                }else if (countryView){
                    setCountryView(false)
                } else if (isCurrencyView){
                    setIsCurrencyView(false)
                }
                Toast.show({
                    icon: 'success',
                    content: res?.data?.message,
                })
            })
            .catch(err => {
                setIsSettingUpdating(false)
                if (err?.response?.data?.message) {
                    Toast.show({
                        icon: 'fail',
                        content: err?.response?.data?.message,
                    })
                } else {
                    Toast.show({
                        icon: 'fail',
                        content: 'Update failed',
                    })
                }
            })
    }

    const makeLanguageOptionValue = languageData && languageData?.map(el => ({
        label : el.title,
        value : el.code
    }))

    const makeCountryOptionValue = countryData && countryData?.countries?.map(el => ({
        label : el.name,
        value : el.flag
    }))

    const makeCurrencyOptionValue = currencyData && currencyData?.map(el => ({
        label : el.suffix,
        value : el.code
    }))

    return (
        <div className="profile_name">
            <div className="profile_info">
                <div>
                    <span className="profile_info__title">Your Name</span>
                </div>
                <div>
                    <span  className="profile_info__icons" onClick={() => setIsLanguageViewFouce(false)}><CloseCircleOutline/></span>
                </div>
            </div>
            <Form
                onFinish={onFinish}
                footer={
                    <Button block type='submit' color='primary' loading={isSettingUpdating}>
                        Submit
                    </Button>
                }
                className="profile_form"
            >
                {
                    isLanguageViewFouce &&
                        <Form.Item
                            name='language'
                            label = {languageValue ? languageValue[0].label : "Language"}
                            trigger='onConfirm'
                            onClick={()=>{
                                setIsLanguageView(true)
                            }}
                        >
                            <Picker
                                columns={[makeLanguageOptionValue]}
                                visible={isLanguageView}
                                onClose={()=>{
                                    setIsLanguageView(false)
                                }}
                                confirmText="Save"
                                cancelText="Cancel"
                                onSelect={(val, extend) => {
                                    setLanguageValue(extend.items)
                                }}
                                onClick={e => {
                                    e.stopPropagation()
                                }}
                            />
                        </Form.Item>
                }

                {
                    countryView &&
                    <Form.Item
                        name='country'
                        label = {countryValue ? countryValue[0].label : "Country"}
                        trigger='onConfirm'
                        onClick={()=>{
                            setIsCountryView(true)
                        }}
                    >
                        <Picker
                            columns={[makeCountryOptionValue]}
                            visible={isCountryView}
                            onClose={()=>{
                                setIsCountryView(false)
                            }}
                            confirmText="Save"
                            cancelText="Cancel"
                            onSelect={(val, extend) => {
                                setCountryValue(extend.items)
                            }}
                            onClick={e => {
                                e.stopPropagation()
                            }}
                        />
                    </Form.Item>
                }

                {
                    isCurrencyView &&
                    <Form.Item
                        name='currency'
                        label = {isCurrencyValue ? isCurrencyValue[0].label : "Currency"}
                        trigger='onConfirm'
                        onClick={()=>{
                            setCurrencyPicker(true)
                        }}
                    >
                        <Picker
                            columns={[makeCurrencyOptionValue]}
                            visible={currencyPicker}
                            onClose={()=>{
                                setCurrencyPicker(false)
                            }}
                            confirmText="Save"
                            cancelText="Cancel"
                            onSelect={(val, extend) => {
                                setIsCurrencyValue(extend.items)
                            }}
                            onClick={e => {
                                e.stopPropagation()
                            }}
                        />
                    </Form.Item>
                }
            </Form>
        </div>
    );
}
export default SettingUpdate
