import React from 'react'
import {GlobalHeaderBack, PageHead} from "components";
import {HomePageBlock} from "demos";
import MyRequestDetailsView from "./components/MyRequestDetailsView";
import {MyRequestProvider} from "context/my-request-context";
import RequestTabBar from "layout/RequestTabBar";

const MyRequest = () => {

    return (
        <MyRequestProvider>
            <PageHead title={`My Request`}/>

            <GlobalHeaderBack title="My Request" height="60px" />

            <HomePageBlock padding="2px 8px" marginBottom="25%">
                <MyRequestDetailsView />
            </HomePageBlock>

            <RequestTabBar />
        </MyRequestProvider>
    )
}

export default MyRequest
