const siteConfigs = {
  ali2bd: {
    webUri: 'https://ali2bd.com',
    mobileUri: 'https://mobile.ali2bd.com',
    brandName: 'Ali2BD',
    brandTagline: 'Smart shopping with BDT',
    favicon: 'https://ali2bd.com/assets/favicon.png',
    phonePlaceholder: '01XXXXXXXX',
    locales: 'en-BD',
    currency: 'BDT',
    googleAnalyticsCode: 'G-92L43EC0QX',
  },
  bahrcart: {
    webUri: 'https://bahrcart.com',
    mobileUri: 'https://mobile.bahrcart.com',
    brandName: 'BahrCart',
    brandTagline: 'Shopping with BahrCart',
    favicon: 'https://sab.sgp1.digitaloceanspaces.com/files/2022/12/14/8184b255-a23a-43c3-bffd-0b95661a74d6.png',
    phonePlaceholder: '5XXXXXXXXX',
    locales: 'en-AE',
    currency: 'AED',
    googleAnalyticsCode: 'G-4JSW8K1NEW',
  }
}

const brandName = (process.env.REACT_APP_BRAND_NAME ?? 'Ali2BD').toLowerCase();
const siteConfig = siteConfigs[brandName]

export default siteConfig;
