import React, { useEffect, useState } from "react"
import useBuilder from "hooks/useBuilder";
import { HomePageBlock } from "demos";
import { Grid, InfiniteScroll, Loading } from "antd-mobile";
import { KeywordSearchProductDetails } from "components";
import "../homepage.scss"

const ProductGridWidget = (props) => {
  const { fetchProducts, products: productsData, isFetchedFirst } = useBuilder(props);
  const [hasMore, setHasMore] = useState(false);
  const [productList, setProductList] = useState([]);

  const [isFirstCall, setIsFirstCall] = useState(true);
  const [isFetched, setIsFetched] = useState(false);
  const [isFilter, setIsFilter] = useState(null);

  useEffect(() => {
    if (props) {
      let filtersData = {
        ...props.source,
        page: 1,
      }
      if (!isFetched && filtersData) {
        setIsFilter(filtersData);
        setIsFetched(true);
      }
    }
  }, [isFetched, props]);

  useEffect(() => {
    if (isFilter && isFetched && isFirstCall) {
      fetchProducts(isFilter);
      setIsFirstCall(false);
      setIsFetched(false);
    }
  }, [fetchProducts, isFetched, isFilter, isFirstCall])

  useEffect(() => {
    if (productsData?.items) {
      setProductList((list) => {
        return [
          ...list,
          ...productsData?.items,
        ]
      })
    }
  }, [productsData])

  let totalPage = parseInt(productsData?.paginate?.last_page - 1)

  useEffect(() => {
    if (props.infinity && parseInt(productsData?.paginate?.current_page) < totalPage) {
      setHasMore(true)
    }
  }, [productsData?.paginate?.current_page, totalPage, props?.infinity])

  const loadMoreProducts = () => {
    if (props.infinity) {
      let currentPage = parseInt(productsData?.paginate?.current_page);
      let lastPage = parseInt(productsData?.paginate?.last_page);
      if (currentPage >= props?.limit || currentPage >= lastPage) {
        setHasMore(false)
      } else {
        let newParams = { ...isFilter, page: productsData?.paginate?.current_page + 1 }
        fetchProducts(newParams, true);
        setHasMore(false)
      }
    }
  }

  const InfiniteScrollContent = ({ hasMore }) => {
    return (
      <>
        {
          hasMore && productsData?.paginate === props?.limit ? (
            <span>End of products</span>
          )
            : (
              <div className="loading_middle">
                <Loading color='primary' />
              </div>
            )
        }
      </>
    )
  }

  return (
    <>
      <HomePageBlock padding="0% 4%" marginBottom="12%">
        <Grid columns={1} gap={0} className="category_grid">
          <Grid.Item span={1}>
            <h2>{props?.title ?? "Latest Collections"}</h2>
          </Grid.Item>
        </Grid>
        <Grid columns={2} gap={8}>
          {
            productList && productList?.map((elements, index) => {
              return (
                <Grid.Item
                  key={`product_search${index}`}
                  className="search_results_grid"
                  style={{
                    backgroundColor: '#fff',
                    padding: '3px',
                    borderRadius: '5px'
                  }}
                >
                  <KeywordSearchProductDetails product={elements}
                    isProductLoading={isFetchedFirst} />
                </Grid.Item>
              )
            })
          }
        </Grid>
        {
          productsData && productsData?.paginate?.last_page > 1 && productsData?.paginate?.current_page < props.limit &&
          <InfiniteScroll hasMore={hasMore} loadMore={loadMoreProducts}>
            <InfiniteScrollContent hasMore={hasMore} />
          </InfiniteScroll>
        }
      </HomePageBlock>
    </>
  );
}

export default ProductGridWidget;
