export const GET_DETAILS_REQUESTED  = 'GET_DETAILS_REQUESTED';
export const GET_DETAILS_SUCCESS    = 'GET_DETAILS_SUCCESS';
export const GET_DETAILS_FAILED     = 'GET_DETAILS_FAILED';
export const RESET_DETAILS          = 'RESET_DETAILS';
export const PRODUCT_DETAILS_CHANGED = 'PRODUCT_DETAILS_CHANGED';
export const SET_STATE_PRODUCT_DETAILS = 'SET_STATE_PRODUCT_DETAILS';

export const GET_SHIPPING_CHARGES_REQUESTED  = 'GET_SHIPPING_CHARGES_REQUESTED';
export const GET_SHIPPING_CHARGES_SUCCESS    = 'GET_SHIPPING_CHARGES_SUCCESS';
export const GET_SHIPPING_CHARGES_FAILED     = 'GET_SHIPPING_CHARGES_FAILED';

export const GET_DOMESTIC_CHARGES_REQUESTED  = 'GET_DOMESTIC_CHARGES_REQUESTED';
export const GET_DOMESTIC_CHARGES_SUCCESS    = 'GET_DOMESTIC_CHARGES_SUCCESS';
export const GET_DOMESTIC_CHARGES_FAILED     = 'GET_DOMESTIC_CHARGES_FAILED';

export const GET_SHADOW_PRODUCT = 'GET_SHADOW_PRODUCT';
export const SET_SHADOW_PRODUCTS = 'SET_SHADOW_PRODUCTS';

export const GET_INTERNAL_PRODUCT_REQUESTED = 'GET_INTERNAL_PRODUCT_REQUESTED';
export const GET_INTERNAL_PRODUCT_SUCCEEDED = 'GET_INTERNAL_PRODUCT_SUCCEEDED';
export const GET_INTERNAL_PRODUCT_FAILED = 'GET_INTERNAL_PRODUCT_FAILED';
