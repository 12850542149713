import * as Types from 'app/actionTypes/orders';

const initialState = {
    myOrders: {
        data: [],
        meta: null,
    },
    toPay: {
        data: [],
        meta: null,
    },
    toPurchased: {
        data: [],
        meta: null,
    },
    toInTransit: {
        data: [],
        meta: null,
    },
    toDelivered: {
        data: [],
        meta: null,
    },
    toDeliverable: {
        data: [],
        meta: null,
    },
    loading: true,
    error: null,
    firstLoad: false,
    requestedFilter: null
}

export default function orders(state = initialState, action) {

    switch (action.type) {
        case Types.GET_ORDER_REQUESTED:
            return {
                ...state,
                requestedFilter: action.payload
            }
        case Types.GET_ORDER_SUCCESS:
            if (state.requestedFilter.paymentStatus === "unpaid"){
                if (!(state.firstLoad)) {
                    return {
                        ...state,
                        toPay: action.data,
                        firstLoad: true,
                        loading: false,
                    }
                }
                return {
                    ...state,
                    data: {
                        ...state.data,
                        toPay: [...state.data.toPay, ...action.data.data],
                        meta: action.data.meta
                    },
                    loading: false
                }
            }else {
                if (!(state.firstLoad)) {
                    return {
                        ...state,
                        myOrders: action.data,
                        firstLoad: true,
                        loading: false,
                    }
                }
                return {
                    ...state,
                    myOrders: {
                        ...state.data,
                        data: [...state.data.myOrders, ...action.data.data],
                        meta: action.data.meta
                    },
                    loading: false
                }
            }
        case Types.GET_ORDER_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default:
            return state
    }
}
