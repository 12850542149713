import React, { useRef, useState } from "react"
import "./Login.scss"
import { Button, Checkbox, Divider, Form, Grid, Image, Input, Modal, Space, Toast } from "antd-mobile";
import { ProductDetailsBlock } from "demos";
import { postLogin, socialLogin } from "services/auth-client";
import { config, LOCAL_STORAGE_KEY } from "constants/constants";
import { useHistory, useLocation } from "react-router-dom";
import { useAuth } from "context/auth-context";
import { GlobalHeaderBack, PageHead } from "components";
import useErrors from "hooks/useErrors";
import { CheckSquareFilled, CheckSquareOutlined } from "@ant-design/icons";
import ChangePasswordModal from "components/ChangePasswordModal";
import { useLayoutContext } from "context/LayoutContext";
import { GoogleLogin } from "@react-oauth/google";

const Login = (props) => {
  const { localeCountry, brandName, notifyMessages } = useLayoutContext();
  const { viewLoginPopUp, setViewLoginPopUp } = props
  let one = useRef(null)
  let two = useRef(null)
  let history = useHistory();
  let location = useLocation()
  const [form] = Form.useForm();
  const { getErrors } = useErrors();
  const [isIntent, setIsIntent] = useState(false);
  const [isSignInLoading, setIsSignInLoading] = useState(false);
  const [, setIsSocialLoading] = useState(false);
  const { setNewUser, setNextIntent, setAuthMeta, getUsername, getProfileData } = useAuth();
  const [isPasswordModalVisible, setIsPasswordModalVisible] = useState(false);

  let pageTitle = brandName?.label || process.env.REACT_APP_BRAND_NAME || '';

  // Initialize a boolean state
  const [passwordShown, setPasswordShown] = useState(false);

  // Password toggle handler
  const togglePassword = (val) => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    if (val) {
      setPasswordShown(!passwordShown);
    } else {
      setPasswordShown(false);
    }
  };


  const onFinish = (value) => {
    let username = value.username;
    username = getUsername(username, localeCountry)

    const loginData = {
      username: username,
      password: value.password
    }

    setIsSignInLoading(true)
    postLogin(loginData)
      .then(res => {
        setIsSignInLoading(false)
        const intent = res?.data?.intent
        if (res?.data?.status === "success") {

          setNextIntent(intent)
          setAuthMeta({
            ...res?.data,
            username: username,
          })

          if (res?.data?.intent === "login_password") {
            setIsIntent(true)
            two.current.focus();
          } else if (res?.data?.intent === "app_home") {
            window.localStorage.setItem(LOCAL_STORAGE_KEY, res.data.token)
            if (res?.data?.token) {

              if (viewLoginPopUp) {
                window.location.href = history.location.pathname + history.location.search;
              } else if (location?.state?.from?.pathname && !viewLoginPopUp) {
                if (location.state.from.pathname === "/auth/login") {
                  setTimeout(() => {
                    window.location.href = "/"
                  }, 500);
                } else {
                  history.push(location.state.from.pathname)
                }
                window.location.href = location.state.from.pathname
              } else {
                setTimeout(() => {
                  window.location.href = "/dashboard"
                }, 500);
              }

            }
          } else if (res?.data?.intent === "otp_email_reg" || res?.data?.intent === "otp_mobile_reg") {
            const intent = res?.data?.intent
            setNewUser((prevState) => {
              return {
                ...prevState,
                username: username,
                password: value.password,
                intent,
              }
            })
            history.push("/auth/otp-login")
          }
        }
      }).catch(err => {
        if (err?.response?.data?.errors) {
          form.setFields(getErrors(err?.response?.data?.errors));
        } else if (err?.response?.data?.message) {
          Toast.show({
            icon: "fail",
            content: err?.response?.data?.message,
          })
        } else {
          Toast.show({
            icon: "fail",
            content: "failed!",
          })
        }
        setIsSignInLoading(false)
      })
  }

  const handleProviderResponse = async (response, provider) => {
    let data

    if (provider === "google") {
      data = { token: response.credential, provider: "google" }
    }
    if (provider === "facebook") {
      data = { token: response.accessToken, provider: "facebook" }
    }

    try {
      setIsSocialLoading(true)
      const socialRes = await socialLogin(data)

      if (socialRes?.data?.intent === "app_home") {
        window.localStorage.setItem(LOCAL_STORAGE_KEY, socialRes?.data?.token)
        if (socialRes?.data?.token) {
          getProfileData(socialRes?.data?.token)
        }
        setTimeout(() => {
          window.location.href = "/dashboard"
        }, 500);
      } else if (socialRes?.data?.intent === "social_registration_password") {
        setIsPasswordModalVisible(true)
        setNextIntent("verify")
        setAuthMeta({
          username: socialRes?.data?.username,
          intent: socialRes?.data?.intent,
          provider,
          token: data?.token,
          message: socialRes?.data?.message,
        })
      } else if (socialRes?.data?.intent === "social_login_password") {
        setIsPasswordModalVisible(true)
        setNextIntent("verify")
        setAuthMeta({
          username: socialRes?.data?.profile,
          intent: socialRes?.data?.intent,
          message: socialRes?.data?.message,
          provider,
          token: data?.token,
        })
      }
    } catch (err) {
      setIsSocialLoading(false)
      form.setFields(getErrors(err?.response?.data?.errors));
    }
  }

  const handleFacebookLogin = () => {
    window.FB?.getLoginStatus(function (response) {
      if (response?.status === "connected") {
        handleProviderResponse(response?.authResponse, "facebook")
      } else {
        window.FB.login((loginRes) => {
          if (loginRes?.status === "connected") {
            handleProviderResponse(loginRes?.authResponse, "facebook")
          }
        }, { scope: 'public_profile, email' })
      }
    });
  }

  return (
    <>
      <PageHead title={`Login`} />

      <GlobalHeaderBack title="" height="60px" color="black" backgroundImage="none" backgroundColor="white"
        viewLoginPopUp={viewLoginPopUp} setViewLoginPopUp={setViewLoginPopUp} />

      <ProductDetailsBlock>
        <div className="login_register_take">
          <div className="login">
            <span className="login__title">Welcome to {pageTitle}</span>
          </div>
          <div className="login_form">
            <Form
              form={form}
              onFinish={onFinish}
              footer={
                <Button block type="submit" color="primary" loading={isSignInLoading} disabled={isSignInLoading} size="large">
                  CONTINUE
                </Button>
              }
            >
              <div style={{paddingLeft: '15px', paddingRight: '10px'}}>
                <Grid columns={12} gap={0}>
                  <Grid.Item span={12}>
                    <div>
                      <Form.Item
                        name="username"
                        label={notifyMessages?.login_label?.label || "Email / Phone:"}
                        rules={[{ required: true, message: "Please enter email / phone number" }]}
                        style={{ margin: '0', padding: '0' }}
                      >
                        <Input
                          id={"input-one"}
                          placeholder={notifyMessages?.login_placeholder?.label || "Please enter email / phone number"} ref={one}
                        />
                      </Form.Item>
                    </div>
                  </Grid.Item>
                </Grid>
              </div>
              {
                isIntent &&
                <>
                  <Form.Item
                    name="password"
                    label="Password"
                  >
                    <Input id={"input-two"} placeholder="Please enter your password" type={passwordShown ? "text" : "password"} ref={two} />
                  </Form.Item>
                  <div style={{ marginLeft: '4%' }}>
                    <Checkbox icon={checked =>
                      checked ? (
                        <CheckSquareFilled style={{ color: 'var(--adm-color-primary)' }} />
                      ) : (
                        <CheckSquareOutlined style={{ color: 'var(--adm-color-weak)' }} />
                      )
                    } onChange={togglePassword}>Show Password</Checkbox>
                  </div>
                </>
              }
            </Form>
          </div>
          <div className="forget_passport">
            <span onClick={() => history.push("/auth/forget_password")}>Forget Password?</span>
          </div>
          {
            (config.GOOGLE_CLIENT_ID || config.FACEBOOK_APPID) &&
            <div className="divider">
              <Divider> OR LOGIN WITH </Divider>
            </div>
          }
          <Grid columns={1} gap={1}>
            <Grid.Item className="login_grid">
              <Space block justify="center" align="center">
                {
                  config.GOOGLE_CLIENT_ID &&
                  <GoogleLogin
                    onSuccess={(response) => handleProviderResponse(response, 'google')}
                    onError={(errResponse) => {
                      console.error({errResponse});
                      if (errResponse.error) {
                        Toast.show({
                          icon: "fail",
                          content: errResponse.error,
                        })
                      } else {
                        Toast.show({
                          icon: "fail",
                          content: 'Failed to login with Google!',
                        })
                      }
                    }}
                    size="medium"
                    text="signin_with"
                  />
                }
                {
                  config.FACEBOOK_APPID &&
                  <Button
                    style={{
                      padding: 0,
                      display: 'flex',
                      alignItems: 'center',
                      height: '32px',
                      overflow: 'hidden',
                      marginTop: '5px'
                    }}
                    onClick={() => handleFacebookLogin()}> <Image src="/assets/icons/facebook.svg" fit="fill" /></Button>
                }
              </Space>
            </Grid.Item>
          </Grid>
        </div>
      </ProductDetailsBlock>

      <Modal
        visible={isPasswordModalVisible}
        content={<ChangePasswordModal fromSocial={true} />}
        closeOnAction
        onClose={() => {
          setIsPasswordModalVisible(false)
        }}
      />
    </>
  );
}
export default Login
