import React from "react";
import { Grid, Rate, Space } from "antd-mobile";
import "./FlashDealProductDetails.scss"
import { useHistory } from "react-router-dom";
import ImageRenderer from "utils/ImageRenderer";
import { changeImageOptimizeSize, RenderNemonicBadge, findRequestAbleStore } from "utils/helper";
import { COD_BADGE } from "consts/storage";
import { useLayoutContext } from "context/LayoutContext";
import ProductPrice from "components/ProductPrice/ProductPrice";
import ReactGA from "react-ga4";

const FlashDealProductDetails = (props) => {
  const { isCODAvailable, preloaderImage } = useLayoutContext();
  const history = useHistory()
  const { product } = props
  let imageUrl = product.image

  if (parseInt(product?.shop_id) === 4 || parseInt(product?.shop_id) === 10) {
    imageUrl = changeImageOptimizeSize(product.image)
  } else if (product?.vendor === "taobao") {
    imageUrl = changeImageOptimizeSize(product.image)
  } else if (product?.type) {
    imageUrl = changeImageOptimizeSize(product.image)
  } else if (product?.slug) {
    imageUrl = product.image
  }

  let pathName = "/products/details"

  if (findRequestAbleStore(product?.shop_id)) {
    pathName = "/manual-requests"
  }

  let searchParams = `?url=${encodeURIComponent(product?.link)}`

  if (product?.type === 'campaign' || product?.pivot?.campaign_id) {
    searchParams = `?ipid=${product.id}&campaign=${product?.pivot?.campaign_id}&campaign_product=${product?.pivot?.id}`
  }
  else if (product?.product_code) {
    let productSlug = product?.slug || product?.title;
    pathName = `/products/${productSlug}/${product.product_code}`
    searchParams = null;
  }
  // else if (product?.slug) {
  //   searchParams = `?id=${product.id}&campaign=${product?.pivot?.campaign_id}`
  // }
  else if (product?.type) {
    searchParams = `?id=${product.id}`
  }

  let discountPrice = product?.discount_price?.min
  let originalPrice = product?.price?.min

  if (product?.price?.discount?.min) {
    discountPrice = product?.price?.discount?.min
  }

  if (product?.price?.original?.min) {
    originalPrice = product?.price?.original?.min
  }

  let showNemonicBadge = false;
  // if ((product?.pivot?.nemonic_badge || product?.pivot?.campaign_id) || (product?.link?.includes('taobao') && (parseFloat(product?.discount_price?.min) > 0))) {
  if (product?.pivot?.nemonic_badge) {
    showNemonicBadge = true;
  }

  return (
    <div onClick={() => {
      history.push({
        pathname: pathName,
        search: searchParams,
        state: {
          title: product.title,
          price: discountPrice ? discountPrice : originalPrice,
          image: product?.image
        }
      });
      ReactGA.event({
        category: 'Product',
        action: 'view_item',
        items: [{
          item_id: product.vpid,
          item_name: product.title,
          price: discountPrice ? discountPrice : originalPrice,
          vendor: product.vendor,
          product_code: product.product_code,
          shop_id: product.shop_id,
          link: product.link,
        }]
      });
    }}>
      <Space direction="vertical" className="product_search"
        style={{
          backgroundColor: '#fff',
          padding: '3px',
          borderRadius: '5px'
        }}
      >
        <div className="product_search_image">
          <div className="product_search_image__thumb">
            <ImageRenderer
              key={product?.id}
              url={imageUrl}
              thumb={imageUrl}
              width={240}
              height={240}
              alt={`ProductImage${product?.id}`}
              imageBackground={preloaderImage ?? "/assets/product/preloader-bg_184X184.png"}
            />
            <div className="product_search_image__thumb__status-top">
              {product?.pivot?.campaign_id ? "Hot Deal" : "Top Sell"}
            </div>
            {
              product?.pivot?.campaign_id && isCODAvailable &&
              <div className="product_search_image__thumb__status-bottom">
                <span>{COD_BADGE}</span>
              </div>
            }
            {
              showNemonicBadge &&
              <RenderNemonicBadge
                showNemonicBadge={showNemonicBadge}
                img={product?.pivot?.nemonic_badge}
              />
            }
          </div>
        </div>
        <div className="product_search_price">
          <Grid columns={8} gap={14}>
            <Grid.Item span={8}>
              <ProductPrice
                originalPrice={originalPrice}
                discountPrice={discountPrice}
              />
            </Grid.Item>
          </Grid>
        </div>

        <div className="product_search_title_flash">
          <span>{product?.title}</span>
        </div>

        {
          product?.rating &&
          <Grid.Item>
            {
              <div className="product_search_rating_flash">
                <span>
                  <Rate readonly allowHalf value={product?.rating} />
                  {
                    product?.rating_count && `(${product?.rating_count})`
                  }
                </span>
              </div>
            }
          </Grid.Item>
        }
      </Space>
    </div>
  );
}

export default FlashDealProductDetails;
