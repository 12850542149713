import { call, put, takeEvery } from 'redux-saga/effects'
import * as types from 'app/actionTypes/orders';
import {getMyOrdersTest} from "services/my-order-client";

const getApi = async (filters = {}) => {
    return getMyOrdersTest(filters)
    .then(res => res.data)
    .catch((error) => { throw error })
}

function* fetchOrders(action) {
    try {
        const data = yield call(() => getApi(action.payload));
        yield put({ type: types.GET_ORDER_SUCCESS, data: data });
    } catch (e) {
        yield put({ type: types.GET_ORDER_FAILED, message: e.message });
    }
}

function* ordersSaga() {
    yield takeEvery(types.GET_ORDER_REQUESTED, fetchOrders);
}

export default ordersSaga;
