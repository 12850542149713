import siteConfig from 'constants/site-configs';
import React from 'react'
import { Helmet } from "react-helmet";

const PageHead = (props) => {
  let envBrandName = siteConfig?.brandName || 'Ali2BD';
  let envTagline = siteConfig?.brandTagline || 'Smart shopping with BDT';
  let envTitle = `${envTagline} | ${envBrandName}`
  let title;
  if (props?.title && envBrandName) {
    envTagline = envTagline ? ` - ${envTagline}` : '';
    title = props?.title + ` | ${envBrandName}${envTagline}`
  } else if (props?.title) {
    title = props?.title
  } else {
    title = envTitle;
  }
  let canoicalURL = props?.canonical;

  let brandTagline = props?.tagline || envBrandName;
  let themeColor = props?.themeColor || siteConfig?.themeColor || "#f2651e";
  let favicon = siteConfig?.favicon || '/assets/favicon.png';
  let appleTouchIcon = siteConfig?.appleTouchIcon || '/assets/apple-touch-icon.png'
  const desc = props?.desc ?? siteConfig?.brandDescription;
  const image = props?.image ?? siteConfig?.ogImage;

  return (
    <Helmet>
      <title>{title}</title>
      <meta name='description' content={desc} />
      <meta name="theme-color" content={themeColor} media="(prefers-color-scheme: light)" />
      <meta name="theme-color" content={themeColor} media="(prefers-color-scheme: dark)" />
      <meta name='twitter:card' content='summary' />
      <meta name='twitter:title' content={title} />
      <meta name='twitter:description' content={desc} />
      <meta name='twitter:site' content={`@${title}`} />
      <meta name='twitter:creator' content={`@${title}`} />
      <meta name='twitter:image' content={image} />
      <meta property='og:type' content='website' />
      <meta property='og:site_name' content={brandTagline?.value} />
      <meta property='og:title' content={title} />
      <meta property='og:image' content={image} />
      <meta property='og:description' content={desc} />
      {
        canoicalURL &&
        <meta property='og:url' content={`${canoicalURL}`} />
      }

      <link rel='icon' href={favicon} />
      <link rel='apple-touch-icon' href={appleTouchIcon ?? '/assets/apple-touch-icon.png'} />
      <link rel='manifest' href='/manifest.json' />
      {canoicalURL && <link rel='canonical' href={`${canoicalURL}`} />}
      {props?.css && <link rel='stylesheet' href={`${props?.css}`} />}
      {props?.js && <script async type='text/javascript' src={`${props?.js}`} />}
    </Helmet>
  );
}

export default PageHead
