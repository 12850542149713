import React from 'react';
import { Link } from 'react-router-dom';

const CountriesSection = (props) => {
  const { countriesSection } = props;

  return (
    <section style={{
      background: '#fff',
      margin: '0 15px 10px',
      borderRadius: '4px',
      padding: '20px 0'
    }}>
      <div className="container py-6 mt-6">
        <header
          className="text-center mb-6"
          style={{
            textAlign: 'center',
            marginBottom: '15px'
          }}
        >
          <h3
            className="font-bold text-3xl"
            style={{
              margin: '0',
              fontSize: '18px',
              marginBottom: '5px'
            }}
          >{countriesSection?.title || `Buy from`}</h3>
          {
            countriesSection?.subtitle &&
            <span className="text-gray-500 text-base">{countriesSection?.subtitle}</span>
          }
        </header>
        <div>
          {
            countriesSection?.countries?.length > 0 ?
              <div
                className="flex items-center justify-center space-x-4"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '8px',
                  flexWrap: 'wrap',
                  textAlign: 'center',
                }}
              >
                {
                  countriesSection.countries.map(country => {
                    return (
                      <Link to={country.link} key={`${country?.label}-${country?.link}`} style={{textDecoration: 'none'}}>
                        <div className="text-center">
                          <div className="w-full h-auto">
                            <img src={country.image} alt={country.label} width={60} height="auto" />
                          </div>
                          <span className="mt-2 font-medium text-base">{country.label}</span>
                        </div>
                      </Link>
                    )
                  })
                }
              </div>
              : null
          }
        </div>
      </div>
    </section>
  )
}

export default CountriesSection;
