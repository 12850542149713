import React, { useEffect } from 'react';
import '../HomePage/homepage.scss'
import { useDispatch, useSelector } from "react-redux";
import { getBuilder, getDefaultBuilder } from "app/actions/builder";
import RowWidget from "pages/HomePageCraft/components/RowWidget";
import ColumnWidget from "pages/HomePageCraft/components/ColumnWidget";
import SliderWidget from "pages/HomePageCraft/components/SliderWidget";
import BlankWidget from "pages/HomePageCraft/components/BlankWidget";
import CarouselWidget from "pages/HomePageCraft/components/CarouselWidget";
import ProductGridWidget from "pages/HomePageCraft/components/ProductGridWidget";
import HomepageSkeleton from "components/HomepageSkeleton";
import YoutubeEmbed from 'components/YoutubeEmbed/YoutubeEmbed';

const HomePageBuilder = () => {

  const dispatch = useDispatch();
  const { data: widgetsData, loading: widgetsLoading, isFirstCall, isFallbackFirstCall } = useSelector(state => state.builder);

  useEffect(() => {
    // Fetching default builder for mobile
    if (!widgetsData?.isFetched && isFirstCall) {
      dispatch(getDefaultBuilder())
    }
  }, [widgetsData, isFirstCall, dispatch])

  React.useEffect(() => {
    // Fetching fixed builder if default builder is not loaded
    // Because default builder api not working properly yet.
    let isSandbox = process.env?.REACT_APP_API_URL?.includes('sandbox');
    if (isSandbox && !widgetsData?.isDefaultFetched && !isFirstCall && isFallbackFirstCall) {
      dispatch(getBuilder(1));
    } else if (!widgetsData?.isDefaultFetched && !isFirstCall && isFallbackFirstCall) {
      dispatch(getBuilder(11))
    }
  }, [widgetsData, isFirstCall, isFallbackFirstCall, dispatch])

  const KeysToWidget = {
    Row: RowWidget,
    Column: ColumnWidget,
    Slider: SliderWidget,
    Carousel: CarouselWidget,
    ProductGrid: ProductGridWidget,
    Menu: BlankWidget,
    Video: YoutubeEmbed,
    'Image Card': BlankWidget,
  }

  const getWidgets = (list) => {
    return list?.map(item => {
      let props = { ...item.props }
      if (item.displayName === 'Video') {
        props['title'] = "How It Works?";
        props['margin'] = "10px 10px 15px";
      }
      if (typeof KeysToWidget[item.displayName] !== "undefined" && !item?.hidden) {
        return React.createElement(
          KeysToWidget[item.displayName],
          {
            ...props,
            key: item.sectionID,
            sectionName: item.displayName
          },
          ['Container', 'Row', 'Column']?.includes(item.displayName) && item.children?.length
            ? getWidgets(item.children)
            : null
        );
      } else {
        return React.createElement(
          BlankWidget,
          {
            props: props,
            key: item.sectionID
          }
        );
      }
    })
  }

  return (
    <>
      {
        !widgetsLoading && widgetsData && widgetsData?.widgets?.length > 0 ?
          getWidgets(widgetsData?.widgets)
          :
          <HomepageSkeleton />
      }
    </>
  );
}

export default HomePageBuilder;
