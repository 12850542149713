import React, {useEffect, useState} from 'react';
import ProductDetailComponents from "./components/ProductDetailComponents";
import qs from "qs";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {useCart} from "context/cart-context";
import {ProductDetailsProvider} from "context/product-context";
import {PageHead} from "../../components";
import {findRequestAbleStore} from "utils/helper";
import {Helmet} from "react-helmet";
import {changingProductDetails, getInternalProduct, getProductDetails, getShadowProduct, setShadowProducts, setStateDetails} from "app/actions/productsDetails";
import {useDispatch, useSelector} from "react-redux";
import ReactGA from "react-ga4";

const ProductDetails = () => {
    const {state} = useLocation();
    const {product_code} = useParams();
    const history = useHistory()
    const params = qs.parse((window.location.search).substring(1))
    const decodeUrl = decodeURIComponent(params.url)

    const {viewAliexpressProduct} = useCart()
    const dispatch = useDispatch();
    const {data: productData, loading: productLoading, isFetched, selectedShadowProduct, isSameProduct, isShadowChecked, internalProduct, refetchCount } = useSelector((state) => state.productsDetails);

    const [, setIsFirstCall] = useState(true);
    const [isStateFirst, setIsStateFirst] = useState(true);
    const [isFirstDispatch, setIsFirstDispatch] = useState(true);

    useEffect(() => {
      let payload = {
        type: 'link',
        value: decodeUrl,
      }
      if (product_code) {
        payload['type'] = 'product_code';
        payload['value'] = product_code;
      }
      if (payload?.type && !isShadowChecked) {
        dispatch(getShadowProduct(payload))
      }
    }, [decodeUrl, isShadowChecked, product_code, dispatch])

    useEffect(() => {
      if (isShadowChecked && !isSameProduct && !isFetched && !productLoading && refetchCount > 0) {
        let pid = params?.ipid || params?.id;
        let cmpID = params?.campaign_product || params?.product_id
        if (pid && cmpID){
          dispatch(getInternalProduct(pid, {campaign_product: cmpID}));
        } else if (product_code) {
          dispatch(getProductDetails({product_code}));
        } else {
          dispatch(getProductDetails({url: decodeUrl}));
        }
        setIsFirstCall(false);
      }
    }, [isShadowChecked, isSameProduct, params, decodeUrl, isFetched, productLoading, refetchCount, product_code, dispatch])

    useEffect(() => {
      let pid = params?.ipid || params?.id;
      let cmpID = params?.campaign_product || params?.product_id
      if (isSameProduct) {
        dispatch(setStateDetails())
        setIsStateFirst(false)
      } else if (pid && cmpID && internalProduct?.isFetched) {
        dispatch(setStateDetails(internalProduct.data))
        setIsStateFirst(false)
      }
    }, [isSameProduct, isStateFirst, params, internalProduct, dispatch])

    useEffect(() => {
      if (!selectedShadowProduct?.id && productData?.id) {
        dispatch(setShadowProducts(productData))
      }
    }, [selectedShadowProduct?.id, productData, dispatch])

    useEffect(() => {
      return () => {
        setIsFirstCall(true)
        setIsStateFirst(true)
        setIsFirstDispatch(true)
        dispatch(changingProductDetails());
      }
    }, [dispatch])

    useEffect(() => {
      if (productData){
        ReactGA.event({
          category: 'Product',
          action: 'view_item',
          items: [{
            item_id: productData.vpid,
            item_name: productData.title,
            price: productData.price.min,
            vendor: productData.vendor,
            product_code: productData.product_code,
            shop_id: productData.shop_id,
            link: productData.link,
          }]
        });
      }
    }, [productData]);

  let stateValue = true

    if (state !== undefined){
        stateValue = !state.viewAliexpressProduct
    }

    if (productData && !productLoading && !viewAliexpressProduct && stateValue) {
        if (findRequestAbleStore(state?.source?.id)){
                history.push({
                    pathname: '/manual-requests',
                    search: history.location.search
                })
        }
    }

    return (
        <ProductDetailsProvider>
            {
              productData &&
                <Helmet>
                    <meta property="og:title" content={productData?.title}/>
                    <meta property="og:url" content={productData?.link} />
                    <meta property="og:image" content={productData?.image} />
                    <meta property="og:description" content={productData?.description ? productData?.description : productData?.title } />

                    {/*For twitter only */}
                    <meta name="twitter:card" content="summary_large_image"/>
                    <meta name="twitter:title" content={productData?.title}/>
                    <meta name="twitter:description" content={productData?.description ? productData?.description : productData?.title }/>
                    <meta name="twitter:image" content={productData?.image}/>
                </Helmet>
            }
            <PageHead
                title={`${productData?.title ? productData?.title : state?.title}`}/>
            <ProductDetailComponents
              product={productData}
              isProductDetailsLoading={productLoading}
              isFirstDispatch={isFirstDispatch}
              setIsFirstDispatch={setIsFirstDispatch}
            />
        </ProductDetailsProvider>
    );
}

export default ProductDetails;
