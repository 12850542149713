import { GlobalHeaderBack, PageHead } from 'components';
import { ProductDetailsBlock } from 'demos';
import Campaigns from 'pages/Campaigns/components/CampaignList';
import React from 'react';
import {useLayoutContext} from "../../context/LayoutContext";

export default function BahrCartOpeningPage() {
  const {bahrCartPageConfigs: pageConfigs} = useLayoutContext();
  const titleItem = pageConfigs?.find(item => item.key === "title");
  const title = titleItem ? titleItem.label : "Opening offer";

  return (
    <>
      <GlobalHeaderBack title={title} height="50px" color="white" />
      <ProductDetailsBlock>
        <div
          className="page-wrapper space-y-8 pt-20 pb-40"
        >
          <PageHead
            title={title}
          />
          <section
            style={{
              width: '100%',
              paddingBottom: '40%',
              backgroundImage: 'url(/eleven-eleven/BahrCart-opening-Banner.jpg)',
              backgroundSize: "100%",
              backgroundPosition: 'center center',
              backgroundRepeat: 'no-repeat',
              marginTop: '-5%'
            }}
          >
          </section>
          <section className="campaign-list container pt-20 pb-8">
            <Campaigns
              showAllActiveCampaign={true}
              gridCols="lg:grid-cols-3"
            />
          </section>
          <section
            className="campaign-deadline bg-red-500 pt-8 pb-9 flex justify-center items-center"
            style={{
              display: 'flex',
              jusifyContent: 'center',
              alignItems: 'center',
              padding: '32px 36px',
              backgroundColor: '#EF4444'
            }}
          >
            <div
              className="w-1/2 flex flex-col items-center"
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                fontFamily: "Noto Sans Bengali, sans-serif",
                fontWeight: 700,
              }}
            >
              <div className="text-white" style={{ color: '#fff',marginBottom: '10px',textAlign: 'center' }}>Get the Blockbuster Offer at the Opening of Bahrcart</div>
              <div
                className="w-1/2 flex items-center justify-center text-center bg-white rounded-full text-red-600 font-bold py-2 text-xl"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#fff',
                  padding: '8px 24px',
                  borderRadius: '9999px',
                  fontSize: '1rem'
                }}
              >
                <div>Up to 70% OFF! Cash on Delivery</div>
              </div>
            </div>
          </section>
          <div className="mb-28" style={{ padding: '3% 5%' }}>
            <section className="container campaign-prizes py-8">
              <div
                className="flex justify-center items-center"
                style={{ fontFamily: "Noto Sans Bengali, sans-serif" }}
              >
                <div className="lg:w-1/2 border border-gray-200 rounded py-5 px-12 drop-shadow">
                  <div
                    className="flex justify-center items-center text-center mb-6"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      textAlign: 'center',
                    }}
                  >
                    <h3
                      className="block text-xl font-bold bg-primary text-white rounded-full px-8 p-2"
                      style={{
                        backgroundColor:  'var(--adm-color-primary)',
                        color: '#fff',
                        borderRadius: '9999px',
                        padding: '8px 36px'
                      }}
                    >How to enjoy the offers of Blockbuster Offer.</h3>
                  </div>
                  <ul
                    className="list-decimal leading-7"
                  >
                    <li>
                      Up to 70% Discount is available on only selected products of the campaign.
                    </li>
                    <li>
                      You can see the product price right from the product page. There will be an estimated shipping cost mentioned as well. After you check out with the product we will call you on the phone or what’s app for order confirmation.
                    </li>
                    <li>
                      You have 2 payment options here. 1. Cash on Delivery 2. Advance payment.
                    </li>
                    <li>
                      <strong>Cash on Delivery:</strong> if you choose cash on delivery payment method then simply pay the entire amount when the product is delivered. You can see the price and estimated shipping cost right from the product page. When the product reaches the destination country the shipping charge will be updated measuring the real product weight.
                    </li>
                    <li>
                      <strong>Advance Payment:</strong>  you can also make the advance payment for the product when you order. We will call you to share the details.
                    </li>
                  </ul>
                </div>
              </div>
            </section>
            <section className="container campaign-authority flex justify-center items-center">
              <div className="lg:w-1/2 text-left" style={{ fontFamily: "Noto Sans Bengali, sans-serif", marginBottom:'10px' }}>
                Enjoy shopping for your desired products from verified sellers in China. Do Shopping, Forget Borders.
              </div>
            </section>
            <section className="container campaign-authority flex justify-center items-center">
              <div className="lg:w-1/2 text-center" style={{ fontFamily: "Noto Sans Bengali, sans-serif", marginBottom:'50px' }}>
                Bahrcart reserves the full right to change or modify these terms and conditions at any time.
              </div>
            </section>
            <section className="container campaign-wholesale"></section>
          </div>
        </div>
      </ProductDetailsBlock>
    </>
  )
}
