import React, {useEffect, useState} from "react"
import '../../Cart/Cart.scss'
import {CheckList, Form, Grid, Loading, Popup, Space, Tag} from "antd-mobile";
import {useCart} from "context/cart-context";
import AddShippingAddress from "pages/Profile/components/ProfileDetails/AddShippingAddress";
import {AddCircleOutline} from "antd-mobile-icons";
import {EditOutlined} from "@ant-design/icons";

const CheckoutDeliveryAddressList = (props) => {

    const [form] = Form.useForm();
    const {
      viewAddAddress,
      isShippingAddressLoading,
      selectedAddress,
      isChangeableAddress,
    } = props

    const {handleCheckoutAddressSelect} = useCart()
    const [addNewAddressView, setAddNewAddressView] = useState(false);
    const [isAddressEdit, setIsAddressEdit] = useState(false);
    const [editAddressValue, setEditAddressValue] = useState(null);
    const [isDefault, setIsDefault] = useState(false);

    useEffect(() => {
        if (selectedAddress) {
            form.setFieldsValue({address: [selectedAddress?.id]});
        }
    }, [selectedAddress, form])

    if (isShippingAddressLoading) {
        return (
            <Loading/>
        )
    }

    const handleAddressEdit = (value) => {
        setAddNewAddressView(true)
        setIsAddressEdit(true)
        setEditAddressValue(value)
    }

    return (
        <div className="checkout_address_details">

            <div className="checkout_address_bar">
                <span className="checkout_address_bar__text">Please select a address</span>
                {/*<span className="checkout_address_bar__icon_text" onClick={() => setViewAddressList(false)}><CloseCircleOutlined /></span>*/}
                <div className="add_new_address_view">
                <span onClick={() => {
                    setAddNewAddressView(true)
                }}> <AddCircleOutline/> Add Address</span>
                </div>
            </div>
            <Form
                form={form}
            >
                <Form.Item
                    name='address'
                    label=''
                >
                    {
                        viewAddAddress?.length > 0 &&
                        <CheckList>
                            {
                                viewAddAddress?.map((address, index) => {
                                    return (
                                        <CheckList.Item
                                            value={address?.id}
                                            key={`checkout_address${index}`}
                                            onClick={() => handleCheckoutAddressSelect(address, isChangeableAddress)}
                                        >
                                            <Grid columns={1} gap={8} className="checkout_address_grid">
                                                <Grid.Item>
                                                    <span
                                                        className="checkout_address_grid__title">{address?.first_name} {address?.last_name}
                                                        <span onClick={() => {
                                                            handleAddressEdit(address)
                                                            setIsDefault(true)
                                                        }} style={{
                                                            color: "#F26623",
                                                            textAlign: "right",
                                                            marginLeft: "2%"
                                                        }}><EditOutlined/></span></span>
                                                </Grid.Item>
                                                <Grid.Item>
                                                    <span
                                                        className="checkout_address_grid__phone">{address?.phone}</span>
                                                </Grid.Item>
                                                <Grid.Item>
                                                    <span
                                                        className="checkout_address_grid__address">{address?.address}, {address?.state}, {address?.city}, {address?.country} - {address?.postal_code}</span>
                                                </Grid.Item>
                                                <Grid.Item>
                                                    <Space>
                                                        {
                                                            address?.default_billing &&
                                                            <Tag color='success'>Default Billing</Tag>
                                                        }
                                                        {
                                                            address?.default_shipping &&
                                                            <Tag color='success'>Default Shipping</Tag>
                                                        }
                                                    </Space>
                                                </Grid.Item>
                                            </Grid>
                                        </CheckList.Item>
                                    )
                                })
                            }
                        </CheckList>
                    }
                </Form.Item>
            </Form>

            <Popup
                visible={addNewAddressView}
                onMaskClick={() => {
                    setAddNewAddressView(false)
                }}
                bodyStyle={{
                    height: '85vh',
                    overflowY: 'scroll',
                    borderTopLeftRadius: '8px',
                    borderTopRightRadius: '8px',
                }}
                forceRender={true}
            >
                <AddShippingAddress isAddressEdit={isAddressEdit} setViewAddAddress={setAddNewAddressView}
                                    editAddressValue={editAddressValue} isDefault={isDefault}/>
            </Popup>
        </div>
    )
}

export default CheckoutDeliveryAddressList
