import React, {useState} from "react"
import './OtpLogin.scss'
import {Button, Image, Modal, NumberKeyboard, PasscodeInput, Space, Toast} from "antd-mobile";
// import OtpInput from "react-otp-input";
import {useHistory} from "react-router-dom";
import {ClockCircleOutline, UndoOutline} from "antd-mobile-icons";
import {useAuth} from "context/auth-context";
import useCounter from "hooks/useCounter";
import {forgetPasswordPost, postLogin, resetLogin} from "services/auth-client";
import {LOCAL_STORAGE_KEY} from "constants/constants";
import {PageHead, GlobalHeaderBack} from "components";
import {ProductDetailsBlock} from "demos";
import ChangePasswordModal from "components/ChangePasswordModal";
import { useLayoutContext } from "context/LayoutContext";

const OtpLogin = () => {
    let history = useHistory();
    const { localeCountry } = useLayoutContext();
    const [otpCode, setOtpCode] = useState(null);
    const [isOtpLoading, setIsOtpLoading] = useState(false);
    const { newUser, setNewUser, authMeta, nextIntent, getProfileData, getUsername} = useAuth();
    const { count, isOff, setCount, setIsOff } = useCounter()
    const [isResendLoading, setIsResendLoading] = useState(false);
    const [isPasswordModalVisible, setIsPasswordModalVisible] = useState(false);
    const [isFilled, setIsFilled] = useState(true);

  /*const style = {
      width: "3.1rem",
      height: "3.1rem",
      margin: "0 1rem",
      fontSize: "2rem",
      borderRadius: "4px",
      border: "2px solid #E9E8E7"
  };*/

    const handleChange = (otp) => {
        setOtpCode(otp)
    }

    const onSubmit = () => {
        if (nextIntent === "app_forget_verify"){
            setIsOtpLoading(true)
            let resetPayload = {
              username: getUsername(authMeta.username, localeCountry),
              otp: otpCode,
          }
            resetLogin(resetPayload).then(res => {
                setIsOtpLoading(false)
                Toast.show({
                    icon: 'success',
                    content: res?.data?.message,
                })
                if (res?.data?.intent === "app_new_password"){
                    window.localStorage.setItem(LOCAL_STORAGE_KEY, res.data.token)
                    getProfileData(res.data.token)
                    setIsPasswordModalVisible(true)
                }
            }).catch(err => {
                if (err?.response?.data?.errors?.otp){
                    Toast.show({
                        icon: 'fail',
                        content: err?.response?.data?.errors?.otp[0],
                    })
                } else if (err?.response?.data?.message) {
                    Toast.show({
                        icon: 'fail',
                        content: err?.response?.data?.message,
                    })
                } else {
                    Toast.show({
                        icon: 'fail',
                        content: 'failed!',
                    })
                }
                setIsOtpLoading(false)
            })
        }else {
          if (otpCode && newUser?.username){
            setIsOtpLoading(true)
            let postLoginPayload = {
              username: getUsername(authMeta.username, localeCountry),
              otp: otpCode,
            }
            postLogin(postLoginPayload).then(res => {
              setIsOtpLoading(false)
              let resData = res?.data;
              if (resData.status === "success") {
                setNewUser((prevState) => {
                  return {
                    ...prevState,
                    otp: otpCode,
                  }
                })
              }

              if (res?.data?.intent === "otp_registration"){
                history.push("/auth/register")
              }
            }).catch(err => {
              if (err?.response?.data?.errors?.otp){
                Toast.show({
                  icon: 'fail',
                  content: err?.response?.data?.errors?.otp[0],
                })
              } else if (err?.response?.data?.message) {
                Toast.show({
                  icon: 'fail',
                  content: err?.response?.data?.message,
                })
              } else {
                Toast.show({
                  icon: 'fail',
                  content: 'failed!',
                })
              }
              setIsOtpLoading(false)
            })
          }
        }
    }

    const onResend = () => {
        const loginData = {
            username: getUsername(authMeta.username, localeCountry),
        }
        setIsResendLoading(true)
        if (nextIntent === "app_forget_verify"){
            forgetPasswordPost(loginData)
                .then(res => {
                    setCount(60)
                    setIsOff(true)
                    setIsResendLoading(false)
                    Toast.show({
                        icon: 'success',
                        content: res?.data?.message,
                    })
                })
                .catch(err => {
                    setIsResendLoading(false)
                    if (err?.response?.data?.message) {
                        Toast.show({
                            icon: 'fail',
                            content: err?.response?.data?.message,
                        })
                    } else {
                        Toast.show({
                            icon: 'fail',
                            content: 'failed!',
                        })
                    }
                })

        }else {
            postLogin(loginData)
                .then(res => {
                    setCount(60)
                    setIsOff(true)
                    setIsResendLoading(false)
                    Toast.show({
                        icon: 'success',
                        content: res?.data?.message,
                    })
                })
                .catch(err => {
                    setIsResendLoading(false)
                    if (err?.response?.data?.message) {
                        Toast.show({
                            icon: 'fail',
                            content: err?.response?.data?.message,
                        })
                    } else {
                        Toast.show({
                            icon: 'fail',
                            content: 'failed!',
                        })
                    }
                })
        }
    }

    const handleFill = (value) => {
      if (value){
        setIsFilled(false)
      }
    }

    return (
        <>
            <PageHead title={`OTP Login`} />

            <GlobalHeaderBack title="" height="60px" color="black" backgroundImage="none" backgroundColor="white" />

            <div className="login_image_view">
                <Image src="/assets/icons/otp.svg" width={150} height={150} fit='fill' />
            </div>

            <ProductDetailsBlock padding="1% 1% 7% 1%">
                <div className="otp_login_form">
                    <div className="otp_login">
                        <Space direction='vertical' block justify="center" align="center">
                            <span className="otp_login__main_title">Enter your verification code</span>
                            <span className="otp_login__title">Enter 4 digit number that send to </span>
                            <span className="otp_login__value">{authMeta?.username}</span>
                        </Space>
                    </div>

                    <div className="otp-input">
                        <span className="otp-input__title">Enter the 4 digit code</span>

                        <PasscodeInput
                            seperated
                            keyboard={<NumberKeyboard />}
                            length={4}
                            className="otp_cell"
                            plain
                            onChange={handleChange}
                            onFill={handleFill}
                        />

                        {/*Later Remove after review*/}
                        {/*<OtpInput*/}
                        {/*    inputStyle={style}*/}
                        {/*    numInputs={4}*/}
                        {/*    isDisabled={false}*/}
                        {/*    hasErrored={false}*/}
                        {/*    onChange={handleChange}*/}
                        {/*    isInputNum={false}*/}
                        {/*    shouldAutoFocus*/}
                        {/*    value={otpCode}*/}
                        {/*/>*/}
                        <div className="otp_resend_code">
                            <Space>
                                {
                                    isOff ?
                                        <div>
                                            <span> <ClockCircleOutline/> 00:{count}</span>
                                        </div>
                                        :
                                        <Button color="default" loading={isResendLoading} disabled={isResendLoading} onClick={() => onResend()} loadingText={"Resending..."}>
                                            <span style={{color: "#F26623", fontWeight: "bold"}}> <UndoOutline/> Resend Code</span>
                                        </Button>
                                }
                            </Space>
                        </div>
                    </div>
                    <Button color='primary' block onClick={() => onSubmit()} loading={isOtpLoading} disabled={isOtpLoading || isFilled }> CONTINUE </ Button>
                </div>
            </ProductDetailsBlock>

            <Modal
                visible={isPasswordModalVisible}
                content = {<ChangePasswordModal />}
                closeOnAction
                onClose={()=>{
                    setIsPasswordModalVisible(false)
                }}
            />
        </>
    );
}
export default OtpLogin
