/**
 * User Activities ActionTypes
 */
export const SET_SEARCHED_KEYWORD = 'SET_SEARCHED_KEYWORD';
export const GET_SEARCHED_KEYWORD = 'GET_SEARCHED_KEYWORD';
export const SET_SEARCHED_KEYWORDS = 'SET_SEARCHED_KEYWORDS';
export const GET_SEARCHED_KEYWORDS = 'GET_SEARCHED_KEYWORDS';
export const SET_RECENTLY_VIEWED = 'SET_RECENTLY_VIEWED';
export const GET_RECENTLY_VIEWED = 'GET_RECENTLY_VIEWED';
export const GET_RECENTLY_VIEWED_PRODUCT = 'GET_RECENTLY_VIEWED_PRODUCT';

export const SET_PRODUCT_DETAILS = 'SET_PRODUCT_DETAILS';
export const GET_PRODUCT_DETAILS = 'GET_PRODUCT_DETAILS';

export const SET_PRODUCT_IDS = 'SET_PRODUCT_IDS';
export const GET_PRODUCT_EXTERNAL_DATA = 'GET_PRODUCT_EXTERNAL_DATA';
