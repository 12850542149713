import React, { useEffect, useState } from 'react'
import '../../Myorders.scss'
import { Collapse, Grid, Popover, Space } from "antd-mobile";
import { RightOutlined } from "@ant-design/icons";
import { formatDate } from "utils/helper";
import { InformationCircleFill } from "antd-mobile-icons";
import OrderParcelProductDetails from "../OrderParcelProductDetails/OrderParcelProductDetails";
import { StatusTag } from "../../../../components";
import { getParcelProductDetails } from 'services/my-order-client';

const OrderParcel = (props) => {

  const { elements, index } = props

  const [isParcelProductLoading, setIsParcelProductLoading] = useState(false);
  const [parcelProductDetails, setParcelProductDetails] = useState(null);

  const fetchParcelProductDetails = React.useCallback(
    () => {
      setIsParcelProductLoading(true)
      getParcelProductDetails(elements.id)
        .then(res => {
          setParcelProductDetails(res.data)
          setIsParcelProductLoading(false)
        })
        .catch(err => {
          setIsParcelProductLoading(false)
        })
    },
    [elements.id],
  )


  useEffect(() => {
    fetchParcelProductDetails()
  }, [fetchParcelProductDetails]);

  const orderTitle = <Grid columns={13} gap={8} style={{ alignItems: "center" }}>
    <Grid.Item span={7}>
      <Space direction="vertical" style={{ '--gap': '2' }}>
        <span className="all-orders__order-text">Parcel #{elements?.id} <RightOutlined /> <Popover
          content={elements?.address} placement={'topLeft'} trigger='click'>
          <InformationCircleFill />
        </Popover>
        </span>
        <div>
          {parcelProductDetails?.products?.[0]?.item?.order_type ? <span style={{ color: "var(--adm-color-primary)" }}>{parcelProductDetails?.products?.[0]?.item?.order_type} </span> : null}<span className="all-orders__order-date">{formatDate(elements?.created_at)}</span>
        </div>
      </Space>
    </Grid.Item>
    <Grid.Item span={6} style={{ textAlign: 'right' }}>
      <Space direction="vertical" style={{ '--gap': '2px' }}>
        <StatusTag slug={elements?.status} text={elements?.status} />
        {
          elements?.tracking_code &&
          <span className="product_order_details__order-date">Tracking: {elements?.tracking_code}</span>
        }
      </Space>
    </Grid.Item>
  </Grid>

  return (
    <div className="all-orders">
      <Collapse defaultActiveKey={['0', '1', '2', '3', '4', '5', '6']}>
        <Collapse.Panel key={index} title={orderTitle} >
          <OrderParcelProductDetails
            elements={elements}
            isParcelProductLoading={isParcelProductLoading}
            parcelProductDetails={parcelProductDetails}
          />
        </Collapse.Panel>
      </Collapse>
    </div>
  )
}

export default OrderParcel
