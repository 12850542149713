import client from './api-client'

const createPayment = (data) => {
    return client.post('/payment/create', data)
}

const createPaymentByToken = (token, data) => {
    return client.post(`/payment/create/${token}`, data)
}

const preparePayment = (data) => {
    return client.post('/payment/prepare', data)
}

const preparePaymentByToken = (token, data) => {
    return client.post(`/payment/prepare/${token}`, data)
}

const getPaymentGatewaysByOrder = (orderId) => {
    return client.get(`/orders/pay/${orderId}`)
}

const processPaymentByToken = (token, data) => {
    return client.post(`/payment/process/${token}`, data)
}

const processPayment = (data) => {
    return client.post(`/payment/process`, data)
}

const orderPayment = (order_id) => {
    return client.get(`orders/pay/${order_id}`)
}

const getUserBalance = () => {
    return client.get('/wallet/balance')
}

const getAllGateway = () => {
    return client.get('/payment/gateways')
}

export {
    createPayment,
    createPaymentByToken,
    preparePayment,
    preparePaymentByToken,
    getPaymentGatewaysByOrder,
    processPaymentByToken,
    orderPayment,
    getUserBalance,
    getAllGateway,
    processPayment
}
