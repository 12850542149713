import { Button, Grid, Image, Space } from 'antd-mobile';
import { useProduct } from 'context/product-context';
import React from 'react';
import { changeImageHostName } from 'utils/helper';

const VariationItem = ({ propMeta, idx }) => {

  const { productData, handleVariationSelect, selectedVariations,
    selectedProps, getSelectedSkuQty } = useProduct();

  const [stockOutProps, setStockOutProps] = React.useState([]);
  const [stockOutItems, setStockoutItems] = React.useState([]);
  const [selectedIdx, setSelectedIdx] = React.useState(null);

  React.useEffect(() => {
    let skus = productData?.skus;
    let items = []
    if (skus) {
      for (let element of skus) {
        if (element.stock > 0) {
          items.push(element.props.split(','))
        }
      }
    }
    setStockoutItems(items)
  }, [productData])

  const handlePropClick = (pvalue, idx) => {
    setSelectedIdx(idx)
    handleVariationSelect(pvalue, idx)
    let filteredStockOut = stockOutItems.filter((item) =>
      // item.includes(value.id)
      item[idx] === pvalue.id
    );
    setStockOutProps(filteredStockOut.flat())
  }

  return (
    <Grid
      columns={2}
      gap={2}
      className="product_variation_select"
      key={idx + "productVariationTitle"}
    >
      <Grid.Item
        span={2}
        className="product_variation_select__text"
      >
        <Grid columns={1} gap={2}>
          <Grid.Item>
            <div className="product_variation_select__text__gird">
              <span>{`${propMeta?.name}:`}</span>
              <span className="product_variation_select__text__label">
                {selectedProps?.[idx]?.label ??
                  selectedProps?.[idx]?.title}
              </span>
            </div>
          </Grid.Item>
        </Grid>
      </Grid.Item>
      <Grid.Item
        span={2}
        className="product_variation_select__button"
      >
        <Space wrap justify="start" align="center">
          {propMeta?.values?.map((pvalue, pidx) => {
            let skuQty = getSelectedSkuQty(pvalue, idx);
            let isSelected = false;
            if (selectedVariations) {
              if (
                [selectedVariations[idx]]?.includes(
                  pvalue?.id,
                )
              ) {
                isSelected = true;
              }
            }

            let selectedOptimizeProductThumb = changeImageHostName(
              pvalue?.thumb,
            );

            let isDisabled = false;
            if (idx !== selectedIdx && stockOutProps?.length > 0) {
              isDisabled = !stockOutProps?.includes(pvalue?.id);
            }

            if (isSelected && isDisabled) {
              isSelected = false;
            }

            return (
              <div
                className="product_variation_muti_select"
                key={pidx + "pvalue"}
                style={{ position: 'relative' }}
              >
                {
                  skuQty > 0 &&
                  <span
                    className="sku-qty-value absolute -top-1 -left-1 w-6 h-6 rounded-full bg-primary text-white text-xs flex items-center justify-center"
                    style={{ position: 'absolute', top: '0', left: '0', width: '24px', height: '24px', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'red', color: 'white', fontSize: '9px', zIndex: '9' }}
                  >{skuQty}</span>
                }
                {pvalue?.image
                  ? (
                    <Button
                      disabled={isDisabled}
                      className={isSelected
                        ? "product_variation_muti_select__image_button_hover"
                        : "product_variation_muti_select__image_button"}
                      onClick={() => handlePropClick(pvalue, idx)}
                    >
                      <Image
                        src={selectedOptimizeProductThumb}
                        width={40}
                        height={40}
                        fit="fill"
                        style={{ borderRadius: 8 }}
                      />
                    </Button>
                  )
                  : (
                    <Button
                      disabled={isDisabled}
                      onClick={() => handlePropClick(pvalue, idx)}
                      className={isSelected
                        ? "product_variation_muti_select__title_button_hover"
                        : "product_variation_muti_select__title_button"}
                    >
                      <div
                        style={{
                          maxWidth: "26em",
                          overflowWrap: "break-word",
                        }}
                      >
                        {pvalue?.title}
                      </div>
                    </Button>
                  )}
              </div>
            );
          },
          )}
        </Space>

        {/*<Button color='primary' size='small'>Shopping Assistance <RightOutlined/></Button>*/}
      </Grid.Item>
    </Grid>
  )
}

export default VariationItem;
