import React from 'react'
import {Button, ErrorBlock} from "antd-mobile";
import { useHistory } from 'react-router-dom';

const EmptyPage = (props) => {
    const history = useHistory();
    const { title, description, buttonTitle, status} = props

    return (
        <div style={{padding: "0 20px 10% 20px"}}>
            <ErrorBlock fullPage status={status} title={title} description={description} style={{paddingTop: "calc(35vh - var(--image-height-full-page))"}}>
                <Button color='primary' size="small" onClick={() => history.push('/') }>{buttonTitle}</Button>
            </ErrorBlock>
        </div>
    )
}

export default EmptyPage
