import * as actionTypes from 'app/actionTypes/cart';

export const setBuyNowItem = (payload) => {
  return {
    type: actionTypes.SET_BUY_NOW_ITEM,
    payload,
  }
}

export const getBuyNowItem = () => {
  return {
    type: actionTypes.GET_BUY_NOW_ITEM
  }
}

export const removeBuyNowItem = () => {
  return {
    type: actionTypes.REMOVE_BUY_NOW_ITEM
  }
}

export const initBuyNowItem = () => {
  return {
    type: actionTypes.INIT_BUY_NOW_ITEM
  }
}
