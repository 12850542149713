import React, {useEffect, useMemo, useRef, useState} from "react"
import {Empty, Swiper, Tabs} from "antd-mobile";
import {useMyRequest} from "context/my-request-context";
import RequestedProductDetails from "./RequestedProductDetails";
import {LoadMore} from "components";
import '../MyRequest.scss'
import {ACTIVE_REQUEST_TAB, ACTIVE_TAB_INDEX_REQUEST} from "constants/constants";

const MyRequestDetailsView = () => {

    const {
        requested,
        myRequestedLoadings,
        approved,
        approvedLoadings,
        handlerSelectedTab,
        rejected,
        rejectedLoadings,
        expired,
        expiredLoadings,
        loadMoreRequested,
        loadMoreApproved,
        loadMoreRejected,
        loadMoreExpired
    } = useMyRequest()

    const activeTabIndex = JSON.parse(localStorage.getItem(ACTIVE_TAB_INDEX_REQUEST))

    const swiperRef = useRef(null)
    const [activeIndex, setActiveIndex] = useState(activeTabIndex ? activeTabIndex : 0)

    const tabItems = useMemo(() => {
        return [
            { key: 'requested', title: 'Pending' },
            { key: 'approved', title: 'Approved' },
            { key: 'rejected', title: 'Rejected' },
            { key: 'expired', title: "Expired" },
        ]
    }, [])

    useEffect(() => {
        handlerSelectedTab(tabItems[activeIndex].key)
        window.localStorage.setItem(ACTIVE_REQUEST_TAB, JSON.stringify(tabItems[activeIndex].key))
        window.localStorage.setItem(ACTIVE_TAB_INDEX_REQUEST, JSON.stringify(activeIndex))
    }, [activeIndex, handlerSelectedTab, tabItems]);

    const isRequestButtonLoading = myRequestedLoadings.isButtonLoading
    const myRequestMeta = requested?.meta

    const isApprovedButtonLoading = approvedLoadings.isButtonLoading
    const myApprovedMeta = approved?.meta

    const isRejectButtonLoading = rejectedLoadings.isButtonLoading
    const myRejectedMeta = rejected?.meta

    const isExpiredButtonLoading = expiredLoadings.isButtonLoading
    const myExpiredMeta = expired?.meta

    return (
        <>
            <Tabs
                activeKey={tabItems[activeIndex].key}
                onChange={key => {
                    const index = tabItems.findIndex(item => item.key === key)
                    setActiveIndex(index)
                    swiperRef.current?.swipeTo(index)
                }}
            >
                {tabItems.map(item => (
                    <Tabs.Tab title={item.title} key={item.key} />
                ))}
            </Tabs>

            <Swiper
                allowTouchMove={true}
                direction='horizontal'
                loop
                indicator={() => null}
                ref={swiperRef}
                defaultIndex={activeIndex}
                onIndexChange={index => {
                    setActiveIndex(index)
                }}
                className="request_tab"
                rubberband={true}
            >
                <Swiper.Item>
                    {
                        requested && requested.data.length <= 0 ?
                            <Empty
                                style={{padding: '256px 0'}}
                                imageStyle={{width: 128}}
                                description='No data found '
                            />
                            :
                            <>
                                {
                                    requested && requested?.data?.map((elements, index) => {
                                        return (
                                            <div key={`requested${index}`} className="request_tab_value">
                                                <RequestedProductDetails elements={elements}/>
                                            </div>
                                        )
                                    })
                                }
                                {
                                    myRequestMeta && myRequestMeta.last_page > 1 && myRequestMeta.current_page !== myRequestMeta.last_page &&
                                    <LoadMore
                                        handleClick={loadMoreRequested}
                                        isLoading={isRequestButtonLoading}
                                    />
                                }
                            </>

                    }
                </Swiper.Item>
                <Swiper.Item>
                    {
                        approved && approved.data.length <= 0 ?
                            <Empty
                                style={{padding: '256px 0'}}
                                imageStyle={{width: 128}}
                                description='No data found '
                            />
                            :
                            <>
                                {
                                    approved && approved?.data?.map((elements, index) => {
                                        return (
                                            <div key={`requested${index}`} className="request_tab_value">
                                                <RequestedProductDetails elements={elements}/>
                                            </div>
                                        )
                                    })
                                }
                                {
                                    myApprovedMeta && myApprovedMeta.last_page > 1 && myApprovedMeta.current_page !== myApprovedMeta.last_page &&
                                    <LoadMore
                                        handleClick={loadMoreApproved}
                                        isLoading={isApprovedButtonLoading}
                                    />
                                }
                            </>
                    }
                </Swiper.Item>
                <Swiper.Item>
                    {
                        rejected && rejected.data.length <= 0 ?
                            <Empty
                                style={{padding: '256px 0'}}
                                imageStyle={{width: 128}}
                                description='No data found '
                            />
                            :
                            <>
                                {
                                    rejected && rejected?.data?.map((elements, index) => {
                                        return (
                                            <div key={`requested${index}`} className="request_tab_value">
                                                <RequestedProductDetails elements={elements}/>
                                            </div>
                                        )
                                    })
                                }
                                {
                                    myRejectedMeta && myRejectedMeta.last_page > 1 && myRejectedMeta.current_page !== myRejectedMeta.last_page &&
                                    <LoadMore
                                        handleClick={loadMoreRejected}
                                        isLoading={isRejectButtonLoading}
                                    />
                                }
                            </>
                    }
                </Swiper.Item>
                <Swiper.Item>
                    {
                        expired && expired.data.length <= 0 ?
                            <Empty
                                style={{padding: '256px 0'}}
                                imageStyle={{width: 128}}
                                description='No data found '
                            />
                            :
                            <>
                                {
                                    expired && expired?.data?.map((elements, index) => {
                                        return (
                                            <div key={`requested${index}`} className="request_tab_value">
                                                <RequestedProductDetails elements={elements}/>
                                            </div>
                                        )
                                    })
                                }
                                {
                                    myExpiredMeta && myExpiredMeta.last_page > 1 && myExpiredMeta.current_page !== myExpiredMeta.last_page &&
                                    <LoadMore
                                        handleClick={loadMoreExpired}
                                        isLoading={isExpiredButtonLoading}
                                    />
                                }
                            </>
                    }
                </Swiper.Item>
            </Swiper>

            {/*Under review* don't remove/}
            {/*<Tabs className="request_tab" activeKey={tabKey} onChange={setTabKey}>
                <Tabs.Tab title={<Badge content={myRequestMeta?.total}>Pending</Badge>} key='requested'>
                </Tabs.Tab>

                <Tabs.Tab title={<Badge content={myApprovedMeta?.total}>Approved</Badge>} key='approved'>
                </Tabs.Tab>

                <Tabs.Tab title={<Badge content={myRejectedMeta?.total}>Rejected</Badge>} key='rejected'>

                </Tabs.Tab>

                <Tabs.Tab title={<Badge content={myExpiredMeta?.total}>Expired</Badge>} key='expired'>

                </Tabs.Tab>
            </Tabs>*/}

        </>
    )
}

export default MyRequestDetailsView
