import {Popup, SearchBar} from 'antd-mobile';
import {GlobalHeaderBack, PageHead} from 'components';
import {useLayoutContext} from 'context/LayoutContext';
import {ProductDetailsBlock} from 'demos';
import ProductSearch from 'pages/ProductSearch';
import React, {useState} from 'react';

export default function DoubleChallengePage() {
  const {
    doubleChallengeConfigs: pageConfigs
  } = useLayoutContext();
  const [isSearchView, setIsSearchView] = useState(false);
  return (
    <>
      <GlobalHeaderBack title={pageConfigs?.page_title?.label || "Double Challenge offer"} height="50px"
                        color="white"/>
      <ProductDetailsBlock padding="3% 5%">
        <div className="page-wrapper space-y-8 pt-20 pb-40">
          <PageHead
            title="Wholesale"
          />
          <section className="container text-center space-y-2" style={{textAlign: 'center'}}>
            <h2 style={{marginBottom: 0}}>
              {pageConfigs?.title?.label ?? "Title"}
            </h2>
            <p className="text-base text-gray-500"
               style={{marginTop: 5, fontSize: 14}}>{pageConfigs?.tagline?.label ?? "Subtitle"}</p>
          </section>
          <section className="search-field container flex justify-center items-center">
            <div className="xl:w-1/2">
              <button
                onClick={() => setIsSearchView(true)}
                className="home_search_box"
              >
                <SearchBar placeholder='Search from million products'/>
              </button>
            </div>
            {
              pageConfigs?.stores_image &&
              <div style={{marginTop: '6%'}}>
                <img src={pageConfigs?.stores_image?.image} width="100%" alt="China india's stores"/>
              </div>
            }
          </section>
          <section
            style={{
              width: '100%',
              paddingBottom: '20%',
              backgroundImage: 'url(/assets/double-challenge-mobile-version.png)',
              backgroundSize: "cover",
              backgroundPosition: '36% 50%',
              height: '120px',
              backgroundRepeat: 'no-repeat',
              margin: '10px 0 30px'
            }}
          >
          </section>
          <section className="container campaign-marketplaces py-8 flex justify-center items-center">
            <div className="lg:w-1/2 text-center" style={{ fontFamily: "Noto Sans Bengali, sans-serif", textAlign: 'center', marginBottom: '20px' }}>
              অংশগ্রহণ করুন Ali2BD এর মেগা চ্যালেঞ্জ অফারে। শপিং করুন যেকোন পণ্যের Taobao, Indian Amazon, Myntra কিংবা Flipkart থেকে। ২৫ দিনে প্রোডাক্ট দেশে না আসলে শিপিং চার্জ ফ্রি।
            </div>
          </section>
          <section className="container campaign-marketplaces py-8 flex justify-center items-center">
            <div className="lg:w-1/2 text-center" style={{ fontFamily: "Noto Sans Bengali, sans-serif", textAlign: 'center', marginBottom: '20px' }}>{pageConfigs?.time_content?.label ?? "আপনার পছন্দের পণ্যের লিঙ্ক পেস্ট করুন সার্চ বারে। শিপিং চ্যালেঞ্জে অংশগ্রহণ করুন মন খুলে। অফার চলবে ১৬ মে তারিখ পর্যন্ত। এই রেস সময়ের সাথে শিপিং এর। Let the race Begin."}
            </div>
          </section>
          <section className="container campaign-rules py-8">
            <div
              className="flex justify-center items-center"
              style={{fontFamily: "Noto Sans Bengali, sans-serif"}}
            >
              <div className="lg:w-1/2 border border-gray-200 rounded py-5 px-12 drop-shadow">
                <div
                  className="flex justify-center items-center text-center mb-6"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                  }}
                >
                  <h3
                    className="block text-xl font-bold bg-primary text-white rounded-full px-8 p-2"
                    style={{
                      backgroundColor: 'var(--adm-color-primary)',
                      color: '#fff',
                      borderRadius: '9999px',
                      padding: '8px 36px'
                    }}
                  >ডাবল চ্যালেঞ্জ অফারের সার্বিক নিয়মাবলী</h3>
                </div>
                <ul
                  className="list-decimal leading-7"
                  style={{
                    listStyleType: "bengali",
                    lineHeight: '1.5rem',
                  }}
                >
                  <li>
                    অর্ডারকৃত পণ্য অবশ্যই Taobao, ইন্ডিয়ান Amazon, Flipkart এবং Myntra শপিং
                    প্ল্যাটফর্মের হতে হবে। অন্য কোন প্ল্যাটফর্মের ক্ষেত্রে এই অফার প্রযোজ্য নয়।
                  </li>
                  <li>
                    ২৫ কার্যদিবসের মধ্যে পণ্য দেশে অর্থাৎ বাংলাদেশ এয়ারপোর্টে পৌঁছে গেলে কেজি প্রতি
                    শিপিং চার্জ প্রযোজ্য হবে। যদি এই সময়সীমার মাঝে দেশে না আসে তবেই শিপিং চার্জ
                    সম্পূর্ণ ফ্রি।
                  </li>
                  <li>
                    {pageConfigs?.time_content_with_date?.label ?? "অর্ডার প্লেস করতে হবে ১৬ মে রাত ১১ঃ৫৯ ঘটিকার মধ্যে।"}
                  </li>
                  <li>
                    কোন কাস্টোমাইজড প্রোডাক্ট এই অফারে অর্ডার করা যাবে না। প্রোডাক্ট কাস্টোমাইজেশন
                    একটি সময় সাপেক্ষ ব্যাপার।
                  </li>
                  <li>
                    সেলার প্রোডাক্ট দিতে দেরি করবে এমন পণ্য এই অফারে অন্তর্ভুক্ত নয়। কারণ এই
                    ক্ষেত্রে দেরি সেলারের পক্ষ থেকে হবে।
                  </li>
                  <li>
                    পারচেজের ৩ দিনের মধ্যে পণ্য চায়নার MoveOn ওয়ারহাউজে রিসিভ হতে হবে। সেলার দেরি
                    করলে Ali2BD এই চ্যালেঞ্জের শর্ত পূরণে দায়ী নয়।
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section className="container campaign-marketplaces py-8 flex justify-center items-center">
            <div className="lg:w-1/2 text-center" style={{
              fontFamily: "Noto Sans Bengali, sans-serif",
              textAlign: 'center',
              marginBottom: '20px'
            }}>
              Ali2BD ডাবল চ্যালেঞ্জের সকল নিয়মাবলী পরিবর্তন এবং বাতিলের অধিকার এক মাত্র Ali2BD দ্বারা
              সংরক্ষিত। Do Shopping, Forget Borders.
            </div>
          </section>
          <Popup
            visible={isSearchView}
            onMaskClick={() => {
              setIsSearchView(false)
            }}
            position='right'
            bodyStyle={{
              height: '180vh',
              width: '100vw'
            }}
            destroyOnClose={true}
          >
            <ProductSearch
              setIsSearchView={setIsSearchView}
              isSearchView={isSearchView}
              // componentFrom="wholesale_page"
            />
          </Popup>
        </div>
      </ProductDetailsBlock>
    </>
  )
}
