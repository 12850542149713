import React, {useEffect, useState} from 'react'
import './AddressModal.scss'
import {AddCircleOutline, EnvironmentOutline, TruckOutline} from "antd-mobile-icons";
import {Button, Loading, Popup, Radio, Selector, Space, Toast} from "antd-mobile";
import {createParcel} from "services/my-order-client";
import {useMyOrders} from "context/my-order-context";
import {getAllAddress, getCourier} from "app/actions/address";
import {useDispatch, useSelector} from "react-redux";
import AddShippingAddress from "pages/Profile/components/ProfileDetails/AddShippingAddress";
import ReactGA from "react-ga4";
import {defaultKeys, OFFICE_ADDRESS, OFFICE_NAME, OFFICE_PHONE} from "consts/storage";
import {useLayoutContext} from "context/LayoutContext";

const AddressModal = ({ setViewAddressModal }) => {
    const { notifyMessages } = useLayoutContext();
    const { selectedProducts } = useMyOrders()
    const dispatch = useDispatch();
    const { address, loading, courier: courierItem, isCourier: isCourierLoading } = useSelector((state) => state.address);
    let deliveryMessage = notifyMessages?.[defaultKeys.deliveryMessageAfterCourier]?.label;

    const [courierValue, setCourierValue] = useState(null);
    const [, setSelectedCourier] = useState(null);
    const [isOfficePickup, setIsOfficePickup] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [isCreateParcelLoading, setIsCreateParcelLoading] = useState(false);
    const [isFirstCall, setIsFirstCall] = useState(true);
    const [addNewAddressView, setAddNewAddressView] = useState(false);

    useEffect(() => {
        if (isFirstCall){
            dispatch(getAllAddress())
            dispatch(getCourier())
            setIsFirstCall(false)
        }
    }, [dispatch, setIsFirstCall, isFirstCall])

    useEffect(() => {
        if (address?.data?.length) {
            setSelectedAddress([address.data[0].id])
        }
    }, [address])

    if (loading || isCourierLoading){
        return (
            <div className="loading_middle">
                <Loading color='primary'/>
            </div>
        )
    }

    let addressMoreItemList = address?.data?.map(item => {
        return {
            label: <Space direction="vertical" className="address_tab" style={{'--gap': '4px'}}>
                <span className="address_tab__name">{item?.first_name} {item?.last_name}</span>
                <span className="address_tab__address">{item?.address}, {item?.state}, {item?.city}, {item?.country}</span>
                <span className="address_tab__mobile">{item?.phone}</span>
            </Space>,
            value: item?.id
        }
    })

    let addressMoreItemListOffice = [{
        label: <Space direction="vertical" className="address_tab" style={{'--gap': '4px'}}>
          <span className="address_tab__name">{OFFICE_NAME}</span>
          <span className="address_tab__address">{OFFICE_ADDRESS}</span>
          <span className="address_tab__mobile">{OFFICE_PHONE}</span>
        </Space>,
        value: 1
      }]

    const handleSelectedAddress = (value) => {
      setSelectedAddress(value)
    }

    let mapSelectedProductList = selectedProducts?.map(item => {
        let productItem = item.products?.map(itemProduct => itemProduct.id)
        return {
            id : item.id,
            products: productItem
        }
    })

    const handleParcelSelect = () => {

        let data = {
            courier_id: courierValue ? courierValue : undefined,
            orders: mapSelectedProductList
        }

        if (!isOfficePickup) {
            data['address_id'] = selectedAddress?.length > 0 ? selectedAddress[0] : undefined;
        }

        setIsCreateParcelLoading(true)
        createParcel(data)
            .then(res => {
                window.location.reload()
                setViewAddressModal(false)
                setIsCreateParcelLoading(false)
                if (res?.data?.message){
                    Toast.show({
                        icon: "success",
                        content: res?.data?.message,
                    })
                }
                ReactGA.event({
                    category: 'parcel',
                    action: 'create_parcel',
                });
            })
            .catch(err => {
                setIsCreateParcelLoading(false)
                if (err?.response?.data?.errors?.orders){
                    Toast.show({
                        icon: 'fail',
                        content: err?.response?.data?.errors?.orders[0],
                    })
                }else if (err?.response?.data?.errors?.address_id){
                    Toast.show({
                        icon: 'fail',
                        content: err?.response?.data?.errors?.address_id[0],
                    })
                } else if (err?.response?.data?.errors?.courier_id){
                    Toast.show({
                        icon: 'fail',
                        content: err?.response?.data?.errors?.courier_id[0],
                    })
                }else if (err?.response?.data?.message){
                    Toast.show({
                        icon: 'fail',
                        content: err?.response?.data?.message,
                    })
                }
            })
    }

    return (
      <div className="address_modal">
          <Space block justify="between" style={{marginBottom: '3%'}}>
              <span className="address_modal__title"><EnvironmentOutline color='var(--adm-color-primary)' /> Delivery Address  </span>
              <span className="address_modal__title__value" onClick={() => { setAddNewAddressView(true)}}> <AddCircleOutline /> Add Address</span>
          </Space>
          {
            (address?.data?.length > 0 && !isOfficePickup) ?
                  <Selector
                      options={addressMoreItemList}
                      columns={2}
                      defaultValue={[addressMoreItemList[0]?.value]}
                      onChange={(arr) => handleSelectedAddress(arr)}
                  />
                :
                <Selector
                  options={addressMoreItemListOffice}
                  columns={2}
                  defaultValue={[addressMoreItemListOffice[0]?.value]}
                />
          }
          <div className="courier">
              <span className="address_modal__title"><TruckOutline color='var(--adm-color-primary)'/> Courier</span>
              <Radio.Group
                  value={courierValue}
                  onChange={(val)=>{
                      const desiredCourier = courierItem.find( courier => courier.id === val);
                      if (desiredCourier.id) {
                        setIsOfficePickup(desiredCourier.name.toLowerCase().replace(/\s/g, ``) === 'officepickup')
                        setSelectedCourier(desiredCourier)
                      }
                      setCourierValue(val)
                  }}>
                  <Space block wrap>
                      {
                          courierItem?.map((courier, index) => {
                              return (
                                  <Radio value={courier.id} key={`addressModel${index}`}>{courier.name}</Radio>
                              )
                          })
                      }
                  </Space>
              </Radio.Group>
          </div>
          <div className="delivery_info">
              <span className="delivery_info__title">{deliveryMessage}</span>
              <span className="delivery_info__title">Expected Delivery Time 24 to 48 hours </span>
          </div>

          <div className="delivery_button">
              <Button block color='primary' size='middle' onClick={() => handleParcelSelect()} loading={isCreateParcelLoading}>
                  Submit
              </Button>
          </div>

          <Popup
              visible={addNewAddressView}
              onMaskClick={() => {
                  setAddNewAddressView(false)
              }}
              bodyStyle={{
                  height: '85vh',
                  overflowY: 'scroll',
                  borderTopLeftRadius: '8px',
                  borderTopRightRadius: '8px',
              }}
              forceRender={true}
          >
              <AddShippingAddress isAddressEdit={false} setViewAddAddress={setAddNewAddressView}/>
          </Popup>

      </div>
    )
}

export default AddressModal
