import { Card, Grid, Popup, SearchBar, Space } from "antd-mobile";
import { GlobalHeaderBack, PageHead } from "components";
import { useLayoutContext } from "context/LayoutContext";
import { DemoBlock, ProductDetailsBlock } from "demos";
import ProductSearch from "pages/ProductSearch";
import { useCallback, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { getCampaignDetails } from "services/campaign-client";
import ImageRenderer from "utils/ImageRenderer";
import { ShowPriceWithCurrency } from "utils/helper";

export default function IndiaShippingChallenge() {
  const history = useHistory();
  const {
    indiaShippingChallengeConfigs: pageConfigs,
    preloaderImage,
    localeCurrency,
  } = useLayoutContext();
  const [isSearchView, setIsSearchView] = useState(false);
  const slug = useLocation().pathname.slice(1);
  const [productsData, setProductsData] = useState(null);
  const [isFirstCall, setIsFirstCall] = useState(true);
  const [fetchedCampaign, setFetchedCampaign] = useState(null);

  const getProductsFromApi = useCallback((params = {}) => {
    if (params?.campaign) {
      getCampaignDetails(params.campaign).then((res) => {
        const { data, campaign } = res.data;
        let resData = { items: data, campaign };
        setFetchedCampaign(resData);
        setIsFirstCall(false);
      });
    }
  }, []);

  useEffect(() => {
    if (isFirstCall) {
      getProductsFromApi({ campaign: slug });
      setIsFirstCall(false);
    }
  }, [isFirstCall, getProductsFromApi, slug]);

  useEffect(() => {
    if (fetchedCampaign?.items) {
      setProductsData(fetchedCampaign?.items);
    }
  }, [fetchedCampaign]);

  return (
    <>
      <GlobalHeaderBack
        title={
          pageConfigs?.page_title?.label || "India shipping Challenge offer"
        }
        height="50px"
        color="white"
      />
      <ProductDetailsBlock padding="3% 5%">
        <div className="page-wrapper space-y-8 pt-20 pb-40">
          <PageHead title="Wholesale" />
          <section
            className="container text-center space-y-2"
            style={{ textAlign: "center" }}
          >
            <h2
              style={{
                marginBottom: 0,
                padding: "20px 0",
                fontFamily: "Noto Sans Bengali, sans-serif",
              }}
            >
              {pageConfigs?.title?.label ??
                "ইন্ডিয়ার শিপিং চ্যালেঞ্জ টুর্নামেন্ট থাকছে ডাবল এনজয়মেন্ট"}
            </h2>
            <p
              className="text-base text-gray-500"
              style={{
                marginTop: 5,
                fontSize: 14,
                fontFamily: "Noto Sans Bengali, sans-serif",
              }}
            >
              {pageConfigs?.tagline?.label ??
                "আপনার পছন্দের প্রোডাক্ট লিঙ্কটি এখানে পেস্ট করে অংশগ্রহণ করুন ১৫ দিন ব্যাপী মেগা চ্যালেঞ্জ অফারে।"}
            </p>
          </section>
          <section className="search-field container flex justify-center items-center">
            <div className="xl:w-1/2">
              <button
                className="home_search_box"
                style={{
                  borderRadius: "10px",
                }}
              >
                <SearchBar
                  onSearch={(val) => {
                    history.push({
                      pathname: `/my-request/request`,
                      search: `?url=${encodeURIComponent(val)}`,
                    });
                  }}
                  placeholder="Paste link & tap enter/go to make request"
                />
              </button>
            </div>
            {pageConfigs?.stores_image && (
              <div style={{ marginTop: "6%" }}>
                <img
                  src={pageConfigs?.stores_image?.image}
                  width="100%"
                  alt="China india's stores"
                />
              </div>
            )}
          </section>
          <section
            style={{
              width: "100%",
              backgroundImage:
                "url(/assets/india-shipping-challenge-august-23.png)",
              backgroundSize: "cover",
              backgroundPosition: "36% 50%",
              height: "120px",
              backgroundRepeat: "no-repeat",
              margin: "30px 0 30px",
            }}
          ></section>
          <DemoBlock borderRadius="8px">
            <div
              style={{
                padding: "0 10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "5px",
              }}
            >
              <Link
                style={{
                  color: "var(--adm-color-text)",
                  textDecoration: "none",
                }}
                to="/campaigns/india-shipping-challenge"
              >
                <h4
                  style={{
                    fontFamily: "Noto Sans Bengali, sans-serif",
                    margin: "0",
                    fontSize: "1.17em",
                  }}
                >
                  বাছাইকৃত ইন্ডিয়ান প্রোডাক্ট দেখুনঃ
                </h4>
              </Link>
              <span>
                <Link
                  style={{ textDecoration: "none" }}
                  to="/campaigns/india-shipping-challenge"
                >
                  View All
                </Link>
              </span>
            </div>
            {productsData && productsData?.length > 0 ? (
              <Card>
                <Grid columns={3} gap={12}>
                  {productsData &&
                    productsData?.length > 0 &&
                    productsData?.slice(0, 3)?.map((product, index) => {
                      let discountPrice = product?.discount_price?.min;
                      let originalPrice = product?.price?.min;

                      if (product?.price?.discount?.min) {
                        discountPrice = product?.price?.discount?.min;
                      } else if (product?.price?.discount?.max) {
                        discountPrice = product?.price?.discount?.max;
                      }

                      if (product?.price?.original?.min) {
                        originalPrice = product?.price?.original?.min;
                      } else if (product?.price?.original?.max) {
                        originalPrice = product?.price?.original?.max;
                      }

                      return (
                        <Grid.Item key={`category_product${index}`}>
                          <Link
                            className="link_underline"
                            to={{
                              pathname: `/products/details`,
                              search: `?ipid=${product.id}&campaign=${product?.pivot?.campaign_id}&campaign_product=${product?.pivot?.id}`,
                              state: {
                                title: product.title,
                                price: discountPrice
                                  ? discountPrice
                                  : originalPrice,
                                image: product?.image,
                                link: product?.link,
                              },
                            }}
                          >
                            <Grid
                              columns={1}
                              gap={8}
                              style={{ alignItems: "center" }}
                            >
                              <Grid.Item>
                                <div className="campaign_product">
                                  <div className="campaign_product__thumb">
                                    <ImageRenderer
                                      key={1}
                                      url={product?.image}
                                      thumb={product?.image}
                                      width={60}
                                      height={60}
                                      alt={`ProductImage01`}
                                      imageBackground={
                                        preloaderImage ??
                                        "/assets/product/preloader-bg_184X184.png"
                                      }
                                    />
                                  </div>
                                </div>
                              </Grid.Item>
                              <Grid.Item style={{ placeSelf: "center" }}>
                                <span className="campaign_title">
                                  {" "}
                                  {product?.title}{" "}
                                </span>
                              </Grid.Item>
                              <Grid.Item style={{ placeSelf: "center" }}>
                                <Space className="campaign_price">
                                  {discountPrice ? (
                                    <span className="campaign_price__price">
                                      {ShowPriceWithCurrency({
                                        price: discountPrice,
                                        currency: localeCurrency,
                                      })}
                                    </span>
                                  ) : (
                                    <span className="campaign_price__price">
                                      {ShowPriceWithCurrency({
                                        price: originalPrice,
                                        currency: localeCurrency,
                                      })}
                                    </span>
                                  )}
                                  {discountPrice && (
                                    <del className="campaign_price__del">
                                      {ShowPriceWithCurrency({
                                        price: originalPrice,
                                        currency: localeCurrency,
                                      })}
                                    </del>
                                  )}
                                </Space>
                              </Grid.Item>
                            </Grid>
                          </Link>
                        </Grid.Item>
                      );
                    })}
                </Grid>
              </Card>
            ) : (
              <Card>
                <div style={{ textAlign: "center" }}>Data not found</div>
              </Card>
            )}
          </DemoBlock>
          <section className="container campaign-marketplaces py-8 flex justify-center items-center">
            <div
              className="lg:w-1/2 text-center"
              style={{
                fontFamily: "Noto Sans Bengali, sans-serif",
                textAlign: "center",
                margin: "10px 0",
              }}
            >
              আবারো চলে এলো Ali2BD এর শিপিং মেগা চ্যালেঞ্জ অফার। অংশগ্রহণ করতে
              Indian Amazon, Myntra কিংবা Flipkart থেকে যেকোনো প্রোডাক্ট কিনুন
              স্বাচ্ছন্দ্যে। কারণ ১৫ দিনে প্রোডাক্ট দেশে না আসলে শিপিং চার্জ
              একদম ফ্রি!
            </div>
          </section>
          <section className="container campaign-marketplaces py-8 flex justify-center items-center">
            <div
              className="lg:w-1/2 text-center"
              style={{
                fontFamily: "Noto Sans Bengali, sans-serif",
                textAlign: "center",
                marginBottom: "20px",
              }}
            >
              {pageConfigs?.time_content?.label ??
                "আপনার পছন্দের প্রোডাক্ট লিঙ্ক পেস্ট করুন উপরের সার্চ বারে। অফারের সময়সীমা- ১৬ আগস্ট  রাত ১১ঃ৫৯ পর্যন্ত। এবার পছন্দের শপিং হবে ধুমধাম। "}
            </div>
          </section>
          <section className="container campaign-marketplaces py-8 flex justify-center items-center">
            <div
              className="lg:w-1/2 text-center"
              style={{
                fontFamily: "Noto Sans Bengali, sans-serif",
                textAlign: "center",
              }}
            >
              <h2>শিপিং চ্যালেঞ্জ টুর্নামেন্টে আপনি জিতবেন তো? </h2>
            </div>
          </section>
          <section className="container campaign-rules py-8">
            <div
              className="flex justify-center items-center"
              style={{ fontFamily: "Noto Sans Bengali, sans-serif" }}
            >
              <div
                className="lg:w-1/2 border border-gray-200 rounded py-5 px-12 drop-shadow"
                style={{
                  marginBottom: "50px",
                }}
              >
                <div
                  className="flex justify-center items-center text-center mb-6"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <h3
                    className="block text-xl font-bold bg-primary text-white rounded-full px-8 p-2"
                    style={{
                      backgroundColor: "var(--adm-color-primary)",
                      color: "#fff",
                      borderRadius: "9999px",
                      padding: "8px 36px",
                      marginBottom: "2px",
                    }}
                  >
                    শিপিং চ্যালেঞ্জের সার্বিক নিয়মাবলী
                  </h3>
                </div>
                <ul
                  className="list-decimal leading-7"
                  style={{
                    listStyleType: "bengali",
                    lineHeight: "1.5rem",
                  }}
                >
                  <li>
                    অর্ডারকৃত পণ্য অবশ্যই Indian Amazon, Flipkart এবং Myntra
                    শপিং প্ল্যাটফর্মের হতে হবে। অন্য কোন প্ল্যাটফর্মের ক্ষেত্রে
                    এই অফার প্রযোজ্য নয়।
                  </li>
                  <li>
                    ১৫ কার্যদিবসের মধ্যে পণ্য দেশে অর্থাৎ বাংলাদেশ এয়ারপোর্টে
                    পৌঁছে গেলে কেজি প্রতি শিপিং চার্জ প্রযোজ্য হবে। যদি এই
                    সময়সীমার মাঝে দেশে না আসে তবেই শিপিং চার্জ সম্পূর্ণ ফ্রি।
                  </li>
                  <li>
                    {pageConfigs?.time_content_with_date?.label ??
                      "অর্ডার প্লেস করতে হবে ১৬ আগস্ট  রাত ১১ঃ৫৯ ঘটিকার মধ্যে।"}
                  </li>
                  <li>
                    কোন কাস্টমাইজড প্রোডাক্ট এই অফারে অর্ডার করা যাবে না।
                    প্রোডাক্ট কাস্টমাইজেশন একটি সময় সাপেক্ষ ব্যাপার।
                  </li>
                  <li>
                    সেলার প্রোডাক্ট দিতে দেরি করবে এমন পণ্য এই অফারে অন্তর্ভুক্ত
                    নয়। কারণ এই ক্ষেত্রে দেরি সেলারের পক্ষ থেকে হবে।
                  </li>
                  <li>
                    কোন অনাকাঙ্ক্ষিত কারণ যেমন প্রাকৃতিক দুর্যোগ, আকস্মিক লক
                    ডাউন, হরতাল, এই সকল ক্ষেত্রে Ali2BD এই চ্যালেঞ্জের শর্ত
                    পূরণে দায়ী নয়।
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section className="container campaign-marketplaces py-8 flex justify-center items-center">
            <div
              className="lg:w-1/2 text-center"
              style={{
                fontFamily: "Noto Sans Bengali, sans-serif",
                textAlign: "center",
                marginBottom: "50px",
              }}
            >
              Ali2BD ডাবল চ্যালেঞ্জের সকল নিয়মাবলী পরিবর্তন এবং বাতিলের অধিকার
              এক মাত্র Ali2BD দ্বারা সংরক্ষিত। Do Shopping, Forget Borders.
            </div>
          </section>

          <Popup
            visible={isSearchView}
            onMaskClick={() => {
              setIsSearchView(false);
            }}
            position="right"
            bodyStyle={{
              height: "180vh",
              width: "100vw",
            }}
            destroyOnClose={true}
          >
            <ProductSearch
              setIsSearchView={setIsSearchView}
              isSearchView={isSearchView}
              // componentFrom="wholesale_page"
            />
          </Popup>
        </div>
      </ProductDetailsBlock>
    </>
  );
}
