import client from './api-client'
import Qs from "qs";

const getMyWallet = () => {
    return client.get('/wallet/balance')
}

const getMyWalletTransactions = (page = null, perPage = 15) => {
    let data = {
        page: page ? page : 1,
        per_page: perPage
    }

    return client.get('/wallet/transactions', {
        params: data,
        paramsSerializer: function (params) {
            return Qs.stringify(params, {arrayFormat: 'brackets'})
        },
    })
}

const getWithdrawalsMethod = () => {
    return client.get('wallet/withdrawals/methods')
}

const storeWithdrawalsMethod = (data) => {
    return client.post(`wallet/withdrawals/methods`, data)
}

const makeWithdrawal = (data) => {
    return client.post(`wallet/withdrawals/request`, data)
}

const getWithdrawalTransactions = (page = null, perPage = 15) => {
    let data = {
        page: page ? page : 1,
        per_page: perPage
    }

    return client.get('/wallet/withdrawals', {
        params: data,
        paramsSerializer: function (params) {
            return Qs.stringify(params, {arrayFormat: 'brackets'})
        },
    })
}

export {
    getMyWallet,
    getWithdrawalsMethod,
    storeWithdrawalsMethod,
    getMyWalletTransactions,
    makeWithdrawal,
    getWithdrawalTransactions
}
