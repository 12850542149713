import React, {useCallback, useEffect, useState} from 'react'
import {getMyOrders, getOrderParcel} from "services/my-order-client";

const MyOrderContext = React.createContext()

const MyOrderProvider = (props) => {

    const [selectedTab, setSelectedTab] = useState(null);
    const [selectedProducts, setSelectedProducts] = useState([]);

    const [isFirstCall, setIsFirstCall] = useState({
        myOrder: true,
        toPay: true,
        toBePurchased: true,
        toInTransit: true,
        toDelivered: true,
        toBeDeliverable: true,
        orderParcel: true,
        cancel: true
    });

    const [myOrders, setMyOrders] = useState({
        data: [],
        meta: null,
    })

    const [toPay, setToPay] = useState({
        data: [],
        meta: null,
    })

    const [toPurchased, setToPurchased] = useState({
        data: [],
        meta: null,
    })

    const [canceled, setCanceled] = useState({
        data: [],
        meta: null,
    })

    const [toInTransit, setToInTransit] = useState({
        data: [],
        meta: null,
    })

    const [toDelivered, setToDelivered] = useState({
        data: [],
        meta: null,
    })

    const [toDeliverable, setToDeliverable] = useState({
        data: [],
        meta: null,
    })

    const [orderParcels, setOrderParcels] = useState({
        data: [],
        meta: null,
    })

    const [myOrdersLoadings, setMyOrdersLoadings] = useState({
        isPageLoading: false,
        isButtonLoading: false,
    })

    const [toPayLoadings, setToPayLoadings] = useState({
        isPageLoading: false,
        isButtonLoading: false,
    })

    const [toPurchasedLoadings, setToPurchasedLoadings] = useState({
        isPageLoading: false,
        isButtonLoading: false,
    })

    const [toCancelLoadings, setToCancelLoadings] = useState({
        isPageLoading: false,
        isButtonLoading: false,
    })

    const [toTransitLoadings, setToTransitLoadings] = useState({
        isPageLoading: false,
        isButtonLoading: false,
    })

    const [toDeliveredLoadings, setToDeliveredLoadings] = useState({
        isPageLoading: false,
        isButtonLoading: false,
    })

    const [toDeliverableLoadings, setToDeliverableLoadings] = useState({
        isPageLoading: false,
        isButtonLoading: false,
    })

    const [toOrderParcelLoadings, setToOrderParcelLoadings] = useState({
        isPageLoading: false,
        isButtonLoading: false,
    })

    const fetchMyOrders = useCallback(
        (page) => {
            setMyOrdersLoadings({
                isPageLoading: myOrders.data.length <= 0,
                isButtonLoading: true
            })
            getMyOrders(page).then((response) => {
                let newData = [...myOrders.data, ...response.data.data]
                setMyOrders({
                    data: newData,
                    meta: response.data.meta,
                })
                setMyOrdersLoadings({
                    isPageLoading: false,
                    isButtonLoading: false,
                })
            }).catch(error => {
                if (error.response.status === 401) {
                    window.location.href = "/auth/login"
                }
                setMyOrdersLoadings({
                    isPageLoading: false,
                    isButtonLoading: false,
                })
            })
        },
        [setMyOrders, setMyOrdersLoadings, myOrders],
    )

    const fetchMyToPay = useCallback(
        (page) => {
            setToPayLoadings({
                isPageLoading: toPay.data.length <= 0,
                isButtonLoading: true
            })
            getMyOrders(page, {paymentStatus: 'unpaid', payType: ['online-full']})
                .then((response) => {
                let newData = [...toPay.data, ...response.data.data]
                setToPay({
                    data: newData,
                    meta: response.data.meta,
                })
                setToPayLoadings({
                    isPageLoading: false,
                    isButtonLoading: false,
                })
            }).catch(error => {
                if (error.response.status === 401) {
                    window.location.href = "/auth/login"
                }
                setToPayLoadings({
                    isPageLoading: false,
                    isButtonLoading: false,
                })
            })
        },
        [setToPay, setToPayLoadings, toPay],
    )

    const fetchToBePurchased = useCallback(
        (page) => {
            setToPurchasedLoadings({
                isPageLoading: toPurchased.data.length <= 0,
                isButtonLoading: true
            })
            getMyOrders(page, {productStatus: 'in_transit'})
                .then((response) => {
                    let newData = [...toPurchased.data, ...response.data.data]
                    setToPurchased({
                        data: newData,
                        meta: response.data.meta,
                    })
                    setToPurchasedLoadings({
                        isPageLoading: false,
                        isButtonLoading: false,
                    })
                }).catch(error => {
                if (error.response.status === 401) {
                    window.location.href = "/auth/login"
                }
                setToPurchasedLoadings({
                    isPageLoading: false,
                    isButtonLoading: false,
                })
            })
        },
        [setToPurchased, setToPurchasedLoadings, toPurchased],
    )

    const fetchToBeDelivered = useCallback(
        (page) => {
            setToDeliveredLoadings({
                isPageLoading: toDelivered.data.length <= 0,
                isButtonLoading: true
            })
            getMyOrders(page, {productStatus: 'delivered'})
                .then((response) => {
                    let newData = [...toDelivered.data, ...response.data.data]
                    setToDelivered({
                        data: newData,
                        meta: response.data.meta,
                    })
                    setToDeliveredLoadings({
                        isPageLoading: false,
                        isButtonLoading: false,
                    })
                }).catch(error => {
                if (error.response.status === 401) {
                    window.location.href = "/auth/login"
                }
                setToDeliveredLoadings({
                    isPageLoading: false,
                    isButtonLoading: false,
                })
            })
        },
        [setToDelivered, setToDeliveredLoadings, toDelivered],
    )

    const fetchToBeTransit = useCallback(
        (page) => {
            setToTransitLoadings({
                isPageLoading: toInTransit.data.length <= 0,
                isButtonLoading: true
            })
            getMyOrders(page, {productStatus: 'in_transit'})
                .then((response) => {
                    let newData = [...toInTransit.data, ...response.data.data]
                    setToInTransit({
                        data: newData,
                        meta: response.data.meta,
                    })
                    setToTransitLoadings({
                        isPageLoading: false,
                        isButtonLoading: false,
                    })
                }).catch(error => {
                if (error.response.status === 401) {
                    window.location.href = "/auth/login"
                }
                setToTransitLoadings({
                    isPageLoading: false,
                    isButtonLoading: false,
                })
            })
        },
        [setToInTransit, setToTransitLoadings, toInTransit],
    )

    const fetchToBeDeliverable = useCallback(
        (page) => {
            setToDeliverableLoadings({
                isPageLoading: toDeliverable.data.length <= 0,
                isButtonLoading: true
            })
            getMyOrders(page, {productStatus: 'received_in_country'})
                .then((response) => {
                    let newData = [...toDeliverable.data, ...response.data.data]
                    setToDeliverable({
                        data: newData,
                        meta: response.data.meta,
                    })
                    setToDeliverableLoadings({
                        isPageLoading: false,
                        isButtonLoading: false,
                    })
                }).catch(error => {
                if (error.response.status === 401) {
                    window.location.href = "/auth/login"
                }
                setToDeliverableLoadings({
                    isPageLoading: false,
                    isButtonLoading: false,
                })
            })
        },
        [setToDeliverable, setToDeliverableLoadings, toDeliverable],
    )

    const fetchOrderParcel = useCallback(
        (page) => {
            setToOrderParcelLoadings({
                isPageLoading: orderParcels.data.length <= 0,
                isButtonLoading: true
            })
            getOrderParcel(page).then((response) => {
                let newData = [...orderParcels.data, ...response.data.data]
                setOrderParcels({
                    data: newData,
                    meta: response.data.meta,
                })
                setToOrderParcelLoadings({
                    isPageLoading: false,
                    isButtonLoading: false,
                })
            }).catch(error => {
                if (error.response.status === 401) {
                    window.location.href = "/auth/login"
                }
                setToOrderParcelLoadings({
                    isPageLoading: false,
                    isButtonLoading: false,
                })
            })
        },
        [setOrderParcels, setToOrderParcelLoadings, orderParcels],
    )

    const fetchCancelOrder = useCallback(
        (page) => {
            setToCancelLoadings({
                isPageLoading: canceled.data.length <= 0,
                isButtonLoading: true
            })
            getMyOrders(page, {productStatus: 'cancelled'})
                .then((response) => {
                    let newData = [...canceled.data, ...response.data.data]
                    setCanceled({
                        data: newData,
                        meta: response.data.meta,
                    })
                    setToCancelLoadings({
                        isPageLoading: false,
                        isButtonLoading: false,
                    })
                }).catch(error => {
                if (error.response.status === 401) {
                    window.location.href = "/auth/login"
                }
                setToCancelLoadings({
                    isPageLoading: false,
                    isButtonLoading: false,
                })
            })
        },
        [setCanceled, setToCancelLoadings, canceled],
    )


    const handlerSelectedTab = (value) => {
      setSelectedTab(value)
    }

    useEffect(() => {
        if (isFirstCall.myOrder && selectedTab === "all") {
            fetchMyOrders()
            setIsFirstCall({
                ...isFirstCall,
                myOrder: false,
            })
        }
        if (isFirstCall.toPay && selectedTab === "to_pay") {
            fetchMyToPay()
            setIsFirstCall({
                ...isFirstCall,
                toPay: false,
            })
        }
        if (isFirstCall.toBePurchased && selectedTab === "to_be_purchased") {
            fetchToBePurchased()
            setIsFirstCall({
                ...isFirstCall,
                toBePurchased: false,
            })
        }
        if (isFirstCall.toInTransit && selectedTab === "to_inTransit") {
            fetchToBeTransit()
            setIsFirstCall({
                ...isFirstCall,
                toInTransit: false,
            })
        }
        if (isFirstCall.toDelivered && selectedTab === "delivered") {
            fetchToBeDelivered()
            setIsFirstCall({
                ...isFirstCall,
                toDelivered: false,
            })
        }
        if (isFirstCall.toBeDeliverable && selectedTab === "to_deliverable") {
            fetchToBeDeliverable()
            setIsFirstCall({
                ...isFirstCall,
                toBeDeliverable: false,
            })
        }
        if (isFirstCall.orderParcel && selectedTab === "order_parcel") {
            fetchOrderParcel()
            setIsFirstCall({
                ...isFirstCall,
                orderParcel: false,
            })
        }
        if (isFirstCall.cancel && selectedTab === "cancel") {
            fetchCancelOrder()
            setIsFirstCall({
                ...isFirstCall,
                cancel: false,
            })
        }
    }, [
        fetchOrderParcel,
        orderParcels.data,
        isFirstCall.orderParcel,
        fetchToBeDeliverable,
        toDeliverable.data,
        isFirstCall.toBeDeliverable,
        fetchToBeDelivered,
        toDelivered.data,
        isFirstCall.toDelivered,
        fetchToBeTransit,
        toInTransit.data,
        isFirstCall.toInTransit,
        fetchToBePurchased,
        toPurchased.data,
        isFirstCall.toBePurchased,
        fetchMyOrders,
        myOrders.data,
        isFirstCall.myOrder,
        fetchMyToPay,
        toPay.data,
        isFirstCall.toPay,
        selectedTab,
        isFirstCall,
        setIsFirstCall,
        isFirstCall.cancel,
        fetchCancelOrder,
    ])

    const loadMoreAllOrders = () => {
        if (myOrders.meta) {
            fetchMyOrders((myOrders.meta.current_page + 1))
        }
    }

    const loadMoreToPay = () => {
        if (toPay.meta) {
            fetchMyToPay((toPay.meta.current_page + 1))
        }
    }

    const loadMoreToPurchased = () => {
        if (toPurchased.meta) {
            fetchToBePurchased((toPurchased.meta.current_page + 1))
        }
    }

    const loadMoreToInTransit = () => {
        if (toInTransit.meta) {
            fetchToBeTransit((toInTransit.meta.current_page + 1))
        }
    }

    const loadMoreToDeliverable = () => {
        if (toDeliverable.meta) {
            fetchToBeDeliverable((toDeliverable.meta.current_page + 1))
        }
    }

    const loadMoreToDelivered = () => {
        if (toDelivered.meta) {
            fetchToBeDelivered((toDelivered.meta.current_page + 1))
        }
    }

    const loadMoreOrderParcel = () => {
        if (orderParcels.meta) {
            fetchOrderParcel((orderParcels.meta.current_page + 1))
        }
    }

    const loadMoreCancelOrder = () => {
        if (canceled.meta) {
            fetchCancelOrder((canceled.meta.current_page + 1))
        }
    }

    return <MyOrderContext.Provider value={{
        myOrders,
        myOrdersLoadings,
        toPay,
        toPayLoadings,
        handlerSelectedTab,
        toPurchased,
        toPurchasedLoadings,
        toInTransit,
        toTransitLoadings,
        toDelivered,
        toDeliveredLoadings,
        toDeliverable,
        toDeliverableLoadings,
        orderParcels,
        toOrderParcelLoadings,
        loadMoreAllOrders,
        loadMoreToPay,
        loadMoreToPurchased,
        loadMoreToInTransit,
        loadMoreToDeliverable,
        loadMoreToDelivered,
        loadMoreOrderParcel,
        setSelectedProducts,
        selectedProducts,
        loadMoreCancelOrder,
        canceled,
        toCancelLoadings
    }} {...props} />
}

const useMyOrders = () => {
    const context = React.useContext(MyOrderContext)
    if (context === undefined) {
        throw new Error(`useMyOrders must be used within a MyOrderProvider`)
    }
    return context
}

export { MyOrderProvider, useMyOrders }
