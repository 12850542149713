import * as types from 'app/actionTypes/category';
import { call, put, takeEvery } from 'redux-saga/effects';
import {getAllCategory} from "../../services/product-client";

const getCategoryApi = async (filters = {}) => {
    return getAllCategory(filters)
        .then(res => res.data)
        .catch((error) => { throw error })
}

function* fetchCategory(action) {
    try {
        const data = yield call(() => getCategoryApi(action.payload));
        yield put({ type: types.GET_SHIPPING_CATEGORY_SUCCESS, payload: {data, filters: action.payload} });
    } catch (e) {
        yield put({ type: types.GET_SHIPPING_CATEGORY_FAILED, message: e.message });
    }
}

function* categorySaga() {
    yield takeEvery(types.GET_SHIPPING_CATEGORY_REQUESTED, fetchCategory);
}

export default categorySaga;
