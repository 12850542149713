import React, { useEffect, useState } from 'react';
import { SearchProductDetails } from "components";
import { Grid, InfiniteScroll, Loading, Popup, Space } from "antd-mobile";
import '../SellerDetails.scss'
import SearchFilter from "../../ProductSearch/components/SearchFilters";
import { getProductsSearch, navigateToNewSearch } from "app/actions/products";
import { useDispatch } from "react-redux";
import useFilters from "hooks/useFilters";
import { FilterOutlined } from "@ant-design/icons";
import { ProductDetailsBlock } from "demos";

const SellerProductDetails = (props) => {

  const { products, paginate, filters: filtersData } = props
  const dispatch = useDispatch();

  let isFetched = true

  const {
    filters,
    handleFilterChange,
    handleFilterClear,
    updateQueryParams
  } = useFilters();

  const [viewFilterList, setViewFilterList] = useState(false);
  const [hasMore, setHasMore] = useState(false)

  const handleProductFilter = () => {
    dispatch(getProductsSearch(filters));
    setViewFilterList(false)
    updateQueryParams()
  };

  const InfiniteScrollContent = ({ hasMore }) => {
    return (
      <>
        {
          hasMore ? (
            <span>End of products</span>
          )
            : (
              <div className="loading_middle">
                <Loading color='primary' />
              </div>
            )
        }
      </>
    )
  }

  let totalPage = parseInt(paginate?.last_page - 1)

  useEffect(() => {
    if (paginate?.current_page < totalPage) {
      setHasMore(true)
    }
  }, [paginate?.current_page, totalPage]);

  const loadMoreProducts = () => {
    if (paginate) {
      dispatch(getProductsSearch({ ...filters, ...{ page: paginate.current_page + 1 } }, true));
      setHasMore(false)
    }
  }

  const onFilterClear = () => {
    dispatch(navigateToNewSearch());
    window.scroll(0, 0)
    handleFilterClear();
  }

  return (
    <>
      <div className="seller_product_details">
        {
          filtersData &&
          <ProductDetailsBlock padding="0">
            <Space block justify="end" align="end" className="seller_filter">
              <span className="seller_filter__icons" onClick={() => {
                setViewFilterList(true)
              }}><FilterOutlined />  Filter</span>
            </Space>
          </ProductDetailsBlock>
        }
        <Grid columns={2} gap={8} className="masonry_grid_seller">
          {
            products?.map((product, index) => {
              return (
                <Grid.Item key={`justForYou${index}`} className="search_results_grid">
                  <SearchProductDetails product={product} />
                </Grid.Item>
              )
            })
          }
        </Grid>
        {
          products && paginate?.last_page > 1 && paginate?.current_page !== paginate?.last_page &&
          <InfiniteScroll hasMore={hasMore} loadMore={loadMoreProducts}>
            <InfiniteScrollContent hasMore={hasMore} />
          </InfiniteScroll>
        }
      </div>

      <Popup
        visible={viewFilterList}
        onMaskClick={() => {
          setViewFilterList(false)
        }}
        position='right'
        bodyStyle={{ minWidth: '75vw', width: '50px' }}
      >
        <SearchFilter
          setViewFilterList={setViewFilterList}
          handleProductFilter={handleProductFilter}
          handleFilterChange={handleFilterChange}
          handleFilterClear={onFilterClear}
          isFetched={isFetched}
          filters={filters}
          filtersData={filtersData}
        />
      </Popup>
    </>
  );
}

export default SellerProductDetails;
