import * as actionTypes from 'app/actionTypes/builder';
import { all, call, put, takeEvery } from "redux-saga/effects";
import { getBuilder, getMobileBuilder } from 'services/builder';

const getBuilderApi = (id) => {
    return getBuilder(id)
        .then(res => res.data?.data ?? res.data)
        .catch((error) => {throw error})
}

function* fetchBuilderData(action) {
    try {
        const data = yield call(() => getBuilderApi(action.payload));
        yield put({type: actionTypes.GET_BUILDER_SUCCEEDED, payload: data})
    }
    catch(e) {
        yield put({type: actionTypes.GET_BUILDER_FAILED, error: e.message})
    }
}

const getDefaultBuilderApi = (id) => {
  return getMobileBuilder(id)
      .then(res => res.data?.data ?? res.data)
      .catch((error) => {throw error})
}

function* fetchDefaultBuilder(action) {
  try {
      const data = yield call(() => getDefaultBuilderApi(action.payload));
      yield put({type: actionTypes.DEFAULT_BUILDER_SUCCEEDED, payload: data})
  }
  catch(e) {
      yield put({type: actionTypes.DEFAULT_BUILDER_FAILED, error: e.message})
  }
}

function* builderSaga() {
    yield all([
        takeEvery(actionTypes.GET_BUILDER_REQUESTED, fetchBuilderData),
        takeEvery(actionTypes.DEFAULT_BUILDER_REQUESTED, fetchDefaultBuilder),
    ])
}

export default builderSaga;
