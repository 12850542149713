import React from 'react';
import InternalGateway from './InternalGateway';
import PaymentDetails from './PaymentDetails';

const GatewayFields = (props) => {
  const { data } = props;
  return (
    <div>
      {
        (data && data.type === 'internal') && <InternalGateway data={data} />
      }
      {
        (data && data.type === 'external') && <PaymentDetails data={data} />
      }
    </div>
  )
}

export default GatewayFields;
