import React, { useEffect, useState } from "react"
import "../../Cart/Cart.scss"
import { GlobalHeaderBack, PaymentTypes } from "components";
import { ProductDetailsBlock } from "demos";
import { useCart } from "context/cart-context";
import CheckoutProductDetails from "./CheckoutProductDetails";
import PlaceOrderTabBar from "layout/PlaceOrderTabBar";
import { Button, Checkbox, Divider, Grid, Popup, Skeleton, Space, Tag, TextArea } from "antd-mobile";
import { LocationFill, PhoneFill, TeamFill } from "antd-mobile-icons";
import CheckoutDeliveryAddressList from "./CheckoutDeliveryAddressList";
import AddShippingAddress from "../../Profile/components/ProfileDetails/AddShippingAddress";
import { getBasicGateways } from "app/actions/gateways";
import { useDispatch, useSelector } from "react-redux";
import { getAllAddress } from "app/actions/address";
import { useHistory, useLocation } from "react-router-dom";
import routePaths from "consts/route-paths";
import { useLayoutContext } from "context/LayoutContext";
import { ShowPriceWithCurrency, getFloatingPrice } from "utils/helper";

const AddressComp = ({ address, type, children }) => {
  return (
    <Space block className="shipping_grid" direction="vertical">
      <Tag
        style={{ padding: '3px 6px' }}
      >
        {type ?? 'Shipping Address'}
      </Tag>
      <span
        className="shipping_grid__title"><TeamFill /> {address?.first_name} {address?.last_name}</span>
      <span
        className="shipping_grid__phone"><PhoneFill /> {address?.phone}</span>
      <div className="shipping_flex">
        <span><LocationFill /></span>
        <span
          className="shipping_grid__address"> {address?.address}, {address?.state}, {address?.city}, {address?.country} - {address?.postal_code}</span>
      </div>
      {children}
    </Space>
  )
}

const CheckoutDetails = () => {
  const { localeCurrency } = useLayoutContext();
  const { state } = useLocation();
  const history = useHistory();
  const {
    checkoutItems,
    setCheckoutItems,
    setIsCheckoutOutAgreeCheck,
    isCheckoutOutAgreeCheck,
    setAddAdditionalNote,
    setViewAddressList,
    viewAddressList,
    setIsChangeableAddress,
    isChangeableAddress,
    selectedBillingAddress,
    selectedShippingAddress,
    isUseWallet,
    setIsUseWallet,
    walletCurrentBalance,
    partiallyUseWalletBalance,
    fullyUseWalletBalance,
    restAmountToPay,
    state: cartState,
  } = useCart()
  const dispatch = useDispatch();
  const { address, loading } = useSelector((state) => state.address);
  const [isFirstCall, setIsFirstCall] = useState(true);

  let checkoutItemsList = checkoutItems

  if (state) {
    checkoutItemsList = state.data
  }

  useEffect(() => {
    setCheckoutItems(checkoutItemsList)
  }, [setCheckoutItems, checkoutItemsList])

  if (checkoutItemsList?.length === 0) {
    history.push('/cart')
  }

  const checkoutTotal = checkoutItemsList.reduce((acc, value) => acc + (parseFloat(value.price)), 0)?.toFixed(2)

  const [addNewAddressView, setAddNewAddressView] = useState(false);
  const [shippingCheckboxValue, setShippingCheckboxValue] = useState(true);
  const [selectedAddress, setSelectedAddress] = useState(null);

  useEffect(() => {
    if (isFirstCall) {
      dispatch(getBasicGateways())
      dispatch(getAllAddress())
      setIsFirstCall(false)
    }
  }, [isFirstCall, dispatch]);

  let viewAddAddress = address.data
  let shippingAddress = address.defaultShipping
  let isShippingAddressLoading = loading

  const ChangeShippingAddress = ({ text = "Change Address" }) => (
    <Button color="primary" size="mini" onClick={() => {
      setViewAddressList(true)
      setIsChangeableAddress("shipping")
      setSelectedAddress(selectedShippingAddress)
    }}>{text}</Button>
  )

  const getShippingAddress = () => {
    if (selectedShippingAddress) {
      return (
        <AddressComp address={selectedShippingAddress}>
          <ChangeShippingAddress />
        </AddressComp>
      )
    }
    else if (shippingAddress) {
      return (
        <AddressComp address={shippingAddress}>
          <ChangeShippingAddress />
        </AddressComp>
      )
    }
    else {
      return (
        <Button color="primary" size="mini" onClick={() => {
          setViewAddressList(true)
          setIsChangeableAddress("shipping")
          setSelectedAddress(selectedShippingAddress)
        }}>Select an Address</Button>
      )
    }
  }

  const ToTheSameBilling = 'Bill to the same Address'
  // const ToTheDefaultBilling = 'Bill to the default Billing Address'

  const ChangeBillingAddress = ({ text = "Change Address" }) => (
    <Button color="primary" size="mini" onClick={() => {
      setViewAddressList(true)
      setIsChangeableAddress("billing")
      setSelectedAddress(selectedBillingAddress)
    }}>{text}</Button>
  )

  const getSameAsAddress = ({ SameAsComp, showCheckbox = true }) => (
    <Space block justify="between" align="center" style={{ marginTop: "3%" }}>
      {
        showCheckbox &&
        <Checkbox
          onChange={(val) => {
            setShippingCheckboxValue(preState => !preState)
          }}
          checked={shippingCheckboxValue}
          style={{
            "--icon-size": "18px",
            "--font-size": "14px",
            "--gap": "6px",
          }}> {SameAsComp ?? 'Select an address'}</Checkbox>
      }
      <ChangeBillingAddress />
    </Space>
  )

  const getBillingAddress = () => {
    if (selectedBillingAddress) {
      if (selectedShippingAddress?.id && selectedBillingAddress?.id === selectedShippingAddress?.id) {
        return getSameAsAddress({ SameAsComp: ToTheSameBilling })
      }
      // else if (address?.default_billing?.id && selectedBillingAddress?.id === address?.default_billing?.id) {
      //   return getSameAsAddress({ SameAsComp: ToTheDefaultBilling })
      // }
      else {
        return (
          <>
            <AddressComp address={selectedBillingAddress} type="Billing Address">
              <ChangeBillingAddress />
            </AddressComp>
          </>
        )
      }
    }
    // else if (address?.default_billing?.id) {
    //   return getSameAsAddress({ SameAsComp: ToTheDefaultBilling })
    // }
    else if (selectedShippingAddress || (selectedShippingAddress?.id === address?.default_shipping?.id)) {
      return getSameAsAddress({ SameAsComp: ToTheSameBilling })
    }
    else if (address?.default_shipping?.id) {
      return getSameAsAddress({ SameAsComp: ToTheSameBilling })
    }
    else if (!selectedBillingAddress && !selectedShippingAddress && !address?.default_billing?.id) {
      return getSameAsAddress()
    } else {
      return (
        <>
          <AddressComp address={selectedBillingAddress} type="Billing Address">
            <ChangeBillingAddress />
          </AddressComp>
        </>
      )
    }
  }

  let groupedItems = {};
  let totalItems = 0;
  if (Object.keys(cartState.groupedItems)?.length) {
    for (const sellerKey in cartState.groupedItems) {
      const seller = cartState.groupedItems[sellerKey];
      const filteredItems = seller.filter(item => item.isSelected);
      const flen = filteredItems.length;
      if (flen) {
        groupedItems[sellerKey] = filteredItems;
        totalItems += flen;
      }
    }
  }

  if (totalItems <= 0) {
    history.push('/cart')
  }

  return (
    <>
      <GlobalHeaderBack title="Checkout " height="60px" color="white" />

      <ProductDetailsBlock marginTop="2%" background="none">
        {
          Object.keys(groupedItems).length ?
            Object.keys(groupedItems).map(seller => <div
              key={`cartvendordlist-${seller}`}
              className="checkout_details"
            >
              <CheckoutProductDetails
                sellerItems={groupedItems[seller]}
                seller={seller} />
            </div>
            )
            : null
        }
      </ProductDetailsBlock>
      <ProductDetailsBlock padding="4% 5%" marginBottom="25%">
        {
          loading ?
            <Skeleton.Paragraph lineCount={4} animated />
            :
            <>
              <div style={{ fontWeight: '700', fontSize: '1.3em' }}>Total amount Summary </div>
              <Divider />

              <Grid columns={12} gap={[10, 5]}>
                <Grid.Item span={6} >
                  <strong>Subtotal of {totalItems} items(s): </strong>
                </Grid.Item>
                <Grid.Item span={6}>
                  <strong>{ShowPriceWithCurrency({ price: getFloatingPrice(cartState.total), currency: localeCurrency })}</strong>
                </Grid.Item>
                {
                  cartState?.charges?.length > 0 && cartState?.charges?.map(charge => {
                    return (
                      <React.Fragment key={charge.type}>
                        <Grid.Item span={6} >
                          <strong>{charge?.label}: </strong>
                        </Grid.Item>
                        <Grid.Item span={6}>
                          <strong>{ShowPriceWithCurrency({ price: getFloatingPrice(charge.total), currency: localeCurrency })}</strong>
                        </Grid.Item>
                      </React.Fragment>
                    )
                  })
                }

              </Grid>
              <Divider />
              <Grid columns={12} gap={[10, 5]}>
                <Grid.Item span={6} >
                  <strong>Total Charges: </strong>
                </Grid.Item>
                <Grid.Item span={6}>
                  <strong>{ShowPriceWithCurrency({ price: getFloatingPrice(cartState.totalCharges), currency: localeCurrency })}</strong>
                </Grid.Item>
                <Grid.Item span={6} >
                  <strong>Total Amount: </strong>
                </Grid.Item>
                <Grid.Item span={6}>
                  <strong>{ShowPriceWithCurrency({ price: getFloatingPrice(cartState.total + cartState.totalCharges), currency: localeCurrency })}</strong>
                </Grid.Item>
              </Grid>
              <Divider />
              <div style={{ fontWeight: '700', fontSize: '1.3em' }}>Shipping &amp; Billing Address</div>
              <Divider />
              {
                viewAddAddress?.length > 0 ?
                  <>
                    {getShippingAddress()}
                    <Divider />
                    {getBillingAddress()}
                  </>
                  :
                  <>
                    {
                      !isShippingAddressLoading &&
                      <Space block justify="center">
                        <Button color="primary" size="small" onClick={() => {
                          setAddNewAddressView(true)
                        }}>Add New Address</Button>
                      </Space>
                    }
                  </>
              }
            </>
        }
        <Divider />
        {
          walletCurrentBalance > 0 &&
          <Grid columns={1} gap={8} style={{ margin: '15px 0' }}>
            <Grid.Item>
              <span>
                <Checkbox
                  defaultChecked={isUseWallet}
                  onChange={() => setIsUseWallet(!isUseWallet)}
                >
                  <strong style={{ color: "#13c2c2" }}>Use wallet ({ShowPriceWithCurrency({ price: walletCurrentBalance, currency: localeCurrency })})</strong>
                </Checkbox>
              </span>
            </Grid.Item>
          </Grid>
        }
        {
          partiallyUseWalletBalance && restAmountToPay > 0 &&
          <Grid columns={2} gap={8}>
            <Grid.Item>
              <strong>Remaining amount to pay:</strong>
            </Grid.Item>
            <Grid.Item className="text_align">
              <strong>{ShowPriceWithCurrency({ price: restAmountToPay, currency: localeCurrency })}</strong>
            </Grid.Item>
          </Grid>
        }
        {
          fullyUseWalletBalance !== true &&
          <PaymentTypes checkoutItemsList={checkoutItemsList} checkoutTotal={checkoutTotal} />
        }
        <Space block direction="vertical" style={{ marginTop: "3%" }}>
          <span className="additional_note_size">Add additional Note</span>
          <TextArea
            placeholder="Please enter any additional note"
            onChange={val => {
              setAddAdditionalNote(val)
            }}
            rows={3}
            style={{ marginTop: "3%" }}
            className="additional_note"
          />
        </Space>
        <Divider />
        <Grid columns={1} gap={8}>
          <Grid.Item>
            <span>
              <Checkbox defaultChecked={isCheckoutOutAgreeCheck} onChange={val => {
                setIsCheckoutOutAgreeCheck(val)
              }}> I agree with all <a href={routePaths.pages.tncs} alt="termsConditions" className="link_underline">Terms and Conditions,</a>
                <a href={routePaths.pages.privacyPolicy} alt="termsConditions"
                  className="link_underline">Privacy Policy & </a>
                <a href={routePaths.pages.refundPolicy} alt="termsConditions"
                  className="link_underline">Refund Policy</a>
              </Checkbox>
            </span>
          </Grid.Item>
        </Grid>
        <Divider />
        <Popup
          visible={viewAddressList}
          onMaskClick={() => {
            setViewAddressList(false)
          }}
          bodyStyle={{
            height: "65vh",
            overflowY: "scroll",
            borderTopLeftRadius: "15px",
            borderTopRightRadius: "15px",
          }}
          forceRender={false}
        >
          <CheckoutDeliveryAddressList
            viewAddAddress={viewAddAddress}
            selectedAddress={selectedAddress}
            isChangeableAddress={isChangeableAddress}
            isShippingAddressLoading={isShippingAddressLoading} />
        </Popup>
        <Popup
          visible={addNewAddressView}
          onMaskClick={() => {
            setAddNewAddressView(false)
          }}
          bodyStyle={{
            height: "85vh",
            overflowY: "scroll",
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
          }}
          forceRender={false}
        >
          <AddShippingAddress
            isAddressEdit={false}
            setViewAddAddress={setAddNewAddressView}
            componentFrom="checkout"
            setViewAddressList={() => setViewAddressList(true)}
          />
        </Popup>
      </ProductDetailsBlock>
      <PlaceOrderTabBar checkoutTotal={parseFloat(cartState.total + cartState.totalCharges).toFixed(2)} />
    </>
  )
}

export default CheckoutDetails
