import React, {useEffect, useState} from 'react'
import {useProduct} from "context/product-context";
import './ProductDescription.scss'
import {Button, ErrorBlock, Loading, Space} from "antd-mobile";
import {support_format_webp} from "utils/helper";
import {ReloadOutlined} from "@ant-design/icons";
import {getProductDescriptions} from "app/actions/productsDetailsData";
import {useDispatch, useSelector} from "react-redux";

const ProductDescription = () => {

    const dispatch = useDispatch();
    const { isFetched, data } = useSelector(state => state.productsDetails);
    const {productDescription, isProductDescriptionLoading} = useSelector((state) => state.productsDetailsData);
    const {state: productData} = useProduct()

    const [isFirstCall, setIsFirstCall] = useState(true);

    useEffect(() => {
        if (isFetched && data?.id && isFirstCall) {
            dispatch(getProductDescriptions({id: data?.id}))
            setIsFirstCall(false)
        }
    }, [isFetched, data?.id, isFirstCall, dispatch])

    useEffect(() => {
      return () => {
        setIsFirstCall(true)
      }
    }, [])

    const parser = new DOMParser()

    let parserProductDetails = parser.parseFromString(productDescription, "text/html")

    if (productData?.content) {
        parserProductDetails = parser.parseFromString(productData?.content, "text/html")
    }

    const parserProductImage = parserProductDetails.getElementsByTagName('img')

    let addAdditionImage = "_800x800Q80.jpg_.webp"
    let addAdditionImageJpg = "_800x800Q80.jpg"

    if (productData?.shop_id === 11) {
        if (parserProductImage.length > 0) {
            for (let i = 0; i < parserProductImage.length; i++) {
                let img = parserProductImage[i]
                let src = img.getAttribute('data-src')
                let width = img.getAttribute('ori-width')
                let height = img.getAttribute('ori-height')
                img.setAttribute('src', src)
                img.setAttribute('width', width)
                img.setAttribute('height', height)
            }
        }
    } else if (productData?.source?.id === 4 || productData?.source?.id === 10) {
        if (parserProductImage.length > 0) {
            for (let i = 0; i < parserProductImage.length; i++) {
                let img = parserProductImage[i]
                let src = null
                if (support_format_webp) {
                    src = img.getAttribute('src') + addAdditionImage
                } else {
                    src = img.getAttribute('src') + addAdditionImageJpg;
                }
                img.setAttribute('src', src)
            }
        }
    }

    if (isProductDescriptionLoading) {
        return (
            <div className="loading_middle">
                <Loading color='primary'/>
            </div>
        )
    }

    const handleReloadDescription = () => {
        dispatch(getProductDescriptions({id: productData.id}))
    }

    if (!parserProductDetails) return <>
        <Space justify="end" block>
            <Button color='primary' size="mini" style={{textAlign: 'right'}} loading={isProductDescriptionLoading}
                    onClick={() => handleReloadDescription()}><ReloadOutlined/></Button>
        </Space>
        <ErrorBlock status='default' title="Description Not Found" description="This product description is not found"/>
    </>

    return (
        <>
            {
                (productDescription || productData?.content) ?
                    <div className="product-reviews-wrap">
                        <div
                            dangerouslySetInnerHTML={{
                                __html: `${parserProductDetails.body.innerHTML}`
                            }}>
                        </div>
                    </div>
                    :
                    <>
                        <Space justify="end" block>
                            <Button color='primary' size="mini" style={{textAlign: 'right'}}
                                    loading={isProductDescriptionLoading}
                                    onClick={() => handleReloadDescription()}><ReloadOutlined/></Button>
                        </Space>
                        <ErrorBlock status='empty' title="No Description Found"
                                    description=""/>
                    </>
            }
        </>
    )
}

export default ProductDescription
