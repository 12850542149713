import React, {useState} from 'react'
import {DatePicker, Form, Input, Toast} from "antd-mobile";
import dayjs from "dayjs";
import {imageUpload} from "services/settings-client";

const DynamicFormField = (props) => {
    const {data, setPaymentSlipId} = props

    const [pickerVisible, setPickerVisible] = useState(false)

    const now = new Date()

    const handleFileUpload = (e) => {
        let formData = new FormData();
        formData.append('image', e.target.files[0]);
        imageUpload(formData)
            .then(res => {
                setPaymentSlipId(res.data.id)
                if (res.status === 200) {
                    Toast.show({
                        icon: 'success',
                        content: "Upload successfully",
                    })
                }
            })
            .catch(({err}) => {
                if (err?.response?.data?.message) {
                    Toast.show({
                        icon: 'fail',
                        content: err?.response?.data?.message,
                    })
                }else {
                    Toast.show({
                        icon: 'fail',
                        content: 'failed!',
                    })
                }
            })
    }

    const getField = (fieldName) => {
        if (fieldName === 'payment_time') {
            return (
                <Form.Item
                    name={fieldName}
                    label={data.label}
                    trigger='onConfirm'
                    onClick={() => {
                        setPickerVisible(true)
                    }}
                >
                    <DatePicker
                        visible={pickerVisible}
                        onClose={() => {
                            setPickerVisible(false)
                        }}
                        confirmText="Save"
                        cancelText="Cancel"
                        defaultValue={now}
                        max={now}
                    >
                        {
                            value => value ? dayjs(value).format('YYYY-MM-DD') : "Please select a date"
                        }
                    </DatePicker>
                </Form.Item>
            )
        } else if (fieldName === 'attachment') {
            return (
                <div style={{marginLeft: '4%', marginTop: '3%', marginBottom: '3%'}}>
                    <div style={{marginBottom: '3%'}}>Upload Payment slip</div>
                    <input type="file" placeholder='Upload file' onChange={handleFileUpload}/>
                </div>
            )
        } else if (fieldName === 'password'){
            return (
                <Form.Item
                    name={fieldName}
                    label={data.label}
                    rules={[{required: true}]}
                >
                    <Input type="password"/>
                </Form.Item>
            )
        }else {
            return (
                <Form.Item
                    name={fieldName}
                    label={data.label}
                    rules={[{required: true}]}
                >
                    <Input type="text"/>
                </Form.Item>
            )
        }
    }

    return (
        <div>
            {getField(data.name)}
        </div>
    )
}

export default DynamicFormField
