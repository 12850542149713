import "./App.css";
import {BrowserRouter as Router} from "react-router-dom";
import Routes from "./Routes";
import React from "react";
import ScrollToTop from "./hooks/useScrollToTop";
import {ErrorBlock} from "antd-mobile";
import {useAuth} from "context/auth-context";
import {USER_AGENT_CHECK} from "consts/storage";
import {LoadingState} from "components";
import { LayoutProvider } from "context/LayoutContext";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { config } from "constants/constants";

function App() {

  const { findMaintainMode, findMaintainNotice, isLoadingAPP, apiStatus } = useAuth();

  if (isLoadingAPP){
    return <LoadingState />
  }

  if ((parseInt(findMaintainMode?.value) === 1 && window.navigator.userAgent?.includes(USER_AGENT_CHECK)) || apiStatus === 503) {
    return  <ErrorBlock title="APP is under maintenance" description={findMaintainNotice?.value} fullPage status='busy' />
  }

  return (
        <GoogleOAuthProvider clientId={config.GOOGLE_CLIENT_ID}>
          <Router>
            <LayoutProvider>
              <ScrollToTop/>
              <Routes/>
            </LayoutProvider>
          </Router>
        </GoogleOAuthProvider>
    );
}

export default App;
