import React, { useEffect } from "react";
import { CampaignListSkeleton, GlobalHeaderBack, PageHead } from "components";
import "../Campaign.scss"
import { useDispatch, useSelector } from "react-redux";
import useFilters from "hooks/useFilters";
import { getRunningCampaign, getUpcomingCampaign } from "app/actions/campaign";
import { DemoBlock, ProductDetailsBlock } from "demos";
import { ErrorBlock, Grid } from "antd-mobile";
import Countdown from "react-countdown";
import { useHistory } from "react-router-dom";

const Campaigns = (props) => {
  const { showAllActiveCampaign } = props
  const dispatch = useDispatch();
  let history = useHistory()
  const { running, loading, upComing } = useSelector(state => state.campaign);
  const [campaignList, setCampaignList] = React.useState([])

  const { filters, isFirstCall, isFetched, initializeAvailableFilter } =
    useFilters();

  useEffect(() => {
    if (!isFetched && running?.filters) {
      initializeAvailableFilter(running?.filters);
    }
  }, [isFetched, initializeAvailableFilter, running?.filters]);

  useEffect(() => {
    if (filters && !isFetched && isFirstCall) {
      dispatch(getRunningCampaign(filters));
      dispatch(getUpcomingCampaign(filters))
    }
  }, [dispatch, isFirstCall, filters, isFetched]);

  useEffect(() => {
    if (showAllActiveCampaign && running?.data?.length && upComing?.data?.length) {
      setCampaignList([...running.data, ...upComing.data])
    } else if (running?.data?.length) {
      setCampaignList(running.data)
    }
  }, [showAllActiveCampaign, running, upComing])

  if (loading) return (<CampaignListSkeleton />)


  return (
    <>
      {
        !showAllActiveCampaign &&
        <>
          <PageHead title={`Campaign Lists`} />
          <GlobalHeaderBack title="Campaigns" height="60px" color="white" background="#f26624" />
        </>
      }

      {/* <ProductDetailsBlock
        background={showAllActiveCampaign ? 'transparent' : "linear-gradient(360deg, rgba(235,170,135,1) 0%, rgba(236,86,67,1) 25%, rgba(231,75,93,1) 53%, rgba(245,155,14,1) 98%, rgba(245,158,11,1) 100%)"}
        padding="0 0 15% 0"
        minHeight="600px"> */}
      <ProductDetailsBlock
        background={"linear-gradient(360deg, rgba(235,170,135,1) 0%, rgba(236,86,67,1) 25%, rgba(231,75,93,1) 53%, rgba(245,155,14,1) 98%, rgba(245,158,11,1) 100%)"}
        padding="0 0 15% 0"
        minHeight="600px">
        {
          !running?.loading && running?.data?.length !== 0 &&
          <DemoBlock padding={showAllActiveCampaign ? 0 : "2%"} margin="0 2%" borderRadius="8px" background="none">
            {
              !showAllActiveCampaign &&
              <Grid columns={1} gap={8}>
                <Grid.Item className="campaign_slogan">
                  <div className="card_body">
                    <span>Running Campaign</span>
                  </div>
                </Grid.Item>
              </Grid>
            }

            <Grid columns={1} gap={8}>
              {
                !running?.loading && campaignList?.length > 0 ? campaignList?.map((item, index) => {
                  return (
                    <Grid.Item style={ showAllActiveCampaign ? { marginTop: "6%", border: '1px solid var(--adm-color-primary)', borderRadius: '10px' } : { marginTop: "6%"}} key={`running${index}`}
                      onClick={() => history.push(`/campaigns/${item?.slug}`)}>
                      <div className="campaigns-header">
                        <div className="campaigns-header-banner">
                          <img src={item?.monogram_url} alt={item?.title} />
                        </div>
                        <div className="campaigns-header-heading">
                          <div className="section-title">
                            <span>{item?.title}</span>
                          </div>
                        </div>
                        <div className="campaigns-header-timer">
                          <div className="countDown">
                            <Countdown
                              date={item.finished_at * 1000}
                              renderer={props => {
                                return (
                                  <div className="countDownTimer__cd_timer">
                                    <div className="countDownTimer__cd_timer__block">
                                      <span
                                        className="countDownTimer__days">{props.formatted.days} </span>
                                    </div>
                                    <strong>:</strong>
                                    <div className="countDownTimer__cd_timer__block">
                                      <span
                                        className="countDownTimer__hours">{props.formatted.hours} </span>
                                    </div>
                                    <strong>:</strong>
                                    <div className="countDownTimer__cd_timer__block">
                                      <span
                                        className="countDownTimer__minutes">{props.formatted.minutes} </span>
                                    </div>
                                    <strong>:</strong>
                                    <div className="countDownTimer__cd_timer__block">
                                      <span
                                        className="countDownTimer__seconds">{props.formatted.seconds} </span>
                                    </div>
                                  </div>
                                )
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </Grid.Item>
                  )
                })
                  :
                  <div style={{ marginBottom: "10%" }}>
                    <ErrorBlock title="No Campaign is running"
                      description="No campaign is running right now. Look another time"
                      status="empty" />
                  </div>
              }
            </Grid>
          </DemoBlock>
        }
        {
          !upComing?.loading && upComing?.data?.length >= 0 && !showAllActiveCampaign &&
          <DemoBlock padding="2%" margin="0 2%" borderRadius="8px" background="transparent">
            <Grid columns={1} gap={8}>
              <Grid.Item className="campaign_slogan">
                <div className="card_body">
                  <span>Upcoming Campaign</span>
                </div>
              </Grid.Item>
            </Grid>

            <Grid columns={1} gap={8}>
              {
                upComing && upComing?.data?.map((item, index) => {
                  return (
                    <Grid.Item style={{ marginTop: "3%" }} key={`upcoming${index}`}
                      onClick={() => history.push(`/campaigns/${item?.slug}`)}>
                      <div className="campaigns-header">
                        <div className="campaigns-header-banner">
                          <img src={item?.monogram_url}
                            alt={item?.title}
                            className="img-fluid" />
                        </div>
                        <div className="campaigns-header-heading">
                          <div className="section-title campaign-title">
                            <span>{item?.title}</span>
                          </div>
                        </div>
                        <div className="campaigns-header-timer">
                          <div className="countDown">
                            <Countdown
                              date={item.started_at * 1000}
                              renderer={props => {
                                return (
                                  <div className="countDownTimer__cd_timer">
                                    <div className="countDownTimer__cd_timer__block">
                                      <span
                                        className="countDownTimer__days">{props.formatted.days} </span>
                                    </div>
                                    <strong>:</strong>
                                    <div className="countDownTimer__cd_timer__block">
                                      <span
                                        className="countDownTimer__hours">{props.formatted.hours} </span>
                                    </div>
                                    <strong>:</strong>
                                    <div className="countDownTimer__cd_timer__block">
                                      <span
                                        className="countDownTimer__minutes">{props.formatted.minutes} </span>
                                    </div>
                                    <strong>:</strong>
                                    <div className="countDownTimer__cd_timer__block">
                                      <span
                                        className="countDownTimer__seconds">{props.formatted.seconds} </span>
                                    </div>
                                  </div>
                                )
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </Grid.Item>
                  )
                })
              }
            </Grid>
          </DemoBlock>
        }
      </ProductDetailsBlock>
    </>
  );
}

export default Campaigns
