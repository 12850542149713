import React, {useState} from 'react';
import DynamicFormField from './DynamicFormField';
import {processPayment} from "services/payments";
import {useHistory, useParams} from "react-router-dom";
import {Button, Form, Input, Selector, Toast} from "antd-mobile";
import useErrors from "hooks/useErrors";
import dayjs from "dayjs";
import './GatewayForm.scss'

const GatewayForm = (props) => {

    const {data, bankTabs, total, resData} = props
    const history = useHistory()
    let {order} = useParams();
    const [form] = Form.useForm();
    const {getErrors} = useErrors();
    const fields = data?.fields ? data.fields : null;
    const [isLoading, setIsLoading] = useState(false);
    const [bankValue, setBankValue] = useState(null);
    const [paymentSlipId, setPaymentSlipId] = useState(null);

    const onFinish = (values) => {
        values.bank = values?.bank ? values?.bank[0] : null
        values.payment_time = dayjs(values.payment_time).format('YYYY-MM-DD h:mm:ss A')
        values.attachment = paymentSlipId ? paymentSlipId : null
        values.gateway_id = resData?.id
        values.invoice_id=  resData?.invoice?.id ? resData?.invoice?.id : order
        values.invoice_type= resData?.invoice?.type
        setIsLoading(true)
        processPayment(values)
            .then(res => {
                setIsLoading(false)
                if (res.status === 200){
                    history.push('/payment/feedback?status=success')
                }else {
                    history.push('/payment/feedback?status=failed')
                }
            })
            .catch(({response}) => {
                if (response?.data?.errors){
                    form.setFields(getErrors(response.data.errors));
                }else if (response?.data?.message){
                    Toast.show({
                        icon: 'fail',
                        content: response?.data?.message,
                    })
                }else {
                    Toast.show({
                        icon: 'fail',
                        content: 'failed!',
                    })
                }
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    let makeBankOption = bankTabs?.map(item => {
        return {
            label: item.title,
            value: item.icon
        }
    })

    let findExBank = null

    if (bankValue){
        findExBank = bankTabs.find(el => el.icon === bankValue[0])
    }

    return (
        <Form
            onFinish={onFinish}
            form={form}
            className="payment_form"
            initialValues={{
                amount: total,
            }}
            footer={
                <Button type="submit" block color="primary" loading={isLoading}>Submit</Button>
            }
        >
            <div>
                <div className="payment_total">
                    <span>Total: {data?.caption}</span>
                </div>
                {
                    bankTabs &&
                    <Form.Item
                        name='bank'
                        label='Bank Name'
                        rules={[{required: true, message: 'Please input'}]}
                    >
                        <Selector
                            columns={3}
                            options={makeBankOption}
                            onChange={(arr) => setBankValue(arr)}
                        />
                    </Form.Item>
                }
                {
                    findExBank &&
                        <div className="bank_details">
                            <p dangerouslySetInnerHTML={{ __html: `${findExBank?.content}`}}/>
                        </div>
                }

                <Form.Item name='amount' label='Amount'>
                  <Input type="number" placeholder='Enter a Amount' readOnly={true} disabled />
                </Form.Item>

                {
                    fields && fields.map((field, idx) => <DynamicFormField key={`dff-${idx}`} data={field} setPaymentSlipId={setPaymentSlipId}/>)
                }
            </div>
        </Form>
    )
}

export default GatewayForm
