import React, {useEffect, useState} from "react"
import {ImageViewer, Swiper} from "antd-mobile";
import "./ImageGallery.scss"
import {useLocation} from "react-router-dom";
import {changeImageHostName} from "utils/helper";
import {useProduct} from "context/product-context";
import ReactPlayer from "react-player";
import {PlayCircleOutlined} from "@ant-design/icons";
import {useSelector} from "react-redux";
import { useLayoutContext } from "context/LayoutContext";

const ImageGallery = () => {
  const { preloaderImage } = useLayoutContext();
  const {state} = useLocation();

  const {state: productData} = useProduct()

  const [viewImageGallery, setViewImageGallery] = useState(false);
  const [selectedImageGalleryImage, setSelectedImageGalleryImage] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [isVideoPaused, setIsVideoPaused] = useState(0);
  const { isFetched } = useSelector((state) => state.productsDetails);

  const imageGallery = productData?.gallery

  const handleGalleryImage = (value) => {
    let imageGalleyData = value?.map(el => changeImageHostName(el?.image, productData?.source?.id))
    setSelectedImageGalleryImage(imageGalleyData)
    setViewImageGallery(true)
  }

  //Push state image to gallery don't remove it will be use in future
  useEffect(() => {
    let newImageGallery = Object.assign([], imageGallery);
      if (state){
        newImageGallery?.unshift(state)
      }
  }, [imageGallery, state]);

  const onLoad = () => {
    setLoaded(true);
  }

  return (
    <>
      {
        productData &&
        <img src={imageGallery[0].image} alt={productData?.title} onLoad={onLoad} style={{display: "none"}}/>
      }
      {
        productData && isFetched && loaded ?
          <Swiper
            className="image_gallery"
            indicatorProps={{
              color: "white",
            }}
            defaultIndex={0}
            rubberband={true}
            onIndexChange={(index) => setIsVideoPaused(index)}
          >
            {
              imageGallery?.map((elements, index) => {
                return (
                  <Swiper.Item key={index + "image_gallery"} className="swiper-images">
                    {
                      elements?.video &&
                      <ReactPlayer
                        url={elements?.video}
                        playing={isVideoPaused === 0}
                        controls={true}
                        loop={true}
                        light={elements?.image}
                        width={"auto"}
                        height={370}
                        playIcon={<div className="video_play_icon"><PlayCircleOutlined/></div>}
                        className="react-player"
                      />
                    }
                    <div className="product_details_image">
                      <div className="product_details_image__thumb" onClick={() => {
                        handleGalleryImage(imageGallery)
                      }}>
                        <img src={changeImageHostName(elements?.image, productData?.source?.id)}
                             className="product_details_image__thumb__image" alt={productData?.title}
                             style={{
                               backgroundImage: preloaderImage ?? `url(${process.env.PUBLIC_URL + "/assets/product/preloader-bg_411x411.png"})`,
                               backgroundRepeat: "no-repeat",
                             }}
                        />
                      </div>
                    </div>
                  </Swiper.Item>
                )
              })
            }
          </Swiper>
          :
          <div className="image_gallery">
            <div className="swiper-images">
              <div className="product_details_image">
                <div className="product_details_image__thumb">
                  {
                    state ?
                      <img src={changeImageHostName(state?.image, productData?.source?.id)}
                           className="product_details_image__thumb__image" alt={state?.title}
                           style={{
                             backgroundImage: preloaderImage ?? `url(${process.env.PUBLIC_URL + "/assets/product/preloader-bg_411x411.png"})`,
                             backgroundRepeat: "no-repeat",
                           }}/>
                      :
                      <img src={preloaderImage ?? process.env.PUBLIC_URL + "/assets/product/preloader-bg_411x411.png"}
                           className="product_details_image__thumb__image" alt="image_not_state"/>
                  }
                </div>
              </div>
            </div>
          </div>
      }
      <ImageViewer.Multi
        images={selectedImageGalleryImage}
        visible={viewImageGallery}
        defaultIndex={0}
        onClose={() => {
          setViewImageGallery(false)
        }}
      />
    </>
  )
}

export default ImageGallery
