import React, { useState} from "react";
import {Button, Card, Form, Input, Space, Toast} from "antd-mobile";
import '../Wallet.scss'
import {makeWithdrawal} from "services/wallet";
import useErrors from "hooks/useErrors";
import {capitalizeFirstLetter} from "utils/helper";

const MakeWithdrawal = (props) => {

    const { setMakeWithDrawal, findActiveAccount, fetchWithdrawal } = props

    const [form] = Form.useForm();
    const [isWithdrawingLoad, setIsWithdrawingLoad] = useState(false);
    const {getErrors} = useErrors();

    const onFinish = (value) => {
        let data = {
            amount: value.amount,
            password: value.password,
            method: findActiveAccount?.type,
        }

        setIsWithdrawingLoad(true)
        makeWithdrawal(data)
            .then(res => {
                fetchWithdrawal()
                setIsWithdrawingLoad(false)
                setMakeWithDrawal(false)
                Toast.show({
                    content: res?.data?.message,
                    position: 'bottom'
                })
            })
            .catch(err => {
                setIsWithdrawingLoad(false)
                if (err?.response?.data?.errors){
                    form.setFields(getErrors(err.response.data.errors));
                }else if (err?.response?.data?.message) {
                    Toast.show({
                        icon: 'fail',
                        content: err?.response?.data?.message,
                    })
                }else {
                    Toast.show({
                        icon: 'fail',
                        content: 'failed!',
                    })
                }
            })
    }

    return (
        <div className="windrow_form">
            <Form
                autoComplete="chrome-off"
                onFinish={onFinish}
                form={form}
                footer={
                    <Button block type='submit' color='primary' loading={isWithdrawingLoad}>
                        Save
                    </Button>
                }
            >
                <Card>
                    <Space align="start" justify="start" block direction="vertical" style={{marginTop: '1%', marginLeft: '3%'}}>
                        <span className="with_history">Active Account</span>
                        <span className="with_history_type">Type: {capitalizeFirstLetter(findActiveAccount?.type)}</span>
                        <span className="with_history_number">Account: {findActiveAccount?.account}</span>
                    </Space>
                </Card>
                <Form.Item
                    name='amount'
                    label='Amount'
                >
                    <Input placeholder='1000' autoComplete="chrome-off"/>
                </Form.Item>
                <Form.Item
                    name='password'
                    label='Password'
                >
                    <Input placeholder='Enter your password' type="password" />
                </Form.Item>
            </Form>
        </div>
    );
}

export default MakeWithdrawal
