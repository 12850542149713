import React, {useState} from "react"
import './ProfileDetails.scss'
import {Button, Form, Input, Toast} from "antd-mobile";
import {useAuth} from "context/auth-context";
import {CloseCircleOutline} from "antd-mobile-icons";
import useErrors from "hooks/useErrors";
import {storeProfileInfo} from "services/profile-client";
import {LOCAL_STORAGE_USER} from "constants/constants";
import { useLayoutContext } from "context/LayoutContext";

const PhoneNumber = (props) => {
    const { localeCountry } = useLayoutContext();
    const {setIsViewPhoneNumber, userData} = props
    const [form] = Form.useForm()
    const {getErrors} = useErrors()
    const {getUsername} = useAuth();

    const {profileInfo} = useAuth()

    const [isFormLoading, setIsFormLoading] = useState(false)
    const [isOtpSend, setIsOtpSend] = useState(null);

    const onFinishProfileUpdate = (value) => {

        let phoneNumber = getUsername(value.mobile, localeCountry)

        const profileValue = {
            action: "mobile",
            mobile: phoneNumber
        }

        const profileVerifyValue = {
            action: "mobile",
            mobile: phoneNumber,
            otp: value.otp,
            password: value.password,
        }

        setIsFormLoading(true)
        storeProfileInfo(isOtpSend ? profileVerifyValue : profileValue)
            .then(res => {
                if (res?.data?.intent) {
                    setIsOtpSend(res?.data?.intent)
                } else {
                    setIsViewPhoneNumber(false)
                }
                if (res.status === 200) {
                    profileInfo.mobile = profileVerifyValue.mobile
                    window.localStorage.setItem(LOCAL_STORAGE_USER, JSON.stringify(profileInfo))
                }
                Toast.show({
                    icon: 'success',
                    content: res?.data?.message,
                })
                setIsFormLoading(false)
            })
            .catch(err => {
                if (err?.response?.data?.errors) {
                    form.setFields(getErrors(err.response.data.errors));
                }else {
                    Toast.show({
                        icon: 'fail',
                        content: 'Changes failed',
                    })
                }
                setIsFormLoading(false)
            })
    }

    return (
        <div className="profile_name">
            <div className="profile_info">
                <div>
                    <span className="profile_info__title">Mobile Number</span>
                </div>
                <div>
                    <span className="profile_info__icons"
                          onClick={() => setIsViewPhoneNumber(false)}><CloseCircleOutline/></span>
                </div>
            </div>
            <Form
                onFinish={onFinishProfileUpdate}
                footer={
                    <Button block type='submit' color='primary' loading={isFormLoading}>
                        Submit
                    </Button>
                }
                className="profile_form"
                initialValues={{
                    mobile:  userData?.mobile,
                }}
                form={form}
            >
                {
                    !isOtpSend &&
                    <Form.Item
                        name='mobile'
                        label='Mobile Number'
                    >
                        <Input placeholder='Please enter your mobile number'/>
                    </Form.Item>
                }

                {
                    isOtpSend === "new_mobile_verify" &&
                    <>
                        <Form.Item
                            name='mobile'
                            label='New Mobile Number'
                        >
                            <Input placeholder='Please enter your mobile number' type="text"/>
                        </Form.Item>
                        <Form.Item
                            name='otp'
                            label='OTP'
                        >
                            <Input placeholder='Please enter your otp' type="text"/>
                        </Form.Item>
                        <Form.Item
                            name='password'
                            label='Password'
                        >
                            <Input placeholder='Please enter your password' type="password"/>
                        </Form.Item>
                    </>
                }
            </Form>
        </div>
    );
}
export default PhoneNumber
