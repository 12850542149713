import { SearchBar, Skeleton, Space, Steps, Toast } from 'antd-mobile';
import { CheckCircleFill } from 'antd-mobile-icons';
import { GlobalHeaderBack, PageHead } from 'components';
import { useLayoutContext } from 'context/LayoutContext';
import { ProductDetailsBlock } from 'demos';
import qs from 'qs';
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { trackingService } from 'services/services';
import { formatDate } from 'utils/helper';

export default function TrackingPage() {
  const { brandName, localeCountry, countries } = useLayoutContext();
  const [originCountry, setOriginCountry] = useState(null);
  const [timeline, setTimeline] = useState([]);
  const [product, setProduct] = useState(null);
  const [responseStatus, setResponseStatus] = useState(null);
  const [trackingLoading, setTrackingLoading] = useState(false);
  const [isTrackingCalled, setIsTrackingCalled] = useState(true);
  const router = useLocation();
  const { search: searchQuery } = router;
  const params = qs.parse(searchQuery.substring(1));

  const fetchTracking = React.useCallback((code) => {
    if (!code || code?.length < 4) {
      Toast.show({
        icon: "fail",
        content: "Please provide valid tracking code.",
      })
      return;
    }

    setTrackingLoading(true);
    trackingService({ code })
      .then(res => {
        if (res.data?.data) {
          setTimeline(res.data.data?.data);
          setProduct(res.data?.data?.product);
          setResponseStatus(res.data?.status)
        }
      })
      .catch(({ response }) => {
        console.error({ response })
      })
      .finally(() => {
        setTrackingLoading(false);
      })
  }, [])

  useEffect(() => {
    if (params?.code && isTrackingCalled) {
      fetchTracking(params?.code)
      setIsTrackingCalled(false)
    }
  }, [params?.code, isTrackingCalled, fetchTracking])

  useEffect(() => {
    if (product?.trackable?.origin && countries?.length) {
      let originC = countries.find(c => c?.code === product?.trackable?.origin);
      if (originC?.name) {
        setOriginCountry(originC);
      }
    }
  }, [product, countries])

  const handleTracking = (code) => {
    setTimeline([]);
    setProduct(null);
    fetchTracking(code)
  }

  return (
    <>
      <GlobalHeaderBack height="50px" color="white" />
      <ProductDetailsBlock padding="3% 5% 20%">
        <div className="tracking-wrapper container space-y-8 mb-20">
          <PageHead title={`${brandName?.label || 'Ali2BD'} Tracking`} />
          <div
            id="tracking-map-block" className="map-block bg-white w-full">
            <div
              style={{
                backgroundImage: 'url(/assets/backgrounds/bg-tracking-world-map.jpeg)',
                minHeight: '150px',
                backgroundSize: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
              }}
              className="flex flex-col items-center justify-center space-y-4"
            >
              <div className="text-xl font-semibold text-center"
                style={{
                  fontSize: '18px',
                  fontWeight: '700',
                  marginBottom: '12px'
                }}
              ><span>Track Your Product</span></div>
              <div className=" w-4/5 lg:w-[550px] space-y-3">
                <SearchBar
                  placeholder="Product code"
                  style={{
                    border: '1px solid var(--adm-color-primary)',
                    borderRadius: '6px',
                    '--background': '#ffffff',
                  }}
                  onSearch={handleTracking}
                />
              </div>
            </div>
          </div>
          {
            params?.code && product?.code &&
            <>
              <div className="info-block" style={{
                margin: '20px 0'
              }}>
                <div className="flex items-center justify-between"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginBottom: '10px',
                  }}
                >
                  <span>Product Code: </span>
                  <strong>{product?.trackable?.product_number}</strong>
                </div>
                <div className="flex items-center justify-between"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginBottom: '10px',
                  }}
                >
                  <span>Tracking Code: </span>
                  <strong>{product?.code}</strong>
                </div>
                <div className="flex items-center justify-between"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginBottom: '10px',
                  }}
                >
                  <div><span>Origin:</span> <strong>{originCountry?.name}</strong></div>
                  <div><span>Destination:</span> <strong>{localeCountry?.name}</strong></div>
                </div>
              </div>
            </>
          }
          <div id='current_status'
            className="hl-block text-center py-8 text-primary bg-opacity-10 font-bold text-xl bg-blue-500 rounded"
            style={{
              textAlign: 'center',
              padding: '16px 8px',
              marginBottom: '16px',
              background: 'rgb(59 130 246 / 0.1)',
              borderRadius: '4px',
              color: 'var(--adm-color-primary)'
            }}
          >
            <h3>{
              params?.code && timeline?.[0]?.content
                ? timeline?.[0].content
                : responseStatus === 'success' ? 'Not found or Invalid Code' : `Search by product/tracking number`}</h3>
          </div>
          {/* <!-- component --> */}
          {
            params?.code &&
            <>
              <div id="tracking-timeline" className="bg-blue-500 bg-opacity-10 rounded flex flex-col justify-center"
                style={{
                  // background: 'rgb(59 130 246 / 0.1)',
                  padding: '10px 0',
                  marginBottom: '20px',
                }}
              >
                {
                  timeline?.length ?
                    <Steps
                      direction='vertical'
                      current={0}
                    >
                      {
                        timeline.map((item, idx) => {
                          return (
                            <Steps.Step
                              key={item?.tracking_id + item?.id}
                              title={
                                <Space block direction='vertical'
                                  style={{
                                    "--gap": '0'
                                  }}
                                >
                                  <div className="text-gray-500 font-normal">{formatDate(item?.created_at)}</div>
                                  <div>
                                    <strong>{item.content}</strong>
                                  </div>
                                </Space>
                              }
                              icon={<CheckCircleFill />}
                            />
                          )
                        })
                      }
                    </Steps>
                    : trackingLoading &&  <Skeleton
                      animated
                      style={{
                        '--width': '100%',
                        '--height': '100px',
                        '--border-radius': '8px',
                      }}
                    />
                }
              </div>

              {
                product?.code &&
                <div className="bg-blue-500 bg-opacity-10 border rounded p-8"
                  style={{
                    background: 'rgb(59 130 246 / 0.1)',
                    padding: '16px 8px',
                    borderRadius: '4px'
                  }}
                >
                  <div className="flex items-center justify-between"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      marginBottom: '10px',
                    }}
                  >
                    <span>Tracking number: </span>
                    <strong>{product?.code} </strong>
                  </div>
                  <div className="TrackingInfo_trackInfo-list__1ZjdM">
                    <div className="flex items-center justify-between"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginBottom: '10px',
                      }}
                    >
                      <span>Shipment Requested</span>
                      <strong>{formatDate(product?.trackable?.shipping_started_at)}</strong>
                    </div>
                    <div className="flex items-center justify-between"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginBottom: '10px',
                      }}
                    >
                      <span>Last update</span>
                      <strong>{formatDate(product?.trackable?.updated_at)}</strong></div>
                    <div className="flex items-center justify-between"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginBottom: '10px',
                      }}
                    >
                      <span>Estimated delivery period</span>
                      <strong>{formatDate(product?.trackable?.valid_to)}</strong></div>
                    <div className="flex items-center justify-between"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginBottom: '10px',
                      }}
                    >
                      <span>Route</span>
                      <strong>{originCountry?.name} to {localeCountry?.name}</strong>
                    </div>
                  </div>
                </div>
              }
            </>
          }
        </div>
      </ProductDetailsBlock>
    </>
  )
}
