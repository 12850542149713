import * as Types from 'app/actionTypes/campaign';

export const getRunningCampaign = (filter = {}) => {
  return {
    type: Types.GET_RUNNING_CAMPAIGN_REQUESTED,
    payload: filter,
  }
}

export const getUpcomingCampaign = (filter = {}) => {
  return {
    type: Types.GET_UPCOMING_CAMPAIGN_REQUESTED,
    payload: filter,
  }
}

export const getCampaignDetails = (slug, isTagFilterCall, filters = {}, reset=false) => {
  return {
    type: Types.GET_CAMPAIGN_DETAILS_REQUESTED,
    payload: {slug, filters, isTagFilterCall, reset},
  }
}

export const updateCampaignProducts = (data) => {
  return {
    type: Types.UPDATE_CAMPAIGN_PRODUCTS,
    payload: data,
  }
}

export const LoadMoreCampaignProducts = (payload) => {
  return {
    type: Types.LOAD_MORE_PRODUCTS,
    payload
  }
}
