import React from 'react';
import { useSelector } from 'react-redux';
import { Swiper } from "antd-mobile";
import { HomePageBlock } from "demos";
import "../homepage.scss"
import { useHistory } from "react-router-dom";


const SliderWidget = (props) => {
  const { loading: widgetsLoading } = useSelector((state) => state.builder);
  let history = useHistory();

  return (
    <HomePageBlock
      padding="3% 4%"
      {...props}
    >
      {
        !widgetsLoading &&
        <Swiper autoplayInterval={3000} loop={true} autoplay={true}>
          {
            props?.slides?.map((slider, idx) => {
              return (
                <Swiper.Item key={`swiper${idx}`} className="swiper-images-homepage" onClick={() => history.push({
                  pathname: slider?.link
                })}>
                  <div className="product_details_image_homepage">
                    <div className="product_details_image_homepage__thumb">
                      <img src={slider?.image}
                        className="product_details_image_homepage__thumb__image"
                        alt="homepage"
                      />
                    </div>
                  </div>
                </Swiper.Item>
              )
            })
          }
        </Swiper>
      }
    </HomePageBlock>
  )
}

export default SliderWidget;
