import React, { useCallback, useEffect, useReducer, useState } from "react"
import { productReducer } from "./productReducer";
import { useCart } from "./cart-context";
import { addToCart, getCart } from "services/cart-client";
import { Toast } from "antd-mobile";
import { ACTIVE_REQUEST_TAB, CHECKOUT_ITEM, LOCAL_STORAGE_KEY, STORE_STORAGE, THREE_TIPS } from "constants/constants";
import { getLocalData, getShippingRate } from "utils/helper";
import { addMyRequest } from "services/my-request-client";
import qs from "qs";
import { getDomesticChargesValue, getShippingChargesValue } from "../app/actions/productsDetails";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { setBuyNowItem } from "app/actions/cart";
import { defaultKeys } from "consts/storage";
import { useLayoutContext } from "./LayoutContext";
import ReactGA from "react-ga4";

const ProductDetailsContext = React.createContext()

const ProductDetailsProvider = (props) => {
  const { getConfigValue, wholeslaeShops } = useLayoutContext();
  const history = useHistory();
  let storeLocal = getLocalData(STORE_STORAGE);
  //Full Product Details
  const dispatchRedux = useDispatch()
  const loggedIn = localStorage.getItem(LOCAL_STORAGE_KEY)
  const { fetchCart } = useCart()
  const [state, dispatch] = useReducer(productReducer, null)
  const [selectedVariations, setSelectedVariations] = useState(null);
  const [selectedProductSku, setSelectedProductSku] = useState(null);
  const [selectedProductImg, setSelectedProductImg] = useState(null);
  const [totalQuantity, setTotalQuantity] = useState(1);
  const [selectedShippingDetails, setSelectedShippingDetails] = useState(null);
  const [isAddToCardLoading, setIsAddToCardLoading] = useState(false);
  const [selectedProps, setSelectedProps] = useState(null);

  //Pop up select from context
  const [viewDisclaimer, setViewDisclaimer] = useState(false);
  const [viewVariations, setViewVariations] = useState(false);
  const [viewSpecification, setViewSpecification] = useState(false);
  const [viewShippingSelect, setViewShippingSelect] = useState(false);
  const [viewLoginPopUp, setViewLoginPopUp] = useState(false);
  const [afterCartDialogView, setAfterCartDialogView] = useState(false);
  const [stockOutProps, setStockOutProps] = useState(null);
  const [threeTipsView, setThreeTipsView] = useState(false);
  const [myRequestLoading, setMyRequestLoading] = useState(false);

  const [totalPrice, setTotalPrice] = useState(0);
  const [itemsMeta, setItemsMeta] = useState([]);
  const [variationsSize, setVariationsSize] = useState(0);
  const [fixedTotal, setFixedTotal] = useState(null);
  const [totalQty, setTotalQty] = useState(0);
  const [isWholesaleVendor, setIsWholeslaleVendor] = useState(false);
  const [defaultSkuMessage, setDefaultSkuMessage] = useState(null);
  const [shippingPayload, setShippingPayload] = useState({})
  const [approxWeight, setApproxWeight] = useState(0);
  const [approxShippingCost, setApproxShippingCost] = useState(0);
  const [shippingRate, setShippingRate] = useState(null);
  const [domesticPayload, setDomesticPayload] = useState('');
  const [activeWholesalePrice, setActiveWholesalePrice] = useState(null);

  //Product loading state
  const [isProductLoading, setIsProductLoading] = useState(true);

  const [isBuyNowLoading, setIsBuyNowLoading] = useState(false);

  const [is3TipsAgreed, setIs3TipsAgreed] = React.useState(false);
  const [categoryShippingCharge, setCategoryShippingCharge] = useState(null);
  const [similarProduct, setSimilarProduct] = useState(null);

  const {
    shipping: shippingMethodsData,
    isShippingLoading: isShippingMethodsLoading,
    domesticShipping: selectedDomesticDetails,
    isDomesticShippingLoading,
    data: productData,
    loading: productLoading,
    isFetched,
  } = useSelector((state) => state.productsDetails);

  let queryParams = qs.parse((window.location.search).substring(1))
  let isCampaignItem = queryParams?.campaign && queryParams?.campaign_product;
  const isWholesaleProduct = (isWholesaleVendor && !isCampaignItem) || (isCampaignItem && productData?.campaign?.type === 'wholesale')



  useEffect(() => {
    if (isFetched && productData?.id) {
      let payload = {
        ...productData
      }
      let pattrs = [...payload?.attributes];
      let shipsFromIdx = pattrs.findIndex(item => item?.name?.toLowerCase()?.split(' ')?.join('_') === 'ships_from');

      if (shipsFromIdx >= 0) {
        let shipsFrom = pattrs[shipsFromIdx];
        pattrs[shipsFromIdx] = {
          ...shipsFrom,
          values: shipsFrom.values?.filter(item => ['cn', 'ch', 'china']?.includes(item?.title?.toLowerCase()) || ['cn', 'ch', 'china']?.includes(item?.name?.toLowerCase())),
        }
        payload = {
          ...payload,
          attributes: [...pattrs],
        }
      }
      dispatch({ type: "SET_STATE", payload });
      setIsProductLoading(false);
    }
  }, [isFetched, productData])

  useEffect(() => {
    if (state) {
      if (!state?.meta?.videos) {
        setSelectedProductImg(state?.image)
      }
      if (state?.meta?.weight) {
        setApproxWeight(state.meta.weight)
      }
      let propsLength = state.attributes.length
      setVariationsSize(propsLength);
      let variation = []
      for (let i = 0; i < propsLength; i++) {
        let nonZeroVariation = state?.skus?.find(el => el?.stock !== 0)

        let findNonZeroVariation = state.attributes[i].values?.filter(el => {
          if (el.id === nonZeroVariation?.props) {
            return el
          }
          return null
        })
        variation.push(findNonZeroVariation?.length > 0 ? findNonZeroVariation[0].id : state.attributes[i].values[0].id)
      }
      setSelectedVariations(variation)
      // let vSizeArr = new Array(propsLength).fill(null);
      // setSelectedVariations(vSizeArr)
      if (wholeslaeShops?.value) {
        let isWsVendor = wholeslaeShops.value?.split(',')?.includes(state?.shop_id?.toString())
        setIsWholeslaleVendor(isWsVendor);
      }
    }
  }, [state, wholeslaeShops])

  let productStore = null;
  if (storeLocal?.value?.length && productData?.link) {
    productStore = storeLocal.value.find(item => {
      if (productData?.source?.identifier === item.identifier) return true;
      else if ((productData?.source?.id === item.id) || (productData?.shop_id === item.id)) return true;
      else if (productData?.link?.includes(item.identifier)) return true;
      return false;
    })
  }

  useEffect(() => {
    let isTipsAgreed = getLocalData(THREE_TIPS);
    setIs3TipsAgreed(isTipsAgreed)
  }, [])

  const resetProduct = () => {
    setSelectedProductSku(null)
    setSelectedVariations(null)
    setTotalQuantity(1)
    setSelectedShippingDetails(null)
    setSelectedProductImg(null)
  }

  // const getProductVariationPrice = useCallback((variation) => {
  //   if (!state) return null

  //   let selectedSku;
  //   if (selectedVariations?.length <= 0) {
  //     selectedSku = state?.skus?.[0]
  //   } else if (selectedVariations?.length < 2) {
  //     selectedSku = state?.skus?.find(item => item.props === variation.toString())
  //   } else {
  //     selectedSku = state?.skus?.find(item => item.props === selectedVariations?.join(","))
  //   }

  //   // setTotalQuantity(1)
  //   // setSelectedProductSku(selectedSku);

  //   return selectedSku;

  // }, [state, selectedVariations])

  const getProductVariationPrice = useCallback((variation, type = "retail") => {
    if (!state || !selectedVariations) return null
    let selectedSku;
    if (selectedVariations?.length <= 0) {
      selectedSku = state?.skus?.[0]
    }
    else if (selectedVariations?.length < 2) {
      selectedSku = state?.skus?.find(item => item.props === variation.toString())
    }
    else {
      if (type === 'retail') {
        selectedSku = state?.skus?.find(item => item.props === variation?.join(','))
      } else if (type === 'wholesale') {
        let slicedVarations = selectedVariations.slice(0, selectedVariations.length - 1);
        selectedSku = state.skus.find(item => item.props === [...slicedVarations, ...[variation]].join(','))
      }
    }

    return selectedSku;

  }, [state, selectedVariations])

  useEffect(() => {
    let selectedSku;
    if ((selectedVariations?.length > 0 && selectedVariations[0]) || (selectedVariations?.length === 0)) {
      selectedSku = getProductVariationPrice(selectedVariations);
    }
    setSelectedProductSku(selectedSku);
  }, [getProductVariationPrice, selectedVariations])

  useEffect(() => {
    if (selectedVariations?.length > 0) {
      let selectedProps = state && state?.attributes?.map((item, idx) => {
        return item?.values?.find(prop => prop?.id === selectedVariations[idx])
      })
      setSelectedProps(selectedProps);
    }

  }, [selectedVariations]) // eslint-disable-line react-hooks/exhaustive-deps

  const getProductVariationPriceSku = (variation) => {
    return state.skus.find(item => item.props === variation)
  }

  const handleVariationSelect = (item, metaIdx) => {
    let newSelectedVariation = selectedVariations?.map((varItem, itemIndex) => {
      if (itemIndex === metaIdx) {
        return item.id
      }
      return varItem
    })
    if (item?.image) {
      setSelectedProductImg(item.image)
    } else {
      let attrs = [...productData?.attributes];
      let imageURI = null;
      if (attrs?.length > 1) {
        attrs.splice(metaIdx, 1)

        for (let attr of attrs) {
          let desiredPropValue = attr.values.find(value => newSelectedVariation.includes(value.id))
          if (desiredPropValue?.image) {
            imageURI = desiredPropValue?.image
            break;
          }
        }
      }
      if (imageURI) {
        setSelectedProductImg(imageURI)
      } else {
        let desiredImage = getConfigValue(defaultKeys.defaultSkuImg);
        let desiredMessage = getConfigValue(defaultKeys.defaultSkuMsg);
        let defaultSkuImgShops = getConfigValue(defaultKeys.defaultSkuImgShops);
        let hasDefaultSkuShops = defaultSkuImgShops?.value?.split(',')?.includes(productData?.source?.id) || productData?.source?.identifier === 'taobao';
        if (hasDefaultSkuShops && desiredImage?.value) {
          setSelectedProductImg(desiredImage?.value);
        }
        if (hasDefaultSkuShops && desiredMessage?.value) {
          setDefaultSkuMessage(desiredMessage?.value);
        }
      }
    }
    setSelectedVariations(newSelectedVariation)
    let selectedSku = getProductVariationPrice(newSelectedVariation);
    setSelectedProductSku(selectedSku);
  }

  const getSelectedSkuQty = (item, _propIdx) => {
    let skusQty = itemsMeta.filter(meta => meta.key.split(',')?.includes(item.id))
    return skusQty.reduce((qty, current) => {
      return qty + current?.qty;
    }, 0)
  }

  const getMetaField = (item) => {
    let newVariations
    if (variationsSize < 2) {
      newVariations = item.id
    } else {
      let slicedVariations = [...selectedVariations];
      if (slicedVariations.length > 1) {
        slicedVariations = slicedVariations.slice(0, slicedVariations.length - 1);
      }
      newVariations = [...slicedVariations, ...[item.id]].join(',')
    }
    if (typeof newVariations === "number") {
      return itemsMeta.find(item => item.key.toString() === newVariations.toString())
    } else {
      return itemsMeta.find(item => item.key === newVariations)
    }
  }

  const handleAddProductMeta = (value, item, type) => {
    let isValid = false
    let regex = /^[0-9\s]*$/
    isValid = regex.test(value)

    if (!isValid) return

    let newVariations
    if (variationsSize < 2) {
      newVariations = [item.id]
    } else {
      let slicedVariations = [...selectedVariations];
      if (slicedVariations.length > 1) {
        slicedVariations = slicedVariations.slice(0, slicedVariations.length - 1);
      }
      newVariations = [...slicedVariations, ...[item.id]]
    }

    // Get selected meta values
    let cartItemMeta = []
    for (let i = 0; i < newVariations.length; i++) {
      const element = newVariations[i]
      let propsItem = productData.attributes[i]
      if (propsItem) {

        let propValue = propsItem.values.find(propItem => propItem.id === element)
        cartItemMeta.push({
          title: propsItem.name,
          value: propValue.name,
          image: propValue?.image ? propValue.image : null,
          thumb: propValue?.thumb ? propValue.thumb : null,
        })
      }
    }

    let wholeSalePrice = ''
    if (productData?.wholesales != null) {
      wholeSalePrice = productData.wholesales?.filter(WSPrice => WSPrice.qty >= fixedTotal && WSPrice.qty <= fixedTotal)
    } else {
      wholeSalePrice = 0
    }

    let finalWholeSalePrice = null
    if (wholeSalePrice !== 0) {
      // wholeSalePrice?.map(finalWSPrice => {
      //   finalWholeSalePrice = finalWSPrice
      // })
      finalWholeSalePrice = wholeSalePrice[wholeSalePrice.length - 1]
    }

    let metaKey = newVariations.join(',')
    // Cart item price
    let cartItemPriceProp = getProductVariationPriceSku(metaKey)
    let sku_id = cartItemPriceProp.id

    let itemPrice = 0
    if (finalWholeSalePrice?.qty && !isCampaignItem) {
      itemPrice = finalWholeSalePrice.discount || finalWholeSalePrice.original
    } else if (cartItemPriceProp?.price?.offer || cartItemPriceProp?.discount_price) {
      itemPrice = cartItemPriceProp?.price?.offer || cartItemPriceProp?.discount_price
    } else if (cartItemPriceProp?.price?.actual || cartItemPriceProp?.price) {
      itemPrice = cartItemPriceProp?.price?.actual || cartItemPriceProp?.price
    }

    let findMeta = itemsMeta.find(meta => meta.key === metaKey)
    let newItemsMeta
    if (findMeta) {
      let qtyValue = isNaN(parseInt(value)) ? '' : parseInt(value)
      if (type && type === 'dec') {
        qtyValue = parseInt(findMeta.qty) - 1
      }
      if (type && type === 'inc') {
        qtyValue = parseInt(findMeta.qty) + 1
      }

      if (qtyValue < 1) {
        newItemsMeta = itemsMeta.filter(meta => meta.key !== metaKey)
      } else {
        newItemsMeta = itemsMeta.map(metaItem => {
          if (metaItem.key === metaKey) {
            metaItem.qty = qtyValue
            metaItem.meta = cartItemMeta
          }
          return metaItem
        })
      }

      // if (finalWholeSalePrice) {
      //   newItemsMeta = newItemsMeta.map(metaItem => {
      //     metaItem.price = itemPrice
      //     return metaItem
      //   })
      // }

    } else {
      newItemsMeta = [
        ...itemsMeta,
        ...[{
          sku_id: sku_id,
          key: metaKey,
          qty: value,
          price: itemPrice,
          meta: cartItemMeta,
        }]
      ]
    }
    const totalQuantity = newItemsMeta.reduce((acc, value) => {
      if (value.qty === '') {
        value = 0
      } else {
        value = parseInt(value.qty)
      }
      return acc + value
    }, 0)
    setFixedTotal(totalQuantity)
    setTotalQty(totalQuantity)
    setItemsMeta(newItemsMeta)
  }

  useEffect(() => {
    if (productData?.wholesales?.length && isWholesaleProduct) {
      let filteredWholeSales = productData.wholesales.filter(WSPrice => WSPrice.qty <= fixedTotal);
      let wsPriceObj = filteredWholeSales[filteredWholeSales.length - 1];
      let wholesaleTotalPrice;

      if (wsPriceObj?.qty) {
        setActiveWholesalePrice(wsPriceObj)
        wholesaleTotalPrice = itemsMeta?.reduce((total, sku) => {
          let activePrice;
          if (wsPriceObj.discount) {
            activePrice = parseFloat(wsPriceObj?.discount)
          } else {
            activePrice = parseFloat(wsPriceObj?.original)
          }
          total += (activePrice * sku.qty);
          return total;
        }, 0)
      } else {
        wholesaleTotalPrice = itemsMeta?.reduce((total, sku) => {
          let activePrice = parseFloat(sku.price);
          total += (activePrice * sku.qty);
          return total;
        }, 0)
      }
      setTotalPrice(wholesaleTotalPrice);
    }
  }, [productData, isWholesaleProduct, itemsMeta, fixedTotal])

  useEffect(() => {
    if (isFetched && productData?.id && !productLoading && selectedProductSku?.id && productData?.type !== "campaign") {
      let dataShipping = {
        id: productData?.id,
        type: productData?.type ? "internal" : "external"
      }

      if (isWholesaleProduct) {
        dataShipping = {
          ...dataShipping,
          qty: fixedTotal || 1,
          sku: selectedProductSku?.id || productData?.skus?.[0]?.id
        }
        if (dataShipping?.id !== shippingPayload?.id) {
          setSelectedShippingDetails(null);
          setShippingPayload(dataShipping)
          dispatchRedux(getShippingChargesValue(dataShipping))
        }
        setApproxWeight(() => parseFloat(((productData?.meta?.weight || 0) * (fixedTotal || 1)).toFixed(2)))
      } else if (selectedProductSku?.id && totalQuantity) {
        dataShipping = {
          ...dataShipping,
          sku: selectedProductSku?.id,
          qty: totalQuantity,
        }
        setSelectedShippingDetails(null);
        dispatchRedux(getShippingChargesValue(dataShipping))
        setApproxWeight(() => parseFloat(((productData?.meta?.weight || 0) * (totalQuantity || 1)).toFixed(2)))
      }

      let dataDomesticShipping = {
        pid: productData?.id,
      }
      if (isWholesaleProduct) {
        dataDomesticShipping['qty'] = fixedTotal;
      } else {
        dataDomesticShipping['qty'] = totalQuantity;
      }
      setSelectedShippingDetails(null);
      let payloadMemoized = Object.keys(dataDomesticShipping)?.map(key => `${key}:${dataDomesticShipping[key]}`).join('');
      if (productData?.shop_id !== 1 && dataDomesticShipping?.qty && !domesticPayload) {
        dispatchRedux(getDomesticChargesValue(dataDomesticShipping))
        setDomesticPayload(payloadMemoized)
      }
    }
  }, [isFetched, productData, productLoading, selectedProductSku?.id, totalQuantity, dispatchRedux, isWholesaleProduct, shippingPayload, fixedTotal, domesticPayload])

  useEffect(() => {
    let findMoveOnShipping = shippingMethodsData?.find(el => el.identifier === "mvn_ship_for_me")
    if (shippingMethodsData?.length >= 1) {
      if (findMoveOnShipping) {
        let newFee = Object.assign({}, findMoveOnShipping);
        newFee.fee = "Category not selected"
        if (categoryShippingCharge) {
          if (categoryShippingCharge?.unit === "piece") {
            newFee.fee = categoryShippingCharge?.price
          } else {
            newFee.fee = ((parseFloat(categoryShippingCharge.price) / 1000) * 100)?.toFixed(2)
          }
          newFee.freight_cat = categoryShippingCharge.value
        }
        setSelectedShippingDetails(newFee)
      } else {
        setSelectedShippingDetails(shippingMethodsData[0])
      }
    }
  }, [categoryShippingCharge, shippingMethodsData, state?.shop_id]);

  const handleSelectedShippingMethod = (value) => {
    setSelectedShippingDetails(value)
    // setViewShippingSelect(false)
  }

  useEffect(() => {
    if (categoryShippingCharge && approxWeight >= 0) {
      let rate = getShippingRate({ selectedFrCat: categoryShippingCharge, approxWeight, isWholesaleProduct })
      if (rate >= 0) {
        setShippingRate(rate)
      }
      let approxCost;
      if (rate) {
        approxCost = parseFloat((rate * approxWeight).toFixed(2))
        setApproxShippingCost(approxCost);
      }
    }
  }, [approxWeight, categoryShippingCharge, isWholesaleProduct])

  const handleRequestNow = () => {

    let totalSkuQuantity = 1
    let stock = selectedProductSku?.stock

    totalSkuQuantity = parseInt(totalQuantity);

    if (totalQuantity < stock) {
      totalSkuQuantity = parseInt(totalQuantity);
    }

    let selectedRequestSku = state?.attributes?.map((productVariations, index) => {
      return {
        key: productVariations?.name,
        value: selectedProps?.[index]?.label ?? selectedProps?.[index]?.title
      }
    })

    let requestFormData = {
      link: state.link,
      title: state.title,
      shop_id: state?.source?.id,
      image: state.image,
      attrs: selectedRequestSku,
      quantity: totalSkuQuantity,
      instructions: state?.instructions,
    }

    setMyRequestLoading(true)
    addMyRequest(requestFormData)
      .then(res => {
        setMyRequestLoading(false)
        Toast.show({
          content: res?.data?.message,
        })
        window.localStorage.setItem(ACTIVE_REQUEST_TAB, JSON.stringify("requested"))
        history.push('/my-request')
      })
      .catch(err => {
        setMyRequestLoading(false)
        if (err?.response?.data?.message) {
          Toast.show({
            icon: "fail",
            content: err?.response?.data?.message,
          })
        } else {
          Toast.show({
            icon: "fail",
            content: "Add to cart failed!",
          })
        }
      })
  }

  const handleAddToCart = (isBuyNow) => {

    let params = qs.parse((window.location.search).substring(1))

    let stock = selectedProductSku?.stock
    let totalSkuQuantity = 1

    if (totalQuantity < stock) {
      totalSkuQuantity = parseInt(totalQuantity);
    }

    setViewVariations(true)

    let data = {
      id: state?.id,
      freight: selectedShippingDetails?.identifier,
      // skuid: selectedProductSku?.id?.toString(),
      // quantity: totalSkuQuantity,
      // type: state?.type ? "internal" : undefined,
      // freight_cat: (selectedShippingDetails?.identifier === "mvn_ship_for_me" && selectedShippingDetails?.freight_cat) ? selectedShippingDetails?.freight_cat : undefined,
    }

    if (isWholesaleProduct) {
      data['order_type'] = 'wholesale'
      data['meta'] = itemsMeta.map(item => ({
        quantity: item.qty,
        skuid: item.sku_id
      }))
    } else {
      data['meta'] = [
        {
          quantity: totalSkuQuantity,
          skuid: selectedProductSku?.id?.toString()
        }
      ]
    }

    if (state?.type === "campaign") {
      data = {
        ...data,
        freight: "mvn_ship_for_me",
        campaign_product_id: parseInt(params?.campaign_product ?? params?.product_id),
        type: "internal"
      }
    }
    if (data.freight === 'mvn_ship_for_me' && state?.type !== "campaign") {
      data['freight_cat'] = (selectedShippingDetails?.identifier === "mvn_ship_for_me" && selectedShippingDetails?.freight_cat) ? selectedShippingDetails?.freight_cat : undefined;
    }

    if (!loggedIn) {
      setViewLoginPopUp(true)
      setViewVariations(false)
    } else if (!is3TipsAgreed) {
      setThreeTipsView(true)
      setViewVariations(false)
    } else if (data?.meta?.length <= 0) {
      Toast.show({
        icon: "fail",
        content: "You haven't picked any skus."
      })
    }
    else if (totalQuantity > stock) {
      Toast.show({
        icon: "fail",
        content: "The Quantity exceeded the available stock!"
      })
    } else if ((!selectedProductSku || !viewVariations)) {
      setViewVariations(true)
      setViewShippingSelect(false)
    } else if (!selectedShippingDetails && state?.type !== "campaign") {
      setViewShippingSelect(true)
    } else if (selectedShippingDetails?.identifier === "mvn_ship_for_me" && !categoryShippingCharge) {
      Toast.show({
        content: "Please select a category"
      })
      setViewShippingSelect(true)
    } else if (state?.type === "campaign" && !state?.shippingCategory?.id) {
      Toast.show({
        content: "There is no shipping charge found for this product"
      })
    } else if (isBuyNow === "buyNow") {
      setIsBuyNowLoading(true)
      addToCart(data)
        .then(res => {
          getCart({ buy_id: res.data.added_id })
            .then(res => {
              fetchCart()
              setIsBuyNowLoading(false)
              setViewVariations(false)
              setViewShippingSelect(false)
              window.localStorage.setItem(CHECKOUT_ITEM, JSON.stringify(res.data.data))
              dispatchRedux(setBuyNowItem(res.data.data))
              setTimeout(() => {
                history.push('/checkout')
              }, 1000);

            })
            .catch(err => {
              Toast.show({
                icon: "fail",
                content: "Not Found!",
              })
            })
        })
        .catch((err) => {
          setIsBuyNowLoading(false)
          if (err?.response?.status === 401) {
            setViewLoginPopUp(true)
          } else if (err?.response?.data?.message) {
            Toast.show({
              icon: "fail",
              content: err?.response?.data?.message,
            })
          } else {
            Toast.show({
              icon: "fail",
              content: "Add to cart failed!",
            })
          }
        })
    } else {
      setIsAddToCardLoading(true)
      addToCart(data)
        .then(res => {
          setAfterCartDialogView(true)
          fetchCart()
          setIsAddToCardLoading(false)
          setViewVariations(false)
          setViewShippingSelect(false)

          ReactGA.event({
            category: 'cart',
            action: 'add_to_cart',
            items: [{
              item_id: data.id,
              freight: data.freight,
              freight_cat: data.freight_cat,
            }]
          });
        })
        .catch((err) => {
          if (err?.response?.status === 401) {
            setViewLoginPopUp(true)
          } else if (err?.response?.data?.message) {
            Toast.show({
              icon: "fail",
              content: err?.response?.data?.message,
            })
          } else {
            Toast.show({
              icon: "fail",
              content: "Add to cart failed!",
            })
          }
          setIsAddToCardLoading(false)
        })
    }
  }

  return <ProductDetailsContext.Provider value={{
    state,
    dispatch,
    selectedVariations,
    setSelectedVariations,
    selectedProductSku,
    setSelectedProductSku,
    getProductVariationPrice,
    getProductVariationPriceSku,
    handleVariationSelect,
    setSelectedProductImg,
    selectedProductImg,
    setTotalQuantity,
    totalQuantity,
    shippingMethodsData,
    isShippingMethodsLoading,
    handleSelectedShippingMethod,
    setSelectedShippingDetails,
    selectedShippingDetails,
    handleAddToCart,
    isAddToCardLoading,
    viewDisclaimer,
    setViewDisclaimer,
    viewVariations,
    setViewVariations,
    viewSpecification,
    setViewSpecification,
    viewShippingSelect,
    setViewShippingSelect,
    resetProduct,
    selectedProps,
    setIsProductLoading,
    isProductLoading,
    viewLoginPopUp,
    setViewLoginPopUp,
    afterCartDialogView,
    setAfterCartDialogView,
    isBuyNowLoading,
    setStockOutProps,
    stockOutProps,
    setThreeTipsView,
    threeTipsView,
    handleRequestNow,
    myRequestLoading,
    setIs3TipsAgreed,
    is3TipsAgreed,
    categoryShippingCharge,
    setCategoryShippingCharge,
    selectedDomesticDetails,
    isDomesticShippingLoading,
    similarProduct, setSimilarProduct,
    productStore,
    itemsMeta, setItemsMeta,
    getMetaField,
    variationsSize,
    handleAddProductMeta,
    totalQty, setTotalQty,
    getSelectedSkuQty,
    isCampaignItem,
    isWholesaleProduct,
    defaultSkuMessage,
    totalPrice, setTotalPrice,
    approxWeight,
    approxShippingCost,
    shippingRate, setShippingRate,
    activeWholesalePrice, setActiveWholesalePrice
  }} {...props} />
}

const useProduct = () => {
  const context = React.useContext(ProductDetailsContext)
  if (context === undefined) {
    throw new Error(`useProduct must be used within a ProductProvider`)
  }
  return context
}

export { ProductDetailsProvider, useProduct }
