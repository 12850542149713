import React from 'react'
import {ImageViewer} from "antd-mobile";

const LargeImageViewer = ({ src, visible, setVisible}) => {

    // const [visible, setVisible] = useState(false)

    return (
        <ImageViewer
            image={src}
            visible={visible}
            onClose={() => {
                setVisible(false)
            }}
        />
    )
}

export default LargeImageViewer
