import React from 'react'
import { Image, TabBar } from "antd-mobile";
import {
  HomeOutlined,
  ReconciliationOutlined,
  ShoppingCartOutlined,
  UserOutlined,
  HeartOutlined,
} from "@ant-design/icons";
import './BottomTabBar.scss'
import { useCart } from "context/cart-context";
import { useHistory, useLocation } from "react-router-dom";
import { AppOutline } from "antd-mobile-icons";
import { useLayoutContext } from 'context/LayoutContext';
import { defaultKeys } from 'consts/storage';

const BottomTabBar = () => {
  const { visibleConfigs, homeIcon } = useLayoutContext();
  const { state: cartMetaData } = useCart()

  const history = useHistory()
  const location = useLocation()
  const { pathname } = location

  const setRouteActive = (value) => {
    history.push(value)
  }

  //For test use it might be remove in future
  const pathImages = <Image src={homeIcon?.image ?? "/assets/logo/circle_logo.png"} width={35} height={35} fit='contain' style={{ borderRadius: 32 }} />

  const tabs = [
    {
      key: '/',
      title: pathname === "/" ? pathImages : "Home",
      icon: pathname === "/" ? null : <HomeOutlined />,
    },
    {
      key: '/categories',
      title: 'Category',
      icon: <AppOutline />,
    },
    {
      key: '/cart',
      title: 'Cart',
      icon: <ShoppingCartOutlined />,
      badge: cartMetaData.data?.length,
    },
    {
      key: '/dashboard',
      title: 'Account',
      icon: <UserOutlined />,
    },
  ]

  if (visibleConfigs?.[defaultKeys.productRequestVisible]) {
    tabs.splice(2, 0, {
      key: '/my-request',
      title: 'Request',
      icon: <ReconciliationOutlined />,
    },)
  } else {
    tabs.splice(2, 0, {
      key: '/wish-list',
      title: 'Wishlist',
      icon: <HeartOutlined />,
    },)
  }

  return (
    <div className="tab-bar">
      <TabBar activeKey={pathname} onChange={value => setRouteActive(value)}>
        {tabs.map(item => (
          <TabBar.Item key={item.key} icon={item.icon} title={item.title} badge={item.badge} />
        ))}
      </TabBar>
    </div>
  )
}

export default BottomTabBar
