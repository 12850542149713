import produce from "immer";
import * as Types from 'app/actionTypes/issue';

const initialState = {
  itemIssues: null,
  itemIssueLoading: true
}

export default function issue(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.ISSUE_ABLE_DETAILS_REQUEST:
        draft.itemIssueLoading = true
        break
      case Types.ISSUE_ABLE_DETAILS_SUCCESS:
        draft.itemIssues = action.data
        draft.itemIssueLoading = false
        break
      case Types.ISSUE_ABLE_DETAILS_FAILED:
        draft.itemIssueLoading = false
        draft.error = action.error
        break
      default:
        return state
    }
  })
}
