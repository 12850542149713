import { localStorageKey } from "constants/constants";
import produce from "immer";


export const getGroupedItems = (items, groupTypes = 'default') => {

  if (groupTypes === 'checkout') {
    items = items?.filter(item => item?.isSelected === true)
  }

  let newSellerList = [];
  let newGroupedItems = {};

  for (let i = 0; i < items?.length; i++) {
    const element = items[i]
    let hasSeller = newSellerList.includes(element?.seller_id)
    if (hasSeller) {
      newGroupedItems[element?.seller_id] = [...newGroupedItems[element?.seller_id], ...[element]]
    } else {
      newGroupedItems[element?.seller_id] = [element]
      newSellerList.push(element?.seller_id)
    }
  }

  return newGroupedItems;
}

const calculateSelectAllStatus = (allItems, selectedItems) => {
  if (selectedItems?.length === allItems?.length) {
    return {
      selected: true,
      indeterminate: false
    }
  } else if ((selectedItems?.length < allItems?.length) && selectedItems?.length > 0) {
    return {
      selected: false,
      indeterminate: true
    }
  } else {
    return {
      selected: false,
      indeterminate: false
    }
  }
}

const getTotalPriceBdt = (items, isSelected = false) => {
  let value = 0;
  let totalPrice;
  let totalValue = 0;
  totalPrice = items.reduce((acc, item) => {
    if (item.price === '') {
      value = 0
    } else {
      value = parseFloat(item.price)
    }
    if (isSelected) {
      if (item.isSelected) {
        totalValue = parseFloat(acc) + parseFloat(value)
      }
      return totalValue
    }

    totalValue = parseFloat(acc) + parseFloat(value)

    return totalValue;
  }, 0)

  return totalPrice;

}

const getSelectedItemsID = (products) => {
  return products?.filter(item => item?.isSelected === true).map(item => item?.id);
}

const getSelectedItems = (products) => {
  return products?.filter(item => item?.isSelected === true).map(item => item);
}

const updateLocalCartItems = (products) => {
  localStorage.setItem(localStorageKey.SELECTED_CART_ITEMS, JSON.stringify([...products]))
}

const getUpdatedList = (draft) => {
  let ulist = draft.data;
  if (draft.filter_type === 'wholesale') {
    ulist = draft.data.filter(item => item.order_type === draft.filter_type)
  } else if (draft.filter_type === 'retails') {
    ulist = draft.data.filter(item => item.order_type !== 'wholesale')
  }
  return ulist;
}

const cartReducer = (state, action) => {
  const { payload, type } = action;

  return produce(state, (draft) => {
    switch (type) {
      case 'SET_FILTERED_TYPE':
        draft.filter_type = payload.type;
        draft.list = getUpdatedList(draft);
        draft.groupedItems = getGroupedItems(draft.list);
        break;
      case 'STATE_UPDATE':
        draft.isChanged = payload.isChanged;
        break;
      case 'ADD_ITEM':
        draft.data.push(payload)
        draft.list = getUpdatedList(draft);
        draft.groupedItems = getGroupedItems(draft.list);
        break;
      case 'REMOVE_ITEM':
        draft.data = draft.data.filter(item => !payload.includes(item.id));
        draft.list = getUpdatedList(draft);
        draft.groupedItems = getGroupedItems(draft.list);
        break;
      case 'QTY_CHANGE': {
        const { quantity, meta_id, meta_price, price, id } = payload
        if (isNaN(quantity) || parseInt(quantity) < 0) {
          return state
        }
        let qtyValue = isNaN(parseInt(quantity)) ? '' : parseInt(quantity)

        draft.data = draft.data.map(item => {
          if (item?.id === id && meta_id && quantity) {
            item['meta_items'] = item.meta_items?.map(meta => {
              if (meta?.id === meta_id) {
                meta['quantity'] = qtyValue;
                meta['price'] = meta_price;
              }
              return meta;
            })
            item.price = price;
          }
          else if (item.id === id) {
            item.quantity = qtyValue
            item.price = price
          }
          return item
        })
        draft.list = getUpdatedList(draft);
        draft.groupedItems = getGroupedItems(draft.list);
      }
        break;
      case 'UPDATE_NOTE':
        draft.data = draft.data.map(item => {
          if (parseInt(payload.itemId) === item.id) {
            item.instructions = payload.instructions
          }
          return item
        })
        draft.list = getUpdatedList(draft);
        draft.groupedItems = getGroupedItems(draft.list);
        break;
      case 'CHECKBOX_CHANGE': {
        let newItems = draft.data?.map(item => {
          if (item.id === payload.itemId) {
            item.isSelected = !item.isSelected
          }
          return item
        })
        let selectedItemsID = getSelectedItemsID(newItems)

        // Set checked all status
        let selectedAllStatus = calculateSelectAllStatus(newItems, selectedItemsID)
        draft.selectedAllStatus = selectedAllStatus;

        // update groupedSelectedStatus
        let groupedSelectedStatus = {}
        let groupSelectedItems = getGroupedItems(newItems);
        for (const property in groupSelectedItems) {
          let propertySelectedItems = getSelectedItemsID(groupSelectedItems[property])
          groupedSelectedStatus[property] = calculateSelectAllStatus(groupSelectedItems[property], propertySelectedItems)
        }
        draft.groupedSelectedStatus = groupedSelectedStatus;

        // update total amount
        let selectedItems = getSelectedItems(newItems);
        let selectedItemsAmount = getTotalPriceBdt(selectedItems, true)
        draft.total = selectedItemsAmount;

        // update charges and total charges
        let selectedSellers = 0;
        if (draft?.charge_type === 'by_seller') {
          for (const key in groupedSelectedStatus) {
            const item = groupedSelectedStatus[key];
            if (item.selected || item.indeterminate) {
              selectedSellers++;
            }
          }
        } else {
          selectedSellers = 1;
        }

        let updatedChargeList = [];
        let totalCharges = 0;
        if (draft.charges.length && draft.charge_type) {
          updatedChargeList = draft.charges.map(item => ({
            ...item,
            qty: selectedSellers,
            total: item.amount * selectedSellers,
          }))
          totalCharges = updatedChargeList.reduce((total, charge) => {
            total += charge.total
            return total;
          }, 0)
        }

        draft.charges = updatedChargeList;
        draft.totalCharges = totalCharges;
        draft.data = newItems;
        draft.list = getUpdatedList(draft);
        draft.groupedItems = getGroupedItems(draft.list);
      }
        break;
      case 'SELECT_ALL': {
        let prevData = draft.data;
        let updatedData;
        let groupedSelectedStatus = draft.groupedSelectedStatus
        let selectedSellers = 0;
        let isAllSelected = false;
        let isAllIndeterminate = false;
        let selectedCount = 0;
        if (payload?.selectedCartGroup !== "default" && payload?.filteredItemsID?.length) {
          const filteredSellers = [];

          updatedData = prevData?.map(item => {
            if (payload?.filteredItemsID?.includes(item.id)) {
              if (!filteredSellers.includes(item.seller_id)) {
                filteredSellers.push(item.seller_id)
              }
              item.isSelected = payload.isChecked
              if (item.isSelected) {
                selectedCount++;
              }
            }
            return item
          })

          isAllSelected = selectedCount === payload?.filteredItemsID?.length;
          isAllIndeterminate = selectedCount > 0 && selectedCount !== payload?.filteredItemsID?.length;

          const sellers = Object.keys(groupedSelectedStatus);

          sellers.forEach(seller => {
            if (filteredSellers.includes(seller)) {
              let item = groupedSelectedStatus[seller];
              let selected = payload.isChecked
              groupedSelectedStatus[seller] = {
                ...item,
                selected,
              }
              if (selected) {
                selectedSellers++;
              }
            }
          })
        }
        else {
          updatedData = prevData?.map(item => {
            item.isSelected = payload.isChecked;
            if (item.isSelected) {
              selectedCount++;
            }
            return item
          })
          isAllSelected = selectedCount === prevData.length;
          isAllIndeterminate = selectedCount > 0 && selectedCount !== prevData.length;
          const sellers = Object.keys(groupedSelectedStatus);

          sellers.forEach(seller => {
            let item = groupedSelectedStatus[seller];
            let selected = payload.isChecked;
            if (selected) {
              selectedSellers++;
            }
            groupedSelectedStatus[seller] = {
              ...item,
              selected,
            }
            return groupedSelectedStatus[seller];
          })
        }

        // update selected all status
        draft.selectedAllStatus = {
          selected: isAllSelected,
          indeterminate: isAllIndeterminate,
        }
        // upddate group selected status
        draft.groupedSelectedStatus = groupedSelectedStatus;

        let selectedItemsID = getSelectedItemsID(updatedData)
        updateLocalCartItems(selectedItemsID)

        let selectedItems = getSelectedItems(updatedData);
        draft.total = getTotalPriceBdt(selectedItems)

        if (draft?.charge_type === 'by_order') {
          selectedSellers = isAllSelected || isAllIndeterminate ? 1 : 0;
        }

        let updatedChargeList = [];
        let totalCharges = 0;
        if (draft.charges.length && draft.charge_type) {
          updatedChargeList = draft.charges.map(item => ({
            ...item,
            qty: selectedSellers,
            total: item.amount * selectedSellers,
          }))

          totalCharges = updatedChargeList.reduce((total, charge) => {
            total += charge.total
            return total;
          }, 0)
        }

        draft.charges = updatedChargeList;
        draft.totalCharges = totalCharges;
        draft.data = updatedData;
        draft.list = getUpdatedList(draft);
        draft.groupedItems = getGroupedItems(draft.list);
      }
        break;
      case 'SELLER_SELECT': {
        const sellerID = payload.seller;
        const newData = draft.data.map(item => {
          if (item.seller_id === payload.seller) {
            item.isSelected = payload.isChecked
          }
          return item
        })
        // update data
        draft.data = newData;

        // update select all status
        let selectedItemsID = getSelectedItemsID(newData)
        let selectedAllStatus = calculateSelectAllStatus(newData, selectedItemsID)
        draft.selectedAllStatus = selectedAllStatus;

        // update total amount
        let selectedItems = getSelectedItems(newData);
        let selectedItemsAmount = getTotalPriceBdt(selectedItems, true)
        draft.total = selectedItemsAmount

        // update group selected status
        draft.groupedSelectedStatus[sellerID].selected = payload.isChecked;

        let selectedSellers = 0;
        if (draft?.charge_type === 'by_seller') {
          for (const key in draft.groupedSelectedStatus) {
            const item = draft.groupedSelectedStatus[key];
            if (item.selected) {
              selectedSellers++;
            }
          }
        } else {
          selectedSellers = 1;
        }
        // update charges and total charges;
        let updatedChargeList = [];
        let totalCharges = 0;
        if (draft.charges.length && draft.charge_type) {
          updatedChargeList = draft.charges.map(item => ({
            ...item,
            qty: selectedSellers,
            total: item.amount * selectedSellers,
          }))
          totalCharges = updatedChargeList.reduce((total, charge) => {
            total += charge.total
            return total;
          }, 0)
        }
        draft.charges = updatedChargeList;
        draft.totalCharges = totalCharges;
        draft.list = getUpdatedList(draft);
        draft.groupedItems = getGroupedItems(draft.list);
      }
        break;
      case 'SET_EXISTING_CART':
        const groupedSelectedStatus = {};
        const data = payload.data.map(item => {
          item.isSelected = false
          if (!groupedSelectedStatus[item.seller_id]) {
            groupedSelectedStatus[item.seller_id] = {
              selected: false,
              indeterminate: false,
            }
          }
          return item
        })
        let charges = [];
        if (payload.charge_type) {
          charges = payload.charges.map(charge => ({
            ...charge,
            qty: 0,
            total: charge.amount,
            label: charge.type.split('_').map(word => {
              if (word.length > 2) {
                word = word.charAt(0).toUpperCase() + word.slice(1,);
              }
              return word;
            }).join(' '),
          }))
        }

        draft.data = data;
        draft.groupedSelectedStatus = groupedSelectedStatus;
        draft.charge_type = payload.charge_type
        draft.charges = charges;
        let totalCharges = 0;

        if (draft.charge_type === 'by_order') {
          totalCharges = charges.reduce((total, charge) => {
            total += charge.total;
            return total;
          }, 0)
        }
        draft.totalCharges = totalCharges;
        draft.filter_type = 'default';
        draft.list = getUpdatedList(draft);
        draft.groupedItems = getGroupedItems(draft.list);
        break;
      case 'CLEAR_CART_STATE': {
        let newData = draft.data?.map(item => {
          item.isSelected = false;
          return item;
        })

        // Set brand group check status
        let groupStatus = {}
        let groupedSelectedStatus = draft.groupedSelectedStatus;
        for (const property in groupedSelectedStatus) {
          groupStatus[property] = {
            selected: false,
            indeterminate: false,
          }
        }

        const charges = draft.charges.map(item => ({
          ...item,
          qty: 0,
          total: 0,
        }))

        const totalCharges = charges.reduce((total, charge) => {
          total += charge.total
          return total;
        }, 0)
        draft.data = newData;
        draft.charges = charges;
        draft.totalCharges = totalCharges;
        draft.groupedSelectedStatus = groupedSelectedStatus;
        draft.selectedAllStatus = {
          selected: false,
          indeterminate: false,
        }
        draft.list = draft.data
        draft.groupedItems = getGroupedItems(draft.list);
      }
        break;

      default:
        return state;
    }
  })
}

export { cartReducer }
