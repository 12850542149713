import React, { useEffect, useState } from "react"
import "../../Myorders.scss"
import { getProductIssue, getProductIssueDetails, storeProductIssue } from "services/my-order-client";
import { Button, Form, Grid, Popup, Selector, Skeleton, Space, Tag, Toast } from "antd-mobile";
import useErrors from "hooks/useErrors";
import { ShowPriceWithCurrency } from "utils/helper";
import { useHistory } from "react-router-dom";
import { useLayoutContext } from "context/LayoutContext";

const ProductIssue = ({ productId, item, fromIssueDetails }) => {
  const history = useHistory();
  const { localeCurrency } = useLayoutContext();
  const [form] = Form.useForm();
  const [isProductIssueLoading, setIsProductIssueLoading] = useState(false);
  const [productIssueData, setProductIssueData] = useState(null);
  const [isProductIssueDetailsLoading, setIsProductIssueDetailsLoading] = useState(false);
  const [productIssueDataDetails, setProductIssueDataDetails] = useState(null);
  const [viewIssueFrom, setViewIssueFrom] = useState(false);
  const { getErrors } = useErrors();
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [selectedIssue, setSelectedIssue] = useState("purchase");
  const [isFirstCall, setIsFirstCall] = useState(true);

  const fetchProductIssue = React.useCallback(
    () => {
      setIsProductIssueLoading(true)
      getProductIssue(productId)
        .then(res => {
          setProductIssueData(res.data.data)
          setIsProductIssueLoading(false)
        })
        .catch(err => {
          setIsProductIssueLoading(false)
        })
    },
    [productId],
  )

  const fetchProductIssueDetails = React.useCallback(
    () => {
      setIsProductIssueDetailsLoading(true)
      if (productIssueData?.issue?.id) {
        getProductIssueDetails(productIssueData?.issue?.id)
          .then(res => {
            setProductIssueDataDetails(res.data)
            setIsProductIssueDetailsLoading(false)
          })
          .catch(err => {
            setIsProductIssueDetailsLoading(false)
          })
      }
    },
    [productIssueData?.issue?.id],
  )

  useEffect(() => {
    if (isFirstCall) {
      fetchProductIssue()
      setIsFirstCall(false)
    }
    form.setFieldsValue({ action: ["purchase"] })
    if (productIssueData?.issue?.id) {
      fetchProductIssueDetails()
    }
  }, [fetchProductIssue, form, fetchProductIssueDetails, productIssueData?.issue?.id, isFirstCall])

  const onFinishIssue = (value) => {
    setIsFormLoading(true)
    let action = value.action?.length > 0 ? value.action[0] : null
    storeProductIssue(productIssueData.issue.id, { action: action })
      .then(res => {
        Toast.show({
          content: res?.data?.message,
        })
        setViewIssueFrom(false)
        setIsFormLoading(false)
        if (fromIssueDetails) {
          history.push('/issues')
        } else {
          window.location.reload()
        }
      })
      .catch(err => {
        if (err?.response?.data?.errors) {
          form.setFields(getErrors(err.response.data.errors));
        } else if (err?.response?.data?.message) {
          Toast.show({
            icon: "fail",
            content: err?.response?.data?.message,
          })
        } else {
          Toast.show({
            icon: "fail",
            content: "failed!",
          })
        }
        setIsFormLoading(false)
      })
  }

  let reasonText = null

  if (productIssueData?.issue?.type === "price_increased") {
    reasonText = "Price Increased"
  } else if (productIssueData?.issue?.type === "illegal_item") {
    reasonText = "Illegal Item"
  } else if (productIssueData?.issue?.type === "stock_out") {
    reasonText = "Stock Out"
  } else if (productIssueData?.issue?.type === "high_tax_possibility") {
    reasonText = "High Tax Possibility"
  } else {
    reasonText = "Other"
  }

  // if (isProductIssueLoading || isProductIssueDetailsLoading) return (<Loading/>)

  let makeIssueOptions = productIssueDataDetails && Object.keys(productIssueDataDetails?.action_options).map(key => {
    return {
      label: productIssueDataDetails?.action_options[key],
      value: key
    }
  })

  return (
    <>
      <Grid columns={7} gap={16} className="issue_action" style={{ marginBottom: '5%' }}>
        <Grid.Item span={4}>
          {
            !isProductIssueLoading && !isProductIssueDetailsLoading ?
              <span className="issue_action__title">ISSUE #{productId}</span>
              :
              <Skeleton.Paragraph animated lineCount={1} />
          }
        </Grid.Item>
        <Grid.Item span={3} style={{ textAlign: "right" }}>
          {
            !isProductIssueLoading && !isProductIssueDetailsLoading ?
              <Button color="primary" size="mini" onClick={() => setViewIssueFrom(true)}>Take Action</Button>
              :
              <Skeleton.Paragraph animated lineCount={1} />
          }
        </Grid.Item>
        <Grid.Item span={7}>
          {
            !isProductIssueLoading && !isProductIssueDetailsLoading ?
              <Space direction="vertical">
                <span className="issue_action__sub_title">Reason: <span className="issue_action__sub_title_value">{reasonText}</span></span>
                <span className="issue_action__sub_title">Additional message: {
                  productIssueData?.issue?.customer_note
                }</span>
              </Space>
              :
              <Skeleton.Paragraph animated lineCount={3} />
          }
        </Grid.Item>
      </Grid>
      {
        !isProductIssueLoading && !isProductIssueDetailsLoading ?
          <>
            {
              productIssueData?.issue?.meta?.length > 0 &&
              <>
                <div className="issue_title">Changed Details</div>
                {
                  productIssueData?.issue?.meta?.map((el, index) => {
                    return (
                      <Space key={`issue${index}`} block align={"center"} className="issue_price_range">
                        <Space key={`issue${index}`} direction="vertical">
                          <div>Original Price: <span
                            className="issue_price_range__title">{ShowPriceWithCurrency({price: item?.price, currency: localeCurrency})}</span>
                          </div>
                          <div>Changed Price: <span
                            className="issue_price_range__title">{ShowPriceWithCurrency({price: parseFloat(el.value) * parseFloat(productIssueData?.order_item?.fx), currency: localeCurrency})}</span>
                          </div>
                        </Space>
                      </Space>
                    )
                  })
                }
              </>
            }
          </>
          :
          <Skeleton.Paragraph animated lineCount={5} />
      }

      <Popup
        visible={viewIssueFrom}
        onMaskClick={() => {
          setViewIssueFrom(false)
        }}
        bodyStyle={{
          height: "50vh",
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
        }}
      >
        <Form
          form={form}
          onFinish={onFinishIssue}
          footer={
            <Button block type="submit" color="primary" size="middle" loading={isFormLoading}>
              Submit
            </Button>
          }
          className="submit_form"
        >
          <Form.Header>Product Action</Form.Header>
          <Form.Item name="action" label="Action">
            <Selector
              columns={2}
              options={makeIssueOptions}
              onChange={(arr) => setSelectedIssue(arr[0])}
            />
          </Form.Item>
          {
            selectedIssue === "purchase" &&
            <Grid columns={1} gap={8} className="issue_from">
              <Grid.Item>
                {
                  <span className="issue_from__title">If you selected <Tag
                    color="primary"
                    fill="outline"
                    style={{ "--border-radius": "6px" }}
                  > Request to be purchased </Tag>, We will adjust the increased amount <Tag
                    color="primary"
                    fill="outline"
                    style={{ "--border-radius": "6px" }}
                  >
                      {productIssueData?.issue?.meta?.length > 0 && ShowPriceWithCurrency({price: (parseFloat(productIssueData?.issue?.meta[0]?.value) * parseFloat(productIssueData?.order_item?.fx)) - parseFloat(item?.price), currency: localeCurrency})}
                    </Tag>
                    to this product price and you must have to pay once the invoice is updated.
                  </span>
                }
              </Grid.Item>
            </Grid>
          }
          {
            selectedIssue === "refund" &&
            <Grid columns={1} gap={8} className="issue_from">
              <Grid.Item>
                <span className="issue_from__title">We will refund your money as soon as possible. Please read our <a
                  className="link_underline" href="/pages/refund-policy">Refund policy</a></span>
              </Grid.Item>
            </Grid>
          }
          {
            selectedIssue === "cancel" &&
            <Grid columns={1} gap={8} className="issue_from">
              <Grid.Item>
                <span className="issue_from__title">Your product will be cancelled state.</span>
              </Grid.Item>
            </Grid>
          }
        </Form>
      </ Popup>
    </>
  )
}

export default ProductIssue
