import React, { useEffect, useState } from "react"
import { Button, ErrorBlock, Space } from "antd-mobile";
import { GlobalHeaderBack } from "components";
import { useHistory } from "react-router-dom";
import { ACTIVE_TAB, defaultKeys } from "consts/storage";
import qs from "qs";
import {useAuth} from "context/auth-context";

const CodFeedback = () => {
  const history = useHistory()
  const params = qs.parse((window.location.search).substring(1))
  const { mainMode: configs } = useAuth();

  useEffect(() => {
    document.body.style.background = "#ffffff"
  }, [])

  const [codSuccessMessage, setCodSuccessMessage] = useState(null);
  const [codFailedMessage, setCodFailedMessage] = useState(null);

  useEffect(() => {
    if (configs?.length) {
      let successMsg = configs?.data?.find(item => item.key === defaultKeys.codSuccessMsg);
      let failedMsg = configs?.data?.find(item => item.key === defaultKeys.codFailedMsg);
      if (successMsg?.value) {
        setCodSuccessMessage(successMsg.value)
      }
      if (failedMsg?.value) {
        setCodFailedMessage(failedMsg.value)
      }
    }
  }, [configs])

  let paymentTitle = "";
  let headerTitle = "";
  if (params.status === "success") {
    headerTitle = "Success"
    paymentTitle = <span style={{ fontWeight: "bold" }}>Successfully placed the order</span>
  } else if (params.status === "failed") {
    paymentTitle = <span style={{ fontWeight: 'bold' }}>Order Failed</span>
    headerTitle = "Failed"
  }

  const handleTabSelect = (value) => {
    window.localStorage.setItem(ACTIVE_TAB, JSON.stringify(value))
    history.push("/account/orders")
  }

  return (
    <>
      <GlobalHeaderBack title={headerTitle} height="60px" color="white" />
      {
        params.status === "success" ?
          <ErrorBlock
            fullPage
            image="/assets/social/payment_successful.svg"
            style={{
              "--image-height": "150px",
              paddingBottom: "33%"
            }}
            description={codSuccessMessage ? codSuccessMessage : "Order has been successfully placed."}
            title={paymentTitle}
          >
            <Space>
              <Button color="primary" onClick={() => history.push("/")}>Continue Shopping</Button>
              <Button color="success" onClick={() => handleTabSelect("all")}>My Orders</Button>
            </Space>
          </ErrorBlock>
          :
          <ErrorBlock
            fullPage
            image="/assets/social/payment_failed.svg"
            style={{
              "--image-height": "150px",
              paddingBottom: "33%"
            }}
            description={codFailedMessage ? codFailedMessage : "Order has been failed, Please try again"}
            title={paymentTitle}
          >
            <Space>
              <Button color="primary" onClick={() => history.push("/")}>Continue Shopping</Button>
              <Button color="success" onClick={() => handleTabSelect("all")}>My Orders</Button>
            </Space>
          </ErrorBlock>
      }
    </>
  )
}

export default CodFeedback
