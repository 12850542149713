import * as actionTypes from 'app/actionTypes/builder';

export const getBuilder = (id) => {
    return {
        type: actionTypes.GET_BUILDER_REQUESTED,
        payload: id
    }
}

export const getDefaultBuilder = () => {
  return {
    type: actionTypes.DEFAULT_BUILDER_REQUESTED
  }
}

export const setBuilderData = (payload) => {
  return {
      type: actionTypes.SET_BUILDER_DATA,
      payload,
  }
}

export const getBuilderSectionData = () => {
  return {
      type: actionTypes.GET_SECTION_DATA_REQUESTED
  }
}

export const setBuilderSectionData = (id, data, isInfinity=false) => {
  return {
      type: actionTypes.SET_BUILDER_SECTION_DATA,
      payload: {id, data, isInfinity}
  }
}
