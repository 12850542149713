import React, { useEffect, useState } from "react";
import { HomePageBlock } from "demos";
import { Grid, Space, Swiper } from "antd-mobile";
import { FlashDealProductDetails } from "components";
import useBuilder from "hooks/useBuilder";
import "../homepage.scss"
import OrderSliderSkeleton from "components/OrderSliderSkeleton";
import { useAuth } from "context/auth-context";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { compareCampaignTime } from "utils/helper";
import Countdown from "react-countdown";

const CarouselWidget = (props) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const { sections: sectionsData } = useSelector(state => state.builder);
  const { findShop } = useAuth();
  const { productsLoading, fetchProducts, products: fdProducts } = useBuilder(props);
  const [sourceParams, setSourceParams] = useState(null);
  const [productsData, setProductsData] = useState(null);
  const [isFirstCall, setIsFirstCall] = useState(true);

  useEffect(() => {
    if (props) {
      let sourceParams = props?.source;
      setSourceParams(sourceParams);
      if (sourceParams && !sectionsData?.[props?.nodeID]?.isFetched && isFirstCall) {
        fetchProducts({
          ...sourceParams,
          shop_id: findShop?.value,
        })
        setIsFirstCall(false)
      }
    }
  }, [props, isFirstCall, dispatch, fetchProducts, findShop?.value, sectionsData])

  useEffect(() => {
    if (fdProducts?.items) {
      setProductsData(fdProducts?.items)
    }
  }, [props?.source, fdProducts, productsLoading])

  let filterParams = ""
  if (sourceParams) {
    for (const [key, value] of Object.entries(sourceParams)) {
      filterParams = `${key}=${value}`
    }
  }

  let scheduledTime = null

  if (compareCampaignTime(fdProducts?.campaign?.finished_at, fdProducts?.campaign?.started_at) === "Scheduled") {
    scheduledTime = fdProducts?.campaign?.started_at
  } else if (compareCampaignTime(fdProducts?.campaign?.finished_at, fdProducts?.campaign?.started_at) === "Running") {
    scheduledTime = fdProducts?.campaign?.finished_at
  }

  let isCarouselLoaded = props?.source?.campaign ? fdProducts?.isFetched : sectionsData?.[props?.nodeID]?.isFetched;

  return (
    <>
      <HomePageBlock padding="0% 4%">
        <Grid columns={12} gap={8} className="flash_deal_grid">
          <Grid.Item span={9}>
            <div style={{ display: 'flex', gap: '0 6px' }}>
              <h3 style={{ lineHeight: '1.25rem' }}>{props?.title ?? "Flash Deals"}</h3>
              {
                (scheduledTime || fdProducts?.campaign?.finished_at) && sourceParams?.campaign &&
                <div style={{ display: 'flex', width: '50%' }}>
                  <Countdown
                    date={(scheduledTime ? scheduledTime : fdProducts?.campaign?.finished_at) * 1000}
                    renderer={props => {
                      return (
                        <Grid columns={8} gap={4} style={{ alignItems: "center" }}>
                          <Grid.Item span={2}>
                            <Space direction="horizontal" align="center" className="count_down_format_homepage">
                              <span className="count_down_format_homepage__title">{props.formatted.days} </span>
                            </Space>
                          </Grid.Item>
                          <Grid.Item span={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div><strong className="dot">:</strong></div>
                          </Grid.Item>
                          <Grid.Item span={2}>
                            <Space direction="horizontal" align="center" className="count_down_format_homepage">
                              <span className="count_down_format_homepage__title">{props.formatted.hours}</span>
                            </Space>
                          </Grid.Item>
                          <Grid.Item span={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div><strong className="dot">:</strong></div>
                          </Grid.Item>
                          <Grid.Item span={2}>
                            <Space direction="vertical" align="center" className="count_down_format_homepage">
                              <span className="count_down_format_homepage__title">{props.formatted.minutes}</span>
                            </Space>
                          </Grid.Item>
                        </Grid>
                      )
                    }}
                  />
                </div>
              }
            </div>
          </Grid.Item>
          <Grid.Item span={3} style={{ textAlign: "right", color: '#fff !important' }}>
            {
              sourceParams?.campaign ?
                <span className="flash-deal-view-all"
                  onClick={() => history.push(`/campaigns/${fdProducts?.campaign?.slug}`)}>View All</span>
                :
                <span className="flash-deal-view-all"
                  onClick={() => history.push(`/products?${filterParams}&shop_id=4`)}>View All</span>
            }
          </Grid.Item>
        </Grid>
        {
          isCarouselLoaded ?
            <Swiper
              className="flash_deal_swiper"
              allowTouchMove={true}
              indicator={() => null}
              slideSize={42}
            >
              {
                productsData && productsData?.map((product, index) => {
                  return (
                    <Swiper.Item key={`flashDeal${index}`}>
                      <FlashDealProductDetails product={product} />
                    </Swiper.Item>
                  )
                })
              }
            </Swiper>
            :
            <OrderSliderSkeleton />
        }
      </HomePageBlock>
    </>
  );
}

export default CarouselWidget;
