import * as Types from 'app/actionTypes/products';

const initialState = {
    items: {
        items: [],
        meta: null,
        filters: null,
        seller: null,
    },
    loading: true,
    error: null,
    firstLoad: false,
    isFetched: false,
}

export default function products(state = initialState, action) {

    switch (action.type) {
        case Types.GET_SEARCH_REQUESTED:
          if (action.isLoadMore) {
            return {
              ...state,
              loading: false,
              firstLoad: true,
            }
          }
          return initialState;
        case Types.GET_SEARCH_SUCCESS:
            if ((!action.isLoadMore)) {
                return {
                    ...state,
                    items: action.items,
                    firstLoad: true,
                    isFetched: true,
                    loading: false,
                }
            }
            return {
                ...state,
                items: {
                    ...state.items,
                    items: [...state.items.items, ...action.items.items],
                    paginate: action.items.paginate
                },
                isFetched: true,
                loading: false,
            }
        case Types.GET_SEARCH_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error,
                isFetched: false,
            }
        case Types.RESET_SEARCH:
            return {
                ...initialState,
                loading: state.loading
            }
        case Types.NAVIGATE_TO_NEW_SEARCH:
          return {
            ...state,
            isFetched: false,
          }
        default:
            return state
    }
}
