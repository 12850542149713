import client from './api-client'
import Qs from "qs";
import axios from "axios";

const getMyRequest = (page = null, filter = null, perPage = 10) => {
    let data = {
        page: page ? page : 1,
        per_page: perPage
    }

    if (filter) {
        data = {...data, ...filter}
    }
    return client.get('/requests/items', {
        params: data,
        paramsSerializer: function (params) {
            return Qs.stringify(params, {arrayFormat: 'brackets'})
        },
    })
}

const addMyRequest = (data) => {
    return client.post(`requests/add`, data)
}

const updateMyRequest = (data) => {
    return client.patch('requests/update', data)
}

const moveToCart = (ids) => {

    let data = {
        ids: ids,
    }

    return client.post(`requests/move-to-cart`, data)
}

const getNotification = (page = null, perPage = 15) => {
    let data = {
        page: page ? page : 1,
        per_page: perPage
    }

    return client.get('/notifications', {
        params: data,
        paramsSerializer: function (params) {
            return Qs.stringify(params, {arrayFormat: 'brackets'})
        },
    })
}

const storeNotification = (data) => {
    return client.post(`/notifications/action`, data)
}

const getRequestMetaData = (filter = null) => {
    return axios.get('https://inventory.ali2bd.com/api/v1/metadata', {
        params: filter,
        paramsSerializer: function (params) {
            return Qs.stringify(params, {arrayFormat: 'brackets', encode: false})
        },
    })
}

export {
    getMyRequest,
    addMyRequest,
    moveToCart,
    getNotification,
    storeNotification,
    updateMyRequest,
    getRequestMetaData
}
