import React, { useState } from "react";
import { Grid, Image, Popup, Space, SearchBar } from "antd-mobile";
import '../homepage.scss'
import { BellOutline, HeartOutline } from "antd-mobile-icons";
import ProductSearch from "../../ProductSearch";
import { useHistory } from "react-router-dom";
import { useLayoutContext } from "context/LayoutContext";

const SearchBarHome = () => {
  const { appLogo, notifyMessages } = useLayoutContext();
  const history = useHistory()

  const [isSearchView, setIsSearchView] = useState(false);


  return (
    <>
      <>
        <header className='top-search-bar'>
          <Space block justify="between" align="center">
            <Grid.Item>
              <Image src={appLogo?.image ?? "assets/logo/logo_white_back.png"} fit="contain" className="searchbar_title_image" />
            </Grid.Item>
            <Space style={{ '--gap-horizontal': '16px' }}>
              <span className="top-search-bar__icon"
                onClick={() => history.push("/wish-list")}><HeartOutline /></span>
              <span className="top-search-bar__icon" onClick={() => history.push("/notification")}><BellOutline /></span>
            </Space>
          </Space>
          <div className="top-search-bar__title">Get Global Products At The Best Prices</div>
        </header>
        <button
          onClick={() => setIsSearchView(true)}
          className="home_search_box"
        >
          <SearchBar placeholder={notifyMessages?.search_placeholder?.label || 'Search from million product or paste link'} />
        </button>
      </>
      <Popup
        visible={isSearchView}
        onMaskClick={() => {
          setIsSearchView(false)
        }}
        position='right'
        bodyStyle={{
          height: '180vh',
          width: '100vw'
        }}
        destroyOnClose={true}
      >
        <ProductSearch setIsSearchView={setIsSearchView} isSearchView={isSearchView} />
      </Popup>
    </>
  );
}

export default SearchBarHome;
