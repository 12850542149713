import client from './without-auth-api-client'
import Qs from "qs";

const getHomePageData = () => {
  return client.get(`system/home`)
}

const getStoreData = () => {
  return client.get(`system/shops`)
}

const getCountries = () => {
  return client.get(`/system/countries`);
}

const getCallingCode = () => {
  return client.get(`system/address/calling-codes`)
}

const getCurrencies = () => {
  return client.get(`system/currencies`)
}

const getLanguages = () => {
  return client.get(`system/languages`)
}

const getCouriers = () => {
  return client.get(`system/couriers`)
}

const getHomePageMenu = () => {
  return client.get(`system/menus`)
}

const getLabels = () => {
  return client.get(`system/labels`)
}

const getStatesByCountryCode = (code) => {
  return client.get(`system/address/states?country=${code}`)
}

const getPostCode = (filter = {}) => {
  return client.get('/system/address/find-by-code', {
    params: filter,
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
};

export const getDistrict = (filter = {}) => {
  return client.get('/system/address/states', {
    params: filter,
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
};

const imageUpload = (data) => {
  return client.post('files/upload-image', data)
}

const getAllImage = () => {
  return client.get('files/images')
}

export {
  getHomePageData,
  getStoreData,
  getCurrencies,
  getLanguages,
  getCountries,
  getHomePageMenu,
  getCouriers,
  getCallingCode,
  getLabels,
  getStatesByCountryCode,
  imageUpload,
  getAllImage,
  getPostCode
}
