import React from 'react'
import '../SellerDetails.scss'
import {Grid, Image,Space} from "antd-mobile";

const SellerFullDetails = (props) => {
    const { items } = props

    return (
        <>
            <div className="seller_full_details">
                <Image src="assets/dashboard/shop_icon.svg" width={50} height={50} fit='fill' />
                <div className="seller_full_details__info">
                    <div className="seller_full_details__title">{items?.seller?.name ? items.seller.name : items?.seller?.vendor_id}</div>
                    <div className="seller_full_details__sub_title"><span className="seller_full_details__sub_title__trusted">{items?.seller?.meta?.trusted}</span> Customer Satisfaction</div>
                </div>
            </div>

            {
                items?.seller?.meta?.ratings &&
                <Grid columns={3} gap={10} className="review_details_grid">
                    <Grid.Item>
                        <Space block align="center" justify="start">
                            <Image src="assets/dashboard/product_icon.svg" width={25} height={25} fit='fill' />
                            <span className="review_button__rating"> <span className="review_button__rating__text">{items?.seller?.meta?.ratings?.describe?.score} </span>Avg</span>
                        </Space>
                    </Grid.Item>
                    <Grid.Item>
                        <Space block align="center" justify="start">
                            <Image src="assets/dashboard/shipping_icon.svg" width={25} height={25} fit='fill' />
                            <span className="review_button__rating"> <span className="review_button__rating__text">{items?.seller?.meta?.ratings?.communication?.score} </span>Avg</span>
                        </Space>
                    </Grid.Item>
                    <Grid.Item>
                        <Space block align="center" justify="start">
                            <Image src="assets/dashboard/com_icon.svg" width={25} height={25} fit='fill' />
                            <span className="review_button__rating"> <span className="review_button__rating__text">{items?.seller?.meta?.ratings?.shipping?.score} </span>Avg</span>
                        </Space>
                    </Grid.Item>
                </Grid>
            }

            <Space className="all_products" direction="vertical">
                <span>All Products</span>
                <Image src="assets/dashboard/bar_ran.svg" width={60} height={15} fit='fill' />
            </Space>
        </>
    )
}

export default SellerFullDetails
