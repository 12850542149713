import React, {useState} from 'react'
import {Button, TabBar} from "antd-mobile";
import './RequestTabBar.scss'
import {useHistory} from "react-router-dom";

const RequestTabBar = () => {

    const history = useHistory()
    const [activeKey, setActiveKey] = useState(null)

    const checkoutButton = <div>
        <Button
            block
            size='middle'
            onClick={() => history.push("/my-request/request")}
            className="request_cart"
        > Make New Request </Button>
    </div>

    const tabs = [
        {
            key: 'checkout',
            icon: checkoutButton,
            className: "request_button"
        },
    ]

    if (activeKey === 'cart'){
        history.push('/cart')
    }

    return (
        <div className="tab-bar-request">
            <TabBar activeKey={activeKey} onChange={setActiveKey}>
                {tabs.map(item => (
                    <TabBar.Item key={item.key} icon={item.icon} title={item.title} badge={item.badge} className={item.className}/>
                ))}
            </TabBar>
        </div>
    )
}

export default RequestTabBar
