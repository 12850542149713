import React from 'react';
import './ProductSearch.scss'
import { ProductDetailsBlock } from "demos";
import SearchHistory from "./components/SearchHistory";
import SearchStore from "./components/SearchStore";
import ProductSearchBar from "./components/ProductSearchBar";
import { PageHead } from "components";
import { useLayoutContext } from 'context/LayoutContext';
import { defaultKeys } from 'consts/storage';

const ProductSearch = (props) => {
  const { visibleConfigs } = useLayoutContext();
  const { setIsSearchView, isSearchView, componentFrom } = props

  return (
    <div>
      <PageHead title={`Product Search`} />

      <ProductSearchBar
        setIsSearchView={setIsSearchView}
        isSearchView={isSearchView}
        componentFrom={componentFrom}
      />
      <ProductDetailsBlock padding="0 1.5%" marginTop="15%">
        <SearchHistory setIsSearchView={setIsSearchView} />
      </ProductDetailsBlock>
      {/*Remove it for app testing*/}
      {
        visibleConfigs?.[defaultKeys.storesVisible] &&
        <ProductDetailsBlock padding="0 1.5%">
          <SearchStore />
        </ProductDetailsBlock>
      }
    </div>
  );
}

export default ProductSearch;
