import React, { useEffect, useMemo, useRef, useState } from "react"
import '../../Myorders.scss'
import { Button, Empty, Popup, Swiper, Tabs, Toast } from "antd-mobile";
import AllOrders from "../AllOrders";
import ToPay from "../ToPay";
import ToBePurchased from "../ToBePurchased";
import ToDeliverable from "../ToDeliverable";
import ToDelivered from "../ToDelivered";
import OrderParcel from "../OrderParcel";
import { AddressModal, LoadMore, SkeletonOrder } from "components";
import { useMyOrders } from "context/my-order-context";
import { ACTIVE_TAB } from "consts/storage";
import { ACTIVE_TAB_INDEX } from "constants/constants";
import ToCancel from "pages/MyOrders/components/ToCancel";
import DeliverableNotice from "../DeliverableNotice/DeliverableNotice";
import { useLayoutContext } from "context/LayoutContext";
import { HomePageBlock } from "demos";
import { RenderPromoBanner } from "utils/helper";
import { Link } from "react-router-dom";

const MyOrderDetailsView = () => {
  const { promoBannerAll } = useLayoutContext();
  const showPromoBanner = promoBannerAll?.visible?.label?.toLowerCase()?.includes('true');
  const {
    myOrders,
    myOrdersLoadings,
    handlerSelectedTab,
    toPay,
    toPurchased,
    toDelivered,
    toDeliverable,
    loadMoreAllOrders,
    toPayLoadings,
    toPurchasedLoadings,
    toDeliveredLoadings,
    toDeliverableLoadings,
    loadMoreToPay,
    loadMoreToPurchased,
    loadMoreToDeliverable,
    loadMoreToDelivered,
    loadMoreOrderParcel,
    orderParcels,
    toOrderParcelLoadings,
    selectedProducts,
    toCancelLoadings,
    canceled,
    loadMoreCancelOrder
  } = useMyOrders()

  const activeTabIndex = JSON.parse(localStorage.getItem(ACTIVE_TAB_INDEX))


  // const [tabKey, setTabKey] = useState(activeTab ? activeTab : "all");
  const [viewAddressModal, setViewAddressModal] = useState(false);

  //My order button loading and meta data loading
  const isMyOrderButtonLoading = myOrdersLoadings.isButtonLoading
  const isMyOrderPageLoading = myOrdersLoadings.isPageLoading
  const myOrderMeta = myOrders?.meta

  //To Pay button loading and meta data loading
  const isToPayButtonLoading = toPayLoadings.isButtonLoading
  const isToPayPageLoading = toPayLoadings.isPageLoading
  const toPayMeta = toPay?.meta

  //To Purchased button loading and meta data loading
  const isToPurchasedButtonLoading = toPurchasedLoadings.isButtonLoading
  const isToPurchasedPageLoading = toPurchasedLoadings.isPageLoading
  const toPurchasedMeta = toPurchased?.meta

  //To Delivered button loading and meta data loading
  const isToDeliveredButtonLoading = toDeliveredLoadings.isButtonLoading
  const isToDeliveredPageLoading = toDeliveredLoadings.isPageLoading
  const toDeliveredMeta = toDelivered?.meta

  //To Delivered button loading and meta data loading
  const isToDeliverableButtonLoading = toDeliverableLoadings.isButtonLoading
  const isToDeliverablePageLoading = toDeliverableLoadings.isPageLoading
  const toDeliverableMeta = toDeliveredMeta?.meta

  //To Order Parcel button loading and meta data loading
  const isOrderParcelButtonLoading = toOrderParcelLoadings.isButtonLoading
  const isOrderParcelPageLoading = toOrderParcelLoadings.isPageLoading
  const toOrderParcelMeta = orderParcels?.meta

  //To Delivered button loading and meta data loading
  const isToCancelButtonLoading = toCancelLoadings.isButtonLoading
  const isToCancelPageLoading = toCancelLoadings.isPageLoading
  const toCancelMeta = canceled?.meta

  /*const deliverable = <Badge content={toDeliverable?.data?.length} offset={[-91, 2]}> <span> Deliverable </span>
  </Badge>*/

  const swiperRef = useRef(null)
  const [activeIndex, setActiveIndex] = useState(activeTabIndex ? activeTabIndex : 0)

  const tabItems = useMemo(() => {
    return [
      { key: 'all', title: 'All' },
      { key: 'to_pay', title: 'To Pay' },
      { key: 'to_be_purchased', title: 'In Transit' },
      { key: 'to_deliverable', title: "Deliverable" },
      { key: 'order_parcel', title: 'Parcel' },
      { key: 'delivered', title: 'Delivered' },
      { key: 'cancel', title: 'Cancelled' },
    ]
  }, [])

  useEffect(() => {
    handlerSelectedTab(tabItems[activeIndex].key)
    window.localStorage.setItem(ACTIVE_TAB, JSON.stringify(tabItems[activeIndex].key))
    window.localStorage.setItem(ACTIVE_TAB_INDEX, JSON.stringify(activeIndex))
  }, [activeIndex, handlerSelectedTab, tabItems]);

  const handleSelection = () => {
    if (selectedProducts?.length === 0) {
      Toast.show({
        content: "Please select some products to request",
      })
    } else {
      setViewAddressModal(true)
    }
  }

  return (
    <div style={{ marginBottom: '17%' }}>
      <DeliverableNotice />
      {
          showPromoBanner &&
          <HomePageBlock padding="5px" borderRadius="10px" marginBottom="10px" marginTop="10px">
            <RenderPromoBanner
              Link={Link}
              promoBanner={promoBannerAll?.horizontal_banner}
              borderRadius="5px"
            />
          </HomePageBlock>
        }
      <Tabs
        activeKey={tabItems[activeIndex].key}
        onChange={key => {
          const index = tabItems.findIndex(item => item.key === key)
          setActiveIndex(index)
          swiperRef.current?.swipeTo(index)
        }}
      >
        {tabItems.map(item => (
          <Tabs.Tab title={item.title} key={item.key} />
        ))}
      </Tabs>

      <Swiper
        allowTouchMove={true}
        direction='horizontal'
        loop
        indicator={() => null}
        ref={swiperRef}
        defaultIndex={activeIndex}
        onIndexChange={index => {
          setActiveIndex(index)
        }}
        className="order_tab"
        rubberband={true}
      >
        <Swiper.Item className="all_swiper">
          {
            !isMyOrderPageLoading ?
              <>
                {
                  myOrders?.data?.length <= 0 ?
                    <Empty
                      style={{ padding: '256px 0' }}
                      imageStyle={{ width: 128 }}
                      description='No data found '
                    />
                    :
                    <>
                      {
                        myOrders && myOrders?.data?.map((elements, index) => {
                          return (
                            <div key={`myOrder${index}`}>
                              <AllOrders elements={elements} index={index} />
                            </div>
                          )
                        })
                      }
                      {
                        myOrderMeta && myOrderMeta.last_page > 1 && myOrderMeta.current_page !== myOrderMeta.last_page &&
                        <LoadMore
                          handleClick={loadMoreAllOrders}
                          isLoading={isMyOrderButtonLoading}
                          loadingText="AllOrderLoading"
                        />
                      }
                    </>
                }
              </>
              :
              <SkeletonOrder />
          }
        </Swiper.Item>
        <Swiper.Item>
          {
            !isToPayPageLoading ?
              <>
                {
                  !isToPayPageLoading && toPay.data.length <= 0 ?
                    <Empty
                      style={{ padding: '256px 0' }}
                      imageStyle={{ width: 128 }}
                      description='No data found '
                    />
                    :
                    <>
                      {
                        toPay && toPay?.data?.map((elements, index) => {
                          return (
                            <div key={`toPay${index}`}>
                              <ToPay elements={elements} index={index} />
                            </div>
                          )
                        })
                      }
                      {
                        toPayMeta && toPayMeta.last_page > 1 && toPayMeta.current_page !== toPayMeta.last_page &&
                        <LoadMore
                          handleClick={loadMoreToPay}
                          isLoading={isToPayButtonLoading}
                          loadingText="ToPayLoading"
                        />
                      }
                    </>
                }
              </>
              :
              <SkeletonOrder />
          }
        </Swiper.Item>
        <Swiper.Item>
          {
            !isToPurchasedPageLoading ?
              <>
                {
                  !isToPurchasedPageLoading && toPurchased.data.length <= 0 ?
                    <Empty
                      style={{ padding: '256px 0' }}
                      imageStyle={{ width: 128 }}
                      description='No data found '
                    />
                    :
                    <>
                      {
                        toPurchased && toPurchased?.data?.map((elements, index) => {
                          return (
                            <div key={`toPurchased${index}`}>
                              <ToBePurchased elements={elements} index={index} />
                            </div>
                          )
                        })
                      }
                      {
                        toPurchasedMeta && toPurchasedMeta.last_page > 1 && toPurchasedMeta.current_page !== toPurchasedMeta.last_page &&
                        <LoadMore
                          handleClick={loadMoreToPurchased}
                          isLoading={isToPurchasedButtonLoading}
                          loadingText="ToBePurchasedLoading"
                        />
                      }
                    </>
                }
              </>
              :
              <SkeletonOrder />
          }
        </Swiper.Item>
        <Swiper.Item>
          {
            !isToDeliverablePageLoading ?
              <>
                {
                  toDeliverable.data.length <= 0 ?
                    <Empty
                      style={{ padding: '256px 0' }}
                      imageStyle={{ width: 128 }}
                      description='No data found '
                    />
                    :
                    <>
                      {
                        toDeliverable && toDeliverable?.data?.map((elements, index) => {
                          return (
                            <div key={`toDeliverable${index}`}>
                              <ToDeliverable elements={elements} index={index} isToDeliverablePageLoading={isToDeliverablePageLoading} />
                            </div>
                          )
                        })
                      }
                      <div className="submit_delivery_request">
                        <Button block color='primary' size='middle' onClick={() => handleSelection()}>
                          Submit Delivery Request
                        </Button>
                      </div>
                      {
                        toDeliverableMeta && toDeliverableMeta.last_page > 1 && toDeliverableMeta.current_page !== toDeliverableMeta.last_page &&
                        <LoadMore
                          handleClick={loadMoreToDeliverable}
                          isLoading={isToDeliverableButtonLoading}
                          loadingText="ToDeliverableLoading"
                        />
                      }
                    </>
                }
              </>
              :
              <SkeletonOrder />
          }
        </Swiper.Item>
        <Swiper.Item>
          {
            !isOrderParcelPageLoading ?
              <>
                {
                  orderParcels.data.length <= 0 ?
                    <Empty
                      style={{ padding: '256px 0' }}
                      imageStyle={{ width: 128 }}
                      description='No data found '
                    />
                    :
                    <>
                      {
                        orderParcels && orderParcels?.data?.map((elements, index) => {
                          return (
                            <div key={`myOrder${index}`}>
                              <OrderParcel elements={elements} index={index} />
                            </div>
                          )
                        })
                      }
                      {
                        toOrderParcelMeta && toOrderParcelMeta.last_page > 1 && toOrderParcelMeta.current_page !== toOrderParcelMeta.last_page &&
                        <LoadMore
                          handleClick={loadMoreOrderParcel}
                          isLoading={isOrderParcelButtonLoading}
                          loadingText="ParcelLoading"
                        />
                      }
                    </>
                }
              </>
              :
              <SkeletonOrder />
          }
        </Swiper.Item>
        <Swiper.Item>
          {
            !isToDeliveredPageLoading ?
              <>
                {
                  !isToDeliveredPageLoading && toDelivered.data.length <= 0 ?
                    <Empty
                      style={{ padding: '256px 0' }}
                      imageStyle={{ width: 128 }}
                      description='No data found '
                    />
                    :
                    <>
                      {
                        toDelivered && toDelivered?.data?.map((elements, index) => {
                          return (
                            <div key={`toDelivered${index}`}>
                              <ToDelivered elements={elements} index={index} />
                            </div>
                          )
                        })
                      }
                      {
                        toDeliveredMeta && toDeliveredMeta.last_page > 1 && toDeliveredMeta.current_page !== toDeliveredMeta.last_page &&
                        <LoadMore
                          handleClick={loadMoreToDelivered}
                          isLoading={isToDeliveredButtonLoading}
                          loadingText="ToDeliveredLoading"
                        />
                      }
                    </>
                }
              </>
              :
              <SkeletonOrder />
          }
        </Swiper.Item>
        <Swiper.Item>
          {
            !isToCancelPageLoading ?
              <>
                {
                  !isToCancelPageLoading && canceled.data.length <= 0 ?
                    <Empty
                      style={{ padding: '256px 0' }}
                      imageStyle={{ width: 128 }}
                      description='No data found '
                    />
                    :
                    <>
                      {
                        canceled && canceled?.data?.map((elements, index) => {
                          return (
                            <div key={`canceled${index}`}>
                              <ToCancel elements={elements} index={index} />
                            </div>
                          )
                        })
                      }
                      {
                        toCancelMeta && toCancelMeta.last_page > 1 && toCancelMeta.current_page !== toCancelMeta.last_page &&
                        <LoadMore
                          handleClick={loadMoreCancelOrder}
                          isLoading={isToCancelButtonLoading}
                          loadingText="ToCancelLoading"
                        />
                      }
                    </>
                }
              </>
              :
              <SkeletonOrder />
          }
        </Swiper.Item>
      </Swiper>

      {/*Don't remove this div element it is under review for future use*/}
      {/*<Tabs className="order_tab" activeKey={tabKey} onChange={setTabKey} >
                <Tabs.Tab title="All" key='all'>
                    {
                        !isMyOrderPageLoading ?
                            <>
                                {
                                    myOrders?.data?.length <= 0 ?
                                        <Empty
                                            style={{padding: '64px 0'}}
                                            imageStyle={{width: 128}}
                                            description='No data found '
                                        />
                                        :
                                        <>
                                            {
                                                myOrders && myOrders?.data?.map((elements, index) => {
                                                    return (
                                                        <div key={`myOrder${index}`}>
                                                            <AllOrders elements={elements} index={index}/>
                                                        </div>
                                                    )
                                                })
                                            }
                                            {
                                                myOrderMeta && myOrderMeta.last_page > 1 && myOrderMeta.current_page !== myOrderMeta.last_page &&
                                                <LoadMore
                                                    handleClick={loadMoreAllOrders}
                                                    isLoading={isMyOrderButtonLoading}
                                                    loadingText="AllOrderLoading"
                                                />
                                            }
                                        </>
                                }
                            </>
                            :
                            <SkeletonOrder/>
                    }
                </Tabs.Tab>
                <Tabs.Tab title="To Pay" key='to_pay'>
                    {
                        !isToPayPageLoading ?
                            <>
                                {
                                    !isToPayPageLoading && toPay.data.length <= 0 ?
                                        <Empty
                                            style={{padding: '64px 0'}}
                                            imageStyle={{width: 128}}
                                            description='No data found '
                                        />
                                        :
                                        <>
                                            {
                                                toPay && toPay?.data?.map((elements, index) => {
                                                    return (
                                                        <div key={`toPay${index}`}>
                                                            <ToPay elements={elements} index={index}/>
                                                        </div>
                                                    )
                                                })
                                            }
                                            {
                                                toPayMeta && toPayMeta.last_page > 1 && toPayMeta.current_page !== toPayMeta.last_page &&
                                                <LoadMore
                                                    handleClick={loadMoreToPay}
                                                    isLoading={isToPayButtonLoading}
                                                    loadingText="ToPayLoading"
                                                />
                                            }
                                        </>
                                }
                            </>
                            :
                            <SkeletonOrder/>
                    }
                </Tabs.Tab>
                it is to ship earlier it was used my purchased that why its all name purchased
                <Tabs.Tab title="To Ship" key='to_be_purchased'>
                    {
                        !isToPurchasedPageLoading ?
                            <>
                                {
                                    !isToPurchasedPageLoading && toPurchased.data.length <= 0 ?
                                        <Empty
                                            style={{padding: '64px 0'}}
                                            imageStyle={{width: 128}}
                                            description='No data found '
                                        />
                                        :
                                        <>
                                            {
                                                toPurchased && toPurchased?.data?.map((elements, index) => {
                                                    return (
                                                        <div key={`toPurchased${index}`}>
                                                            <ToBePurchased elements={elements} index={index}/>
                                                        </div>
                                                    )
                                                })
                                            }
                                            {
                                                toPurchasedMeta && toPurchasedMeta.last_page > 1 && toPurchasedMeta.current_page !== toPurchasedMeta.last_page &&
                                                <LoadMore
                                                    handleClick={loadMoreToPurchased}
                                                    isLoading={isToPurchasedButtonLoading}
                                                    loadingText="ToBePurchasedLoading"
                                                />
                                            }
                                        </>
                                }
                            </>
                            :
                            <SkeletonOrder/>
                    }
                </Tabs.Tab>

                <Tabs.Tab title={deliverable} key='to_deliverable'>
                    {
                        !isToDeliverablePageLoading ?
                            <>
                                {
                                    toDeliverable.data.length <= 0 ?
                                        <Empty
                                            style={{padding: '64px 0'}}
                                            imageStyle={{width: 128}}
                                            description='No data found '
                                        />
                                        :
                                        <>
                                            {
                                                toDeliverable && toDeliverable?.data?.map((elements, index) => {
                                                    return (
                                                        <div key={`toDeliverable${index}`}>
                                                            <ToDeliverable elements={elements} index={index} isToDeliverablePageLoading={isToDeliverablePageLoading}/>
                                                        </div>
                                                    )
                                                })
                                            }
                                            <div className="submit_delivery_request">
                                                <Button block color='primary' size='middle' onClick={() => handleSelection()}>
                                                    Submit Delivery Request
                                                </Button>
                                            </div>
                                            {
                                                toDeliverableMeta && toDeliverableMeta.last_page > 1 && toDeliverableMeta.current_page !== toDeliverableMeta.last_page &&
                                                <LoadMore
                                                    handleClick={loadMoreToDeliverable}
                                                    isLoading={isToDeliverableButtonLoading}
                                                    loadingText="ToDeliverableLoading"
                                                />
                                            }
                                        </>
                                }
                            </>
                            :
                            <SkeletonOrder/>
                    }
                </Tabs.Tab>
                <Tabs.Tab title="Parcel" key='order_parcel'>
                    {
                        !isOrderParcelPageLoading ?
                            <>
                                {
                                    orderParcels.data.length <= 0 ?
                                        <Empty
                                            style={{padding: '64px 0'}}
                                            imageStyle={{width: 128}}
                                            description='No data found '
                                        />
                                        :
                                        <>
                                            {
                                                orderParcels && orderParcels?.data?.map((elements, index) => {
                                                    return (
                                                        <div key={`myOrder${index}`}>
                                                            <OrderParcel elements={elements} index={index}/>
                                                        </div>
                                                    )
                                                })
                                            }
                                            {
                                                toOrderParcelMeta && toOrderParcelMeta.last_page > 1 && toOrderParcelMeta.current_page !== toOrderParcelMeta.last_page &&
                                                <LoadMore
                                                    handleClick={loadMoreOrderParcel}
                                                    isLoading={isOrderParcelButtonLoading}
                                                    loadingText="ParcelLoading"
                                                />
                                            }
                                        </>
                                }
                            </>
                            :
                            <SkeletonOrder/>
                    }
                </Tabs.Tab>
                <Tabs.Tab title="Delivered" key='delivered'>
                    {
                        !isToDeliveredPageLoading ?
                            <>
                                {
                                    !isToDeliveredPageLoading && toDelivered.data.length <= 0 ?
                                        <Empty
                                            style={{padding: '64px 0'}}
                                            imageStyle={{width: 128}}
                                            description='No data found '
                                        />
                                        :
                                        <>
                                            {
                                                toDelivered && toDelivered?.data?.map((elements, index) => {
                                                    return (
                                                        <div key={`toDelivered${index}`}>
                                                            <ToDelivered elements={elements} index={index}/>
                                                        </div>
                                                    )
                                                })
                                            }
                                            {
                                                toDeliveredMeta && toDeliveredMeta.last_page > 1 && toDeliveredMeta.current_page !== toDeliveredMeta.last_page &&
                                                <LoadMore
                                                    handleClick={loadMoreToDelivered}
                                                    isLoading={isToDeliveredButtonLoading}
                                                    loadingText="ToDeliveredLoading"
                                                />
                                            }
                                        </>
                                }
                            </>
                            :
                            <SkeletonOrder/>
                    }
                </Tabs.Tab>
            </Tabs>*/}

      {/*<div style={{marginBottom: '13%'}}>
                <JustForYou justForYou={homepageData?.value?.justForYou}/>
            </div>*/}

      <Popup
        visible={viewAddressModal}
        onMaskClick={() => {
          setViewAddressModal(false)
        }}
        bodyStyle={{
          height: '55vh',
          borderTopLeftRadius: '15px',
          borderTopRightRadius: '15px',
          overflowY: 'scroll'
        }}
      >
        <AddressModal setViewAddressModal={setViewAddressModal} />
      </Popup>
    </div>
  )
}

export default MyOrderDetailsView
