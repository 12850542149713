import { GlobalHeaderBack, PageHead } from 'components';
import { ProductDetailsBlock } from 'demos';
import Campaigns from 'pages/Campaigns/components/CampaignList';
import React from 'react';

export default function EidOffersPage() {

  return (
    <>
      <GlobalHeaderBack title={"Eid Offers"} height="50px" color="white" />
      <ProductDetailsBlock>
        <div
          className="page-wrapper space-y-8 pt-20 pb-40"
        >
          <PageHead
            title="তুফানি শপিং হবে এবার, থাকছে ৩ দেশে ধামাকা অফার।"
          />
          <section
            style={{
              width: '100%',
              paddingBottom: '40%',
              backgroundImage: 'url(https://s3.ali2bd.net/files/2023/03/20/df995b36-7137-4c7a-9ee6-40935536dd2e.png)',
              backgroundSize: "100%",
              backgroundPosition: 'center center',
              backgroundRepeat: 'no-repeat',
              marginTop: '-5%'
            }}
          >
          </section>
          <section
            className="campaign-desc flex justify-center items-center"
            style={{
              display: 'flex',
              jusifyContent: 'center',
              alignItems: 'center',
              padding: '16px 36px',
              fontSize: '1.1em',
            }}
          >
            <div>
              <p>
              বছরের <strong>প্রথম ঈদের শপিং</strong> করুন <strong>তিন দেশ</strong> জুড়ে। ঘরে বসেই <strong>ঈদের শপিং</strong> করুন <strong>চায়না, ইন্ডিয়া এবং ইউএসএ</strong> থেকে। <strong>বাছাইকৃত</strong> সকল পণ্যে পাচ্ছেন <strong>৭০% পর্যন্ত ডিসকাউন্ট</strong>। সেই সাথে <strong>Aliexpress, Taobao, Amazon, Myntra, Flipkart এবং Walmart</strong> থেকে শপিং করার সুযোগ তো থাকছেই।
              </p>
            </div>
          </section>
          <section className="campaign-list container pt-20 pb-8">
            <Campaigns
              showAllActiveCampaign={true}
              gridCols="lg:grid-cols-3"
            />
          </section>
          <section
            className="campaign-deadline bg-red-500 pt-8 pb-9 flex justify-center items-center"
            style={{
              display: 'flex',
              jusifyContent: 'center',
              alignItems: 'center',
              padding: '32px 36px',
              backgroundColor: '#EF4444'
            }}
          >
            <div
              className="w-1/2 flex flex-col items-center"
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                fontFamily: "Noto Sans Bengali, sans-serif",
                fontWeight: 700,
              }}
            >
              <div className="text-white" style={{ color: '#fff' }}>অফার চলবে</div>
              <div
                className="w-1/2 flex items-center justify-center text-center bg-white rounded-full text-red-600 font-bold py-2 text-xl"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#fff',
                  padding: '8px 24px',
                  borderRadius: '9999px',
                  fontSize: '1rem'
                }}
              >
                <div>22 থেকে 28 মার্চ পর্যন্ত</div>
              </div>
            </div>
          </section>
          <div className="mb-28" style={{ padding: '3% 5%' }}>
            <section className="container campaign-rules py-8">
              <div
                className="flex justify-center items-center"
                style={{ fontFamily: "Noto Sans Bengali, sans-serif" }}
              >
                <div className="lg:w-1/2 border border-gray-200 rounded py-5 px-12 drop-shadow">
                  <div
                    className="flex justify-center items-center text-center mb-6"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      textAlign: 'center',
                    }}
                  >
                    <h3
                      className="block text-xl font-bold bg-primary text-white rounded-full px-8 p-2"
                      style={{
                        backgroundColor:  'var(--adm-color-primary)',
                        color: '#fff',
                        borderRadius: '9999px',
                        padding: '8px 36px'
                      }}
                    >ক্যাম্পেইনে শপিং এর সার্বিক নিয়মাবলী</h3>
                  </div>
                  <ul
                    className="list-decimal leading-7"
                    style={{
                      paddingLeft: '16px',
                      listStyleType: "bengali",
                      lineHeight: '1.5rem',
                    }}
                  >
                    <li>
                      <div><strong>কিভাবে ঈদ অফারে অর্ডার করবেন?</strong></div>
                      <div>২২ মার্চ রাত ১২ ঘটিকা হতে আমাদের বাছাইকৃত পণ্যের ক্যাম্পেইন ওপেন করে দেওয়া হবে। আপনি খুব সহজেই Ali2BD ক্যাম্পেইন পেজ ভিজিট করে প্রোডাক্টগুলোর ডিটেলস দেখতে পাবেন। এবং আপনার পছন্দের প্রোডাক্টটি অর্ডার করতে পারবেন।</div>
                    </li>
                    <li>
                      <div><strong>কতদিন পর্যন্ত ঈদ অফারের প্রোডাক্ট অর্ডার করা যাবে? অর্ডার কবে পারচেজ করা হবে?</strong></div>
                      <div>ঈদ অফারের সকল প্রোডাক্ট আপনি অর্ডার করতে পারবেন ২২ মার্চ রাত ১২টা থেকে ২৮ মার্চ রাত ১১ঃ৫৯ পর্যন্ত। এই সময়ের পরবর্তীতে আর কোন অর্ডার এই অফারের অধীনে গ্রহণযোগ্য হবে না।<br/>
                      ক্যাম্পেইন চলাকালীন সময়েই আপনার প্রোডাক্ট পারচেজ প্রসেসে চলে যাবে। সুতরাং যত তাড়াতাড়ি অর্ডার করবেন আপনার ঈদের প্রোডাক্ট ততো তাড়াতাড়ি পারচেজ হবে।
                      </div>
                    </li>
                    <li>
                      <div><strong>কতো পারসেন্ট ছাড় পাচ্ছেন এইবারের ঈদ অফারে?</strong></div>
                      <div>Ali2BD এবারের চায়না, ইন্ডিয়া এবং ইউএসএর বাছাইকৃত পণ্যে ৭০% পর্যন্ত ছাড় দিচ্ছে।</div>
                    </li>
                    <li>
                      <div><strong>ডেলিভারি টাইম কতদিন?</strong></div>
                      <div>দেশের ভিত্তিতে ডেলিভারি টাইপ নির্ধারিত হয়। সাধারণত আপনার প্রোডাক্ট সেলারের দেশ থেকে বাংলাদেশে পৌছাতে নিম্নোক্ত সময় লাগতে পারেঃ</div>
                      <ul className="list-disc leading-7 pl-4">
                        <li>চায়না - ১৫ থেকে ২৫ দিন</li>
                        <li>ইন্ডিয়া - ১৫ থেকে ২৫ দিন</li>
                        <li>ইউএসএ - ২৫ থেকে ৪০ দিন</li>
                      </ul>
                      <div>অনাকাঙ্ক্ষিত কারণবশত [যথাঃ চায়না লকডাউন, শিপমেন্ট জটিলতা, কাস্টমস ক্লিয়ারেন্স] শিপমেন্ট বিলম্বিত হলে Ali2BD দায়ী নয়।</div>
                    </li>
                    <li>
                      <div><strong>MoveOn - Ship For Me শিপিং মেথড দিয়ে অর্ডার করতে পারবো কি?</strong></div>
                      <div>ঈদ অফার ক্যাম্পেইনের সকল প্রোডাক্ট “MoveOn Ship for Me” শিপিং সার্ভিসে দেশে আসবে। প্রোডাক্টের ওজন এর উপর ভিত্তি করে আনুমানিক শিপিং চার্জ প্রোডাক্ট পেজে উল্লেখিত থাকবে।</div>
                    </li>
                    <li>
                      <div><strong>রেগুলার অর্ডার কি করা সম্ভব ক্যাম্পেইনের বাছাইকৃত প্রোডাক্ট ছাড়া?</strong></div>
                      <div>ক্যাম্পেইনের বাছাইকৃত প্রোডাক্ট ছাড়াও চায়না, ইন্ডিয়া, দুবাই এবং ইউএসএর সকল সাধারণ অর্ডার যথারীতি নেওয়া হবে।</div>
                    </li>
                    <li>
                      <div><strong>Ali2BD ঈদ অফারের অন্যান্য গুরুত্বপূর্ণ বিষয়সমূহঃ</strong></div>
                      <ul className="list-disc leading-7 pl-4"
                        style={{
                          paddingLeft: '16px',
                          listStyleType: "bengali",
                        }}
                      >
                        <li>অর্ডার করার সময় যদি কোন প্রোডাক্ট স্টকআউট হয়(সাধারণত খুব কম হয় ) বা অন্য কোন ইস্যু থাকে সেগুলোর রিফান্ড করা হবে ৭ থেকে ১০ কর্মদিবসের মাঝে।</li>
                        <li>শুধু মাত্র দাম কম দেখে অর্ডার করা থেকে বিরত থাকুন। অর্ডারের পূর্বে সেলারের রেটিং এবং প্রোডাক্ট রিভিউ দেখে অর্ডার করুন।</li>
                        <li>অর্ডার প্রসেসিং এর ক্ষেত্রে First come, First serve পলিসি অনুসরণ করা হবে। অর্থাৎ যিনি আগে অর্ডার কনফার্ম করবেন তার অর্ডারটি আগে প্রসেস করা হবে।</li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </section>
            <section className="container campaign-marketplaces py-8 flex justify-center items-center">
              <div className="lg:w-1/2 text-center" style={{ fontFamily: "Noto Sans Bengali, sans-serif", textAlign: 'center', marginBottom: '20px' }}>
              এই ঈদে ঘরে বসেই শপিং করুন চায়না, ইন্ডিয়া এবং ইউএসএ থেকে। Do Shopping, Forget Borders.
              </div>
            </section>
            <section className="container campaign-authority flex justify-center items-center">
              <div className="lg:w-1/2 text-center" style={{ fontFamily: "Noto Sans Bengali, sans-serif", paddingBottom: '20%' }}>
                ** ক্যাম্পেইনের সময়সীমা, অফার এবং সুবিধা উপভোগের সকল পরিবর্তন ও পরিবর্ধনের অধিকার শুধুমাত্র Ali2BD দ্বারা সংরক্ষিত। গ্রাহক এর সুবিধার্থে ক্যাম্পেইন এর যেকোন পরিবর্তন গ্রহণযোগ্য বলে গণ্য করা হবে।
              </div>
            </section>
            <section className="container campaign-wholesale"></section>
          </div>
        </div>
      </ProductDetailsBlock>
    </>
  )
}
