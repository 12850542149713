import * as Types from "app/actionTypes/system";

export const getPostCode = (filter = {}) => {
    return {
        type: Types.GET_POST_CODE_REQUEST,
        payload: filter,
    }
}

export const getAllDistrict = (filter = {}) => {
    return {
        type: Types.GET_DISTRICT_REQUEST,
        payload: filter,
    }
}

export const getCategories = () => {
  return {
    type: Types.GET_CATEGORIES_REQUESTED,
  }
}

export const getConfigsData = () => {
  return {
    type: Types.GET_CONFIGS_REQUESTED,
  }
}

export const getCountriesData = () => {
  return {
    type: Types.GET_COUNTRIES_REQUESTED,
  }
}

export const getCurrenciesData = () => {
  return {
    type: Types.GET_CURRENCIES_REQUESTED,
  }
}

export const getLanguagesData = () => {
  return {
    type: Types.GET_LANGUAGES_REQUESTED,
  }
}

