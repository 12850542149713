import React, { useCallback, useEffect, useState } from "react"
import { Divider, Grid, Swiper } from "antd-mobile";
import "./RecommendedProduct.scss"
import { RecommendedProductList, SliderSkeleton } from "../index";
import { useHistory } from "react-router-dom";
import { useProduct } from "context/product-context";
import { getKeywordsSearch } from "services/product-client";
import { useDispatch, useSelector } from "react-redux";
import { navigateToNewSearch } from "app/actions/products";

const RecommendedProduct = ({ similarFromCart }) => {
  const dispatch = useDispatch();
  const [isSimilarProductLoading, setIsSimilarProductLoading] = useState(false);
  // const [similarProduct, setSimilarProduct] = useState([]);
  // const [isFirstCall, setIsFirstCall] = useState(true);
  const { isFetched, data: gsProductData } = useSelector(state => state.productsDetails)
  const { state: productData, similarProduct, setSimilarProduct } = useProduct()
  let history = useHistory();

  let shopID = productData?.shop_id;
  if (productData?.source?.identifer === 'aliexpress' || productData?.shop_id === 1) {
    shopID = 4
  }

  const fetchSimilarProductProducts = useCallback(
    (product) => {
      setIsSimilarProductLoading(true)
      let filters = {
        item_id: product?.id,
        shop_id: product?.shop_id,
      }
      if (product?.source?.identifer === 'aliexpress' || product?.shop_id === 1) {
        filters['shop_id'] = 4;
      }
      if (filters?.shop_id) {
        getKeywordsSearch(filters)
          .then(res => {
            setIsSimilarProductLoading(false)
            setSimilarProduct(res?.data)
          })
          .catch(err => {
            setIsSimilarProductLoading(false)
          })
      }
    },
    [setSimilarProduct],
  )

  useEffect(() => {
    if (isFetched && gsProductData?.id) {
      fetchSimilarProductProducts(gsProductData)
    }
  }, [isFetched, gsProductData, fetchSimilarProductProducts]);

  return (
    <>
      <Grid columns={11} gap={0} className="re_product_grid">
        <Grid.Item span={6}>
          <h3>Similar Product</h3>
        </Grid.Item>
        <Grid.Item span={5}
          onClick={() => {
            dispatch(navigateToNewSearch())
            history.push({
              pathname: "/similar-products",
              search: `?item_id=${productData.id}&shop_id=${shopID}`,
            })
          }}>
          <Divider contentPosition="right">
            <span className="re_product_grid__view-all">VIEW ALL</span>
          </Divider>
        </Grid.Item>
      </Grid>
      {
        !isSimilarProductLoading && similarProduct?.items?.length > 0 ?
          <div style={{ cursor: "pointer" }} onClick={() => {
            dispatch(navigateToNewSearch())
            history.push({
              pathname: "/similar-products",
              search: `?item_id=${productData.id}&shop_id=${shopID}`,
            })
          }}>
            <Swiper
              allowTouchMove={true}
              indicator={() => null}
              slideSize={
                similarFromCart === "fromCart" ? 48 : 42
              }
              className={similarProduct?.items?.length <= 3 ? "swiper_value" : "swiper_value_item"}
            >
              {
                similarProduct?.items?.map((product, index) => {
                  return (
                    <Swiper.Item key={`shipper_item${index}`}>
                      <div style={{ cursor: "pointer" }}>
                        <RecommendedProductList product={product} />
                      </div>
                    </Swiper.Item>
                  )
                })
              }
            </Swiper>
          </div>
          :
          <SliderSkeleton />
      }
    </>
  )
}

export default RecommendedProduct
