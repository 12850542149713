import { Collapse, Popup, SearchBar, Space } from 'antd-mobile';
import { GlobalHeaderBack, PageHead } from 'components';
import YoutubeEmbed from 'components/YoutubeEmbed/YoutubeEmbed';
import { defaultKeys } from 'consts/storage';
import { useLayoutContext } from 'context/LayoutContext';
import { ProductDetailsBlock } from 'demos';
import ProductSearch from 'pages/ProductSearch';
import React, { useState } from 'react';

export default function ChinaRetailPage() {
  const {
    visibleConfigs,
    chinaPageFaqs: pageFaqs,
    chinaPageVideos: pageVideos,
    chinaPageConfigs: pageConfigs
  } = useLayoutContext();
  const [isSearchView, setIsSearchView] = useState(false);

  return (
    <>
      <GlobalHeaderBack title={pageConfigs?.page_title?.label || "Start shopping from China"} height="50px" color="white" />
      <ProductDetailsBlock padding="3% 5%">
        <div className="page-wrapper space-y-8 pt-20 pb-40">
          <PageHead
            title="Wholesale"
          />
          {
            visibleConfigs?.[defaultKeys.wholesaleLabelVisible] ?
              <section className="container text-center space-y-2" style={{ textAlign: 'center' }}>
                <h2 style={{ marginBottom: 0 }}>
                  {pageConfigs?.title?.label ?? "Title"}
                </h2>
                <p className="text-base text-gray-500" style={{ marginTop: 5, fontSize: 14 }}>{pageConfigs?.tagline?.label ?? "Subtitle"}</p>
              </section>
              : <section className="container text-center" style={{ textAlign: 'center' }}>
                <h2 style={{ marginBottom: 0 }} className="text-3xl font-bold">You will find it, we will buy it.</h2>
                <p className="text-base text-gray-500" style={{ marginTop: 5, fontSize: 14 }}>Did you find your products? Enter your desired keyword and hit enter!</p>
              </section>
          }

          <section className="search-field container flex justify-center items-center">
            <div className="xl:w-1/2">
              <button
                onClick={() => setIsSearchView(true)}
                className="home_search_box"
              >
                <SearchBar placeholder='Search from million products' />
              </button>
            </div>
            {
              pageConfigs?.stores_image &&
              <div style={{ marginTop: '6%' }}>
                <img src={pageConfigs?.stores_image?.image} width="100%" alt="Dubai's stores" />
              </div>
            }
          </section>
          <section
            style={{
              width: '100%',
              paddingBottom: '25%',
              backgroundImage: 'url(/assets/wholesale.svg)',
              backgroundSize: "cover",
              backgroundPosition: '36% 50%',
              height: '120px',
              backgroundRepeat: 'no-repeat',
              margin: '10px 0 30px'
            }}
          >
          </section>
          {
            Array.isArray(pageVideos) && pageVideos?.length > 0 &&
            <section className="wholesale-how-to container"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
            >
              {/* <header className="text-center font-semibold text-xl mt-4 mb-12">
            <h3>How to order wholesale product</h3>
          </header> */}
              <Space direction="vertical" justify="center" style={{ textAlign: 'center', fontSize: 12, color: "var(--adm-color-text-secondary)" }}>
                {
                  pageVideos?.map(video => (
                    <div key={video.key}>
                      <YoutubeEmbed videoId={video.key} title={video.label} />
                    </div>
                  ))
                }
              </Space>
            </section>
          }
          {
            pageFaqs &&
            <section className="wholesale-faq container" style={{ marginTop: 30, marginBottom: 60 }}>
              <header className="text-center font-semibold text-xl mt-4 mb-12"
                style={{
                  textAlign: 'center',
                  fontWeight: 'bold',
                  fontSize: 14,
                }}
              >
                <h3>FAQs - Frequently asked questions</h3>
              </header>
              <div className="w-full grid grid-cols-2 gap-x-4">
                <Collapse accordion defaultActiveKey={['1']} className="space-y-2">
                  {pageFaqs?.map((item, idx) => (
                    <Collapse.Panel
                      key={`${idx}`}
                      title={item?.title ?? item.key}
                    >
                      {item?.content ?? item?.label}
                    </Collapse.Panel>
                  ))}
                </Collapse>
              </div>
            </section>
          }
          <Popup
            visible={isSearchView}
            onMaskClick={() => {
              setIsSearchView(false)
            }}
            position='right'
            bodyStyle={{
              height: '180vh',
              width: '100vw'
            }}
            destroyOnClose={true}
          >
            <ProductSearch
              setIsSearchView={setIsSearchView}
              isSearchView={isSearchView}
              // componentFrom="wholesale_page"
            />
          </Popup>
        </div>
      </ProductDetailsBlock>
    </>
  )
}
