import React, { useEffect, useState } from "react"
import "../../Myorders.scss"
import { GlobalHeaderBack, PageHead, ProductFullDetailsSkeleton, StatusTag } from "components";
import { DemoBlock } from "demos";
import { getOrderDetails } from "services/my-order-client";
import { useHistory, useParams } from "react-router-dom";
import { Button, CapsuleTabs, Divider, Grid, List, Space, Toast } from "antd-mobile";
import {
  calculateShippingCharges,
  calculateShippingChargesWithTag,
  changeImageOptimizeSize,
  findRequestAbleStore,
  formatDate,
  formatDateTime,
  getShippingLabel,
  getShippingRate,
  ShowPriceWithCurrency,
} from "utils/helper";
import { EnvironmentOutline, MailOutline, PhoneFill } from "antd-mobile-icons";
import ImageRenderer from "utils/ImageRenderer";
import ProductItemLogDetails from "../ProductItemLogDetails";
import LargeImageViewer from "components/LargeImageViewer";
import { CheckOutlined, CopyOutlined, InboxOutlined } from "@ant-design/icons";
import ProductIssue from "pages/MyOrders/components/ProductIssue";
import { PRODUCT_DETAILS_TAB } from "constants/constants";
import { useLayoutContext } from "context/LayoutContext";

const ProductFullDetails = () => {
  const { localeCurrency, defaultShippingUnit, shippingMethodsMap, helpDeskDomain } = useLayoutContext();
  let { orderId } = useParams();
  const history = useHistory();
  const defaultTab = localStorage.getItem(PRODUCT_DETAILS_TAB)

  const [isProductOrderLoading, setIsProductOrderLoading] = useState(false);
  const [productDetails, setProductDetails] = useState(null);
  const [isImageView, setIsImageView] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [selectedTracking, setSelectedTracking] = useState(null);

  const fetchProductOrderDetailsWithLog = React.useCallback(
    () => {
      setIsProductOrderLoading(true)
      getOrderDetails(orderId)
        .then(res => {
          setProductDetails(res.data.data)
          setIsProductOrderLoading(false)
        })
        .catch(error => {
          if (error?.response?.status === 401) {
            window.location.href = "/auth/login"
          }
          setIsProductOrderLoading(false)
        })
    },
    [orderId],
  )

  useEffect(() => {
    fetchProductOrderDetailsWithLog()
  }, [fetchProductOrderDetailsWithLog]);

  if (isProductOrderLoading) {
    return (
      <ProductFullDetailsSkeleton />
    )
  }

  const handleCopy = (value) => {
    Toast.show({
      content: "Copied!"
    })
    setIsCopied(true)
    navigator.clipboard.writeText(value)
    setSelectedTracking(value)
  }

  const packageItem = productDetails?.packages

  const shippingAddress = productDetails?.address.filter(itemAddress => itemAddress.type === "shipping" || itemAddress.type === "both")

  const totalQuantity = packageItem?.map(varItem => varItem.items.reduce((acc, value) => acc + value.quantity, 0))
    .reduce((acc, value) => acc + value, 0)

  // ?payType=${paymentOption}&gateWayId=${gateWayId}
  return (
    <>
      <PageHead title={`Product Details`} />

      <GlobalHeaderBack title="My Product" height="60px" color="white" />

      <DemoBlock padding="16px 12px" margin="2% 3%" borderRadius="8px">
        <Grid columns={14} gap={20} className="product_order_details">
          <Grid.Item span={8}>
            <Space direction="vertical">
              <div className="product_order_details__order-text">
                <span>Order {productDetails?.id}</span>
              </div>
              <span
                className="product_order_details__order-date">Date: {formatDate(productDetails?.created_at)}</span>
            </Space>
          </Grid.Item>
          {
            (productDetails?.payments?.due > 0 && productDetails?.pay_type !== "cod") &&
            <Grid.Item span={6} className="pay-invoice">
              <Button color="primary" size="small"
                onClick={() => history.push(`/payment/${orderId}?PT=${productDetails?.pay_type}&GWI=${10}`)}> Pay
                Invoice </Button>
            </Grid.Item>
          }
        </Grid>
      </DemoBlock>

      <DemoBlock padding="8px 4px" margin="2% 3%" borderRadius="8px">
        {
          packageItem && packageItem?.map((packageDataItem, index) => {
            return (
              <div key={`packageItemData${index}`}>
                <Grid columns={1} gap={8} className="package_details">
                  <Grid.Item>
                    <Space direction="vertical">
                      <div>
                        <h3><InboxOutlined /> Package {index + 1}</h3>
                      </div>
                    </Space>
                  </Grid.Item>
                </Grid>

                {
                  packageDataItem && packageDataItem?.items?.map((productItems, index) => {
                    const item = productItems

                    let itemShippingRate = parseFloat(item?.extras?.shipping?.fee)
                    if (item?.freight === "mvn_ship_for_me") {
                      let isWholesaleProduct = item.order_type === 'wholesale'
                      let approxQty = item?.quantity;
                      let approxWeight = (item?.extras?.weight?.value || 0) * approxQty;
                      itemShippingRate = getShippingRate({ selectedFrCat: item?.shipping_category, approxWeight, isWholesaleProduct });
                      itemShippingRate = parseFloat(itemShippingRate)

                      if (item.metas && item.metas.length > 0) {
                        itemShippingRate = getShippingRate({ selectedFrCat: item.metas[0].value, approxWeight, isWholesaleProduct })
                        itemShippingRate = parseFloat(itemShippingRate)
                      }
                    }

                    let shippingLabel = getShippingLabel({shippingIdentifier: item?.freight, shippingMethodsMap});

                    let pathName = "/products/details"

                    if (findRequestAbleStore(parseInt(item?.shop_id))) {
                      pathName = "/manual-requests"
                    } else if (item?.type === "internal") {
                      pathName = `/products/details`
                    }

                    let searchParams = `?url=${encodeURIComponent(item?.link)}`

                    if (item?.campaign_product?.id) {
                      searchParams = `?ipid=${item.campaign_product?.product_id}&campaign=${item.campaign_product?.campaign_id}&campaign_product=${item.campaign_product?.id}`
                    } else if (item?.product_code) {
                      let productSlug = item?.slug || item?.title;
                      pathName = `/products/${productSlug}/${item.product_code}`
                      searchParams = null;
                    }

                    return (
                      <div key={`product01Item${index}`} className="product_all_details">
                        <Grid columns={10} gap={8} className="product_details_with_order">
                          <Grid.Item span={2} onClick={() => history.push({
                            pathname: pathName,
                            search: searchParams,
                            state: {
                              title: productItems.title,
                              price: productItems?.price,
                              image: productItems?.image
                            }
                          })}>
                            <ImageRenderer
                              key={item.id}
                              url={item?.image}
                              thumb={item?.image}
                              width={60}
                              height={60}
                              alt={`ProductItemImage${item.id}`}
                              borderRadius="8px"
                            />
                          </Grid.Item>
                          <Grid.Item span={8}>
                            <Grid columns={1} gap={0}>
                              <Grid.Item span={1} className="product_title">
                                <div className="product_title__title"
                                  onClick={() => history.push({
                                    pathname: pathName,
                                    search: searchParams,
                                    state: {
                                      title: productItems.title,
                                      price: productItems?.price,
                                      image: productItems?.image
                                    }
                                  })}>
                                  <span>{item.title}</span>
                                </div>
                              </Grid.Item>
                            </Grid>
                            <Grid columns={1} gap={8} className="product_meta">
                              <Grid.Item>
                                <div
                                  className="product_meta_grid"
                                >
                                  <div>
                                    Price: <span
                                      className="product_qty_price">{ShowPriceWithCurrency({ price: item?.price, currency: localeCurrency })}</span>
                                  </div>
                                  <div>
                                    Quantity: <span
                                      className="product_qty_price">{item.quantity}</span>
                                  </div>
                                </div>
                              </Grid.Item>
                              <Grid.Item>
                                {
                                  productDetails?.pay_type === "cod" &&
                                  <div>Pay
                                    Type: {productDetails?.pay_type?.toUpperCase()}</div>
                                }
                              </Grid.Item>
                              {
                                item?.status &&
                                <Grid.Item>
                                  <div className="product_status_full">
                                    <div>Status: <StatusTag slug={item.status}
                                      text={item.status} /></div>
                                  </div>
                                </Grid.Item>
                              }
                              <Grid.Item>
                                {
                                  item?.campaign_product ?
                                    <span
                                      className="shipping_charges_order"> {getShippingLabel({ shippingIdentifier: item?.freight, shippingMethodsMap })}: {calculateShippingChargesWithTag({ shipping: item?.shipping_category, rate: itemShippingRate, currency: localeCurrency, defaultShippingUnit, shopId: item?.shop })}</span>
                                    :
                                    <>
                                      {
                                        item?.freight === "mvn_ship_for_me" ?
                                          <span className="shipping_charges_order">
                                            <>
                                            {getShippingLabel({ shippingIdentifier: item?.freight, shippingMethodsMap })}: {calculateShippingCharges({ shipping: item?.shipping_category, rate: itemShippingRate, currency: localeCurrency, defaultShippingUnit, shopId: item?.shop_id })}
                                            </>
                                          </span>
                                          :
                                          <>
                                            {
                                              item?.extras?.shipping?.identifier &&
                                              <span
                                                className="shipping_charges">SHIPPING: {item?.freight_title || shippingLabel}</span>
                                            }
                                          </>
                                      }
                                    </>
                                }
                              </Grid.Item>
                              {
                                item?.tracking_code &&
                                <Grid.Item className="product_tracking">
                                  <span style={{ cursor: "pointer" }}
                                    onClick={() => history.push(`/tracking?code=${item?.tracking_code}`)}>Tracking: {item?.tracking_code}</span>
                                  {
                                    isCopied && selectedTracking === item?.tracking_code ?
                                      <span
                                        style={{ marginLeft: "2%" }}><CheckOutlined /></span>
                                      :
                                      <span style={{ marginLeft: "2%" }}
                                        onClick={() => handleCopy(item.tracking_code)}><CopyOutlined /></span>
                                  }
                                </Grid.Item>
                              }
                            </Grid>
                          </Grid.Item>
                        </Grid>
                        <CapsuleTabs defaultActiveKey={defaultTab ? defaultTab : "details"}
                          className="collapse_log_panel">
                          <CapsuleTabs.Tab title="Details" key="details">
                            <Grid columns={1} gap={8}>
                              <Grid.Item>
                                {
                                  item?.order_type === 'wholesale' && item?.metaItems?.length
                                    ? <Space direction="vertical" style={{ fontSize: '12px', width: '100%', "--gap-vertical": '3px' }}>
                                      {
                                        item.metaItems.map(itemSku => {
                                          return (
                                            <Space
                                              key={`${itemSku.id}`}
                                              align="center"
                                              justify="between"
                                              style={{ width: '100%', borderTop: '1px solid #efefef', paddingTop: '3px' }}>
                                              <div>
                                                {
                                                  itemSku?.attrs?.map(skuMeta => {
                                                    return (
                                                      <div key={`${skuMeta.propId}:${skuMeta.valueId}`} className="text-xs">
                                                        <span>{skuMeta.key}: <strong>{skuMeta.value}</strong></span>
                                                      </div>
                                                    )
                                                  })
                                                }
                                              </div>
                                              <div className="price_qty">
                                                <span>qty: <strong>{itemSku?.quantity}</strong></span>
                                              </div>
                                              <div>
                                                {ShowPriceWithCurrency({ price: itemSku.price?.toFixed(2), currency: localeCurrency })}
                                              </div>
                                            </Space>
                                          )
                                        })
                                      }
                                    </Space>
                                    : <Space direction="vertical">
                                      {
                                        item?.attrs?.map((el, index) => {
                                          return (
                                            <span key={`productAttrMeta${index}`}
                                              className="product_attr">{el.key}: {el.value}</span>
                                          )
                                        })
                                      }
                                    </Space>
                                }
                              </Grid.Item>
                            </Grid>
                          </CapsuleTabs.Tab>
                          <CapsuleTabs.Tab title="Timeline" key="timeline">
                            <ProductItemLogDetails productId={item.id}
                              timeline={productDetails?.events} />
                          </CapsuleTabs.Tab>
                          {
                            item?.status === "not_purchased" &&
                            <CapsuleTabs.Tab title="Action" key="action">
                              <ProductIssue productId={item.id} item={item} />
                            </CapsuleTabs.Tab>
                          }
                        </CapsuleTabs>
                        <Divider />
                        <LargeImageViewer src={changeImageOptimizeSize(item?.image)}
                          visible={isImageView} setVisible={setIsImageView} />
                      </div>
                    )
                  })
                }
              </div>
            )
          })
        }
      </DemoBlock>

      <DemoBlock padding="16px 12px" margin="2% 3%" borderRadius="8px">
        <div className="order_summery">
          <span className="order_summery__title">Total Summery</span>
          <div className="order_details">
            <span>Quantity</span>
            <span className="order_details__total_value">{totalQuantity}</span>
          </div>
          <div className="order_details">
            <span>Paid</span>
            <span
              className="order_details__total_value">{ShowPriceWithCurrency({ price: productDetails?.payments?.paid, currency: localeCurrency })}</span>
          </div>
          {
            parseFloat(productDetails?.payments?.total) >= parseFloat(productDetails?.payments?.paid) ?
              <div className="order_details">
                <span>Due</span>
                <span
                  className="order_details__total_value">{ShowPriceWithCurrency({ price: productDetails?.payments?.due, currency: localeCurrency })}</span>
              </div>
              :
              <div className="order_details">
                <span>Overpaid</span>
                <span
                  className="order_details__total_value">{ShowPriceWithCurrency({ price: -1 * productDetails?.payments?.due, currency: localeCurrency })}</span>
              </div>
          }
          {
            productDetails?.charges?.length > 0 && productDetails?.charges?.map((item, index) => {
              return (
                <div key={`productCharge${index}`} className="order_details">
                  <span>{item?.type?.replaceAll("_", " ")}</span>
                  <span
                    className="order_details__total_value">{ShowPriceWithCurrency({ price: item.amount, currency: localeCurrency })}</span>
                </div>
              )
            })
          }
          {
            productDetails?.payments?.total &&
            <div className="order_details">
              <span>Sub Total</span>
              <span
                className="order_details__total_value">{ShowPriceWithCurrency({ price: productDetails?.payments?.total, currency: localeCurrency })}</span>
            </div>
          }
          {/*<div className="order_details">
                        <span>Shipping & Customs</span>
                        <span className="order_details__total_value">BDT 250.00</span>
                    </div>*/}
        </div>
        <Divider />
        {
          productDetails?.payments?.total &&
          <div className="order_total_summery">
            <span>Total Amount</span>
            <span>{ShowPriceWithCurrency({ price: productDetails?.payments?.total, currency: localeCurrency })}</span>
          </div>
        }
      </DemoBlock>

      {
        shippingAddress?.length > 0 &&
        <DemoBlock padding="16px 12px" margin="2% 3%" borderRadius="8px">
          {
            shippingAddress?.map((itemAddress, index) => {
              return (
                <div className="transactions" key={`itemAddress${index}`}>
                  <span className="transactions__title">Shipping Address</span>
                  <h3>{itemAddress.first_name} {itemAddress.last_name}</h3>
                  <Grid columns="9" gap="8" className="trans_grid">
                    <Grid.Item span={1}>
                      <span className="trans_grid__icon"><EnvironmentOutline /></span>
                    </Grid.Item>
                    <Grid.Item span={8}>
                      <span> {itemAddress.address}, {itemAddress.state}, {itemAddress.city}, {itemAddress.country}</span>
                    </Grid.Item>
                  </Grid>
                  <Grid columns="9" gap="20" className="trans_grid">
                    <Grid.Item span={1}>
                      <span className="trans_grid__icon"><MailOutline /></span>
                    </Grid.Item>
                    <Grid.Item span={8}>
                      <span> {itemAddress.email}</span>
                    </Grid.Item>
                  </Grid>
                  <Grid columns="9" gap="20" className="trans_grid">
                    <Grid.Item span={1}>
                      <span className="trans_grid__icon"><PhoneFill /></span>
                    </Grid.Item>
                    <Grid.Item span={8}>
                      <span> {itemAddress.phone}</span>
                    </Grid.Item>
                  </Grid>
                </div>
              )
            })
          }
        </DemoBlock>
      }

      {
        productDetails?.transactions?.length > 0 &&
        <DemoBlock padding="16px 12px" margin="2% 3%" borderRadius="8px">
          <span className="order_summery__title">Transaction</span>
          {
            productDetails.transactions.map((items, index) => {
              return (
                <List key={`transactions${index}`} className="tras_table">
                  <List.Item>
                    <Space block align="center" justify="between" className="tras_table__title">
                      <span>Gateway</span>
                      <span>{items.gateway.name}</span>
                    </Space>
                  </List.Item>
                  <List.Item>
                    <Space block align="center" justify="between">
                      <span>Amount</span>
                      <span
                        className="transactions-table-amount">{items.amount} + ({items.fee})</span>
                    </Space>
                  </List.Item>
                  <List.Item>
                    <Space block align="center" justify="between">
                      <span>Status</span>
                      {
                        items?.status &&
                        <span><StatusTag slug={items.status} text={items.status} /></span>
                      }
                    </Space>
                  </List.Item>
                  <List.Item>
                    <Space block align="center" justify="between">
                      <span>Date</span>
                      <span>{formatDateTime(items.created_at)}</span>
                    </Space>
                  </List.Item>
                </List>
              )
            })
          }
        </DemoBlock>
      }

      <DemoBlock padding="16px 12px" margin="1% 3% 15% 3%" borderRadius="8px">
        <div className="need_help">
          <Button block size="middle" onClick={() => {
            if (helpDeskDomain) {
              window.open(helpDeskDomain)
            }
          }}>
            Need Help?
          </Button>
        </div>
      </DemoBlock>
    </>
  )
}

export default ProductFullDetails
