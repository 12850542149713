export const config = {
    url: {
        CONSUMER_API: `${process.env.REACT_APP_API_URL}/consumer/v1`,
        PUBLIC_API: `${process.env.REACT_APP_API_URL}/public/v1`,
    },
    GOOGLE_CLIENT_ID: process.env.REACT_APP_PROD_GOOGLE_CLIENTID,
    FACEBOOK_APPID: process.env.REACT_APP_PROD_FACEBOOK_APPID
};

export const LOCAL_STORAGE_KEY = '__CUSTOMER_TOKEN__'
export const LOCAL_STORAGE_USER = '__CUSTOMER_USER__'
export const CookieUser = '__ALI2BD_USER__'
export const HOMEPAGE_STORAGE = '__HOMEPAGE_DATA__'
export const STORE_STORAGE = '__STORE__'
export const SEARCH_HISTORY = '__SEARCH_HISTORY__'
export const RECENTLY_VIEW = '__RECENTLY_VIEW__'
export const STATUS_LABELS = '__STATUS_LABELS__'
export const REVIEW_PAGE = "__REVIEW_PAGE__"
export const ACTIVE_REQUEST_TAB = "__REQUEST_TAB__"
export const CHECKOUT_ITEM = "__CHECKOUT_ITEM__"
export const THREE_TIPS = "__THREE_TIPS__"
export const PRODUCT_DETAILS_TAB = "__PRODUCT_DETAILS_TAB__"
export const ACTIVE_TAB_INDEX = '__ACTIVE_TAB_INDEX__';
export const ACTIVE_TAB_INDEX_REQUEST = '__ACTIVE_TAB_INDEX_REQUEST__';

export const localStorageKey = {
  LOCAL_LEBELS: '__LOCAL_LABELS',
  SEARCHCHED_KEYWORDS: '__SEARCHED_KEYWORDS',
  RECENTLY_VIEWED: '__RECENTLY_VIEWED',
  HOME_DATA: '__HOME_DATA',
  CATEGORIES: '__CATEGORIES',
  COUNTRIES: '__COUNTRIES',
  BUY_NOW: '__BUY_NOW',
  Ali2BDUser: 'Ali2BDUser',
  ALI2BD_USER_TOKEN: '__CUSTOMER_TOKEN__',
  CHECKEDOUT_ITEMS: 'CHECKED_OUT_ITEMS',
  SELECTED_CART_ITEMS: '__SELECTED_CART_ITEMS__',
  CART_DATA: 'CART_DATA',
  AGREED_3_TIPS: 'AGREED_3_TIPS',
  SHOW_CTA_NOTICE: '__SHOW_CTA_NOTICE__'
};
