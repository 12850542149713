export const GET_POST_CODE_REQUEST = 'GET_POST_CODE_REQUEST';
export const GET_POST_CODE_SUCCESS = 'GET_POST_CODE_SUCCESS';
export const GET_POST_CODE_FAILED = 'GET_POST_CODE_FAILED';

export const GET_DISTRICT_REQUEST = 'GET_DISTRICT_REQUEST';
export const GET_DISTRICT_SUCCESS = 'GET_DISTRICT_SUCCESS';
export const GET_DISTRICT_FAILED = 'GET_DISTRICT_FAILED';

export const GET_CATEGORIES_REQUESTED = 'GET_CATEGORIES_REQUESTED';
export const GET_CATEGORIES_SUCCEEDED = 'GET_CATEGORIES_SUCCEEDED';
export const GET_CATEGORIES_FAILED = 'GET_CATEGORIES_FAILED';

export const GET_CONFIGS_REQUESTED = 'GET_CONFIGS_REQUESTED';
export const GET_CONFIGS_SUCCEEDED = 'GET_CONFIGS_SUCCEEDED';
export const GET_CONFIGS_FAILED = 'GET_CONFIGS_FAILED';

export const GET_COUNTRIES_REQUESTED = 'GET_COUNTRIES_REQUESTED';
export const GET_COUNTRIES_SUCCEEDED = 'GET_COUNTRIES_SUCCEEDED';
export const GET_COUNTRIES_FAILED = 'GET_COUNTRIES_FAILED';

export const GET_CURRENCIES_REQUESTED = 'GET_CURRENCIES_REQUESTED';
export const GET_CURRENCIES_SUCCEEDED = 'GET_CURRENCIES_SUCCEEDED';
export const GET_CURRENCIES_FAILED = 'GET_CURRENCIES_FAILED';

export const GET_LANGUAGES_REQUESTED = 'GET_LANGUAGES_REQUESTED';
export const GET_LANGUAGES_SUCCEEDED = 'GET_LANGUAGES_SUCCEEDED';
export const GET_LANGUAGES_FAILED = 'GET_LANGUAGES_FAILED';
