import * as types from 'app/actionTypes/payments';

const initialState = {
  currentOrder: {
    invoice_type: 'Order',
    invoice_id: null,
    amount: 0,
  },
  gateways: null,
  gatewayToken: null,
  gatewayLoading: true,
}

export default function payments(state = initialState, action) { 
  switch (action.type) {
    case types.SET_ORDER:
      return {
        ...state,
        currentOrder: action.order
      }
    case types.GET_REQUESTED_GATEWAYS_ORDER: {
      return {
        ...state,
        gatewayLoading: true,
      }
    }
    case types.GET_GATEWAYS_ORDER_SUCCESS:
      return {
        ...state,
        gateways: action.gateways,
        gatewayLoading: false,
      }
    case types.GET_GATEWAYS_ORDER_FAILED:
      return {
        ...state,
        gatewayLoading: false,
      }
    case types.SET_GATEWAY_TOKEN:
      return {
        ...state,
        gatewayToken: action.payload,
      }
    default:
      return state
  }
}
