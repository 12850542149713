import {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import qs from 'qs';

const useFilters = () => {
  const [filters, setFilters] = useState(null);
  const [isFirstCall, setIsFirstCall] = useState(false);
  const [isFetched, setIsFetched] = useState(false);
  const [isLatestCollection, setIsLatestCollection] = useState(false);
  const history = useHistory();

  const initializeFilter = () => {
    const params = qs.parse((window.location.search).substring(1));
    setFilters(params)
    setIsFirstCall(true);
  }

  useEffect(() => {
    if (!isFirstCall) {
      initializeFilter();
    }
  }, [isFirstCall])

  const initializeAvailableFilterWithoutParams = (data) => {
    setFilters(data);
    setIsFetched(true);
  }

  const initializeAvailableFilter = (data) => {
    const params = qs.parse((window.location.search).substring(1));
    const initialFilter = {...params};

    const keywordSearch = params['keyword'];

    if (keywordSearch) {
      initialFilter.keyword = keywordSearch;
    }

    const shopId = params['shop_id'];

    if (shopId) {
      initialFilter.shop_id = shopId;
    }

    const itemId = params['item_id'];

    if (itemId) {
      initialFilter.item_id = itemId;
    }

    Object.keys(data).forEach((key) => {
      initialFilter[key] = params[key] ? params[key] : undefined;
    });
    setFilters(initialFilter);
    setIsFetched(true);
  }

  /**
   * Push parameters to url
   * @param {object} items Params items to be pushed to url
   */
  const updateQueryParams = (items) => {
    // filters.page = 1
    const params = qs.stringify({ ...filters, ...(items) }, { encode: false },{ encodeValuesOnly: true });
    history.push({
      search: `?${params}`,
    });
  };

  /**
   * Push parameters to url
   * @param {object} items Params items to be pushed to url
   */
  const updateQueryWithoutParams = (items) => {
    return qs.stringify({...filters, ...(items)}, {encode: false}, {encodeValuesOnly: true})
  };

  /**
   * Update filter state.
   *
   * @param {object} fields field name and values object for filter
   */
  const handleFilterChange = (fields) => {

    let newFields = fields
    if (newFields?.pr) {
      if (filters?.pr) {
        const prevPr = filters['pr'];
        const [min, max] = prevPr.split('-');
        if (newFields['pr'].tag === 'min') {
          newFields = {...newFields, pr: newFields['pr'].val + '-' + max}
        }
        if (newFields['pr'].tag === 'max') {
          newFields = {...newFields, pr:  min + '-' + newFields['pr'].val}
        }
      } else {
        if (newFields['pr'].tag === 'min') {
          newFields = {...newFields, pr: newFields['pr'].val + '-'}
        }
        if (newFields['pr'].tag === 'max') {
          newFields = {...newFields, pr:  '-' + newFields['pr'].val}
        }
      }
    }

    setFilters({
      ...filters,
      ...newFields,
    });
    updateQueryWithoutParams(newFields);

    // if (isLatestCollection){
    //   updateQueryWithoutParams(newFields);
    // }else {
    //   updateQueryParams(newFields);
    // }
  };

  /**
   * Clear filter state and filter params.
   */
  const handleFilterClear = () => {
    const params = qs.parse((window.location.search).substring(1));
    const initialFilter = {};

    const keywordSearch = params['keyword'];

    if (keywordSearch) {
      initialFilter.keyword = keywordSearch;
    }

    const shopId = params['shop_id'];

    if (shopId) {
      initialFilter.shop_id = shopId;
    }

    const paramsValue = qs.stringify({ ...initialFilter }, { encode: false },{ encodeValuesOnly: true });

    history.push({
      pathname: '/products',
      search: `?${paramsValue}`,
    })
  };

  /**
   * Clear filter state and filter params.
   */
  const handelAllFilterClear = () => {
    history.push({
      search: '',
    });
    const newFilter = {}
    Object.keys(filters).forEach((el) => {
      newFilter[el] = undefined;
    })
    setFilters(newFilter);
  };

  return {
    filters,
    isFirstCall,
    handleFilterChange,
    handleFilterClear,
    isFetched,
    setIsFetched,
    initializeFilter,
    initializeAvailableFilter,
    initializeAvailableFilterWithoutParams,
    setIsLatestCollection,
    isLatestCollection,
    handelAllFilterClear,
    updateQueryParams,
    updateQueryWithoutParams,
    setFilters,
  };
};

export default useFilters;
