import React, { useCallback, useEffect, useState } from 'react'
import { GlobalHeaderBack, RecommendedProductList, SliderSkeleton } from "components";
import { DemoBlock } from "demos";
import { Button, Checkbox, Divider, Grid, Space, Swiper, Toast } from "antd-mobile";
import './ManualRequest.scss'
import ImageRenderer from "utils/ImageRenderer";
import qs from "qs";
import { getKeywordsSearch, getUrlProductDetails } from "services/product-client";
import { ShowPriceWithCurrency } from "utils/helper";
import { Link, useHistory } from "react-router-dom";
import { useCart } from "context/cart-context";
import { addMyRequest } from "services/my-request-client";
import ManualRequestsSkeleton from "../../components/ManualRequestsSkeleton";
import { REQUEST_1ST_TEXT, REQUEST_2ND_TEXT, REQUEST_3RD_TEXT } from "consts/storage";
import { useLayoutContext } from 'context/LayoutContext';

const ManualRequests = () => {
  const { localeCurrency, preloaderImage } = useLayoutContext();
  const history = useHistory()

  const [isProductDetailsLoading, setIsProductDetailsLoading] = useState(false);
  const [productDetailsData, setProductDetailsData] = useState(null);
  const [isSimilarProductLoading, setIsSimilarProductLoading] = useState(false);
  const [similarProduct, setSimilarProduct] = useState(null);
  const [checkboxValue, setCheckboxValue] = useState(false);
  const [isRequestLoading, setIsRequestLoading] = useState(false);
  const { setViewAliexpressProduct } = useCart()

  const params = qs.parse((window.location.search).substring(1))

  let shopID = productDetailsData?.shop_id;
  if (productDetailsData?.source?.identifer === 'aliexpress' || productDetailsData?.shop_id === 1) {
    shopID = 4;
  }

  const decodeUrl = decodeURIComponent(params.url)

  const fetchProductDetails = React.useCallback(
    () => {
      setIsProductDetailsLoading(true)
      getUrlProductDetails({ url: decodeUrl })
        .then(res => {
          setProductDetailsData(res.data)
          setIsProductDetailsLoading(false)
        })
        .catch(err => {
          setIsProductDetailsLoading(false)
        })
    },
    [decodeUrl],
  )

  const fetchSimilarProductProducts = useCallback(
    () => {
      setIsSimilarProductLoading(true)
      let filters = {
        item_id: productDetailsData?.id,
        shop_id: 4
      }
      if (productDetailsData?.source?.identifer === 'aliexpress' || productDetailsData?.shop_id === 1) {
        filters['shop_id'] = 4;
      } else {
        filters['shop_id'] = productDetailsData?.shop_id;
      }
      if (filters?.shop_id) {
        getKeywordsSearch(filters)
          .then(res => {
            setIsSimilarProductLoading(false)
            setSimilarProduct(res?.data)
          })
          .catch(err => {
            setIsSimilarProductLoading(false)
          })
      }
    },
    [productDetailsData],
  )

  useEffect(() => {
    if (decodeUrl) {
      fetchProductDetails()
    }
    if (productDetailsData?.id) {
      fetchSimilarProductProducts()
    }
  }, [decodeUrl, fetchProductDetails, fetchSimilarProductProducts, productDetailsData?.id]);

  if (isProductDetailsLoading) return (
    <ManualRequestsSkeleton />
  )

  const handleViewDetails = () => {
    setViewAliexpressProduct(true)
    history.push({
      pathname: '/product-details',
      search: `?url=${encodeURIComponent(productDetailsData?.link)}`,
      state: {
        title: productDetailsData.title,
        price: productDetailsData?.discount_price?.min
          ?
          productDetailsData?.discount_price?.min
          :
          productDetailsData?.price?.min,
        image: productDetailsData?.image,
        viewAliexpressProduct: true
      }
    })
  }

  const handleSubmit = () => {
    setIsRequestLoading(true)

    let requestFormData = {}
    addMyRequest(requestFormData)
      .then(res => {
        setIsRequestLoading(false)
        Toast.show({
          content: res?.data?.message,
          position: 'bottom'
        })
      })
      .catch(err => {
        if (err?.response?.data?.message) {
          Toast.show({
            icon: 'fail',
            content: err?.response?.data?.message,
          })
        } else {
          Toast.show({
            icon: 'fail',
            content: 'failed!',
          })
        }
        setIsRequestLoading(false)
      })
  }

  return (
    <>
      <GlobalHeaderBack title="Manual Request" height="60px" color="white" />

      {
        productDetailsData &&
        <DemoBlock padding="4%" margin="2% 3% 15% 3%" borderRadius="8px">
          <Grid columns={8} gap={20} className="manual_grid">
            <Grid.Item span={3}>
              <ImageRenderer
                key={productDetailsData.id}
                url={productDetailsData?.image}
                thumb={productDetailsData?.image}
                width={130}
                height={130}
                alt={`CartImage${productDetailsData.id}`}
                borderRadius="8px"
                imageBackground={preloaderImage ?? "/assets/product/preloader-bg_62X62.png"}
              />
            </Grid.Item>
            <Grid.Item span={5}>
              <Space direction="vertical" block>
                <span className="manual_grid__title">{productDetailsData.title}</span>
                <span className="manual_grid__price">
                  {
                    productDetailsData.discount_price.max ?
                      ShowPriceWithCurrency({ price: productDetailsData.discount_price.max, currency: localeCurrency })
                      :
                      ShowPriceWithCurrency({ price: productDetailsData.price.max, currency: localeCurrency })
                  }
                </span>
              </Space>
            </Grid.Item>
            <Grid.Item span={8}>
              <Button block className="manual_grid__button">
                <span> {REQUEST_1ST_TEXT}</span>
              </Button>
            </Grid.Item>

            <Grid.Item span={8}>
              <Grid columns={11} gap={0} className="re_product_grid">
                <Grid.Item span={4}>
                  <h3>Similar Product</h3>
                </Grid.Item>
                <Grid.Item span={7}
                  onClick={() => {
                    history.push({
                      pathname: '/similar-products',
                      search: `?item_id=${productDetailsData.id}&shop_id=${shopID}`
                    })
                  }}>
                  <Divider contentPosition='right'>
                    <span className="re_product_grid__view-all">VIEW ALL</span>
                  </Divider>
                </Grid.Item>
              </Grid>
              {
                !isSimilarProductLoading ?
                  <Swiper
                    allowTouchMove={true}
                    indicator={() => null}
                    slideSize={46}
                  >
                    {
                      similarProduct?.items?.map((product, index) => {
                        return (
                          <Swiper.Item key={`shipper_item${index}`}>
                            <Link to={{
                              pathname: "/product-details",
                              search: `?url=${encodeURIComponent(product?.link)}`,
                              state: {
                                title: product.title,
                                price: product?.price?.min,
                                image: product?.image
                              }
                            }} className="key_word_link">
                              <RecommendedProductList product={product} />
                            </Link>
                          </Swiper.Item>
                        )
                      })
                    }
                  </Swiper>
                  :
                  <SliderSkeleton />
              }
            </Grid.Item>
            <Grid.Item span={8}>
              <span>{REQUEST_2ND_TEXT}</span>
            </Grid.Item>
            <Grid.Item span={8}>
              <Space>
                <Button className="request_now_button" loading={isRequestLoading} onClick={() => handleSubmit()}>Request
                  Now</Button>
                {/*<Button color="primary" block size="middle" className="view_product_button"*/}
                {/*        onClick={() => handleViewDetails()}>View Product</Button>*/}
              </Space>
            </Grid.Item>
            {/*Do not remove it will use later*/}
            <Grid.Item span={8} className="manual_grid__view">
              <Checkbox onChange={(val) => {
                setCheckboxValue(val)
              }}>{REQUEST_3RD_TEXT}</Checkbox>
            </Grid.Item>
            <Grid.Item span={8}>
              <Button disabled={!checkboxValue} color="primary" block size="middle" className="view_product_button"
                onClick={() => handleViewDetails()}>View Product</Button>
            </Grid.Item>
          </Grid>
        </DemoBlock>
      }
    </>
  )
}

export default ManualRequests
