import {all, call, put, takeEvery} from "redux-saga/effects"
import * as types from 'app/actionTypes/productDetails';
import {getDomesticFees, getInternalProductDetails, getShippingCharges, getUrlProductDetails} from "services/product-client";

const getApi = async (filter = null) => {
  return getUrlProductDetails(filter)
    .then(res => res.data)
    .catch((error) => { throw error })
}

const getShippingApi = async (data = null) => {
  return getShippingCharges(data)
    .then(res => res.data)
    .catch((error) => { throw error })
}

const getDomesticShippingApi = async (data = null) => {
  return getDomesticFees(data)
      .then(res => res.data)
      .catch((error) => { throw error })
}

function* fetchProductDetails(action) {
  try {
    const data = yield call(() => getApi(action.payload));
    yield put({ type: types.GET_DETAILS_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: types.GET_DETAILS_FAILED, message: e.message });
  }
}

function* fetchShippingCharges(action) {
  try {
    const data = yield call(() => getShippingApi(action.payload));
    yield put({ type: types.GET_SHIPPING_CHARGES_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: types.GET_SHIPPING_CHARGES_FAILED, message: e.message });
  }
}

function* fetchDomesticShipping(action) {
  try {
    const data = yield call(() => getDomesticShippingApi(action.payload));
    yield put({ type: types.GET_DOMESTIC_CHARGES_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: types.GET_DOMESTIC_CHARGES_FAILED, message: e.message });
  }
}

const getInternalProduct = async ({id, filters = {}}) => {
  return getInternalProductDetails(id, filters)
  .then(res => res.data)
  .catch((error) => { throw error })
}

function* fetchInternalProduct(action) {
  try {
      const data = yield call(() => getInternalProduct(action.payload));
      yield put({ type: types.GET_INTERNAL_PRODUCT_SUCCEEDED, payload: data });
  } catch (e) {
      yield put({ type: types.GET_INTERNAL_PRODUCT_FAILED, message: e.message });
  }
}

function* productDetailsSaga() {
  yield all([
    takeEvery(types.GET_DETAILS_REQUESTED, fetchProductDetails),
    takeEvery(types.GET_SHIPPING_CHARGES_REQUESTED, fetchShippingCharges),
    takeEvery(types.GET_DOMESTIC_CHARGES_REQUESTED, fetchDomesticShipping),
    takeEvery(types.GET_INTERNAL_PRODUCT_REQUESTED, fetchInternalProduct),
  ]);
}

export default productDetailsSaga;
