import React, {useState} from 'react'
import {Button, Dialog, TabBar} from "antd-mobile";
import './PlaceOrder.scss'
import {useCart} from "context/cart-context";
import {sleep} from "utils/sleep";
import ReactGA from "react-ga4";

const PlaceOrderTabBar = (props) => {

    const { checkoutTotal : totalItemPrice } = props

    const { handlePlaceOrder, isCheckoutOutAgreeCheck, isConfirmPlaceOrderLoading } = useCart()

    const [activeKey, setActiveKey] = useState(null)

    const checkoutButton = <div>
        <Button
            block
            size='middle'
            className="place_order"
            onClick={()=>
                Dialog.confirm({
                    content : 'Are you sure you want to place order',
                    closeOnMaskClick:true,
                    confirmText: "Confirm",
                    cancelText: "Cancel",
                    onConfirm: async () => {
                        await sleep(500)
                        handlePlaceOrder()
                        ReactGA.event({
                          category: 'purchase',
                          action: 'purchase',
                          value: totalItemPrice,
                        });
                    },
                })
            }
            loading={isConfirmPlaceOrderLoading}
            disabled={!isCheckoutOutAgreeCheck}
        > Place Order </Button>
    </div>

    const totalCheckout = <div>
        <Button
            block
            size='middle'
        > {totalItemPrice} </Button>
    </div>

    const tabs = [
        {
            key: 'addToCart',
            icon: totalCheckout,
            className: "tab_bar_button_check"
        },
        {
            key: 'checkout',
            icon: checkoutButton,
            className: "tab_bar_button"
        },
    ]

    return (
        <div className="tab-bar-checkout">
            <TabBar activeKey={activeKey} onChange={setActiveKey}>
                {tabs.map(item => (
                    <TabBar.Item key={item.key} icon={item.icon} title={item.title} badge={item.badge} className={item.className}/>
                ))}
            </TabBar>
        </div>
    )
}

export default PlaceOrderTabBar
