import React, { useState, useEffect } from "react";
import { config, LOCAL_STORAGE_KEY, LOCAL_STORAGE_USER, STATUS_LABELS, STORE_STORAGE } from "constants/constants";
import { storeProfileInfo } from "services/profile-client";
import dayjs from "dayjs";
import axios from "axios";
import { useDispatch } from "react-redux";
import { getRecentlyViewProduct, getSearchedKeywords } from "app/actions/userActivity";
import { getLabels, getStoreData } from "services/settings-client";
import { compareDateTimeFormat, getLocalData } from "utils/helper";
import { getConfigInfo } from "services/product-client";

const AuthContext = React.createContext();

const AuthProvider = (props) => {

  const dispatch = useDispatch()

  const [newUser, setNewUser] = useState(null)
  const [nextIntent, setNextIntent] = useState(null)
  const [authMeta, setAuthMeta] = useState(null);
  const [isProfileUpdating, setIsProfileUpdating] = useState(false);
  const [isDataUpdate, setIsDataUpdate] = useState(false);
  const [labels, setLabels] = useState(null);
  const [isFirstCall, setIsFirstCall] = useState(true);
  const [isLoadingAPP, setIsLoadingAPP] = useState(false);


  const profileInfo = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER))
  const labelsLocalData = getLocalData(STATUS_LABELS)
  let store = getLocalData(STORE_STORAGE)

  let rightNowTime = new Date()
  let formatDayJS = new Date(dayjs(rightNowTime).format("M/D/YYYY"))
  let storeDateTime = store?.timestamp
  let storeFormatDayJS = new Date(dayjs(storeDateTime).format("M/D/YYYY"))

  const diffTime = Math.abs(formatDayJS - storeFormatDayJS);
  const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));

  const [mainMode, setMainMode] = useState(false);
  const [apiStatus, setApiStatus] = useState(null);

  const fetchConfig = React.useCallback(
    () => {
      setIsLoadingAPP(true)
      getConfigInfo()
        .then(res => {
          setApiStatus(res.status)
          setIsLoadingAPP(false)
          setMainMode(res.data.data)
        })
        .catch(err => {
          setIsLoadingAPP(false)
        })
    },
    [],
  )

  useEffect(() => {
    fetchConfig()
  }, [fetchConfig]);

  let findMaintainMode = mainMode && mainMode?.find(item => item.key === "app_maintenance_status");
  let findMaintainNotice = mainMode && mainMode?.find(item => item.key === "app_maintenance_description");
  let findShop = mainMode && mainMode?.find(item => item.key === "default_shop");

  useEffect(() => {
    dispatch(getSearchedKeywords())
    dispatch(getRecentlyViewProduct())
  }, [dispatch]);

  const fetchShopData = React.useCallback(
    () => {
      getStoreData()
        .then(res => {
          let rightNowTimeOfSaveData = new Date()
          let shopDataObject = { value: res.data.shops, timestamp: dayjs(rightNowTimeOfSaveData).format() }
          localStorage.setItem(STORE_STORAGE, JSON.stringify(shopDataObject));
        })
        .catch(err => {
        })
    },
    [],
  )

  useEffect(() => {
    if (diffHours > 2 || !store?.value) {
      fetchShopData()
    }
  }, [diffHours, fetchShopData, store?.value]);

  const fetchLabels = React.useCallback(
    () => {
      getLabels()
        .then(res => {
          setLabels(res.data.models)
          let rightNowTimeOfSaveData = new Date()
          let labelsObject = { value: res.data.models, timestamp: dayjs(rightNowTimeOfSaveData).format() }
          localStorage.setItem(STATUS_LABELS, JSON.stringify(labelsObject));
        })
        .catch(err => {

        })
    },
    [],
  )

  let timeDifference = compareDateTimeFormat(labelsLocalData)

  useEffect(() => {
    if ((timeDifference > 12 || !labelsLocalData?.value) && isFirstCall) {
      fetchLabels()
      setIsFirstCall(false)
    }
  }, [timeDifference, fetchLabels, labelsLocalData?.value, isFirstCall]);


  const onFinishProfileUpdate = (value) => {

    let formatDate = dayjs(value?.dob).format("YYYY-MM-DD");

    const profileValue = {
      action: "basic",
      first_name: value.first_name ? value.first_name : profileInfo.first_name,
      last_name: value.last_name ? value.last_name : profileInfo.last_name,
      mobile: value?.mobile ? value?.mobile : profileInfo?.mobile,
      email: value?.email ? value?.email : profileInfo?.email,
      gender: value?.gender ? value.gender[0] : profileInfo?.gender,
      dob: value?.dob ? formatDate : profileInfo?.dob,
    }

    setIsProfileUpdating(true)
    storeProfileInfo(profileValue)
      .then(res => {
        profileInfo.action = profileValue.action
        profileInfo.first_name = profileValue.first_name
        profileInfo.last_name = profileValue.last_name
        profileInfo.mobile = profileValue.mobile
        profileInfo.email = profileValue.email
        profileInfo.gender = profileValue.gender
        profileInfo.dob = profileValue.dob
        window.localStorage.setItem(LOCAL_STORAGE_USER, JSON.stringify(profileInfo))
        setIsProfileUpdating(false)
        setIsDataUpdate(true)
        window.location.reload()
      })
      .catch(err => {
        setIsProfileUpdating(false)
      })

  }

  const getUsername = (username, localeCountry = {}) => {
    username = username.replace(/\s/g, '');
    const numberReg = /(^\d+$)/
    let countryCode = localeCountry?.calling ?? '+88';
    let countryCodeWithoutSymbol = countryCode.slice(1);
    const isPhone = username.startsWith(countryCode) || username.startsWith(countryCodeWithoutSymbol) || numberReg.test(username);
    let isValidPhone = false;

    if (isPhone && username?.slice(0, countryCode.length) === countryCode) {
      isValidPhone = true
    } else if (isPhone && username?.slice(0, countryCodeWithoutSymbol.length) === countryCodeWithoutSymbol) {
      isValidPhone = true;
      username = countryCode[0] + username;
    }

    if (isPhone && countryCode === '+880' && username.startsWith('0')) {
      countryCode = '+88'
    }
    if (isPhone && !isValidPhone && !username?.includes('@')) {
      return countryCode + username
    }
    return username
  }

  const getProfileData = (token) => {
    const date = new Date();
    date.setDate(date.getDate() + 365);
    window.localStorage.setItem(LOCAL_STORAGE_KEY, token)
    axios.get(`${config.url.CONSUMER_API}/account/me`, {
      headers: {
        "Accept": "application/json",
        "Authorization": "Bearer " + token,
      },
    })
      .then(acc => {
        const profileData = acc?.data?.profile
        window.localStorage.setItem(LOCAL_STORAGE_USER, JSON.stringify(profileData))
      })
      .catch(() => {
      })
  }

  return (
    <AuthContext.Provider
      value={{
        newUser,
        setNewUser,
        nextIntent,
        setNextIntent,
        authMeta,
        setAuthMeta,
        profileInfo,
        isProfileUpdating,
        onFinishProfileUpdate,
        isDataUpdate,
        getUsername,
        getProfileData,
        labels,
        findMaintainMode,
        findMaintainNotice,
        findShop,
        isLoadingAPP,
        apiStatus,
        mainMode
      }}
      {...props}
    />
  );
};

const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
};

export { AuthProvider, useAuth };
