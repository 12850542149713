import React from "react"
import { Space } from "antd-mobile";
import { HomePageBlock } from "demos";

const PaymentDetails = (props) => {
  const { data } = props

  return (
    <HomePageBlock padding="4%">
      <Space block justify="center" align="center" direction="vertical">
        {
          data?.name &&
          <span className="payment_internal_title"> Gateway: {data?.name}</span>
        }
        <span className="payment_internal_title"> Invoice amount: {data?.amount}</span>
        {
          data?.form[0]?.caption &&
          <span className="payment_internal">Payable amount: {data?.form[0]?.caption}</span>
        }
      </Space>
    </HomePageBlock>
  )
}

export default PaymentDetails
