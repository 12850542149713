import React from 'react'
import { Stepper } from 'antd-mobile';

const QuantityPicker = ({ onQtyChange, stock, value, selectedProductSku }) => {

  return (
    <>
      {
        stock > 0 &&
        <Stepper
          value={value > selectedProductSku?.stock
            ? selectedProductSku?.stock
            : value || 0}
          min={selectedProductSku?.min_qty
            ? selectedProductSku.min_qty
            : 0}
          max={selectedProductSku?.limit_qty
            ? selectedProductSku.limit_qty
            : stock}
          onChange={(value) => {
            onQtyChange(value);
          }}
          disabled={value >= stock}
          className="button_stepper"
          style={{
            '--height': '32px',
            '--input-width': '64px',
          }}
        />
      }
    </>
  )
}

export default QuantityPicker;
