import React, {useEffect} from "react";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import useFilters from "hooks/useFilters";
import {getIssues} from "app/actions/issue";
import {GlobalHeaderBack, LoadingState, PageHead, StatusTag} from "components";
import {DemoBlock} from "demos";
import {Button, Grid, List, Space} from "antd-mobile";
import './issueList.scss'
import {formatDateTime, formattedWithFloatAmount} from "utils/helper";

const IssueList = () => {

  let history = useHistory();
  const dispatch = useDispatch();
  const {isFetched } = useFilters();

  const {
    itemIssues,
    itemIssueLoading
  } = useSelector((state) => state.issue);

  useEffect(() => {
    if (!isFetched) {
      dispatch(getIssues());
    }
  }, [dispatch, isFetched])

  if (itemIssueLoading)return <LoadingState />;


  return (
    <>
      <PageHead title={`Take Actions`}/>

      <GlobalHeaderBack title="Take Actions" height="50px" color="white"/>

      <DemoBlock padding="1%" margin="2% 2% 15% 2%" borderRadius="8px">
        <List>
        {
          itemIssues?.data?.map((el, index) => {

            let findKey = el?.meta?.find((item) => item.key)

            let priceText = null

            if (findKey){
              if (findKey?.key === "price"){
                priceText = `Increased Price by`
              }
            }

            return (
                <List.Item key={index}>
                  <Grid columns={1} gap={2}>
                    <Grid.Item>
                      <Space direction="vertical" className="issue_list">
                        <Space block justify="between">
                          <div className="issue_list__title">Type: <span className="issue_list__sub_title">{el?.type?.replaceAll("_", " ")?.toUpperCase()}</span></div>
                          <div><Button size="mini" color="primary" onClick={() => history.push(`/issues/${el?.issuable_id}`)}>Action Needed</Button></div>
                        </Space>
                        <div className="issue_list__title">Status: <StatusTag text={el?.status} slug={el?.status} notRounded={true}/></div>
                        <div className="issue_list__title">Price Status: {priceText && <span className="issue_list__sub_title">{priceText} <span className="issue_list__sub_title__price">{formattedWithFloatAmount(findKey.value)}</span></span>}</div>
                        <div className="issue_list__title">Note: <span className="issue_list__sub_title">{el?.customer_note}</span></div>
                        <div className="issue_list__title">Created At: <span className="issue_list__sub_title">{formatDateTime(el?.created_at)}</span></div>
                      </Space>
                    </Grid.Item>
                  </Grid>
                </List.Item>
            )
          })
        }
        </List>
      </DemoBlock>

    </>
  )
}

export default IssueList;
