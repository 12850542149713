import React, { useState, useEffect, useRef } from "react"
import {
  KeywordSearchProductDetails,
  KeywordSearchProductDetailsList,
  KeywordSearchSkeleton,
  NotFound,
  PageHead
} from "components";
import ProductSearchBar from "../ProductSearchBar";
import { ProductDetailsBlock } from "demos";
import { Dropdown, Grid, Image, InfiniteScroll, Loading, Popup, Radio, Selector, Space } from "antd-mobile";
import './SearchResults.scss'
import { AppstoreOutlined, FilterOutlined } from "@ant-design/icons";
import SearchFilter from "../SearchFilters";
import { useDispatch, useSelector } from "react-redux";
import useFilters from "hooks/useFilters";
import { getProductsSearch, navigateToNewSearch } from "app/actions/products";
import qs from "qs";
import { UnorderedListOutline } from "antd-mobile-icons";
import Skeleton from 'react-loading-skeleton';
import { useLocation } from "react-router-dom";
import { useLayoutContext } from "context/LayoutContext";
import { defaultKeys } from "consts/storage";
// import {checkWishListProductExistsByShop} from "../../../../services/cart-client";


const InfiniteScrollContent = ({ hasMore }) => {
  return (
    <>
      {
        hasMore ? (
          <span>End of products</span>
        )
          : (
            <div className="loading_middle">
              <Loading color='primary' />
            </div>
          )
      }
    </>
  )
}

const SearchResults = () => {
  const { defaultWholesaleShop, defaultShop, wholeslaeShops, visibleConfigs } = useLayoutContext();
  const dispatch = useDispatch();
  const sortRef = useRef();
  const [isSearchView, setIsSearchView] = useState(false);
  const { items, loading, isFetched: isItemsFetched } = useSelector((state) => state.products);
  const rrdLocation = useLocation();
  const [viewFilterList, setViewFilterList] = useState(false);
  const [hasMore, setHasMore] = useState(false)
  const [, setScroll] = useState(false)
  const [selectedTab, setSelectedTab] = useState('grid');
  const [isParamsUpdated, setIsParamsUpdated] = useState(false);
  const [wsFilterValue, setWsFilterValue] = useState(null);
  const [isFirstWsFilter, setIsFirstWsFilter] = useState(false);
  const [searchedQueries, setSearchedQueries] = useState('');

  const {
    filters,
    handleFilterChange,
    handleFilterClear,
    isFetched,
    initializeAvailableFilter,
    setFilters,
    updateQueryParams,
  } = useFilters();

  useEffect(() => {
    if (!isFetched && items.filters?.configurator) {
      initializeAvailableFilter(items.filters?.configurator);
    }
  }, [isFetched, initializeAvailableFilter, items.filters?.configurator]);

  useEffect(() => {
    const params = qs.parse((rrdLocation.search).substring(1));
    setFilters(params)
    setIsParamsUpdated(true);
  }, [rrdLocation.search, setFilters])

  useEffect(() => {
    if (!isItemsFetched && filters && isParamsUpdated) {
      let queryStirng = qs.stringify(filters);
      if (searchedQueries !== queryStirng) {
        dispatch(getProductsSearch(filters));
        setSearchedQueries(queryStirng)
      }
    }
  }, [isItemsFetched, filters, isParamsUpdated, searchedQueries, dispatch]);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 4000);
    });
  }, []);

  const params = qs.parse((window.location.search).substring(1))

  useEffect(() => {
    if (params && wholeslaeShops?.value && !isFirstWsFilter) {
      if (wholeslaeShops.value?.split(',')?.includes(params?.shop_id)) {
        setIsFirstWsFilter(true)
        setWsFilterValue('wholesale')
      }
    }
  }, [params, wholeslaeShops, isFirstWsFilter])

  const handleProductFilter = () => {
    const params = qs.stringify({ ...filters }, { encode: false }, { encodeValuesOnly: true });
    window.history.replaceState(null, 'Searching', `/products?${params}`)
    window.scroll(0, 0)
    setViewFilterList(false)
    dispatch(getProductsSearch(filters));
  };

  let totalPage = parseInt(items?.paginate?.last_page - 1)

  useEffect(() => {
    if (items?.paginate?.current_page < totalPage) {
      setHasMore(true)
    }
  }, [items?.paginate?.current_page, totalPage]);

  const loadMoreProducts = () => {
    if (items.paginate) {
      dispatch(getProductsSearch({ ...filters, ...{ page: items.paginate.current_page + 1 } }, true));
      setHasMore(false)
    }
  }

  const handlePriceValue = (value) => {
    let key = "sortType"
    let orderValue = "sortOrder"
    let keyValue = "default"
    let priceValue = "price"

    if (value === "default") {
      updateQueryParams({ [key]: undefined, [orderValue]: undefined })
    } else if (value === "orders" || value === "newest") {
      keyValue = "desc"
      updateQueryParams({ [key]: value + "", [orderValue]: keyValue + "" })
    }  else if (value === "price_low") {
      keyValue = "desc"
      updateQueryParams({ [key]: priceValue + "", [orderValue]: keyValue + "" })
    } else if (value === "price_high") {
      keyValue = "asc"
      updateQueryParams({ [key]: priceValue + "", [orderValue]: keyValue + "" })
    }
    dispatch(navigateToNewSearch());
    setIsParamsUpdated(true);
    sortRef.current?.close()
  }

  const onFilterClear = () => {
    dispatch(navigateToNewSearch());
    window.scroll(0, 0)
    handleFilterClear();
  }

  if (!loading) {
    if (items?.items?.length === 0) {
      return (
        <NotFound title="Product Search Not Found" status="empty"
          description="What you search may be not found, Please search with another keyword" />
      )
    }
  }

  const handleWholesaleFilter = (value) => {
    let queryParams;
    let paramsString;
    if (value && defaultWholesaleShop) {
      paramsString = qs.stringify({ ...params, shop_id: defaultWholesaleShop }, { encode: false }, { encodeValuesOnly: true });
      queryParams = { ...filters, shop_id: defaultWholesaleShop }
      window.history.replaceState(null, 'Searching', `/products?${paramsString}`)
      setWsFilterValue('wholesale')
    } else {
      setWsFilterValue(null)
      const { shop_id, ...restParams } = params;
      queryParams = {...restParams}
      if (defaultShop) {
        queryParams['shop_id'] = defaultShop;
      }
      paramsString = qs.stringify({ ...queryParams }, { encode: false }, { encodeValuesOnly: true });
      queryParams = { ...queryParams }
      window.history.replaceState(null, 'Searching', `/products?${paramsString}`)
    }

    dispatch(navigateToNewSearch());
    setFilters(queryParams);
    setIsParamsUpdated(true);
  }

  return (
    <>
      <PageHead title={params?.keyword ? `${params?.keyword}` : "Product Image Search"} />
      <ProductSearchBar setIsSearchView={setIsSearchView} isSearchView={isSearchView} />
      <ProductDetailsBlock padding="0">
        <div className="product_filter_value">
          <Grid columns={12} gap={22} className="search_filter">
            <Grid.Item span={4}>
              <Dropdown ref={sortRef}>
                <Dropdown.Item key='popularity' title='Popularity'>
                  <div style={{ padding: 12 }} className="search_dropdown">
                    <Radio.Group
                      onChange={(value) => handlePriceValue(value)}
                      defaultValue={params?.sortType}
                    >
                      <Space direction='vertical' block className="ho_gap">
                        <Radio block className="radio_search" value="default">
                          Best match
                        </Radio>
                        <Radio block className="radio_search" value="orders">
                          Orders
                        </Radio>
                        <Radio block className="radio_search" value="newest">
                          Date Added (New to Old)
                        </Radio>
                        <Radio block className="radio_search" value="price_low">
                          Price (High to Low)
                        </Radio>
                        <Radio block className="radio_search" value="price_high">
                          Price (Low to High)
                        </Radio>
                      </Space>
                    </Radio.Group>
                  </div>
                </Dropdown.Item>
              </Dropdown>
            </Grid.Item>
            <Grid.Item span={3}>
              {
                visibleConfigs?.[defaultKeys.wholesaleLabelVisible] &&
                <Selector
                  columns={1}
                  options={[{ label: 'Wholesale', value: 'wholesale' }]}
                  value={[wsFilterValue]}
                  onChange={(arr) => {
                    handleWholesaleFilter(arr[0])
                  }}
                  style={{ '--padding': '3px 6px', fontSize: '13px' }}
                />
              }
            </Grid.Item>
            <Grid.Item span={5}>
              <Space block justify="center" align="center" className="search_filter_slider">
                {
                  selectedTab === 'grid' ?
                    <span onClick={() => setSelectedTab('list')}
                      className="search_filter_slider__icons"><UnorderedListOutline /> <span
                        className="search_filter_slider__border_left">|</span> </span>
                    :
                    <span onClick={() => setSelectedTab('grid')}
                      className="search_filter_slider__icons"><AppstoreOutlined /> <span
                        className="search_filter_slider__border_left">|</span></span>
                }
                <span
                  className={"search_filter_slider__filter"}
                  onClick={() => {
                    setViewFilterList(true)
                  }}><FilterOutlined />  Filter</span>
              </Space>
            </Grid.Item>
          </Grid>
        </div>
      </ProductDetailsBlock>
      {
        params?.file &&
        <ProductDetailsBlock padding="1% 2% 1% 2%" marginTop="27%" marginBottom="2%" background="#2F4858">
          {
            <Grid columns="10" gap="8" style={{ alignItems: 'center', padding: '3% 0' }}>
              <Grid.Item span={3}>
                {
                  !loading ?
                    <div className="image_search_results">
                      <Image src={items?.image?.url} width={100} height={100} fit='contain' style={{display: 'flex', alignItems: 'center'}} />
                    </div>
                    :
                    <Skeleton height={100} />
                }
              </Grid.Item>
              <Grid.Item span={7}>
                <div className="image_search_results_title">
                  <span
                    className="image_search_results_title__value">Showing Results Of This Image</span>
                </div>
              </Grid.Item>
            </Grid>
          }
        </ProductDetailsBlock>
      }
      {
        params?.keyword &&
        <ProductDetailsBlock padding="3% 1% 3% 0" marginTop="26%">
          <div className="more_filter">
            <span>Showing {decodeURI(params?.keyword)}</span>
          </div>
        </ProductDetailsBlock>
      }
      {
        selectedTab === 'grid' &&
        <ProductDetailsBlock padding="0" marginBottom="12%" background="none">
          <div className="search_results">
            <>
              {
                !loading && items.items?.length >= 0 ?
                  <Grid columns={2} gap={8} className="masonry_grid">
                    {
                      items.items.map((elements, index) => {
                        return (
                          <Grid.Item
                            key={`product_search${index}`}
                            className="search_results_grid"
                          >
                            <KeywordSearchProductDetails product={elements}
                              isProductLoading={loading} />
                          </Grid.Item>
                        )
                      })
                    }
                  </Grid>
                  :
                  <KeywordSearchSkeleton />
              }
            </>
            {
              items && items?.paginate?.last_page > 1 && items?.paginate?.current_page !== items?.paginate?.last_page &&
              <InfiniteScroll hasMore={hasMore} loadMore={loadMoreProducts}>
                <InfiniteScrollContent hasMore={hasMore} />
              </InfiniteScroll>
            }
          </div>
        </ProductDetailsBlock>
      }
      {
        selectedTab === 'list' &&
        <ProductDetailsBlock padding="0">
          <div className="search_results">
            <>
              {
                !loading && items.items?.length >= 0 ?
                  <Grid columns={1} gap={10} className="search_results_grid">
                    {
                      items.items.map((elements, index) => {
                        return (
                          <Grid.Item
                            key={`product_search${index}`}
                          >
                            <KeywordSearchProductDetailsList product={elements}
                              isProductLoading={loading} />
                          </Grid.Item>
                        )
                      })
                    }
                  </Grid>
                  :
                  <KeywordSearchSkeleton />
              }
            </>
            {
              items && items?.paginate?.last_page > 1 && items?.paginate?.current_page !== items?.paginate?.last_page &&
              <InfiniteScroll hasMore={hasMore} loadMore={loadMoreProducts}>
                < InfiniteScrollContent hasMore={hasMore} />
              </InfiniteScroll>
            }
          </div>
        </ProductDetailsBlock>
      }

      <Popup
        visible={viewFilterList}
        onMaskClick={() => {
          setViewFilterList(false)
        }}
        position='right'
        bodyStyle={{ minWidth: '75vw', width: '50px' }}
      >
        <SearchFilter
          setViewFilterList={setViewFilterList}
          handleProductFilter={handleProductFilter}
          handleFilterChange={handleFilterChange}
          handleFilterClear={onFilterClear}
          isFetched={isFetched}
          filters={filters}
          filtersData={items.filters}
        />
      </Popup>
    </>
  );
}
export default SearchResults
