import React from 'react'
import styles from './index.less'

export const DemoBlock = (props) => {
    return (
        <div className={styles.demoBlock}>
            <div
                className={styles.content}
                style={{
                    padding: props.padding,
                    background: props.background,
                    border: props.border,
                    borderRadius: props.borderRadius,
                    margin: props.margin,
                    borderLeft: props.borderLeft,
                    boxShadow: props.boxShadow
                }}
            >
                {props.children}
            </div>
        </div>
    )
}

DemoBlock.defaultProps = {
    padding: '2px 2px',
    background: '#ffffff',
}
