import * as activityTypes from 'app/actionTypes/userActivity'

export const setSearchedKeywords = (keyword) => {
    return {
        type: activityTypes.SET_SEARCHED_KEYWORDS,
        payload: keyword,
    }
}

export const getSearchedKeywords = () => {
    return {
        type: activityTypes.GET_SEARCHED_KEYWORDS
    }
}

export const setRecentlyViewProduct = (keyword) => {
    return {
        type: activityTypes.SET_RECENTLY_VIEWED,
        payload: keyword,
    }
}

export const getRecentlyViewProduct = () => {
    return {
        type: activityTypes.GET_RECENTLY_VIEWED_PRODUCT
    }
}

export const setProductDetailsShadow = (data) => {
  return {
    type: activityTypes.SET_PRODUCT_DETAILS,
    payload: data,
  }
}

export const getProductDetailsShadow = (preData, data, productData) => {
  return {
    type: activityTypes.GET_PRODUCT_DETAILS,
    payload: {preData, data, productData},
  }
}

export const setProductIDSShadow = (ids) => {
    return {
        type: activityTypes.SET_PRODUCT_IDS,
        payload: ids,
    }
}

export const getProductExternalData = (externalData) => {
    return {
        type: activityTypes.GET_PRODUCT_EXTERNAL_DATA,
        payload: {externalData},
    }
}
