import React from 'react';
import {Button, Divider, ErrorBlock, Grid, Space} from "antd-mobile";
import './SearchHistory.scss'
import {getLocalData} from "utils/helper";
import {SEARCH_HISTORY} from "constants/constants";
import {useAuth} from "context/auth-context";
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { navigateToNewSearch } from 'app/actions/products';

const SearchHistory = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { setIsSearchView } = props
    const { findShop } = useAuth();

    const handleSearch = (value) => {
      dispatch(navigateToNewSearch())
      history.push({
        pathname: `/products`,
        search: `?keyword=${value}&shop_id=${findShop?.value}`
      })
    }

    const searchResults = getLocalData(SEARCH_HISTORY)

    const handleRemoveSearchHistory = () => {
        localStorage.removeItem(SEARCH_HISTORY)
        setIsSearchView(false)
    }

    return (
        <div className="search_history">
            <Grid columns={14} gap={20} className="search_take_down">
                <Grid.Item span={12}>
                    <span className="search_take_down__search_text title-text">Search History</span>
                </Grid.Item>
                <Grid.Item span={2}>
                    <span className="search_take_down__search_clear title-text">
                        <span onClick={() => handleRemoveSearchHistory()}>Clear</span>
                    </span>
                </Grid.Item>
            </Grid>
            <div className="search_makeup">
                {
                    searchResults ?
                        <Space wrap>
                            {
                                searchResults?.map((item, index) => {
                                    return (
                                        <Button size="mini" onClick={() => handleSearch(item)} key={`searchHistory${index}`}>{item}</Button>
                                    )
                                })
                            }
                        </Space>
                        :
                        <ErrorBlock status='empty' title="Search History" description="You do not search anything yet! Please search something"/>
                }
            </div>
            <Divider/>
        </div>
    );
}

export default SearchHistory;
