import * as type from "app/actionTypes/address";
import produce from "immer";

const initialState = {
    address: {
        data: null,
        meta: null,
        defaultShipping: null,
        defaultBilling: null
    },
    loading: true,
    error: null,
    courier: null,
    isCourier: false,
}

export default function address(state = initialState, action) {
    return produce(state, draft => {
        switch (action.type) {
            case type.GET_ADDRESS_REQUESTED:
                draft.loading = true
                break
            case type.GET_ADDRESS_SUCCESS:
                let addressData = action.items.data
                let totalAddressData = addressData
                if (action.items.default_shipping && action.items.default_billing) {
                    if (action.items.default_shipping.id === action.items.default_billing.id) {
                        addressData.push({
                            ...action.items.default_shipping,
                            default_shipping: true,
                            default_billing: true
                        })
                    }else {
                        if (action.items?.default_shipping) {
                            addressData.push({
                                ...action.items.default_shipping,
                                default_shipping: true
                            })
                        }
                        if (action.items?.default_billing) {
                            addressData.push({
                                ...action.items.default_billing,
                                default_billing: true
                            })
                        }
                    }
                } else {
                    if (action.items?.default_shipping) {
                        addressData.push({
                            ...action.items.default_shipping,
                            default_shipping: true
                        })
                    }
                    if (action.items?.default_billing) {
                        addressData.push({
                            ...action.items.default_billing,
                            default_billing: true
                        })
                    }
                }
                draft.address.data = totalAddressData
                draft.address.defaultShipping = action.items.default_shipping
                draft.address.defaultBilling = action.items.default_billing
                draft.address.meta = action.items.meta
                draft.loading = false
                break
            case type.GET_ADDRESS_FAILED:
                draft.loading = false
                draft.error = action.items
                break
            case type.GET_COURIER_REQUESTED:
                draft.isCourier = true
                break
            case type.GET_COURIER_SUCCESS:
                draft.courier = action.items.couriers
                draft.isCourier = false
                break
            case type.GET_COURIER_FAILED:
                draft.isCourier = false
                draft.error = action.items
                break
            case type.UPDATE_ADDRESS:
                const index = draft.address.data.findIndex(itm => itm.id === action.payload.id)
                if (index !== -1) draft.address.data[index] = {...draft.address.data[index], ...action.payload.data}
                if (draft.address.defaultShipping) {
                    draft.address.defaultShipping = {...action.payload.data}
                } else if (draft.address.defaultBilling) {
                    draft.address.defaultBilling = {...action.payload.data}
                }
                break
            default:
                return state
        }
    })
}
