/**
 * Dynamic script loading hook.
 */
import React from 'react';

type propsType = {
  scriptUrl: string,
  scriptId: string,
  customSript: string,
  scriptConfig: Object,
  nonce: string,
  callback?: () => void
}

const useScript = ({scriptId, scriptUrl, customSript, scriptConfig, nonce, callback}:propsType) => {
  React.useEffect(() => {
    let existingScript:any=null;

    if (document) {
      existingScript = document.getElementById(scriptId);

      if (!existingScript && scriptConfig) {
        const script = document.createElement('script');

        if (scriptId) {
          script.id = scriptId;
        }

        if (nonce) {
          script.nonce = nonce;
        }
        if (scriptUrl) {
          script.src = scriptUrl;
        }
        if (customSript) {
          script.type = "text/javascript";
          script.innerHTML = customSript;
        }

        document.body.appendChild(script);

        script.onload = () => {
          if (callback) {
            callback();
          }
        };
      }

      if (existingScript && callback) {
        callback();
      }
    }

    return () => {
      if (existingScript && callback) {
        existingScript.remove();
      }
    };
  }, [scriptUrl, scriptId, customSript, scriptConfig, nonce, callback]);
};

export default useScript;
