import React, { useEffect, useState } from 'react'
import { HomePageBlock } from "demos";
import { LoadingState, NotFound, PageHead, SellerSearchHeader } from "components";
import SellerFullDetails from "./components/SellerFullDetails";
import { useDispatch, useSelector } from "react-redux";
import useFilters from "hooks/useFilters";
import { getProductsSearch } from "app/actions/products";
import SellerProductDetails from "./components/SellerProductDetails";

const SellerDetails = () => {

  const dispatch = useDispatch();
  const { items, loading, isFetched: isItemsFetched } = useSelector((state) => state.products);
  const [isFetched, setIsFetched] = useState(false);
  const {
    filters,
    initializeAvailableFilter
  } = useFilters();

  useEffect(() => {
    if (!isFetched) {
      initializeAvailableFilter({});
      setIsFetched(true);
    }
  }, [isFetched, initializeAvailableFilter, items.filters?.configurator]);

  useEffect(() => {
    if (filters && filters?.seller_id && !isItemsFetched) {
      dispatch(getProductsSearch(filters));
    }
  }, [dispatch, filters, isItemsFetched]);

  useEffect(() => {
    return () => {
      setIsFetched(false);
    }
  }, [])


  if (!loading) {
    if (items?.items?.length === 0) {
      return (
        <NotFound title="Product Search Not Found" status="empty"
          description="What you search may be not found, Please search with another keyword" />
      )
    }
  } else {
    return (
      <LoadingState />
    )
  }

  return (
    <>
      <PageHead title={`${items?.seller?.name ? items?.seller?.name : items?.seller?.vendor_id}`} />

      <div className="seller_details">
        <HomePageBlock padding="3% 3% 0% 3%"
          backgroundImage="/assets/dashboard/seller_page.svg"
          backgroundRepeat="no-repeat"
          backgroundSize='100%'
          backgroundColor='var(--adm-color-primary)'
          backgroundBlendMode='color-dodge'
        >
          <SellerSearchHeader />
          <SellerFullDetails items={items} />
        </HomePageBlock>
        <HomePageBlock padding="0" background="none">
          <SellerProductDetails products={items?.items} paginate={items?.paginate} filters={items?.filters} />
        </HomePageBlock>
      </div>
    </>
  )
}

export default SellerDetails
