import React from 'react';
import Countdown from 'react-countdown'
import {DemoBlock} from "demos";
import {Grid, Space} from "antd-mobile";
import './CountDown.scss'

const CountDown = ({finishTime, campaignText}) => {

    return (
        <DemoBlock background="#1A1415" padding="2% 7%" margin="3%" borderRadius="8px">
          <div className="countdown-title">{campaignText}</div>
            {
                finishTime &&
                <Countdown
                    date={finishTime * 1000}
                    renderer={props => {
                        return (
                            <Grid columns={19} gap={8} style={{alignItems: 'center'}}>
                                <Grid.Item span={4}>
                                    <Space direction="vertical" align="center" className="count_down_format">
                                        <span className="count_down_format__title">{props.formatted.days} </span>
                                        <span className="count_down_format__sub_title">Days</span>
                                    </Space>
                                </Grid.Item>
                                <Grid.Item span={1}>
                                    <strong className="dot">:</strong>
                                </Grid.Item>
                                <Grid.Item span={4}>
                                    <Space direction="vertical" align="center" className="count_down_format">
                                        <span className="count_down_format__title">{props.formatted.hours}</span>
                                        <span className="count_down_format__sub_title">Hours</span>
                                    </Space>
                                </Grid.Item>
                                <Grid.Item span={1}>
                                    <strong className="dot">:</strong>
                                </Grid.Item>
                                <Grid.Item span={4}>
                                    <Space direction="vertical" align="center" className="count_down_format">
                                        <span className="count_down_format__title">{props.formatted.minutes}</span>
                                        <span className="count_down_format__sub_title">Minutes</span>
                                    </Space>
                                </Grid.Item>
                                <Grid.Item span={1}>
                                    <strong className="dot">:</strong>
                                </Grid.Item>
                                <Grid.Item span={4}>
                                    <Space direction="vertical" align="center" className="count_down_format">
                                        <span className="count_down_format__title">{props.formatted.seconds}</span>
                                        <span className="count_down_format__sub_title">Secs</span>
                                    </Space>
                                </Grid.Item>
                            </Grid>
                        )
                    }}
                />
            }
        </DemoBlock>
    );
}

export default CountDown;
