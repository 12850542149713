import * as type from 'app/actionTypes/address';

export function getAllAddress() {
    return {
        type: type.GET_ADDRESS_REQUESTED,
    }
}

export const updateAddress = (id, data = {}) => ({
    type: type.UPDATE_ADDRESS,
    payload: { id, data },
})

export function getCourier() {
    return {
        type: type.GET_COURIER_REQUESTED,
    }
}
