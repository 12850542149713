import React, {useEffect, useState} from "react";
import {
  CampaignDetailsSkeleton,
  CountDown,
  GlobalHeaderBack,
  KeywordSearchProductDetails, KeywordSearchSkeleton,
  PageHead
} from "components";
import {Card, Grid, Image, InfiniteScroll, Loading, Space} from "antd-mobile";
import {DemoBlock, ProductDetailsBlock} from "demos";
import "./Campaign.scss"
import {RightOutline} from "antd-mobile-icons";
import ImageRenderer from "utils/ImageRenderer";
import {useDispatch, useSelector} from "react-redux";
import useFilters from "hooks/useFilters";
import {getCampaignDetails, LoadMoreCampaignProducts} from "app/actions/campaign";
import {Link, useParams} from "react-router-dom";
import {compareCampaignTime, ShowPriceWithCurrency} from "utils/helper";
import {getTagCategory} from "services/product-client";
import {getCampaignDetails as getCampaignDetailsApi} from "services/campaign-client";
import { useLayoutContext } from "context/LayoutContext";


const Campaigns = () => {
  const { localeCurrency, preloaderImage } = useLayoutContext();
  let {slug} = useParams();
  const dispatch = useDispatch();
  const {details, firstLoad} = useSelector(state => state.campaign);

  const [hasMore, setHasMore] = useState(false)
  const [tagLoading, setTagLoading] = useState(false);
  const [tagList, setTagList] = useState([]);
  const productsRef = React.useRef();
  const {filters, handleFilterChange, handelAllFilterClear} =
    useFilters();
  const [isFirstCalled, setIsFirstCalled] = useState(true);

  useEffect(() => {
    let hasCampaign = details?.campaign?.slug === slug;
    if (filters && isFirstCalled && !hasCampaign) {
      setIsFirstCalled(false)
      dispatch(getCampaignDetails(slug, false, filters, true));
    }
  }, [dispatch, isFirstCalled, filters, slug, details]);

  const fetchCategoryTag = React.useCallback(
    () => {
      setTagLoading(true)
      getTagCategory(details?.campaign?.id)
        .then(res => {
          setTagList(res.data)
          setTagLoading(false)
        })
        .catch(err => {
          setTagLoading(false)
        })
    },
    [details?.campaign?.id],
  )

  useEffect(() => {
    let hasCampaign = details?.campaign?.slug === slug;
    if (hasCampaign && details?.campaign?.id) {
      fetchCategoryTag()
    }
  }, [details?.campaign, slug, fetchCategoryTag])

  useEffect(() => {
    if (details?.meta?.current_page < details?.meta?.last_page) {
      setHasMore(true)
    }
  }, [details?.meta?.current_page, details?.meta?.last_page]);

  const loadMoreProducts = () => {
    let newParams = { ...filters, page: details.meta?.current_page + 1 }
    getCampaignDetailsApi(slug, newParams)
    .then(res => {
      if (res?.status === 200) {
        dispatch(LoadMoreCampaignProducts(res.data))
      }
    })
    .catch(({reponse}) => {
      console.error({reponse})
    })
    setHasMore(false)
  }

  if (details?.loading && !firstLoad) return (<CampaignDetailsSkeleton/>)

  const InfiniteScrollContent = ({hasMore}) => {
    return (
      <>
        {
          hasMore ? (
              <span>End of products</span>
            )
            : (
              <div className="loading_middle">
                <Loading color='primary'/>
              </div>
            )
        }
      </>
    )
  }

  const handleCategoryChange = (value) => {
    if (productsRef) {
      productsRef
      .current.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
    }
    let newParams = {...filters, tags: [value]}
    if (value === "all") {
      handelAllFilterClear()
      dispatch(getCampaignDetails(slug, true, undefined,))
    }else {
      handleFilterChange({...newParams, page: 1})
      dispatch(getCampaignDetails(slug, true, newParams,))
    }
  }

  let scheduledTime = null
  let campaignText = null

  if (compareCampaignTime(details?.campaign?.finished_at, details?.campaign?.started_at) === "Scheduled"){
    scheduledTime = details?.campaign?.started_at
    campaignText = "Start At :"
  }else if(compareCampaignTime(details?.campaign?.finished_at, details?.campaign?.started_at) === "Running"){
    scheduledTime = details?.campaign?.finished_at
    campaignText = "End At :"
  }

  return (
    <>
      <PageHead title={`Campaign`}/>

      <GlobalHeaderBack title="Campaign" height="60px" color="white"/>

      <ProductDetailsBlock background="linear-gradient(360deg, rgba(230,160,127,1) 0%, rgba(242,102,36,1) 65%)" padding="2% 0 10% 0" marginBottom="10%" >

        <DemoBlock margin="0% 3%" borderRadius="8px">
          <div className="product_details_image_campaign">
            <div className="product_details_image_campaign__thumb">
              <img src={details?.campaign?.banner_url} className="product_details_image_campaign__thumb__image"
                   alt={details?.campaign?.name}
                   style={{
                     backgroundImage: preloaderImage ?? `url(${process.env.PUBLIC_URL + "/assets/product/preloader-bg_411x411.png"})`,
                     backgroundRepeat: "no-repeat",
                   }}/>
            </div>
          </div>
        </DemoBlock>
        <CountDown finishTime={scheduledTime ? scheduledTime : details?.campaign?.finished_at} campaignText={campaignText}/>
        <DemoBlock margin="0% 3%" background="none">
          <Grid columns={4} gap={8} className="category_campaign swiper_value_tag">
              <Grid.Item>
                <Space block direction="vertical" align="center" justify="center"
                       onClick={() => handleCategoryChange('all')}>
                  <div className="category_icons">
                    <Image src="/assets/category/icon_02.svg" width={50} height={50} fit="contain"/>
                  </div>
                  <div className="category_title">
                    All
                  </div>
                </Space>
              </Grid.Item>
              {
                !tagLoading && tagList?.data?.map((item, index) => {
                  return (
                    <Grid.Item key={index}>
                      <Space block direction="vertical" align="center" justify="center"
                             onClick={() => handleCategoryChange(item?.id)}>
                        <div className="category_icons">
                          <Image src={item?.image_url} width={50} height={50} fit="contain"/>
                        </div>
                        <div className="category_title">
                          {item.name}
                        </div>
                      </Space>
                    </Grid.Item>
                  )
                })
              }
          </Grid>
        </DemoBlock>
        <DemoBlock margin="2% 3%" borderRadius="8px">
          {
            details && details?.data?.length > 0 &&
            <Card
              title={
                <Space>
                  <Image src="/assets/category/icon.svg" width={20} height={20} fit="contain"/>
                  <span>{details?.data[0]?.campaign_tags[0]?.name}</span>
                </Space>
              }
              style={{borderRadius: "16px"}}
            >
              <Grid columns={3} gap={12}>
                {
                  details && details?.data?.length > 0 && details?.data?.slice(0, 3)?.map((product, index) => {
                    let discountPrice = product?.discount_price?.min
                    let originalPrice = product?.price?.min

                    if (product?.price?.discount?.min){
                      discountPrice = product?.price?.discount?.min
                    } else if (product?.price?.discount?.max){
                      discountPrice = product?.price?.discount?.max
                    }

                    if (product?.price?.original?.min){
                      originalPrice = product?.price?.original?.min
                    } else if (product?.price?.original?.max){
                      originalPrice = product?.price?.original?.max
                    }

                    return (
                      <Grid.Item key={`category_product${index}`}>
                        <Link className="link_underline" to={{
                          pathname: `/products/details`,
                          search: `?ipid=${product.id}&campaign=${product?.pivot?.campaign_id}&campaign_product=${product?.pivot?.id}`,
                          state: {
                            title: product.title,
                            price: discountPrice ? discountPrice : originalPrice,
                            image: product?.image,
                            link: product?.link
                          }
                        }}>
                          <Grid columns={1} gap={8} style={{alignItems: "center"}}>
                            <Grid.Item>
                              <div className="campaign_product">
                                <div className="campaign_product__thumb">
                                  <ImageRenderer
                                    key={1}
                                    url={product?.image}
                                    thumb={product?.image}
                                    width={60}
                                    height={60}
                                    alt={`ProductImage01`}
                                    imageBackground={preloaderImage ?? "/assets/product/preloader-bg_184X184.png"}
                                  />
                                </div>
                              </div>
                            </Grid.Item>
                            <Grid.Item style={{placeSelf: "center"}}>
                              <span className="campaign_title"> {product?.title} </span>
                            </Grid.Item>
                            <Grid.Item style={{placeSelf: "center"}}>
                              <Space className="campaign_price">
                                  {discountPrice ? <span
                                    className="campaign_price__price">{ShowPriceWithCurrency({price: discountPrice, currency: localeCurrency})}</span>
                                    : <span
                                    className="campaign_price__price">{ShowPriceWithCurrency({price: originalPrice, currency: localeCurrency})}</span>
                                  }
                                {discountPrice && <del className="campaign_price__del">{ShowPriceWithCurrency({price: originalPrice, currency: localeCurrency})}</del>}
                              </Space>
                            </Grid.Item>
                          </Grid>
                        </Link>
                      </Grid.Item>
                    )
                  })
                }
              </Grid>
            </Card>
          }
        </DemoBlock>
        <DemoBlock margin="5% 3% 3% 3%" background="none">
          <Space block justify="between">
            <Space block align="center" className="campaign_title_more">
              <Image src="/assets/category/icon.svg" width={20} height={20} fit="contain"/>
              <span ref={productsRef} className="campaign_title_more__main">More To Love</span>
            </Space>
            <span className="campaign_card" onClick={() => handleCategoryChange("all")}>View All<RightOutline/></span>
          </Space>
        </DemoBlock>

        {
          <div>
            <>
              {
                (!details.isPartialLoading && firstLoad) ?
                  <Grid columns={2} gap={8} className="masonry_campaign_grid">
                    {
                      details && details.data?.length >= 0 && details?.data?.map((product, index) => {
                        return (
                          <Grid.Item
                            key={`product_search${index}`}
                            className="search_campaign_results_grid"
                          >
                            <KeywordSearchProductDetails product={product}/>
                          </Grid.Item>
                        )
                      })
                    }
                  </Grid>
                  :
                  <div style={{padding: '0 3%'}}>
                    <KeywordSearchSkeleton/>
                  </div>
              }
            </>
            {
              details && details?.meta?.last_page > 1 && details?.meta?.current_page !== details?.meta?.last_page &&
              <InfiniteScroll hasMore={hasMore} loadMore={loadMoreProducts}>
                <InfiniteScrollContent hasMore={hasMore}/>
              </InfiniteScroll>
            }
          </div>
        }
      </ProductDetailsBlock>

    </>
  );
}

export default Campaigns
