import * as Types from 'app/actionTypes/products';

export const getProductsSearch = (filter = {}, isLoadMore = false) => {
  return {
    type: Types.GET_SEARCH_REQUESTED,
    payload: filter,
    isLoadMore,
  }
}


export function reset() {
  return {
    type: Types.RESET_SEARCH,
  }
}

export function navigateToNewSearch() {
  return {
    type: Types.NAVIGATE_TO_NEW_SEARCH
  }
}
