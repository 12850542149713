import * as actionTypes from 'app/actionTypes/cart';
import produce from 'immer';

const initialState = {
  buyNowItem: null,
}

export default function cart(state = initialState, action) {
  const { payload, type } = action;

  return produce(state, draft => {
    switch(type) {
      case actionTypes.INIT_BUY_NOW_ITEM:
        break;
      case actionTypes.GET_BUY_NOW_ITEM:
        break;
      case actionTypes.SET_BUY_NOW_ITEM:
        draft.buyNowItem = payload;
        break;
      case actionTypes.REMOVE_BUY_NOW_ITEM:
        draft.buyNowItem = null;
        break;
      default:
        return initialState;
    }
  })
}
