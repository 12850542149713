import React, { useEffect } from 'react'
import { GlobalHeaderBack, LoadingState } from "components";
import { Collapse, Grid, Image, TreeSelect } from "antd-mobile";
import './Categories.scss'
import { PageHead } from "../../components";
import ImageRenderer from "utils/ImageRenderer";
import Qs from "qs";
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCategories } from 'app/actions/system';
import { navigateToNewSearch } from 'app/actions/products';
import { useLayoutContext } from 'context/LayoutContext';

const Categories = () => {
  const { preloaderImage } = useLayoutContext();
  const history = useHistory();
  const dispatch = useDispatch();
  const { categories } = useSelector(state => state.system);

  useEffect(() => {
    if (!categories?.isFetched && !categories?.data) {
      dispatch(getCategories())
    }
  }, [categories, dispatch])

  if (categories.loading) return (<LoadingState />)

  const makeCategories = categories?.data && categories?.data?.app_drawer?.items?.map((el) => {
    const categoryName = <div className="mother_categories">
      <span className="mother_categories__icons"><Image width={40} height={40} src={el?.image ? el?.image : preloaderImage} alt={el.id} fit="contain" /></span>
      <span>{el.title}</span>
    </div>

    const labelValue =
      el?.children?.map((cat) => {
        let motherAttr = Qs.stringify(cat?.attrs, { arrayFormat: 'brackets', encode: false })
        return {
          label:
            <Collapse className="collapse_value" defaultActiveKey={['1']}>
              <Collapse.Panel key='1' title={<span onClick={() => {
                history.push({
                  pathname: '/products',
                  search: `?${motherAttr}`
                })
              }}>{cat?.title}</span>} className="collapse_panel">
                <Grid columns={3} gap={10} className="collapse_value__grid">
                  {
                    cat?.children?.map((catChildren, index) => {
                      let childAttr = Qs.stringify(catChildren?.attrs, { arrayFormat: 'brackets', encode: false })
                      return (
                        <Grid.Item style={{ height: "115px" }} key={`catChildren${index}`}>
                          <Link to={() => {
                            dispatch(navigateToNewSearch())
                            return `products?${childAttr}`
                          }} className="link_underline">
                            <div className="collapse_value__grid__item">
                              <ImageRenderer
                                key={catChildren?.id}
                                url={catChildren?.image ? catChildren?.image : preloaderImage}
                                thumb={catChildren?.image ? catChildren?.image : preloaderImage}
                                width={70}
                                height={70}
                                alt={`ProductImage${catChildren?.id}`}
                                imageBackground={"white"}
                                maxWidth="70px" borderRadius="8px"
                              />
                              <span className="collapse_value__grid__title">{catChildren?.title}</span>
                            </div>
                          </Link>
                        </Grid.Item>
                      )
                    })
                  }
                </Grid>
              </Collapse.Panel>
            </Collapse>,
          value: cat.id
        }
      })

    return {
      label: categoryName,
      value: el?.attrs?.keyword,
      children: labelValue,
    }
  })

  return (
    <>
      <PageHead title={'Categories'} />

      <GlobalHeaderBack title="Categories" height="60px" color="white" />
      <div className="tree_select" style={{ height: '750px' }}>
        {
          makeCategories &&
          <TreeSelect
            defaultValue={["Women Fashion", "Women Fashion", "Women Dress"]}
            options={makeCategories}
          />
        }
      </div>
    </>
  )
}

export default Categories
