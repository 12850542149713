import React from 'react'
import {Grid, Swiper} from "antd-mobile";
import {RECENTLY_VIEW} from "constants/constants";
import { Link } from "react-router-dom";
import {getLocalData} from "utils/helper";
import {RecentlyViewProductList} from "components";
import './RecentlyViewProduct.scss'

const RecentlyViewProduct = () => {

    let recentlyViewProduct = getLocalData(RECENTLY_VIEW)

    return (
        <>
            {
                recentlyViewProduct &&
                <>
                    <Grid columns={1} gap={0} className="re_product_grid">
                        <Grid.Item span={1}>
                            <h3>Recently Viewed Products</h3>
                        </Grid.Item>
                    </Grid>

                    <Swiper
                        indicator={() => null}
                        allowTouchMove={true}
                        slideSize={42}
                        className={recentlyViewProduct?.length <= 3 ? "swiper_value" : "swiper_value_item"}
                    >
                        {
                            recentlyViewProduct?.map((product, index) => {
                                return (
                                    <Swiper.Item
                                      key={`shipper_item${index}`}
                                    >
                                        <Link style={{textDecoration: 'none'}} to={{ pathname: "/recently-viewed-products"}}>
                                            <div style={{margin: '0 5px'}}>
                                            <RecentlyViewProductList product={product}/>
                                            </div>
                                        </Link>
                                    </Swiper.Item>
                                )
                            })
                        }
                    </Swiper>
                </>
            }
        </>
    )
}

export default RecentlyViewProduct
