import {all, call, put, takeEvery} from "redux-saga/effects"
import * as types from 'app/actionTypes/issue';
import {getAllIssues} from "services/my-order-client";

const getIssueAbleApi = async (filters = {}) => {
  return getAllIssues(filters)
    .then(res => res.data)
    .catch((error) => { throw error })
}

function* fetchIssuableItem(action) {
  try {
    const data = yield call(() => getIssueAbleApi(action.payload));
    yield put({ type: types.ISSUE_ABLE_DETAILS_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: types.ISSUE_ABLE_DETAILS_FAILED, message: e.message });
  }
}

function* issueSaga() {
  yield all([
    takeEvery(types.ISSUE_ABLE_DETAILS_REQUEST, fetchIssuableItem),
  ]);
}

export default issueSaga;
