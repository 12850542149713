import React from 'react';
import {Grid} from "antd-mobile";
import Skeleton from 'react-loading-skeleton';

const CartSkeleton = () => {

    return (
        <>
            <Grid columns={12} gap={24} className="cart_layout seller_layout" style={{marginTop: '5%'}}>
                <Grid.Item span={1}>
                    <Skeleton count={1}/>
                </Grid.Item>
                <Grid.Item span={9}>
                    <Skeleton count={1}/>
                </Grid.Item>
            </Grid>
            <Grid columns={14} gap={10} className="cart_product_layout">
                <Grid.Item span={1} className="cart_product_layout_checkbox">
                    <Skeleton count={1}/>
                </Grid.Item>
                <Grid.Item span={3} className="cart_product_image">
                    <Skeleton height={60} width={60}/>
                </Grid.Item>
                <Grid.Item span={10} className="cart_product_details">
                    <Grid columns={1} gap={2} style={{'--gap-vertical': '6px'}}>
                        <Grid.Item className="cart_product_details__title">
                            <Skeleton count={1}/>
                        </Grid.Item>
                        <Grid.Item>
                            <Skeleton count={1}/>
                        </Grid.Item>
                        <Grid.Item>
                            <Skeleton count={1}/>
                        </Grid.Item>
                        <Grid.Item className="cart_qty">
                            <Skeleton count={2}/>
                        </Grid.Item>
                        <Grid.Item style={{marginTop: '3%', textAlign: "right"}}>
                            <Skeleton count={1}/>
                        </Grid.Item>
                    </Grid>
                </Grid.Item>
            </Grid>
        </>
    );
}

export default CartSkeleton;
