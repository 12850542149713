import React, { useEffect, useState } from 'react'
import '../../Myorders.scss'
import { GlobalHeaderBack, LoadingState, PageHead, StatusLogIcons, StatusLogTag, StatusTag } from "components";
import { DemoBlock } from "demos";
import { getParcelProductDetails } from "services/my-order-client";
import { useParams } from "react-router-dom";
import { Button, Divider, Grid, Popover, Space, Steps } from "antd-mobile";
import { formatDate, formatDateTime, ShowPriceWithCurrency } from "utils/helper";
import { InformationCircleFill } from "antd-mobile-icons";
import ImageRenderer from "utils/ImageRenderer";
import { useLayoutContext } from 'context/LayoutContext';

const { Step } = Steps

const ParcelFullDetails = () => {
  const { localeCurrency, helpDeskDomain } = useLayoutContext();
  let { orderId } = useParams();

  const [isParcelProductLoading, setIsParcelProductLoading] = useState(false);
  const [parcelProductDetails, setParcelProductDetails] = useState(null);

  const fetchParcelProductDetails = React.useCallback(
    () => {
      setIsParcelProductLoading(true)
      getParcelProductDetails(orderId)
        .then(res => {
          setParcelProductDetails(res.data)
          setIsParcelProductLoading(false)
        })
        .catch(error => {
          if (error.response.status === 401) {
            window.location.href = "/auth/login"
          }
          setIsParcelProductLoading(false)
        })
    },
    [orderId],
  )


  useEffect(() => {
    fetchParcelProductDetails()
  }, [fetchParcelProductDetails]);

  if (isParcelProductLoading) {
    return (
      <LoadingState />
    )
  }

  const products = parcelProductDetails?.products

  return (
    <>
      <PageHead title={`Parcel Details`} />

      <GlobalHeaderBack title="Parcel Details" height="60px" color="white" />

      <DemoBlock padding="16px 12px" margin="2% 3%" borderRadius="8px">
        <Grid columns={15} gap={2} className="product_order_details">
          <Grid.Item span={8}>
            <Space direction="vertical" style={{ "--gap": "2px" }}>
              <div className="product_order_details__order-text">
                <span>Parcel #{parcelProductDetails?.parcel?.id}</span> <Popover
                  content={parcelProductDetails?.parcel?.address} placement={'topLeft'} trigger='click'>
                  <InformationCircleFill />
                </Popover>
              </div>
              <span className="product_order_details__order-date">Date: {formatDate(parcelProductDetails?.parcel?.created_at)}</span>
            </Space>
          </Grid.Item>
          <Grid.Item span={7} style={{ textAlign: "right" }}>
            <Space direction="vertical" style={{ "--gap": "2px" }}>
              <span>Status: <StatusTag text={parcelProductDetails?.parcel?.status} slug={parcelProductDetails?.parcel?.status} /></span>
              {
                parcelProductDetails?.parcel?.tracking_code &&
                <span className="product_order_details__order-date">Tracking: {parcelProductDetails?.parcel?.tracking_code}</span>
              }
            </Space>
          </Grid.Item>
        </Grid>
      </DemoBlock>

      <DemoBlock padding="16px 12px" margin="2% 3%" borderRadius="8px">
        {
          products && products?.map((productItem, index) => {
            let item = productItem.item
            return (
              <div key={`packageItemData${index}`}>
                <div key={`product01Item${index}`}>
                  <Grid columns={10} gap={8} className="product_details_with_order">
                    <Grid.Item span={2}>
                      <ImageRenderer
                        key={item.id}
                        url={item.image}
                        thumb={item.image}
                        width={60}
                        height={60}
                        alt={`ProductItemImage${item.id}`}
                        borderRadius="8px"
                      />
                    </Grid.Item>
                    <Grid.Item span={8}>
                      <Grid columns={1} gap={0}>
                        <Grid.Item span={1} className="product_title">
                          <div className="product_title__title">
                            <span>{item.title}</span>
                          </div>
                        </Grid.Item>
                      </Grid>
                      <Grid columns={1} gap={0} className="product_meta">
                        <Grid.Item>
                          <Space direction='vertical' style={{ "--gap": "2px" }}>
                            {
                              item?.attrs?.map((el, index) => {
                                return (
                                  <span key={`productAttrMeta${index}`}
                                    className="product_attr">{el.key}: {el.value}</span>
                                )
                              })
                            }
                            <div>
                              Price: <span className="product_qty_price">{ShowPriceWithCurrency({ price: item?.price, currency: localeCurrency })}</span>
                            </div>

                          </Space>
                        </Grid.Item>
                        <Grid.Item style={{ marginTop: '1%' }}>
                          <Space align="center" block>
                            <div>Status: <StatusTag slug={item.status} text={item.status} />
                            </div>
                            <div>
                              Quantity: <span className="product_qty_price">{item.quantity}</span>
                            </div>
                          </Space>
                        </Grid.Item>
                      </Grid>
                    </Grid.Item>
                  </Grid>
                </div>
                <Divider>Parcel Timeline</Divider>
                <div className="product_details_step">
                  <Steps direction='vertical' current={parcelProductDetails?.events?.length}>
                    {
                      parcelProductDetails?.events?.map?.((el, index) => {
                        let title = <StatusLogTag slug={el.status} text={el.status} />
                        let description = <div className="step_des">
                          <span>{formatDateTime(el.created_at)}</span>
                          <span className="step_des__message">{el.message}</span>
                        </div>

                        let icons = <StatusLogIcons icons={el.icon} />

                        return (
                          <Step
                            title={title}
                            description={description}
                            icon={icons}
                            key={`productLog${index}`}
                          />
                        )
                      })
                    }
                  </Steps>
                </div>
                <Divider>Product End</Divider>
              </div>
            )
          })
        }
      </DemoBlock>

      <DemoBlock padding="16px 12px" margin="1% 3% 15% 3%" borderRadius="8px">
        <div className="need_help">
          <Button block size='middle' onClick={() => {
            if (helpDeskDomain) {
              window.open(helpDeskDomain)
            }
          }}>
            Need Help?
          </Button>
        </div>
      </DemoBlock>
    </>
  )
}

export default ParcelFullDetails
