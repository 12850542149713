import React from 'react';

const BlankWidget = ({children}) => {
    return (
        <>
            {children}
        </>
    );
}

export default BlankWidget;
