import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { CheckList, Ellipsis, ErrorBlock, Loading, Space } from "antd-mobile";
import { CloseCircleOutlined } from "@ant-design/icons";
import { useProduct } from "context/product-context";
import AddToCartTabBar from "layout/AddToCartTabBar";
import { SHIPPING_DETAILS, SHIPPING_DETAILS_CATEGORY, defaultKeys } from "consts/storage";
import routePaths from "consts/route-paths";
import { getShippingLabel, getShippingRate, ShowPriceWithCurrency } from "utils/helper";
import { useLayoutContext } from "context/LayoutContext";
import { requestShippingSlots } from "services/product-client";
import "./ShippingDetails.scss";

const animatedComponents = makeAnimated();

const ShippingDetails = (props) => {
  const {
    localeCurrency,
    defaultShippingUnit,
    notifyMessages,
    shippingMethodsMap,
    getConfigValue
  } = useLayoutContext();
  const { setViewShippingSelect } = props;
  const { data, loading } = useSelector((state) => state.category);
  const [slotsLoading, setSlotsLoading] = React.useState(false);
  const [fixedCategory, setFixedCategory] = React.useState(false);
  const [selectedCategory, setSelectedCategory] = React.useState(null);

  const {
    state: productData,
    shippingMethodsData,
    handleSelectedShippingMethod,
    setCategoryShippingCharge,
    categoryShippingCharge,
    selectedShippingDetails,
    shippingRate,
    approxWeight,
    isWholesaleProduct,
    isCampaignItem,
  } = useProduct();

  useEffect(() => {
    if (!isCampaignItem && selectedShippingDetails === null && shippingMethodsData?.length) {
      handleSelectedShippingMethod(shippingMethodsData?.[0]);
    }
  }, [isCampaignItem, selectedShippingDetails, shippingMethodsData, handleSelectedShippingMethod]);

  const mvnServiceDisclaimer = productData?.extras?.mvnServiceDisclaimer || notifyMessages?.moveon_service_discalimer?.label || SHIPPING_DETAILS;

  let makeOptionData = data?.map(el => {
    return {
      ...el,
      value: el.id,
      label: el.name,
    };
  });

  useEffect(() => {
    const catId = parseInt(getConfigValue(defaultKeys.fixedShippingCategoryId)?.value);

    if (catId > 0 && data?.length > 0) {
      const cat = data.find(cat => cat.id === catId);
      if (cat) {
        setSelectedCategory({
          ...cat,
          value: cat.id,
          label: cat.name,
        });
        setFixedCategory(true);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const fetchShippingSlots = ({ cat, foreign_type = "moveon" }) => {
    setSlotsLoading(true);
    let payload = {
      country_id: productData?.country_id,
      foreign_type,
      shipping_category_id: cat?.id,
    };
    requestShippingSlots(payload)
      .then(({ data }) => {
        let updatedCat = {
          ...cat,
          price: data?.data?.price,
          slots: data?.data?.slots?.length > 0 ? data.data.slots : [],
        };
        setCategoryShippingCharge(updatedCat);
      })
      .catch(({ response }) => {
        console.error({ response });
      })
      .finally(() => {
        setSlotsLoading(false);
      });
  };

  const onCategoryChange = (data) => {
    if (data?.id) {
      setSelectedCategory(data);
      fetchShippingSlots({ cat: data });
    }
  };

  let categoryView = false;

  if (selectedShippingDetails?.identifier === "mvn_ship_for_me") {
    categoryView = true;
  }

  let shippingFee;
  if (isCampaignItem) {
    shippingFee = getShippingRate({ selectedFrCat: productData?.shipping, approxWeight, isWholesaleProduct });
  }

  if (shippingMethodsData?.length <= 0 && productData?.type !== "campaign") return <ErrorBlock fullPage title="Empty" status="default" description="No Shipping Data Found, Please contact with our help center" />;

  const renderShippingList = (slotsLoading) => {
    if (slotsLoading) {
      return (<>
        <Loading />
      </>);
    }

    return (<>
      {
        isCampaignItem ?
          <CheckList className="shippingChecklist" defaultValue={["mvn_ship_for_me"]}>
            <CheckList.Item
              value={"mvn_ship_for_me"}
            >
              <Space direction="vertical" className="shipping_details_space">
                {shippingFee > 0 ?
                  productData?.shipping?.unit === "kg" && defaultShippingUnit?.includes(productData?.shop_id?.toString()) ?
                    <span className="shipping_details_space__fee title-text">Shipping: {ShowPriceWithCurrency({
                      price: ((parseFloat(shippingFee) / 1000) * 100)?.toFixed(2),
                      currency: localeCurrency
                    })} /- 100gm</span>
                    : <span className="shipping_details_space__fee title-text">Shipping: {ShowPriceWithCurrency({
                      price: shippingFee.toFixed(2),
                      currency: localeCurrency
                    })}/- {productData?.shipping?.unit}</span>
                  : shippingFee === 0 &&
                  <span className="shipping_details_space__fee title-text">Shipping: Free Shipping</span>
                }
                <span
                  className="shipping_details_space__title title-text">Shipment: {shippingMethodsMap?.MoveOn?.label || "MoveOn"}-Ship For Me</span>
                <span
                  className="shipping_details_space__title title-text">Estimated Delivery On 15-25 days</span>
              </Space>
            </CheckList.Item>
          </CheckList>
          :
          <CheckList defaultValue={[shippingMethodsData?.[0]?.identifier]} className="shippingChecklist">
            {
              shippingMethodsData?.map((elements, index) => {
                let shippingPrice = ((elements?.fee) * productData?.fx)?.toFixed(2);
                if (elements.identifier === "mvn_ship_for_me") {
                  if (categoryShippingCharge) {
                    if (categoryShippingCharge?.unit === "kg" && defaultShippingUnit?.includes(productData?.shop_id?.toString())) {
                      shippingPrice = ((parseFloat(shippingRate) / 1000) * 100)?.toFixed(2);
                    } else {
                      shippingPrice = shippingRate;
                    }
                  } else {
                    shippingPrice = "Category not selected";
                  }
                }

                let shippingLabel = getShippingLabel({
                  shippingIdentifier: elements?.identifier,
                  title: elements?.title,
                  shippingMethodsMap
                }) || elements?.title;

                return (
                  <CheckList.Item
                    key={`shipping_method${index}`}
                    value={elements?.identifier}
                    onClick={() => handleSelectedShippingMethod(elements)}
                  >
                    <Space direction="vertical" className="shipping_details_space">
                      {
                        parseFloat(elements?.fee) === 0 ?
                          <span className="shipping_details_space__fee title-text">Free Shipping</span>
                          :
                          <>
                            {categoryShippingCharge?.price > 0 && elements.identifier === "mvn_ship_for_me" ?
                              categoryShippingCharge?.unit === "kg" && defaultShippingUnit?.includes(productData?.shop_id?.toString()) ?
                                <span className="shipping_details_space__fee title-text">Shipping: {ShowPriceWithCurrency({
                                  price: shippingPrice,
                                  currency: localeCurrency
                                })}/- 100gm</span>
                                :
                                <span className="shipping_details_space__fee title-text">Shipping: {ShowPriceWithCurrency({
                                  price: shippingPrice,
                                  currency: localeCurrency
                                })}/- {categoryShippingCharge?.unit}</span>
                              :
                              <span className="shipping_details_space__fee title-text">Shipping: {shippingPrice}</span>
                            }
                          </>
                      }
                      <span
                        className="shipping_details_space__title title-text">Shipment: {shippingLabel}</span>
                      <span
                        className="shipping_details_space__title title-text">Estimated Delivery On {elements?.time} days</span>
                      {
                        elements?.tracking &&
                        <span className="shipping_details_space__title__tracking title-text">Tracking Available</span>
                      }
                    </Space>
                  </CheckList.Item>
                );
              })
            }
          </CheckList>
      }
    </>);
  };

  return (
    <div className="shipping_details">
      <div className="shipping_details__icons">
        <span
          className="shipping_details__icons__icon_text"
          onClick={() => setViewShippingSelect(false)}><CloseCircleOutlined /></span>
      </div>
      {
        (categoryView || isCampaignItem) && shippingFee !== 0 && mvnServiceDisclaimer?.length > 3 &&
        <div className="shipping_notice">
          <Ellipsis
            direction="end"
            rows={3}
            content={mvnServiceDisclaimer}
            expandText="Read More"
            collapseText="Read Less"
            className="shipping_notice__notice_des"
          />
          <div style={{ fontWeight: "bold", marginTop: "5px" }}>
            <a href={routePaths.pages.moveOn} target="_blank" rel="noreferrer">What is MoveOn?</a>
          </div>
        </div>
      }
      {
        (categoryView || isCampaignItem) && shippingFee !== 0 &&
        <div className="shipping_notice shipping_notice_cate">
          <Ellipsis
            direction="end"
            rows={3}
            content={SHIPPING_DETAILS_CATEGORY}
            expandText="Read More"
            collapseText="Read Less"
            className="shipping_notice__notice_des"
          />
        </div>

      }
      {
        categoryView &&
        <Space direction="vertical" block style={{ margin: "4% 0" }}>
          <span className="shipping_details__icons__text">Category:</span>
          {
            data && <Select
              className="basic-single"
              classNamePrefix="select"
              isLoading={loading}
              isClearable={true}
              isSearchable={true}
              name="color"
              options={makeOptionData}
              components={animatedComponents}
              onChange={onCategoryChange}
              placeholder="Please select a category"
              value={selectedCategory}
              isDisabled={fixedCategory}
              onFocus={() => {
                setSelectedCategory(null);
              }}
            />
          }
        </Space>
      }
      {productData?.shop_id === 1 && <span className="shipping_method_title">Please select a shipping method</span>}
      {renderShippingList(slotsLoading)}
      <AddToCartTabBar position="fixed" />
    </div>
  );
};

export default ShippingDetails;
