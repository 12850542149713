import client from './api-client'
import {LOCAL_STORAGE_KEY, LOCAL_STORAGE_USER} from "constants/constants";

const resetLogin = (data) => {
    return client.post(`auth/reset`, data)
}

const postLogin = (data) => {
    return client.post(`auth/login`, data)
}

const getLoginAccountDetails = () => {
    return client.get('/account/me')
}

const getToken = () => {
    return window.localStorage.getItem(LOCAL_STORAGE_KEY)
}

const loggedInUser = () => {
    const token = getToken()
    return !!token
}

const logout = () => {
    client.get('/auth/logout')
        .then(() => {
            window.localStorage.removeItem(LOCAL_STORAGE_KEY)
            window.localStorage.removeItem(LOCAL_STORAGE_USER)
            window.location.href = '/'
        })
        .catch(() => {
        })
}

const postRegister = (data) => {
    return client.post(`auth/register`, data)
}

const socialLogin = (data) => {
    return client.post('/auth/login/social', data)
}

const forgetPasswordPost = (data) => {
    return client.post(`auth/forget`, data)
}

const getAllSetting = () => {
    return client.get('/account/settings')
}

const storeAllSetting = (data) => {
    return client.post('/account/settings', data)
}

export const createHelpdeskSession = () => {
    return client.get('/account/helpdesk');
}

export const authActing = (token) => {
  return client.post(`/auth/acting/${token}`)
}

export {
    postLogin,
    getLoginAccountDetails,
    loggedInUser,
    logout,
    postRegister,
    socialLogin,
    forgetPasswordPost,
    getAllSetting,
    storeAllSetting,
    resetLogin
}
