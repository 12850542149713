import React from 'react';
import {Collapse, Divider, Grid} from "antd-mobile";
import Skeleton from 'react-loading-skeleton';
import './SkeletonOrder.scss'

const SkeletonOrder = () => {

    const orderTitle = <>
        <Skeleton count={1}/>
    </>

    return (
        <div className="all_value_order">
            <Collapse defaultActiveKey={['1']}>
                <Collapse.Panel title={orderTitle} key='1'>
                    <div>
                        <div >
                            <Grid columns={10} gap={8} className="order-product-details">
                                <Grid.Item span={2}>
                                    <Skeleton height={60} width={60}/>
                                </Grid.Item>
                                <Grid.Item span={8}>
                                    <Grid columns={1} gap={0}>
                                        <Skeleton count={1}/>
                                    </Grid>
                                    <Grid columns={2} gap={0} className="product_meta">
                                        <Grid.Item span={2}>
                                            <Skeleton count={1}/>
                                        </Grid.Item>
                                        <Grid.Item span={2}>
                                            <Skeleton count={1}/>
                                        </Grid.Item>
                                        <Grid.Item span={2}>
                                            <Skeleton count={1}/>
                                        </Grid.Item>
                                    </Grid>
                                </Grid.Item>
                            </Grid>
                            <Divider/>
                        </div>
                    </div>
                </Collapse.Panel>
            </Collapse>
            <Collapse defaultActiveKey={['1']} style={{marginTop: '5%'}}>
                <Collapse.Panel title={orderTitle} key='1'>
                    <div>
                        <div >
                            <Grid columns={10} gap={8} className="order-product-details">
                                <Grid.Item span={2}>
                                    <Skeleton height={60} width={60}/>
                                </Grid.Item>
                                <Grid.Item span={8}>
                                    <Grid columns={1} gap={0}>
                                        <Skeleton count={1}/>
                                    </Grid>
                                    <Grid columns={2} gap={0} className="product_meta">
                                        <Grid.Item span={2}>
                                            <Skeleton count={1}/>
                                        </Grid.Item>
                                        <Grid.Item span={2}>
                                            <Skeleton count={1}/>
                                        </Grid.Item>
                                    </Grid>
                                </Grid.Item>
                            </Grid>
                            <Divider/>
                        </div>
                    </div>
                </Collapse.Panel>
            </Collapse>
            <Collapse defaultActiveKey={['1']} style={{marginTop: '5%'}}>
                <Collapse.Panel title={orderTitle} key='1'>
                    <div>
                        <div >
                            <Grid columns={10} gap={8} className="order-product-details">
                                <Grid.Item span={2}>
                                    <Skeleton height={60} width={60}/>
                                </Grid.Item>
                                <Grid.Item span={8}>
                                    <Grid columns={1} gap={0}>
                                        <Skeleton count={1}/>
                                    </Grid>
                                    <Grid columns={2} gap={0} className="product_meta">
                                        <Grid.Item span={2}>
                                            <Skeleton count={1}/>
                                        </Grid.Item>
                                        <Grid.Item span={2}>
                                            <Skeleton count={1}/>
                                        </Grid.Item>
                                    </Grid>
                                </Grid.Item>
                            </Grid>
                            <Divider/>
                        </div>
                    </div>
                </Collapse.Panel>
            </Collapse>
            <Collapse defaultActiveKey={['1']} style={{marginTop: '5%'}}>
                <Collapse.Panel title={orderTitle} key='1'>
                    <div>
                        <div >
                            <Grid columns={10} gap={8} className="order-product-details">
                                <Grid.Item span={2}>
                                    <Skeleton height={60} width={60}/>
                                </Grid.Item>
                                <Grid.Item span={8}>
                                    <Grid columns={1} gap={0}>
                                        <Skeleton count={1}/>
                                    </Grid>
                                    <Grid columns={2} gap={0} className="product_meta">
                                        <Grid.Item span={2}>
                                            <Skeleton count={1}/>
                                        </Grid.Item>
                                        <Grid.Item span={2}>
                                            <Skeleton count={1}/>
                                        </Grid.Item>
                                    </Grid>
                                </Grid.Item>
                            </Grid>
                            <Divider/>
                        </div>
                    </div>
                </Collapse.Panel>
            </Collapse>
        </div>
    );
}

export default SkeletonOrder;
