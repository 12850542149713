import axios from 'axios';
import {config, LOCAL_STORAGE_KEY} from "constants/constants";

let BASE_URL = config.url.CONSUMER_API

const tokenData = localStorage.getItem(LOCAL_STORAGE_KEY);

if (tokenData) {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + tokenData
}

const instance = axios.create({
  baseURL: BASE_URL,
});

instance.interceptors.response.use(resConfig => {
  return resConfig;
}, (error) => {
  // const { response } = error
  // if (response.config.url !== "/wishlist/items" && response.config.url !== "/cart/items" && response.status === 401) {
  //   window.location.href = '/auth/login'
  // }
  return Promise.reject(error);
});

export default instance;
