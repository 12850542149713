export const productReDesign = (product) => {
  product.gallery = product.gallery.map(item => {
    return {
      title: item.title,
      image: item.url ? item.url : item.image,
      thumbnail: item.thumb ? item.thumb : item.thumbnail,
    }
  })

  if (product.variations) {
    product.attributes = Array.isArray(product.variations) ? product.variations : [product.variations]
  }

  if (product.price.original.min) {
    product.price = {
      ...product.price,
      min: product.price.original.min,
      max: product.price.original.max
    }

    product.discount_price = {
      min: product.price.discount.min,
      max: product.price.discount.max
    }

    product.price = {
      min: product.price.original.min,
      max: product.price.original.max
    }
  }


  product.skus = product.skus.map(el => {
    return {
      id: el.id,
      props: el.props,
      price: el.price.actual ? el.price.actual : el.price,
      stock: el.stock.available ? el.stock.available : el.stock,
      special_price: el.price.preorder ? el.price.preorder : el.special_price,
      discount_price: el.price.offer ? el.price.offer : el.discount_price,
      min_qty: el.stock.min ? el.stock.min : el.min_qty,
      limit_qty: el.stock.limit ? el.stock.limit : el.stock,
    }
  })

  return product
}



