import React, { useState } from 'react'
import { Button, Dialog, Divider, Grid, Space, Stepper, Tag, Toast } from "antd-mobile";
// import ImageRenderer from "utils/ImageRenderer";
import '../MyRequest.scss'
import { EyeOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import { moveToCart } from "services/my-request-client";
import { MailOpenOutline } from "antd-mobile-icons";
import { calculateShippingCharges, getShippingLabel, getShippingRate, ShowPriceWithCurrency } from "utils/helper";
import { mnv_shipping } from "consts/storage";
import { useHistory } from 'react-router-dom';
import { useLayoutContext } from 'context/LayoutContext';
import { useCart } from 'context/cart-context';

const RequestedProductDetails = (props) => {
  const { localeCurrency, defaultShippingUnit, shippingMethodsMap, brandName } = useLayoutContext();
  const history = useHistory();
  const { fetchCart } = useCart()
  const { elements } = props

  const [isMoveCartLoading, setIsMoveCartLoading] = useState(false);
  /*const [isQuantityLoading, setIsQuantityLoading] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);*/

  const handleAddToCart = () => {
    setIsMoveCartLoading(true)
    moveToCart([elements.id])
      .then(res => {
        setIsMoveCartLoading(false)
        fetchCart()
        history.push('/cart')
        Toast.show({
          icon: 'success',
          content: res?.data?.message,
        })
      })
      .catch((err) => {
        setIsMoveCartLoading(false)
        if (err?.response?.data?.message) {
          Toast.show({
            icon: 'fail',
            content: err?.response?.data?.message,
          })
        } else {
          Toast.show({
            icon: 'fail',
            content: 'failed!',
          })
        }
      })
  }

  const handleMoveToProductDetails = () => {
    history.push(`/products/details?url=${elements.link}`)
  }

  /*const handleQtyChange = (value, data) => {
      setIsQuantityLoading(true)
      setSelectedProduct(data.id)
      if (value && data) {
          let dataValue = {
              id: data.id,
              link: data.link,
              quantity: value
          }
          updateMyRequest(dataValue)
              .then(res => {
                  setIsQuantityLoading(false)
                  Toast.show({
                      icon: 'success',
                      content: res?.data?.message,
                  })
              })
              .catch(err => {
                  setIsQuantityLoading(false)
                  if (err?.response?.data?.message) {
                      Toast.show({
                          icon: 'fail',
                          content: err?.response?.data?.message,
                      })
                  } else {
                      Toast.show({
                          icon: 'fail',
                          content: 'Quantity update failed',
                      })
                  }
              })
      }
  }*/

  let mvnShip = "Shipping Charges"

  if (elements?.shipping) {
    mvnShip = mnv_shipping
  }
  let itemShippingRate = parseFloat(elements?.extras?.shipping?.fee)
  if (elements?.freight === "mvn_ship_for_me") {
    mvnShip = mnv_shipping
    let isWholesaleProduct = elements.order_type === 'wholesale'
    let approxQty = elements?.quantity;
    let approxWeight = (elements?.extras?.weight?.value || 0) * approxQty;
    itemShippingRate = getShippingRate({ selectedFrCat: elements?.shipping, approxWeight, isWholesaleProduct });
    itemShippingRate = parseFloat(itemShippingRate)
  }

  let shippingLabel = getShippingLabel({shippingIdentifier: elements?.freight, shippingMethodsMap});
  let approvalType = elements.approval_type

  return (
    <>
      <Grid columns={16} gap={10}>
        <Grid.Item span={4}>
          <div className="request_image_product">
            <div className="request_image_product__thumb">
              <img src={elements?.image} alt={elements?.title} className="request_image_product__thumb__image" />
              {/*<ImageRenderer
                        key={elements?.id}
                        url={elements?.image}
                        thumb={elements?.image}
                        width={100}
                        height={100}
                        alt={`RequestImage${elements?.id}`}
                        borderRadius="8px"
                        imageBackground={"/assets/product/preloader-bg_184X184.png"}
                      />*/}
            </div>
          </div>
        </Grid.Item>
        <Grid.Item span={12} className="details_tab">
          <Grid columns={1} gap={8}>
            <Grid.Item>
              <span className="details_tab__title">{elements?.title}</span>
              {
                elements?.freight === "mvn_ship_for_me" ?
                  <div className="shipping_charges">
                    <>
                      {mvnShip}: {calculateShippingCharges({ shipping: elements?.shipping, rate: itemShippingRate, currency: localeCurrency, defaultShippingUnit, shopId: elements?.shop_id })}
                    </>
                  </div>
                  :
                  <>
                    {
                      elements?.extras?.shipping?.identifier &&
                      <span
                        className="shipping_charges">SHIPPING: {elements?.freight_title || shippingLabel}</span>
                    }
                  </>
              }
            </Grid.Item>
            <Grid.Item>
              <Space block wrap align="center">
                {
                  elements?.attrs?.map((el, index) => {
                    return (
                      <span key={`productAttrMeta${index}`} className="product_attr">
                        {el?.key}: {el?.value}{index !== elements.attrs.length - 1 ? ', ' : ''}
                      </span>
                    )
                  })
                }
              </Space>
            </Grid.Item>
            <Grid.Item>
              <Tag color='success'>{elements?.shop?.name}</Tag>
            </Grid.Item>
            {
              elements?.rejection_reason &&
              <Grid.Item>
                <Space block align="center">
                  <span className="rejection_reason"><strong>Reason:</strong> {elements?.rejection_reason}</span>
                </Space>
              </Grid.Item>
            }
            {
              elements?.reason_text &&
              <Grid.Item>
                <div className="reason_text"><strong>Message from {brandName?.label}:</strong> <br/> {elements?.reason_text}</div>
              </Grid.Item>
            }
            <Grid.Item>
              <Space block align="center" justify="between">
                {
                  elements?.price > 0 &&
                  <span className="request_price">Price: {ShowPriceWithCurrency({ price: elements?.price, currency: localeCurrency })}</span>
                }
                <div className="price_qty_request">
                  <Stepper
                    style={{ "--border": "1px solid var(--adm-color-primary)", "--border-inner": "solid 1px var(--adm-color-primary)" }}
                    min={1}
                    // onChange={value => handleQtyChange(value, elements)}
                    defaultValue={elements?.quantity}
                  />
                </div>
              </Space>
            </Grid.Item>
          </Grid>
        </Grid.Item>
      </Grid>
      {
        elements?.status === "approved" &&
        <div className="button_bottom">
          {
            elements.instructions &&
            <Button style={{ borderRadius: '8px' }} size='small'
              onClick={() => {
                Dialog.alert({
                  confirmText: "OK",
                  title: 'Note',
                  content: (
                    <div className="view_note">
                      {elements.instructions}
                    </div>
                  ),
                })
              }}
            > <MailOpenOutline /> View Note</Button>
          }
          <Button 
            size="small" 
            color="primary" 
            loading={isMoveCartLoading} 
            onClick={() => approvalType === 'bulk' ? handleMoveToProductDetails() : handleAddToCart()}
          >
            {
              approvalType === 'bulk' ? <EyeOutlined /> : <ShoppingCartOutlined />
            }
          </Button>
        </div>
      }
      <Divider />
    </>
  )
}

export default RequestedProductDetails
