import React from "react";
import { GlobalHeaderBack, PageHead } from "components/index";
import { DemoBlock, ProductDetailsBlock } from "demos";
import { Grid, Skeleton } from "antd-mobile";
import "./CampaignSkeleton.scss"

const CampaignListSkeleton = () => {

  return (
    <>
      <PageHead title={`Campaign Lists`} />

      <GlobalHeaderBack title="Campaigns" height="60px" color="white" backgroundColor="#7D001E"
        backgroundImage="#7D001E" />

      <ProductDetailsBlock background="#7D001E" padding="0 0 30% 0" marginBottom="10%">
        <DemoBlock padding="2%" margin="0 2%" borderRadius="8px">
          <Grid columns={1} gap={8}>
            <Grid.Item className="campaign_slogan">
              <Skeleton.Paragraph lineCount={2} animated />
            </Grid.Item>
          </Grid>

          <Grid columns={1} gap={8}>
            <Grid.Item style={{ marginTop: "6%" }}>
              <div className="campaigns-header">
                <Skeleton animated className="skeleton_image" />
                <div className="campaigns-header-heading">
                  <Skeleton.Paragraph lineCount={2} animated />
                </div>
              </div>
            </Grid.Item>
          </Grid>
          <Grid columns={1} gap={8}>
            <Grid.Item style={{ marginTop: "6%" }}>
              <div className="campaigns-header">
                <Skeleton animated className="skeleton_image" />
                <div className="campaigns-header-heading">
                  <Skeleton.Paragraph lineCount={2} animated />
                </div>
              </div>
            </Grid.Item>
          </Grid>
          <Grid columns={1} gap={8}>
            <Grid.Item style={{ marginTop: "6%" }}>
              <div className="campaigns-header">
                <Skeleton animated className="skeleton_image" />
                <div className="campaigns-header-heading">
                  <Skeleton.Paragraph lineCount={2} animated />
                </div>
              </div>
            </Grid.Item>
          </Grid>
        </DemoBlock>
        <DemoBlock padding="2%" margin="2%" borderRadius="8px">
          <Grid columns={1} gap={8}>
            <Grid.Item className="campaign_slogan">
              <Skeleton.Paragraph lineCount={2} animated />
            </Grid.Item>
          </Grid>

          <Grid columns={1} gap={8}>


            <Grid.Item style={{ marginTop: "3%" }}>
              <div className="campaigns-header">
                <Skeleton animated className="skeleton_image" />
                <div className="campaigns-header-heading">
                  <Skeleton.Paragraph lineCount={2} animated />
                </div>
              </div>
            </Grid.Item>
          </Grid>
        </DemoBlock>
      </ProductDetailsBlock>
    </>
  );
}

export default CampaignListSkeleton;
