import React, { useState } from 'react'
import { Badge, Grid, Image, NavBar, Popover, Popup, SearchBar, Space, Toast } from "antd-mobile";
import {
  ArrowLeftOutlined,
  MoreOutlined, ShareAltOutlined,
} from "@ant-design/icons";
import { useHistory, useLocation } from "react-router-dom";
import {
  AppstoreOutline,
  ExclamationCircleOutline,
  HeartOutline,
  TextOutline,
  UnorderedListOutline
} from "antd-mobile-icons";
import ProductSearch from "pages/ProductSearch";
import './ProductDetailsHeaderBack.scss'
import { useCart } from "context/cart-context";
import { useLayoutContext } from 'context/LayoutContext';

const ProductDetailsHeaderBack = (props) => {
  const { helpDeskDomain } = useLayoutContext();
  const history = useHistory()
  const location = useLocation();

  const [isSearchView, setIsSearchView] = useState(false);
  const [viewSharePopUp, setViewSharePopUp] = useState(false);

  const {
    wishListTotal,
    wishList
  } = useCart()

  const right = (
    <Space style={{ "--gap-horizontal": '6px' }}>
      <span className="global_right_icon" onClick={() => setViewSharePopUp(true)}><ShareAltOutlined /> </span>
      <Badge content={wishListTotal ? wishListTotal : wishList?.meta?.total}>
        <span className="global_right_icon" onClick={() => history.push('/wish-list')}><HeartOutline /></span>
      </Badge>
      <Popover.Menu
        actions={[
          { text: 'Home', icon: < AppstoreOutline /> },
          { text: 'My Order', icon: <UnorderedListOutline /> },
          { text: 'Dashboard', icon: <TextOutline /> },
          { text: 'Need Help', icon: <ExclamationCircleOutline /> },
        ]}
        placement='bottomLeft'
        onAction={node => {
          if (node.text === "Home") {
            history.push("/")
          } else if (node.text === "My Order") {
            history.push("/account/orders")
          } else if (node.text === "Dashboard") {
            history.push("/dashboard")
          } else if (node.text === "Need Help" && helpDeskDomain) {
            window.open(helpDeskDomain)
          }
        }}
        trigger='click'
      >
        <span className="global_right_icon"><MoreOutlined /></span>
      </Popover.Menu>
    </Space>
  )

  const left = (
    <Space className="left_nav">
      <div onClick={() => setIsSearchView(true)}>
        <SearchBar placeholder='Please search by keyword' style={{ '--border-radius': '20px', width: '100%' }} />
      </div>
    </Space>
  )

  const backArrow = (
    <span className="global_right_icon"><ArrowLeftOutlined /></span>
  )

  const back = () => history.goBack()

  const handleCopy = (value) => {
    Toast.show({
      content: 'Copied!'
    })
    navigator.clipboard.writeText(value)
  }
  let hostName = process.env.REACT_APP_WEB_DOMAIN || 'https://ali2bd.com';
  let shareUrl = `${hostName}/${encodeURIComponent(location.pathname)}`

  return (
    <>
      <div className="product_details_header" style={{
        backgroundColor: "var(--adm-color-primary)"
      }}>
        <NavBar children={left} right={right} onBack={back} style={{ '--height': props.height, color: props.color }} backArrow={backArrow} />
      </div>

      <Popup
        visible={isSearchView}
        onMaskClick={() => {
          setIsSearchView(false)
        }}
        position='right'
        bodyStyle={{
          height: '180vh',
          width: '100vw'
        }}
        destroyOnClose={true}
      >
        <ProductSearch setIsSearchView={setIsSearchView} isSearchView={isSearchView} />
      </Popup>

      <Popup
        visible={viewSharePopUp}
        onMaskClick={() => {
          setViewSharePopUp(false)
        }}
        bodyStyle={{
          height: '15vh',
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          backgroundColor: "#FFE7D4"
        }}
        destroyOnClose={true}
      >
        <Grid columns={1} gap={8} className="share_grid">
          <Grid.Item>
            <Space block align="center" justify="center">
              <span className="share_grid__title">Share</span>
            </Space>
            {/*<Divider />*/}
          </Grid.Item>
          <Grid.Item>
            <Space block align="center" justify="center">
              <div className="share_image" onClick={() => window.open(`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`)}>
                <Image src="/assets/share/fb.svg" width={50} height={50} fit='contain' />
              </div>
              <div className="share_image" onClick={() => window.open(`https://twitter.com/intent/tweet?url=${shareUrl}`)}>
                <Image src="/assets/share/twitter.svg" width={50} height={50} fit='contain' />
              </div>
              <div className="share_image" onClick={() => handleCopy(window.location.href)}>
                <Image src="/assets/share/link.png" width={50} height={50} fit='contain' />
              </div>
            </Space>
          </Grid.Item>
        </Grid>
      </Popup>
    </>
  )
}

export default ProductDetailsHeaderBack
