import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from "react-router-dom";
import usePayments from "hooks/usePayments";
import { createPayment, preparePayment } from "services/payments";
import { getPaymentGatewaysByOrder } from "app/actions/payments";
import { GlobalHeaderBack, PageHead } from "components";
import { HomePageBlock } from "demos";
import {Button, Grid, Image, Radio, Space, Toast} from "antd-mobile";
import Skeleton from 'react-loading-skeleton';
import GatewayFields from "../GatewayFields";
import qs from "qs";
import { useLayoutContext } from 'context/LayoutContext';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import StripeCheckout from './components/StripeCheckout';

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Payments = () => {

  const dispatch = useDispatch();
  const { cashbackOffBanner } = useLayoutContext();
  const { gateways, gatewayLoading } = useSelector(state => state.payments);
  const [gatewayFields, setGatewayFields] = useState(null);
  const [selectedGateway, setSelectedGateway] = useState(null);
  const [userAmount,] = useState(null);
  const [payLoading, setPayLoading] = useState(false);
  const [methodLoading, setMethodLoading] = useState(false);
  const [stripeClientSecret, setStripeClientSecret] = useState('');
  const [gatewaySessionToken, setGatewaySessionToken] = useState('');
  const [isStripePayentCreated, setIsStripePaymentCreated] = useState(false);

  const { order: orderId } = useParams();
  const history = useHistory()
  const params = qs.parse((window.location.search).substring(1))

  const handleBkashResponse = (data) => {
    if (data?.status === 'error') {
      setPayLoading(false)
    } else if (data?.status === 'success') {
      history.push('/payment/feedback?status=success')
    }
  }

  const { handleBkashCheckout } = usePayments({ handleBkashResponse: handleBkashResponse });

  const getGatewayFields = useCallback((gatewayId) => {
    setMethodLoading(true)
    preparePayment({
      "gateway_id": gatewayId,
      "invoice_type": "Order",
      "invoice_id": orderId
    })
      .then(res => {
        setGatewayFields(res.data.data);
        setMethodLoading(false)
      })
      .catch(({ response }) => {
        setMethodLoading(false)
        if (response.data.message === "Payment time expired!"){
          Toast.show({
            icon: 'fail',
            content: response.data.message +" "+ response?.data?.errors?.invoice_id[0],
          })
        }else {
          Toast.show({
            icon: 'fail',
            content: response.data.message,
          })
        }
      })
  }, [orderId]);

  const getGatewayFieldsByToken = useCallback((data) => {
    setMethodLoading(true)
    preparePayment(data)
      .then(res => {
        setGatewayFields(res.data.data);
        setMethodLoading(false)
      })
      .catch(({ response }) => {
        setMethodLoading(false)
        if (response.data.message === "Payment time expired!"){
          Toast.show({
            icon: 'fail',
            content: response.data.message +" "+ response?.data?.errors?.invoice_id[0],
          })
        }else {
          Toast.show({
            icon: 'fail',
            content: response.data.message,
          })
        }
      })
  }, []);

  const getExtarnalGateway = React.useCallback((module, token, data) => {
    setPayLoading(true)
    let mainData = {
      source: "mobile",
      gateway_id: selectedGateway ? selectedGateway?.id : params.GWI,
      invoice_type: "Order",
      invoice_id: orderId,
    }
    createPayment(mainData)
      .then(res => {
        switch (module) {
          case 'bKashCheckout':
            handleBkashCheckout({
              ...mainData,
              ...res.data
            })
            break;
          case 'Stripe':
            setStripeClientSecret(res.data.data?.stripe_payment_intent?.client_secret)
            setGatewaySessionToken(res.data.data?.token)
            setIsStripePaymentCreated(true);
            break;
          case 'SSLCommerz':
          case 'Nagad':
          case 9:
            window.location.href = res.data.data.url;
            break;
          default:
            break;
        }
      })
      .catch(({ response }) => {
        console.error({ response })
      })
      .finally(() => {
        setPayLoading(false)
      })
  }, [handleBkashCheckout, orderId, params.GWI, selectedGateway]);

  useEffect(() => {
    if (orderId) {
      dispatch(getPaymentGatewaysByOrder(orderId))
    }
  }, [dispatch, orderId])

  useEffect(() => {
    if (params.GWI) {
      let data = {
        source: "mobile",
        gateway_id: parseInt(params.GWI),
        invoice_type: "Order",
        invoice_id: orderId,
      }
      if (data) {
        getGatewayFieldsByToken(data);
      } else {
        getGatewayFields(parseInt(params.GWI));
      }
    }
  }, [getGatewayFields, getGatewayFieldsByToken, orderId, params.GWI, userAmount]);

  useEffect(() => {
    if (gatewayFields?.module === 'Stripe' && orderId && !isStripePayentCreated) {
      const data = { invoice_id: orderId, invoice_type: gatewayFields?.invoice?.type, gateway_id: gatewayFields.id, source: 'desktop' };
      getExtarnalGateway(gatewayFields.module, gatewayFields.token, data)
      setIsStripePaymentCreated(true)
    }
  }, [gatewayFields, orderId, getExtarnalGateway, isStripePayentCreated])


  const handleGatewayChange = (gateway) => {
    setSelectedGateway(gateway);
    setGatewayFields(null);
    if (gateway?.module === "SSLCommerz" || gateway?.module === "bKashCheckout" || gateway?.module === "Nagad") {
      let data = {
        gateway_id: gateway.id,
        source: "mobile",
        invoice_type: "Order",
        invoice_id: orderId,
      }
      getGatewayFieldsByToken(data);
    } else {
      getGatewayFields(gateway.id);
    }
  }

  let filteredCashbacks = cashbackOffBanner?.filter(item => item?.label?.toLowerCase() === 'on');
  let cfBannerLen = filteredCashbacks.length;
  let gridCols = 1;
  let colSpan = 1;
  if (cfBannerLen === 2) {
    gridCols = 2;
  } else if (cfBannerLen === 3) {
    gridCols = 3
  } else if (cfBannerLen === 4) {
    gridCols = 6;
    colSpan = 3;
  } else if (cfBannerLen > 4) {
    gridCols = 6;
    colSpan = 2;
  }

  // Stripe opitons
  const options = {
    clientSecret: stripeClientSecret,
    appearance: {
      theme: 'stripe',
    }
  };

  return (
    <>
      <PageHead title={`Payments`} />

      <div className="payment">
        <GlobalHeaderBack title="Payment Method" height="60px" color="white" />
        {
          !gatewayLoading ?
            <HomePageBlock padding="2% 4%" marginTop="2%">
              <h2>Payment With</h2>
              <div className="payment_tab">
                <Radio.Group
                  defaultValue={parseInt(params.GWI)}
                >
                  <Space block justify="between" wrap>
                    {
                      gateways && gateways.map(gateway => {
                        return (
                          <Radio
                            value={gateway.id}
                            id={`gateway-${gateway.id}`}
                            onChange={() => handleGatewayChange(gateway)}
                            key={`gateway-${gateway.id}`}
                          >
                            <Image src={gateway.icon_url} width={75} height={75} fit='contain' />
                          </Radio>
                        )
                      })
                    }
                  </Space>
                </Radio.Group>
              </div>
            </HomePageBlock>
            :
            <HomePageBlock padding="2% 4%" marginTop="2%">
              <Skeleton count={15} />
            </HomePageBlock>
        }

        {
          cfBannerLen > 0 ?
            <HomePageBlock padding="2% 4%" marginTop="2%">
              <Grid columns={gridCols} gap={6} style={{ marginBottom: '10px' }}>
                {
                  filteredCashbacks.map(item => {
                    return (
                      <Grid.Item
                        span={colSpan}
                        className="chk_cashback_offer"
                        key={`${item.key}`}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}
                      >
                        <img
                          src={item.image}
                          alt="Cashback offer"
                          width={100}
                        />
                      </Grid.Item>
                    )
                  })
                }
              </Grid>
            </HomePageBlock>
            : null
        }

        {
          !methodLoading &&
          <div className="payment-content">
            {
              gatewayFields && <GatewayFields data={gatewayFields} />
            }
            <div className="payment-content__button">
              {
                gatewayFields?.module === "Stripe" && stripeClientSecret ? (
                  <Elements options={options} stripe={stripePromise}>
                    <StripeCheckout gatewayToken={gatewaySessionToken} />
                  </Elements>
                ) :
                  (gatewayFields && gatewayFields.type === 'external') &&
                  <Button block type="submit" loading={payLoading} color="primary" onClick={() => getExtarnalGateway(gatewayFields?.module)}>
                    Pay Now
                  </Button>
              }
            </div>
          </div>
        }

      </div>

    </>
  )
}

export default Payments
