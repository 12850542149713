import React, {useCallback, useEffect, useState} from "react"
import {getMyRequest} from "../services/my-request-client";

const MyRequestContext = React.createContext()

const MyRequestProvider = (props) => {

    const [selectedTab, setSelectedTab] = useState(null);

    const [isFirstCall, setIsFirstCall] = useState({
        requested: true,
        approved: true,
        rejected: true,
        expired: true,
    });

    const [requested, setRequested] = useState({
        data: [],
        meta: null,
    })

    const [approved, setApproved] = useState({
        data: [],
        meta: null,
    })

    const [rejected, setRejected] = useState({
        data: [],
        meta: null,
    })

    const [expired, setExpired] = useState({
        data: [],
        meta: null,
    })

    const [myRequestedLoadings, setRequestedLoadings] = useState({
        isPageLoading: false,
        isButtonLoading: false,
    })

    const [approvedLoadings, setApprovedLoadings] = useState({
        isPageLoading: false,
        isButtonLoading: false,
    })

    const [rejectedLoadings, setRejectedLoadings] = useState({
        isPageLoading: false,
        isButtonLoading: false,
    })

    const [expiredLoadings, setExpiredLoadings] = useState({
        isPageLoading: false,
        isButtonLoading: false,
    })


    const fetchRequested = useCallback(
        (page) => {
            setRequestedLoadings({
                isPageLoading: requested.data.length <= 0,
                isButtonLoading: true
            })
            getMyRequest(page, {status: "requested"}).then((response) => {
                let newData = [...requested.data, ...response.data.data]
                setRequested({
                    data: newData,
                    meta: response.data.meta,
                })
                setRequestedLoadings({
                    isPageLoading: false,
                    isButtonLoading: false,
                })
            }).catch(error => {
                if (error.response.status === 401) {
                    window.location.href = "/auth/login"
                }
                setRequestedLoadings({
                    isPageLoading: false,
                    isButtonLoading: false,
                })
            })
        },
        [setRequested, setRequestedLoadings, requested],
    )

    const fetchApproved = useCallback(
        (page) => {
            setApprovedLoadings({
                isPageLoading: approved.data.length <= 0,
                isButtonLoading: true
            })
            getMyRequest(page, {status: "approved"})
                .then((response) => {
                    let newData = [...approved.data, ...response.data.data]
                    setApproved({
                        data: newData,
                        meta: response.data.meta,
                    })
                    setApprovedLoadings({
                        isPageLoading: false,
                        isButtonLoading: false,
                    })
                }).catch(error => {
                    if (error.response.status === 401) {
                        window.location.href = "/auth/login"
                    }
                    setApprovedLoadings({
                        isPageLoading: false,
                        isButtonLoading: false,
                    })
            })
        },
        [setApproved, setApprovedLoadings, approved],
    )

    const fetchRejected = useCallback(
        (page) => {
            setRejectedLoadings({
                isPageLoading: rejected.data.length <= 0,
                isButtonLoading: true
            })
            getMyRequest(page, {status: "rejected"})
                .then((response) => {
                    let newData = [...rejected.data, ...response.data.data]
                    setRejected({
                        data: newData,
                        meta: response.data.meta,
                    })
                    setRejectedLoadings({
                        isPageLoading: false,
                        isButtonLoading: false,
                    })
                }).catch(error => {
                if (error.response.status === 401) {
                    window.location.href = "/auth/login"
                }
                setRejectedLoadings({
                    isPageLoading: false,
                    isButtonLoading: false,
                })
            })
        },
        [setRejected, setRejectedLoadings, rejected],
    )

    const fetchExpired = useCallback(
        (page) => {
            setExpiredLoadings({
                isPageLoading: expired.data.length <= 0,
                isButtonLoading: true
            })
            getMyRequest(page, {status: "expired"})
                .then((response) => {
                    let newData = [...expired.data, ...response.data.data]
                    setExpired({
                        data: newData,
                        meta: response.data.meta,
                    })
                    setExpiredLoadings({
                        isPageLoading: false,
                        isButtonLoading: false,
                    })
                }).catch(error => {
                    if (error.response.status === 401) {
                        window.location.href = "/auth/login"
                    }
                    setExpiredLoadings({
                        isPageLoading: false,
                        isButtonLoading: false,
                    })
            })
        },
        [setExpired, setExpiredLoadings, expired],
    )

    const handlerSelectedTab = (value) => {
        setSelectedTab(value)
    }

    useEffect(() => {
        if (isFirstCall.requested && selectedTab === "requested") {
            fetchRequested()
            setIsFirstCall({
                ...isFirstCall,
                requested: false,
            })
        }
        if (isFirstCall.approved && selectedTab === "approved") {
            fetchApproved()
            setIsFirstCall({
                ...isFirstCall,
                approved: false,
            })
        }
        if (isFirstCall.rejected && selectedTab === "rejected") {
            fetchRejected()
            setIsFirstCall({
                ...isFirstCall,
                rejected: false,
            })
        }
        if (isFirstCall.expired && selectedTab === "expired") {
            fetchExpired()
            setIsFirstCall({
                ...isFirstCall,
                expired: false,
            })
        }
    }, [
        fetchRequested,
        requested.data,
        isFirstCall.requested,
        fetchApproved,
        approved.data,
        isFirstCall.approved,
        fetchRejected,
        rejected.data,
        isFirstCall.rejected,
        fetchExpired,
        expired.data,
        isFirstCall.expired,
        selectedTab,
        isFirstCall,
        setIsFirstCall,
    ])

    const loadMoreRequested = () => {
        if (requested.meta) {
            fetchRequested((requested.meta.current_page + 1))
        }
    }

    const loadMoreApproved = () => {
        if (approved.meta) {
            fetchApproved((approved.meta.current_page + 1))
        }
    }

    const loadMoreRejected = () => {
        if (rejected.meta) {
            fetchRejected((rejected.meta.current_page + 1))
        }
    }

    const loadMoreExpired = () => {
        if (expired.meta) {
            fetchExpired((expired.meta.current_page + 1))
        }
    }

    return <MyRequestContext.Provider value={{
        requested,
        myRequestedLoadings,
        approved,
        approvedLoadings,
        handlerSelectedTab,
        rejected,
        rejectedLoadings,
        expired,
        expiredLoadings,
        loadMoreRequested,
        loadMoreApproved,
        loadMoreRejected,
        loadMoreExpired
    }} {...props} />
}

const useMyRequest = () => {
    const context = React.useContext(MyRequestContext)
    if (context === undefined) {
        throw new Error(`useMyRequest must be used within a MyRequestProvider`)
    }
    return context
}

export {MyRequestProvider, useMyRequest}
