import React, { useState, useRef } from 'react';
import classnames from 'classnames';
import { useIntersection } from './intersectionObserver';
import './ImageRenderer.scss'

const ImageRenderer = ({ url, thumb, width, height, alt, maxWidth, borderRadius, imageBackground }) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [isInView, setIsInView] = useState(false);
    const imgRef = useRef();
    useIntersection(imgRef, () => {
        setIsInView(true);
    });

    const handleOnLoad = () => {
        setIsLoaded(true);
    };
    return (
        <div
            className="image-container"
            ref={imgRef}
            style={{
                paddingBottom: `${(height / width) * 100}%`,
                width: '100%',
                maxWidth: maxWidth,
                borderRadius: borderRadius,
                backgroundImage: `url(${process.env.PUBLIC_URL + imageBackground})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain'
            }}
        >
            {isInView && (
                <>
                    <img
                        className={classnames('image', 'thumb', {
                            'isLoaded': !!isLoaded
                        })}
                        src={thumb}
                        alt={alt}
                    />
                    <img
                        className={classnames('image', {
                            'isLoaded': !!isLoaded
                        })}
                        src={url}
                        onLoad={handleOnLoad}
                        alt={alt}
                    />
                </>
            )}
        </div>
    );
};

export default ImageRenderer;
