import React, { useEffect, useState } from "react"
import ImageGallery from "../ImageGallery";
import ProductTitleDescription from "../ProductTitleDescription";
import SellerRating from "../SellerRating";
// import ProductShipping from "../ProductShipping";
import ProductSpecificationMeta from "../ProductSpecificationMeta";
import ProductDescription from "../ProductDescription";
import ProductReviews from "../ProductReviews";
import SellerProducts from "../SellerProducts";
import { useDispatch, useSelector } from "react-redux";
import RecentlyViewProduct from "../RecentlyViewProduct";
import { Ellipsis, FloatingBubble } from "antd-mobile";
import Skeleton from "react-loading-skeleton";
import { setRecentlyViewProduct } from "app/actions/userActivity";
import { ProductDetailsHeaderBack, RecommendedProduct } from "components";
import { useProduct } from "context/product-context";
import { ProductDetailsBlock } from "demos";
import AddToCartTabBar from "layout/AddToCartTabBar";
import { UpCircleOutline } from "antd-mobile-icons";
import CampaignTimer from "pages/ProductDetails/components/CampaignTimer";
import { ORDER_NOTICE } from "consts/storage";
import { Link, useHistory } from "react-router-dom";
import { changingProductDetails } from "app/actions/productsDetails";
import { useLayoutContext } from "context/LayoutContext";
import { RenderPromoBanner } from "utils/helper";
import PromoCampaignCarousel from "components/PromoCampaignCarousel/PromoCampaignCarousel";

const ProductDetailComponents = (props) => {
  const { promoBannerAll, campaignPromoSection } = useLayoutContext();
  const { product } = props
  const history = useHistory()
  const dispatchProductLocal = useDispatch()

  const { data, isFailed, refetchCount } = useSelector(state => state.productsDetails)
  const {
    state,
    setIsProductLoading,
    isProductLoading,
    setSelectedShippingDetails,
    isWholesaleProduct
  } = useProduct()
  const [isVisible, setIsVisible] = useState(false);


  useEffect(() => {
    if (state) {
      let makingRecentlyView = {
        link: state?.link,
        image: state?.image,
        price: state?.price?.min,
        title: state?.title,
        id: state?.id,
        stock: state?.stock,
        slug: state?.slug,
        product_code: state?.product_code,
        type: state?.type,
        pivot: state?.pivot,
      }
      dispatchProductLocal(setRecentlyViewProduct(makingRecentlyView));
    }
  }, [dispatchProductLocal, state]);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 500) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  useEffect(() => {
    return () => {
      setIsProductLoading(true);
      setSelectedShippingDetails(null);
    }
  }, [setIsProductLoading, setSelectedShippingDetails])

  if (refetchCount === 0 && !data?.id && isFailed) {
    dispatchProductLocal(changingProductDetails())
    history.push({
      pathname: "/my-request/request",
      search: history.location.search
    })
  }

  const showPromoBanner = promoBannerAll?.visible?.label?.toLowerCase()?.includes('true');

  let filteredPromoCampaigns = campaignPromoSection?.filter(item => item?.label?.toLowerCase() === 'on');

  return (
    <>
      <ProductDetailsHeaderBack title="Back" height="50px" color="white" />

      <ImageGallery />
      {
        state?.campaigns?.length > 0 &&
        <ProductDetailsBlock padding="3% 2.5% 2% 2.5%" background="#7D001E" borderRadius="26px 0 26px 0">
          <CampaignTimer />
        </ProductDetailsBlock>
      }
      {
        showPromoBanner &&
        <ProductDetailsBlock padding="0">
          <RenderPromoBanner Link={Link} promoBanner={promoBannerAll?.horizontal_banner} />
        </ProductDetailsBlock>
      }
      <ProductDetailsBlock padding="3% 2.5%">
        <ProductTitleDescription />
      </ProductDetailsBlock>
      {
        state?.type !== "campaign" && !isWholesaleProduct &&
        <ProductDetailsBlock padding="3% 2.5%" marginTop="1.5%">
          {
            !isProductLoading && ORDER_NOTICE
              ?
              <Ellipsis direction="end" rows={5} content={ORDER_NOTICE} expandText="Read More"
                collapseText="Read Less" className="product_des__notice_des" />
              :
              <Skeleton count={3} />
          }
        </ProductDetailsBlock>
      }
      <ProductDetailsBlock padding="0 2.5%" marginTop="1.5%">
        <ProductSpecificationMeta />
      </ProductDetailsBlock>
      {/* {
        parseFloat(selectedDomesticDetails?.total) >= 0 ?
        <ProductDetailsBlock padding="0 2.5%" marginTop="1.5%">
          <ProductShipping
            selectedDomesticDetails={selectedDomesticDetails}
            isProductPage="productPage"
          />
        </ProductDetailsBlock>
        : null
      } */}

      {
        filteredPromoCampaigns?.length > 0 ?
          filteredPromoCampaigns?.map((campaignQuery) => {
            return (
              <PromoCampaignCarousel campaignQuery={campaignQuery} key={`${campaignQuery.link}`} />
            )
          }) : null
      }

      {
        state?.type !== "campaign" &&
        <ProductDetailsBlock marginTop="2%" padding="2% 2.5%">
          <RecommendedProduct />
        </ProductDetailsBlock>
      }
      {
        product?.seller &&
        <ProductDetailsBlock padding="0 2.5%" marginTop="3%">
          <SellerRating />
        </ProductDetailsBlock>
      }
      {
        product?.seller &&
        <ProductDetailsBlock padding="2% 2.5%" marginTop="3%">
          <SellerProducts product={product} />
        </ProductDetailsBlock>
      }
      {
        state?.type !== "campaign" &&
        <ProductDetailsBlock padding="2% 2.5%" marginTop="3%">
          <ProductReviews />
        </ProductDetailsBlock>
      }
      <ProductDetailsBlock padding="3% 2.5%" marginTop="3%">
        <ProductDescription />
      </ProductDetailsBlock>
      <ProductDetailsBlock marginTop="2%" padding="2% 2.5%">
        <RecentlyViewProduct />
      </ProductDetailsBlock>
      <AddToCartTabBar />

      {
        isVisible &&
        <FloatingBubble
          style={{
            "--initial-position-bottom": "60px",
            "--initial-position-right": "10px",
          }}
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        >
          <UpCircleOutline fontSize={32} />
        </FloatingBubble>
      }
    </>
  )
}

export default ProductDetailComponents
