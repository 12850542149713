import React, {useEffect, useState} from 'react'
import {useProduct} from "context/product-context";
import {Button, Divider, Ellipsis, ErrorBlock, Grid, Image, ImageViewer, Loading, Space} from "antd-mobile";
import './ProductReviews.scss'
import {changeImageHostName, formatDate} from "utils/helper";
import {ReloadOutlined} from "@ant-design/icons";
import {useDispatch, useSelector} from "react-redux";
import {getProductReview} from "app/actions/productsDetailsData";

const ProductReviews = () => {

    const dispatch = useDispatch();
    const { isFetched, data } = useSelector(state => state.productsDetails);
    const {productReviews, isProductReviewsLoading} = useSelector((state) => state.productsDetailsData);
    const {state: productData} = useProduct()
    const [isFirstCall, setIsFirstCall] = useState(true);
    const [viewImageGallery, setViewImageGallery] = useState(false);
    const [selectedImageGalleryImage, setSelectedImageGalleryImage] = useState([]);
    const [showMore, setShowMore] = useState(false);

    useEffect(() => {
        if (isFetched && data?.id && isFirstCall) {
            dispatch(getProductReview({id: data?.id}))
            setIsFirstCall(false)
        }
    }, [isFetched, data?.id, isFirstCall, dispatch])

    useEffect(() => {
      return () => {
        setIsFirstCall(true)
      }
    }, [])

    if (isProductReviewsLoading) {
        return <div className="loading_middle">
            <Loading color="primary"/>
        </div>
    }

    const handleGalleryImage = (value) => {
        let optimizeImage = value?.map(itemImage => changeImageHostName(itemImage, productData?.source?.id))
        setSelectedImageGalleryImage(optimizeImage)
        setViewImageGallery(true)
    }

    const numberOfItems = showMore ? productReviews?.reviews?.length : 2;

    const handleReloadReview = () => {
        dispatch(getProductReview({id: productData.id}))
    }


    return (
        <>
            {
                productReviews?.reviews?.length > 0 ?
                    <>
                        <div className="rating_review">
                            <span
                                className="rating_review__title">Rating & Reviews ({productReviews?.reviews?.length})</span>
                            {
                                productReviews && productReviews?.reviews?.slice(0, numberOfItems)?.map((review, index) => {
                                    return (
                                        <div key={`rating_review${index}`} className="product_rating">
                                            <Grid columns={18} gap={8} className="rating_details">
                                                <Grid.Item span={2}>
                                                    {
                                                        review?.avatar ?
                                                            <Image
                                                                src={review?.avatar}
                                                                width={36}
                                                                height={36}
                                                                fit='cover'
                                                                style={{borderRadius: 32}}
                                                            />
                                                            :
                                                            <Image
                                                                src="/assets/dashboard/review.png"
                                                                width={36}
                                                                height={36}
                                                                fit='cover'
                                                                style={{borderRadius: 32}}
                                                            />
                                                    }
                                                </Grid.Item>
                                                <Grid.Item span={10}>
                                                    <Space direction='vertical'>
                                                        <div className="rating_details__name">{review?.name}</div>
                                                        <div>
                                                            {
                                                                review?.meta?.map((metaItem, index) => {
                                                                    return (
                                                                        <span key={`metaItemValue${index}`}
                                                                              className="rating_details__meta">{metaItem?.key} - {metaItem?.value} , </span>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </Space>
                                                </Grid.Item>
                                                <Grid.Item span={6} style={{textAlign: 'right'}}>
                                                    <span
                                                        className="rating_details__date">{formatDate(review?.datetime)}</span>
                                                </Grid.Item>
                                            </Grid>
                                            <div className="rating_content">
                                                <Ellipsis direction='end' rows={6} content={review?.content}/>
                                            </div>
                                            <div className="rating_image">
                                                {
                                                    review?.images?.map((itemImages, index) => {
                                                        return (
                                                            <Space
                                                                className="rating_view_space"
                                                                key={`ratingImage${index}`}
                                                            >
                                                                <Image
                                                                    src={changeImageHostName(itemImages, productData?.source?.id)}
                                                                    width={50}
                                                                    height={50}
                                                                    fit='cover'
                                                                    className="rating_image__thumb"
                                                                    onClick={() => {
                                                                        handleGalleryImage(review?.images)
                                                                    }}
                                                                />
                                                            </Space>
                                                        )
                                                    })
                                                }
                                            </div>
                                            <Divider/>
                                        </div>
                                    )
                                })
                            }
                            <div className="view_more_customer_order_review">
                                <div onClick={() => setShowMore(!showMore)}> {
                                    showMore
                                        ?
                                        <Divider contentPosition='center'>
                                            <div className="customize_order">
                                                <Image src="/assets/icons/down.svg" width={18} height={18} fit='fill'/>
                                                <span className="customize_order__title">View Less</span>
                                            </div>
                                        </Divider>
                                        :
                                        <Divider contentPosition='center'>
                                            <div className="customize_order">
                                                <Image src="/assets/icons/up.svg" width={18} height={18} fit='fill'/>
                                                <span className="customize_order__title">View More</span>
                                            </div>
                                        </Divider>
                                }
                                </div>
                            </div>
                        </div>

                        <ImageViewer.Multi
                            images={selectedImageGalleryImage}
                            visible={viewImageGallery}
                            defaultIndex={0}
                            onClose={() => {
                                setViewImageGallery(false)
                            }}
                        />
                    </>
                    :
                    <>
                        <Space justify="end" block>
                            <Button color='primary' size="mini" style={{textAlign: 'right'}}
                                    loading={isProductReviewsLoading}
                                    onClick={() => handleReloadReview()}><ReloadOutlined/></Button>
                        </Space>
                        <ErrorBlock status='empty' title="No review found"
                                    description=""/>
                    </>
            }
        </>
    )
}

export default ProductReviews
