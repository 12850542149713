import * as types from 'app/actionTypes/payments'

export const setOrder = (data) => {
  return {
    type: types.SET_ORDER,
    payload: data,
  }
}

export const getPaymentGatewaysByOrder = (orderId) => {
  return {
    type: types.GET_REQUESTED_GATEWAYS_ORDER,
    payload: orderId,
  }
}

export const setGatewayToken = (token) => {
  return {
    type: types.SET_GATEWAY_TOKEN,
    payload: token,
  }
}
