import { call, put, takeEvery, all } from "redux-saga/effects";
import * as types from 'app/actionTypes/payments';
import { getPaymentGatewaysByOrder } from 'services/payments';

const getGatewaysByOrderApi = async (orderid) => {
  return getPaymentGatewaysByOrder(orderid)
    .then(res => res?.data)
    .catch()
}

function* fetchGatewaysByOrder(action) {
  try {
    const data = yield call(() => getGatewaysByOrderApi(action.payload));
    yield put({ type: types.GET_GATEWAYS_ORDER_SUCCESS, gateways: data.gateways })
  }
  catch (e) {
    yield put({ type: types.GET_GATEWAYS_ORDER_FAILED, error: e.message })
  }
}

function* paymentSaga() {
  yield all([
    takeEvery(types.GET_REQUESTED_GATEWAYS_ORDER, fetchGatewaysByOrder),
  ])
}

export default paymentSaga;
