import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import { Button, Result } from "antd-mobile";

export default function StripeCheckout({ gatewayToken }) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage(null);

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);
    const getStripeReturnUrl = (gatewayToken) => {
      let hostDomain;
      if (window?.location?.origin) {
        hostDomain = window.location.origin;
      }
      else if (process.env.NEXT_PUBLIC_NODE_ENV !== 'development') {
        hostDomain = `${process.env.NEXT_PUBLIC_WEB_URI}`
      } else {
        hostDomain = `http://${process.env.NEXT_PUBLIC_SITE_URL}:${process.env.NEXT_PUBLIC_SITE_PORT}`
      }
      // return `${hostDomain}/payment/stripe?payment_id=${gatewayToken}`
      return `${hostDomain}/payment/feedback?payment_id=${gatewayToken}&gateway=stripe`
    }

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: getStripeReturnUrl(gatewayToken),
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  return (
    <form
      id="payment-form"
      onSubmit={handleSubmit}
      className="shadow-md p-6 rounded"
      style={{
        padding: "1.5rem",
        borderRadius: "0.25rem",
        boxShadow: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
        border: '1px solid #efefef',
        marginBottom: '3rem'
      }}
    >
      {/* Show any error or success messages */}
      {message && <div id="payment-message">
      <Result
          status='error'
          description={message}
          style={{color: 'red'}}
        />
      </div>}

      <PaymentElement id="payment-element" />
      <Button
        block
        type="submit"
        id="submit"
        loading={isLoading}
        color="primary"
        disabled={isLoading || !stripe || !elements}
        style={{
          marginTop: "1rem",
        }}
      >
        Pay Now
      </Button>
    </form>
  );
}
