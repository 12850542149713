import client from './api-client'

const getShippingAddress = () => {
    return client.get(`addresses`)
}

const addShippingAddress = (data) => {
    return client.post(`addresses/add`, data)
}

const removeAddress = (id) => {
    return client.delete(`addresses/remove`, {
        params: {id}
    })
}

const editAddress = (data) => {
    return client.patch('addresses/update', data)
}

export {
    getShippingAddress,
    addShippingAddress,
    removeAddress,
    editAddress
}
