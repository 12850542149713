import React, { useReducer, useEffect, useState, useCallback } from 'react'
import { cartReducer } from "./cartReducer"
import { Toast } from "antd-mobile";
import { orderPayment } from "services/payments";
import { loggedInUser } from "services/auth-client";
import {
  addToWishListItem, checkWishListProductExists, deleteFormWishListItem,
  getCart,
  getWishList, placeOrder,
  removeCartItem,
  updateNotes,
  updateQuantity
} from "services/cart-client";
import { ACTIVE_TAB, systemConstants } from "consts/storage";
import { CHECKOUT_ITEM } from "constants/constants";
import { useDispatch, useSelector } from 'react-redux';
import { removeBuyNowItem } from 'app/actions/cart';
import { getMyWallet } from 'services/wallet';
import ReactGA from "react-ga4";

const CartContext = React.createContext()

const CartProvider = (props) => {
  const dispatchRedux = useDispatch();
  const isLoggedIn = loggedInUser();
  const { buyNowItem } = useSelector(state => state.cart);
  const [state, dispatch] = useReducer(cartReducer, {
    charge_type: null,
    charges: [],
    data: [],
    total: 0,
    totalCharges: 0,
    groupedSelectedStatus: {},
    selectedAllStatus: { selected: false, indeterminate: false },
    isChanged: false,
    list: [],
    groupedItems: {},
  });
  const { address: addresses, } = useSelector((state) => state.address);
  const [cartItemList, setCartItemList] = useState([]);
  const [isFirstCall, setIsFirstCall] = useState(true);
  const [isQuantityLoading, setIsQuantityLoading] = useState(false);
  const [checkoutItems, setCheckoutItems] = useState([])
  const [isConfirmPlaceOrderLoading, setIsConfirmPlaceOrderLoading] = useState(false);
  const [isCartItemRemoving, setIsCartItemRemoving] = useState(false);
  const [isCheckoutOutAgreeCheck, setIsCheckoutOutAgreeCheck] = useState(true);

  const [addAdditionalNote, setAddAdditionalNote] = useState(null);
  const [viewAddressList, setViewAddressList] = useState(false);

  const [selectedBillingAddress, setSelectedBillingAddress] = useState(null);
  const [selectedShippingAddress, setSelectedShippingAddress] = useState(null);
  const [addressListSelectedAddress, setAddressListSelectedAddress] = useState(null);
  const [isChangeableAddress, setIsChangeableAddress] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isNoteLoading, setIsNoteLoading] = useState(false);

  const [paymentOption, setPaymentOption] = useState("online-full");
  const [paymentType, setPaymentType] = useState([3]);

  const [viewAliexpressProduct, setViewAliexpressProduct] = useState(false);
  const [wishListTotal, setWishListTotal] = useState(null);

  const [isCartLoading, setIsCartLoading] = useState(true)
  const [isWishListExits, setIsWishListExits] = useState(null);
  const [isWishListFetching, setIsWishListFetching] = useState(false);

  const [isUseWallet, setIsUseWallet] = useState(false);
  const [isUserBalanceLoading, setIsUserBalanceLoading] = useState(false);
  const [walletBalance, setWalletBalance] = useState(null);
  const [isFiltered, setIsFiltered] = useState(false);
  const [, setIsSelectChange] = useState([]);
  const [selectedPaymentType, setSelectedPaymentType] = useState()
  const [wishList, setWishList] = useState({
    data: [],
    meta: null,
  })

  const [isWishListLoading, setIsWishListLoading] = useState({
    isPageLoading: true,
    isButtonLoading: false,
  })

  const cartFilterOptions = [
    { label: 'All', value: 'default' },
    { label: 'Retails', value: 'retails' },
    { label: 'Wholesale', value: 'wholesale' },
  ]
  const [selectedCartGroup, setSelectedCartGroup] = useState(cartFilterOptions[0].value)

  const fetchCart = useCallback(
    () => {
      setIsCartLoading(true)
      getCart()
        .then(res => {
          dispatch({ type: 'SET_EXISTING_CART', payload: res.data })
          setIsFirstCall(false)
          setIsCartLoading(false)
        })
        .catch(error => {
          if (window.location.pathname !== "/auth/login" && error.response.status === 401) {
            window.location.href = "/auth/login"
          }
          setIsFirstCall(false)
          setIsCartLoading(false)
        })
    },
    [],
  )

  const fetchWishList = useCallback(
    (page) => {
      setIsWishListLoading({
        isPageLoading: wishList?.data?.length <= 0,
        isButtonLoading: true
      })
      getWishList(page)
        .then(res => {
          let newData = [...wishList.data, ...res.data.data]
          setWishList({
            data: newData,
            meta: res.data,
          })
          setIsFirstCall(false)

          setIsWishListLoading({
            isPageLoading: false,
            isButtonLoading: false,
          })
        })
        .catch(error => {
          if (window.location.pathname !== "/auth/login" && error.response.status === 401) {
            window.location.href = "/auth/login"
          }
          setIsFirstCall(false)
          setIsWishListLoading({
            isPageLoading: false,
            isButtonLoading: false,
          })
        })
    },
    [setWishList, setIsWishListLoading, wishList],
  )

  const fetchUserBalance = useCallback(
    () => {
      setIsUserBalanceLoading(true)
      getMyWallet()
        .then(res => {
          setWalletBalance(res.data)
          setIsUserBalanceLoading(false)
        })
        .catch(error => {
          setIsUserBalanceLoading(false)
        })
    }, [])

  useEffect(() => {
    if (isFirstCall && isLoggedIn && window.location.pathname !== "/auth/login") {
      fetchCart()
      fetchWishList()
      fetchUserBalance()
      setIsFirstCall(false)
    }
  }, [fetchCart, isFirstCall, fetchWishList, isLoggedIn, fetchUserBalance])

  useEffect(() => {
    const checkoutData = buyNowItem;
    if (checkoutData) {
      setCheckoutItems(checkoutData)
    }
  }, [buyNowItem]);

  const loadMoreWishListItem = () => {
    if (wishList?.meta) {
      fetchWishList((wishList?.meta?.current_page + 1))
    }
  }

  useEffect(() => {
    if (state.data?.length && !isFiltered) {
      setCartItemList(state.data)
    }
  }, [state, isFiltered])

  const handleCartFilter = (typeGroup) => {
    dispatch({ type: 'CLEAR_CART_STATE' })
    let cartItems = [...state.data]
    let filteredItems = [];
    setIsFiltered(true)
    if (typeGroup === 'wholesale') {
      filteredItems = cartItems.filter(item => item.order_type === 'wholesale')
    } else if (typeGroup === 'retails') {
      filteredItems = cartItems.filter(item => item.order_type !== 'wholesale')
    } else {
      filteredItems = cartItems;
    }

    setCartItemList(filteredItems)
    setSelectedCartGroup(typeGroup)
    dispatch({ type: 'SET_FILTERED_TYPE', payload: { type: typeGroup, filteredItems } })
  }

  const preventSelectBothType = ({ selectType, item }) => {
    /**
     * Prevent to select two or more and all for different type of cart item type
     * like Campaign, wholesale, retails from these 2 or more items together
     */
    const hasCamMsg = systemConstants.cartSelectMsg;
    let hasMapAll = {
      campaign: 0,
      wholesale: 0,
      retails: 0,
    }
    let hasMapSelected = {
      campaign: 0,
      wholesale: 0,
      retails: 0,
    }
    let selectedItems = [];
    let listLen = cartItemList.length;

    for (let item of cartItemList) {
      if (item.isSelected) {
        selectedItems.push(item.id);
      }
      if (item?.campaign_product_id) {
        hasMapAll['campaign'] = hasMapAll.campaign + 1;
        if (item?.isSelected) {
          hasMapSelected['campaign'] = hasMapSelected.campaign + 1
        }
      } else if (item?.order_type === 'wholesale') {
        hasMapAll['wholesale'] = hasMapAll.wholesale + 1;
        if (item?.isSelected) {
          hasMapSelected['wholesale'] = hasMapSelected.wholesale + 1
        }
      } else {
        hasMapAll['retails'] = hasMapAll.retails + 1;
        if (item?.isSelected) {
          hasMapSelected['retails'] = hasMapSelected.retails + 1
        }
      }
    }

    let hasMapAllValues = Object.values(hasMapAll);
    if (selectType === 'all') {
      if (!hasMapAllValues?.includes(listLen)) {
        Toast.show({
          content: hasCamMsg,
          position: 'top',
          icon: 'fail'
        })
        return true
      } else {
        return false;
      }
    }
    else if (selectType === 'single' && selectedItems?.length) {
      let restMapSelected = {};
      if (item.order_type !== 'wholesale' && !item?.campaign_product_id) {
        const { retails, ...restProps } = hasMapSelected;
        restMapSelected = { ...restProps };
      } else if (item?.campaign_product_id) {
        const { campaign, ...restProps } = hasMapSelected;
        restMapSelected = { ...restProps };
      } else if (item.order_type === 'wholesale') {
        const { wholesale, ...restProps } = hasMapSelected;
        restMapSelected = { ...restProps };
      }
      let restMapValues = Object.values(restMapSelected);
      let hasTwoPlusType = restMapValues?.filter(item => item > 0)?.length;
      if (hasTwoPlusType > 0) {
        Toast.show({
          content: hasCamMsg,
          position: 'top',
          icon: 'fail'
        })
        return true
      }
    }
  }

  const handleSellerSelect = (isChecked, seller) => {
    if (preventSelectBothType({ selectType: 'single', item: state.groupedItems[seller]?.[0], })) {
      return
    }
    setIsSelectChange((prevState) => {
      if (prevState?.includes(seller)) {
        return prevState.filter(item => item !== seller);
      } else {
        return [...prevState, seller]
      }
    })
    dispatch({ type: 'SELLER_SELECT', payload: { seller, isChecked } })
  }

  const handleSelectAll = (e) => {
    if (preventSelectBothType({ selectType: 'all' })) {
      return
    }

    let filteredItemsID = []
    if (selectedCartGroup !== "default") {
      filteredItemsID = cartItemList.map(item => item.id);
    }

    dispatch({
      type: 'SELECT_ALL', payload: {
        cartItems: cartItemList,
        isChecked: e.target.checked,
        filteredItemsID,
        selectedCartGroup,
      }
    })
  }

  const handleCheckBoxChange = (isChecked, item) => {
    if (preventSelectBothType({ selectType: 'single', item })) {
      return
    }
    dispatch({ type: 'CHECKBOX_CHANGE', payload: { itemId: item.id } })
  }

  const handleQtyChange = (value, item, itemId, type = "retails") => {
    setIsQuantityLoading(true)
    setSelectedProduct(itemId)
    let payload = {
      quantity: value,
    }
    if (type === 'wholesale') {
      payload['meta_id'] = itemId
    } else {
      payload['id'] = itemId
    }
    if (payload) {
      updateQuantity(payload)
        .then(res => {
          payload['price'] = res.data.item.price;
          if (type === 'wholesale') {
            payload['id'] = item?.id
            payload['meta_price'] = res?.data?.item?.meta_price;
          }
          dispatch({ type: 'QTY_CHANGE', payload })
          setIsQuantityLoading(false)
          Toast.show({
            icon: 'success',
            content: res?.data?.message,
          })
        })
        .catch(({ response }) => {
          setIsQuantityLoading(false)
          if (response?.data?.message) {
            Toast.show({
              icon: 'fail',
              content: response.data.message,
            })
          } else {
            Toast.show({
              icon: 'fail',
              content: 'Quantity update failed',
            })
          }
        })
    }
  }

  const handleUpdateNote = (value, itemId, setViewUpdateNote) => {
    setSelectedProduct(itemId)
    setIsNoteLoading(true)
    if (value && itemId) {
      updateNotes(value, itemId)
        .then(res => {
          dispatch({ type: 'UPDATE_NOTE', payload: { itemId: itemId, instructions: value } })
          Toast.show({
            icon: 'success',
            content: res?.data?.message,
          })
          setIsNoteLoading(false)
          setViewUpdateNote(false)
        })
        .catch(err => {
          setIsNoteLoading(false)
          if (err?.response?.data?.message) {
            Toast.show({
              icon: 'fail',
              content: err?.response?.data?.message,
            })
          } else {
            Toast.show({
              icon: 'fail',
              content: 'Note update failed',
            })
          }
        })
    }
  }

  const cartItemRemoved = (ids) => {
    setIsCartItemRemoving(true)
    removeCartItem(ids)
      .then(res => {
        dispatch({ type: 'REMOVE_ITEM', payload: ids })
        setIsCartItemRemoving(false)
        Toast.show({
          icon: 'success',
          content: res?.data?.message,
        })
      })
      .catch(err => {
        setIsCartItemRemoving(false)
        if (err?.response?.data?.message) {
          Toast.show({
            icon: 'fail',
            content: err?.response?.data?.message,
          })
        } else {
          Toast.show({
            icon: 'fail',
            content: 'failed!',
          })
        }
      })
  }

  const handleCheckoutItems = () => {
    const newData = state.data.filter(el => el.isSelected)
    let filterNewData = newData?.filter(el => el.type === "internal")
    let filterNewDataExternal = newData?.filter(el => el.type === "external")
    if (filterNewData?.length > 0) {
      setCheckoutItems(filterNewData)
      if (filterNewData?.length > 0 && filterNewDataExternal?.length > 0) {
        Toast.show({
          icon: 'success',
          content: "You can not select both campaign and general items for checkout, Only campaign items can be checkout",
        })
      }
    } else {
      setCheckoutItems(newData)
    }
  }

  const checkoutTotal = checkoutItems.reduce((acc, value) => acc + (parseFloat(value.price)), 0)?.toFixed(2)
  let walletCurrentBalance = walletBalance && parseFloat(walletBalance?.balance);
  let selectedItemsTotalAmount = checkoutTotal && Number.parseFloat(checkoutTotal)

  let fullyUseWalletBalance = false;
  let partiallyUseWalletBalance = false;
  let restAmountToPay = 0;
  if (walletCurrentBalance >= selectedItemsTotalAmount && isUseWallet) {
    fullyUseWalletBalance = true;
  } else if ((walletCurrentBalance > 0 && walletCurrentBalance < selectedItemsTotalAmount) && isUseWallet) {
    partiallyUseWalletBalance = true;
    restAmountToPay = parseFloat(parseFloat(selectedItemsTotalAmount) - parseFloat(walletCurrentBalance)).toFixed(2);
  }

  const handlePlaceOrder = () => {

    const filterCheckoutItem = checkoutItems.map(item => item.id)

    let checkoutAgree = "yes"

    if (!isCheckoutOutAgreeCheck) {
      checkoutAgree = "no"
    }

    let addressIDs = {}
    let shippingAdr = selectedShippingAddress ?? addresses.defaultShipping
    let billingAdr = selectedBillingAddress ?? addresses.defaultBilling

    if (!shippingAdr) {
      Toast.show({
        icon: 'fail',
        content: "Please select shipping address",
      })
      return;
    }

    let isSameAddress = false;

    if (shippingAdr && billingAdr) {
      isSameAddress = shippingAdr.id === billingAdr.id
    }

    if (isSameAddress) {
      addressIDs['shipping_address_id'] = shippingAdr.id;
    } else {
      addressIDs['shipping_address_id'] = shippingAdr.id;

      if (billingAdr) {
        addressIDs['billing_address_id'] = billingAdr.id;
      }
    }

    let gateWayId = null

    if (paymentType) {
      gateWayId = paymentType[0]
    }

    let data = {
      ids: filterCheckoutItem,
      instructions: addAdditionalNote,
      accept_agreement: checkoutAgree,
      pay_type: paymentOption,
      gateway_id: gateWayId,
      ...addressIDs
    }

    if (isUseWallet && walletCurrentBalance > 0) {
      data['use_balance'] = 'yes'
    }

    if (paymentOption === "cod") {
      data = {
        ids: filterCheckoutItem,
        instructions: addAdditionalNote,
        accept_agreement: checkoutAgree,
        pay_type: paymentOption,
        ...addressIDs
      }
    }

    setIsConfirmPlaceOrderLoading(true)
    placeOrder(data)
      .then(res => {
        setIsConfirmPlaceOrderLoading(false)
        if (res?.status === 200) {
          let order_id = res?.data?.ids[0]
          window.localStorage.removeItem(CHECKOUT_ITEM)
          dispatchRedux(removeBuyNowItem())
          if (isUseWallet && fullyUseWalletBalance) {
            Toast.show({
              icon: 'success',
              content: res?.data?.message,
            })
            window.localStorage.setItem(ACTIVE_TAB, JSON.stringify("all"));
            window.location.href = '/payment/feedback?status=success';
          }
          else if (paymentOption === "cod") {
            Toast.show({
              icon: 'success',
              content: res?.data?.message,
            })
            window.localStorage.setItem(ACTIVE_TAB, JSON.stringify("all"))
            window.location.href = '/payment/cod-feedback?status=success'
          } else {
            orderPayment(order_id)
              .then(res => {
                if (res?.status === 200) {
                  window.location.href = `/payment/${order_id}?PT=${paymentOption}&GWI=${gateWayId}`
                }
              })
              .catch(err => {
                if (err?.response?.data?.message) {
                  Toast.show({
                    icon: 'fail',
                    content: err?.response?.data?.message,
                  })
                } else {
                  Toast.show({
                    icon: 'fail',
                    content: 'failed!',
                  })
                }
              })
          }
        }
        Toast.show({
          icon: 'success',
          content: res?.data?.message,
        })
      })
      .catch((err) => {
        setIsConfirmPlaceOrderLoading(false)
        if (err?.response?.data?.errors) {
          if (err?.response?.data?.errors?.gateway_id) {
            Toast.show({
              icon: 'fail',
              content: "Please select a gateway",
            })
          } else if (err?.response?.data?.errors?.pay_type) {
            Toast.show({
              icon: 'fail',
              content: "Please select payment method",
            })
          } else if (err?.response?.data?.errors?.shipping_address_id || err?.response?.data?.errors?.address) {
            Toast.show({
              icon: 'fail',
              content: err?.response?.data?.errors?.shipping_address_id[0],
            })
          }
        } else if (err?.response?.data?.message) {
          Toast.show({
            icon: 'fail',
            content: err?.response?.data?.message,
          })
        }
      })
  }

  const handleCheckoutAddressSelect = (value, type) => {
    if (type === 'billing') {
      setSelectedBillingAddress(value);
    } else {
      setSelectedShippingAddress(value);
    }
    setAddressListSelectedAddress(value)
    setViewAddressList(false)
  }

  const fetchIsWishListExits = React.useCallback(
    (id) => {
      setIsWishListFetching(true);
      checkWishListProductExists([id])
        .then(res => {
          setIsWishListExits(res.data.items)
          setIsWishListFetching(false)
        })
        .catch(err => {
          setIsWishListFetching(false)
        })
    },
    [],
  )

  const addToWishList = (id, shop) => {
    let data = {
      id: id.toString(),
    }

    if (shop) {
      data = {
        id: id.toString(),
        shop: shop,
      }
    }

    addToWishListItem(data)
      .then(res => {
        fetchIsWishListExits(id)
        setWishListTotal(res?.data?.count)
        ReactGA.event({
          category: 'Button',
          action: 'add_to_wishlist',
          value:res?.data?.total
        });
        Toast.show({
          icon: 'success',
          content: res?.data?.message,
        })
      })
      .catch((err) => {
        if (err.response.status === 401) {
          window.location.href = '/auth/login'
        }
        if (err?.response?.data?.message) {
          Toast.show({
            icon: 'fail',
            content: err?.response?.data?.message,
          })
        } else {
          Toast.show({
            icon: 'fail',
            content: 'failed!',
          })
        }
      })
  }

  const handleDeleteWishList = (id) => {
    deleteFormWishListItem([parseInt(id)])
      .then(res => {
        let newData = wishList.data.filter(item => item.id !== id)
        setWishList({
          ...wishList,
          data: newData
        })
        Toast.show({
          icon: 'success',
          content: res?.data?.message,
        })
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          Toast.show({
            icon: 'fail',
            content: err?.response?.data?.message,
          })
        } else {
          Toast.show({
            icon: 'fail',
            content: 'failed!',
          })
        }
      })
  }

  return <CartContext.Provider value={{
    fetchCart,
    state,
    dispatch,
    wishList,
    isWishListLoading,
    isCartLoading,
    handleSelectAll,
    handleCheckBoxChange,
    handleQtyChange,
    isQuantityLoading,
    checkoutItems,
    handleCheckoutItems,
    handlePlaceOrder,
    isConfirmPlaceOrderLoading,
    cartItemRemoved,
    handleSellerSelect,
    isCartItemRemoving,
    setIsCheckoutOutAgreeCheck,
    isCheckoutOutAgreeCheck,
    setAddAdditionalNote,
    handleCheckoutAddressSelect,
    setViewAddressList,
    viewAddressList,
    loadMoreWishListItem,
    addToWishList,
    handleDeleteWishList,
    setSelectedBillingAddress,
    selectedBillingAddress,
    setSelectedShippingAddress,
    selectedShippingAddress,
    addressListSelectedAddress,
    setIsChangeableAddress,
    isChangeableAddress,
    selectedProduct,
    handleUpdateNote,
    isNoteLoading,
    setViewAliexpressProduct,
    viewAliexpressProduct,
    paymentOption,
    setPaymentOption,
    paymentType,
    setPaymentType,
    setCheckoutItems,
    wishListTotal,
    isWishListExits,
    isWishListFetching,
    fetchIsWishListExits,
    isUseWallet, setIsUseWallet,
    isUserBalanceLoading, setIsUserBalanceLoading,
    walletBalance, setWalletBalance,
    walletCurrentBalance,
    fullyUseWalletBalance,
    partiallyUseWalletBalance,
    restAmountToPay,
    handleCartFilter,
    selectedCartGroup, setSelectedCartGroup,
    cartFilterOptions,
    selectedPaymentType, setSelectedPaymentType
  }} {...props} />
}

const useCart = () => {
  const context = React.useContext(CartContext)
  if (context === undefined) {
    throw new Error(`useCart must be used within a CartProvider`)
  }
  return context
}

export { CartProvider, useCart }
