import React, {useState} from "react"
import './ProfileDetails.scss'
import {Button, DatePicker, Form} from "antd-mobile";
import {useAuth} from "context/auth-context";
import {CloseCircleOutline} from "antd-mobile-icons";
import dayjs from "dayjs";

const BirthDay = (props) => {

    const {setIsViewBirthDay} = props

    const {onFinishProfileUpdate, isProfileUpdating} = useAuth()

    const [pickerVisible, setPickerVisible] = useState(false)

    const minimumDate = new Date (1950, 1, 1)
    const maximumDate = new Date ()

    return (
        <div className="profile_name">
            <div className="profile_info">
                <div>
                    <span className="profile_info__title">BirthDay</span>
                </div>
                <div>
                    <span className="profile_info__icons"
                          onClick={() => setIsViewBirthDay(false)}><CloseCircleOutline/></span>
                </div>
            </div>
            <Form
                onFinish={onFinishProfileUpdate}
                footer={
                    <Button block type='submit' color='primary' loading={isProfileUpdating}>
                        Submit
                    </Button>
                }
                className="profile_form"
                // initialValues={{
                //     dob: dayjs(profileInfo?.dob).format('YYYY-MM-DD'),
                // }}
            >
                <Form.Item
                    name='dob'
                    label='Birthday'
                    trigger='onConfirm'
                    onClick={() => {
                        setPickerVisible(true)
                    }}
                >
                    <DatePicker
                        visible={pickerVisible}
                        onClose={() => {
                            setPickerVisible(false)
                        }}
                        onClick={e => {
                            e.stopPropagation()
                        }}
                        confirmText="Save"
                        cancelText="Cancel"
                        min={minimumDate}
                        // defaultValue={maximumDate}
                        max={maximumDate}
                    >
                        {value =>
                            value ? dayjs(value).format('YYYY-MM-DD') : 'Please select a date'
                        }
                    </ DatePicker>
                </Form.Item>
            </Form>
        </div>
    );
}
export default BirthDay
