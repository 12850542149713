import React from "react"
import "../../Cart/Cart.scss"
import { Divider, Grid, Space } from "antd-mobile";
import ImageRenderer from "utils/ImageRenderer";
import {
  calculateShippingCharges,
  calculateShippingChargesWithTag,
  changeImageHostName,
  findRequestAbleStore,
  getFloatingPrice,
  getShippingLabel,
  getShippingRate,
  ShowPriceWithCurrency
} from "utils/helper";
import { Link } from "react-router-dom";
import { mnv_shipping } from "consts/storage";
import { useLayoutContext } from "context/LayoutContext";
import { useCart } from "context/cart-context";

const CartProductDetails = (props) => {
  const { localeCurrency, defaultShippingUnit, preloaderImage, shippingMethodsMap } = useLayoutContext();
  const { sellerItems } = props
  const {
    state: cartState,
  } = useCart();

  return (
    <>
      <Grid columns={12} gap={24} className="cart_layout seller_layout">
        <Grid.Item span={9}>
          {
            sellerItems[0]?.seller_id && sellerItems[0]?.extras?.seller_type !== "campaign" ?
              <span className="store_title"
              >
                {sellerItems[0]?.seller_name ? sellerItems[0]?.seller_name : "Mixed"}
              </span>
              :
              <span
                className="seller_group">{sellerItems[0]?.seller_name ? sellerItems[0]?.seller_name : "Mixed"}</span>
          }
        </Grid.Item>
      </Grid>
      {
        sellerItems.map((item, idx) => {
          let mvnShip = "Shipping Charges"
          let itemShippingRate = parseFloat(item?.extras?.shipping?.fee)
          if (item?.freight === "mvn_ship_for_me") {
            mvnShip = mnv_shipping
            let isWholesaleProduct = item.order_type === 'wholesale'
            let approxQty = item?.quantity;
            let approxWeight = (item?.extras?.weight?.value || 0) * approxQty;
            itemShippingRate = getShippingRate({ selectedFrCat: item?.shipping, approxWeight, isWholesaleProduct });
            itemShippingRate = parseFloat(itemShippingRate)
          }

          let pathName = "/products/details"

          if (findRequestAbleStore(parseInt(item?.shop_id))) {
            pathName = "/manual-requests"
          } else if (item?.extras?.seller_type === "campaign") {
            pathName = `/products/details`
          }

          let searchParams = `?url=${encodeURIComponent(item?.link)}`

          if (item?.campaign_product_id) {
            searchParams = `?ipid=${item.inv_pid}&campaign_product=${item.campaign_product_id}`
          } else if (item?.product_code) {
            let productSlug = item?.slug || item?.title;
            pathName = `/products/${productSlug}/${item.product_code}`
            searchParams = null;
          }

          let shippingLabel = getShippingLabel({ shippingIdentifier: item?.freight, shippingMethodsMap });

          return (
            <React.Fragment key={`sellerWiseCheckoutProduct${item.id}_${idx}`}>
              <Grid columns={15} gap={10} className="cart_product_layout">
                <Grid.Item span={4} className="cart_product_image">
                  <ImageRenderer
                    key={item?.id}
                    url={changeImageHostName(item?.image, item?.shop_id)}
                    thumb={changeImageHostName(item?.image, item?.shop_id)}
                    width={60}
                    height={60}
                    alt={`CartImage${item?.id}`}
                    borderRadius="8px"
                    imageBackground={preloaderImage ?? "/assets/product/preloader-bg_62X62.png"}
                  />
                </Grid.Item>
                <Grid.Item span={11} className="cart_product_details">
                  <Grid columns={1} gap={2} style={{ "--gap-vertical": "6px" }}>
                    <Grid.Item className="cart_product_details__title">
                      <Link to={{
                        pathname: pathName,
                        search: searchParams,
                        state: {
                          title: item.title,
                          price: item?.price,
                          image: item?.image
                        }
                      }}>
                        <span>{item?.title}</span>
                      </Link>

                    </Grid.Item>
                    <Grid.Item style={{ marginTop: "2%" }}>
                      <Grid columns={1} gap={2} style={{ marginTop: "2%" }}>
                        <Grid.Item>
                          {
                            item?.order_type === 'wholesale' && item?.meta_items?.length
                              ? <Space direction="vertical" style={{ fontSize: '11px', width: '100%', "--gap-vertical": '3px' }}>
                                {
                                  item.meta_items.map(itemSku => {
                                    return (
                                      <Space key={`${itemSku?.cart_item_id}:${itemSku?.id}`} align="center" justify="between" style={{ width: '100%', borderTop: '1px solid #efefef', paddingTop: '3px' }}>
                                        <Space direction="vertical">
                                          {
                                            itemSku?.attrs?.map(skuMeta => {
                                              return (
                                                <div key={`${skuMeta.propId}:${skuMeta.valueId}`} className="text-xs" style={{ maxWidth: '150px', marginBottom: '3px' }}>
                                                  <span>{skuMeta.key}: <strong>{skuMeta.value}</strong></span>
                                                </div>
                                              )
                                            })
                                          }

                                        </Space>
                                        <div>
                                          <div className="price_qty" style={{ marginBottom: '5px' }}>
                                            <strong>Qty: {itemSku?.quantity}</strong>
                                          </div>
                                          {ShowPriceWithCurrency({ price: parseFloat(itemSku.price)?.toFixed(2), currency: localeCurrency })}
                                        </div>
                                      </Space>
                                    )
                                  })
                                }
                              </Space>
                              : item?.attrs?.map((el, index) => {
                                return (
                                  <span key={`productAttrMeta${index}`}
                                    className="product_attr">{el?.value}, </span>
                                )
                              })
                          }
                        </Grid.Item>
                      </Grid>
                    </Grid.Item>
                    <Grid.Item style={{ borderTop: '1px solid #efefef', paddingTop: '6px' }}>
                      <span className="cart_product_details__price">Price: {ShowPriceWithCurrency({ price: getFloatingPrice(item?.price), currency: localeCurrency })}</span>
                    </Grid.Item>
                    <Grid.Item className="cart_qty">
                      <div className="price_qty">
                        <span>{item?.order_type === 'wholesale' && item?.meta_items?.length ? 'Total ' : null}Quantity: {item?.quantity}</span>
                      </div>
                    </Grid.Item>
                  </Grid>
                </Grid.Item>
              </Grid>
              <Divider />
              <Space align="center" justify="between" block>
                {
                  item?.campaign_product_id ?
                    <span className="shipping_charges"> {mvnShip}: {calculateShippingChargesWithTag({ shipping: item?.shipping, rate: itemShippingRate, currency: localeCurrency, defaultShippingUnit, shopId: item?.shop_id })}</span>
                    :
                    <>
                      {
                        item?.freight === "mvn_ship_for_me" ?
                          <span className="shipping_charges">
                            <>
                              {mvnShip}: {calculateShippingCharges({ shipping: item?.shipping, rate: itemShippingRate, currency: localeCurrency, defaultShippingUnit, shopId: item?.shop_id })}
                            </>
                          </span>
                          :
                          <>
                            {
                              item?.extras?.shipping?.identifier &&
                              <span
                                className="shipping_charges">SHIPPING: {item?.frieght_title || shippingLabel}</span>
                            }
                          </>
                      }
                    </>
                }
              </Space>
              <Divider />
            </React.Fragment>
          )
        })
      }
      {
        cartState?.charge_type === 'by_seller' && cartState?.charges?.length > 0 &&
        <Grid columns={12} gap={[10, 5]} style={{ marginTop: 0, paddingBottom: 20, }}>
          {
            cartState?.charges?.map(charge => {
              return (
                <React.Fragment key={charge.type}>
                  <Grid.Item span={6} >
                    <strong>{charge?.label}: </strong>
                  </Grid.Item>
                  <Grid.Item span={6}>
                    <strong>{ShowPriceWithCurrency({ price: getFloatingPrice(charge.amount), currency: localeCurrency })}</strong>
                  </Grid.Item>
                </React.Fragment>
              )
            })
          }
        </Grid>
      }
    </>
  )
}

export default CartProductDetails
