import React from 'react';
import {Divider, Grid} from "antd-mobile";
import Skeleton from 'react-loading-skeleton';
import {DemoBlock} from "../../demos";
import {SliderSkeleton} from "../index";
import GlobalHeaderBack from "../GlobalHeaderBack";

const ManualRequestsSkeleton = () => {

    return (
        <>
            <GlobalHeaderBack title="Manual Request" height="60px" color="white"/>

            <DemoBlock padding="4%" margin="2% 3% 15% 3%" borderRadius="8px">
                <Grid columns={8} gap={20} className="manual_grid">
                    <Grid.Item span={3}>
                        <Skeleton height={120} width={120}/>
                    </Grid.Item>
                    <Grid.Item span={5}>
                        <Skeleton count={1}/>
                        <Skeleton count={1}/>
                        <Skeleton count={1}/>
                        <Skeleton count={1}/>
                        <Skeleton count={1}/>
                        <Skeleton count={1}/>
                    </Grid.Item>
                    <Grid.Item span={8}>
                        <Skeleton height={150} width={'100%'}/>
                    </Grid.Item>

                    <Grid.Item span={8}>
                        <Grid columns={11} gap={0} className="re_product_grid">
                            <Grid.Item span={4}>
                                <Skeleton count={1}/>
                            </Grid.Item>
                            <Grid.Item span={7}>
                                <Divider contentPosition='right'>
                                    <Skeleton count={1}/>
                                </Divider>
                            </Grid.Item>
                        </Grid>
                        <SliderSkeleton />
                    </Grid.Item>
                    <Grid.Item span={8}>
                        <Skeleton height={80} width={'100%'}/>
                    </Grid.Item>
                    <Grid.Item span={8}>
                        <Skeleton count={1}/>
                        <Skeleton count={1}/>
                        <Skeleton count={1}/>
                    </Grid.Item>
                </Grid>
            </DemoBlock>
        </>
    );
}

export default ManualRequestsSkeleton;
