import * as Types from 'app/actionTypes/internalProducts';
import produce from "immer";

const initialState = {
  data: null,
  loading: true,
}

export default function internalProducts(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.GET_INTERNAL_SEARCH_REQUESTED:
        draft.loading = true
        break
      case Types.GET_INTERNAL_SEARCH_SUCCESS:
        draft.data = action.data
        draft.loading = false
        break
      case Types.GET_INTERNAL_SEARCH_FAILED:
        draft.loading = false
        break
      case Types.RESET_INTERNAL_SEARCH:
        draft.loading = state.loading
        break
      default:
        return state
    }
  })
}
