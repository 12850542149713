import React from 'react';
import { HomePageBlock } from "demos";
import Profile from "./components/Profile";
import MyOrderLists from "./components/MyOrderLists";
import './dashboard.scss'
import ProfileDetailsEdit from "./components/ProfileDetailsEdit";
import { PageHead } from "components";
import OurServices from "pages/Dashboard/components/OurServices";
import HomePageBuilder from 'pages/HomePageCraft/HomePageBuilder';
import { RenderPromoBanner } from 'utils/helper';
import { useLayoutContext } from 'context/LayoutContext';
import { Link } from 'react-router-dom';

const Dashboard = () => {
  const { promoBannerAll } = useLayoutContext();
  const showPromoBanner = promoBannerAll?.visible?.label?.toLowerCase()?.includes('true');

  return (
    <>
      <PageHead title={`Dashboard`} />

      <div className="profile">
        <HomePageBlock padding="0%">
          <Profile />
        </HomePageBlock>
        {
          showPromoBanner &&
          <HomePageBlock padding="5px" borderRadius="10px" marginBottom="10px">
            <RenderPromoBanner
              Link={Link}
              promoBanner={promoBannerAll?.horizontal_banner}
              borderRadius="5px"
            />
          </HomePageBlock>
        }
        <HomePageBlock borderRadius="10px" padding="2%">
          <MyOrderLists />
        </HomePageBlock>
        {/*<HomePageBlock borderRadius="10px" marginTop="2%">*/}
        {/*    <OrderStatus/>*/}
        {/*</HomePageBlock>*/}
        <HomePageBlock borderRadius="10px" marginTop="2%" padding="2px 2px">
          <ProfileDetailsEdit />
        </HomePageBlock>
        <HomePageBlock borderRadius="10px" marginTop="2%">
          <OurServices />
        </HomePageBlock>
        <HomePageBlock borderRadius="10px" marginTop="2%" padding="2px 2px">
          <HomePageBuilder />
        </HomePageBlock>
      </div>
    </>
  );
}

export default Dashboard;
