import { setBuilderSectionData } from "app/actions/builder";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCampaignDetails } from "services/campaign-client";
import { getKeywordsSearch } from "services/product-client";

const useBuilder = (widgetProps) => {
  const dispatch = useDispatch();
  const { sections: sectionsData } = useSelector(state => state.builder);
  const [products, setProducts] = useState(null);
  const [productsLoading, setProductsLoading] = useState(true);
  const [isFetchedFirst, setIsFetchedFirst] = useState(false);
  const [fetchedProducts, setFetchedProducts] = useState(null);

  const getProductsFromApi = useCallback((params = {}) => {
    setProductsLoading(true);
    if (params?.campaign && !params?.keyword) {
      getCampaignDetails(params.campaign)
        .then(res => {
          const { data, campaign} = res.data;
          let resData = {items: data, campaign}
          setFetchedProducts(resData)
          if (!isFetchedFirst) {
            setIsFetchedFirst(true)
          }
        })
        .catch(({ response }) => {
          if (response?.data) {
            // toastErrors(response.data)
          }
        })
        .finally(() => {
          setProductsLoading(false);
        })
    } else {
      getKeywordsSearch(params)
        .then(res => {
          setFetchedProducts(res.data)
          if (!isFetchedFirst) {
            setIsFetchedFirst(true)
          }
        })
        .catch(({ response }) => {
          if (response?.data) {
            // toastErrors(response.data)
          }
        })
        .finally(() => {
          setProductsLoading(false);
        })
    }
  }, [isFetchedFirst])

  const fetchProducts = useCallback((params = {}, isInfinity = null) => {
    if (widgetProps?.nodeID && isInfinity) {
      getProductsFromApi(params)
    }
    else if (widgetProps?.nodeID && !sectionsData?.[widgetProps?.nodeID]) {
      getProductsFromApi(params)
    }
  }, [widgetProps?.nodeID, sectionsData, getProductsFromApi])

  useEffect(() => {
    if (widgetProps?.nodeID && widgetProps?.infinity && fetchedProducts) {
      dispatch(setBuilderSectionData(widgetProps.nodeID, fetchedProducts, widgetProps.infinity))
    }
    else if (widgetProps?.nodeID && fetchedProducts) {
      dispatch(setBuilderSectionData(widgetProps.nodeID, fetchedProducts))
    }
  }, [fetchedProducts, widgetProps?.nodeID, widgetProps?.infinity, dispatch])

  useEffect(() => {
    if (widgetProps?.nodeID && sectionsData?.[widgetProps?.nodeID]) {
      setProducts(sectionsData?.[widgetProps.nodeID]);
      if (!isFetchedFirst) {
        setIsFetchedFirst(true)
      }
    }
  }, [sectionsData, widgetProps?.nodeID, isFetchedFirst])

  return {
    products,
    productsLoading,
    setProductsLoading,
    fetchProducts,
    isFetchedFirst,
  }
}

export default useBuilder;
