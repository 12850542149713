import React, {useState} from 'react'
import {Grid, Popover, Popup, SearchBar} from "antd-mobile";
import {
    ArrowLeftOutlined,
    MoreOutlined,
} from "@ant-design/icons";
import './SellerSearchHeader.scss'
import ProductSearch from "../../pages/ProductSearch";
import {AppstoreOutline, ExclamationCircleOutline, TextOutline, UnorderedListOutline} from "antd-mobile-icons";
import {useHistory} from "react-router-dom";
import { useLayoutContext } from 'context/LayoutContext';

const SellerSearchHeader = () => {
    const { helpDeskDomain } = useLayoutContext();
    const [isSearchView, setIsSearchView] = useState(false);
    const history = useHistory()

    return (
        <>
            <Grid columns={14} gap={2} className="header-back">
                <Grid.Item span={1}>
                    <span className="header-back__icons" onClick={() => window.history.back()}><ArrowLeftOutlined /></span>
                </Grid.Item>
                <Grid.Item span={12} onClick={() => setIsSearchView(true)}>
                    <SearchBar placeholder='Please enter product title'/>
                </Grid.Item>
                <Grid.Item span={1}>
                    <Popover.Menu
                        actions={[
                            { text : 'Home' , icon : < AppstoreOutline /> },
                            { text : 'My Order' , icon : <UnorderedListOutline /> },
                            { text : 'Dashboard' , icon : <TextOutline/> },
                            { text : 'Need Help' , icon : <ExclamationCircleOutline/> },
                        ]}
                        placement='bottomLeft'
                        onAction = { node => {
                            if (node.text === "Home"){
                                history.push("/")
                            }else if (node.text === "My Order"){
                                history.push("/account/orders")
                            }else if (node.text === "Dashboard"){
                                history.push("/dashboard")
                            }else if (node.text === "Need Help" && helpDeskDomain){
                                window.open(helpDeskDomain)
                            }
                        }}
                        trigger='click'
                    >
                        <span className="header-back__icons"><MoreOutlined /></span>
                    </Popover.Menu>
                </Grid.Item>
            </Grid>

            <Popup
                visible={isSearchView}
                onMaskClick={() => {
                    setIsSearchView(false)
                }}
                position='right'
                bodyStyle={{
                    height: '180vh',
                    width: '100vw'
                }}
                destroyOnClose={true}
            >
                <ProductSearch setIsSearchView={setIsSearchView} isSearchView={isSearchView}/>
            </Popup>
        </>
    )
}

export default SellerSearchHeader
