import React from 'react'
import {PageHead} from "components";
import CheckoutDetails from "./components/CheckoutDetails";

const Cart = () => {

    return (
        <>
            <PageHead title={`Checkout`}/>
            <CheckoutDetails/>
        </>
    )
}

export default Cart
