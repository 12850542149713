import { NoticeBar } from 'antd-mobile';
import { defaultKeys } from 'consts/storage';
import { useLayoutContext } from 'context/LayoutContext';
import React from 'react';
import { getMyOrders } from 'services/my-order-client';

const DeliverableNotice = ({ items }) => {
  const { notifyMessages } = useLayoutContext();
  const [dvoItems, setDvoItems] = React.useState(0);
  let notice = notifyMessages?.[defaultKeys.deliverableNotifyMsg]?.label;

  React.useEffect(() => {
    if (!items) {
      getMyOrders(null, {
        productStatus: 'received_in_country'
      }
      ).then((resp) => {
        if (resp?.data?.data?.length) {
          setDvoItems(resp.data.data.length)
        }
      })
        .catch(({ response }) => {
          console.error({ response })
        })
    } else if (items?.length) {
      setDvoItems(items?.length)
    }
  }, [items])

  if (dvoItems <= 0) {
    return null;
  }

  return (
    <div className="bg-primary text-white px-4 py-2 rounded">
      <NoticeBar
        content={notice ?? <p>You may have deliverable items that must be requested for delivery. If you have paid, please create a delivery request in the <strong>Deliverable Orders</strong> tab.</p>
        }
        color='error'
        className="notice"
        closeable
        speed={40}
        style={{fontSize: 16, marginTop: 5, color: 'white'}}
      />

    </div>
  )
}

export default DeliverableNotice;
