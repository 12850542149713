import * as type from 'app/actionTypes/system';
import produce from "immer";

const initialState = {
  system: null,
  loading: true,
  error: null,
  district: null,
  districtLoading: true,
  categories: {
    data: null,
    loading: true,
    isFetched: false,
    error: null,
  },
  configs: {
    data: null,
    loading: false,
    isFeched: false,
    error: null
  },
  countries: {
    data: null,
    loading: false,
    isFeched: false,
    error: null
  },
  currencies: {
    data: null,
    loading: false,
    isFeched: false,
    error: null
  },
  languages: {
    data: null,
    loading: false,
    isFeched: false,
    error: null
  }
}

export default function system(state = initialState, action) {
  const { payload } = action;
  return produce(state, draft => {
    switch (action.type) {
      case type.GET_POST_CODE_REQUEST:
        draft.loading = true
        break
      case type.GET_POST_CODE_SUCCESS:
        draft.system = action.items
        draft.loading = false
        break
      case type.GET_POST_CODE_FAILED:
        draft.loading = false
        draft.error = action.items
        break
      case type.GET_DISTRICT_REQUEST:
        draft.districtLoading = true
        break
      case type.GET_DISTRICT_SUCCESS:
        draft.district = action.items
        draft.districtLoading = false
        break
      case type.GET_DISTRICT_FAILED:
        draft.districtLoading = false
        draft.error = action.items
        break
      case type.GET_CATEGORIES_REQUESTED:
        draft.categories.loading = true;
        break;
      case type.GET_CATEGORIES_SUCCEEDED:
        draft.categories.data = payload;
        draft.categories.loading = false;
        draft.categories.isFetched = true;
        break;
      case type.GET_CATEGORIES_FAILED:
        draft.categories.loading = false;
        draft.categories.isFetched = false;
        draft.categories.error = payload;
        break;
      case type.GET_CONFIGS_REQUESTED:
        draft.configs.loading = true;
        draft.configs.isFeched = false;
        break;
      case type.GET_CONFIGS_SUCCEEDED:
        draft.configs.data = payload.data;
        draft.configs.isFeched = true;
        draft.configs.loading = false;
        break;
      case type.GET_CONFIGS_FAILED:
        draft.configs.isFeched = false;
        draft.configs.loading = false;
        break;

      case type.GET_COUNTRIES_REQUESTED:
        draft.countries.loading = true;
        draft.countries.isFeched = false;
        break;
      case type.GET_COUNTRIES_SUCCEEDED:
        draft.countries.data = payload.countries;
        draft.countries.isFeched = true;
        draft.countries.loading = false;
        break;
      case type.GET_COUNTRIES_FAILED:
        draft.countries.isFeched = false;
        draft.countries.loading = false;
        break;

      case type.GET_CURRENCIES_REQUESTED:
        draft.currencies.loading = true;
        draft.currencies.isFeched = false;
        break;
      case type.GET_CURRENCIES_SUCCEEDED:
        draft.currencies.data = payload.currencies;
        draft.currencies.isFeched = true;
        draft.currencies.loading = false;
        break;
      case type.GET_CURRENCIES_FAILED:
        draft.currencies.isFeched = false;
        draft.currencies.loading = false;
        break;

      case type.GET_LANGUAGES_REQUESTED:
        draft.languages.loading = true;
        draft.languages.isFeched = false;
        break;
      case type.GET_LANGUAGES_SUCCEEDED:
        draft.languages.data = payload.languages;
        draft.languages.isFeched = true;
        draft.languages.loading = false;
        break;
      case type.GET_LANGUAGES_FAILED:
        draft.languages.isFeched = false;
        draft.languages.loading = false;
        break;
      default:
        return state
    }
  })
}
