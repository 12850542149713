import React from "react";
import {Swiper} from "antd-mobile";
import Skeleton from "react-loading-skeleton";
import {HomePageBlock} from "demos";
import OrderSliderSkeleton from "components/OrderSliderSkeleton";

const HomepageSkeleton = () => {

    return (
        <HomePageBlock padding="3% 4%">
            <Swiper autoplayInterval={3000} loop={true} autoplay={true}>
                <Swiper.Item className="swiper-images-homepage">
                    <div className="product_details_image_homepage">
                        <div className="product_details_image_homepage__thumb">
                            <Skeleton height={115}/>
                        </div>
                    </div>
                </Swiper.Item>
            </Swiper>
            <HomePageBlock marginTop="2%" padding={0}>
                <OrderSliderSkeleton/>
            </HomePageBlock>
            <HomePageBlock marginTop="2%" padding={0}>
                <OrderSliderSkeleton/>
            </HomePageBlock>
            <HomePageBlock marginTop="2%" padding={0}>
                <OrderSliderSkeleton/>
            </HomePageBlock>
        </HomePageBlock>
    );
}

export default HomepageSkeleton;
