import React, { useCallback, useEffect, useState } from "react"
import './ProfileDetails.scss'
import {
  Button,
  Checkbox,
  Form,
  Input, NoticeBar, Picker,
  Selector, TextArea,
  Toast
} from "antd-mobile";
import { CloseCircleOutline } from "antd-mobile-icons";
import { CheckSquareFilled, CheckSquareOutlined } from "@ant-design/icons";
import { addShippingAddress, editAddress } from "services/address-client";
import useErrors from "hooks/useErrors";
import { useAuth } from "context/auth-context";
import { LOCAL_STORAGE_USER } from "constants/constants";
import { getAllAddress } from "app/actions/address";
import { useDispatch, useSelector } from "react-redux";
import useFilters from "hooks/useFilters";
import { getAllDistrict, getPostCode } from "app/actions/system";
import useDebounce from "hooks/useDebounce";
import { useLayoutContext } from "context/LayoutContext";
import usePhone from "hooks/usePhone";

const AddShippingAddress = (props) => {
  const { localeCountry, countriesState } = useLayoutContext();
  const dispatch = useDispatch();
  const { system } = useSelector((state) => state.system);
  const [form] = Form.useForm();
  const { setViewAddAddress,
    isAddressEdit,
    editAddressValue,
    isDefault,
    componentFrom,
    setViewAddressList
  } = props
  const { getErrors } = useErrors();
  const { getUsername } = useAuth();
  const {
    isFirstCall,
    isFetched,
  } = useFilters();
  const {
    selectedCountry, setSelectedCountry,
    countryPhoneStates: states,
    // states: phoneStates,
    // selectedState: selectedPhoneState, setSelectedState: setSelectedPhoneState,
  } = usePhone();

  const [isShippingAddLoading, setIsShippingAddLoading] = useState(false);
  const [postCodeValue, setPostCodeValue] = useState('');
  const debouncedSearchTerm = useDebounce(postCodeValue, 1000);
  const [pickerVisible, setPickerVisible] = useState(false);
  const [showCountryPicker, setShowCountryPicker] = useState(false);
  const profileInfo = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER))
  const [, setPhoneValue] = useState({ preValue: '+880', realValue: '' })
  const [isFilledFirst, setIsFilledFirst] = useState(false);
  const [isChecked, setIsChecked] = useState(true);

  useEffect(() => {
    if (editAddressValue?.country && countriesState?.data?.length) {
      let desiredCountry = countriesState.data.find(c => c.code === editAddressValue.country);
      setSelectedCountry(desiredCountry)
    }
  }, [countriesState, editAddressValue, setSelectedCountry])

  useEffect(() => {
    let filterData = {
      country: "BD",
      postal: debouncedSearchTerm
    }
    if (!isFetched && filterData && debouncedSearchTerm) {
      dispatch(getPostCode(filterData));
    }
    if (!isFetched && isFirstCall) {
      dispatch(getAllDistrict({ country: "BD" }));
    }

  }, [dispatch, isFirstCall, isFetched, debouncedSearchTerm]);

  useEffect(() => {
    if (!isFilledFirst && editAddressValue) {
      setIsFilledFirst(true);
      if (editAddressValue?.postal_code) {
        form.setFieldsValue({ postal_code: editAddressValue?.postal_code });
      }
      if (editAddressValue?.country) {
        form.setFieldsValue({ country: [editAddressValue?.country] });
      }
      if (editAddressValue?.state) {
        form.setFieldsValue({ state: [editAddressValue?.state] });
      }
      if (editAddressValue?.city) {
        form.setFieldsValue({ city: editAddressValue?.city });
      }

      form.setFieldsValue({ first_name: editAddressValue?.first_name });
      form.setFieldsValue({ last_name: editAddressValue?.last_name });
      form.setFieldsValue({ email: editAddressValue?.email });
      if (editAddressValue?.phone) {
        form.setFieldsValue({ phone: editAddressValue?.phone });
      }
      form.setFieldsValue({ address: editAddressValue?.address ? editAddressValue?.address : '' });
      form.setFieldsValue({ company: editAddressValue?.company ? editAddressValue?.company : '' });
      form.setFieldsValue({ type: editAddressValue?.type ? editAddressValue?.type : '' });
      if (editAddressValue?.default_shipping || editAddressValue?.default_billing) {
        setIsChecked(true);
      } else {
        setIsChecked(false);
      }
    } else if (!isFilledFirst && profileInfo) {
      setIsFilledFirst(true);
      form.setFieldsValue({ first_name: profileInfo?.first_name || '' });
      form.setFieldsValue({ last_name: profileInfo?.last_name || '' });
      form.setFieldsValue({ email: profileInfo?.email || '' });
      form.setFieldsValue({ phone: profileInfo?.mobile || '' });
    }
  }, [form, editAddressValue, profileInfo, isFilledFirst]);

  useEffect(() => {
    if (system) {
      let desiredCountry = countriesState.data.find(c => c.name === system.country);
      if (desiredCountry?.code) {
        setSelectedCountry(desiredCountry)
        form.setFieldsValue({ country: [desiredCountry.code] })
      }
      form.setFieldsValue({ state: [system.state] })
      form.setFieldsValue({ city: system.city })
    }
  }, [system, form, countriesState, setSelectedCountry])

  useEffect(() => {
    if (selectedCountry?.code) {
      form.setFieldsValue({ country: [selectedCountry.code] });
    }
  }, [selectedCountry, form])

  const getPhoneWithoutCode = useCallback((number) => {
    let preValue = '';
    let realValue = '';
    let callingCode = selectedCountry?.calling;
    let callingCodeLen = callingCode?.length;
    if (number?.startsWith(callingCode)) {
      preValue = number.slice(0, callingCodeLen);
      realValue = number?.slice(callingCodeLen)
    } else {
      realValue = number;
    }
    return { preValue, realValue };
  }, [selectedCountry?.calling])

  useEffect(() => {
    if (editAddressValue?.phone) {
      let phoneNumberValue = getPhoneWithoutCode(editAddressValue?.phone);
      setPhoneValue(phoneNumberValue)
    }
  }, [editAddressValue, selectedCountry?.calling, getPhoneWithoutCode])

  useEffect(() => {
    return () => {
      form.resetFields();
      setIsChecked(true);
    }
  }, [form])

  const viewSelectedAddressType = [
    {
      label: "Shipping",
      value: 'shipping'
    },
    {
      label: "Billing",
      value: 'billing'
    },
    {
      label: "Shipping & Billing",
      value: 'both'
    },
  ]


  const onFinishShipping = (value) => {
    let phoneNumber = getUsername(value.phone, localeCountry)

    let shippingFormData = {
      first_name: value.first_name,
      last_name: value.last_name,
      email: value.email,
      phone: phoneNumber,
      country: value?.country?.length > 0 ? value?.country?.[0] : 'BD',
      state: value.state?.length > 0 ? value.state[0] : null,
      address: value.address,
      company: value.company,
      house: value.house,
      type: value?.type ? value?.type[0] : null,
      is_default: isChecked || (isDefault ? true : false),
    }
    if (shippingFormData?.country === 'BD') {
      shippingFormData = {
        ...shippingFormData,
        city: value.city,
        postal_code: value.postal_code,
      }
    }

    if (isAddressEdit) {

      let type = value?.type

      if (Array.isArray(value?.type)) {
        type = value?.type[0]
      }

      shippingFormData = {
        first_name: value.first_name,
        last_name: value.last_name,
        email: value.email,
        phone: phoneNumber,
        country: "BD",
        city: value.city,
        state: value.state?.length > 0 ? value.state[0] : null,
        address: value.address,
        postal_code: value.postal_code,
        company: value.company,
        house: value.house,
        type: value?.type ? type : editAddressValue.type,
        is_default: isChecked || (isDefault ? true : false),
        id: editAddressValue.id ? editAddressValue.id : null
      }
    }

    setIsShippingAddLoading(true)
    if (isAddressEdit) {
      editAddress(shippingFormData)
        .then(res => {
          dispatch(getAllAddress())
          // dispatch(updateAddress(shippingFormData.id, { ...shippingFormData }))
          setIsShippingAddLoading(false)
          Toast.show({
            content: res?.data?.message,
          })
          setViewAddAddress(false)
        })
        .catch(err => {
          if (err?.response?.data?.errors) {
            form.setFields(getErrors(err.response.data.errors));
          } else if (err?.response?.data?.message) {
            Toast.show({
              icon: 'fail',
              content: err?.response?.data?.message,
            })
          } else {
            Toast.show({
              icon: 'fail',
              content: 'failed!',
            })
          }
          setIsShippingAddLoading(false)
        })
    } else {
      addShippingAddress(shippingFormData)
        .then(res => {
          dispatch(getAllAddress())
          setIsShippingAddLoading(false)
          Toast.show({
            content: res.data?.message || 'Success',
          })
          setViewAddAddress(false)
          if (componentFrom === 'checkout') {
            setViewAddressList();
          }
        })
        .catch(({response}) => {
          if (response.data.errors) {
            form.setFields(getErrors(response.data.errors));
          } else if (response.data.message) {
            Toast.show({
              icon: 'fail',
              content: response.data.message,
            })
          } else {
            Toast.show({
              icon: 'fail',
              content: 'failed!',
            })
          }
          setIsShippingAddLoading(false)
        })
    }
  }

  let makeCountriesOptionData = countriesState?.data?.length && countriesState?.data?.filter(c => c.code === localeCountry?.code)?.map(el => {
    return {
      value: el.code,
      label: el.name,
    }
  })

  return (
    <div className="profile_name">
      <div className="profile_info">
        <div>
          <span className="profile_info__title">Add New Address</span>
        </div>
        <div>
          <span className="profile_info__icons" onClick={() => setViewAddAddress(false)}><CloseCircleOutline /></span>
        </div>
      </div>
      <Form
        onFinish={onFinishShipping}
        footer={
          <Button block type='submit' color='primary' loading={isShippingAddLoading}>
            Submit
          </Button>
        }
        className="shipping_address"
        form={form}
      >
        <div className="address_form_name">
          <Form.Item
            name='first_name'
            label='First Name'
            rules={[{ required: true, message: 'Please enter your first name' }]}
          >
            <Input placeholder='Please enter your first name' autoComplete="chrome-off" />
          </Form.Item>
          <Form.Item
            name='last_name'
            label='Last Name'
            rules={[{ required: true, message: 'Please enter your last name' }]}
          >
            <Input placeholder='Please enter your last name' autoComplete="chrome-off" />
          </Form.Item>
        </div>
        <Form.Item
          name='country'
          label='Country'
          rules={[{ required: true, message: 'Please enter your country name' }]}
          trigger='onConfirm'
          onClick={() => {
            setShowCountryPicker(true)
          }}
        >
          {
            makeCountriesOptionData?.length && !countriesState?.loading &&
            <Picker
              columns={[makeCountriesOptionData]}
              visible={showCountryPicker}
              onClose={() => {
                setShowCountryPicker(false)
              }}
              onConfirm={(val, extend) => {
                let desiredCountry = countriesState.data.find(c => c.code === val[0]);
                setSelectedCountry(desiredCountry)
              }}
              cancelText='Cancel'
              confirmText='Confirm'
            >
              {items => {
                if (items.every(item => item === null)) {
                  return 'Not selected'
                } else {
                  return items.map(item => item?.label ?? 'Not selected')
                }
              }}
            </Picker>
          }
        </Form.Item>
        <Form.Item
          name='phone'
          label='Phone'
          rules={[{ required: true, message: 'Please enter your phone number with country code ex. +971 for UAE, +880 for BD etc.' }]}
        >
          <Input placeholder='Phone number with country code' autoComplete="chrome-off" />
        </Form.Item>
        <Form.Item
          name='email'
          label='Email'
          rules={[{ message: 'Please enter your Email Address' }]}
        >
          <Input placeholder='Please enter your email address' autoComplete="chrome-off" />
        </Form.Item>
        {
          (selectedCountry?.code || localeCountry?.code) === 'BD' &&
          <>
            <NoticeBar content='To get your district and upazila please write your correct post code' color='info' className="notice" />
            <Form.Item
              name='postal_code'
              label='Zip/Postal Code'
              rules={[{ required: true, message: 'Please enter your zip code' }]}
            >
              <Input
                autoComplete="chrome-off"
                placeholder='Please enter your postal code'
                onChange={val => {
                  setPostCodeValue(val)
                }} />
            </Form.Item>
          </>
        }
        <Form.Item
          name='state'
          label={(selectedCountry?.code || localeCountry?.code) === 'BD' ? 'District' : 'State'}
          rules={[{ required: true, message: `Please enter your ${(selectedCountry?.code || localeCountry?.code) === 'BD' ? 'District' : 'State'} name` }]}
          trigger='onConfirm'
          onClick={() => {
            setPickerVisible(true)
          }}
        >
          {
            states?.length > 0 &&
            <Picker
              columns={[states]}
              // onConfirm={(val, extend) => {
              //   let desiredState = states.find(c => c.value === val[0]);
              //   setSelectedCountry(desiredState)
              // }}
              visible={pickerVisible}
              onClose={() => {
                setPickerVisible(false)
              }}
              cancelText='Cancel'
              confirmText='Confirm'
            >
              {items => {
                if (items.every(item => item === null)) {
                  return 'Not selected'
                } else {
                  return items.map(item => item?.label ?? 'Not selected').join(' - ')
                }
              }}
            </Picker>
          }
        </Form.Item>
        {
          (selectedCountry?.code || localeCountry?.code) === 'BD' &&
          <Form.Item
            name='city'
            label='Thana/Upazila'
            rules={[{ required: true, message: 'Please enter your Thana/Upazila' }]}
          >
            <Input placeholder='Please enter your Thana/Upazila name' autoComplete="chrome-off" />
          </Form.Item>
        }
        <Form.Item
          name='address'
          label='Address'
          rules={[{ required: true, message: 'Please enter your address' }]}
        >
          <TextArea placeholder='Please enter your street address' rows={3} autoComplete="chrome-off" />
        </Form.Item>

        <Form.Item
          name='company'
          label='Company'
        >
          <Input placeholder='Please enter your company name' autoComplete="chrome-off" />
        </Form.Item>
        <Form.Item
          name='type'
          label="Please select a address type"
          rules={[{ required: true, message: 'Please select a shipping type' }]}
        >
          <Selector
            columns={2}
            options={viewSelectedAddressType}
          />
        </Form.Item>
        <Form.Item
          name='is_default'
        >
          <Checkbox
            checked={isChecked}
            onChange={checked => {
              if (checked) {
                setIsChecked(true)
              } else {
                setIsChecked(false)
              }
            }}
            icon={checked =>
              checked ? (
                <CheckSquareFilled style={{ color: 'var(--adm-color-primary)' }} />
              ) : (
                <CheckSquareOutlined style={{ color: 'var(--adm-color-weak)' }} />
              )
            }
          > Set as default address </Checkbox>
        </Form.Item>
      </Form>
    </div>
  );
}
export default AddShippingAddress
