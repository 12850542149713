import React, { useEffect, useState } from "react"
import './ProfileDetails.scss'
import { Button, Dialog, Divider, Grid, Popup, Space, Toast } from "antd-mobile";
import { ProductDetailsBlock } from "demos";
import { removeAddress } from "services/address-client";
import AddShippingAddress from "./AddShippingAddress";
import { LoadingState, PageHead, GlobalHeaderBack } from "components";
import { DeleteOutline, EditSFill } from "antd-mobile-icons";
import { sleep } from "utils/sleep";
import { useDispatch, useSelector } from "react-redux";
import { getAllAddress } from "app/actions/address";


const ShippingAddress = () => {
  const dispatch = useDispatch();
  const { address, loading } = useSelector((state) => state.address);

  const [viewAddAddress, setViewAddAddress] = useState(false);
  const [isAddressEdit, setIsAddressEdit] = useState(false);
  const [editAddressValue, setEditAddressValue] = useState(null);
  const [isDefault, setIsDefault] = useState(false);

  useEffect(() => {
    dispatch(getAllAddress())
  }, [dispatch]);

  let allAddress = address.data
  let shippingAddress = address.defaultShipping
  let billingAddress = address.defaultBilling

  if (loading) {
    return (
      <LoadingState />
    )
  }

  const addressRemoved = (id) => {
    removeAddress(id)
      .then(res => {
        dispatch(getAllAddress())
        Toast.show({
          icon: 'success',
          content: res?.data?.message,
        })
      })
      .catch(err => {
        if (err?.response?.data?.message) {
          Toast.show({
            icon: 'fail',
            content: err?.response?.data?.message,
          })
        } else {
          Toast.show({
            icon: 'fail',
            content: 'failed!',
          })
        }
      })
  }

  const handleRemoveAddress = (id) => {
    Dialog.confirm({
      content: 'Are you sure you want to delete those item',
      closeOnMaskClick: true,
      confirmText: "Confirm",
      cancelText: "Cancel",
      onConfirm: async () => {
        await sleep(2000)
        addressRemoved(id)
      },
    })
  }

  const handleAddressEdit = (value) => {
    setViewAddAddress(true)
    setIsAddressEdit(true)
    setEditAddressValue(value)
  }

  return (
    <>
      <PageHead title={`Shipping Address`} />

      <GlobalHeaderBack title="Shipping Address" height="60px" color="white" />
      {
        shippingAddress &&
        <ProductDetailsBlock padding="4%" marginTop="10px">
          <div className="shipping_title">Shipping Address</div>
          <Divider />
          <Grid columns={2} gap={20}>
            <Grid.Item>
              <Space className="shipping_grid" direction="vertical">
                <span
                  className="shipping_grid__title">{shippingAddress?.first_name} {shippingAddress?.last_name}</span>
                <span className="shipping_grid__phone">{shippingAddress?.phone}</span>
                <span className="shipping_grid__book">{shippingAddress?.address}, {shippingAddress?.state}, {shippingAddress?.city}, {shippingAddress?.country} - {shippingAddress?.postal_code}</span>
              </Space>
            </Grid.Item>
            <Grid.Item className="text_align">
              <Space direction="vertical">
                <Button color="primary" size="mini" onClick={() => {
                  setIsDefault(true)
                  handleAddressEdit(shippingAddress)
                }}><EditSFill /></Button>
              </Space>
            </Grid.Item>
          </Grid>
        </ProductDetailsBlock>
      }
      {
        billingAddress &&
        <ProductDetailsBlock padding="4%" marginTop="10px">
          <div className="shipping_title">Billing Address</div>
          <Divider />
          <Grid columns={2} gap={20}>
            <Grid.Item>
              <Space className="shipping_grid" direction="vertical">
                <span
                  className="shipping_grid__title">{billingAddress?.first_name} {billingAddress?.last_name}</span>
                <span className="shipping_grid__phone">{billingAddress?.phone}</span>
                <span
                  className="shipping_grid__book">{billingAddress?.address}, {billingAddress?.state}, {billingAddress?.city}, {billingAddress?.country} - {billingAddress?.postal_code}</span>
              </Space>
            </Grid.Item>
            <Grid.Item className="text_align">
              <Space direction="vertical">
                <Button color="primary" size="mini" onClick={() => {
                  setIsDefault(true)
                  handleAddressEdit(billingAddress)
                }}><EditSFill /></Button>
              </Space>
            </Grid.Item>
          </Grid>
        </ProductDetailsBlock>
      }
      {
        allAddress?.length > 0 &&
        <>
          <ProductDetailsBlock padding="4%" marginTop="10px" marginBottom="25%">
            <div className="shipping_title">All Addresses</div>
            <Divider />
            {
              allAddress?.map((elements, index) => {
                return (
                  <div key={`allAddress${index}`} className="address_border">
                    <Grid columns={2} gap={20}>
                      <Grid.Item>
                        <Space className="shipping_grid" direction="vertical">
                          <span
                            className="shipping_grid__title">{elements?.first_name} {elements?.last_name}</span>
                          <span className="shipping_grid__phone">{elements?.phone}</span>
                          <span
                            className="shipping_grid__book">{elements?.address}, {elements?.state}, {elements?.city}, {elements?.country} - {elements?.postal_code}</span>
                        </Space>
                      </Grid.Item>
                      <Grid.Item className="text_align">
                        <Space direction="vertical">
                          <Button color="primary" size="mini" onClick={() => handleAddressEdit(elements)}><EditSFill /></Button>
                          <Button className="shipping_icons" color="primary" size="mini" onClick={() => handleRemoveAddress(elements?.id)}><DeleteOutline /></Button>
                        </Space>
                      </Grid.Item>
                    </Grid>
                    <Divider />
                  </div>
                )
              })
            }
          </ProductDetailsBlock>
        </>
      }

      <div className="add_new_address">
        <Button color='primary' block onClick={() => setViewAddAddress(true)}>ADD NEW ADDRESS</Button>
      </div>

      <Popup
        visible={viewAddAddress}
        onMaskClick={() => {
          setViewAddAddress(false)
        }}
        bodyStyle={{
          borderTopLeftRadius: '15px',
          borderTopRightRadius: '15px',
          height: '80vh',
          overflowY: 'scroll'
        }}
        destroyOnClose={true}
      >
        <AddShippingAddress
          setViewAddAddress={setViewAddAddress}
          isAddressEdit={isAddressEdit}
          editAddressValue={editAddressValue}
          isDefault={isDefault}
        />
      </Popup>
    </>
  );
}
export default ShippingAddress
