import React from "react";
import "../dashboard.scss"
import { DotLoading, Grid, Space } from "antd-mobile";
import {
  CustomerServiceOutlined,
  EnvironmentOutlined,
  KeyOutlined,
  SolutionOutlined,
  TrophyOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { BellOutline, CheckCircleOutline, HandPayCircleOutline } from "antd-mobile-icons";
import { createHelpdeskSession } from "services/auth-client";
import { useLayoutContext } from "context/LayoutContext";
import { defaultKeys } from "consts/storage";

const ProfileDetailsEdit = () => {
  const { visibleConfigs } = useLayoutContext();
  const history = useHistory()
  const [loading, setLoading] = React.useState(false);

  const handleHelpdesk = () => {
    setLoading(true)
    createHelpdeskSession()
      .then(res => {
        window.open(res.data?.url)
      })
      .catch(({ response }) => {
        window.location.href = `/auth/login`
        // router.push('/auth/login?next=/helpdesk')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <div className="profile_details_edit">
      <Grid columns={4} gap={1} className="details_menu_edit">
        <Grid.Item onClick={() => history.push("/profile/shipping-address")}>
          <Space direction="vertical">
            <span className="details_menu_edit__icon"><EnvironmentOutlined /></span>
            <span className="details_menu_edit__text">Address</span>
          </Space>
        </Grid.Item>
        <Grid.Item onClick={() => history.push("/campaigns")}>
          <Space direction="vertical">
            <span className="details_menu_edit__icon"><SolutionOutlined /></span>
            <span className="details_menu_edit__text">Campaign</span>
          </Space>
        </Grid.Item>
        <Grid.Item onClick={() => history.push("/profile/details")}>
          <Space direction="vertical">
            <span className="details_menu_edit__icon"><KeyOutlined /></span>
            <span className="details_menu_edit__text">Password</span>
          </Space>
        </Grid.Item>
        <Grid.Item>
          <Space direction="vertical" onClick={() => history.push("/account/wallet")}>
            <span className="details_menu_edit__icon"><HandPayCircleOutline /></span>
            <span className="details_menu_edit__text">Wallet</span>
          </Space>
        </Grid.Item>
      </Grid>
      <Grid columns={4} gap={1} className="details_menu_edit">
        <Grid.Item onClick={() => history.push("/notification")}>
          <Space direction="vertical">
            <span className="details_menu_edit__icon"><BellOutline /></span>
            <span className="details_menu_edit__text">Notification</span>
          </Space>
        </Grid.Item>
        {
          visibleConfigs?.[defaultKeys.storesVisible] &&
          <Grid.Item onClick={() => history.push("/account-store")}>
            <Space direction="vertical">
              <span className="details_menu_edit__icon"><TrophyOutlined /></span>
              <span className="details_menu_edit__text">Shops</span>
            </Space>
          </Grid.Item>
        }
        <Grid.Item onClick={() => history.push("/issues")}>
          <Space direction="vertical">
            <span className="details_menu_edit__icon"><CheckCircleOutline /></span>
            <span className="details_menu_edit__text">Action Needed</span>
          </Space>
        </Grid.Item>
        {
          !loading ?
            <Grid.Item onClick={() => handleHelpdesk()}>
              <Space direction="vertical">
                <span className="details_menu_edit__icon"><CustomerServiceOutlined /></span>
                <span className="details_menu_edit__text">Help Center</span>
              </Space>
            </Grid.Item>
            :
            <DotLoading />
        }
      </Grid>
    </div>
  );
}

export default ProfileDetailsEdit;
