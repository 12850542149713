import * as activityTypes from "app/actionTypes/userActivity";
import {getLocalData} from "utils/helper";
import {RECENTLY_VIEW, SEARCH_HISTORY} from "constants/constants";
import produce from "immer";

const initialState = {
  loading: true,
  error: null,
  searchedKeyword: null,
  searchedKeywords: [],
  recentlyViewedIDs: [],
  productDetails: [],
  isSameProduct: false,
  isProductDetailsLoading: true,
  currentProductData: null,
  currentReviewData: null,
  productIDS: [],
  isProductIDSLoading: true,
  externalData: {
    reviews: [],
    description: [],
  }
}

const updateLocalSearchedKeywords = (keywords) => {
  localStorage.setItem(SEARCH_HISTORY, JSON.stringify([...keywords]))
}

const updateLocalRecentlyView = (jsonData) => {
  localStorage.setItem(RECENTLY_VIEW, JSON.stringify([...jsonData]))
}

export default function userActivity(state = initialState, action) {
  const {payload} = action;

  return produce(state, draft => {
    switch (action.type) {
      case activityTypes.GET_SEARCHED_KEYWORD:
        draft.loading = true
        break
      case activityTypes.GET_SEARCHED_KEYWORDS:
        let searchedKeywords = getLocalData(SEARCH_HISTORY) ?? [];
        draft.searchedKeywords = searchedKeywords
        draft.loading = true
        break
      case activityTypes.SET_SEARCHED_KEYWORDS:
        let updatedKeywords = [...state?.searchedKeywords];
        if (updatedKeywords?.length > 20) {
          updatedKeywords.splice(-1, 1);
        }
        if (Array.isArray(updatedKeywords) && !updatedKeywords.includes(payload)) {
          updatedKeywords.unshift(payload);
          updateLocalSearchedKeywords(updatedKeywords)
        }
        draft.searchedKeywords = updatedKeywords
        draft.loading = true
        break
      case activityTypes.GET_RECENTLY_VIEWED:
        draft.loading = true
        break
      case activityTypes.GET_RECENTLY_VIEWED_PRODUCT:
        let recentlyProducts = getLocalData(RECENTLY_VIEW) ?? [];
        draft.recentlyViewedIDs = recentlyProducts
        draft.loading = true
        break
      case activityTypes.SET_RECENTLY_VIEWED:
        let updatedRecentlyViewed = [...state?.recentlyViewedIDs];
        if (updatedRecentlyViewed?.length > 20) {
          updatedRecentlyViewed.splice(-1, 1);
        }
        let findMatchValue = updatedRecentlyViewed?.find(item => item.id === payload?.id)

        if (Array.isArray(updatedRecentlyViewed) && !findMatchValue) {
          updatedRecentlyViewed.unshift(payload);
          updateLocalRecentlyView(updatedRecentlyViewed)
        }
        draft.recentlyViewedIDs = updatedRecentlyViewed
        draft.loading = true
        break
      case activityTypes.SET_PRODUCT_DETAILS:
        let updateProductDetails = [...state?.productDetails];
        if (updateProductDetails?.length > 20) {
          updateProductDetails.splice(-1, 1);
        }
        let findMatchProduct = updateProductDetails?.find(item => item.id === payload?.id)
        if (Array.isArray(updateProductDetails) && !findMatchProduct) {
          updateProductDetails.unshift(payload);
        }
        draft.productDetails = updateProductDetails
        draft.isProductDetailsLoading = false
        break
      case activityTypes.GET_PRODUCT_DETAILS:
        let findProductMatch = payload?.preData?.find(item => item.link === payload?.data)
        draft.isSameProduct = !!findProductMatch;
        draft.isProductDetailsLoading = false
        draft.currentProductData = findProductMatch
        break
      case activityTypes.SET_PRODUCT_IDS:
        let productIDS = [...state?.productIDS];
        if (productIDS?.length > 20) {
          productIDS.splice(-1, 1);
        }
        let findMatchProductIDS = productIDS?.find(item => item === payload)

        if (Array.isArray(productIDS) && !findMatchProductIDS) {
          productIDS.unshift(payload);
        }
        draft.productIDS = productIDS
        draft.isProductIDSLoading = false
        break
      default:
        return state
    }
  })
}
