import React, {useEffect, useState} from "react";
import {Divider, Grid, Image, Space} from "antd-mobile";
import "./StoreAccount.scss"
import {GlobalHeaderBack, PageHead} from "components";
import {STORE_STORAGE} from "constants/constants";
import {getStoreData} from "services/settings-client";
import * as dayjs from "dayjs";
import {ProductDetailsBlock} from "demos";
import {StoreSkeleton} from "../../components";
import {getLocalData} from "utils/helper";

const StoreAccount = () => {

  const [storeData, setStoreData] = useState(null);
  const [isStoreLoading, setIsStoreLoading] = useState(false);

  let store = getLocalData(STORE_STORAGE)

  const fetchShopData = React.useCallback(
    () => {
      setIsStoreLoading(true)
      getStoreData()
        .then(res => {
          setStoreData(res?.data?.shops)
          setIsStoreLoading(false)
          let rightNowTimeOfSaveData = new Date()
          let shopDataObject = {value: res?.data?.shops, timestamp: dayjs(rightNowTimeOfSaveData).format()}
          localStorage.setItem(STORE_STORAGE, JSON.stringify(shopDataObject));
        })
        .catch(err => {
          setIsStoreLoading(false)
        })
    },
    [],
  )

  let rightNowTime = new Date()
  let formatDayJS = new Date(dayjs(rightNowTime).format("M/D/YYYY"))
  let storeDateTime = store?.timestamp
  let storeFormatDayJS = new Date(dayjs(storeDateTime).format("M/D/YYYY"))

  const diffTime = Math.abs(formatDayJS - storeFormatDayJS);
  const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));


  useEffect(() => {
    if (diffHours > 2 || !store?.value) {
      fetchShopData()
    }
  }, [diffHours, fetchShopData, store?.value]);

  if (isStoreLoading) {
    return (
      <StoreSkeleton/>
    )
  }

  let storeValueData = store?.value ? store?.value : storeData

  return (
    <>
      <PageHead title={`Store Account`}/>

      <GlobalHeaderBack title="Store List" height="60px" color="white"/>
      <ProductDetailsBlock padding="3%" marginBottom="15%">
        {
          storeValueData && storeValueData?.length > 0 && storeValueData?.map((elements, index) => {
            return (
              <div className="services-shop" key={`store${index}`} onClick={() => window.open(elements?.url)}>
                <Grid columns={20} gap={0}>
                  <Grid.Item span={6}>
                    {
                      elements?.images?.icon_svg
                        ?
                        <Image src={elements?.images?.icon_svg} fit="fill" height={100} width={100}
                               style={{borderRadius: 8}}/>
                        :
                        <Image src="/assets/product/logo-bg_118X118.png" fit="fill" height={100} width={100}
                               style={{borderRadius: 8}}/>
                    }
                  </Grid.Item>
                  <Grid.Item span={14} className="services-shop__details">
                    <Space direction="vertical">
                      {
                        elements?.name &&
                          <span className="services-shop__title">{elements?.name}</span>
                      }
                      {
                        elements?.country_name &&
                          <span>{elements?.country_name}</span>
                      }
                      {
                        elements?.description &&
                          <span>{elements?.description}</span>
                      }
                    </Space>
                  </Grid.Item>
                </Grid>
                <Divider/>
              </div>
            )
          })
        }
      </ProductDetailsBlock>
    </>
  );
}

export default StoreAccount;
