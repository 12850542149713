export const GET_BUILDER_REQUESTED = 'GET_BUILDER_REQUESTED';
export const GET_BUILDER_SUCCEEDED = 'GET_BUILDER_SUCCEEDED';
export const GET_BUILDER_FAILED = 'GET_BUILDER_FAILED';
export const DEFAULT_BUILDER_REQUESTED = 'DEFAULT_BUILDER_REQUESTED';
export const DEFAULT_BUILDER_SUCCEEDED = 'DEFAULT_BUILDER_SUCCEEDED';
export const DEFAULT_BUILDER_FAILED = 'DEFAULT_BUILDER_FAILED';

export const SET_BUILDER_DATA = 'SET_BUILDER_DATA';

export const GET_SECTION_DATA_REQUESTED = 'GET_SECTION_DATA_REQUESTED';
export const GET_SECTION_DATA_SUCCEEDED = 'GET_SECTION_DATA_SUCCEEDED';
export const GET_SECTION_DATA_FAILED = 'GET_SECTION_DATA_FAILED';

export const SET_BUILDER_SECTION_DATA = 'SET_BUILDER_SECTION_DATA'
