import React from 'react'
import {Grid, Space} from "antd-mobile";
import './RecentlyViewProductList.scss'
import { changeImageOptimizeSize, ShowPriceWithCurrency} from "utils/helper";
import ImageRenderer from "utils/ImageRenderer";
import { useLayoutContext } from 'context/LayoutContext';

const RecentlyViewProductList = ( { product }) => {
    const { localeCurrency, preloaderImage } = useLayoutContext();

    return (
        <Space direction="vertical" className="recently_list">
            <div className="recently_list_image">
                <div className="recently_list_image__thumb">
                    <ImageRenderer
                        key={product?.id}
                        url={changeImageOptimizeSize(product.image)}
                        thumb={changeImageOptimizeSize(product.image)}
                        width={240}
                        height={240}
                        alt={`ProductImage${product?.id}`}
                        imageBackground={preloaderImage ?? "/assets/product/preloader-bg_62X62.png"}
                    />
                </div>
            </div>
            <div className="recently_price">
                <Grid columns={1} gap={5} style={{ "--gap-vertical" : '6px'}}>
                    <Grid.Item>
                        {
                            product?.price &&
                            <span className="recently_price__price">{ShowPriceWithCurrency({ price: product?.price, currency: localeCurrency })}</span>
                        }
                    </Grid.Item>
                    <Grid.Item className="recently_price__title">
                        {
                            product?.title &&
                            <span>{product?.title}</span>
                        }
                    </Grid.Item>
                </Grid>
            </div>
        </Space>
    )
}

export default RecentlyViewProductList
