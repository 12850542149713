import React from 'react'
import CartDetails from "./components/CartDetails";
import {PageHead} from "components";

const Cart = () => {

    return (
        <>
            <PageHead title={`Cart`}/>
            <CartDetails/>
        </>
    )
}

export default Cart
