import * as Types from 'app/actionTypes/productDetailsData';

export const getProductReview = (filter = {}) => {
  return {
    type: Types.GET_PRODUCT_REVIEW_REQUESTED,
    payload: filter,
  }
}

export const getProductDescriptions = (filter = {}) => {
  return {
    type: Types.GET_PRODUCT_DESCRIPTION_REQUESTED,
    payload: filter,
  }
}
