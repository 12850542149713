import React from 'react'
import { NavBar, Popover } from "antd-mobile";
import {
  ArrowLeftOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import './GlobalHeaderBack.scss'
import {
  AppstoreOutline, CheckShieldOutline,
  ExclamationCircleOutline,
  TextOutline, UnorderedListOutline,
} from "antd-mobile-icons";
import { useLayoutContext } from 'context/LayoutContext';

const GlobalHeaderBack = (props) => {
  const { helpDeskDomain } = useLayoutContext();
  const { title, viewLoginPopUp, setViewLoginPopUp } = props

  const history = useHistory()

  const right = (
    <Popover.Menu
      actions={[
        { text: 'Home', icon: < AppstoreOutline /> },
        { text: 'My Order', icon: <UnorderedListOutline /> },
        { text: 'Dashboard', icon: <TextOutline /> },
        { text: 'Campaigns', icon: <CheckShieldOutline /> },
        { text: 'Need Help', icon: <ExclamationCircleOutline /> },
      ]}
      placement='bottomLeft'
      onAction={node => {
        if (node.text === "Home") {
          history.push("/")
        } else if (node.text === "My Order") {
          history.push("/account/orders")
        } else if (node.text === "Dashboard") {
          history.push("/dashboard")
        } else if (node.text === "Campaigns") {
          history.push("/campaigns")
        } else if (node.text === "Need Help" && helpDeskDomain) {
          window.open(helpDeskDomain)
        }
      }}
      trigger='click'
    >
      <span className="global_right_icon"><MoreOutlined /></span>
    </Popover.Menu>
  )

  const left = (
    <span className="global_left_title">{title}</span>
  )

  const backArrow = (
    <span className="global_right_icon"><ArrowLeftOutlined /></span>
  )

  const back = () => {
    if (viewLoginPopUp) {
      setViewLoginPopUp(false)
    } else if (window.location.pathname === "/my-request/request") {
      history.push("/my-request")
    } else {
      history.goBack()
    }
  }

  return (
    <div className="global_header_back" style={props.backgroundImage ? { backgroundImage: props.backgroundImage } : { backgroundColor: props.backgroundColor }
    }>
      <NavBar left={left} right={right} onBack={back} style={{ '--height': props.height, color: props.color }} backArrow={backArrow} />
    </div>
  )
}

GlobalHeaderBack.defaultProps = {
  backgroundColor: "var(--adm-color-primary)",
}

export default GlobalHeaderBack
