import React from "react";
import { GlobalHeaderBack} from "components";
import {DemoBlock} from "demos";
import {Grid} from "antd-mobile";
import Skeleton from "react-loading-skeleton";

const WalletSkeleton = () => {

    return (
        <>
            <GlobalHeaderBack title="Wallet" height="60px" color="white"/>

            <DemoBlock padding="10% 3%" margin="2% 3%" borderRadius="8px" borderLeft="2px solid #F26623"
                       boxShadow="3px 5px 3px #dcdcdc">
                <Skeleton count={2}/>
            </DemoBlock>
            <DemoBlock padding="8% 3%" margin="2% 3%" borderRadius="8px" borderLeft="2px solid #F26623"
                       boxShadow="3px 5px 3px #dcdcdc">
                <Grid columns={9} gap="8">
                    <Grid.Item span={8}>
                        <Skeleton count={5}/>
                    </Grid.Item>
                    <Grid.Item span={1}>
                        <Skeleton count={2}/>
                    </Grid.Item>
                </Grid>
            </DemoBlock>
            <DemoBlock padding="4% 3%" margin="4% 3% 20% 3%" borderRadius="8px" borderLeft="2px solid #F26623"
                       boxShadow="3px 5px 3px #dcdcdc">
                <div className="transactions_table">
                    <Skeleton count={25}/>
                </div>
            </DemoBlock>
        </>
    );
}

export default WalletSkeleton
