import client from './without-auth-api-client'
import Qs from 'qs';

const getRunningCampaign = (filter = {}) => {
    return client.get('/campaigns/running', {
        params: filter,
        paramsSerializer(params) {
            return Qs.stringify(params, { arrayFormat: 'brackets' });
        },
    });
};

const getUpcomingCampaign = (filter = {}) => {
    return client.get('/campaigns/upcoming', {
        params: filter,
        paramsSerializer(params) {
            return Qs.stringify(params, { arrayFormat: 'brackets' });
        },
    });
};

const getCampaignDetails = (slug, filter = {}) => {
    return client.get(`/campaigns/details/${slug}`, {
        params: filter,
        paramsSerializer(params) {
            return Qs.stringify(params, { arrayFormat: 'brackets' });
        },
    });
};

export {
    getRunningCampaign,
    getUpcomingCampaign,
    getCampaignDetails
}
