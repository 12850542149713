import React from 'react';
import {Divider, Grid, Rate} from "antd-mobile";
import './KeywordSearchProductDetailsList.scss'
import {calculateDiscount, changeImageOptimizeSize, findRequestAbleStore} from "utils/helper";
import {Link} from "react-router-dom";
import ImageRenderer from "utils/ImageRenderer";
import qs from "qs";
import ProductPrice from 'components/ProductPrice/ProductPrice';
import { useLayoutContext } from 'context/LayoutContext';

const KeywordSearchProductDetailsList = (props) => {
    const { preloaderImage } = useLayoutContext();
    const {product} = props

    const params = qs.parse((window.location.search).substring(1))

    let imageUrl = product.image

    if (parseInt(params?.shop_id) === 4 || parseInt(params?.shop_id) === 10) {
        imageUrl = changeImageOptimizeSize(product.image)
    } else if (product?.vendor === "taobao") {
        imageUrl = changeImageOptimizeSize(product.image)
    } else if (product?.type){
        imageUrl = changeImageOptimizeSize(product.image)
    } else if (product?.slug){
        imageUrl = changeImageOptimizeSize(product.image)
    }

    let pathName = "/products/details"

    if (findRequestAbleStore(params?.shop_id)) {
        pathName = "/manual-requests"
    }

    let searchParams = `?url=${encodeURIComponent(product?.link)}`

    if (product?.type === 'campaign' || product?.pivot?.campaign_id){
      searchParams = `?ipid=${product.id}&campaign=${product?.pivot?.campaign_id}&campaign_product=${product?.pivot?.id}`
    } else if (product?.product_code) {
      let productSlug = product?.slug || product?.title;
      pathName = `/products/${productSlug}/${product.product_code}`
      searchParams = null;
    } else if (product?.type){
      searchParams = `?id=${product.id}`
    }

    let discountPrice = product?.discount_price?.min
    let originalPrice = product?.price?.min

    if (product?.price?.discount?.min){
        discountPrice = product?.price?.discount?.min
    }

    if (product?.price?.original?.min){
        originalPrice = product?.price?.original?.min
    }

    return (
        <>
            <Link to={{
                pathname: pathName,
                search: searchParams,
                state: {
                    title: product.title,
                    price: discountPrice ? discountPrice : originalPrice,
                    image: product?.image
                }
            }} className="key_word_link">
                <Grid columns={6} gap={10} className="product_keyword_search_list">
                    <Grid.Item span={2}>
                        <div className="product_keyword_search_image_list">
                            <div className="product_keyword_search_image_list__thumb">
                                <ImageRenderer
                                    key={product?.id}
                                    url={imageUrl}
                                    thumb={imageUrl}
                                    width={240}
                                    height={240}
                                    alt={`ProductImage${product?.id}`}
                                    imageBackground={preloaderImage ?? "/assets/product/preloader-bg_184X184.png"}
                                />
                                {
                                    product?.discount_price?.min &&
                                    <div className="product_keyword_search_image_list__thumb__status-top">
                                        <span >{calculateDiscount(product?.price?.min, product?.discount_price?.min)}%</span>
                                    </div>
                                }
                            </div>
                        </div>
                    </Grid.Item>
                    <Grid.Item span={6} style={{marginTop: '2%'}}>
                        <div className="product_keyword_search_title_list">
                            <span>{product?.title}</span>
                        </div>
                        <div className="product_keyword_search_price_list">
                          <ProductPrice
                            originalPrice={originalPrice}
                            discountPrice={discountPrice}
                          />
                        </div>
                        <div className="product_keyword_search_price_list">
                            {
                                product?.sold &&
                                <span className="product_keyword_search_price_list__sold">Sold: {product?.sold}</span>
                            }
                        </div>
                        <div className="product_keyword_search_rating_list">
                            {
                                product?.rating &&
                                <span><Rate readonly allowHalf value={product?.rating}/>
                                    {
                                        product?.rating_count && `(${product?.rating_count})`
                                    }
                                </span>
                            }
                        </div>
                    </Grid.Item>
                </Grid>
            </Link>
            <Divider/>
        </>
    );
}

export default KeywordSearchProductDetailsList;
