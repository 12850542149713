export const GET_RUNNING_CAMPAIGN_REQUESTED     = 'GET_RUNNING_CAMPAIGN_REQUESTED';
export const GET_RUNNING_CAMPAIGN_SUCCESS       = 'GET_RUNNING_CAMPAIGN_SUCCESS';
export const GET_RUNNING_CAMPAIGN_FAILED        = 'GET_RUNNING_CAMPAIGN_FAILED';

export const GET_UPCOMING_CAMPAIGN_REQUESTED    = 'GET_UPCOMING_CAMPAIGN_REQUESTED';
export const GET_UPCOMING_CAMPAIGN_SUCCESS      = 'GET_UPCOMING_CAMPAIGN_SUCCESS';
export const GET_UPCOMING_CAMPAIGN_FAILED       = 'GET_UPCOMING_CAMPAIGN_FAILED';

export const GET_CAMPAIGN_DETAILS_REQUESTED     = 'GET_CAMPAIGN_DETAILS_REQUESTED';
export const GET_CAMPAIGN_DETAILS_SUCCESS       = 'GET_CAMPAIGN_DETAILS_SUCCESS';
export const GET_CAMPAIGN_DETAILS_FAILED        = 'GET_CAMPAIGN_DETAILS_FAILED';
export const UPDATE_CAMPAIGN_PRODUCTS           = 'UPDATE_CAMPAIGN_PRODUCTS';

export const LOAD_MORE_PRODUCTS                 = 'LOAD_MORE_PRODUCTS';
