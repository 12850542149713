import React from "react"
import "../Cart.scss"
import { CartSkeleton, GlobalHeaderBack } from "components";
import { ProductDetailsBlock } from "demos";
import { Button, Dialog, ErrorBlock, Grid, Selector, Space, Toast } from "antd-mobile";
import { DeleteOutline } from "antd-mobile-icons";
import CartProductDetails from "./CartProductDetails";
import { useCart } from "context/cart-context";
import CheckoutTabBar from "layout/CheckoutTabBar";
import { sleep } from "utils/sleep";
import Skeleton from "react-loading-skeleton";
import { useHistory } from "react-router-dom";
import { defaultKeys } from "consts/storage";
import { useLayoutContext } from "context/LayoutContext";
import ReactGA from "react-ga4";

const CartDetails = () => {
  const history = useHistory();
  const { visibleConfigs } = useLayoutContext();
  const {
    state: cartState,
    isCartLoading,
    handleSelectAll,
    cartItemRemoved,
    cartFilterOptions,
    handleCartFilter,
  } = useCart()

  const handleRemoveCartItem = () => {
    let selectedItems = cartState.data.filter(el => el.isSelected).map(el => el.id)
    if (selectedItems?.length === 0) {
      Toast.show({
        content: "Please select at least one item in cart",
      })
    } else {
      Dialog.confirm({
        content: "Are you sure you want to delete those item",
        closeOnMaskClick: true,
        confirmText: "Confirm",
        cancelText: "Cancel",
        onConfirm: async () => {
          await sleep(2000)
          cartItemRemoved(selectedItems)
          ReactGA.event({
            category: 'cart',
            action: 'remove_from_cart',
          });
        },
      })
    }
  }

  if (!isCartLoading) {
    if (cartState.data?.length === 0) {
      return (
        <div style={{ padding: "0 0 10% 0" }}>
          <GlobalHeaderBack title="My Cart" height="60px" color="white" />
          <ErrorBlock fullPage status="default" title="Cart Empty"
            style={{ paddingTop: "calc(44vh - var(--image-height-full-page))" }}
            description="Your cart is empty, Please add some products">
            <Button color="primary" size="small" onClick={() => history.push('/')}>Back
              Home</Button>
          </ErrorBlock>
        </div>
      )
    }
  }

  // let isInternalProductExits = cartState.data?.find(el => el.type === "internal")

  return (
    <>
      <GlobalHeaderBack title="My Cart" height="60px" color="white" />

      <ProductDetailsBlock marginTop="2%" padding="6px" marginBottom="30%">
        {
          !isCartLoading ?
            <>
              <Grid columns={12} gap={24} className="cart_layout cart_layout_all_select">
                <Grid.Item span={2}>
                  <Space align="center">
                    <input type="checkbox" id="selectAll" checked={cartState.selectedAllStatus.selected}
                      onChange={handleSelectAll} />
                    <label className="store_title" htmlFor="selectAll">All</label>
                  </Space>
                </Grid.Item>
                <Grid.Item span={1} className="cart_delete">
                  {
                    cartState.list?.length > 0 &&
                    <span className="cart_delete__icon"
                      onClick={handleRemoveCartItem}><DeleteOutline /></span>
                  }

                </Grid.Item>
                <Grid.Item span={9}>
                  {
                    visibleConfigs?.[defaultKeys.wholesaleLabelVisible] &&
                    <Selector
                      columns={3}
                      options={cartFilterOptions}
                      defaultValue={['default']}
                      onChange={(arr) => {
                        handleCartFilter(arr[0])
                      }}
                      style={{ '--padding': '3px 8px' }}
                    />
                  }

                </Grid.Item>
              </Grid>
            </>
            :
            <Skeleton height={20} />

        }

        {
          !isCartLoading && Object.keys(cartState.groupedItems).length !== 0 ?
            Object.keys(cartState.groupedItems).map(seller => <CartProductDetails
              key={`cartvendordlist-${seller}`} sellerItems={cartState.groupedItems[seller]}
              seller={seller} />)
            :
            <>
              <CartSkeleton />
              <CartSkeleton />
              <CartSkeleton />
              <CartSkeleton />
              <CartSkeleton />
            </>
        }
      </ProductDetailsBlock>
      <CheckoutTabBar />
    </>
  )
}

export default CartDetails
