import * as actionTypes from 'app/actionTypes/campaign';
import produce from 'immer';

const initialState = {
  running: {
    data: null,
    meta: null,
    loading: true,
    error: null,
  },
  upComing: {
    data: null,
    meta: null,
    loading: true,
    error: null,
  },
  details: {
    data: null,
    meta: null,
    campaign: null,
    loading: true,
    error: null,
    isPartialLoading: false,
  },
  firstLoad: false
}

export default function campaigns(state = initialState, action) {
  const { type } = action;

  return produce(state, draft => {
    switch (type) {
      case actionTypes.GET_CAMPAIGN_DETAILS_REQUESTED:
        if (action.payload.isTagFilterCall) {
          draft.firstLoad = true
          draft.details.loading = false;
          draft.details.isPartialLoading = true;
        } else {
          draft.details.loading = true;
        }
        break;
      case actionTypes.GET_CAMPAIGN_DETAILS_SUCCESS:
        const {actionParams} = action.data;
        if (!(state.firstLoad) || actionParams?.reset) {
          draft.details = action.data;
          draft.firstLoad = true;
          draft.details.loading = false;
          draft.details.isPartialLoading = false;
          draft.details.isFetched = true;
          break;
        }
        if (state.details.isPartialLoading) {
          draft.details.data = action.data.data;
          draft.details.meta = action.data.meta;
          draft.details.isPartialLoading = false;
          draft.details.isFetched = true;
        } else {
          draft.details.data = [...state.details.data, ...action.data.data];
          draft.details.meta = action.data.meta;
          draft.details.campaign= action.data.campaign;
          draft.details.loading = false;
          draft.details.isFetched = true;
        }
        break;
      case actionTypes.GET_CAMPAIGN_DETAILS_FAILED:
        draft.details.error = action;
        draft.details.loading = false;
        draft.details.isPartialLoading = false;
        break;
      case actionTypes.UPDATE_CAMPAIGN_PRODUCTS:
        draft.details.data = [...state.details.data, ...action.data.data];
        draft.details.meta = action.data.meta;
        draft.details.campaign= action.data.campaign;
        draft.details.loading = false;
        break;
      case actionTypes.LOAD_MORE_PRODUCTS:
        draft.details.data = [...draft.details.data, ...action.payload.data];
        draft.details.meta = action.payload.meta;
        break;
      case actionTypes.GET_RUNNING_CAMPAIGN_REQUESTED:
        draft.running.loading = true;
        break;
      case actionTypes.GET_RUNNING_CAMPAIGN_SUCCESS:
        draft.running = {...draft.running, ...action.data};
        draft.running.loading = false;
        break;
      case actionTypes.GET_RUNNING_CAMPAIGN_FAILED:
        draft.running.error = action;
        draft.running.loading = false;
        break;

      case actionTypes.GET_UPCOMING_CAMPAIGN_REQUESTED:
        draft.upComing.loading = true;
        break;
      case actionTypes.GET_UPCOMING_CAMPAIGN_SUCCESS:
        draft.upComing = {...draft.upComing, ...action.data};
        draft.upComing.loading = false;
        break;
      case actionTypes.GET_UPCOMING_CAMPAIGN_FAILED:
        draft.upComing.error = action;
        draft.upComing.loading = false;
        break;

      default:
        return state;
    }
  })
}
