import React, {useState} from "react"
import './ProfileDetails.scss'
import {Button, Form, Input, Toast} from "antd-mobile";
import {CloseCircleOutline, EyeFill} from "antd-mobile-icons";
import {storeProfileInfo} from "services/profile-client";
import useErrors from "hooks/useErrors";

const ChangePassword = (props) => {

    const [form] = Form.useForm()
    const {getErrors} = useErrors()
    const { setChangePassword } = props

    const [isFormLoading, setIsFormLoading] = useState(false)


    const [passwordShown, setPasswordShown] = useState(false);
    const [CPasswordShown, setCPasswordShown] = useState(false);
    const [CUPasswordShown, setCUPasswordShown] = useState(false);

    const togglePassword = (value) => {
        if (value === 'cuPass') {
            setCUPasswordShown(!CUPasswordShown)
        } else if (value === 'cPass') {
            setCPasswordShown(!CPasswordShown)
        } else {
            setPasswordShown(!passwordShown);
        }
    };

    const onFinishProfileUpdate = (value) => {

        const profileValue = {
            action: "password",
            password: value.password,
            new_password: value.new_password,
            new_password_confirmation: value.new_password_confirmation
        }

        setIsFormLoading(true)
        storeProfileInfo(profileValue)
            .then(res => {
                Toast.show({
                    icon: 'success',
                    content: res?.data?.message,
                })
                setIsFormLoading(false)
                setChangePassword(false)
            })
            .catch(err => {
                if (err?.response?.data?.errors){
                    form.setFields(getErrors(err.response.data.errors));
                }
                setIsFormLoading(false)
            })
    }

    return (
        <div className="profile_name">
            <div className="profile_info">
                <div>
                    <span className="profile_info__title">Change Password</span>
                </div>
                <div>
                    <span  className="profile_info__icons" onClick={() => setChangePassword(false)}><CloseCircleOutline/></span>
                </div>
            </div>
            <Form
                onFinish={onFinishProfileUpdate}
                footer={
                    <Button block type='submit' color='primary' loading={isFormLoading}>
                        Submit
                    </Button>
                }
                className="profile_form"
                form={form}
            >
                <Form.Item
                    name='password'
                    label='Current Password'
                    extra = { <span onClick={() => togglePassword("cuPass")}><EyeFill fontSize={22} /></span>}
                >
                    <Input placeholder='Please enter current password' type={CUPasswordShown ? "text" : "password"} />
                </Form.Item>
                <Form.Item
                    name='new_password'
                    label='New Password'
                    rules={[{ required: true, message: 'Please insert new password' }]}
                    extra = { <span onClick={() => togglePassword("pass")}><EyeFill fontSize={22} /></span>}
                >
                    <Input placeholder='Please enter new password' type={passwordShown ? "text" : "password"} />
                </Form.Item>
                <Form.Item
                    name='new_password_confirmation'
                    label='Password Confirmation'
                    rules={[{ required: true, message: 'Please insert confirm password' }]}
                    extra = { <span onClick={() => togglePassword("cPass")}><EyeFill fontSize={22} /></span>}
                >
                    <Input placeholder='Please enter your new password again' type={CPasswordShown ? "text" : "password"} />
                </Form.Item>
            </Form>
        </div>
    );
}
export default ChangePassword
