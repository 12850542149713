import React, { useEffect, useRef, useState } from "react";
import '../../ProductSearch.scss'
import { Grid, SearchBar, SpinLoading, Toast } from "antd-mobile";
import { useHistory } from "react-router-dom";
import {
  ArrowLeftOutlined,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { setSearchedKeywords } from "app/actions/userActivity";
import qs from "qs";
import { CameraOutline } from "antd-mobile-icons";
import { imageUpload } from "services/settings-client";
import { useAuth } from "context/auth-context";
import { navigateToNewSearch } from "app/actions/products";
import { changingProductDetails } from "app/actions/productsDetails";
import { useLayoutContext } from "context/LayoutContext";
import ReactGA from "react-ga4";

const ProductSearchBar = (props) => {
  const { defaultShop, defaultWholesaleShop } = useLayoutContext();
  const { setIsSearchView, isSearchView, componentFrom } = props
  const dispatch = useDispatch()
  const { findShop } = useAuth();

  const searchRef = useRef(null)
  const imageInput = useRef(null)
  const history = useHistory();
  const [isImageUploading, setIsImageUploading] = useState(false);

  const params = qs.parse((window.location.search).substring(1))

  useEffect(() => {
    if (isSearchView) {
      searchRef.current.focus()
    }
  }, [isSearchView]);

  const getShopID = () => {
    let shopID = findShop?.value
    if (params?.shop_id) {
      shopID = params.shop_id;
    } else if (componentFrom === 'wholesale_page' && defaultWholesaleShop) {
      shopID = defaultWholesaleShop;
    } else if (defaultShop && defaultWholesaleShop !== defaultShop) {
      shopID = defaultShop
    }
    return shopID;
  }

  const handleSearchProduct = (value) => {
    //Don't remove it might be used in future
    // let regex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%\-/]))?/;
    let regxPaUrlCheck = /(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])/

    if (regxPaUrlCheck.test(value)) {
      let foundValue = value.match(regxPaUrlCheck)[0];
      let url = new URL(foundValue);
      let urlValue = foundValue
      if (foundValue?.length > 512 && (url.hostname === 'item.taobao.com' || url.hostname === 'detail.tmall.com' || url.hostname === 'm.intl.taobao.com')) {
        let params = new URLSearchParams(url.search).get('id');
        urlValue = `${url?.origin}${url?.pathname}?id=${params}`
      } else if (foundValue?.length > 512 && (url.hostname === 'www.aliexpress.com' || url.hostname === 'm.aliexpress.com')) {
        urlValue = `${url?.origin}${url?.pathname}`
      }
      dispatch(changingProductDetails())
      setIsSearchView(false)
      history.push({
        pathname: `/products/details`,
        search: `?url=${encodeURIComponent(urlValue)}`
      })
      ReactGA.event({
        category: 'search',
        action: 'search',
        label: 'search by url',
      });
    } else {
      const searchData = value?.replace(/[&/\\#,+()$~%.'":*?<>{}]/g, '');
      let shopID = getShopID()
      dispatch(setSearchedKeywords(searchData));
      dispatch(navigateToNewSearch())
      window.scrollTo(0, 0)
      setIsSearchView(false)
      let searchedQueryString = `?keyword=${searchData}`;
      if (shopID !== defaultShop) {
        searchedQueryString = `?keyword=${searchData}&shop_id=${shopID}`;
      }
      ReactGA.event({
        category: 'search',
        action: 'search',
        label: 'search by keyword',
        keyword: searchData,
      });
      history.push({
        pathname: `/products`,
        search: searchedQueryString,
      })
    }
  }

  const handleImageSubmit = (e) => {
    e.preventDefault()
    const data = new FormData()
    data.append('image', e.target.files[0])
    setIsImageUploading(true)
    let shopID = getShopID()
    imageUpload(data)
      .then(res => {
        setIsImageUploading(false)
        imageInput.current.value = null
        dispatch(navigateToNewSearch());
        window.scrollTo(0, 0);
        history.push({
          pathname: `/products`,
          search: `?file=${res.data.id}&shop_id=${shopID}`,
        })
      })
      .catch(err => {
        setIsImageUploading(false)
        if (err?.response?.data?.message) {
          Toast.show({
            icon: 'fail',
            content: err?.response?.data?.message,
          })
        } else {
          Toast.show({
            icon: 'fail',
            content: 'Image Size Too Large (Max: 5MB), Please Try with Smaller Image',
          })
        }
      })
  }

  const handleImageClick = (e) => {
    e.preventDefault()
    imageInput.current.click()
  }

  return (
    <div className="product_manual_search">
      <Grid columns={13} gap={10} className="product_search_grid">
        <Grid.Item span={1}>
          <span className="product_search_grid__icons" onClick={
            !isSearchView ?
              () => history.goBack()
              :
              () => setIsSearchView(false)}><ArrowLeftOutlined /></span>
        </Grid.Item>
        <Grid.Item span={10}>
          <SearchBar
            ref={searchRef}
            placeholder='Search or paste URL'
            onSearch={val => {
              handleSearchProduct(val)
            }}
            defaultValue={params?.keyword}
          />
        </Grid.Item>
        <Grid.Item span={2}>
          {
            !isImageUploading ?
              <span className="image-search">
                <label onClick={handleImageClick} className="sr-only" htmlFor="img-search-if"><span
                  className="product_search_grid__icons"><CameraOutline /></span></label>
                <input
                  id="img-search-if"
                  className="image-search__display"
                  type="file"
                  onChange={handleImageSubmit}
                  ref={imageInput}
                // accept="image/png, image/jpeg, image/jpg"
                />
              </span>
              :
              <SpinLoading color="currentColor" style={{ '--size': '24px' }} />

          }
        </Grid.Item>
      </Grid>
    </div>
  );
}

export default ProductSearchBar;
