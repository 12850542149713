import React from 'react';
import { Grid, Rate } from "antd-mobile";
import './KeywordSearchProductDetails.scss'
import { calculateDiscount, changeImageOptimizeSize, RenderNemonicBadge, findRequestAbleStore } from "utils/helper";
import { Link } from "react-router-dom";
import ImageRenderer from "utils/ImageRenderer";
import qs from "qs";
import { COD_BADGE } from "consts/storage";
import { useLayoutContext } from 'context/LayoutContext';
import ProductPrice from 'components/ProductPrice/ProductPrice';
import ReactGA from "react-ga4";

const KeywordSearchProductDetails = (props) => {
  const { isCODAvailable, preloaderImage } = useLayoutContext();
  //Do not remove for wishlist
  // const {addToWishList, wishList} = useCart()

  const { product } = props
  const params = qs.parse((window.location.search).substring(1))
  let imageUrl = product.image
  if (parseInt(params?.shop_id) === 4 || parseInt(params?.shop_id) === 10) {
    imageUrl = changeImageOptimizeSize(product.image, 80)
  } else if (product?.vendor === 'onesixeighteight') {
    imageUrl = changeImageOptimizeSize(product.image, 80)
  } else if (product?.vendor === "taobao") {
    imageUrl = changeImageOptimizeSize(product.image)
  } else if (product?.type) {
    imageUrl = changeImageOptimizeSize(product.image)
  } else if (product?.slug) {
    imageUrl = product.image
  }

  //Do not remove for wish list
  // const findWishListExist = wishList?.data.find(item => item?.ipid === product?.id)

  let pathName = "/products/details"

  if (findRequestAbleStore(params?.shop_id)) {
    pathName = "/manual-requests"
  }

  let searchParams = `?url=${encodeURIComponent(product?.link)}`

  if (product?.type === 'campaign' || product?.pivot?.campaign_id) {
    searchParams = `?ipid=${product.id}&campaign=${product?.pivot?.campaign_id}&campaign_product=${product?.pivot?.id}`
  } else if (product?.product_code) {
    let productSlug = product?.slug || product?.title;
    pathName = `/products/${productSlug}/${product.product_code}`
    searchParams = null;
  }
  // else if (product?.slug) {
  //   searchParams = `?id=${product.id}&campaign=${product?.pivot?.campaign_id}&product_id=${product?.pivot?.id}`
  // }
  else if (product?.type) {
    searchParams = `?id=${product.id}`
  }

  let discountPrice = product?.discount_price?.min
  let originalPrice = product?.price?.min

  if (product?.price?.discount?.min) {
    discountPrice = product?.price?.discount?.min
  }

  if (product?.price?.original?.min) {
    originalPrice = product?.price?.original?.min
  }

  let showNemonicBadge = false;
  // if ((product?.pivot?.nemonic_badge || product?.pivot?.campaign_id) || (product?.link?.includes('taobao') && (parseFloat(product?.discount_price?.min) > 0))) {
  if (product?.pivot?.nemonic_badge) {
    showNemonicBadge = true;
  }


  return (
    <div className="product_keyword_search product-grid-item">
      {/*Remove Wishlist button for later use do not remove
            {
                findWishListExist ?
                    <div className="wish_product_list__exist">
                        <button onClick={() => addToWishList(product.vpid, product.vendor)}><HeartFilled/></button>
                    </div>
                    :
                    <div className="wish_product_list__wish">
                        <button onClick={() => addToWishList(product.vpid, product.vendor)}><HeartFilled/></button>
                    </div>
            }*/}
      <Link
        to={{
          pathname: pathName,
          search: searchParams,
          state: {
            title: product.title,
            price: discountPrice ? discountPrice : originalPrice,
            image: product?.image,
            link: product?.link
          }
        }}
        className="key_word_link"
        onClick={() => {
          ReactGA.event({
            category: 'Product',
            action: 'select_item',
            items: [{
              item_id: product.vpid,
              item_name: product.title,
              price: product.price.min,
              vendor: product.vendor,
              product_code: product.product_code,
              shop_id: product.shop_id,
              link: product.link,
            }]
          });
        }}
      >
        <Grid columns={1} gap={4}>
          <Grid.Item>
            <div className="product_keyword_search_image">
              <div className="product_keyword_search_image__thumb">
                <ImageRenderer
                  key={product?.id}
                  url={imageUrl}
                  thumb={imageUrl}
                  width={180}
                  height={180}
                  alt={`ProductImage${product?.id}`}
                  imageBackground={preloaderImage ?? "/assets/product/preloader-bg_184X184.png"}
                // maxWidth={175}
                />
                {/*<Image src={product?.image} fit='fill' className="product_keyword_search_image__thumb__image" style={{backgroundImage : `url(/product/preloader-bg.png)`}}/>*/}
                {
                  discountPrice &&
                  <div
                    className="product_keyword_search_image__thumb__status-top"
                  >
                    <span>{calculateDiscount(originalPrice, discountPrice)}%</span>
                  </div>
                }

                {
                  product?.pivot?.campaign_id && isCODAvailable &&
                  <div className="product_keyword_search_image__thumb__status-bottom">
                    <span>{COD_BADGE}</span>
                  </div>
                }
                {
                  showNemonicBadge &&
                  <RenderNemonicBadge
                    showNemonicBadge={showNemonicBadge}
                    img={product?.pivot?.nemonic_badge}
                  />
                }
              </div>
            </div>
          </Grid.Item>
          <Grid.Item>
            <div className="product_keyword_search_title">
              <span>{product?.title?.slice(0, 30)}</span>
            </div>
          </Grid.Item>
          <Grid.Item>
            <div className="product_keyword_search_price">
              <Grid columns={1} gap={4}>
                <Grid.Item>
                  <ProductPrice
                    originalPrice={originalPrice}
                    discountPrice={discountPrice}
                  />
                  {/* {
                    !discountPrice &&
                    <span className="product_keyword_search_price__price">{ShowPriceWithCurrency({price: originalPrice, currency: localeCurrency})}</span>
                  }
                  {
                    discountPrice &&
                    <>
                      <span
                        className="product_keyword_search_price__price">{ShowPriceWithCurrency({price: discountPrice, currency: localeCurrency})}</span>
                      <del className="product_keyword_search_price__del">{ShowPriceWithCurrency({price: originalPrice, currency: localeCurrency})}</del>
                    </>
                  } */}
                </Grid.Item>
                {
                  product?.sold &&
                  <Grid.Item>
                    <span className="product_keyword_search_price__sold">Sold: {product?.sold}</span>
                  </Grid.Item>
                }
                {
                  product?.rating &&
                  <Grid.Item>
                    {

                      <div className="product_keyword_search_rating">
                        <span>
                          <Rate readonly allowHalf value={product?.rating} />
                          {
                            product?.rating_count && `(${product?.rating_count})`
                          }
                        </span>
                      </div>
                    }
                  </Grid.Item>
                }
              </Grid>
            </div>
          </Grid.Item>
        </Grid>
      </Link>
    </div>
  );
}

export default KeywordSearchProductDetails;
