import React from "react";
import {Button} from "antd-mobile";
import './LoadMore.scss'

const LoadMore = (props) => {

    const {isLoading,handleClick, loadingText, loadMoreText} = props;

    return (
        <div className="load_more">
            <Button block color='primary' size='small' loading={isLoading} onClick={handleClick} loadingText={loadingText} >
                {loadMoreText ? loadMoreText : "Load More"}
            </Button>
        </div>
    )
}
export default LoadMore
