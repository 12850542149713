import React, { useState } from "react"
import { Button, Radio, Space } from "antd-mobile";
import { ProductDetailsBlock } from "demos";
import './ThreeTips.scss'
import { GlobalHeaderBack } from "components";
import { InformationCircleFill } from "antd-mobile-icons";
import { useProduct } from "context/product-context";
import { getLocalData } from "utils/helper";
import { THREE_TIPS } from "constants/constants";
import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { SERVICES_RANGE_TIPS, SHOPPING_PROCESS, TRANSPOTATION_RICK } from "consts/storage";
import { useLayoutContext } from "context/LayoutContext";
import siteConfig from "constants/site-configs";

const ThreeTips = () => {
  const { brandName, shippingMethodsMap } = useLayoutContext();
  let initialValue = 1;

  const [isChecked, setIsChecked] = useState(false);
  const [count, setCount] = useState(initialValue);
  const [isLocalExist, setIsLocalExist] = useState(false)

  const {
    setThreeTipsView,
    threeTipsView,
    setIs3TipsAgreed
  } = useProduct()

  React.useEffect(() => {
    let isAgreed = getLocalData(THREE_TIPS);
    if (isAgreed) {
      setCount(3);
      setIsChecked(true)
      setIsLocalExist(true);
    }
  }, [])

  const handleTips = (isChecked) => {
    if (!isLocalExist) {
      // AddToCartRequest();
      localStorage.setItem(THREE_TIPS, JSON.stringify(isChecked))
      setIs3TipsAgreed(isChecked)
    }
    setThreeTipsView(false)
  }

  const handleCheckBoxChange = (event) => {
    setIsChecked(event.target.checked)
  }

  const handleTermsChange = () => {
    setCount(count + 1)

    if (count === 3) {
      setCount(3)
    }

    if (count === 2) {
      setIsChecked(true)
    }

    if (isChecked && count === 3) {
      handleTips(isChecked)
    }
  }

  const handleGoBack = () => {
    if (count === 3) {
      setCount(2)
    }
    if (count === 2) {
      setCount(1)
    }
  }

  let confirmButtonText = ''

  if (isChecked && count === 3) {
    confirmButtonText = "I agree, Continue"
  } else if (count === 1 || count === 2) {
    confirmButtonText = <span>Next<CaretRightOutlined /></span>
  } else {
    confirmButtonText = <span>Next<CaretRightOutlined /></span>
  }

  const threeTipsList = [
    {
      title: 'Service ranges',
      tips: SERVICES_RANGE_TIPS(siteConfig?.brandName)
    },
    {
      title: 'Shopping process',
      tips: SHOPPING_PROCESS(siteConfig?.brandName)
    },
    {
      title: 'Transportation risk',
      tips: TRANSPOTATION_RICK(siteConfig?.brandName)
    },
  ]


  return (
    <>
      <GlobalHeaderBack title="Three Tips" height="50px" color="white" setViewLoginPopUp={setThreeTipsView} viewLoginPopUp={threeTipsView} />

      <ProductDetailsBlock padding="3%" marginTop="2%">
        <Space block align="center" justify="center" direction="vertical" className="three_tips">
          <span className="three_tips__title">{brandName?.label || 'We'} cares about you!</span>
          <span className="three_tips__sub_title">Three Things You Should Know</span>
        </Space>

        {
          threeTipsList?.map((tip, index) => {
            return (
              <div key={`tips-step-${index}`}>
                {
                  count === (index + 1) &&
                  <div className="tips_main">
                    {
                      tip?.tips?.map((item, idx) => {
                        return (
                          <Space key={`tip-content-${idx}`} direction="vertical" className="variation_value">
                            <div className="variation_value__main_title">
                              {tip?.title}
                            </div>
                            <div className="variation_value__title">
                              <span className="variation_value__number">0{index + 1}.</span> {item?.title}.
                            </div>
                            <div className="variation_value__sub_title">
                              {item?.text}
                            </div>
                          </Space>
                        )
                      })

                    }
                  </div>
                }
              </div>
            )
          })
        }
        <div className="three_tips_notice">
          <span className="three_tips_notice__icons"><InformationCircleFill /> </span> <span> <span className="three_tips_notice__title_bar">{shippingMethodsMap?.MoveOn?.label || 'MoveOn'}-Ship for me, </span>you can get the shipment service only and our <span className="three_tips_notice__order"> Dedicated Order Handler </span> if to provide you an instant solution to any type of your requirements.</span>
        </div>
        <div className={count === 3 ? 'visible' : 'invisible'}>
          <Radio defaultChecked onChange={handleCheckBoxChange}>I have read and agree to the <Link to="/pages/terms-of-services" className="link_underline"> Terms and Conditions</Link></Radio>
        </div>
        <Space block align="center" justify="end" style={{ "--gap": '14px' }}>
          <div className={count <= 1 ? 'invisible' : ""}>
            <Button color="default" fill="outline" onClick={handleGoBack}><CaretLeftOutlined />Back</Button>
          </div>
          <Button color="primary" onClick={handleTermsChange} >
            {
              isChecked && count === 3
                ? 'Agreed'
                : confirmButtonText
            }
          </Button>
        </Space>
      </ProductDetailsBlock>
    </>
  )
}

export default ThreeTips
