import React, {useState} from "react"
import "../../pages/Profile/components/ProfileDetails/ProfileDetails.scss"
import {Button, Form, Input, Toast} from "antd-mobile";
import {EyeFill} from "antd-mobile-icons";
import useErrors from "hooks/useErrors";
import axios from "axios";
import {config, LOCAL_STORAGE_KEY} from "constants/constants";
import {useAuth} from "context/auth-context";
import {socialLogin} from "services/auth-client";

const ChangePasswordModal = (props) => {

  const [form] = Form.useForm()
  const {getErrors} = useErrors()
  const {setChangePassword, fromSocial} = props
  const {getProfileData} = useAuth();

  const [isFormLoading, setIsFormLoading] = useState(false)
  const {authMeta} = useAuth();

  const [passwordShown, setPasswordShown] = useState(false);
  const [CPasswordShown, setCPasswordShown] = useState(false);
  const [CUPasswordShown, setCUPasswordShown] = useState(false);

  const togglePassword = (value) => {
    if (value === "cuPass") {
      setCUPasswordShown(!CUPasswordShown)
    } else if (value === "cPass") {
      setCPasswordShown(!CPasswordShown)
    } else {
      setPasswordShown(!passwordShown);
    }
  };

  const onFinishProfileUpdate = (value) => {

    const profileValue = {
      action: "password",
      new_password: value.password,
      new_password_confirmation: value.new_password_confirmation
    }
    const tokenData = localStorage.getItem(LOCAL_STORAGE_KEY)

    setIsFormLoading(true)
    if (fromSocial) {
      let data = {
        provider: authMeta?.provider,
        token: authMeta?.token,
        password: value.password,
        password_confirmation: value.new_password_confirmation
      }
      /*if (authMeta.intent === "social_login_password" ){
          data = {
              provider: authMeta?.provider,
              token: authMeta?.token,
              password: value.new_password,
          }
      }*/
      socialLogin(data)
        .then(res => {
          window.localStorage.setItem(LOCAL_STORAGE_KEY, res?.data?.token)
          if (res?.data?.token) {
            getProfileData(res?.data?.token)
          }
          setTimeout(() => {
            window.location.href = "/dashboard"
          }, 500);
          Toast.show({
            icon: "success",
            content: res?.data?.message,
          })
          setIsFormLoading(false)
          setChangePassword(false)
        }).catch(err => {
        if (err?.response?.data?.errors) {
          form.setFields(getErrors(err?.response?.data?.errors));
        }
        setIsFormLoading(false)
      })
    } else {
      axios.post(`${config.url.CONSUMER_API}/account/me`, profileValue, {
        headers: {
          "Accept": "application/json",
          "Authorization": "Bearer " + tokenData,
        },
      }).then(res => {
        window.location.href = "/dashboard"
        // history.push("/dashboard")
        Toast.show({
          icon: "success",
          content: res?.data?.message,
        })
        setIsFormLoading(false)
        setChangePassword(false)
      }).catch(err => {
        if (err?.response?.data?.errors) {
          form.setFields(getErrors(err?.response?.data?.errors));
        }
        setIsFormLoading(false)
      })
    }
  }

  return (
    <div className="profile_name">
      <div className="profile_info">
        <span className="profile_info__title">Password</span>
      </div>
      <Form
        onFinish={onFinishProfileUpdate}
        footer={
          <Button block type="submit" color="primary" loading={isFormLoading} disabled={isFormLoading}>
            Submit
          </Button>
        }
        className="profile_form"
        form={form}
      >
        <Form.Item
          name="password"
          label="Password"
          rules={[{required: true, message: "Please insert password"}]}
          extra={<span onClick={() => togglePassword("pass")}><EyeFill fontSize={22}/></span>}
        >
          <Input placeholder="Please enter password" type={passwordShown ? "text" : "password"}/>
        </Form.Item>
        {
          authMeta?.intent !== "social_login_password" &&
          <Form.Item
            name="new_password_confirmation"
            label="Password Confirmation"
            rules={[{required: true, message: "Please insert confirm password"}]}
            extra={<span onClick={() => togglePassword("cPass")}><EyeFill fontSize={22}/></span>}
          >
            <Input placeholder="Please insert confirm password"
                   type={CPasswordShown ? "text" : "password"}/>
          </Form.Item>
        }
      </Form>
    </div>
  );
}
export default ChangePasswordModal
