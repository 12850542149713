import React, {useEffect, useState} from "react";
import {GlobalHeaderBack, KeywordSearchProductDetails, KeywordSearchSkeleton, PageHead} from "components";
import "./InternalProduct.scss"
import useFilters from "hooks/useFilters";
import {useDispatch, useSelector} from "react-redux";
import {getInternalProducts} from "app/actions/internalProducts";
import {Grid, InfiniteScroll, Loading} from "antd-mobile";
import {ProductDetailsBlock} from "demos";
import qs from "qs";

const InternalProduct = () => {

    const dispatch = useDispatch();
    const {items, loading} = useSelector((state) => state.internalProducts);

    const [hasMore, setHasMore] = useState(false)

    const params = qs.parse((window.location.search).substring(1))

    const {
        filters,
        isFetched,
        initializeAvailableFilter,
    } = useFilters();

    useEffect(() => {
        if (!isFetched && params) {
            initializeAvailableFilter(params);
        }
    }, [isFetched, initializeAvailableFilter, params]);

    useEffect(() => {
        if (!isFetched) {
            dispatch(getInternalProducts(params));
        }
    }, [dispatch, params, isFetched]);

    let totalPage = parseInt(items?.last_page - 1)

    useEffect(() => {
        if (items?.current_page < totalPage) {
            setHasMore(true)
        }
    }, [items?.current_page, totalPage]);

    const InfiniteScrollContent = ({hasMore}) => {
        return (
            <>
                {
                    !hasMore ? (
                            <span>End of products</span>
                        )
                        : (
                            <div className="loading_middle">
                                <Loading color="primary"/>
                            </div>
                        )
                }
            </>
        )
    }


    const loadMoreProducts = () => {
        if (items) {
            dispatch(getInternalProducts({...filters, ...{page: items.current_page + 1}}));
            setHasMore(false)
        }
    }

    return (
        <>
            <PageHead title={`Internal Product`}/>

            <GlobalHeaderBack title="Internal Product" height="60px" color="white"/>

            <ProductDetailsBlock padding="0 2%" marginBottom="10%" background="none" marginTop="2%">
                <div className="search_results">
                    <>
                        {
                            !loading && items.data?.length >= 0 ?
                                <Grid columns={2} gap={8} className="masonry_grid">
                                    {
                                        items?.data?.map((elements, index) => {
                                            return (
                                                <Grid.Item
                                                    key={`product_search${index}`}
                                                    className="search_results_grid"
                                                >
                                                    <KeywordSearchProductDetails product={elements}
                                                                                 isProductLoading={loading}/>
                                                </Grid.Item>
                                            )
                                        })
                                    }
                                </Grid>
                                :
                                <KeywordSearchSkeleton/>
                        }
                    </>
                    {
                        items && items?.last_page > 1 && items?.current_page !== items?.last_page &&
                        <InfiniteScroll hasMore={hasMore} loadMore={loadMoreProducts}>
                            <InfiniteScrollContent hasMore={hasMore}/>
                        </InfiniteScroll>
                    }
                </div>
            </ProductDetailsBlock>
        </>
    );
}

export default InternalProduct
