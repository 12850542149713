import React, {useEffect, useState} from "react";
import {WalletSkeleton, GlobalHeaderBack, LoadMore, PageHead} from "components";
import {getMyWallet, getMyWalletTransactions, getWithdrawalsMethod, getWithdrawalTransactions} from "services/wallet";
import {DemoBlock} from "demos";
import {Button, CapsuleTabs, ErrorBlock, Grid, Loading, Popover, Popup, Space} from "antd-mobile";
import "./Wallet.scss"
import {EditSFill, ExclamationCircleFill} from "antd-mobile-icons";
import WithdrawMethod from "pages/Wallet/components/WithdrawMethod";
import {capitalizeFirstLetter, formatDate, ShowPriceWithCurrency} from "utils/helper";
import MakeWithdrawal from "pages/Wallet/components/MakeWithdrawal";
import StatusTag from "components/StatusTag/StatusTag";
import { useLayoutContext } from "context/LayoutContext";

const Wallet = () => {
    const { localeCurrency } = useLayoutContext();
    const [walletData, setWalletData] = useState(null);
    const [isWalletLoading, setIsWalletLoading] = useState(false);
    const [viewRefundMethod, setViewRefundMethod] = useState(false);
    const [makeWithDrawal, setMakeWithDrawal] = useState(false);
    const [isWithdrawing, setIsWithdrawing] = useState(false);
    const [withdrawal, setWithdrawal] = useState(null);

    const [errorMessage, setErrorMessage] = useState(null);

    const [isTransactionsLoading, setIsTransactionsLoading] = useState({
        isPageLoading: false,
        isButtonLoading: false,
    });
    const [transactionsData, setTransactionsData] = useState({
        data: [],
        meta: null,
    });

    const [isWithdrawLoading, setIsWithdrawLoading] = useState({
        isPageLoading: false,
        isButtonLoading: false,
    });
    const [withdrawData, setWithdrawData] = useState({
        data: [],
        meta: null,
    });

    const [isFirstCall, setIsFirstCall] = useState(true);

    const fetchWalletData = React.useCallback(
        () => {
            setIsWalletLoading(true)
            getMyWallet()
                .then(res => {
                    setWalletData(res.data)
                    setIsWalletLoading(false)
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        window.location.href = "/auth/login"
                    }
                    setIsWalletLoading(false)
                })
        },
        [],
    )

    const fetchTransactions = React.useCallback(
        (page) => {
            setIsTransactionsLoading({
                isPageLoading: transactionsData.data.length <= 0,
                isButtonLoading: true
            })
            getMyWalletTransactions(page)
                .then(res => {
                    let newData = [...transactionsData.data, ...res.data.data]
                    setTransactionsData({
                        data: newData,
                        meta: res.data.meta,
                    })
                    setIsTransactionsLoading({
                        isPageLoading: false,
                        isButtonLoading: false,
                    })
                })
                .catch(err => {
                    setIsTransactionsLoading({
                        isPageLoading: false,
                        isButtonLoading: false,
                    })

                    setErrorMessage(err?.response?.data?.message)
                })
        },
        [setTransactionsData, setIsTransactionsLoading, transactionsData],
    )

    const fetchWithdrawal = React.useCallback(
        (page) => {
            setIsWithdrawLoading({
                isPageLoading: withdrawData.data.length <= 0,
                isButtonLoading: true
            })
            getWithdrawalTransactions(page)
                .then(res => {
                    let newData = res.data.data
                    if (page) {
                        newData = [...withdrawData.data, ...res.data.data]
                    }
                    setWithdrawData({
                        data: newData,
                        meta: res.data.meta,
                    })
                    setIsWithdrawLoading({
                        isPageLoading: false,
                        isButtonLoading: false,
                    })
                })
                .catch(err => {
                    setIsWithdrawLoading({
                        isPageLoading: false,
                        isButtonLoading: false,
                    })
                    setErrorMessage(err?.response?.data?.message)
                })
        },
        [setWithdrawData, setIsWithdrawLoading, withdrawData],
    )

    const fetchWithdrawalsMethods = React.useCallback(
        () => {
            setIsWithdrawing(true)
            getWithdrawalsMethod()
                .then(res => {
                    setWithdrawal(res.data.methods)
                    setIsWithdrawing(false)
                })
                .catch(err => {
                    setIsWithdrawing(false)
                })
        },
        [],
    )

    useEffect(() => {
        if (isFirstCall) {
            fetchWalletData()
            fetchWithdrawalsMethods()
            fetchTransactions()
            fetchWithdrawal()
            setIsFirstCall(false)
        }
    }, [fetchWalletData, fetchWithdrawalsMethods, fetchTransactions, isFirstCall, fetchWithdrawal]);

    const findActiveAccount = withdrawal && Object.values(withdrawal).find((el) => el.is_default === true)

    if (isWithdrawing || isWalletLoading || isTransactionsLoading.isPageLoading) return (<WalletSkeleton/>)

    const loadMoreTransactions = () => {
        if (transactionsData.meta) {
            fetchTransactions((transactionsData.meta.current_page + 1))
        }
    }

    const loadMoreWithdrawal = () => {
        if (withdrawData.meta) {
            fetchWithdrawal((withdrawData.meta.current_page + 1))
        }
    }

    return (
        <>
            <PageHead title={`Wallet`}/>

            <GlobalHeaderBack title="Wallet" height="60px" color="white"/>

            <DemoBlock padding="7% 3%" margin="2% 3%" borderRadius="8px" borderLeft="2px solid var(--adm-color-primary)"
                       boxShadow="3px 5px 3px #dcdcdc">
                <Grid columns={13} gap={22}>
                    <Grid.Item span={7}>
                        <div className="withdraw_request">
                            <span>Refund Balance: </span>
                            {
                                !isWalletLoading ?
                                    <div className="withdraw_request__balance">{ShowPriceWithCurrency({price: walletData?.balance, currency: localeCurrency})}</div>
                                    :
                                    <Loading/>
                            }
                        </div><br/>
                        <div className="withdraw_request">
                            {
                                !isWalletLoading &&
                                <Space block align="center">
                                    <span>Hold balance: </span>
                                    <span className="withdraw_request__balance">{ShowPriceWithCurrency({price: walletData?.locked, currency: localeCurrency})}</span>
                                </Space>
                            }
                        </div>
                    </Grid.Item>
                    <Grid.Item span={6} className="wallet_button">
                        <Button color="primary" size="small" onClick={() => setMakeWithDrawal(true)}>Make
                            Withdrawal </Button>
                    </Grid.Item>
                </Grid>
            </DemoBlock>
            <DemoBlock padding="8% 3%" margin="2% 3%" borderRadius="8px" borderLeft="2px solid var(--adm-color-primary)"
                       boxShadow="3px 5px 3px #dcdcdc">
                <Grid columns={9} gap="8">
                    <Grid.Item span={8}>
                        <Space direction="vertical" className="withdraw_method">
                            <span className="withdraw_method__title">Withdraw Method </span>
                            {
                                findActiveAccount &&
                                <span
                                    className="withdraw_method__sub_title">Account number: {findActiveAccount?.account} {`(${findActiveAccount?.type})`}</span>
                            }
                            {
                                withdrawal && findActiveAccount?.type === "bank" &&
                                <span
                                    className="withdraw_method__sub_title">Bank Name: {findActiveAccount?.bank_name}</span>
                            }
                            {
                                withdrawal && findActiveAccount?.type === "bank" && findActiveAccount?.bank_branch &&
                                <span
                                    className="withdraw_method__sub_title">Branch Name: {findActiveAccount?.bank_branch}</span>
                            }
                            {
                                withdrawal && !findActiveAccount?.type === "bank" &&
                                <span className="withdraw_method__sub_title">Phone number: 0xxxxxxxxxx</span>
                            }
                        </Space>
                    </Grid.Item>
                    <Grid.Item span={1}>
                        <Button color="primary" size="small"
                                onClick={() => setViewRefundMethod(true)}><EditSFill/></Button>
                    </Grid.Item>
                </Grid>
            </DemoBlock>

            <DemoBlock margin="4% 3% 20% 3%" borderRadius="8px" borderLeft="2px solid var(--adm-color-primary)"
                       boxShadow="3px 5px 3px #dcdcdc">
                <CapsuleTabs className="wallet_capsule">
                    <CapsuleTabs.Tab title="Transactions" key="transactions">
                        {
                            !errorMessage ?
                                <div className="transactions_table">
                                    <span className="transactions_table__title">Transactions</span>
                                    <table>
                                        <thead>
                                        <tr>
                                            <th>Type</th>
                                            <th>Amount</th>
                                            <th>Date</th>
                                        </tr>
                                        </thead>
                                        <tbody className="trans_body">
                                        {
                                            transactionsData && transactionsData?.data?.map((transaction, index) => {
                                                return (
                                                    <tr key={`transaction${index}`}>
                                                        <td className="transactions_table__tasn">
                                                            <Space direction="vertical">
                                                                <span>{capitalizeFirstLetter(transaction?.type)}</span>
                                                            </Space>
                                                        </td>
                                                        <td className="transactions_table__amount">
                                                            <Space direction="vertical">
                                                                <span>{transaction.amount}</span>
                                                            </Space>
                                                        </td>
                                                        <td>
                                                            <Space direction="vertical">
                                                                <span
                                                                    className="transactions_table__tasn">{formatDate(transaction?.created_at)}</span>
                                                            </Space>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        </tbody>
                                    </table>
                                    <div style={{marginBottom: "5%"}}>
                                        {
                                            transactionsData?.meta && transactionsData?.meta.last_page > 1 && transactionsData?.meta.current_page !== transactionsData?.meta.last_page &&
                                            <LoadMore
                                                handleClick={loadMoreTransactions}
                                                isLoading={isTransactionsLoading.isButtonLoading}
                                                loadingText={"LoadMore"}
                                                loadMoreText={`LoadMore`}
                                            />
                                        }
                                    </div>
                                </div>
                                :
                                <DemoBlock padding="4% 3%" margin="4% 3% 20% 3%" borderRadius="8px"
                                           borderLeft="2px solid var(--adm-color-primary)"
                                           boxShadow="3px 5px 3px #dcdcdc">
                                    <ErrorBlock status="empty" title={errorMessage} description={null}/>
                                </DemoBlock>
                        }
                    </CapsuleTabs.Tab>
                    <CapsuleTabs.Tab title="Withdrawal History" key="withdrawal_history">
                        {
                            !errorMessage ?
                                <div className="withdraw_table">
                                    <span className="withdraw_table__title">Withdrawal History</span>
                                    <table>
                                        <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Amount</th>
                                            <th>Status</th>
                                            <th>Method</th>
                                        </tr>
                                        </thead>
                                        <tbody className="trans_body">
                                        {
                                            withdrawData && withdrawData?.data?.map((withdraw, index) => {
                                                return (
                                                    <tr key={`transaction${index}`}>
                                                        <td>
                                                            <Space direction="vertical">
                                                        <span
                                                            className="transactions_table__tasn">{formatDate(withdraw?.created_at)}</span>
                                                            </Space>
                                                        </td>
                                                        <td className="withdraw_table__amount">
                                                            <Space direction="vertical">
                                                                <span>{withdraw.amount}</span>
                                                            </Space>
                                                        </td>
                                                        <td><StatusTag text={withdraw?.status} slug={withdraw?.status}
                                                                       notRounded={true}/></td>
                                                        <td className="withdraw_table__tasn">
                                                            <Space style={{"--gap-horizontal": "2px"}}>
                                                                <span>{capitalizeFirstLetter(withdraw?.withdrawal_method?.type)}</span>
                                                                <Popover content={withdraw?.withdrawal_method?.account}
                                                                         placement={"top"} trigger="click">
                                                                    <ExclamationCircleFill/>
                                                                </Popover>
                                                            </Space>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        </tbody>
                                    </table>
                                    <div style={{marginBottom: "5%"}}>
                                        {
                                            withdrawData?.meta && withdrawData?.meta.last_page > 1 && withdrawData?.meta.current_page !== withdrawData?.meta.last_page &&
                                            <LoadMore
                                                handleClick={loadMoreWithdrawal}
                                                isLoading={isWithdrawLoading.isButtonLoading}
                                                loadingText={"LoadMore"}
                                                loadMoreText={"LoadMore"}
                                            />
                                        }
                                    </div>
                                </div>
                                :
                                <DemoBlock padding="4% 3%" margin="4% 3% 20% 3%" borderRadius="8px"
                                           borderLeft="2px solid var(--adm-color-primary)"
                                           boxShadow="3px 5px 3px #dcdcdc">
                                    <ErrorBlock status="empty" title={errorMessage} description={null}/>
                                </DemoBlock>
                        }
                    </CapsuleTabs.Tab>
                </CapsuleTabs>
            </DemoBlock>

            <Popup
                visible={viewRefundMethod}
                onMaskClick={() => {
                    setViewRefundMethod(false)
                }}
                bodyStyle={{
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px",
                    minHeight: "55vh",
                }}
            >
                <WithdrawMethod
                    setIsViewRefundMethod={setViewRefundMethod}
                    withdrawal={withdrawal}
                    isWithdrawing={isWithdrawing}
                    fetchWithdrawalsMethods={fetchWithdrawalsMethods}
                    findActiveAccount={findActiveAccount}
                />
            </Popup>

            <Popup
                visible={makeWithDrawal}
                onMaskClick={() => {
                    setMakeWithDrawal(false)
                }}
                bodyStyle={{
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px",
                }}
            >
                <div style={{overflowY: "scroll", height: "50vh"}}>
                    <MakeWithdrawal
                        setMakeWithDrawal={setMakeWithDrawal}
                        findActiveAccount={findActiveAccount}
                        fetchWithdrawal={fetchWithdrawal}
                    />
                </div>
            </Popup>
        </>
    );
}

export default Wallet
