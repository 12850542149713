import React from 'react'
import GatewayForm from './GatewayForm'
import {HomePageBlock} from "demos";

const InternalGateway = (props) => {
  const { data } = props
  const formItems = data?.form ? data.form : null
  const tabs = formItems.find(item => item.type === 'tab')
  const fieldCard = formItems.find(item => item.type === 'card')
  
  return (
    <HomePageBlock padding="2% 1%" marginBottom="13%">
        {/*<BankTab data={tabs} />*/}
      {
        fieldCard && <GatewayForm resData={data} data={fieldCard} bankTabs={tabs && tabs.tabs} total={parseFloat(data?.total)} />
      }
    </HomePageBlock>
  )
}

export default InternalGateway
