import React from 'react';
import '../dashboard.scss'
import { Grid, Space } from "antd-mobile";
import {
  CaretRightOutlined,
  CarOutlined, ExceptionOutlined,
  MoneyCollectOutlined,
  RedEnvelopeOutlined,
  ShopOutlined
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { ACTIVE_TAB, defaultKeys } from "consts/storage";
import { CheckShieldOutline } from "antd-mobile-icons";
import { ACTIVE_TAB_INDEX } from "constants/constants";
import { useLayoutContext } from 'context/LayoutContext';

const MyOrderLists = () => {
  const { visibleConfigs } = useLayoutContext();
  const history = useHistory()

  const handleTabSelect = (value, key) => {
    window.localStorage.setItem(ACTIVE_TAB, JSON.stringify(value))
    window.localStorage.setItem(ACTIVE_TAB_INDEX, JSON.stringify(key))
    history.push("/account/orders")
  }

  return (
    <div className="my-order">
      <Grid columns={2} gap={24}>
        <Grid.Item className="my-order__orders">
          <span>My Orders</span>
        </Grid.Item>
        <Grid.Item className="my-order__view-all">
          <span onClick={() => handleTabSelect("all", 0)} >View All <CaretRightOutlined /> </span>
        </Grid.Item>
      </Grid>
      <Grid columns={4} gap={1} className="order_menu">
        <Grid.Item onClick={() => handleTabSelect("to_pay", 1)}>
          <Space direction='vertical'>
            <span className="order_menu__icon"><MoneyCollectOutlined /></span>
            <span className="order_menu__text">To Pay</span>
          </Space>
        </Grid.Item>
        <Grid.Item onClick={() => handleTabSelect("to_be_purchased", 2)}>
          <Space direction='vertical'>
            <span className="order_menu__icon"><RedEnvelopeOutlined /></span>
            <span className="order_menu__text">In Transit</span>
          </Space>
        </Grid.Item>
        <Grid.Item onClick={() => handleTabSelect("to_deliverable", 3)}>
          <Space direction='vertical'>
            <span className="order_menu__icon"><ShopOutlined /></span>
            <span className="order_menu__text">Deliverable</span>
          </Space>
        </Grid.Item>
        <Grid.Item onClick={() => handleTabSelect("delivered", 5)}>
          <Space direction='vertical'>
            <span className="order_menu__icon"><CarOutlined /></span>
            <span className="order_menu__text">Delivered</span>
          </Space>
        </Grid.Item>
      </Grid>
      <Grid columns={visibleConfigs?.[defaultKeys.productRequestVisible] ? 2 : 1} gap={24} className="return_cancel">
        {
          visibleConfigs?.[defaultKeys.productRequestVisible] &&
          <Grid.Item onClick={() => history.push("/my-request")}>
            <span><ExceptionOutlined /> My Request</span>
          </Grid.Item>
        }
        <Grid.Item onClick={() => handleTabSelect("order_parcel", 4)}>
          <span ><CheckShieldOutline /> My Parcel</span>
        </Grid.Item>
      </Grid>
    </div>
  );
}

export default MyOrderLists;
