import React from 'react';
import NoVariations from '../NoVariations/NoVariations';
import ProductSize from '../ProductSize/ProductSize';
import VariationItem from '../VariationItem/VariationItem';

const WholesaleSkuSelections = ({ ssrProduct }) => {

  let productMeta = []
  let metaWithoutLastItem = []

  productMeta = ssrProduct?.attributes

  if (productMeta?.length <= 0 || productMeta === null) {

    return (
      <div className="product-variations">
        {
          <NoVariations meta={ssrProduct.skus} />
        }
      </div>
    )
  }

  if (productMeta?.length < 2) {

    return (
      <div className="product-variations">
        {
          <div className="product-variations__item">
            <ProductSize meta={productMeta[0]} />
          </div>
        }
      </div>
    )
  }

  metaWithoutLastItem = productMeta && productMeta.slice(0, productMeta?.length - 1);

  return (
    <div className="product-variations">
      <div className="product-variations__item clearfix">
        {
          metaWithoutLastItem?.map((item, idx) => {
            return <VariationItem propMeta={item} key={`product-variations-${idx}`} idx={idx} />
          })
        }
      </div>
      <div className="product-variations__item">
        {
          productMeta &&
          <ProductSize meta={productMeta[productMeta.length - 1]} />
        }
      </div>
    </div>
  )
}

export default WholesaleSkuSelections;
