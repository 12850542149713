import client from './api-client'
import Qs from "qs";

const getCart = (filter = null) => {
    return client.get('/cart/items', {
        params: filter,
        paramsSerializer: function (params) {
            return Qs.stringify(params, {arrayFormat: 'brackets'})
        },
    })
}

const getWishList = (page = null, filter = null, perPage = 15) => {
    let data = {
        page: page ? page : 1,
        per_page: perPage
    }

    if (filter) {
        data = {...data, ...filter}
    }
    return client.get('/wishlist/items', {
        params: data,
        paramsSerializer: function (params) {
            return Qs.stringify(params, {arrayFormat: 'brackets'})
        },
    })
}

const addToCart = (data) => {
    return client.post(`cart/add`, data)
}

const updateQuantity = (payload = {}) => {
    return client.patch(`/cart/update`, payload)
}

const updateNotes = (value, itemId) => {
    return client.patch(`/cart/update`, { id: itemId, instructions: value})
}

const removeCartItem = (ids) => {
    return client.delete(`cart/remove`, {
        params: {ids}
    })
}

const placeOrder = (data) => {
    return client.post(`/cart/checkout`, data)
}

const addToWishListItem = (data) => {
    return client.post(`wishlist/add`, data)
}

const deleteFormWishListItem = (ids) => {
    return client.delete(`wishlist/remove`, {
        params: {ids}
    })
}

const checkWishListProductExists = (ids) => {
    return client.post(`wishlist/exists`, {ids: ids})
}

const checkWishListProductExistsByShop = (data) => {
    return client.post('wishlist/exists-by-shop', data)
}

export {
    getCart,
    getWishList,
    addToCart,
    updateQuantity,
    placeOrder,
    removeCartItem,
    addToWishListItem,
    deleteFormWishListItem,
    checkWishListProductExists,
    updateNotes,
    checkWishListProductExistsByShop
}
