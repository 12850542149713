import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { RouteWithLayout, RouteWithOutAuthLayout } from "./components";
import BasicLayout from "./layout/BasicLayout";
import GenericNotFound from "./pages/GenericNotFound/GenericNotFound";
import WithoutTabBarLayout from "./layout/WithoutTabBarLayout";
import Login from "pages/Auth/Login";
import OtpLogin from "pages/Auth/OtpLogin";
import Register from "pages/Auth/Register";
import ForgetPassword from "pages/Auth/ForgetPassword";
import HomePageCraft from "pages/HomePageCraft";
import StoreAccount from "pages/StoreAccount";
import Dashboard from "pages/Dashboard";
import WishList from "pages/WishList";
import Cart from "pages/Cart";
import MyOrders from "pages/MyOrders";
import ProductFullDetails from "pages/MyOrders/components/ProductFullDetails";
import ParcelFullDetails from "pages/MyOrders/components/ParcelFullDetails";
import ProductDetails from "pages/ProductDetails";
import SearchResults from "pages/ProductSearch/components/SearchResults";
import ProfileDetails from "pages/Profile/components/ProfileDetails/ProfileDetails";
import ShippingAddress from "pages/Profile/components/ProfileDetails/ShippingAddress";
import Checkout from "pages/Checkout";
import MyRequest from "pages/MyRequest";
import RequestForm from "pages/MyRequest/components/RequestForm";
import SellerDetails from "pages/SellerDetails";
import PaymentSuccessful from "pages/Payments/components/PaymentSuccessful";
import PaymentDetails from "pages/Payments/index";
import SimilarProducts from "pages/SimilarProducts";
import ManualRequests from "pages/ManualRequests";
import Categories from "pages/Categories";
import IssueDetails from "pages/IssueList/components/IssueDetails";
import Pages from "pages/Pages";
import IssueList from "pages/IssueList";
import InternalProduct from "pages/InternalProduct";
import CampaignListLayout from "./pages/Campaigns/components/CampaignList";
import CampaignLayout from "./pages/Campaigns";
import WalletLayout from "./pages/Wallet";
import Notification from "./pages/Notification";
import LoginWithToken from "pages/Auth/LoginWithToken";
import RecentlyViewedProducts from "pages/RecentlyViewedProducts";
import CodFeedback from "pages/Payments/components/CodFeedback";
import WholesalePage from "pages/wholesale";
import UaeStoresPage from "pages/dubai";
import UsaStoresPage from "pages/usa";
import ElevenElevenPage from "pages/11.11";
import ChinaRetailPage from "pages/china";
import IndiaStoresPage from "pages/india";
import TrackingPage from "pages/tracking";
import routePaths from "consts/route-paths";
import EidOffersPage from "pages/eid-offers";
import DoubleChallengePage from "pages/doubleChallenge";
import BahrCartOpeningPage from "./pages/opening-offer";
import IndiaShippingChallenge from "pages/indiaShippingChallenge";

/*const HomePage = React.lazy(() => import("./pages/HomePage"))
const StoreAccount = React.lazy(() => import("./pages/StoreAccount"))
const Dashboard = React.lazy(() => import("./pages/Dashboard"))
const WishList = React.lazy(() => import("./pages/WishList"))

const Cart = React.lazy(() => import("./pages/Cart/index"))

const MyOrders = React.lazy(() => import("./pages/MyOrders/index"))
const ProductFullDetails = React.lazy(() => import("./pages/MyOrders/components/ProductFullDetails"))
const ParcelFullDetails = React.lazy(() => import("./pages/MyOrders/components/ParcelFullDetails"))

const ProductDetails = React.lazy(() => import("./pages/ProductDetails/index"))

const SearchResults = React.lazy(() => import("./pages/ProductSearch/components/SearchResults"))

const ProfileDetails = React.lazy(() => import("./pages/Profile/components/ProfileDetails/ProfileDetails"))
const ShippingAddress = React.lazy(() => import("./pages/Profile/components/ProfileDetails/ShippingAddress"))

const Checkout = React.lazy(() => import("./pages/Checkout/index"))

const MyRequest = React.lazy(() => import("./pages/MyRequest/index"))
const RequestForm = React.lazy(() => import("./pages/MyRequest/components/RequestForm"))

const SellerDetails = React.lazy(() => import("./pages/SellerDetails/index"))

const PaymentDetails = React.lazy(() => import("./pages/Payments/index"))
const PaymentSuccessful = React.lazy(() => import("./pages/Payments/components/PaymentSuccessful"))

const SimilarProducts = React.lazy(() => import("./pages/SimilarProducts"))

const Notification = React.lazy(() => import("./pages/Notification"))

const ManualRequests = React.lazy(() => import("./pages/ManualRequests"))
const Categories = React.lazy(() => import("./pages/Categories"))

const WalletLayout = React.lazy(() => import("./pages/Wallet"))
const InternalLayout = React.lazy(() => import("./pages/InternalProduct"))
const CampaignLayout = React.lazy(() => import("./pages/Campaigns"))
const CampaignListLayout = React.lazy(() => import("./pages/Campaigns/components/CampaignList"))
const IssueList = React.lazy(() => import("./pages/IssueList"))
const IssueDetails = React.lazy(() => import("./pages/IssueList/components/IssueDetails"))
const Pages = React.lazy(() => import("./pages/Pages"))*/

const Routes = () => {
  return (
    <Switch>
      <RouteWithOutAuthLayout
        component={HomePageCraft}
        exact
        layout={BasicLayout}
        path="/"
      />
      <RouteWithOutAuthLayout
        component={Login}
        layout={BasicLayout}
        path="/auth/login"
      />
      <RouteWithOutAuthLayout
        component={LoginWithToken}
        layout={BasicLayout}
        path="/auth/acting/:token"
      />
      <RouteWithOutAuthLayout
        component={OtpLogin}
        layout={BasicLayout}
        path="/auth/otp-login"
      />
      <RouteWithOutAuthLayout
        component={Register}
        layout={BasicLayout}
        path="/auth/register"
      />
      <RouteWithOutAuthLayout
        component={ForgetPassword}
        layout={BasicLayout}
        path="/auth/forget_password"
      />
      <RouteWithOutAuthLayout
        component={StoreAccount}
        exact
        layout={BasicLayout}
        path="/account-store"
      />
      <RouteWithLayout
        component={Dashboard}
        exact
        layout={BasicLayout}
        path="/dashboard"
      />
      <RouteWithLayout
        component={WishList}
        exact
        layout={BasicLayout}
        path="/wish-list"
      />
      <RouteWithLayout
        component={Cart}
        exact
        layout={BasicLayout}
        path="/cart"
      />
      <RouteWithLayout
        component={MyOrders}
        exact
        layout={BasicLayout}
        path="/account/orders"
      />
      <RouteWithLayout
        component={ProductFullDetails}
        layout={BasicLayout}
        path="/account/orders/:orderId"
      />
      <RouteWithLayout
        component={ParcelFullDetails}
        layout={BasicLayout}
        path="/account/parcel/:orderId"
      />
      <RouteWithOutAuthLayout
        component={ProductDetails}
        layout={WithoutTabBarLayout}
        path="/products/:slug/:product_code"
      />
      <RouteWithOutAuthLayout
        component={ProductDetails}
        layout={WithoutTabBarLayout}
        path="/products/details"
      />
      <RouteWithOutAuthLayout
        component={SearchResults}
        layout={BasicLayout}
        path="/products"
      />
      <RouteWithLayout
        component={ProfileDetails}
        layout={BasicLayout}
        path="/profile/details"
      />
      <RouteWithOutAuthLayout
        component={ShippingAddress}
        layout={BasicLayout}
        path="/profile/shipping-address"
      />
      <RouteWithLayout
        component={Checkout}
        exact
        layout={BasicLayout}
        path="/checkout"
      />
      <RouteWithLayout
        component={MyRequest}
        exact
        layout={BasicLayout}
        path="/my-request"
      />
      <RouteWithLayout
        component={RequestForm}
        exact
        layout={BasicLayout}
        path="/my-request/request"
      />
      <RouteWithOutAuthLayout
        component={SellerDetails}
        exact
        layout={BasicLayout}
        path="/seller-details"
      />
      <RouteWithOutAuthLayout
        component={PaymentSuccessful}
        exact
        layout={BasicLayout}
        path="/payment/feedback"
      />
      <RouteWithOutAuthLayout
        component={CodFeedback}
        exact
        layout={BasicLayout}
        path="/payment/cod-feedback"
      />
      <RouteWithOutAuthLayout
        component={PaymentDetails}
        exact
        layout={BasicLayout}
        path="/payment/:order"
      />
      <RouteWithOutAuthLayout
        component={SimilarProducts}
        exact
        layout={BasicLayout}
        path="/similar-products"
      />
      <RouteWithOutAuthLayout
        component={Notification}
        exact
        layout={BasicLayout}
        path="/notification"
      />
      <RouteWithOutAuthLayout
        component={ManualRequests}
        exact
        layout={BasicLayout}
        path="/manual-requests"
      />
      <RouteWithOutAuthLayout
        component={Categories}
        exact
        layout={BasicLayout}
        path="/categories"
      />
      <RouteWithLayout
        component={WalletLayout}
        exact
        layout={BasicLayout}
        path="/account/wallet"
      />
      <RouteWithOutAuthLayout
        component={CampaignLayout}
        exact
        layout={BasicLayout}
        path="/campaigns/:slug"
      />
      <RouteWithOutAuthLayout
        component={CampaignListLayout}
        exact
        layout={BasicLayout}
        path="/campaigns"
      />
      <RouteWithOutAuthLayout
        component={InternalProduct}
        exact
        layout={BasicLayout}
        path="/internal-product"
      />
      <RouteWithLayout
        component={IssueList}
        layout={BasicLayout}
        exact
        path="/issues"
      />
      <RouteWithLayout
        component={IssueDetails}
        layout={BasicLayout}
        exact
        path="/issues/:id"
      />
      <RouteWithOutAuthLayout
        component={Pages}
        exact
        layout={BasicLayout}
        path="/pages/:slug"
      />
      <RouteWithOutAuthLayout
        component={RecentlyViewedProducts}
        exact
        layout={BasicLayout}
        path="/recently-viewed-products"
      />
      <RouteWithOutAuthLayout
        component={ChinaRetailPage}
        exact
        layout={BasicLayout}
        path="/china"
      />
      <RouteWithOutAuthLayout
        component={WholesalePage}
        exact
        layout={BasicLayout}
        path="/wholesale"
      />
      <RouteWithOutAuthLayout
        component={UaeStoresPage}
        exact
        layout={BasicLayout}
        path="/dubai"
      />
      <RouteWithOutAuthLayout
        component={UsaStoresPage}
        exact
        layout={BasicLayout}
        path="/usa"
      />
      <RouteWithOutAuthLayout
        component={IndiaStoresPage}
        exact
        layout={BasicLayout}
        path="/india"
      />
      <RouteWithOutAuthLayout
        component={ElevenElevenPage}
        exact
        layout={BasicLayout}
        path="/11.11"
      />
      <RouteWithOutAuthLayout
        component={BahrCartOpeningPage}
        exact
        layout={BasicLayout}
        path="/opening-offer"
      />
      <RouteWithOutAuthLayout
        component={EidOffersPage}
        exact
        layout={BasicLayout}
        path="/eid-offers"
      />
      <RouteWithOutAuthLayout
        component={DoubleChallengePage}
        exact
        layout={BasicLayout}
        path="/double-challenge"
      />
      <RouteWithOutAuthLayout
        component={IndiaShippingChallenge}
        exact
        layout={BasicLayout}
        path="/india-shipping-challenge"
      />
      <RouteWithOutAuthLayout
        component={TrackingPage}
        exact
        layout={BasicLayout}
        path={routePaths.pages.tracking}
      />
      <Route path="/404" component={GenericNotFound} />
      <Redirect to="/404" />
    </Switch>
  );
};

export default Routes;
