import React from 'react'
import './Myorders.scss'
import {HomePageBlock} from "demos";
import {PageHead} from "components";
import MyOrderDetailsView from "./components/MyOrderDetailsView";
import {MyOrderProvider} from "context/my-order-context";
import {GlobalHeaderBack} from "../../components";

const MyOrders = () => {
    return (
        <MyOrderProvider>
            <PageHead title={`My Orders`}/>

            <GlobalHeaderBack title="Orders" height="50px" color="white"/>

            <div className="orders">
                {/*<HomePageBlock padding="3%">*/}
                {/*    <HeaderBack/>*/}
                {/*</HomePageBlock>*/}
                <HomePageBlock padding="2px 8px" background="none" backgroundColor="transparent">
                    <MyOrderDetailsView />
                </HomePageBlock>
            </div>
        </MyOrderProvider>
    )
}

export default MyOrders
