import {all, call, put, takeEvery} from "redux-saga/effects"
import * as types from 'app/actionTypes/system';
import {getCountries, getCurrencies, getDistrict, getHomePageMenu, getLanguages, getPostCode} from "services/settings-client";
import { getConfigInfo } from "services/product-client";

const getApi = async (filters = {}) => {
    return getPostCode(filters)
        .then(res => res.data)
        .catch((error) => { throw error })
}

const getStateApi = async (filters = {}) => {
    return getDistrict(filters)
        .then(res => res.data)
        .catch((error) => { throw error })
}

function* fetchPostCode(action) {
    try {
        const data = yield call(() => getApi(action.payload));
        yield put({ type: types.GET_POST_CODE_SUCCESS, items: data });
    } catch (e) {
        yield put({ type: types.GET_POST_CODE_FAILED, message: e.message });
    }
}

function* fetchDistrict(action) {
    try {
        const data = yield call(() => getStateApi(action.payload));
        yield put({ type: types.GET_DISTRICT_SUCCESS, items: data });
    } catch (e) {
        yield put({ type: types.GET_DISTRICT_FAILED, message: e.message });
    }
}

const fetchCategoriesApi = async (filters = {}) => {
  return getHomePageMenu()
      .then(res => res.data)
      .catch((error) => { throw error })
}

function* fetchCategories(action) {
  try {
      const data = yield call(() => fetchCategoriesApi());
      yield put({ type: types.GET_CATEGORIES_SUCCEEDED, payload: data.data });
  } catch (e) {
      yield put({ type: types.GET_CATEGORIES_FAILED, message: e.message });
  }
}

const fetchConfigsApi = async () => {
  return getConfigInfo()
    .then(res => res.data)
    .catch((error) => { throw error })
}

function* fetchConfigsData() {
  try {
    const data = yield call(() => fetchConfigsApi());
    yield put({type: types.GET_CONFIGS_SUCCEEDED, payload: data});
  } catch (e) {
    yield put({type: types.GET_CONFIGS_FAILED, message: e.message})
  }
}

const fetchCountriesApi = async () => {
  return getCountries()
    .then(res => res.data)
    .catch((error) => { throw error })
}

function* fetchCountriesData() {
  try {
    const data = yield call(() => fetchCountriesApi());
    yield put({type: types.GET_COUNTRIES_SUCCEEDED, payload: data});
  } catch (e) {
    yield put({type: types.GET_COUNTRIES_FAILED, message: e.message})
  }
}

const fetchCurrenciesApi = async () => {
  return getCurrencies()
    .then(res => res.data)
    .catch((error) => { throw error })
}

function* fetchCurrenciesData() {
  try {
    const data = yield call(() => fetchCurrenciesApi());
    yield put({type: types.GET_CURRENCIES_SUCCEEDED, payload: data});
  } catch (e) {
    yield put({type: types.GET_CURRENCIES_FAILED, message: e.message})
  }
}

const fetchLanguagesApi = async () => {
  return getLanguages()
    .then(res => res.data)
    .catch((error) => { throw error })
}

function* fetchLanguagesData() {
  try {
    const data = yield call(() => fetchLanguagesApi());
    yield put({type: types.GET_LANGUAGES_SUCCEEDED, payload: data});
  } catch (e) {
    yield put({type: types.GET_LANGUAGES_FAILED, message: e.message})
  }
}

function* systemSaga() {
    yield all([
        takeEvery(types.GET_POST_CODE_REQUEST, fetchPostCode),
        takeEvery(types.GET_DISTRICT_REQUEST, fetchDistrict),
        takeEvery(types.GET_CATEGORIES_REQUESTED, fetchCategories),
        takeEvery(types.GET_CONFIGS_REQUESTED, fetchConfigsData),
        takeEvery(types.GET_COUNTRIES_REQUESTED, fetchCountriesData),
        takeEvery(types.GET_LANGUAGES_REQUESTED, fetchLanguagesData),
        takeEvery(types.GET_CURRENCIES_REQUESTED, fetchCurrenciesData),
    ]);
}

export default systemSaga;
