import React from 'react'
import './LoadingState.scss'
import { Loading } from "antd-mobile";

// Splash Screen
const LoadingState = () => {
  let spalshLogo = process.env.REACT_APP_BRAND_NAME || 'Loading...';

  return (
    <div className="loading_value">
      <div className="loading_value__color-picker">
        <span> {spalshLogo} </span>
        <Loading color="var(--adm-color-primary)" />
      </div>
    </div>
  )
}

export default LoadingState
