import React, { useEffect, useState } from "react";
import "./SearchStore.scss"
import { Divider, Grid, Image, Popover, Space } from "antd-mobile";
import { Link } from "react-router-dom";
import { QuestionCircleOutlined } from "@ant-design/icons";
import * as dayjs from "dayjs"
import { STORE_STORAGE } from "constants/constants";
import { getStoreData } from "services/settings-client";
import { LoadingState } from "components";
import { getLocalData } from "utils/helper";
import { useLayoutContext } from "context/LayoutContext";

const SearchStore = () => {
  const { appLogo } = useLayoutContext();
  const [storeData, setStoreData] = useState(null);
  const [isStoreLoading, setIsStoreLoading] = useState(false);

  let store = getLocalData(STORE_STORAGE)

  const fetchShopData = React.useCallback(
    () => {
      setIsStoreLoading(true)
      getStoreData()
        .then(res => {
          setStoreData(res?.data?.shops)
          setIsStoreLoading(false)
          let rightNowTimeOfSaveData = new Date()
          let shopDataObject = { value: res?.data?.shops, timestamp: dayjs(rightNowTimeOfSaveData).format() }
          localStorage.setItem(STORE_STORAGE, JSON.stringify(shopDataObject));
        })
        .catch(err => {
          setIsStoreLoading(false)
        })
    },
    [],
  )

  let rightNowTime = new Date()
  let formatDayJS = new Date(dayjs(rightNowTime).format("M/D/YYYY"))
  let storeDateTime = store?.timestamp
  let storeFormatDayJS = new Date(dayjs(storeDateTime).format("M/D/YYYY"))

  const diffTime = Math.abs(formatDayJS - storeFormatDayJS);
  const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));


  useEffect(() => {
    if (diffHours > 2 || !store?.value) {
      fetchShopData()
    }
  }, [diffHours, fetchShopData, store?.value]);

  if (isStoreLoading) {
    return (
      <LoadingState />
    )
  }

  let storeValueData = store?.value ? store?.value : storeData

  return (
    <div className="search_store">
      <Grid columns={22} gap={2} className="search_store_grid">
        <Grid.Item span={14}>
          <span className="search_store_grid__text title-text">Visit the site directly to shop items</span>
        </Grid.Item>
        <Grid.Item span={8} style={{ textAlign: "right", marginTop: "4%" }}>
          <Popover content="Hello World" placement={"right"} trigger="click">
            <QuestionCircleOutlined />
          </Popover> <span className="title-text">
            <Link to="/">Watch Tutorial</Link>
          </span>
        </Grid.Item>
      </Grid>
      <div className="store_lists">
        <Grid columns={4} gap={4} className="store_lists_grid">
          {
            storeValueData && storeValueData?.length > 0 && storeValueData?.slice(0, 8)?.map((itemImage, index) => {
              return (
                <Grid.Item className="store_lists_grid_item" key={`itemImage${index}`}
                  onClick={() => window.open(itemImage?.url)}>
                  <Space block justify="center" align="center" direction="vertical"
                    style={{ textAlign: "center" }}>
                    <Image
                      src={appLogo?.image}
                      fit="contain"
                      width="60px"
                      height="60px"
                      style={{ borderRadius: 16, marginBottom: "10%", backgroundColor: "#f0f0f0" }}
                    />
                    <Link to={itemImage?.url ? itemImage?.url : ""}>
                      <span>{itemImage?.name ? itemImage?.name : "Shop"}</span>
                    </Link>
                  </Space>
                </Grid.Item>
              )
            }
            )}
        </Grid>
      </div>
      <Divider />
      <Link to="/">Unable to find items? Contact our Export. </Link>
    </div>
  );
}

export default SearchStore;
