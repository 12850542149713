import * as Types from 'app/actionTypes/productDetails';
import produce from "immer";
import { productReDesign } from 'utils/productAdopter';

const initialState = {
  data: null,
  loading: false,
  isFetched: false,
  isFailed: false,
  shipping: null,
  isShippingLoading: true,
  domesticShipping: null,
  isDomesticShippingLoading: true,
  isDetailsChanged: false,
  shadowProducts: [],
  selectedShadowProduct: null,
  isSameProduct: false,
  isShadowChecked: false,
  internalProduct: {
    data: null,
    loading: false,
    error: null,
    isFetched: false,
  },
  refetchCount: 3,
}

let defaultShipping = [
  {
    "id": 32,
    "title": "MoveOn - Ship For Me",
    "time": "15-25",
    "identifier": "mvn_ship_for_me",
    "tracking": false,
    "notice": "Shipping charge will be calculated after product has been received.",
    "fee_type": "estimated",
    "options": null
  }
]

export default function productsDetails(state = initialState, action) {
  const { payload } = action;
  return produce(state, draft => {
    switch (action.type) {
      case Types.SET_SHADOW_PRODUCTS:
        let isExistingShadow = state.shadowProducts.some(item => item.link === payload.link)
        if (!isExistingShadow) {
          draft.shadowProducts = [...state.shadowProducts, payload]
        }
        break;
      case Types.GET_SHADOW_PRODUCT:
        let desiredProduct = state.shadowProducts.find(item => item[payload.type] === payload.value)
        if (desiredProduct?.id) {
          draft.selectedShadowProduct = desiredProduct
          draft.isSameProduct = true;
        } else {
          draft.isSameProduct = false;
          draft.selectedShadowProduct = null;
        }
        draft.isShadowChecked = true;
      break;
      case Types.GET_DETAILS_REQUESTED:
        draft.loading = true
        draft.isFetched = false
        break
      case Types.GET_DETAILS_SUCCESS:
        draft.data = action.data
        draft.loading = false
        draft.isDetailsChanged = true;
        draft.isFetched = true
        break
      case Types.GET_DETAILS_FAILED:
        draft.loading = false
        draft.isFailed = true
        draft.refetchCount = state.refetchCount - 1;
        break
      case Types.PRODUCT_DETAILS_CHANGED:
        draft.loading = false;
        draft.isFetched = false;
        draft.isSameProduct = false;
        draft.isDetailsChanged = false;
        draft.data = null;
        draft.isShadowChecked = false;
        draft.internalProduct.data = null;
        draft.internalProduct.isFetched = false;
        draft.isFailed = false;
        draft.shipping = null;
        draft.domesticShipping = null;
        if(state.refetchCount === 0 && !state.data?.id && state.isFailed) {
          draft.refetchCount = 3;
          draft.isFailed = false;
          draft.data = null;
        }
        break;
      case Types.SET_STATE_PRODUCT_DETAILS:
        let productData = null;
        if (payload?.id) {
          productData = payload;
        } else {
          productData = state.selectedShadowProduct
        }
        draft.data = productData;
        draft.loading = false
        draft.isDetailsChanged = true;
        draft.isFetched = true;
      break;
      case Types.GET_SHIPPING_CHARGES_REQUESTED:
        draft.isShippingLoading = true
        break
      case Types.GET_SHIPPING_CHARGES_SUCCESS:
        if (action?.data?.data?.length) {
          draft.shipping = action.data.data
        } else {
          draft.shipping = defaultShipping
        }
        draft.isShippingLoading = false
        break
      case Types.GET_SHIPPING_CHARGES_FAILED:
        draft.shipping = defaultShipping;
        draft.isShippingLoading = false
        break
      case Types.RESET_DETAILS:
        draft.loading = state.loading
        break
      case Types.GET_DOMESTIC_CHARGES_REQUESTED:
        draft.isDomesticShippingLoading = true
        break
      case Types.GET_DOMESTIC_CHARGES_SUCCESS:
        draft.domesticShipping = action.data
        draft.isDomesticShippingLoading = false
        break
      case Types.GET_DOMESTIC_CHARGES_FAILED:
        draft.isDomesticShippingLoading = false
        break
      case Types.GET_INTERNAL_PRODUCT_REQUESTED:
        draft.internalProduct.loading = true;
        draft.loading = true;
        draft.isFetched = false
      break;
      case Types.GET_INTERNAL_PRODUCT_SUCCEEDED:
        let updatedProduct = {
          ...payload.data,
          shipping: payload?.shipping,
          campaign: payload?.campaign,
        }
        if (updatedProduct?.id) {
          updatedProduct = productReDesign(updatedProduct);
        }
        draft.internalProduct.data = updatedProduct;
        draft.internalProduct.isFetched = true;
        draft.internalProduct.loading = false;
        draft.loading = false;
        draft.isFetched = true;
        draft.isDetailsChanged = true;
      break;
      case Types.GET_INTERNAL_PRODUCT_FAILED:
        draft.internalProduct.loading = false;
        draft.internalProduct.isFetched = false;
        draft.isFailed = true
        draft.refetchCount = state.refetchCount - 1;
        draft.loading = false
        draft.isFetched = false
        break;
      default:
        return state
    }
  })
}
