import React from "react";

const YoutubeEmbed = (props) => {
  const { videoId, title, } = props;

  return (
    <div style={{
      margin: props?.margin,
      padding: '8px',
      border: '2px solid #ddd',
      borderRadius: '12px',
      boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
      background: props?.background
    }}>
      <div className="video-responsive overflow-hidden pb-[56.25%] relative h-0"
        style={{
          // overflow: 'hidden',
          // padddingBottom: '56.25%',
          // position: 'relative',
          // height: 0
        }}
      >
        <iframe
          className="absolute left-0 top-0 h-full w-full"
          style={{
            // position: 'absolute',
            // left: 0,
            // top: 0,
            // height: '100%',
            width: '100%',
            minHeight: '180px'
          }}
          src={`https://www.youtube.com/embed/${videoId}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title={title ?? "Embedded youtube"}
        />
      </div>
      {
        title &&
        <h3 className="text-center font-medium mt-2" style={{margin: '5px 0', textAlign: 'center'}}>{title}</h3>
      }
    </div>
  )
};

export default YoutubeEmbed;
