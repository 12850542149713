import React, {useEffect, useState} from "react";
import {getPagesDetails} from "services/product-client";
import {useParams} from "react-router-dom";
import {ErrorBlock} from "antd-mobile";
import {GlobalHeaderBack, LoadingState, PageHead} from "components";
import {ProductDetailsBlock} from "demos";

const Pages = () => {

  let {slug} = useParams();

  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isPageData, setIsPageData] = useState(null);

  const fetchPageDetails = React.useCallback(
    () => {
      setIsPageLoading(true)
      getPagesDetails(slug)
        .then(res => {
          setIsPageData(res.data.data)
          setIsPageLoading(false)
        })
        .catch(err => {
          setIsPageLoading(false)
        })
    },
    [slug],
  )

  useEffect(() => {
    if (slug){
      fetchPageDetails()
    }
  }, [fetchPageDetails, slug]);

  if (isPageLoading) {
    return <LoadingState />
  }

  return (
    <>
      <PageHead title={`My Orders`}/>

      <GlobalHeaderBack title={isPageData?.title} height="50px" color="white"/>
      <ProductDetailsBlock padding="3% 5%">
        <h2 style={{textAlign: "center"}}>{isPageData?.title}</h2>
        {
          !isPageLoading && isPageData ?
            <div className="product-reviews-wrap">
              <div
                dangerouslySetInnerHTML={{
                  __html: `${isPageData.content}`
                }}>
              </div>
            </div>
            :
            <>
              <ErrorBlock status='empty' title="No Description Found"
                          description=""/>
            </>
        }
      </ProductDetailsBlock>
    </>
  );
}

export default Pages;
