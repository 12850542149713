import { useLayoutContext } from 'context/LayoutContext';
import { useProduct } from 'context/product-context';
import React from 'react';
import { getLocaleValue } from 'utils/helper';

const WholesalesPriceRanges = ({ wholesales, fromTitle }) => {
  const { localeCurrency, localeCountry } = useLayoutContext();
  const { totalQty } = useProduct()

  return (
    <div
      className="ws_qty_range"
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'start', fontSize: fromTitle ? '16px' : '12px', gap: '5px' }}
    >
      {
        wholesales?.map((item, idx, wsAra) => {
          let upto = idx < wsAra.length - 1 ? wsAra[idx + 1]?.qty - 1 : item.qty;
          let isWsRangeActive = false;
          if (idx === wsAra.length - 1) {
            isWsRangeActive = totalQty >= upto;
          } else {
            isWsRangeActive = totalQty >= item?.qty && totalQty <= upto;
          }

          return (
            <div
              key={`${item.qty}-${item.original}`}
              className="ws_qty_range__box"
            >
              {
                isWsRangeActive &&
                <div className="bg-overlay ws_qty_range__bg_overlay" />
              }
              <div className="ws_qty_range__price">
                {
                  item?.discount ?
                  <>
                    <strong style={{fontSize: '15px'}}>
                      {/* ShowPriceWithCurrency({ price: item?.discount, currency: localeCurrency }) */}
                      {getLocaleValue({
                        value: item?.discount,
                        locales: `en-${localeCountry.code}`,
                        currency: localeCurrency.code ,
                        currencyDisplay: 'narrowSymbol'
                      })}
                    </strong>
                    <div className="text-xs text-gray-500" style={{fontSize: '11px'}}>
                      <del>
                      {getLocaleValue({
                        value: item?.original,
                        locales: `en-${localeCountry.code}`,
                        currency: localeCurrency.code ,
                        currencyDisplay: 'narrowSymbol'
                      })}
                      </del>
                    </div>
                  </>
                  : <strong style={{fontSize: '15px'}}>
                      {getLocaleValue({
                        value: item?.original,
                        locales: `en-${localeCountry.code}`,
                        currency: localeCurrency.code ,
                        currencyDisplay: 'narrowSymbol'
                      })}
                  </strong>
                }
              </div>
              <div style={{ marginTop: '5px', fontSize: '12px' }}>
                {
                  idx === wsAra.length - 1 ?
                    <span>≥ {upto}{fromTitle ? ' qty' : null}</span>
                    : <span>≥ {item?.qty}{fromTitle ? ' qty' : null}</span>
                }

              </div>
            </div>
          )
        })
      }
    </div>
  )
}

export default WholesalesPriceRanges;
