import * as actionTypes from 'app/actionTypes/builder';
import produce from 'immer';

const initialState = {
    data: null,
    loading: true,
    error: null,
    sections: {

    },
    isFirstCall: true,
    isFallbackFirstCall: true,
}

const makeNestedNodes = (nodes, jsonData) => {
    let nestedNodes = [];
    nodes.forEach(nodeID => {
        let node = jsonData[nodeID];
        let key = node.custom?.displayName;
        let parentNodeType = jsonData?.[node.parent]?.displayName;
        let newSection = {
            ...node,
            sectionID: nodeID,
            sectionKey: `${nodeID}-${key}`,
            title: key ? key.replaceAll('_', ' ') : node.displayName,
            parentNodeType,
            props: {
                ...node.props,
                nodeID,
            }
        };
        if (node.nodes) {
            newSection['children'] = makeNestedNodes(node.nodes, jsonData);
        }
        nestedNodes.push(newSection)
    })

    return nestedNodes;
}

export default function builder(state = initialState, action) {
    const { type, payload } = action;
    return produce(state, draft => {
        switch (type) {
            case actionTypes.GET_BUILDER_REQUESTED:
                draft.loading = true;
                break;
            case actionTypes.GET_BUILDER_SUCCEEDED:
                let widgets = makeNestedNodes(payload.widgets.ROOT.nodes, payload.widgets);
                draft.data = {
                    ...payload,
                    widgets,
                    isFetched: true,
                };
                draft.loading = false;
                 draft.isFallbackFirstCall = false;
                break;
            case actionTypes.GET_BUILDER_FAILED:
                draft.loading = false;
                 draft.isFallbackFirstCall = false;
                break;
            case actionTypes.SET_BUILDER_DATA: {
                let widgets = makeNestedNodes(payload.widgets.ROOT.nodes, payload.widgets);
                draft.data = {
                    ...payload,
                    widgets
                };
                draft.loading = false;
            }
            break;
            case actionTypes.SET_BUILDER_SECTION_DATA:
              if (payload.isInfinity && draft.sections?.[payload.id]?.items) {
                draft.sections[payload.id].items = [
                  ...draft.sections[payload.id].items,
                  ...payload.data?.items,
                ]
                draft.sections[payload.id].paginate = payload.data?.paginate
                draft.sections[payload.id] = {
                  ...draft.sections[payload.id],
                  isFetched : true,
                  isLoading: false,
                }
              } else {
                draft.sections[payload.id] = payload.data
                draft.sections[payload.id] = {
                  ...draft.sections[payload.id],
                  isFetched : true,
                  isLoading: false,
                }
              }
            break;
            case actionTypes.DEFAULT_BUILDER_REQUESTED:
              draft.loading = true;
              break;
            case actionTypes.DEFAULT_BUILDER_SUCCEEDED: {
              let widgets = makeNestedNodes(payload.widgets.ROOT.nodes, payload.widgets);
                draft.data = {
                    ...payload,
                    widgets,
                    isFetched: true,
                    isDefaultFetched: true,
                };
                draft.loading = false;
                draft.isFirstCall = false;
            }
            break;
            case actionTypes.DEFAULT_BUILDER_FAILED:
              draft.loading = false;
              draft.isFirstCall = false;
              break;
            default:
                return state;
        }
    })
}
