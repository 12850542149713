import React, { useEffect } from "react";
import "./ProductSpecificationMeta.scss";
import { Button, DotLoading, Image, List, Popup, Space, Steps } from "antd-mobile";
import {
  CarOutlined,
  DollarCircleOutlined,
  FileProtectOutlined,
  ProfileOutlined,
  RocketOutlined
} from "@ant-design/icons";
import qs from "qs";
import Disclaimer from "../Disclaimer";
import ProductSpecification from "../ProductSpecification";
import ProductVariationsSelected from "../ProductVariationsSelected";
import ShippingDetails from "../ShippingDetails";
import { useProduct } from "context/product-context";
import { changeImageHostName, getLocalData, getShippingLabel, getShippingUnitFee } from "utils/helper";
import { ContentOutline, FileWrongOutline } from "antd-mobile-icons";
import ThreeTips from "pages/ProductDetails/components/ThreeTips";
import { useDispatch, useSelector } from "react-redux";
import { getCategoryDetails } from "app/actions/category";
import { STORE_STORAGE } from "constants/constants";
import { useLayoutContext } from "context/LayoutContext";
import ApproxWeightCost from "../ProductDetailComponents/components/ApproxWeightCost/ApproxWeightCost";
import { DISCLAIMER, defaultKeys } from "consts/storage";
import { requestShippingSlots } from "../../../../services/product-client";

const { Step } = Steps;

const ProductSpecificationMeta = () => {
  const {
    localeCurrency,
    defaultShippingUnit,
    notifyMessages,
    shippingMethodsMap,
    localeCountry,
    getConfigValue
  } = useLayoutContext();
  const params = qs.parse((window.location.search).substring(1));
  const { isDetailsChanged } = useSelector(state => state.productsDetails);
  const { filteredBy, loading: shippingCatsLoading } = useSelector((state) => state.category);
  let storeLocal = getLocalData(STORE_STORAGE);
  const {
    shippingMethodsData,
    selectedShippingDetails,
    viewDisclaimer,
    setViewDisclaimer,
    viewVariations,
    setViewVariations,
    viewSpecification,
    setViewSpecification,
    viewShippingSelect,
    setViewShippingSelect,
    state: productData,
    setThreeTipsView,
    threeTipsView,
    categoryShippingCharge,
    setCategoryShippingCharge,
    productStore,
    isShippingMethodsLoading,
    isProductLoading,
    approxWeight,
    shippingRate,
    isCampaignItem,
  } = useProduct();
  const { data: frCats } = useSelector((state) => state.category);

  const dispatch = useDispatch();

  useEffect(() => {
    const catId = parseInt(getConfigValue(defaultKeys.fixedShippingCategoryId)?.value);

    if (catId > 0 && frCats?.length > 0) {
      const cat = frCats.find(cat => cat.id === catId);
      if (cat) {
        fetchShippingSlots({ cat });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [frCats]);

  const fetchShippingSlots = ({ cat, foreign_type = "moveon" }) => {
    requestShippingSlots({
      country_id: productData?.country_id,
      foreign_type,
      shipping_category_id: cat?.id,
    })
      .then(({ data }) => {
        setCategoryShippingCharge({
          ...cat,
          price: data?.data?.price,
          slots: data?.data?.slots?.length > 0 ? data.data.slots : [],
        });
      });
  };

  useEffect(() => {
    let desiredShopCountry;
    let shopsArray = storeLocal.value;
    if (productData?.source?.country) {
      desiredShopCountry = { country_code: productData?.source?.country };
    } else if (productStore?.country_code) {
      desiredShopCountry = productStore;
    }
    let hasCountryCode = !!desiredShopCountry?.country_code;
    if (!hasCountryCode) {
      desiredShopCountry = { country_code: "CN" };
    }
    let hasShippingCats = desiredShopCountry?.country_code !== filteredBy?.country && shopsArray?.length && !!productData?.link;

    if (isDetailsChanged && hasShippingCats && !params?.campaign_product && !shippingCatsLoading) {
      dispatch(getCategoryDetails({ type: "moveon", country: desiredShopCountry?.country_code }));
    }
  }, [isDetailsChanged, dispatch, params?.campaign_product, productData, storeLocal, filteredBy?.country, shippingCatsLoading, productStore]);

  const brandServiceDisclaimer = productData?.extras?.brandServiceDisclaimer || notifyMessages?.brand_service_disclaimer?.label || DISCLAIMER;

  let shippingFeePayload = {
    productData,
    selectedShippingDetails,
    categoryShippingCharge,
    approxWeight, shippingRate,
    shippingMethodsData,
    localeCurrency,
    defaultShippingUnit,
    isCampaignItem,
  };

  let shippingFee = getShippingUnitFee(shippingFeePayload);

  let titleOfSpecification =
    <div className="title-text">To {localeCountry?.name} via <span className="shipping_details_spe">{getShippingLabel({
      shippingIdentifier: selectedShippingDetails?.identifier,
      title: selectedShippingDetails?.title,
      shippingMethodsMap
    }) || (selectedShippingDetails?.title ? selectedShippingDetails?.title : shippingMethodsData && shippingMethodsData[0]?.title)}</span>
    </div>;
  if (isCampaignItem) {
    titleOfSpecification =
      <div className="title-text">To {localeCountry?.name} via <span className="shipping_details_spe">{getShippingLabel({
        shippingIdentifier: "mvn_ship_for_me",
        shippingMethodsMap
      }) || `${shippingMethodsMap?.MoveOn?.label || "MoveOn"}-Ship For Me`}</span></div>;
  }
  let estimated =
    <span>Estimated delivery time {selectedShippingDetails?.time ? selectedShippingDetails?.time : shippingMethodsData && shippingMethodsData[0]?.time} days</span>;

  if (isCampaignItem) {
    estimated = <span>Estimated delivery time 15-25 days</span>;
  }
  let desTitle = `From ${productStore?.country_name || "China"}`;

  let shippingUnitRate = <span className="shipping_details_spe__rate">Shipping: {shippingFee}</span>;

  const titleShipping = <Steps direction="vertical" current={1} className="shipping_step">
    <Step
      title={titleOfSpecification}
      description={shippingUnitRate}
      icon={<CarOutlined />}
    />
    <Step
      title={desTitle}
      description={estimated}
      icon={<RocketOutlined />}
    />
  </Steps>;

  const specifications = productData?.specifications;
  const attributes = productData?.attributes;

  const findImage = productData && attributes[0]?.values?.slice(0, 3)?.find(el => el.image !== null);

  return (
    <>
      <List>
        <List.Item onClick={() => setViewVariations(true)} prefix={productData && !findImage &&
          <DollarCircleOutlined />}>
          {
            productData && attributes?.length !== 0 && findImage ?
              <>
                {
                  attributes?.map((el, index) => {
                    return (
                      <Space key={`attr${index}`} className="comma">
                        <span>{el?.values?.length}</span>
                        <span>{el.name} </span>
                      </Space>
                    );
                  })
                }
                <br />
                {
                  productData && attributes[0]?.values?.slice(0, 3)?.map((productVariationValue, VIndex) => {
                    return (
                      <Space wrap key={VIndex + "productVariationValue"} className="product_main_variation">
                        {
                          productVariationValue?.image &&
                          <Button
                            size="mini"
                            className="product_main_variation__button"
                          >
                            <Image
                              src={changeImageHostName(productVariationValue.thumb)}
                              width={60}
                              height={60}
                              fit="cover"
                              style={{ borderRadius: 8 }}
                            />
                          </Button>
                        }
                      </Space>
                    );
                  })
                }
              </>
              :
              "Please choose product variations"
          }
        </List.Item>
        {
          specifications && specifications.length > 0 &&
          <List.Item prefix={<FileProtectOutlined />} onClick={() => setViewSpecification(true)}>
            Product Specification
          </List.Item>
        }
        {
          brandServiceDisclaimer?.length > 3 ?
            <List.Item prefix={<ProfileOutlined />} onClick={() => setViewDisclaimer(true)}>
              Disclaimer
            </List.Item>
            : null
        }
        <List.Item prefix={<ContentOutline />} onClick={() => setThreeTipsView(true)}>
          3 Must Reads Before Purchase
        </List.Item>
        {
          (shippingMethodsData?.length > 0 || isCampaignItem) ?
            <List.Item
              title={titleShipping}
              onClick={() => setViewShippingSelect(true)}
              className="shipping_list"
            >
            </List.Item>
            : (isProductLoading || isShippingMethodsLoading) ?
              <List.Item prefix={<FileWrongOutline />}>
                <span>Shipping methods loading</span> <DotLoading />
              </List.Item> :
              <List.Item prefix={<FileWrongOutline />}>
                No shipping methods found
              </List.Item>
        }
        {
          productData?.source?.identifier !== "aliexpress" && approxWeight > 0 &&
          <List.Item>
            <ApproxWeightCost />
          </List.Item>
        }

      </List>

      <Popup
        visible={viewDisclaimer}
        onMaskClick={() => {
          setViewDisclaimer(false);
        }}
        bodyStyle={{
          height: "40vh",
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
        }}
      >
        <Disclaimer setViewDisclaimer={setViewDisclaimer} brandServiceDisclaimer={brandServiceDisclaimer} />
      </Popup>

      <Popup
        visible={viewVariations}
        onMaskClick={() => {
          setViewVariations(false);
        }}
        bodyStyle={{
          height: "90vh",
          overflowY: "scroll",
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
        }}
        forceRender={true}
      >
        <ProductVariationsSelected viewShippingSelect={viewShippingSelect} setViewVariations={setViewVariations} />
      </Popup>

      <Popup
        visible={viewSpecification}
        onMaskClick={() => {
          setViewSpecification(false);
        }}
        bodyStyle={{
          height: "80vh",
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
        }}
      >
        <ProductSpecification setViewSpecification={setViewSpecification} />
      </Popup>

      <Popup
        visible={viewShippingSelect}
        onMaskClick={() => {
          setViewShippingSelect(false);
        }}
        bodyStyle={{
          height: "90vh",
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
        }}
      >
        <ShippingDetails setViewShippingSelect={setViewShippingSelect} />
      </Popup>

      <Popup
        visible={threeTipsView}
        onMaskClick={() => {
          setThreeTipsView(false);
        }}
        position="right"
        bodyStyle={{
          minWidth: "100vw",
          overflowY: "scroll"
        }}
      >
        <ThreeTips />
      </Popup>
    </>
  );
};

export default ProductSpecificationMeta;
