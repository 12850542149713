import React from 'react'
import { Grid, Space } from "antd-mobile";
import './SellerProductList.scss'
import { calculateDiscount, changeImageOptimizeSize, RenderNemonicBadge } from "utils/helper";
import ImageRenderer from "utils/ImageRenderer";
import ProductPrice from 'components/ProductPrice/ProductPrice';

const SellerProductList = ({ product }) => {

  let productPrice = product?.price?.min
  let productDiscountPrice = product?.discount_price?.min

  let showNemonicBadge = false;
  // if ((product?.pivot?.nemonic_badge || product?.pivot?.campaign_id) || (product?.link?.includes('taobao') && (parseFloat(product?.discount_price?.min) > 0))) {
  if (product?.pivot?.nemonic_badge) {
    showNemonicBadge = true;
  }


  return (
    <Space direction="vertical" className="seller_product_list seller-search-grid-item">
      <div className="seller_product_list_image">
        <div className="seller_product_list_image__thumb">
          <ImageRenderer
            key={product?.id}
            url={changeImageOptimizeSize(product?.image)}
            thumb={changeImageOptimizeSize(product?.image)}
            width={240}
            height={240}
            alt={`ProductImage${product?.id}`}
          />
          {
            productDiscountPrice ?
              <div className="seller_product_list_image__thumb__status-top">
                <span>{calculateDiscount(productPrice, productDiscountPrice)}%</span>
              </div>
              :
              <div className="seller_product_list_image__thumb__status-top">
                <span>New</span>
              </div>
          }
          {
            showNemonicBadge &&
            <RenderNemonicBadge
              showNemonicBadge={showNemonicBadge}
              img={product?.pivot?.nemonic_badge}
            />
          }
        </div>
      </div>
      <div className="seller_product_list_price">
        <Grid columns={1} gap={10}>
          <Grid.Item>
            <ProductPrice
              originalPrice={productPrice}
              discountPrice={productDiscountPrice}
            />
          </Grid.Item>
        </Grid>
      </div>
    </Space>
  )
}

export default SellerProductList
