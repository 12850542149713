import client from './api-client'
import Qs from "qs";

const getMyOrders = (page = null, filter = null, perPage = 10) => {
    let data = {
        page: page ? page : 1,
        per_page: perPage
    }

    if (filter) {
        data = {...data, ...filter}
    }
    return client.get('/orders', {
        params: data,
        paramsSerializer: function (params) {
            return Qs.stringify(params, {arrayFormat: 'brackets'})
        },
    })
}


const getToPayOrder = (page = null, filter = null, perPage = 10) => {
    let data = {
        page: page ? page : 1,
        per_page: perPage
    }

    if (filter) {
        data = {...data, ...filter}
    }
    return client.get('/orders/to-pay', {
        params: data,
        paramsSerializer: function (params) {
            return Qs.stringify(params, {arrayFormat: 'brackets'})
        },
    })
}

const getMyOrdersTest = (filter = {}) => {
    return client.get('/orders', {
        params: filter,
        paramsSerializer(params) {
            return Qs.stringify(params, { arrayFormat: 'brackets' });
        },
    });
};

const getOrderDetails = (order_id) => {
    return client.get(`/orders/${order_id}`)
}

const getOrderProductDetails = (order_id, product_id) => {
    return client.get(`orders/details/${order_id}/product/${product_id}`)
}

const getOrderParcel = (page = null, filter = null, perPage = 10) => {
    let data = {
        page: page ? page : 1,
        per_page: perPage
    }

    if (filter) {
        data = {...data, ...filter}
    }

    return client.get('/parcels', {
        params: data,
        paramsSerializer: function (params) {
            return Qs.stringify(params, {arrayFormat: 'brackets'})
        },
    })
}

const getParcelProductDetails = (id) => {
    return client.get(`/parcels/details?id=${id}`)
}

const createParcel = (data) => {
    return client.post(`/parcels/create`, data)
}

const getProductDetails = (product_id) => {
    return client.get(`/orders/product/${product_id}`)
}

const getProductIssue = (item_id) => {
    return client.get(`/order-item/${item_id}/issue`)
}

const getProductIssueDetails = (id) => {
    return client.get(`/orders/action/${id}`)
}

const storeProductIssue = (issue_id, data) => {
    return client.post(`/orders/action/${issue_id}`, data)
}


export const getAllIssues = (filter = {}) => {
    return client.get('/issues', {
        params: filter,
        paramsSerializer(params) {
            return Qs.stringify(params, { arrayFormat: 'brackets' });
        },
    });
};

export {
    getMyOrders,
    getOrderDetails,
    getOrderProductDetails,
    getOrderParcel,
    getParcelProductDetails,
    createParcel,
    getProductDetails,
    getMyOrdersTest,
    getToPayOrder,
    getProductIssue,
    storeProductIssue,
    getProductIssueDetails
}
