import React from "react"
import './ProfileDetails.scss'
import {Button, Form, Selector} from "antd-mobile";
import {useAuth} from "context/auth-context";
import {CloseCircleOutline} from "antd-mobile-icons";

const Gender = (props) => {

    const { setIsViewGender, userData } = props

    const { onFinishProfileUpdate, isProfileUpdating } = useAuth()

    return (
        <div className="profile_name">
            <div className="profile_info">
                <div>
                    <span className="profile_info__title">Gender</span>
                </div>
                <div>
                    <span  className="profile_info__icons" onClick={() => setIsViewGender(false)}><CloseCircleOutline/></span>
                </div>
            </div>
            <Form
                onFinish={onFinishProfileUpdate}
                footer={
                    <Button block type='submit' color='primary' loading={isProfileUpdating}>
                        Submit
                    </Button>
                }
                className="profile_form"
            >
                <Form.Item name='gender' label='Gender' >
                    <Selector
                        defaultValue={[userData?.gender]}
                        columns = {2}
                        options = { [
                            {label:'Male', value : 'male'},
                            {label:'Female',value: 'female'},
                        ] }
                    />
                </Form.Item>
            </Form>
        </div>
    );
}
export default Gender
