const useErrors = () => {
  /**
   * Format errors object from server side for use in form
   *
   * @param {object} errors errors object to be formated
   * @returns {Array} formated array
   */
  const getErrors = (errors) => {
    const errorValues = [];
    Object.keys(errors).forEach((key) => {
      errorValues.push({
        name: key,
        errors: errors[key],
      });
    });

    return errorValues;
  };

  return {
    getErrors,
  };
};

export default useErrors;
