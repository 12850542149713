export const ICON_INFO          = 'info';
export const ICON_TRUCK         = 'truck';
export const ICON_TRUCK_LOAD    = 'truck_load';
export const ICON_TRUCK_UNLOAD  = 'truck_unload';
export const ICON_AIRPLANE_UP   = 'airplane_up';
export const ICON_AIRPLANE_DOWN = 'airplane_down';
export const ICON_PACKAGE       = 'package';
export const ICON_CAUTION       = 'caution';
export const ICON_SCHEDULED     = 'scheduled';
export const ICON_PACKAGE_TIMER = 'package_timer';
export const ICON_WEIGHT_SCALE  = 'weight_scale';
export const ICON_MONEY         = 'money';
export const ICON_MONEY_PLUS    = 'money_plus';
export const ICON_MONEY_MINUS   = 'money_minus';
export const ICON_CONFIRMED     = 'confirmed';
export const ICON_CANCEL        = 'cancel';
export const ICON_TRASH         = 'trash';
export const ICON_RESTORE       = 'restore';
export const ICON_PAUSE         = 'pause';
export const ICON_RESUME        = 'resume';
