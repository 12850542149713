import * as Types from 'app/actionTypes/internalProducts';

export const getInternalProducts = (id, filter = {}) => {
  return {
    type: Types.GET_INTERNAL_SEARCH_REQUESTED,
    payload: {id, filter},
  }
}


export function reset() {
  return {
    type: Types.RESET_INTERNAL_SEARCH,
  }
}
