import * as types from 'app/actionTypes/address';
import { call, put, takeEvery } from 'redux-saga/effects';
import {getShippingAddress} from "services/address-client";
import {getCouriers} from "services/settings-client";

const getApi = async (filters = {}) => {
    return getShippingAddress(filters)
        .then(res => res.data)
        .catch((error) => { throw error })
}

const getCourierApi = async () => {
    return getCouriers()
        .then(res => res.data)
        .catch((error) => { throw error })
}

function* fetchAddress(action) {
    try {
        const data = yield call(() => getApi(action.payload));
        yield put({ type: types.GET_ADDRESS_SUCCESS, items: data });
    } catch (e) {
        yield put({ type: types.GET_ADDRESS_FAILED, message: e.message });
    }
}

function* fetchCourier(action) {
    try {
        const data = yield call(() => getCourierApi(action.payload));
        yield put({ type: types.GET_COURIER_SUCCESS, items: data });
    } catch (e) {
        yield put({ type: types.GET_COURIER_FAILED, message: e.message });
    }
}

function* addressSaga() {
    yield takeEvery(types.GET_ADDRESS_REQUESTED, fetchAddress);
    yield takeEvery(types.GET_COURIER_REQUESTED, fetchCourier);
}

export default addressSaga;
