import React, {useEffect, useState} from 'react'
import '../../Myorders.scss'
import {StatusLogIcons, StatusLogTag} from "components";
import {getProductDetails} from "services/my-order-client";
import {Image, Loading, Steps} from "antd-mobile";
import {formatDateTime} from "utils/helper";

const {Step} = Steps

const ProductItemLogDetails = (props) => {

    const { productId, timeline } = props

    const [isLogDetailsLoading, setIsLogDetailsLoading] = useState(false);
    const [logDetails, setLogDetails] = useState(null);
    const [showMore, setShowMore] = useState(false);

    const fetchProductDetails = React.useCallback(
        () => {
            setIsLogDetailsLoading(true)
            getProductDetails(productId)
                .then(res => {
                    setLogDetails(res.data.data)
                    setIsLogDetailsLoading(false)
                })
                .catch(err => {
                    setIsLogDetailsLoading(false)
                })
        },
        [productId],
    )


    useEffect(() => {
        fetchProductDetails()
    }, [fetchProductDetails]);

    if (isLogDetailsLoading) {
        return (
            <div className="loading_middle">
                <Loading color="primary"/>
            </div>
        )
    }

    let logProductEvent = logDetails?.events;
    let logEvent = timeline
    if (logProductEvent?.length > 0){
        logEvent = logProductEvent.concat(timeline);
    }

    const numberOfItems = showMore ? logEvent?.length : 2;

    // const lastStatus = logDetails?.events.slice(-1).pop()

    return (
        <>
            {
                logEvent?.length > 0 &&
                <>
                    <div className="product_details_step">
                        {/*{
                            lastStatus &&
                            <Button size="mini" block color="primary">
                                <span className="product_details_step__title">
                                    <StatusLogTag slug={lastStatus.status} text={lastStatus.status} />
                                </span>
                                <span>Status Log</span>
                            </Button>
                        }*/}
                        <Steps direction='vertical' current={logEvent?.length} style={{marginTop: '5%'}}>
                            {
                                logEvent?.slice(0, numberOfItems)?.map?.((el, index) => {
                                    let title = <StatusLogTag slug={el.status} text={el.status}/>
                                    let description = <div className="step_des">
                                        <span>{formatDateTime(el.created_at)}</span>
                                        <span className="step_des__message">{el.message}</span>
                                    </div>

                                    let icons = <StatusLogIcons icons={el.icon}/>

                                    return (
                                        <Step
                                            title={title}
                                            description={description}
                                            icon={icons}
                                            key={`productLog${index}`}
                                        />
                                    )
                                })
                            }
                            {
                                logEvent?.length > 2 &&
                                <div className="view_more">
                                    <div onClick={() => setShowMore(!showMore)}> {
                                        showMore
                                            ?
                                            <Image src="/assets/icons/down.svg" width={18} height={18} fit='fill' />
                                            :
                                            <Image src="/assets/icons/up.svg" width={18} height={18} fit='fill' />
                                    }
                                    </div>
                                </div>
                            }
                        </Steps>
                    </div>
                </>
            }
        </>
    )
}

export default ProductItemLogDetails
