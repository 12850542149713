import React, {useCallback, useEffect, useState} from "react";
import {Grid, Image, Loading, Space} from "antd-mobile";
import '../dashboard.scss'
import {
    LoginOutlined,
} from "@ant-design/icons";
import {LOCAL_STORAGE_KEY, LOCAL_STORAGE_USER} from "constants/constants";
import {Link, useHistory} from "react-router-dom";
import {SetOutline} from "antd-mobile-icons";
import {getMyWallet} from "services/wallet";
import {useAuth} from "context/auth-context";
import { ShowPriceWithCurrency} from "utils/helper";
import { useLayoutContext } from "context/LayoutContext";

const Profile = () => {
    const { localeCurrency } = useLayoutContext();
    let history = useHistory();
    const {getProfileData} = useAuth();
    const profileInfo = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER))
    const tokenInfo = localStorage.getItem(LOCAL_STORAGE_KEY)

    const [userBalance, setUserBalance] = useState(0);
    const [isUserBalanceLoading, setIsUserBalanceLoading] = useState(false);
    const [isFirstCall, setIsFirstCall] = useState(true);

    const fetchUserBalance = useCallback(
        () => {
            setIsUserBalanceLoading(true)
            getMyWallet()
                .then(res => {
                    setUserBalance(res.data)
                    setIsUserBalanceLoading(false)
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        window.location.href = "/auth/login"
                    }
                    setIsUserBalanceLoading(false)
                })
        },
        [],
    )

    useEffect(() => {
        if (isFirstCall){
            fetchUserBalance()
            setIsFirstCall(false)
            if (!profileInfo){
                getProfileData(tokenInfo)
            }
        }
    }, [fetchUserBalance, getProfileData, isFirstCall, profileInfo, tokenInfo]);

    return (
        <div className="profile__grid">
            <Grid columns={10} gap={10} className="profile__grid__layout">
                <Grid.Item span={2} style={{margin: 'auto 0'}}>
                    <Image
                        src="/assets/dashboard/profile.png"
                        width={64}
                        height={64}
                        fit='cover'
                        style = { { borderRadius : 32 } }
                    />
                </Grid.Item>
                <Grid.Item span={7}>
                    <Space direction = 'vertical'>
                        <span className="profile_details__name">
                            <>
                                {
                                    profileInfo
                                        ?
                                        <>
                                            {profileInfo?.first_name} {profileInfo?.last_name} {profileInfo?.username ? `(${profileInfo?.username})` : null}
                                        </>
                                        :
                                        "Me"
                                }
                            </>
                        </span>
                        {
                            (profileInfo?.mobile || profileInfo?.email) &&
                                <span>{profileInfo?.mobile ? profileInfo?.mobile : profileInfo?.email}</span>
                        }
                        {
                            !isUserBalanceLoading ?
                                <Space direction="vertical">
                                    <div>Refund Balance: <Link to="/account/wallet" className="link_underline"><span className="profile_details__balance"> {ShowPriceWithCurrency({price: userBalance?.balance, currency: localeCurrency})}</span></Link></div>
                                    {
                                        userBalance?.locked &&
                                            <div>Hold Balance: <Link to="/account/wallet" className="link_underline"><span className="profile_details__balance"> {ShowPriceWithCurrency({price: userBalance?.locked, currency: localeCurrency})}</span></Link></div>
                                    }
                                </Space>
                                :
                                <Loading/>
                        }
                    </Space>
                </Grid.Item>
                <Grid.Item className="profile_details_icons">
                    {
                        tokenInfo ?
                            <span className="setting_icon" onClick={() => history.push("/profile/details")}><SetOutline /></span>
                            :
                            <span className="setting_icon" onClick={() => history.push("/auth/login")}><LoginOutlined /></span>
                    }
                </Grid.Item>
            </Grid>
            {/*<Grid columns={4} gap={8} className="profile_list">
                <Grid.Item onClick={() => history.push("my-request")}>
                    <Space direction='vertical'>
                        <Badge content='1'>
                            <span className="profile_list__icon"><ContentOutline /></span>
                        </Badge >
                        <span className="profile_list__text" >Request</span>
                    </Space>
                </Grid.Item>
                <Grid.Item onClick={() => history.push("/wish-list")}>
                    <Space direction='vertical'>
                        <span className="profile_list__icon"><ContainerOutlined /></span>
                        <span className="profile_list__text">Wish List</span>
                    </Space>
                </Grid.Item>
                <Grid.Item>
                    <Space direction='vertical'>
                        <span className="profile_list__icon"><FieldTimeOutlined /></span>
                        <span className="profile_list__text">Store</span>
                    </Space>
                </Grid.Item>
                <Grid.Item>
                    <Space direction='vertical'>
                        <span className="profile_list__icon"><BookOutlined /></span>
                        <span className="profile_list__text__action">Action Needed</span>
                    </Space>
                </Grid.Item>
            </Grid>*/}
        </div>
    );
}

export default Profile;
