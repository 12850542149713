import { all } from 'redux-saga/effects'
import productsSaga from './productsSaga'
import paymentSaga from "./paymentSaga";
import productDetailsSaga from "./productsDetails";
import ordersSaga from "./ordersSaga";
import gatewaysSaga from "./gatewaysSaga";
import internalProductsSaga from "./internalProductsSaga";
import campaignSaga from "./campaignSaga";
import categorySaga from "./categorySaga";
import addressSaga from "./addressSaga";
import systemSaga from "./systemSaga";
import issueSaga from "./issueSaga";
import builderSaga from "./builderSaga";
import productDetailsDataSaga from "./productsDetailsDataSaga";

export default function* rootSaga() {
  yield all([
      productsSaga(),
      paymentSaga(),
      productDetailsSaga(),
      ordersSaga(),
      gatewaysSaga(),
      internalProductsSaga(),
      campaignSaga(),
      categorySaga(),
      addressSaga(),
      systemSaga(),
      issueSaga(),
      builderSaga(),
      productDetailsDataSaga(),
  ])
}
