import React from 'react'
import '../../Myorders.scss'
import { Button, Collapse, Grid, Space, } from "antd-mobile";
import { RightOutlined } from "@ant-design/icons";
import { formatDate } from "utils/helper";
import MyOrderProductDetails from "../MyOrderProductDetails/MyOrderProductDetails";
import { useHistory } from "react-router-dom";

const ToDelivered = (props) => {
  const { elements, index } = props
  const history = useHistory();

  const orderTitle = <Grid columns={5} gap={8}>
    <Grid.Item span={3}>
      <Space direction="vertical" style={{ '--gap': '2px' }}>
        <span className="all-orders__order-text">Order #{elements?.id} <RightOutlined /></span>
        <div>
          {elements.products?.[0]?.order_type ? <span style={{ color: "var(--adm-color-primary)" }}>{elements.products?.[0]?.order_type} </span> : null}<span className="all-orders__order-date">{formatDate(elements?.created_at)}</span>
        </div>
      </Space>
    </Grid.Item>
    {
      (parseFloat(elements?.due) > 0 && elements?.pay_type !== "cod") &&
      <Grid.Item span={2} style={{ textAlign: 'right' }}>
        <Button size="mini" color="danger" onClick={() => history.push(`/payment/${elements.id}?PT=online-full&GWI=10`)}> Pay Now</Button>
      </Grid.Item>
    }
  </Grid>

  return (
    <div className="all-orders">
      <Collapse defaultActiveKey={['0', '1', '2', '3', '4', '5', '6']}>
        <Collapse.Panel key={index} title={orderTitle} >
          <MyOrderProductDetails elements={elements} />
        </Collapse.Panel >
      </Collapse>
    </div>
  )
}

export default ToDelivered
