import React, { useEffect, useState } from 'react'
import { Grid, InfiniteScroll, Loading } from "antd-mobile";
import { GlobalHeaderBack, KeywordSearchSkeleton, RecentlyViewProductList } from "components";
import { ProductDetailsBlock } from "demos";
import { PageHead } from "../../components";
import './SimilarProducts.scss'
import { getLocalData } from 'utils/helper';
import { RECENTLY_VIEW } from 'constants/constants';
import { Link } from 'react-router-dom';

const RecentlyViewedProducts = () => {
  let recentlyViewProducts = getLocalData(RECENTLY_VIEW);

  const [items,] = useState([]);
  const [currentPage,] = useState(1);

  const [hasMore, setHasMore] = useState(false)

  const InfiniteScrollContent = ({ hasMore }) => {
    return (
      <>
        {
          hasMore ? (
            <span>End of products</span>
          )
            : (
              <div className="loading_middle">
                <Loading color='primary' />
              </div>
            )
        }
      </>
    )
  }

  let totalPage = parseInt(items?.length - 1)

  useEffect(() => {
    if (currentPage < totalPage) {
      setHasMore(true)
    }
  }, [currentPage, totalPage]);

  const loadMoreProducts = () => {
    if (items?.paginate) {
      setHasMore(false)
    }
  }

  return (
    <>
      <PageHead title={'Recently Viewed Products'} />

      <GlobalHeaderBack title="Recently Viewed Products" height="60px" color="white" />
      <ProductDetailsBlock padding="3%" marginBottom="12%" background="none">
        {
          recentlyViewProducts?.length >= 0 ?
            <Grid columns={2} gap={8}>
              {
                recentlyViewProducts?.map((product, index) => {
                  let pathName = '/products/details';
                  let searchParams = `?url=${encodeURIComponent(product?.link)}`
                  if (product?.type === 'campaign' || product?.pivot?.campaign_id) {
                    searchParams = `?ipid=${product.id}&campaign=${product?.pivot?.campaign_id}&campaign_product=${product?.pivot?.id}`
                  } else if (product?.product_code) {
                    let productSlug = product?.slug || product?.title;
                    pathName = `/products/${productSlug}/${product.product_code}`
                    searchParams = null;
                  } else if (product?.type) {
                    searchParams = `?id=${product.id}`
                  }

                  return (
                    <Link to={{
                      pathname: pathName,
                      search: searchParams
                    }} style={{ textDecoration: 'none' }} >
                      <Grid.Item
                        key={`product_search${index}`}
                        className="similar_results_grid"
                      >
                        <RecentlyViewProductList product={product} componentFor="page" />
                      </Grid.Item>
                    </Link>
                  )
                })

              }
            </Grid>
            :
            <KeywordSearchSkeleton />
        }

        {
          items && items?.paginate?.last_page > 1 && items?.paginate?.current_page !== items?.paginate?.last_page &&
          <InfiniteScroll hasMore={hasMore} loadMore={loadMoreProducts}>
            < InfiniteScrollContent hasMore={hasMore} />
          </InfiniteScroll>
        }
      </ProductDetailsBlock>
    </>
  )
}

export default RecentlyViewedProducts
