import Qs from 'qs';
import client from './without-auth-api-client'

export const trackingService = (filter = {}) => {
  return client.get(`/services/moveon/tracking`, {
    params: {
      ...filter,
      per_page: filter?.per_page ?? 30,
    },
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });
};
