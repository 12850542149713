import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Grid, Radio, Selector, Skeleton, Space } from "antd-mobile";
import "./PaymentTypes.scss"
import { useCart } from "context/cart-context";
import { getMyWallet } from "services/wallet";
import { useAuth } from "context/auth-context";
import { defaultConstants, systemConstants } from "consts/storage";
import { ShowPriceWithCurrency } from "utils/helper";
import { useLayoutContext } from "context/LayoutContext";

const PaymentTypes = ({ checkoutItemsList, checkoutTotal }) => {
  const { localeCurrency, cashbackOffBanner, campaignPayTypes, regularPayTypes } = useLayoutContext();
  const {
    paymentOption,
    setPaymentOption,
    setPaymentType,
    paymentType,
    checkoutItems,
    setSelectedPaymentType
  } = useCart()

  const { items: gatewayItems, loading } = useSelector(state => state.gateways)
  const { mainMode: configs } = useAuth()
  const [items, setItems] = useState(gatewayItems)
  const [userBalance, setUserBalance] = useState(0);
  const [isUserBalanceLoading, setIsUserBalanceLoading] = useState(false);
  const [isFirstCall, setIsFirstCall] = useState(true);
  const [minCODAmount, setMinCODAmount] = useState(null);
  const [codMessage, setCodMessage] = useState(null);

  let isCampaignOrder = checkoutItems?.every(item => !!(item.campaign_product_id))

  useEffect(() => {
    let updatedPayTypes = {};
    let filteredArray;

    const getFilteredGateways = (gatewayItems, Module = "Wallet") => {
      let moduleWallet = null;
      let filteredGateways = gatewayItems.data;
      if (gatewayItems?.data?.length) {
        moduleWallet = gatewayItems.data.find(item => item.module === Module);
      }
      if (moduleWallet) {
        filteredGateways = gatewayItems.data.filter(item => item.module !== Module);
      }
      return filteredGateways
    }

    const setUpdatedPayTypes = (payTypeValues, payTypesList) => {
      let splittedPayTypes = payTypeValues?.split(',')?.map(item => item?.trim()?.toLowerCase());
      let filteredArray = Object.entries(payTypesList)?.filter(item => splittedPayTypes?.includes(item[0]));
      updatedPayTypes = Object.fromEntries(filteredArray);
      let filteredGateways = getFilteredGateways(gatewayItems, 'Wallet');

      setItems({
        ...gatewayItems,
        pay_types: updatedPayTypes,
        data: filteredGateways
      })
    }

    if (gatewayItems?.data && gatewayItems?.pay_types) {
      let gtPayTypes = gatewayItems?.pay_types;

      if (isCampaignOrder && campaignPayTypes) {
        setUpdatedPayTypes(campaignPayTypes, gtPayTypes);
      } else if (regularPayTypes) {
        setUpdatedPayTypes(regularPayTypes, gtPayTypes);
      }
      else {
        filteredArray = Object.entries(gtPayTypes)?.filter(item => !item[0]?.includes('cod'));
        updatedPayTypes = Object.fromEntries(filteredArray);
        let filteredGateways = getFilteredGateways(gatewayItems, 'Wallet');

        setItems({
          ...gatewayItems,
          pay_types: updatedPayTypes,
          data: filteredGateways,
        });
      }
    }
  }, [isCampaignOrder, gatewayItems, campaignPayTypes, regularPayTypes])

  const makeOption = items && items?.data?.map(el => {
    return {
      ...el,
      value: el.id,
      label: el?.module === "Wallet" ? `${el.name} ${!isUserBalanceLoading ? `(${userBalance?.balance})` : (0.00)}` : el.name,
    }
  })

  const handleOnlinePayment = (value, items) => {
    let typeObj = items.find(item => item.id)
    setPaymentType(value)
    setSelectedPaymentType(typeObj)
  }

  const fetchUserBalance = useCallback(
    () => {
      setIsUserBalanceLoading(true)
      getMyWallet()
        .then(res => {
          setUserBalance(res.data)
          setIsUserBalanceLoading(false)
        })
        .catch(error => {
          setIsUserBalanceLoading(false)
        })
    },
    [],
  )

  useEffect(() => {
    if (isFirstCall) {
      fetchUserBalance()
      setIsFirstCall(false)
    }
  }, [fetchUserBalance, isFirstCall]);

  useEffect(() => {
    if (configs?.length && checkoutTotal) {
      let partialCodMinAmount = configs?.find(item => item.key === defaultConstants.partialCodMinAmount);
      let partialCodMinPercent = configs?.find(item => item.key === defaultConstants.partialCodMinPercent);
      let codParcentAmount = null;
      let codMinAmountValue = null;
      let finalMinAmount = null;
      let totalAmount = Number.parseFloat(checkoutTotal);

      if (partialCodMinAmount?.value) {
        codMinAmountValue = parseFloat(partialCodMinAmount?.value);
      }
      if (partialCodMinPercent?.value) {
        codParcentAmount = parseFloat(((parseFloat(partialCodMinPercent?.value) * totalAmount) / 100).toFixed(2))
      }
      if (codMinAmountValue && codParcentAmount) {
        if (codParcentAmount < totalAmount && codParcentAmount > codMinAmountValue) {
          finalMinAmount = codParcentAmount
          setCodMessage(`To confirm this order, you must pay ${partialCodMinPercent?.value}% = ${codParcentAmount?.toFixed(2)} of the total amount (${totalAmount?.toFixed(2)}) in advance. ${systemConstants.codRestAmountMsg}`);
        } else if (codMinAmountValue < totalAmount && codParcentAmount < codMinAmountValue) {
          finalMinAmount = codMinAmountValue
          setCodMessage(`To confirm this order, you must pay ${codMinAmountValue?.toFixed(2)} of the total amount (${totalAmount?.toFixed(2)}) in advance. ${systemConstants.codRestAmountMsg}`);
        }
      } else if (codMinAmountValue && !codParcentAmount && codMinAmountValue < totalAmount) {
        finalMinAmount = codMinAmountValue
        setCodMessage(`To confirm this order, you must pay ${codMinAmountValue?.toFixed(2)} of the total amount (${totalAmount?.toFixed(2)}) in advance. ${systemConstants.codRestAmountMsg}`);
      } else if (!codMinAmountValue && codParcentAmount && codParcentAmount < totalAmount) {
        finalMinAmount = codParcentAmount
        setCodMessage(`To confirm this order, you must pay ${partialCodMinPercent?.value}% = ${codParcentAmount?.toFixed(2)} of the total amount (${totalAmount?.toFixed(2)}) in advance. ${systemConstants.codRestAmountMsg}`);
      }

      if (finalMinAmount) {
        setMinCODAmount(finalMinAmount)
      } else {
        setCodMessage(`Because the total amount of your order is less than the minimum COD amount, you must pay the full amount. ${systemConstants.codRestFeesMsg}`)
      }
    }
  }, [configs, checkoutTotal])

  let isInternalProductExits = checkoutItemsList?.find(el => el?.type === "internal")

  let filteredCashbacks = cashbackOffBanner?.filter(item => item?.label?.toLowerCase() === 'on');
  let cfBannerLen = filteredCashbacks.length;
  let gridCols = 1;
  let colSpan = 1;
  if (cfBannerLen === 2) {
    gridCols = 2;
  } else if (cfBannerLen === 3) {
    gridCols = 3
  } else if (cfBannerLen === 4) {
    gridCols = 6;
    colSpan = 3;
  } else if (cfBannerLen > 4) {
    gridCols = 6;
    colSpan = 2;
  }

  return (
    <>
      {
        loading ?
          <Skeleton.Paragraph lineCount={2} animated />
          :
          <div className="payment_ex">
            {
              cfBannerLen > 0 ?
                <Grid columns={gridCols} gap={6} style={{ marginBottom: '10px' }}>
                  {
                    filteredCashbacks.map(item => {
                      return (
                        <Grid.Item
                          span={colSpan}
                          className="chk_cashback_offer"
                          key={`${item.key}`}
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <img
                            src={item.image}
                            alt="Cashback offer"
                            width={100}
                          />
                        </Grid.Item>
                      )
                    })
                  }
                </Grid>
                : null
            }
            <div className="payment_type">Choose Payment Method</div>
            <Radio.Group
              defaultValue="online-full"
              value={paymentOption}
              onChange={(val) => {
                setPaymentOption(val)
              }}
            >
              <Space direction="vertical" block>
                {
                  items.pay_types && Object.keys(items.pay_types).map(((el, index) => {
                    return (
                      <div key={`paymentType${index}`}>
                        <Radio block value={el} disabled={!isInternalProductExits && el === "online-cod"}>
                          {items.pay_types[el]}
                        </Radio>
                        {
                          paymentOption === "online-full" && el === "online-full" &&
                          <div className="payment_selector">
                            <Selector
                              defaultValue={paymentType}
                              options={makeOption}
                              columns={2}
                              onChange={(arr, { items }) => {
                                handleOnlinePayment(arr, items)
                              }}
                            />
                          </div>
                        }
                        {
                          paymentOption === "online-cod" && el === "online-cod" &&
                          <div className="payment_selector">
                            <Selector
                              options={makeOption}
                              columns={2}
                              onChange={(arr, { items }) => handleOnlinePayment(arr, items)}
                            />
                          </div>
                        }
                      </div>
                    )
                  }))
                }
                {
                  paymentOption === "cod" &&
                  <Space direction="vertical">
                    <span className="payment_des">For order confirmation, we'll call you on Phone or WhatsApp. Make sure your number have WhatsApp or active for receive the call.</span>
                  </Space>
                }
                {
                  paymentOption === "online-cod" &&
                  <Space direction="vertical">
                    <span className="payment_title">Minimum amount to pre pay: <span
                      className="payment_title__value">{ShowPriceWithCurrency({ price: minCODAmount, currency: localeCurrency })}</span></span>
                    <span className="payment_des">{codMessage}</span>
                  </Space>
                }
              </Space>
            </Radio.Group>

          </div>
      }
    </>
  )
}

export default PaymentTypes
