import React from "react"
import './SearchFilter.scss'
import { Button, Form, Grid, Selector } from "antd-mobile";
import { CustomFormElement } from "../../../../components";
import qs from "qs";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { navigateToNewSearch } from "app/actions/products";
import { useLayoutContext } from "context/LayoutContext";
import { defaultKeys } from "consts/storage";


const SearchFilter = (props) => {
  const { visibleConfigs } = useLayoutContext();
  const history = useHistory();
  const {
    handleProductFilter,
    handleFilterChange,
    handleFilterClear,
    isFetched,
    filters,
    filtersData,
    setViewFilterList,
  } = props
  const dispatch = useDispatch();

  if (!filters || !filtersData) {
    return null;
  }

  let makeOption = [
    {
      label: "Aliexpress",
      value: 1
    },
    {
      label: "Taobao",
      value: 4
    }]

  const params = qs.parse((window.location.search).substring(1))

  const handleShopValue = (value) => {
    setViewFilterList(false)
    dispatch(navigateToNewSearch())
    window.scroll(0, 0);
    history.push({
      pathname: '/products',
      search: `?keyword=${params?.keyword}&shop_id=${value}`,
    })
  }

  return (
    <div className="search_filters_results">
      <span className="search_filters_results__filter_by">Filter By</span>
      <Form
        layout="vertical"
        className="search_filters_form"
      >
        {
          visibleConfigs?.[defaultKeys.storesVisible] &&
          <Selector
            options={makeOption}
            columns={2}
            defaultValue={[parseInt(params?.shop_id)]}
            onChange={(arr) => handleShopValue(arr)}
          />
        }
        <Grid columns={1} gap={0}>
          <Grid.Item>
            {
              isFetched && Object.keys(filters).length > 0 && Object.keys(filtersData?.configurator).map((el, idx) => {
                const fieldElement = filtersData?.configurator[el]

                const fieldProps = {
                  type: fieldElement.type,
                  options: fieldElement.values,
                  handleChange: handleFilterChange,
                  name: el,
                  label: fieldElement.title,
                  fieldValue: filters[el],
                  separator: fieldElement.separator,
                }

                return <CustomFormElement key={idx} {...fieldProps} />
              })
            }
          </Grid.Item>
        </Grid>
        <div className="bottom_button">
          <Grid columns={2} gap={4}>
            <Grid.Item>
              <Button
                onClick={() => {
                  setViewFilterList(false)
                  handleFilterClear();
                }}
                color="primary"
                block
                size="small"
              >
                Clear
              </Button>
            </Grid.Item>
            <Grid.Item>
              <Button
                color="primary"
                block
                size="small"
                onClick={() => handleProductFilter()}
              >
                Filter
              </Button>
            </Grid.Item>
          </Grid>
        </div>
      </Form>
    </div>
  )
}
export default SearchFilter
