import React, {useEffect} from 'react';
import {Button, Result, Space} from "antd-mobile";
import './GenericNotFound.scss'
import {GlobalHeaderBack, PageHead} from "components";
import { useHistory } from 'react-router-dom';

const GenericNotFound = () => {
    const history = useHistory();

    useEffect(() => {
        document.body.style.background = '#ffffff'
    }, [])

    return (
        <>
            <PageHead title={`404`}/>
            <GlobalHeaderBack title="404 Not Found" height="60px" color="white"/>
            <div className="result_error">
                <Space direction="vertical" block align="center">
                    <Result
                        status='error'
                        title='Page Not Found'
                        description='What you want to find that page is not found, Please try again later'
                    />
                    <Button color="primary" onClick={() => history.push('/')}>Back Home</Button>
                </Space>
            </div>
        </>
    );
}

export default GenericNotFound;
