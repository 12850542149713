import { GlobalHeaderBack, PageHead } from 'components';
import { ProductDetailsBlock } from 'demos';
import Campaigns from 'pages/Campaigns/components/CampaignList';
import React from 'react';

export default function ElevenElevenPage() {

  return (
    <>
      <GlobalHeaderBack title={"জমজমাট সেল! ১১.১১"} height="50px" color="white" />
      <ProductDetailsBlock>
        <div
          className="page-wrapper space-y-8 pt-20 pb-40"
        >
          <PageHead
            title="জমজমাট সেল! ১১.১১"
          />
          <section
            style={{
              width: '100%',
              paddingBottom: '40%',
              backgroundImage: 'url(/eleven-eleven/banner-11-11.jpg)',
              backgroundSize: "100%",
              backgroundPosition: 'center center',
              backgroundRepeat: 'no-repeat',
              marginTop: '-5%'
            }}
          >
          </section>
          <section className="campaign-list container pt-20 pb-8">
            <Campaigns
              showAllActiveCampaign={true}
              gridCols="lg:grid-cols-3"
            />
          </section>
          <section
            className="campaign-deadline bg-red-500 pt-8 pb-9 flex justify-center items-center"
            style={{
              display: 'flex',
              jusifyContent: 'center',
              alignItems: 'center',
              padding: '32px 36px',
              backgroundColor: '#EF4444'
            }}
          >
            <div
              className="w-1/2 flex flex-col items-center"
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                fontFamily: "Noto Sans Bengali, sans-serif",
                fontWeight: 700,
              }}
            >
              <div className="text-white" style={{ color: '#fff' }}>অফার চলবে</div>
              <div
                className="w-1/2 flex items-center justify-center text-center bg-white rounded-full text-red-600 font-bold py-2 text-xl"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#fff',
                  padding: '8px 24px',
                  borderRadius: '9999px',
                  fontSize: '1rem'
                }}
              >
                <div>১ থেকে ১১ নভেম্বর পর্যন্ত</div>
              </div>
            </div>
          </section>
          <div className="mb-28" style={{ padding: '3% 5%' }}>
            <section className="container campaign-prizes py-8">
              <div
                className="flex justify-center items-center"
                style={{ fontFamily: "Noto Sans Bengali, sans-serif" }}
              >
                <div className="lg:w-1/2 border border-gray-200 rounded py-5 px-12 drop-shadow">
                  <div
                    className="flex justify-center items-center text-center mb-6"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      textAlign: 'center',
                    }}
                  >
                    <h3
                      className="block text-xl font-bold bg-primary text-white rounded-full px-8 p-2"
                      style={{
                        backgroundColor:  'var(--adm-color-primary)',
                        color: '#fff',
                        borderRadius: '9999px',
                        padding: '8px 36px'
                      }}
                    >ক্যাম্পেইনের প্রাইজসমূহঃ</h3>
                  </div>
                  <ul
                    className="list-decimal leading-7"
                    style={{
                      listStyleType: "bengali",
                      lineHeight: '1.5rem',
                    }}
                  >
                    <li>
                      পুরো ক্যাম্পেইনে মাত্র ১০ হাজারের শপিং করলেই পাচ্ছেন <strong>মেগা প্রাইজ ২৪‌‌‍" এলসিডি টিভি</strong> জিতে নেয়ার সুযোগ। লাকি ড্রয়ের মাধ্যমে বিজয়ী নির্ণীত হবেন।
                    </li>
                    <li>
                      প্রতিদিন মিনিমাম ৩ হাজার টাকার অর্ডারকারী ক্রেতা সুযোগ
                      পাচ্ছেন লাকি ড্রয়ের আকর্ষণীয় পুরষ্কার লুফে নেওয়ার সুযোগ,
                      থাকছে <strong>ব্রান্ডেড গিফট কার্ড</strong> সেই সাথে আকর্ষণীয় <strong>ফিচার ফোন!</strong>
                    </li>
                  </ul>
                </div>
              </div>
            </section>
            <section className="container campaign-rules py-8">
              <div
                className="flex justify-center items-center"
                style={{ fontFamily: "Noto Sans Bengali, sans-serif" }}
              >
                <div className="lg:w-1/2 border border-gray-200 rounded py-5 px-12 drop-shadow">
                  <div
                    className="flex justify-center items-center text-center mb-6"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      textAlign: 'center',
                    }}
                  >
                    <h3
                      className="block text-xl font-bold bg-primary text-white rounded-full px-8 p-2"
                      style={{
                        backgroundColor:  'var(--adm-color-primary)',
                        color: '#fff',
                        borderRadius: '9999px',
                        padding: '8px 36px'
                      }}
                    >১১ ক্যাম্পেইনে শপিং এর সার্বিক নিয়মাবলী</h3>
                  </div>
                  <ul
                    className="list-decimal leading-7"
                    style={{
                      listStyleType: "bengali",
                      lineHeight: '1.5rem',
                    }}
                  >
                    <li>
                      ক্যাম্পেইনের বাছাইকৃত সব পণ্যেই বিষেশ ১১.১১ সুবিধাসমূহ উপভোগ করা যাবে। ক্যাম্পেইনের বিভিন্ন সুবিধা উপভোগ করতে পারবেন নিম্নোক্ত ক্যাটাগরিতে-
                      <ul className="list-disc leading-7 pl-4">
                        <li>সাধারণ ক্যাটাগরির ক্যাম্পেইন (প্রাইস ডিসকাউন্ট + শিপিং ডিসকাউন্ট)</li>
                        <li>১১.১১ মিস্ট্রি বক্স (১১ টাকায়)</li>
                        <li>গ্যাজেট ফেস্ট (প্রাইস ডিসকাউন্ট)</li>
                        <li>উইন্টার কালেকশন (প্রাইস ডিসকাউন্ট)</li>
                        <li>৫০০-১০০০ টাকার প্রোডাক্ট (ফ্রি শিপিং)</li>
                        <li>৫০০ টাকার প্রোডাক্ট (ফ্রি শিপিং)</li>
                      </ul>
                    </li>
                    <li>
                      অর্ডার প্লেস করতে হবে ১১ই নভেম্বর রাত ১১ঃ৫৯ ঘটিকার মধ্যে।
                    </li>
                  </ul>
                </div>
              </div>
            </section>
            <section className="container campaign-marketplaces py-8 flex justify-center items-center">
              <div className="lg:w-1/2 text-center" style={{ fontFamily: "Noto Sans Bengali, sans-serif", textAlign: 'center', marginBottom: '20px' }}>
                এছাড়াও জনপ্রিয় চাইনিজ শপিং সাইট Aliexpress এবং Taobao এর সকল অফারের পণ্য খুব সহজেই অর্ডার করতে পারবেন Ali2BD এর মাধ্যমে।
              </div>
            </section>
            <section className="container campaign-authority flex justify-center items-center">
              <div className="lg:w-1/2 text-center" style={{ fontFamily: "Noto Sans Bengali, sans-serif", paddingBottom: '20%' }}>
                ** ক্যাম্পেইনের সময়সীমা, অফার এবং সুবিধা উপভোগের সকল পরিবর্তন ও পরিবর্ধনের অধিকার শুধুমাত্র Ali2BD দ্বারা সংরক্ষিত। গ্রাহক এর সুবিধার্থে ক্যাম্পেইন এর যেকোন পরিবর্তন গ্রহণযোগ্য বলে গণ্য করা হবে।
              </div>
            </section>
            <section className="container campaign-wholesale"></section>
          </div>
        </div>
      </ProductDetailsBlock>
    </>
  )
}
