import React from "react"
import './ProfileDetails.scss'
import {Button, Form, Input} from "antd-mobile";
import {useAuth} from "context/auth-context";
import {CloseCircleOutline} from "antd-mobile-icons";

const ProfileName = (props) => {
    
    const { setIsViewName } = props

    const { profileInfo, onFinishProfileUpdate, isProfileUpdating} = useAuth()

    return (
        <div className="profile_name">
            <div className="profile_info">
                <div>
                    <span className="profile_info__title">Your Name</span>
                </div>
                <div>
                    <span  className="profile_info__icons" onClick={() => setIsViewName(false)}><CloseCircleOutline/></span>
                </div>
            </div>
            <Form
                onFinish={onFinishProfileUpdate}
                footer={
                    <Button block type='submit' color='primary' loading={isProfileUpdating}>
                        Submit
                    </Button>
                }
                className="profile_form"
                initialValues={{
                    first_name: profileInfo?.first_name,
                    last_name: profileInfo?.last_name
                }}
            >
                <Form.Item
                    name='first_name'
                    label='First Name'
                >
                    <Input placeholder='Please enter your first name' />
                </ Form.Item>
                <Form.Item
                    name='last_name'
                    label='Last Name'
                >
                    <Input placeholder='Please enter your last name'/>
                </ Form.Item>
            </Form>
        </div>
    );
}
export default ProfileName
