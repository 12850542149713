import React from 'react'
import { Button, Grid, Image, Space, Tag } from "antd-mobile";
import './SellerRating.scss'
import { useProduct } from "context/product-context";
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { navigateToNewSearch } from 'app/actions/products';

const SellerRating = () => {
  const history = useHistory();
  const { state: productData } = useProduct()
  const dispatch = useDispatch();
  let ratingText = "Low"

  if (productData?.seller?.meta?.ratings?.communication?.score >= 4) {
    ratingText = "High"
  } else if (productData?.seller?.meta?.ratings?.communication?.score >= 2) {
    ratingText = "Medium"
  }

  return (
    <>
      {
        productData &&
        <div className="seller_rating" onClick={() => {
          dispatch(navigateToNewSearch())
          history.push({
            pathname: `/seller-details`,
            search: `?seller_id=${productData?.seller?.id}&shop_id=${productData?.source?.id}`,
          })
        }}>
          <Grid columns={16} gap={2}>
            <Grid.Item span={1}>
              <Image src="/assets/icons/seller_shop.svg" width={20} height={20} fit='fill' />
            </Grid.Item>
            <Grid.Item span={15}>
              <span className="seller_rating__title">
                {productData?.seller?.name ? productData.seller.name : productData?.seller?.source_id}
              </span>
            </Grid.Item>
          </Grid>
          <Grid columns={12} gap={8} className="seller_rating_details">
            <Grid.Item span={productData?.seller?.meta?.ratings ? 4 : 12}>
              <Space direction='vertical' className="seller_original_rating">
                <span className="seller_original_rating__rating">{productData?.seller?.meta?.trusted}</span>
                <div className="seller_original_rating__status title-text">Positive Seller Ratings</div>
              </Space>
            </Grid.Item>
            {
              productData?.seller?.meta?.ratings &&
              <Grid.Item span={8}>
                <Grid columns={10} gap={8}>
                  <Grid.Item span={5}>
                    <Space direction='vertical' className="seller_rating_log_details">
                      <div>Communication:</div>
                      <div>Product Describe:</div>
                      <div>Product Shipping:</div>
                    </Space>
                  </Grid.Item>
                  <Grid.Item span={5} style={{ textAlign: 'right' }}>
                    <Space direction='vertical' className="seller_rating_log_details">
                      <div>
                        <Space>
                          <span>{productData?.seller?.meta?.ratings?.communication?.score}</span>
                          <span> <Tag round color='#2db7f5'
                            className="seller_rating_log_details__tag">{ratingText}</Tag></span>
                          {
                            productData?.seller?.meta?.ratings?.communication?.ratings &&
                            <span className="seller_rating_log_details__rate">{productData?.seller?.meta?.ratings?.communication?.ratings}</span>
                          }
                        </Space>
                      </div>
                      <div>
                        <Space>
                          <span>{productData?.seller?.meta?.ratings?.describe?.score}</span>
                          <span> <Tag round color='#2db7f5'
                            className="seller_rating_log_details__tag">{ratingText}</Tag></span>
                          {
                            productData?.seller?.meta?.ratings?.describe?.ratings &&
                            <span className="seller_rating_log_details__rate">{productData?.seller?.meta?.ratings?.describe?.ratings}</span>
                          }
                        </Space>
                      </div>
                      <div>
                        <Space>
                          <span>{productData?.seller?.meta?.ratings?.shipping?.score}</span>
                          <span> <Tag round color='#2db7f5'
                            className="seller_rating_log_details__tag">{ratingText}</Tag></span>
                          {
                            productData?.seller?.meta?.ratings?.shipping?.ratings &&
                            <span className="seller_rating_log_details__rate">{productData?.seller?.meta?.ratings?.shipping?.ratings}</span>
                          }
                        </Space>
                      </div>
                    </Space>
                  </Grid.Item>
                </Grid>
              </Grid.Item>
            }
          </Grid>
          <Space className={productData?.seller?.meta?.ratings ? "store_button" : "store_button__seller_middle"}>
            <Button color='primary' size="middle">View Store</Button>
          </Space>
        </div>
      }
    </>
  )
}

export default SellerRating
