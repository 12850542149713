import React from 'react';
import {Grid} from "antd-mobile";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const KeywordSearchSkeleton = () => {

    return (
        <Grid columns={2} gap={4}>
            <Grid.Item>
                <SkeletonTheme baseColor="#ffffff" highlightColor="#f7f7f7">
                    <Skeleton height={220}/>
                    <Skeleton count={1}/>
                    <Skeleton count={1}/>
                    <Skeleton count={1}/>
                </SkeletonTheme>
            </Grid.Item>
            <Grid.Item>
                <SkeletonTheme baseColor="#ffffff" highlightColor="#fafafa">
                    <Skeleton height={220}/>
                    <Skeleton count={1}/>
                    <Skeleton count={1}/>
                    <Skeleton count={1}/>
                </SkeletonTheme>
            </Grid.Item>
            <Grid.Item>
                <SkeletonTheme baseColor="#ffffff" highlightColor="#fafafa">
                    <Skeleton height={220}/>
                    <Skeleton count={1}/>
                    <Skeleton count={1}/>
                    <Skeleton count={1}/>
                </SkeletonTheme>
            </Grid.Item>
            <Grid.Item>
                <SkeletonTheme baseColor="#ffffff" highlightColor="#fafafa">
                    <Skeleton height={220}/>
                    <Skeleton count={1}/>
                    <Skeleton count={1}/>
                    <Skeleton count={1}/>
                </SkeletonTheme>
            </Grid.Item>
            <Grid.Item>
                <SkeletonTheme baseColor="#ffffff" highlightColor="#fafafa">
                    <Skeleton height={220}/>
                    <Skeleton count={1}/>
                    <Skeleton count={1}/>
                    <Skeleton count={1}/>
                </SkeletonTheme>
            </Grid.Item>
            <Grid.Item>
                <SkeletonTheme baseColor="#ffffff" highlightColor="#fafafa">
                    <Skeleton height={220}/>
                    <Skeleton count={1}/>
                    <Skeleton count={1}/>
                    <Skeleton count={1}/>
                </SkeletonTheme>
            </Grid.Item>
            <Grid.Item>
                <SkeletonTheme baseColor="#ffffff" highlightColor="#fafafa">
                    <Skeleton height={220}/>
                    <Skeleton count={1}/>
                    <Skeleton count={1}/>
                    <Skeleton count={1}/>
                </SkeletonTheme>
            </Grid.Item>
            <Grid.Item>
                <SkeletonTheme baseColor="#ffffff" highlightColor="#fafafa">
                    <Skeleton height={220}/>
                    <Skeleton count={1}/>
                    <Skeleton count={1}/>
                    <Skeleton count={1}/>
                </SkeletonTheme>
            </Grid.Item>
            <Grid.Item>
                <SkeletonTheme baseColor="#ffffff" highlightColor="#fafafa">
                    <Skeleton height={220}/>
                    <Skeleton count={1}/>
                    <Skeleton count={1}/>
                    <Skeleton count={1}/>
                </SkeletonTheme>
            </Grid.Item>
            <Grid.Item>
                <SkeletonTheme baseColor="#ffffff" highlightColor="#fafafa">
                    <Skeleton height={220}/>
                    <Skeleton count={1}/>
                    <Skeleton count={1}/>
                    <Skeleton count={1}/>
                </SkeletonTheme>
            </Grid.Item>
        </Grid>
    );
}

export default KeywordSearchSkeleton;
