import React, { useEffect, useRef, useState } from "react"
import {
  Button,
  CheckList,
  DotLoading,
  Form,
  Image,
  Input,
  List,
  Loading,
  Popup,
  Tag,
  TextArea,
  Toast
} from "antd-mobile";
import { GlobalHeaderBack, PageHead } from "components";
import { ACTIVE_REQUEST_TAB, STORE_STORAGE } from "constants/constants";
import '../MyRequest.scss'
import { addMyRequest, getRequestMetaData } from "services/my-request-client";

import qs from "qs";
import { AddCircleOutline, AppstoreOutline, DeleteOutline, TruckOutline } from "antd-mobile-icons";
import { getLocalData } from "utils/helper";
import { useHistory } from "react-router-dom";
import { imageUpload } from "services/settings-client";

const RequestForm = () => {

  const [form] = Form.useForm();
  const history = useHistory();
  let store = getLocalData(STORE_STORAGE)
  const [isRequestUrlFound, setIsRequestUrlFound] = useState(null);
  const [isRequestUrlLoading, setIsRequestUrlLoading] = useState(false);
  const [errorState, setErrorState] = useState({});
  const [myRequestLoading, setMyRequestLoading] = useState(false);
  const [isViewStore, setIsViewStore] = useState(false);
  const [storeValue, setStoreValue] = useState(null);
  const [uploadedUrl, setUploadedUrl] = useState(null);
  const [imageUploading, setImageUploading] = useState(false);
  const imageInput = useRef()

  const params = qs.parse((window.location.search).substring(1))

  const host = params.url ? new URL(params.url) : null

  const fetchMetaData = React.useCallback(
    (url) => {
      setIsRequestUrlLoading(true)
      let productUrl = params?.url
      if (url) {
        productUrl = url
      }
      let makeUrl = productUrl
      const host = productUrl ? new URL(productUrl) : productUrl

      if (productUrl) {
        if (host.origin.includes("flipkart")) {
          makeUrl = host.href
        } else {
          makeUrl = host.origin + host.pathname
        }
      }

      getRequestMetaData({ url: makeUrl })
        .then(res => {
          setIsRequestUrlFound(res.data.data)
          setIsRequestUrlLoading(false)
        })
        .catch(err => {
          setIsRequestUrlLoading(false)
        })
    },
    [params?.url],
  )

  useEffect(() => {
    if (params?.url) {
      fetchMetaData()
    }
  }, [fetchMetaData, params?.url]);

  useEffect(() => {
    form.setFieldsValue({ title: isRequestUrlFound?.title })
    form.setFieldsValue({ link: isRequestUrlFound?.url ? isRequestUrlFound?.url : '' })
    form.setFieldsValue({ image: isRequestUrlFound?.image })
  }, [form, isRequestUrlFound, params?.url]);


  const onFinishShipping = (value) => {

    let isEmpty = value?.attrs?.every(item => !item.key && !item.value)
    let hasStore = findStore?.id || storeValue;

    if (!hasStore) {
      form.setFields([{ name: 'shop_id', errors: 'Shop is required' }]);
      setIsViewStore(true);
      return;
    }
    let requestFormData = {
      link: value.link,
      title: value.title,
      shop_id: findStore ? findStore.id : storeValue.id,
      image: value.image,
      attrs: isEmpty ? undefined : value.attrs,
      quantity: value.quantity,
      instructions: value.instructions,
    }

    setMyRequestLoading(true)
    addMyRequest(requestFormData)
      .then(res => {
        setMyRequestLoading(false)
        Toast.show({
          content: res?.data?.message,
          position: 'bottom'
        })
        window.localStorage.setItem(ACTIVE_REQUEST_TAB, JSON.stringify("requested"))
        history.push('/my-request');
      })
      .catch(({ response }) => {
        if (response?.status === 401) {
          window.location.href = `/auth/login`
        }
        let errorsData = response?.data?.errors;
        let formattedErrors = {}
        for (let err in errorsData) {
          formattedErrors[err] = errorsData[err]?.[0]
        }
        setErrorState(formattedErrors)
        setMyRequestLoading(false)
      })
  }

  const handleStoreSelect = (value) => {
    setStoreValue(value)
    setIsViewStore(false)
  }

  const findStore = store?.value?.find(el => {
    if (isRequestUrlFound?.shop) {
      if (isRequestUrlFound?.shop === el?.identifier) {
        return el
      }
    } else if (host?.origin) {
      if (host?.origin === el?.url) {
        return el
      }
    }
    return null
  })

  let storeName = "Store"

  if (findStore) {
    storeName = findStore.name
  } else if (storeValue) {
    storeName = storeValue.name
  }

  const handleLinkFetch = (value) => {
    if (value) {
      setTimeout(() => {
        fetchMetaData(value)
      }, 1000);
    }
  }

  const handleImageSubmit = (e) => {
    e.preventDefault()
    const data = new FormData()
    data.append('image', e.target.files[0])
    setImageUploading(true);
    imageUpload(data)
      .then(res => {
        imageInput.current.value = null
        setUploadedUrl(res.data.url)
        form.setFieldValue('image', res.data.url)
        Toast.show({
          icon: 'success',
          content: "Image Uploaded",
        })
      })
      .catch(err => {
        if (err?.response?.data?.message) {
          Toast.show({
            icon: 'fail',
            content: err?.response?.data?.message,
          })
        } else {
          Toast.show({
            icon: 'fail',
            content: 'Image Upload failed',
          })
        }
      })
      .finally(() => {
        setImageUploading(false);
      })
  }

  const handleImageClick = (e) => {
    e.preventDefault()
    imageInput.current.click()
  }

  let requestedImgURL = isRequestUrlFound?.image || uploadedUrl;

  return (
    <>
      <PageHead title={`Request Form`} />

      <GlobalHeaderBack title="Request Form" height="50px" color="white" />
      <div className="product_request">
        <div className="product_request_info">
          <span className="product_request_info__title">Add new product request</span>
        </div>
        <div className="product_request_meta" style={{ padding: '10px 15px' }}>
          <Form.Item
            label='Paste Product Link'
          >
            {
              isRequestUrlLoading ? <div style={{ textAlign: 'center', color: '#ff3141' }}><DotLoading /></div> :
                <Input type="link" placeholder='Please enter valid product link' onChange={val => handleLinkFetch(val)} />
            }
          </Form.Item>
        </div>
        <Form
          form={form}
          onFinish={onFinishShipping}
          footer={
            <Button block type='submit' color='primary' loading={myRequestLoading}>
              Submit
            </Button>
          }
          className="product_request_meta"
          hasFeedback
          initialValues={{
            quantity: 1,
          }}
        >
          <Form.Item
            name='link'
            label='Link'
            rules={[{ required: true, message: 'Link is required' }]}
          >
            {
              isRequestUrlLoading ? <div style={{ textAlign: 'center', color: '#ff3141' }}><DotLoading /></div> :
                <Input type="link" placeholder='Please enter valid product link' onChange={val => handleLinkFetch(val)} />
            }
          </Form.Item>
          <Form.Item
            name='title'
            label='Title'
            rules={[{ required: true, message: 'title is required' }]}
          >
            {
              isRequestUrlLoading ? <div style={{ textAlign: 'center', color: '#ff3141' }}><DotLoading /></div> :
                <Input placeholder='Please enter a title' autoComplete="chrome-off" />
            }
          </Form.Item>
          <Form.Item
            name='image'
            label='Image Link'
          >
            {
              isRequestUrlLoading ? <div style={{ textAlign: 'center', color: '#ff3141' }}><DotLoading /></div> :
                <Input type="link" placeholder='Please enter valid product image link' />
            }

          </Form.Item>
          {
            requestedImgURL &&
            <Image src={requestedImgURL} alt="product" className="image_data__image" width={100} height={150} fit='contain' />
          }
          {
            !isRequestUrlFound?.image &&
            <>
              <div style={{ textAlign: 'center', paddingTop: '8px' }}>OR</div>
              <Form.Item>
                <div className="image-search">
                  <button
                    onClick={handleImageClick} htmlFor="img-search-if"
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <span
                      style={{
                        backgroundColor: '#efefef',
                        color: 'var(--adm-color-primary)',
                        padding: '10px 20px',
                        borderRadius: '4px',
                        width: '100%',
                      }}
                    >
                      {
                        imageUploading ? <Loading />
                          : 'Upload an Image'
                      }
                    </span>
                  </button>
                  <input
                    id="img-search-if"
                    className="image-search__display"
                    type="file"
                    onChange={handleImageSubmit}
                    ref={imageInput}
                    accept="image/png, image/jpeg, image/jpg"
                  />
                </div>
              </Form.Item>
            </>
          }
          {
            !isRequestUrlFound?.image &&
            <Form.Item>
              <Tag color='warning' style={{ whiteSpace: 'pre-wrap' }}>If you attach an image of the product you want from the product link, it will be easier for us to execute your request.</Tag>
            </Form.Item>

          }
          <Form.Item
            name='quantity'
            label='Quantity'
            rules={[{ required: true, message: 'Quantity is required' }]}
          >
            <Input type="number" placeholder='Please enter quantity' autoComplete="chrome-off" min={1} />
          </Form.Item>
          <List style={{ margin: '3% 0' }}>
            <List.Item prefix={< AppstoreOutline />} onClick={() => setIsViewStore(true)} >
              {storeName}
            </List.Item>
          </List>
          <Form.Item label="Attributes (color, size etc.)" />
          <Form.Array
            name='attrs'
            renderAdd={() => (
              <Button color='primary' fill="none">
                <AddCircleOutline /> Add Atrribute
              </Button>
            )}
            children={(fields, { remove }) => {
              return (
                fields.map(({ index, key }) => {
                  let keyName = `attrs.${index}.key`;
                  let valueName = `attrs.${index}.value`;
                  return (
                    <React.Fragment key={key}>
                      <div className="form-inline">
                        <Form.Item
                          name={[index, 'key']}
                          label='Key'
                        >
                          <Input placeholder='Color' />
                        </Form.Item>
                        <Form.Item name={[index, 'value']} label='Value'>
                          <Input placeholder='Blue' />
                        </Form.Item>
                        <Button color='danger' className="button_remove" size="small"
                          onClick={() => remove(index)}><DeleteOutline /></Button>
                      </div>
                      {
                        (errorState?.[keyName] || errorState?.[valueName]) &&
                        <div style={{ color: 'red', fontSize: '11px', textAlign: 'center' }}>The key and its value are required</div>
                      }
                    </React.Fragment>
                  )
                })
              )
            }}
          />
          <Form.Item
            name='instructions'
            label='Description'
          >
            <TextArea placeholder='Please enter some instructions' rows={5} maxLength={600} autoSize={true} autoComplete="chrome-off" />
          </Form.Item>
        </Form>
      </div>
      <Popup
        visible={isViewStore}
        onMaskClick={() => {
          setIsViewStore(false)
        }}
        bodyStyle={{
          overflowY: "scroll",
          height: '40vh',
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
        }}
      >
        <div className="request_product">
          <CheckList defaultValue={[findStore ? findStore.id : null]}>
            {
              store?.value?.map((storeValue, index) => {
                return (
                  <CheckList.Item value={storeValue.id} key={`storeValue${index}`} onClick={() => handleStoreSelect(storeValue)}>
                    <span><TruckOutline /> {storeValue.name}</span>
                  </CheckList.Item>
                )
              })
            }
          </CheckList>
        </div>
      </Popup>
    </>
  )
}

export default RequestForm
