import React, {useState} from "react"
import './ProfileDetails.scss'
import {Button, Form, Input, Toast} from "antd-mobile";
import {CloseCircleOutline} from "antd-mobile-icons";
import useErrors from "hooks/useErrors";
import {useAuth} from "context/auth-context";
import {storeProfileInfo} from "services/profile-client";
import {LOCAL_STORAGE_USER} from "constants/constants";


const EmailAddress = (props) => {

    const [form] = Form.useForm()
    const {getErrors} = useErrors()
    const {setIsViewEmailAddress, userData} = props

    const {profileInfo} = useAuth()

    const [isFormLoading, setIsFormLoading] = useState(false)
    const [isOtpSend, setIsOtpSend] = useState(null);

    const onFinishProfileUpdate = (value) => {

        const profileValue = {
            action: "email",
            email: value.email
        }

        const profileVerifyValue = {
            action: "email",
            email: value.email,
            otp: value.otp,
            password: value.password,
        }

        setIsFormLoading(true)
        storeProfileInfo(isOtpSend ? profileVerifyValue : profileValue)
            .then(res => {
                if (res?.data?.intent) {
                    setIsOtpSend(res?.data?.intent)
                } else {
                    setIsViewEmailAddress(false)
                }
                profileInfo.email = profileVerifyValue.email
                window.localStorage.setItem(LOCAL_STORAGE_USER, JSON.stringify(profileInfo))
                Toast.show({
                    icon: 'success',
                    content: res?.data?.message,
                })
                setIsFormLoading(false)
            })
            .catch(err => {
                if (err?.response?.data?.errors) {
                    form.setFields(getErrors(err.response.data.errors));
                }
                setIsFormLoading(false)
            })
    }

    return (
        <div className="profile_name">
            <div className="profile_info">
                <div>
                    <span className="profile_info__title">Email Address</span>
                </div>
                <div>
                    <span className="profile_info__icons"
                          onClick={() => setIsViewEmailAddress(false)}><CloseCircleOutline/></span>
                </div>
            </div>
            <Form
                onFinish={onFinishProfileUpdate}
                footer={
                    <Button block type='submit' color='primary' loading={isFormLoading}>
                        Submit
                    </Button>
                }
                className="profile_form"
                initialValues={{
                    email: userData?.email,
                }}
            >
                {
                    !isOtpSend &&
                    <Form.Item
                        name='email'
                        label='Email Address'
                    >
                        <Input placeholder='Please enter your email address' type="email"/>
                    </ Form.Item>
                }
                {
                    isOtpSend === "new_email_verify" &&
                    <>
                        <Form.Item
                            name='email'
                            label='New Email Address'
                        >
                            <Input placeholder='Please enter your email address' type="email"/>
                        </ Form.Item>
                        <Form.Item
                            name='otp'
                            label='OTP'
                        >
                            <Input placeholder='Please enter your otp' type="text"/>
                        </ Form.Item>
                        <Form.Item
                            name='password'
                            label='Password'
                        >
                            <Input placeholder='Please enter your password' type="password"/>
                        </ Form.Item>
                    </>
                }
            </Form>
        </div>
    );
}
export default EmailAddress
