import { useLayoutContext } from 'context/LayoutContext';
import useScript from 'hooks/useScript';
import React from 'react'
import { useLocation } from 'react-router-dom';
import BottomTabBar from "../BottomTabBar";

const BasicLayout = (props) => {
  const { heroSlides, scriptsConfig } = useLayoutContext();
  const { children } = props;
  const routeLocation = useLocation();

  useScript(
    {
      scriptId: "google-tag",
      scriptConfig: scriptsConfig?.google_tag_id?.label,
      customSript: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${scriptsConfig?.google_tag_id?.label}');`,
    }
  )

  useScript({
    scriptConfig: scriptsConfig?.facebook_app_id?.label,
    scriptId: 'facebook-sdk',
    src: 'https://connect.facebook.net/en_US/sdk.js',
    customSript: `
      window.fbAsyncInit = function () {
        FB.init({
          appId: '${scriptsConfig?.facebook_app_id?.label}',
          cookie: true,
          xfbml: true,
          version: 'v12.0'
        });
        FB.AppEvents.logPageView();
      };
    `,
  })

  useScript({
    scriptConfig: scriptsConfig?.facebook_pixel_id?.label,
    scriptId: 'facebook-pixel',
    customSript: `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '${scriptsConfig?.facebook_pixel_id?.label}');
      fbq('track', 'PageView');
    `
  })

  useScript(
    {
      scriptId: "messenger-desk",
      scriptConfig: scriptsConfig?.chatwoot_token?.label && scriptsConfig?.chatwoot_base_url?.label && scriptsConfig?.chatwoot_mobile?.label,
      customSript: `
      (function(d,t) {
        var BASE_URL="${scriptsConfig?.chatwoot_base_url?.label}";
        var g=d.createElement(t),s=d.getElementsByTagName(t)[0];
        g.src=BASE_URL+"/packs/js/sdk.js";
        g.defer = true;
        g.async = true;
        s.parentNode.insertBefore(g,s);
        g.onload=function(){
          window.chatwootSDK.run({
            websiteToken: '${scriptsConfig?.chatwoot_token?.label}',
            baseUrl: BASE_URL
          })
        }
      })(document,"script");
    `,
    }
  )

  useScript(
    {
      scriptId: "hotjar-tracking",
      scriptConfig: scriptsConfig?.hotjar_id?.label,
      customSript: `
      (function(h,o,t,j,a,r){
      h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
      h._hjSettings={hjid:${scriptsConfig?.hotjar_id?.label},hjsv:${scriptsConfig?.hotjar_sv?.label || '6'}};
      a=o.getElementsByTagName('head')[0];
      r=o.createElement('script');r.async=1;
      r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
      a.appendChild(r);
      })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    `,
    }
  )


  let isCampaign = heroSlides.some(item => item.key === 'campaign' || item?.link?.includes('campaigns'))

  let style = {};
  if (isCampaign && (routeLocation.pathname === '/' || routeLocation.pathname === '/products')) {
    style = {
      backgroundColor: "rgb(248,113,113)",
      background: "linear-gradient(90deg, rgba(248,113,113,1) 0%, rgba(245,158,11,1) 35%, rgba(248,113,113,1) 100%)"
    }
  }

  return (
    <>
      <div style={style}>
        {children}
      </div>
      <BottomTabBar />
    </>

  )
}

export default BasicLayout
