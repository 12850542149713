import React from 'react'
import { Space } from "antd-mobile";
import './Disclaimer.scss'
import { CloseOutlined } from "@ant-design/icons";

const Disclaimer = (props) => {
  const { setViewDisclaimer, brandServiceDisclaimer } = props

  return (
    <Space className="disclaimer" direction='vertical'>
      <div className="disclaimer_title">
        <span className="disclaimer__text title-text">Disclaimer</span>
        <span className="disclaimer__icons" onClick={() => setViewDisclaimer(false)}><CloseOutlined /></span>
      </div>
      <div className="disclaimer__content">
        <span className="title-text"> {brandServiceDisclaimer}</span>
      </div>
    </Space>
  )
}

export default Disclaimer
