import React, { useState } from "react"
import "../Cart.scss"
import { Button, Divider, Form, Grid, Loading, Popup, Space, Stepper, TextArea } from "antd-mobile";
import { useCart } from "context/cart-context";
import ImageRenderer from "utils/ImageRenderer";
import { Link, useHistory } from "react-router-dom";
import { AddSquareOutline, EditFill } from "antd-mobile-icons";
import {
  calculateShippingCharges,
  calculateShippingChargesWithTag,
  findRequestAbleStore,
  getFloatingPrice,
  getShippingLabel,
  getShippingRate,
  ShowPriceWithCurrency
} from "utils/helper";
import { mnv_shipping } from "consts/storage";
import { useLayoutContext } from "context/LayoutContext";

const CartProductDetails = (props) => {
  const { localeCurrency, defaultShippingUnit, preloaderImage, shippingMethodsMap } = useLayoutContext();
  let history = useHistory();

  const {
    handleCheckBoxChange,
    handleQtyChange,
    isQuantityLoading,
    handleSellerSelect,
    selectedProduct,
    handleUpdateNote,
    isNoteLoading,
    state: cartState,
  } = useCart()

  const { sellerItems, seller } = props

  const [selectedItem, setSelectedItem] = useState(null);
  const [viewUpdateNote, setViewUpdateNote] = useState(false);

  // const handleRemoveCartItem = (id) => {
  //     Dialog.confirm({
  //         content: 'Are you sure you want to delete this item',
  //         closeOnMaskClick: true,
  //         confirmText: "Confirm",
  //         cancelText: "Cancel",
  //         onConfirm: async () => {
  //             await sleep(2000)
  //             cartItemRemoved([id])
  //         },
  //     })
  // }

  const onFinishNote = (value) => {
    handleUpdateNote(value.instructions, selectedItem.id, setViewUpdateNote)
  }

  return (
    <>
      <Grid columns={12} gap={24} className="cart_layout seller_layout">
        <Grid.Item span={1}>
          <div className="cart_item_checkbox">
            <input type="checkbox" id={`brand-${seller}`} checked={cartState.groupedSelectedStatus?.[seller].selected}
              onChange={(e) => handleSellerSelect(e.target.checked, seller)} />
          </div>
        </Grid.Item>
        <Grid.Item span={9}>
          {
            sellerItems[0]?.seller_id && sellerItems[0]?.extras?.seller_type !== "campaign" ?
              <span className="store_title"
                onClick={() => {
                  history.push({
                    pathname: '/seller-details',
                    search: `?seller_id=${seller}&shop_id=4`
                  })
                }}>
                {sellerItems[0]?.seller_name ? sellerItems[0]?.seller_name : "Mixed"}
              </span>
              :
              <span
                className="seller_group">{sellerItems[0]?.seller_name ? sellerItems[0]?.seller_name : "Mixed"}</span>
          }
        </Grid.Item>
      </Grid>
      {
        sellerItems?.length > 0 && sellerItems.map((item, index) => {
          let pathName = "/products/details"
          
          if (findRequestAbleStore(parseInt(item?.shop_id))) {
            pathName = "/manual-requests"
          } else if (item?.extras?.seller_type === "campaign") {
            pathName = `/products/details`
          }

          let searchParams = `?url=${encodeURIComponent(item?.link)}`

          if (item?.campaign_product_id) {
            searchParams = `?ipid=${item.inv_pid}&campaign_product=${item.campaign_product_id}`
          } else if (item?.product_code) {
            let productSlug = item?.slug || item?.title;
            pathName = `/products/${encodeURIComponent(productSlug)}/${item.product_code}`
            searchParams = null;
          }

          let mvnShip = "Shipping Charges"
          let itemShippingRate = parseFloat(item?.extras?.shipping?.fee)
          if (item?.freight === "mvn_ship_for_me") {
            mvnShip = mnv_shipping
            let isWholesaleProduct = item.order_type === 'wholesale'
            let approxQty = item?.quantity;
            let approxWeight = (item?.extras?.weight?.value || 0) * approxQty;
            itemShippingRate = getShippingRate({ selectedFrCat: item?.shipping, approxWeight, isWholesaleProduct });
            itemShippingRate = parseFloat(itemShippingRate)
          }

          let shippingLabel = getShippingLabel({ shippingIdentifier: item?.freight, shippingMethodsMap });

          return (
            <div key={`sellerWiseProduct${index}`} className="cart_full_layout">
              <Grid columns={12} gap={10} className="cart_product_layout">
                <Grid.Item span={1} className="cart_product_layout_checkbox">
                  <div className="cart_item_checkbox">
                    <input type="checkbox" id={`cart_item-${item.id}`}
                      onChange={(e) => handleCheckBoxChange(e.target.checked, item)}
                      checked={item.isSelected} />
                  </div>
                </Grid.Item>
                <Grid.Item span={2} className="cart_product_image" onClick={() => history.push({
                  pathname: pathName,
                  search: searchParams,
                  state: {
                    title: item.title,
                    price: item?.price,
                    image: item?.image
                  }
                })}>
                  <ImageRenderer
                    key={item?.id}
                    url={item?.image}
                    thumb={item?.image}
                    width={60}
                    height={60}
                    alt={`CartImage${item?.id}`}
                    borderRadius="8px"
                    imageBackground={preloaderImage ?? "/assets/product/preloader-bg_62X62.png"}
                  />
                </Grid.Item>
                <Grid.Item span={9} className="cart_product_details">
                  <Grid columns={1} gap={6}>
                    <Grid.Item className="cart_product_details__title">
                      <Link to={{
                        pathname: pathName,
                        search: searchParams,
                        state: {
                          title: item.title,
                          price: item?.price,
                          image: item?.image
                        }
                      }}>
                        <span>{item?.title}</span>
                      </Link>
                    </Grid.Item>
                    <Grid.Item>
                      <Grid columns={1} gap={2}>
                        <Grid.Item>
                          {
                            item?.attrs?.map((el, index) => {
                              return (
                                <span key={`productAttrMeta${index}`}
                                  className="cart_attr">{el?.value} </span>
                              )
                            })
                          }
                        </Grid.Item>
                        <Grid.Item>
                          {
                            item?.campaign_product_id ?
                              <span className="shipping_charges"> {mvnShip}: {calculateShippingChargesWithTag({ shipping: item?.shipping, rate: itemShippingRate, currency: localeCurrency, defaultShippingUnit, shopId: item?.shop_id })}</span>
                              :
                              <>
                                {
                                  item?.freight === "mvn_ship_for_me" && item?.shipping ?
                                    <span className="shipping_charges">
                                      <>
                                        {getShippingLabel({ shippingIdentifier: item?.freight, shippingMethodsMap })}: {calculateShippingCharges({ shipping: item?.shipping, rate: itemShippingRate, currency: localeCurrency, defaultShippingUnit, shopId: item?.shop_id })}
                                      </>
                                    </span>
                                    :
                                    <>
                                      {
                                        item?.extras?.shipping?.identifier &&
                                        <span
                                          className="shipping_charges">SHIPPING: {item?.freight_title || shippingLabel}</span>
                                      }
                                    </>
                                }
                              </>
                          }
                        </Grid.Item>

                      </Grid>
                    </Grid.Item>
                    <Grid.Item>
                      {
                        item?.order_type === 'wholesale' && item?.meta_items?.length
                          ? <Space direction="vertical" style={{ fontSize: '11px', "--gap-vertical": '3px', width: '100%' }}>
                            {
                              item.meta_items.map(itemSku => {
                                return (
                                  <Space key={`${itemSku?.cart_item_id}:${itemSku?.id}`} align="center" justify="between" style={{ borderTop: '1px solid #efefef', paddingTop: '3px', width: '100%' }}>
                                    <Space direction="vertical">
                                      {
                                        itemSku?.attrs?.map(skuMeta => {
                                          return (
                                            <div key={`${skuMeta.propId}:${skuMeta.valueId}`} className="text-xs" style={{ maxWidth: '150px' }}>
                                              <span>{skuMeta.key}: <strong>{skuMeta.value}</strong></span>
                                            </div>
                                          )
                                        })
                                      }
                                      <strong>
                                        {ShowPriceWithCurrency({ price: parseFloat(itemSku.price)?.toFixed(2), currency: localeCurrency })}
                                      </strong>
                                    </Space>
                                    <div className="price_qty">
                                      <Stepper
                                        min={1}
                                        defaultValue={itemSku?.quantity}
                                        onChange={value => handleQtyChange(value, item, itemSku.id, 'wholesale')}
                                        className="price_qty__stepper"
                                      />
                                    </div>
                                  </Space>
                                )
                              })
                            }
                            <div
                              className="cart_product_details__price"
                              style={{ borderTop: '1px solid #efefef', paddingTop: '6px' }}
                            >
                              {ShowPriceWithCurrency({ price: getFloatingPrice(item?.price), currency: localeCurrency })}
                            </div>
                          </Space>
                          : <Space block justify="between" align="center">
                            {
                              item.id === selectedProduct && isQuantityLoading ?
                                <div style={{ color: "#ff3141" }}>
                                  <Loading color="currentColor" />
                                </div>
                                :
                                <div
                                  className="cart_product_details__price">{ShowPriceWithCurrency({ price: getFloatingPrice(item?.price), currency: localeCurrency })}</div>
                            }
                            <div className="price_qty">
                              <Stepper
                                min={1}
                                defaultValue={item?.quantity}
                                onChange={value => handleQtyChange(value, item, item.id)}
                                className="price_qty__stepper"
                              />
                            </div>
                          </Space>
                      }

                    </Grid.Item>

                  </Grid>
                </Grid.Item>
              </Grid>
              <Grid columns={10} gap={16} className="grid_bottom">
                <Grid.Item span={10} style={{ textAlign: "right" }}>
                  {
                    item.instructions ?
                      <Button
                        size="mini"
                        onClick={() => {
                          setViewUpdateNote(true)
                          setSelectedItem(item)
                        }}
                        className="note_button"
                      >
                        <Space><EditFill /> Edit Note</Space>
                      </Button>
                      :
                      <Button
                        size="mini"
                        onClick={() => {
                          setViewUpdateNote(true)
                          setSelectedItem(item)
                        }}
                        className="note_button"
                      >
                        <Space>Add Note <AddSquareOutline /></Space>
                      </Button>
                  }
                </Grid.Item>
              </Grid>
              <Divider />
            </div>
          )
        })
      }
      {
        cartState?.charge_type === 'by_seller' && cartState?.charges?.length > 0 &&
        <Grid columns={12} gap={[10, 5]} style={{ marginLeft: 20, marginRight: 20, marginBottom: 15, }}>
          {
            cartState?.charges?.map(charge => {
              return (
                <React.Fragment key={charge.type}>
                  <Grid.Item span={6} >
                    <strong>{charge?.label}: </strong>
                  </Grid.Item>
                  <Grid.Item span={6}>
                    <strong>{ShowPriceWithCurrency({ price: getFloatingPrice(charge.amount), currency: localeCurrency })}</strong>
                  </Grid.Item>
                </React.Fragment>
              )
            })
          }
        </Grid>
      }
      <Popup
        visible={viewUpdateNote}
        onMaskClick={() => {
          setViewUpdateNote(false)
        }}
        position="bottom"
        bodyStyle={{
          height: "35vh",
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
        }}
        destroyOnClose={true}
      >
        <div style={{ minHeight: '35vh' }}>
          <Form
            onFinish={onFinishNote}
            footer={
              <Button block type="submit" color="primary" loading={isNoteLoading}>
                Submit
              </Button>
            }
            className="note_form"
            initialValues={{
              instructions: selectedItem?.instructions ? selectedItem?.instructions : ""
            }}
          >
            <Form.Item
              name="instructions"
              label="Note"
            >
              <TextArea placeholder="Please enter note for this product" />
            </Form.Item>
          </Form>
        </div>
      </Popup>
    </>
  )
}

export default CartProductDetails
