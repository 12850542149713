import React from "react"
import { Grid } from "antd-mobile";
import { useProduct } from "context/product-context";
import "./CampaignTimer.scss"
import Countdown from "react-countdown";
import { compareCampaignTime } from "utils/helper";
import { Link } from "react-router-dom";

const CampaignTimer = () => {

  const { state: productData } = useProduct()

  return (
    <>
      {
        productData?.campaigns?.map((camp, index) => {
          let scheduledTime = null
          let campaignText = null

          if (compareCampaignTime(camp?.finished_at, camp?.started_at) === "Scheduled") {
            scheduledTime = camp?.started_at
            campaignText = "Start At :"
          } else if (compareCampaignTime(camp?.finished_at, camp?.started_at) === "Running") {
            scheduledTime = camp?.finished_at
            campaignText = "End At :"
          }

          return (
            <div key={`allCampaign${index}`}>
              <Link
                to={{
                  pathname: `/campaigns/${productData?.campaign?.slug}`,
                }}
                style={{
                  textDecoration: 'none',
                  color: '#ffffff',
                }}
              >
                {
                  compareCampaignTime(camp?.finished_at, camp?.started_at) === "Expired" ?
                    <Grid columns={2} gap={8} className="campaign_timer_grid">
                      <Grid.Item>
                        <strong>{camp?.title}</strong>
                      </Grid.Item>
                      <Grid.Item>
                        <div className="counter_grid">
                          <div className="counter_grid__end">Campaign Expired</div>
                        </div>
                      </Grid.Item>
                    </Grid>
                    :
                    <Grid columns={2} gap={8} className="campaign_timer_grid">
                      <Grid.Item>
                        <strong>{camp?.title}</strong>
                      </Grid.Item>
                      <Grid.Item>
                        <div className="counter_grid">
                          <div className="counter_grid__end">{campaignText} </div>
                          <Countdown
                            date={(scheduledTime ? scheduledTime : camp?.finished_at) * 1000}
                            renderer={props => {
                              return (
                                <div className="counter_grid__counter">
                                  <div className="counter_grid__counter__title">{props.formatted.days} <strong
                                    className="dot">:</strong></div>
                                  <div className="counter_grid__counter__title">{props.formatted.hours} <strong
                                    className="dot">:</strong></div>
                                  <div className="counter_grid__counter__title">{props.formatted.minutes} <strong
                                    className="dot">:</strong></div>
                                  <div className="counter_grid__counter__title">{props.formatted.seconds}</div>
                                </div>
                              )
                            }}
                          />
                        </div>
                      </Grid.Item>
                    </Grid>
                }
              </Link>
            </div>
          )
        })
      }
    </>
  )
}

export default CampaignTimer
