import * as Types from 'app/actionTypes/productDetails';

export const getProductDetails = (filter = {}) => {
  return {
    type: Types.GET_DETAILS_REQUESTED,
    payload: filter,
  }
}

export const getShippingChargesValue = (data = {}) => {
  return {
    type: Types.GET_SHIPPING_CHARGES_REQUESTED,
    payload: data,
  }
}

export const getDomesticChargesValue = (data = {}) => {
  return {
    type: Types.GET_DOMESTIC_CHARGES_REQUESTED,
    payload: data,
  }
}

export function reset() {
  return {
    type: Types.RESET_DETAILS,
  }
}

export function changingProductDetails() {
  return {
    type: Types.PRODUCT_DETAILS_CHANGED
  }
}

export function setStateDetails(payload= {}) {
  return {
    type: Types.SET_STATE_PRODUCT_DETAILS,
    payload,
  }
}

// Get shadow (Stored user visited) product by id/link
export function getShadowProduct(payload) {
  return {
    type: Types.GET_SHADOW_PRODUCT,
    payload,
  }
}

// Store user visited product as shadow by product payload
export function setShadowProducts(payload) {
  return {
    type: Types.SET_SHADOW_PRODUCTS,
    payload
  }
}

export const getInternalProduct = (id, filters = {}) => {
  return {
    type: Types.GET_INTERNAL_PRODUCT_REQUESTED,
    payload: {id, filters},
  }
}
