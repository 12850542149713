import { getConfigsData, getCountriesData, getCurrenciesData, getLanguagesData } from 'app/actions/system';
import { defaultKeys, systemConstants } from 'consts/storage';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const paymentMethods = [
  {
    id: 'pm-01',
    title: 'bKash',
    image: '/assets/payment/bkashLogo.png',
  },
  {
    id: 'pm-02',
    title: 'Rocket',
    image: '/assets/payment/Rocket.png',
  },
  {
    id: 'pm-03',
    title: 'Visa',
    image: '/assets/payment/visa.png',
  },
  {
    id: 'pm-04',
    title: 'MasterCard',
    image: '/assets/payment/MasterCard.png',
  },
  {
    id: 'pm-05',
    title: 'Amex',
    image: '/assets/payment/amex.png',
  },
  {
    id: 'pm-06',
    title: 'Cash',
    image: '/assets/payment/cash.png',
  },
  {
    id: 'pm-07',
    title: 'Bank',
    image: '/assets/payment/Bank.png',
  },
];

const LayoutContext = React.createContext();

const LayoutProvider = (props) => {
  const dispatch = useDispatch();
  const layoutData = useSelector(state => state.system);
  const {
    configs: configsState,
    countries: countriesState,
    currencies: currenciesState,
    languages: languagesState,
  } = layoutData;
  const [configsData, setConfigsData] = useState(configsState?.data)
  const [countries, setCountries] = useState([])
  const [localeCountry, setLocaleCountry] = useState();
  const [currencies, setCurrencies] = useState([]);
  const [localeCurrency, setLocaleCurrency] = useState();
  const [languages, setLanguages] = useState([]);
  const [localeLanguage, setLocaleLanguage] = useState();
  const [brandName, setBrandName] = useState({ label: 'Home' });
  const [brandTagline, setBrandTagline] = useState('');
  const [brandDesc, setBrandDesc] = useState('');
  const [payMethodLogos, setPayMethodLogos] = useState(paymentMethods);
  const [defaultShippingUnit, setDefaultShippingUnit] = useState([])
  const [isCODAvailable, setIsCODAvailable] = useState(false);
  const [wholeslaeShops, setWholeslaeShops] = useState(null);
  const [defaultShop, setDefaultShop] = useState();
  const [defaultWholesaleShop, setDefaultWholesaleShop] = useState(null);
  const [appLogo, setAppLogo] = useState(null);
  const [homeIcon, setHomeIcon] = useState(null);
  const [favicon, setFavicon] = useState(null);
  const [preloaderImage, setPreloaderImage] = useState(null);
  const [visibleConfigs, setVisibleConfigs] = useState({});
  const [brandConfigs, setBrandConfigs] = useState({});
  const [notifyMessages, setNotifyMessages] = useState({});
  const [wholesaleFAQs, setWholesaleFAQs] = useState([]);
  const [wholesaleYTVideos, setWholesaleYTVideos] = useState([]);
  const [wholesalePageConfig, setWholesalePageConfig] = useState({});
  const [promoBannerAll, setPromoBannerAll] = useState(null);
  const [cashbackOffBanner, setCashbackOffBanner] = useState([]);
  const [campaignPromoSection, setCampaignPromoSection] = useState([]);
  const [specialCampaignSection, setSpecialCampaignSection] = useState(null);
  const [specialCampaignPageConfigs, setSpecialCampaignPageConfigs] = useState();
  const [shippingMethodsMap, setShippingMethodsMap] = useState(null);
  const [heroSlides, setHeroSlides] = useState([]);
  const [helpDeskDomain, setHelpDeskDomain] = useState(null);
  const [countriesSection, setCountriesSection] = useState({});
  const [chinaPageConfigs, setChinaPageConfigs] = useState(null);
  const [doubleChallengeConfigs, setDoubleChallengeConfigs] = useState(null);
  const [indiaShippingChallengeConfigs, setIndiaShippingChallengeConfigs] = useState(null);
  const [chinaPageFaqs, setChinaPageFaqs] = useState(null);
  const [chinaPageVideos, setChinaPageVideos] = useState(null);
  const [scriptsConfig, setScriptsConfig] = useState(null);
  const [regularPayTypes, setRegularPayTypes] = useState(null);
  const [campaignPayTypes, setCampaignPayTypes] = useState(null);
  const [bahrCartPageConfigs, setBahrCartPageConfigs] = useState(null);

  const hasCountrySelector = true;
  const hasStateSelector = false;

  useEffect(() => {
    dispatch(getConfigsData())
  }, [dispatch])

  useEffect(() => {
    dispatch(getCountriesData())
  }, [dispatch])

  useEffect(() => {
    dispatch(getCurrenciesData())
  }, [dispatch])

  useEffect(() => {
    dispatch(getLanguagesData())
  }, [dispatch])

  useEffect(() => {
    if (configsState?.data?.length) {
      setConfigsData(configsState.data)
    }
  }, [configsState])

  useEffect(() => {
    if (layoutData?.countries?.data?.length) {
      let payload = [
        ...layoutData.countries.data,
      ]
      payload = payload.map(item => {
        return {
          ...item,
          value: item.code + item.calling
        }
      })
      setCountries(payload)
    }

    if (layoutData?.currencies?.data?.length) {
      let payload = [
        ...layoutData.currencies.data,
      ]
      payload = payload.map(item => {
        return {
          ...item,
          value: item.prefix + item.suffix
        }
      })
      setCurrencies(payload)
    }
    if (layoutData?.languages?.data?.length) {
      setLanguages(layoutData.languages.data)
    }
  }, [layoutData])

  const getConfigValue = React.useCallback((key, data = null) => {
    let collections = data ?? configsData;
    return collections?.find(item => (item.key) === key)
  }, [configsData])

  useEffect(() => {
    if (configsData?.length) {
      for (let config of configsData) {
        if (config.key === defaultKeys.applicationLogos) {
          if (config?.value?.length > 0) {
            for (let configObj of config?.value) {
              if (configObj?.key === defaultKeys.brandName) {
                setBrandName(configObj);
              } else if (configObj?.key === defaultKeys.appLogo) {
                setAppLogo(configObj)
              } else if (configObj?.key === defaultKeys.appHomeIcon) {
                setHomeIcon(configObj)
              } else if (configObj?.key === defaultKeys.favicon) {
                setFavicon(configObj)
              } else if (configObj?.key === defaultKeys.preloaderImage) {
                setPreloaderImage(configObj?.image)
              }
            }
            let brandMap = config?.value?.reduce((obj, cfg) => {
              obj[cfg.key] = cfg;
              return obj
            }, {})
            setBrandConfigs(brandMap)
          }
        } else if (config.key === defaultKeys.brandTagline) {
          setBrandTagline(config.value || '');
        } else if (config.key === defaultKeys.brandTaglineDesc) {
          setBrandDesc(config.value || '');
        }
        else if (config.key === defaultKeys.defaultCountry) {
          let desiredCountry = countries.find(item => item.code === (config?.value || systemConstants.defaultCountry))
          if (desiredCountry?.code) {
            setLocaleCountry(desiredCountry);
          } else {
            // if country not found on countries list from the api resource, set the config value.
            setLocaleCountry({
              "code": config.value,
              "name": config.value,
            })
          }
        } else if (config.key === defaultKeys.defaultCurrency) {
          let desiredCurrency = currencies.find(item => item.code === (config?.value || systemConstants.defaultCurrency));
          if (desiredCurrency?.code) {
            setLocaleCurrency(desiredCurrency);
          } else {
            // if country not found on currencies list from the api resource, set the config value.
            setLocaleCurrency({
              "suffix": config.value,
              "code": config.value,
            })
          }
        } else if (config.key === defaultKeys.defaultLanguage) {
          let desiredLang
          if (languages.length && config?.value) {
            desiredLang = languages?.find(lang => lang.code === config?.value)
            setLocaleLanguage(desiredLang);
          } else if (languages.length) {
            desiredLang = languages?.find(lang => lang.code === 'en-US');
            setLocaleLanguage(desiredLang)
          }
        }
        else if (config?.key === defaultKeys.campaignPayTypes) {
          let isCODAvailable = config?.value?.split(',')?.includes('cod')
          setIsCODAvailable(isCODAvailable)
          setCampaignPayTypes(config?.value)
        }
        else if (config.key === defaultKeys.payMethodLogos) {
          setPayMethodLogos(config?.value)
        } else if (config.key === defaultKeys.shippingUnit) {
          if (config?.value) {
            setDefaultShippingUnit(config?.value?.split(','))
          }
        } else if (config.key === defaultKeys.wholesaleShops) {
          if (config?.value) {
            setWholeslaeShops(config)
          }
        } else if (config?.key === defaultKeys.defaultShop) {
          setDefaultShop(config?.value)
        }
        else if (config?.key === defaultKeys.defaultConfigs) {
          if (config?.value?.length > 0) {
            for (let configObj of config?.value) {
              if (configObj?.key === defaultKeys.defaultWholesaleShop) {
                setDefaultWholesaleShop(configObj?.label)
              }
            }
          }
        }
        else if (config?.key === defaultKeys.visibleConfigs) {
          if (config.value?.length > 0) {
            let visibleMap = {};
            config.value.forEach(item => {
              visibleMap[item.key] = ['true', 'yes', 'on', '1'].includes(item.label?.toLowerCase());
            })
            setVisibleConfigs(visibleMap);
          }
        } else if (config?.key === defaultKeys.notifyMessages) {
          let msgs = config?.value?.reduce((obj, cfg) => {
            obj[cfg.key] = cfg;
            return obj
          }, {})
          setNotifyMessages(msgs)
        } else if (config?.key === defaultKeys.wholesaleFaqs) {
          setWholesaleFAQs(config?.value)
        } else if (config?.key === defaultKeys.wholesaleYTvideos) {
          setWholesaleYTVideos(config?.value)
        } else if (config?.key === defaultKeys.wholesalePageConfigs) {
          let pageObj = config?.value?.reduce((obj, cfg) => {
            obj[cfg.key] = cfg;
            return obj
          }, {})
          setWholesalePageConfig(pageObj)
        } else if (config?.key === defaultKeys.promoBannerAll) {
          let promoBannerObj = config?.value?.reduce((obj, cfg) => {
            obj[cfg.key] = cfg;
            return obj
          }, {})
          setPromoBannerAll(promoBannerObj)
        } else if (config?.key === defaultKeys.cashbackOffBanner) {
          setCashbackOffBanner(config.value)
        }
        else if (config?.key === defaultKeys.campaignPromoSection) {
          setCampaignPromoSection(config.value)
        } else if (config?.key === 'special_campaign_section') {
          let reducedObj = config?.value?.reduce((obj, cfg) => {
            obj[cfg.key] = cfg;
            return obj
          }, {})
          setSpecialCampaignSection(reducedObj)
        } else if (config?.key === 'special_campaign_page_configs') {
          setSpecialCampaignPageConfigs(config.value)
        } else if (config?.key === 'shipping_methods') {
          let reducedObj = config?.value?.reduce((obj, cfg) => {
            obj[cfg.key] = cfg;
            return obj
          }, {})
          setShippingMethodsMap(reducedObj)
        } else if (config?.key === defaultKeys.heroBanners) {
          setHeroSlides(config.value)
        } else if (config?.key === 'helpdesk_domain') {
          if (config?.value?.length > 3) {
            setHelpDeskDomain(config.value)
          }
        } else if (config.key === 'countries_section') {
          if (config?.value) {
            let countriesBlock = {
              countries: [],
            };
            for (let item of config?.value) {
              if (item.key === 'visible') {
                countriesBlock['visible'] = item.label
              } else if (item.key === 'section_title') {
                countriesBlock['title'] = item.label;
              } else if (item.key === 'section_subtitle') {
                countriesBlock['subtitle'] = item.label
              } else if (item.key === 'country') {
                countriesBlock['countries'].push(item);
              }
            }
            setCountriesSection(countriesBlock)
          }
        } else if (config.key === 'china_page_configs') {
          let valObj = config?.value?.reduce((obj, cur) => {
            return {
              ...obj,
              [cur.key]: cur
            }
          }, {})
          setChinaPageConfigs(valObj)
        } else if (config.key === 'double_challenge_page_configs') {
          let valObj = config?.value?.reduce((obj, cur) => {
            return {
              ...obj,
              [cur.key]: cur
            }
          }, {})
          setDoubleChallengeConfigs(valObj)
        }
        else if (config.key === 'india_shipping_challenge_page_configs') {
          let valObj = config?.value?.reduce((obj, cur) => {
            return {
              ...obj,
              [cur.key]: cur
            }
          }, {})
          setIndiaShippingChallengeConfigs(valObj)
        }
        else if (config.key === 'china_page_faqs') {
          setChinaPageFaqs(config.value)
        } else if (config.key === 'china_page_yt_videos') {
          setChinaPageVideos(config.value)
        } else if (config?.key === 'scripts_config') {
          let reducedObj = config?.value?.reduce((obj, cfg) => {
            obj[cfg.key] = cfg;
            return obj
          }, {})
          setScriptsConfig(reducedObj)
        } else if (config.key === defaultKeys.regularPayTypes) {
          setRegularPayTypes(config.value)
        } else if (config.key === 'bahrcart_opening_page_configs') {
          setBahrCartPageConfigs(config.value)
        }
      }
    }
  }, [configsData, countries, currencies, languages, getConfigValue])

  return (
    <LayoutContext.Provider value={{
      layoutData,
      countries,
      configsState,
      configsData,
      payMethodLogos,
      getConfigValue,
      localeCountry,
      currencies,
      localeCurrency,
      languages,
      localeLanguage,
      brandName,
      brandTagline,
      brandDesc,
      hasCountrySelector,
      hasStateSelector,
      defaultShippingUnit,
      isCODAvailable,
      countriesState,
      currenciesState,
      languagesState,
      wholeslaeShops,
      defaultShop,
      defaultWholesaleShop,
      appLogo,
      homeIcon,
      favicon,
      preloaderImage,
      visibleConfigs,
      brandConfigs,
      notifyMessages,
      wholesaleFAQs,
      wholesaleYTVideos,
      wholesalePageConfig,
      promoBannerAll,
      cashbackOffBanner,
      campaignPromoSection,
      specialCampaignSection,
      specialCampaignPageConfigs,
      shippingMethodsMap,
      heroSlides,
      helpDeskDomain,
      countriesSection,
      chinaPageConfigs,
      doubleChallengeConfigs,
      indiaShippingChallengeConfigs,
      chinaPageFaqs,
      chinaPageVideos,
      scriptsConfig,
      regularPayTypes,
      campaignPayTypes,
      bahrCartPageConfigs,
    }} {...props} />
  )
}

const useLayoutContext = () => {
  const context = React.useContext(LayoutContext);
  if (context === undefined) {
    throw new Error(`useLayoutContext must be used within a layout wrapper.`)
  }

  return context;
}

export { LayoutProvider, useLayoutContext }
