import React from 'react'
import './ProductSpecification.scss'
import {useProduct} from "context/product-context";
import {Grid, List} from "antd-mobile";
import {CloseOutlined} from "@ant-design/icons";

const ProductSpecification = (props) => {

    const { setViewSpecification } = props

    const {state: productData} = useProduct()

    const specifications = productData?.specifications

    return (
        <div style={{ height : '80vh', overflowY: 'scroll'}}>
            <Grid columns={10} gap={18} className="specification">
                <Grid.Item span={9}>
                    <span className="specification__title title-text">Specification</span>
                </Grid.Item>
                <Grid.Item span={1}>
                    <span className="specification__icons" onClick={() => setViewSpecification(false)}><CloseOutlined /></span>
                </Grid.Item>
            </Grid>
            <List>
                {
                    specifications?.map((element, index) => {
                        return (
                            <List.Item key={index + "list_item"} >
                                <div className="list_value">
                                    <div className="list_value__text title-text">{element?.label}</div>
                                    <div className="list_value__value title-text">{element?.value}</div>
                                </div>
                            </List.Item>
                        )
                    })
                }
            </List>
        </div>
    )
}

export default ProductSpecification
