import React from "react"
import './ProfileDetails.scss'
import {Button, Form, Input} from "antd-mobile";
import {useAuth} from "context/auth-context";
import {CloseCircleOutline} from "antd-mobile-icons";

const RefundNumber = (props) => {

    const {setIsViewRefundMethod} = props

    const {onFinishProfileUpdate, isProfileUpdating} = useAuth()

    return (
        <div className="profile_name">
            <div className="profile_info">
                <div>
                    <span className="profile_info__title">Refund Numbers</span>
                </div>
                <div>
                    <span className="profile_info__icons"
                          onClick={() => setIsViewRefundMethod(false)}><CloseCircleOutline/></span>
                </div>
            </div>
            <Form
                onFinish={onFinishProfileUpdate}
                footer={
                    <Button block type='submit' color='primary' loading={isProfileUpdating}>
                        Submit
                    </Button>
                }
                className="profile_form"
            >

                <Form.Item
                    name='bash_number'
                    label='Bkash Number'
                >
                    <Input placeholder='Please enter your bkash number' />
                </ Form.Item>
                <Form.Item
                    name='rocket_number'
                    label='Rocket Number'
                >
                    <Input placeholder='Please enter your rocket number'/>
                </ Form.Item>
            </Form>
        </div>
    );
}
export default RefundNumber
