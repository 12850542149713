import {all, call, put, takeEvery} from "redux-saga/effects"
import * as types from 'app/actionTypes/productDetailsData';
import {getProductDescription, getProductReview} from "services/product-client";

const getReviewApi = async (filter = null) => {
  return getProductReview(filter)
      .then(res => res.data)
      .catch((error) => { throw error })
}

const getDescriptionApi = async (filter = null) => {
  return getProductDescription(filter)
      .then(res => res.data)
      .catch((error) => { throw error })
}

function* fetchProductReview(action) {
  try {
    const data = yield call(() => getReviewApi(action.payload));
    yield put({ type: types.GET_PRODUCT_REVIEW_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: types.GET_PRODUCT_REVIEW_FAILED, message: e.message });
  }
}

function* fetchProductDescription(action) {
  try {
    const data = yield call(() => getDescriptionApi(action.payload));
    yield put({ type: types.GET_PRODUCT_DESCRIPTION_SUCCESS, data: data });
  } catch (e) {
    yield put({ type: types.GET_PRODUCT_DESCRIPTION_FAILED, message: e.message });
  }
}

function* productDetailsDataSaga() {
  yield all([
    takeEvery(types.GET_PRODUCT_REVIEW_REQUESTED, fetchProductReview),
    takeEvery(types.GET_PRODUCT_DESCRIPTION_REQUESTED, fetchProductDescription),
  ]);
}

export default productDetailsDataSaga;
