import * as type from 'app/actionTypes/category';
import produce from "immer";

const initialState = {
    data: null,
    loading: false,
    error: null,
    filteredBy: null,
}

const shippingCatsFlatten = (list, parentItem=null) => {
    let flattenItems = [];
    for(const cat of list){
        cat['value'] = cat.id;
        cat['name'] = parentItem ? `${parentItem.name} > ${cat.name}` : cat.name;
        if(cat.children){
            let children = shippingCatsFlatten(cat.children, cat);
            if(children){
                flattenItems = flattenItems.concat(children);
            }
        }
        flattenItems.push(cat)
    }
    return flattenItems;
}

export default function category(state = initialState, action) {
    const { payload } = action;
    return produce(state, draft => {
        switch (action.type) {
            case type.GET_SHIPPING_CATEGORY_REQUESTED:
                draft.loading = true
                break
            case type.GET_SHIPPING_CATEGORY_SUCCESS:
                let catItems = shippingCatsFlatten(payload.data.data);
                draft.data = catItems
                draft.loading = false
                draft.filteredBy = payload.filters
                break
            case type.GET_SHIPPING_CATEGORY_FAILED:
                draft.error = action.error
                draft.loading = false
                break
            default:
                return state
        }
    })
}
