export const ACTIVE_TAB = "__ACTIVE_TAB__";
export const REQUEST_1ST_TEXT = "এই প্রোডাক্টটি পোস্ট অফিস এর মাধ্যমে আসবে। বর্তমানে পোস্ট অফিস শিপমেন্ট ২৫-১২০ দিন বা তারও বেশি সময় লাগছে। পোস্ট অফিস শিপমেন্ট এর উপর Ali2BD এর কোন কন্ট্রোল নেই। আমরা অনুরোধ করছি নিচের প্রোডাক্টগুলো থেকে একই ধরনের প্রোডাক্টটি পছন্দ করে অর্ডার করুন, এই প্রোডাক্টগুলো MoveOn - Ship For Me এর মাধ্যমে দেশে ১৫-২৫ দিনের মধ্যে আসবে।";
export const REQUEST_2ND_TEXT = "যদি মনে করেন উপরের তালিকাতে আপনার পছন্দের প্রোডাক্টটি নেই তাহলে \"Request Now\" বাটনে ক্লিক করুন, Ali2BD আপনাকে একই ধরনের প্রোডাক্টটি বাছাই করে দেয়ার চেস্টা করবে।";
export const REQUEST_3RD_TEXT = "দেরি হলেও আমি প্রোডাক্টটি কিনতে চাই এবং Ali2BD এর সার্ভিস ও রিফান্ড নীতিমালার সাথে সহমত পোষন করছি।";
export const ORDER_NOTICE = "Orders paid between 11:00 AM - 5:00 PM will be processed in 7 hours. Orders paid between 5:00 PM to 11:00 AM will be processed by 2:00PM of the next day.";
export const DISCLAIMER = "Ali2BD একটি ক্রস বর্ডার শপিং ও শিপিং সার্ভিস প্রোভাইডার। আমাদের মূল লক্ষ্য বর্ডার বাধা পেরিয়ে আপনাকে কাঙ্ক্ষিত পণ্য এনে দেওয়া। Ali2BD তে প্রদর্শিত প্রোডাক্ট বিভিন্ন থার্ড পার্টি শপিং ওয়েবসাইট থেকে সংগৃহীত। অর্থাৎ Ali2BD সরাসরি কোন পণ্যের সেলার বা ম্যানুফ্যাকচারার নয়। তবে গ্রাহক স্বার্থ রক্ষার্থে শপিং ওয়েবসাইটের রিফান্ড পলিসি অনুসারে Ali2BD বিফর এবং আফটার সেলস সার্ভিস দেয়।";
export const SHIPPING_DETAILS = "যেহেতু শিপিং চার্জ পণ্যের ওজনের উপর নির্ভরশীল তাই MoveOn - Ship For Me শিপমেন্ট সিলেক্টের সময় টোটাল প্রাইসে শিপিং চার্জ যুক্ত থাকেনা। প্রোডাক্ট দেশে আসার পর পরিমাপ করে (প্যাকেট সহ) ওজন অনুসারে কেজি প্রতি গুণ করে শিপিং চার্জ যুক্ত করা হবে। এছাড়াও টোটাল প্রাইসে দেশের ভিতরের ডেলিভারি চার্জও যুক্ত থাকে না। এটি ডেলিভারির সময় পেমেন্ট করে পণ্য রিসিভ করতে হয়।";
export const SHIPPING_DETAILS_CATEGORY = "Choose product category to get the shipping fee based on the product category";
export const SERVICES_RANGE_TIPS = (brandName = "Ali2BD") => [
  {
    title: `${brandName} will purchase products for you from selected third-party platforms, but ${brandName} will not take responsibility for the risks that come with the products.`,
    text: `${brandName} will only provide you with the shopping agent service for selected third-party platforms. ${brandName} is not responsible for the possible risks and legal consequences like quality issues and copyright infringement. To protect your benefits, ${brandName} will inspect the product multiple times, communicate with you, and provide after-sales assistance during the procedure. If you need to purchase a product from the platform of used items(such as any domestic Chinese website not added to our site) / a third-party platform that cannot be verified, an extra service fee will be charged and the product will not be eligible for return and exchange. In this case, there may be problems like failure to purchase and shipping risks. For details, contact our customer support team.`,
  }
];


export const SHOPPING_PROCESS = (brandName = "Ali2BD") => [
  {
    title: `Two steps of the ${brandName} shopping agent service - First Step: Purchase; Second Step: Shipping. You will make one payment for each of the steps. We kindly ask you to estimate the total shipping cost before placing the order.`,
    text: `Step 1: We will purchase the product for you after you pay for the product and the domestic shipping cost incurred in China. The purchased product will be shipped to the ${brandName} warehouse in the origin country( eg. China or USA). Step 2: Items will be stacked in a bigger carton (if the parcel is smaller than 3kg), packed properly to avoid damage, and then shipped to the ${brandName} warehouse in the destination country (eg. Bangladesh).`,
  }
];

export const TRANSPOTATION_RICK = (brandName = "Ali2BD") => [
  {
    title: `The product is purchased via the shopping agent service and will be delivered by shipping agents; you will be fully responsible for the possible issues with customs restrictions and logistics risks.`,
    text: `As a service-providing platform, ${brandName} is partnered with shipping agents who ship the products we purchased for you. ${brandName} is not responsible for the confiscation, damage, loss, or delay of the products that occurred due to the policy of the customs or the uncontrollable risks associated with international transportation. However, ${brandName} will warn you about the possible risks ahead of time. We will improve our logistics insurance services since we want to help you with assessing the possible risks and minimizing the possible losses.`
  }
];

export const NOTICES = "আসসালামু আলাইকুম।অত্যন্ত আনন্দের সাথে আপনাদের অবগত করতে চাই, ২৬শে মার্চ ২০২২ আমাদের নতুন ওয়েবসাইট আনুষ্ঠানিকভাবে উদ্বোধন করা হয়েছে। ওয়েবসাইট আপগ্রেডের কাজ এখনো চলছে। তাই অর্ডারের ক্ষেত্রে কোনো ধরনের সমস্যা নাহলেও গ্রাহক সেবায় কিছুটা বিলম্ব হতে পারে। সেজন্য আমরা আপনাদের কাছে আন্তরিক ভাবে দুঃখিত ও ক্ষমা প্রার্থী। অর্ডার সংক্রান্ত কোন জটিলতার সম্মুখীন হলে সরাসরি আমাদের ফেসবুক পেজে ইনবক্স করুন কিংবা কল করুন আমাদের কল সেন্টারে ।";

export const USER_AGENT_CHECK = "Linux; Android; Ali2BD";
export const FULL_APP_USER_AGENT = "Mozilla/5.0 (Linux; Android; Ali2BD) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/90.0.4430.91 Mobile Safari/537.36";
export const COD_BADGE = "COD Available";

export const OFFICE_NAME = "Ali2BD";
export const OFFICE_ADDRESS = "Plot 1020, Road, 9 Avenue 9, Dhaka 1216";
export const OFFICE_PHONE = "09666-783333";

export const DOMESTIC_NOTICE = "সেলার ওয়্যারহাউজ থেকে MoveOn গুয়াংজু ওয়্যারহাউজ ডেলিভারি চার্জ, এই চার্জ প্রোডাক্ট টোটাল প্রাইসের সাথে যুক্ত আছে।";

export const defaultConstants = {
  partialCodMinAmount: "partial_cod_min_amount",
  partialCodMinPercent: "partial_cod_min_percent",
  campaignPayTypes: "campaign_pay_types",
};

export const mnv_shipping = "MoveOn-Ship For Me";

export const systemConstants = {
  status: {
    cancelled: "cancelled",
    receivedInCountry: "received_in_country",
  },
  payTypeCOD: "cod",
  ali2bdDisclaimer: "Ali2BD ক্রসবর্ডার শপিং এন্ড শিপিং সার্ভিস প্রোভাইডার। Ali2BD তে প্রদর্শিত প্রোডাক্ট থার্ড পার্টি শপিং ওয়েবসাইট থেকে সংগৃহীত। Ali2BD সরাসরি সেলার বা ম্যানুফাকচারার না। তাই Ali2BD কোনো প্রাসঙ্গিক, জামানত বা যৌথ দায় নিতে বাধ্য নয়। তবে শপিং ওয়েবসাইটের রিফান্ড পলিসি অনুসারে Ali2BD আফটার এবং বিফর সেল সার্ভিস দেয়া হবে।",
  moveOnDisclaimer: "MoveOn - Ship For Me শিপমেন্ট সিলেক্টের সময় টোটাল প্রাইসে শিপিং চার্জ যুক্ত থাকেনা, প্রোডাক্ট দেশে আসার পর ওজন করে (প্যাকেট সহ) ওজন অনুসারে প্রতি কেজিতে গুন করে শিপিং চার্জ যুক্ত হবে। এছাড়া টোটাল প্রাইসে দেশের ভিতরে ডেলিভারি চার্জও যুক্ত থাকেনা, এটি ডেলিভারি সময় পেমেন্ট করতে হবে।",
  helpDeskUri: "https://help.ali2bd.com/",
  tagline: "Smart Shopping with BDT - Ali2BD",
  siteDesc: "The easy platform to brought product from overseas to your doorstep. You can easily using any payment method available in bangladesh.",
  coverImage: "/assets/fallbackBanner.jpg",
  initialGatewayID: 9,
  invoiceTypeOrder: "Order",
  invoiceTypeParcel: "Parcel",
  ctaNotice: `আসসালামু আলাইকুম। অত্যন্ত আনন্দের সাথে আপনাদের অবগত করতে চাই, ২৬শে মার্চ ২০২২ আমাদের নতুন ওয়েবসাইট আনুষ্ঠানিকভাবে উদ্বোধন করা হয়েছে। ওয়েবসাইট আপগ্রেডের কাজ এখনো চলছে। তাই অর্ডারের ক্ষেত্রে কোনো ধরনের সমস্যা নাহলেও গ্রাহক সেবায় কিছুটা বিলম্ব হতে পারে। সেজন্য আমরা আপনাদের কাছে আন্তরিক ভাবে দুঃখিত ও ক্ষমা প্রার্থী। অর্ডার সংক্রান্ত কোন জটিলতার সম্মুখীন হলে সরাসরি আমাদের ফেসবুক পেজে ইনবক্স করুন কিংবা কল করুন আমাদের কল সেন্টারে।`,
  codBadge: "COD Available",
  codeMessage: `Because the total amount of your order is less than the minimum COD amount, you must pay the full amount.`,
  codRestAmountMsg: `When you receive the parcel, pay the remaining amount, as well as MoveOn shipping and local delivery fees.`,
  codRestFeesMsg: `When you receive the parcel, pay the MoveOn shipping and local delivery fees.`,
  cartSelectMsg: "You will be unable to checkout retail, campaign, and wholesale items at the same time."
};

export const defaultKeys = {
  campaignPayTypes: "campaign_pay_types",
  regularPayTypes: "pay_type_settings",
  codSuccessMsg: "cod_success_message",
  codFailedMsg: "cod_failed_message",
  footerSocials: "footer_socials",
  footerPayments: "footer_payments",
  footerMenus: "footer_menus",
  footerCompanyLinks: "footer_company_links",
  supportLinks: "footer_supportLinks",
  applicationLogos: "application_logos",
  footerLogo: "footer_logo",
  brandName: "brand_name",
  brandTagline: "brand_tagline",
  brandTaglineDesc: "brand_tagline_des",
  footerAddress: "office_address",
  footerOfficeHour: "office_hour",
  footerCopyright: "footer_copyright",
  footerPhoneNumber: "contact_number",
  headerLogo: "header_logo",
  headerCtaAlert: "header_cta_alert",
  brandServiceDisclaimer: "brand_service_disclaimer",
  mvnServiceDisclaimer: "mvn_service_disclaimer",
  domesticChargeDisclaimer: "domestic_charge_disclaimer",
  defaultCountry: "country",
  defaultCurrency: "currency",
  defaultLanguage: "language",
  payMethodLogos: "pay_method_logos",
  coverImage: "cover_image",
  themeColor: "theme_color",
  favicon: "favicon",
  appleTouchIcon: "apple_touch_icon",
  defaultImages: "default_images",
  defaultSkuImg: "default_sku_image",
  defaultShippingUnitKG: "shipping_unit",
  heroBanners: "hero_banners",
  heroServices: "hero_services",
  featuredCategories: "featured_categories",
  shippingUnit: "shipping_unit",
  wholesaleShops: "wholesale_shops",
  defaultSkuImgShops: "default_sku_img_shops",
  defaultConfigs: "default_configs",
  defaultShop: "default_shop",
  defaultWholesaleShop: "default_wholesale_shop",
  appLogo: "app_logo",
  appHomeIcon: "app_home_icon",
  preloaderImage: "preloader_image",
  visibleConfigs: "visible_configs",
  storesVisible: "stores_visible",
  wholesaleLabelVisible: "wholesale_label_visible",
  notifyMessages: "notify_messages",
  cartSelectMsg: "cart_select_message",
  deliverableNotifyMsg: "deliverable_notify_message",
  deliverableEmptyMsg: "deliverable_empty_message",
  wholesaleFaqs: "wholesale_faqs",
  wholesaleYTvideos: "wholesale_yt_videos",
  wholesalePageConfigs: "wholesale_page_configs",
  promoBannerAll: "promo_banner_all",
  cashbackOffBanner: "cashback_offer_banner",
  campaignPromoSection: "campaign_promo_section",
  fixedShippingCategoryId: "fixed_shipping_category_id",
  productRequestVisible: "product_request_visible",
  deliveryMessageAfterCourier: "delivery_request_message_after_select_courier",
};

export const campaignStatus = {
  running: "running",
  scheduled: "scheduled",
  expired: "expired",
  leftToStart: "Left to start",
  endsIn: "Ends In",
  ended: "Ended",
};
