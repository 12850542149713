import {all, call, put, takeEvery} from "redux-saga/effects"
import * as types from 'app/actionTypes/campaign';
import {getCampaignDetails, getRunningCampaign, getUpcomingCampaign} from "services/campaign-client";

const getRunningCampaignApi = async (filters = {}) => {
    return getRunningCampaign(filters)
    .then(res => res.data)
    .catch((error) => { throw error })
}

const getUpcomingCampaignApi = async (filters = {}) => {
    return getUpcomingCampaign(filters)
        .then(res => res.data)
        .catch((error) => { throw error })
}

const getCampaignDetailsAPI = async (slug, filters = {}) => {
    return getCampaignDetails(slug, filters)
        .then(res => res.data)
        .catch((error) => { throw error })
}

function* fetchRunningCampaign(action) {
    try {
        const data = yield call(() => getRunningCampaignApi(action.payload));
        yield put({ type: types.GET_RUNNING_CAMPAIGN_SUCCESS, data: data });
    } catch (e) {
        yield put({ type: types.GET_RUNNING_CAMPAIGN_FAILED, message: e.message });
    }
}

function* fetchUpcomingCampaign(action) {
    try {
        const data = yield call(() => getUpcomingCampaignApi(action.payload));
        yield put({ type: types.GET_UPCOMING_CAMPAIGN_SUCCESS, data: data });
    } catch (e) {
        yield put({ type: types.GET_UPCOMING_CAMPAIGN_FAILED, message: e.message });
    }
}

function* fetCampaignDetails(action) {
    try {
        const data = yield call(() => getCampaignDetailsAPI(action.payload.slug, action.payload.filters));
        yield put({ type: types.GET_CAMPAIGN_DETAILS_SUCCESS, data: {...data, actionParams: action.payload} });
    } catch (e) {
        yield put({ type: types.GET_CAMPAIGN_DETAILS_FAILED, message: e.message });
    }
}

function* campaignSaga() {
    yield all([
        takeEvery(types.GET_RUNNING_CAMPAIGN_REQUESTED, fetchRunningCampaign),
        takeEvery(types.GET_UPCOMING_CAMPAIGN_REQUESTED, fetchUpcomingCampaign),
        takeEvery(types.GET_CAMPAIGN_DETAILS_REQUESTED, fetCampaignDetails),
    ]);
}

export default campaignSaga;
